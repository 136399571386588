import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, Navigate, useNavigate } from 'react-router-dom'

const PrivateRoutes = () => {
    // 
    const token = localStorage.getItem('token')

    let booleans = token != null ? true : false
    let auth = { 'token': booleans }
    return (
        // auth.token ? <Outlet /> : <Navigate to={process.env.PUBLIC_URL +"/Login"} />
        auth.token ? <Outlet /> : <Navigate to={"/Login"} />
    )
}

// function PrivateRoutes(props) {
//     const { authorized ,Component } = props;
//     const navigate = useNavigate();
//     useEffect(() => {
//         let login = localStorage.getItem('token');
//         console.log("login", login);
//         console.log("authorized", authorized);

//         if (login == null) {
//             navigate('/Login')
//         }
//     }, []);

//     return (
//         < div >
//             < Component />
//         </div>
//     )
// }
export default PrivateRoutes