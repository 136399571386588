import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Pagination } from '@material-ui/lab'
import moment from 'moment'
import Multiselect from 'multiselect-react-dropdown'
import { AiOutlineClose } from "react-icons/ai"
import { Link, useLocation, useParams } from 'react-router-dom'
import { BASE_URL, GetAPIUrl } from '../API/APIUrl'
import imageErrorSrc from '../assets/images/default.png'
import logo from "../assets/images/logo-img.svg"
import { Colors } from '../constant/Constant'
import { DiamondGridSkeleton } from '../module/Skeletons'
import { confirmRemove, ShowErrorMessage, ShowMessage } from '../module/Tostify'
import { ToastContainer } from 'react-toastify'
import { useSelector } from 'react-redux'
import LoadingSpinner from '../module/LoadingSpinner'
import { b64_to_utf8, BASENAME, utf8_to_b64 } from '../helpers/Utility'
import noimage from "../assets/images/product-details/noimage.jpg";
import JewelryCard from './JewelryCard'

const $ = window.$;

export default function Jewelry(props) {

    const location = useLocation();
    const cid = new URLSearchParams(location.search).get("cid");
    const styleId = new URLSearchParams(location.search).get("styleid");
    const [shape, setShape] = useState(new URLSearchParams(location.search).get("shape"))
    localStorage.setItem('URL', location.pathname + location.search)    

    const {slug} = useParams()

    const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))
    const carat = new URLSearchParams(location.search).get("carat");
    const JewelryId = new URLSearchParams(location.search).get("jid");
    const DiamondId = new URLSearchParams(location.search).get("did");
    const Subcategory = new URLSearchParams(location.search).get("sub");
    const metal = new URLSearchParams(location.search).get("metal");
    const collection = new URLSearchParams(location.search).get("collection");

    const buildYourOwn = location.pathname.split("/")[2] == "Build-Your-Own";
    const breadCrumpCat = location.pathname.split("/")[2];

    // console.log("buildYourOwn",buildYourOwn)
    // console.log("collection",collection)
    

    const [settingType, setSettingType] = useState([])
    const [diamondType, setDiamondType] = useState([])
    const [metalType, setMetalType] = useState([])

    const [SelectedMetalType, setSelectedMetalType] = useState([])
    const [SelectedDiamondType, setSelectedDiamondType] = useState([]// sid == undefined ? [] : b64_to_utf8([sid])
    )
    // const [SelectedDiamondType, setSelectedDiamondType] = useState( sid == undefined ? [] : b64_to_utf8([sid]))
    const [getAllData, setGetAllData] = useState('')
    const [SelectedSettingType, setSelecteSettingType] = useState([])
    const [SelectedCategory, setSelectedCategory] = useState(cid)
    const [SelectedWeight, setSelectedWeight] = useState('')
    const [SelectedPrice, setSelectedPrice] = useState('')
    const [SelectedSort, setSelectedSort] = useState({ name: 'Newest', order_column: 'created_at', sort: 'DESC', type: 'sorting' })

    const multiselectRef = useRef()
    const weightselectRef = useRef()
    const categoryselectRef = useRef()
    const priceselectRef = useRef()
    const sortselectRef = useRef()


    const [weight, setWeight] = useState([
        { name: 'Less Than 2 Grams', min: '', max: '2', type: 'weight' },
        { name: '2 Grams To 4 Grams', min: '2', max: '4', type: 'weight' },
        { name: '4 Grams To 6 Grams', min: '4', max: '6', type: 'weight' },
        { name: '6 Grams And Above', min: '6', max: '', type: 'weight' },
    ])
    const [sorting, setSorting] = useState([
        { name: 'Low To High', order_column: 'settingprice', sort: 'ASC', type: 'sorting' },
        { name: 'High To Low', order_column: 'settingprice', sort: 'DESC', type: 'sorting' },
        { name: 'Newest', order_column: 'created_at', sort: 'DESC', type: 'sorting' },
    ])

    const [price, setPrice] = useState([
        { name: 'Under $100', min: '', max: '100', type: 'price' },
        { name: '$100 To $200', min: '100', max: '200', type: 'price' },
        { name: '$200 To $300', min: '200', max: '300', type: 'price' },
        { name: '$300 To $400', min: '300', max: '400', type: 'price' },
        { name: '$400 To $500', min: '400', max: '500', type: 'price' },
        { name: '$500 And Above', min: '500', max: '', type: 'price' },
    ])

    // const [categoryArr,setcategoryArr]=useState([])
    // const [pendantArr,setpendantArr]=useState([])

    const [category,setcategory]=useState([
        { name: 'Ring', value: 'ring', type: 'ring' },
        { name: 'Pendant', value: 'pendant', type: 'pendant' },
    ])
    let defaultselectedcat={ name: 'Ring', value: 'ring', type: 'ring' };
    if(slug == "Pendant" || slug == "pendant"){
        defaultselectedcat={ name: 'Pendant', value: 'pendant', type: 'pendant' };
    }

    // const [defaultCategory,setDefaultCategory]=useState({ name: 'Ring', value: 'ring', type: 'ring' })
    const [defaultCategory,setDefaultCategory]=useState(defaultselectedcat)

    
    const [jewellryData, setJewellryData] = useState([])
    const [IsWishlistLoading, setIsWishlistLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [List, setList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [filterCount, setFilterCount] = useState();
    const [Currentpage, setCurrentpage] = useState(1);
    const [length, setLength] = useState(28);
    const [IsFilterLoading, setIsFilterLoading] = useState(false);

    const PER_PAGE = length;
    const count = Math.ceil(filterCount / PER_PAGE);
    const timeout = useRef()
    const [jewelryDetail, setjewelryDetail] = useState('')
    const [DiamondDetail, setDiamondDetail] = useState([])
    

    const token = localStorage.getItem('token')
    const is_b2b = JSON.parse(localStorage.getItem('data'))
    const [Wishlist, setWishlist] = useState('');
    
    // useEffect(() => {
    //     // if (params?.slug != undefined) {
    //     //     console.log("state", decodeURIComponent(escape(window.atob(params.slug))));
    //     //     let categoryId = decodeURIComponent(escape(window.atob(params.slug)))

    //     // }
    //     // CallCategoryListApi()
    //     CallFiltersApi()
    //     if (DiamondId != null) {
    //         CallDiamondDetailApi()
    //     }
    //     if (JewelryId != null) {
    //         CalljewelryDetailApi()
    //     }
    //     if (token != null) {
    //         CallCartCountApi()
    //     }
    // }, [Currentpage,SelectedCategory])

    React.useEffect(() => {        
         CallFiltersApi(); 

        if (DiamondId != null) {
            CallDiamondDetailApi()
        }
        if (JewelryId != null) {
        }
        if (token != null) {
            CallCartCountApi()
        }
        getGlobalSettings()            
      }, [
        Currentpage,SelectedCategory
      ])



      const getGlobalSettings = async () => {   
        await axios.post(BASE_URL + GetAPIUrl.GLOBALMARKUPTAG_URL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                if(response?.data?.type == "success")
                {
                    if(response?.data?.data.length > 0 )
                    {
                        setGlobalMarkup(response?.data?.data[0].side_stone_mark_up);
                        setglobalTagFact(response?.data?.data[0].side_stone_tag_fact);
                        setGlobalJewelryMargin(response?.data?.data[0].b2c_margin_rate);
                        CalljewelryDetailApi(response?.data?.data[0].b2c_margin_rate)
                    }
                }  
            }).catch(error => {
            }).finally(() => {

            })
    }

    // const CallCategoryListApi = async () => {

    //     await axios.post(BASE_URL + GetAPIUrl.CATEGORY_URL, null, {
    //         headers: {
    //             'Accept': 'application/json',
    //         }
    //     })
    //         .then(response => {
    //             if (response.data.success == true) {
    //                 let catArray=[];
    //                 let pendantArr=[];
    //                 response?.data?.data?.map((item) => {
    //                     if(item.name.toLowerCase() == 'engagement rings' 
    //                     || item.name.toLowerCase() == 'engagement ring'
    //                     || item.name.toLowerCase() == 'wedding ring'
    //                     || item.name.toLowerCase() == 'wedding rings'
    //                     || item.name.toLowerCase() == 'rings'
    //                     || item.name.toLowerCase() == 'ring'
    //                     ) {
    //                         catArray.push(item.id)
    //                     }
    //                     if(item.name.toLowerCase() == 'pendant' 
    //                     || item.name.toLowerCase() == 'pendants'
    //                     ) {
    //                         pendantArr.push(item.id)
    //                     }
    //                 })
    //                 console.log("catArray",catArray)
    //                 console.log("pendantArr",pendantArr)
    //                 setcategoryArr(catArray)
    //                 setpendantArr(pendantArr)
    //             }
    //         }).catch(error => {
    //             // ShowErrorMessage(error.message)
    //         }).finally(() => {

    //         })
    // }


    const [globalJewelryMargin,setGlobalJewelryMargin] = useState(0);
    const [globalMarkup,setGlobalMarkup] = useState(0);
    const [globalTagFact,setglobalTagFact] = useState(1);

    const CallCartCountApi = async () => {
        const controller = new AbortController();

        await axios.get(BASE_URL + GetAPIUrl.CARTWISHLISTCOUNT_URL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                
                if (response.data.success == true) {
                    setWishlist(response?.data?.wishlist?.jewelry_ids)
                    localStorage.setItem('wishlistcount', JSON.stringify(response.data.wishlist.count))
                    localStorage.setItem('cartcount', JSON.stringify(response.data.cart.count))
                    $(function () {
                        $('.addtocartCount').html(JSON.stringify(response.data.cart.count));
                        $('.addtowishlistCount').html(JSON.stringify(response.data.wishlist.count));
                    })
                } else {
                    localStorage.setItem('wishlistcount', JSON.stringify(0))
                    localStorage.setItem('cartcount', JSON.stringify(0))
                }
            }).catch(error => {
            }).finally(() => {

            })
        controller.abort()
    }


    const CallDiamondDetailApi = async () => {
        setIsLoading(true)
        let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;
        const controller = new AbortController();

        axios.get(BASE_URL + GetAPIUrl.DIAMONDDETAIL_URL + `/${DiamondId}`+ '?is_b2b=' + getdata+'&currency_id='+currency_id)
            .then(response => {
                if (response.data.success == true) {
                    setDiamondDetail(response.data.data);
                } else {
                    ShowErrorMessage(response.data.message)
                }
            }).catch(error => {
                ShowErrorMessage(error.message)
            }).finally(() => {
                setIsLoading(false)
            })
        controller.abort()
    }


    const CalljewelryDetailApi = async (globalmargin) => {
        const controller = new AbortController();

        setIsLoading(true)
        if(JewelryId != null){
            await axios.get(BASE_URL + GetAPIUrl.JEWELRYDETAIL_URL + `/${JewelryId}`)
                .then(response => {
                    if (response.data.success == true) {
                        setjewelryDetail(response?.data?.data);
                    } else {
                        ShowErrorMessage(response.data.message)
                    }
                })
                .catch(error => {
                    ShowErrorMessage(error.message)
                }).finally(() => {
                    setIsLoading(false)
                })
            controller.abort()
        }else{
            setIsLoading(false)
        }
    }

    const handleChange = (e, p) => {
        setList([]);
        setFilterList([]);   
        setCurrentpage(p);
        setIsLoading(true)
    };

    // let debouncedData = useDebounced(SelectedMetalType,500)

    // function useDebounced ( value , timeout ) {
    //     let [ debouncedValue , setDebouncedValue ] = useState ( value )      
    //     useEffect (() => {
    //       let timeoutId = setTimeout (() => {
    //         setDebouncedValue( value )
    //       } , timeout)
    //       return ()=> {
    //         clearTimeout(timeoutId)
    //      }
    //    } , [ value , timeout ] )
    //    console.log("debouncedValue",debouncedValue);
    //     return debouncedValue
    // }


    const handleMetalValues = (selectedList, selectedItem) => {

        let array = [...SelectedMetalType];
        let selectedIndex = array.findIndex((d) => d.id == selectedItem.id)
        if (array?.length > 0 && selectedIndex >= 0) {
            array.splice(selectedIndex, 1);
        } else {
            array.push(selectedItem);
        }
        // useDebounced(array,500)
        setSelectedMetalType(array)
        setIsLoading(true)
        getJewellryData(array, SelectedDiamondType, SelectedSettingType, SelectedWeight, SelectedPrice, SelectedSort, shape)

    };

    const handleDiamondValues = (selectedList, selectedItem) => {
        let array = [...SelectedDiamondType];
        let selectedIndex = array.findIndex((d) => d.id == selectedItem.id)
        if (array?.length > 0 && selectedIndex >= 0) {
            array.splice(selectedIndex, 1);
        } else {
            array.push(selectedItem);
        }
        setSelectedDiamondType(array)
        setIsLoading(true)

        getJewellryData(SelectedMetalType, array, SelectedSettingType, SelectedWeight, SelectedPrice, SelectedSort, shape)
    };

    const handleSettingValues = (selectedList, selectedItem) => {
        let array = [...SelectedSettingType];
        let selectedIndex = array.findIndex((d) => d.id == selectedItem.id)
        if (array?.length > 0 && selectedIndex >= 0) {
            array.splice(selectedIndex, 1);
        } else {
            array.push(selectedItem);
        }

        setSelecteSettingType(array)
        setIsLoading(true)
        getJewellryData(SelectedMetalType, SelectedDiamondType, array, SelectedWeight, SelectedPrice, SelectedSort, shape)

    };
    const handleCategoryValues = (selectedList, selectedItem) => {
        // setSelectedCategory(selectedItem)
        if(selectedItem.name=="Pendant"){
            setSelectedCategory(localStorage.getItem('PendantID'))
            setFilterCount();
            setList([]);
            setFilterList([]);
            // getJewellryData(SelectedMetalType, SelectedDiamondType, SelectedSettingType, SelectedWeight, '', SelectedSort, shape)
            window.history.replaceState(null, "New Page Title", DiamondId == null ? process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/Pendant?cid=${localStorage.getItem('PendantID')}` : process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/Pendant?cid=${localStorage.getItem('PendantID')}&did=${DiamondId}`)
        }
        if(selectedItem.name=="Ring"){
            setSelectedCategory(localStorage.getItem('EngagmentID'))
            setFilterCount();
            setList([]);
            setFilterList([]);
            // getJewellryData(SelectedMetalType, SelectedDiamondType, SelectedSettingType, SelectedWeight, '', SelectedSort, shape)
            window.history.replaceState(null, "New Page Title", DiamondId == null ? process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${localStorage.getItem("EngagmentName")}?cid=${localStorage.getItem("EngagmentID")}` : process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${localStorage.getItem("EngagmentName")}?cid=${localStorage.getItem("EngagmentID")}&did=${DiamondId}`)
        }
        // console.log(selectedItem);
        setDefaultCategory(selectedItem)
    };
    const handleRemoveCategoryValues = (selectedList, selectedItem) => {
        categoryselectRef.current.resetSelectedValues();
        setSelectedCategory('')
        setIsLoading(true)
        // getJewellryData(SelectedMetalType, SelectedDiamondType, SelectedSettingType, '', SelectedPrice, SelectedSort, shape)
        getJewellryData(SelectedMetalType, SelectedDiamondType, SelectedSettingType, SelectedWeight, '', SelectedSort, shape)
    };
    const handleWeightValues = (selectedList, selectedItem) => {
        setSelectedWeight(selectedItem)
        getJewellryData(SelectedMetalType, SelectedDiamondType, SelectedSettingType, selectedItem, SelectedPrice, SelectedSort, shape)

    };
    const handleRemoveWeightValues = (selectedList, selectedItem) => {
        weightselectRef.current.resetSelectedValues();
        setSelectedWeight('')
        setIsLoading(true)
        getJewellryData(SelectedMetalType, SelectedDiamondType, SelectedSettingType, '', SelectedPrice, SelectedSort, shape)

    };

    const handlePriceValues = (selectedList, selectedItem) => {
        setSelectedPrice(selectedItem)
        setIsLoading(true)
        getJewellryData(SelectedMetalType, SelectedDiamondType, SelectedSettingType, SelectedWeight, selectedItem, SelectedSort, shape)

    };

    const handleRemovePriceValues = (selectedList, selectedItem) => {
        priceselectRef.current.resetSelectedValues();
        setSelectedPrice('')
        setIsLoading(true)
        getJewellryData(SelectedMetalType, SelectedDiamondType, SelectedSettingType, SelectedWeight, '', SelectedSort, shape)
    };
    const handleSortingValues = (selectedList, selectedItem) => {
        setSelectedSort(selectedItem)
        setIsLoading(true)
        getJewellryData(SelectedMetalType, SelectedDiamondType, SelectedSettingType, SelectedWeight, SelectedPrice, selectedItem, shape)
    };


    const CallFiltersApi = () => {

        setIsFilterLoading(true)
        axios.get(BASE_URL + GetAPIUrl.JEWELRY_FILTER_URL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    setIsFilterLoading(false)
                    // console.log("res = = =>", response.data.data.Metal_type);
                    setDiamondType(response.data.data.Shape);
                    let shapedata = []
                    let Metaldata = []
                    if (shape != null) {
                        shapedata = response?.data?.data?.Shape.filter((item) => {

                            if (item?.id == shape) {
                                return item
                            }
                        })
                        setSelectedDiamondType(shapedata)
                    }
                    if (metal != null) {
                        Metaldata = response?.data?.data?.Metal_Type?.filter((item) => {

                            if (item?.id == metal) {
                                return item
                            }
                        })
                        setSelectedMetalType(Metaldata)
                    }
                    
                    if(buildYourOwn == true){
                        let filteredArray = response.data.data.Metal_Type.filter(function(e) { return e?.name.toLowerCase() !== 'silver' })
                        setMetalType(filteredArray)
                    }else{
                        let filteredArray = response.data.data.Metal_Type
                        setMetalType(filteredArray)
                    }

                    // setMetalType(response.data.data.Metal_Type)                    

                    setSettingType(response.data.data.Setting_Type)
                    getJewellryData(Metaldata.length > 0 ? Metaldata : SelectedMetalType, shapedata.length > 0 ? shapedata : SelectedDiamondType, SelectedSettingType, SelectedWeight, SelectedPrice, SelectedSort, shape)

                } else {
                    setIsFilterLoading(false)
                }
            })
    }


    const getJewellryData = async (SelectedMetalType, SelectedDiamondType, SelectedSettingType, SelectedWeight, SelectedPrice, SelectedSort, shape) => {
        const getMetalId = SelectedMetalType?.map(item => item.id).join(',');
        const getDiamondId = SelectedDiamondType?.map(item => item.id).join(',');
        const getSettingId = SelectedSettingType?.map(item => item.id).join(',');

        let minPrice = SelectedPrice.min
        let maxPrice = SelectedPrice.max

        let minWeight = SelectedWeight.min
        let maxWeight = SelectedWeight.max

        let order_column = SelectedSort.order_column
        let sort = SelectedSort.sort
        let count = length * (Currentpage - 1)

        var form = new FormData();
        form.append("draw", Currentpage);
        form.append("start", Currentpage == 1 ? 0 : count);
        form.append("length", length);
        form.append("metal_type", getMetalId === undefined ? '' : getMetalId);
        form.append("metal_stamp", "");
        form.append("diamond_type", getDiamondId === undefined ? '' : getDiamondId);
        form.append("setting_type", getSettingId === undefined ? '' : getSettingId);
        form.append("weight_min", minWeight === undefined ? '' : minWeight);
        form.append("weight_max", maxWeight === undefined ? '' : maxWeight);
        form.append("price_min", minPrice === undefined ? '' : minPrice);
        form.append("price_max", maxPrice === undefined ? '' : maxPrice);
        form.append("order_column", order_column === undefined ? '' : order_column);
        form.append("sort", sort === undefined ? '' : sort);
        // form.append("category_id", cid === null ? '' : cid);
        form.append("category_id", SelectedCategory);
        form.append("subcategory_id", Subcategory === null ? '' : Subcategory);

        if(breadCrumpCat == "onlysetting"){
            form.append("finish_status",'Semi-Finished');
        }else{
            form.append("finish_status", buildYourOwn == true ? 'Semi-Finished' : 'Finished');
        }        
        form.append("center_stone_caret", carat == null ? '' : carat);
        form.append("center_stone_shape", DiamondId == null ? '' : shape == null ? '' : shape);
        form.append("is_b2b", is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b);
        form.append("collection_name", collection == null ? '' : collection);
        form.append("currency_id", defaultCurrency?.id == null ? null : defaultCurrency?.id);

        await axios.post(BASE_URL + GetAPIUrl.JEWELRYLIST_URL, form)
            .then((json) => {
                if (json.status == 200) {
                    setFilterCount(json?.data?.recordsFiltered);
                    setList(json?.data?.data);
                    setFilterList(json?.data?.data);                   
                    setIsLoading(false);     
                } else {
                    ShowErrorMessage(json.data.message)
                }
            })
            .catch(error => {
                setIsLoading(false);
                ShowErrorMessage(error.message)
            });
    }


    const removeAllData = () => {
        multiselectRef.current.resetSelectedValues();
        weightselectRef.current.resetSelectedValues();
        priceselectRef.current.resetSelectedValues();
        sortselectRef.current.resetSelectedValues();
        if (DiamondId == null) {
            setSelectedDiamondType([])
            setSelectedMetalType([])
            setShape(null)
            getJewellryData([], [], [], '', '', SelectedSort, null)
        }else{
            getJewellryData([], [], [], '', '', SelectedSort, shape)
        }
        setSelecteSettingType([])
        setSelectedWeight('')
        setSelectedPrice('')
        setSelectedSort(SelectedSort)
        setIsLoading(true)

        // localStorage.removeItem('Shape')
        // localStorage.removeItem('MetalType')
        // setAllNames([])
        // setJewellryData(alljewelleryData)
    }

    const CallAddtoWishlistApi = async (item) => {
        setIsWishlistLoading(true)
        const controller = new AbortController();
        var form = new FormData();

        // console.log("item",item)
        form.append("jewelryid", item?.id);
        // form.append("ringsize", JewelryId);
        form.append('centerStonePrice', null)
        form.append('sideDiaGrpId', null)
        form.append('settingPrice', item.settingprice)
        form.append('currency_id', defaultCurrency?.id == null ? null : defaultCurrency?.id)

        await axios.post(BASE_URL + GetAPIUrl.WISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    setIsWishlistLoading(false)
                    // ShowMessage(response.data.message)
                    CallCartCountApi()
                } else {
                    setIsWishlistLoading(false)
                    ShowErrorMessage(response.data.message)
                }
            })
            // await fetch( GetAPIUrl.WISHLIST_URL, {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Accept": "application/json",
            //         "Authorization": 'Bearer' + ' ' + token
            //     },
            //     body: JSON.stringify(data)
            // }).then(response => response.json())
            //     .then(data => {
            //         console.log("wishlisr resposnes", data);
            //         if (data.success == true) {
            //             setIsWishlistLoading(false)
            //             ShowMessage(data.message)
            //         } else {
            //             setIsWishlistLoading(false)
            //             ShowErrorMessage(data.message)
            //         }
            //     })


            .catch(error => {

                ShowErrorMessage(error.message)
                setIsWishlistLoading(false)
                // window.location.pathname = '/Login'

            });
        controller.abort()
    }

    const confirmRemovejewelry = (item) => {

        confirmRemove("Are you Sure","You want to remove item from wishlist?").then((result) => {
            if (result == true) {
                CallDeletejewelry(item)

            }
        }

        )
    }
    const CallDeletejewelry = async (item) => {
        
        const controller = new AbortController();
        var form = new FormData();

        form.append("id", "");
        form.append("jewelryid", item?.id);
        form.append("diamondid", "");

        // console.log("fff", [...form]);
        await axios.post(BASE_URL + GetAPIUrl.DELETEWISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {

                    // ShowMessage(response.data.message)
                    CallCartCountApi()
                } else {

                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)

            });
        controller.abort()
    }

    $(function () {

        var $cache = $('#getFixed');
        var $head = $('.header-fixscroll');
        function fixDiv() {
            if ($(window).scrollTop() > 100) {

                return (
                    $cache.css({

                        'position': 'fixed',
                        'top': '0px',
                        'width': '100%',
                        'z-index': '1045',
                        'background': '#fff',
                        'box-shadow': '#656565 0px 1px 20px -8px',
                        'padding-bottom': '5px',
                        'padding-top': '5px',
                    }),
                    $head.css({
                        'display': 'none'
                    })
                )
            }
            else {
                return (
                    $cache.css({
                        'position': 'relative',
                        'top': 'auto',
                        'z-index': 'inherit',
                        'box-shadow': 'none',
                        'padding-bottom': 'auto',
                        'background': 'transparent',
                    }),
                    $head.css({
                        'display': 'block'
                    })
                )
            }
        }
        var lastScrollTop = 0;
        $(window).scroll(function () {
            fixDiv();
            var st = $(this).scrollTop();
            if (st > lastScrollTop) {
                $('.header-fixscroll').hide();
                $('#getFixed').show();
            } else {
                $('.header-fixscroll').show();
                $('#getFixed').hide();
            }
            lastScrollTop = st;
            if (st == 0) {
                $('#getFixed').show();
            }
        });


        if ($(window).width() < 991) {
            $('.filterdiv').addClass('f1div');
            $('#filter-modal').addClass('modal fade search-modal modal-bg-fff');
            $('.f2div').addClass('modal-dialog momodel modal-fluid');
            $('.f3div').addClass('modal-content');
            $('.f4div').addClass('modal-body');
            $('.more-fix').addClass('more-filterbtn-div pad-0lf');
            $('.header-set').removeClass('header-set');
        } else {
            $('.filterdiv').removeClass('f1div');
            $('#filter-modal').removeClass('modal fade search-modal modal-bg-fff');
            $('.f2div').removeClass('modal-dialog momodel modal-fluid');
            $('.f3div').removeClass('modal-content');
            $('.f4div').removeClass('modal-body');
            $('label').removeClass('click-shape click-price click-color click-carat click-quality');
            $('.more-fix').removeClass('more-filterbtn-div pad-0lf');
        }

        // $(document).ready(function () {
        //     $('.selectpicker').selectpicker('toggle');
        // });

        $('.f-click').on({
            'click': function () {
                // $('.change-image').attr('src','images/product/p1.png');
                $(this).parent().closest('.find-img').find('.change-image').attr('src', $(this).data('src'));
                // $(this).toggleClass('active-1');
                $(this).addClass("active-1").siblings().removeClass("active-1");
            }
        });

    })
    // $(function () {
    //     window.$('.selectpicker').selectpicker('toggle')
    //     window.addEventListener('scroll', handleScroll, true);

    //     // Remove the event listener
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll, true);
    //     };
    // })
    let sum = parseFloat(jewelryDetail?.settingprice) + parseFloat(DiamondDetail?.diamondprice)
    
    useEffect(()=>{
        if(styleId != '' && styleId != null && styleId > 0){
            if(settingType?.length > 0){
              let settingId = settingType.filter(val=> val?.id == styleId);
              if(settingId?.length > 0){
                  setIsLoading(true)
                  setSelecteSettingType(settingId);
                  setTimeout(() => {
                      getJewellryData(SelectedMetalType, SelectedDiamondType, settingId, SelectedWeight, SelectedPrice, SelectedSort, shape)                
                  }, 2000);
              }
            }
        }
    },[settingType]);

    return (
        
        <div className="mycontainer" >
            {/* {IsWishlistLoading &&
                < LoadingSpinner />
            }
            {
                isLoading &&
                < LoadingSpinner />
            } */}
            <div>
                <div className="container container-main">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb" style={{fontSize:"14px",paddingBottom:0}}>
                            <li className="breadcrumb-item">
                                <a href={process.env.PUBLIC_URL + '/'} className='desk-top-title-sm a-taglink'>
                                    <i className="fa fa-home" aria-hidden="true"></i>
                                </a>
                            </li>
                            {/* <li className="breadcrumb-item active" aria-current="page">
                                Jewelry
                                </li> */}
                            {
                               buildYourOwn == true ?
                              
                                <li className="breadcrumb-item active" aria-current="page">
                                    Build Your Own
                                    </li> 
                                    : (breadCrumpCat != undefined || breadCrumpCat != null) ?
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {decodeURIComponent(breadCrumpCat)}
                                            {collection != null ? decodeURIComponent(collection) : ""}
                                        </li>
                                    : (collection != undefined || collection != null) ?
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {breadCrumpCat != undefined ? decodeURIComponent(breadCrumpCat) : null}
                                            {collection != null ? decodeURIComponent(collection) : ""}
                                        </li>
                                    :
                                    null
                            }
                        </ol>
                    </nav>
                    {/* <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link
                                    className=""
                                    aria-current="page"
                                    to={'/'}
                                >
                                    <i className="fa fa-home" aria-hidden="true"></i>
                                </Link>
                            </li>
                            {Category != '' &&
                                <li className="breadcrumb-item">{Category == '' ? "" : Category?.categoryId == "21" && "Engagment Ring" || Category?.categoryId == "41" && "Wedding Ring"}</li>
                            }
                            <li className="breadcrumb-item active" aria-current="page">{Category == '' ? "Setting" : Category?.categoryName}</li>
                        </ol>
                    </nav> */}
                </div>
            </div>
            {buildYourOwn == true &&

                <section className="mobile-view-none" >
                    <div className="container container-main">
                        <div className="wizard2-steps mb-3">
                            <div className="step wizard2-steps-heading active-step keep-left">
                                <div className="node">
                                    <div className="node-skin">
                                        <div className="cont">
                                            <h2 className="nostyle-heading">Build Your Own Ring</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {JewelryId == null ?
                                <div className="cyo-bar-step active-step step step-item ">
                                    <div className="node" >
                                        <div className="node-skin">
                                            <div className="pho">
                                                <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg"} alt="setting" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                            </div>
                                            <div className="cont">
                                                <div className="action help-tips">
                                                    <a href="#" className="td-u bar-action line1-doted-2"></a>
                                                </div>
                                                <div className="heading">
                                                    <div className="action help-tips">
                                                        <a href="#" className="td-u bar-action">Choose</a>
                                                    </div>
                                                    <h2 className="nostyle-heading">setting</h2>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="cyo-bar-step step step-item active-step keep-left">
                                    <div className="node" >
                                        <div className="node-skin">
                                            <div className="pho">

                                                <img style={{ background: 'rgba(76, 175, 80, 0.1)' }} src={jewelryDetail?.default?.path == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg" : jewelryDetail?.default?.path} alt="setting" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>


                                            </div>
                                            <div className="cont">
                                                <div className="heading"><h2 className="nostyle-heading">Setting</h2>
                                                    <div className="action help-tips">
                                                        <div className="td-u bar-action">{jewelryDetail?.title}
                                                            <aside>Price :{defaultCurrency?.sign ?? "$"}{jewelryDetail?.settingprice}</aside>
                                                        </div>
                                                    </div>
                                                    <div className="action double-action" style={{ display: 'flex' }}>
                                                        <a target="_blank" href={process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${jewelryDetail?.title}?jid=${jewelryDetail?.id}`}>
                                                        View Details
                                                            {/* <button variant="primary" type="button" className="">View Details</button> */}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {DiamondId == null || DiamondId == null ?
                                <div className="cyo-bar-step step step-item ">
                                    <div className="node" >
                                        <div className="node-skin">
                                            <div className="pho">
                                                <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg"} alt="diamond" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                            </div>
                                            <div className="cont">
                                                <div className="action help-tips">
                                                    <a href="#" className="td-u bar-action line1-doted-2"></a>
                                                </div>
                                                <div className="heading">
                                                    <div className="action help-tips">
                                                        <a href="#" className="td-u bar-action">Choose</a>
                                                    </div>
                                                    <h2 className="nostyle-heading">Diamond</h2>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                :
                                <div className="cyo-bar-step step step-item  active-step keep-left">
                                    <div className="node" >
                                        <div className="node-skin">
                                            <div className="pho">
                                                <img src={DiamondDetail.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : DiamondDetail.image} alt="diamond" style={{ backgroundColor: "white" }} onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                            </div>
                                            <div className="cont">
                                                <div className="action help-tips">
                                                    <a href="#" className="td-u bar-action line1-doted-2"></a>
                                                </div>
                                                <div className="heading">
                                                    <h2 className="nostyle-heading">Diamond</h2>
                                                    <div className="action help-tips">
                                                        <div className="td-u bar-action">{DiamondDetail.title}
                                                            <aside>Price :{defaultCurrency?.sign ?? "$"}{DiamondDetail.diamondprice}</aside></div>
                                                    </div>
                                                    <div className="action double-action" style={{ display: 'flex' }}>
                                                        <a target='_blank' href={process.env.PUBLIC_URL + `/DiamondDetails/Build-Your-Own/${DiamondDetail.title}?did=${DiamondDetail.id}`}>
                                                        View Details
                                                            {/* <button variant="primary" type="button" className="">View Details</button> */}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {JewelryId != null && DiamondId != null ?
                                <div className="step step-item invariant-color active-step keep-left">
                                    <div className="node">
                                        <div className="node-skin">
                                            <div className="pho">
                                                <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/ring.svg"} alt="ring" style={{ backgroundColor: "white" }} onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                            </div>
                                            <div className="cont">
                                                <div className="action help-tips">TOTAL</div>
                                                <div className="heading"><h2 className="nostyle-heading">{defaultCurrency?.sign ?? "$"}{sum.toFixed(2)}</h2></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="step step-item invariant-color">
                                    <div className="node">
                                        <div className="node-skin">
                                            <div className="pho">
                                                <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/ring.svg"} alt="ring" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                            </div>
                                            <div className="cont">
                                                <div className="heading"><h2 className="nostyle-heading">TOTAL</h2></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </section>

            }
            <div className='filters-data' style={{}}>
                <div className="list-filter list-filter-with" id="getFixed" style={{ maxWidth: '100%', }}>
                    <div className="container container-main" >
                        <div className="row filterdiv" >
                            <div className="col-lg-12">
                                <div id="filter-modal" style={{ paddingLeft: "0px", zIndex:1111 }}>
                                    <div className="f2div">
                                        <div className="f3div">
                                            <div className="modal-header h-hide mob-filter-btn filter-modal-header">
                                                <h5 className="modal-title mob-filter-tit-35">Filter</h5>
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true">
                                                    <i className="fa fa-angle-left" aria-hidden="true"></i></span></button>
                                                <span className="clear-all-mob clearfilter" onClick={() => removeAllData()}>Clear All</span>
                                            </div>
                                            <div className="f4div">
                                                <div className="dropdown bootstrap-select show-tick" style={{ marginRight: 10 }}>
                                                    <Multiselect
                                                        placeholder='Select Metal type'
                                                        ref={multiselectRef}
                                                        showCheckbox={true}
                                                        customCloseIcon={<AiOutlineClose size={15} style={{ paddingLeft: 2, color: 'red' }} />}
                                                        style={{
                                                            chips: { background: 'white', color: Colors.brown, textDecoration: 'underline black', fontSize: 14 },
                                                            searchBox: { "borderRadius": "0px" },

                                                        }}
                                                        showArrow
                                                        loading={IsFilterLoading ? true : false}
                                                        options={metalType}
                                                        hideSelectedList={true}
                                                        selectedValues={SelectedMetalType}
                                                        onSelect={handleMetalValues}
                                                        onRemove={handleMetalValues}
                                                        displayValue="name" />
                                                </div>
                                                <div className="dropdown bootstrap-select show-tick" style={{ marginRight: 10 }}>
                                                    <Multiselect
                                                        placeholder='Select Diamond type'
                                                        ref={multiselectRef}
                                                        customCloseIcon={<AiOutlineClose size={15} style={{ paddingLeft: 2, color: 'red' }} />}
                                                        style={{
                                                            chips: { background: 'white', color: Colors.brown, textDecoration: 'underline black', fontSize: 14 },
                                                            searchBox: { "borderRadius": "0px" },

                                                        }}
                                                        disable={DiamondId != null ? true : false}
                                                        showArrow
                                                        showCheckbox={true}
                                                        loading={IsFilterLoading ? true : false}
                                                        hideSelectedList={true}
                                                        selectedValues={SelectedDiamondType}
                                                        onSelect={handleDiamondValues}
                                                        onRemove={handleDiamondValues}
                                                        options={diamondType}
                                                        displayValue="name" />
                                                    {/* <Multiselect placeholder='Select Diamond type' showArrow options={diamondType} displayValue="name" /> */}
                                                </div>
                                                <div className="dropdown bootstrap-select show-tick" style={{ marginRight: 10 }}>
                                                    <Multiselect

                                                        placeholder='Select Setting type'
                                                        ref={multiselectRef}
                                                        customCloseIcon={<AiOutlineClose size={15} style={{ paddingLeft: 2, color: 'red' }} />}
                                                        style={{
                                                            chips: { background: 'white', color: Colors.brown, textDecoration: 'underline black', fontSize: 14 },
                                                            searchBox: { "borderRadius": "0px" },

                                                        }}
                                                        showArrow
                                                        showCheckbox={true}
                                                        loading={IsFilterLoading ? true : false}
                                                        hideSelectedList={true}
                                                        selectedValues={SelectedSettingType}
                                                        onSelect={handleSettingValues}
                                                        onRemove={handleSettingValues}
                                                        options={settingType}
                                                        displayValue="name" />
                                                    {/* <Multiselect placeholder='Select Setting type' showArrow options={settingType} displayValue="name" /> */}
                                                </div>

                                                {/* {
                                                    buildYourOwn == true ?
                                                <div className="dropdown bootstrap-select show-tick" style={{ marginRight: 10 }}>
                                                    <Multiselect singleSelect
                                                        placeholder='Select Category'
                                                        customCloseIcon={<AiOutlineClose size={15} style={{ paddingLeft: 2, color: 'red', }} />}
                                                        style={{
                                                            chips: { background: 'white', color: Colors.brown, fontSize: 14 },
                                                            searchBox: { "borderRadius": "0px" },

                                                        }}
                                                        ref={categoryselectRef}
                                                        selectedValues={[defaultCategory]}
                                                        showArrow={true}
                                                        loading={IsFilterLoading ? true : false}
                                                        onSelect={handleCategoryValues}
                                                        onRemove={handleRemoveCategoryValues}
                                                        options={category}
                                                        displayValue="name" />
                                                </div>
                                                :
                                                null
                                                    } */}


                                                <div className="dropdown bootstrap-select show-tick" style={{ marginRight: 10 }}>
                                                    <Multiselect singleSelect
                                                        placeholder='Select Weight type'
                                                        customCloseIcon={<AiOutlineClose size={15} style={{ paddingLeft: 2, color: 'red', }} />}
                                                        style={{
                                                            chips: { background: 'white', color: Colors.brown, fontSize: 14 },
                                                            searchBox: { "borderRadius": "0px" },

                                                        }}
                                                        ref={weightselectRef}

                                                        showArrow
                                                        loading={IsFilterLoading ? true : false}
                                                        onSelect={handleWeightValues}
                                                        onRemove={handleRemoveWeightValues}
                                                        options={weight}
                                                        displayValue="name" />
                                                    {/* <Multiselect placeholder='Select Weight type' showArrow options={settingType} displayValue="name" /> */}
                                                </div>


                                                <div className="dropdown bootstrap-select show-tick" style={{ marginRight: 10 }}>
                                                    <Multiselect singleSelect
                                                        placeholder='Select Price type'
                                                        customCloseIcon={<AiOutlineClose size={15} style={{ paddingLeft: 2, color: 'red' }} />}
                                                        style={{
                                                            chips: { background: 'white', color: Colors.brown, fontSize: 14 },
                                                            searchBox: { "borderRadius": "0px" },

                                                        }}
                                                        ref={priceselectRef}
                                                        showArrow
                                                        loading={IsFilterLoading ? true : false}
                                                        onSelect={handlePriceValues}
                                                        onRemove={handleRemovePriceValues}
                                                        options={price}
                                                        displayValue="name" />

                                                </div>
                                                <div className="dropdown bootstrap-select show-tick">
                                                    <Multiselect singleSelect
                                                        placeholder='Sorting'
                                                        customCloseIcon={<AiOutlineClose size={15} style={{ paddingLeft: 2, color: 'red', }} />}
                                                        style={{
                                                            chips: { background: 'white', color: Colors.brown, fontSize: 14 },
                                                            searchBox: { "borderRadius": "0px" },

                                                        }}
                                                        showArrow
                                                        ref={sortselectRef}
                                                        onSelect={handleSortingValues}
                                                        options={sorting}
                                                        // selectedValues={SelectedSort}
                                                        displayValue="name" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 dd-block991">
                                <button type="button" data-toggle="modal" data-target="#filter-modal" className="btn more-filter-list text-center w-100">
                                    <span className="filter-option">Filter</span>
                                </button>
                            </div>
                            {/* <div style={{ display: 'contents' }}>
                            <select title="Sorting" className=" drop-w-200" defaultValue={0} id="27" onChange={sortingFun}>
                                <option value={0}>Sorting</option>
                                <option value={1}>Low To High</option>
                                <option value={2}>High To Low</option>
                                <option value={3}>Newest</option>
                            </select>
                        </div> */}
                        </div>
                    </div>
                    <div className="container container-main">
                        <div className="d-none d-lg-block">
                            <ul className="selcet-filter" style={{}}>
                                <li className="bg-none">
                                    <span style={{ color: '#23282D', }}>Results({filterCount})</span>
                                </li>
                                {SelectedMetalType?.map((item, index) => {
                                    return (
                                        <li key={Math.random()}>
                                            <span>{item.name}</span>
                                            <a className="select-span-a hand" onClick={() => handleMetalValues(SelectedMetalType, item)}>✖</a>
                                        </li>
                                    )
                                })}
                                {SelectedDiamondType?.map((item, index) => {
                                    return (
                                        <li key={Math.random()}>
                                            <span>{item.name}</span>
                                            <a className="select-span-a hand" onClick={() => handleDiamondValues(SelectedDiamondType, item)}>{DiamondId == null && '✖'}</a>
                                        </li>
                                    )
                                })}
                                {SelectedSettingType?.map((item, index) => {
                                    return (
                                        <li key={Math.random()}>
                                            <span>{item.name}</span>
                                            <a className="select-span-a hand" onClick={() => handleSettingValues(SelectedSettingType, item)}>✖</a>
                                        </li>
                                    )
                                })}
                                {SelectedWeight != '' ?

                                    <li >
                                        <span>{SelectedWeight.name}</span>
                                        <a className="select-span-a hand" onClick={() => handleRemoveWeightValues(SelectedWeight, SelectedWeight)}>✖</a>
                                    </li>
                                    : null
                                }
                                {SelectedPrice != '' ?

                                    <li >
                                        <span>{SelectedPrice.name}</span>
                                        <a className="select-span-a hand" onClick={() => handleRemovePriceValues(SelectedPrice, SelectedPrice)}>✖</a>
                                    </li>
                                    : null
                                }
                                {SelectedMetalType.length > 0 || SelectedDiamondType.length > 0 || SelectedSettingType.length > 0 || SelectedWeight != '' || SelectedPrice != '' ?
                                    <li className="clearall hand">
                                        <a onClick={() => removeAllData()}>Clear all</a>
                                    </li> : null}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <section className="jumbotron pt-3" >
                <div className="container container-main" >
                    <div className="product-page-div" onContextMenu={(e)=>e.preventDefault()} >
                        {
                        isLoading ?                           
                            <DiamondGridSkeleton />                             
                            : 
                              List?.length > 0 ?
                                List?.map((item, index) => {
                                    return (
                                    <JewelryCard key={index}
                                        slug={slug} 
                                        item={item} 
                                        Wishlist={Wishlist}
                                        token={token}
                                        globalJewelryMargin={globalJewelryMargin}
                                        globalMarkup={globalMarkup}
                                        globalTagFact={globalTagFact}
                                        confirmRemovejewelry={confirmRemovejewelry}
                                        CallAddtoWishlistApi={CallAddtoWishlistApi}
                                        buildYourOwn={buildYourOwn}
                                        DiamondId={DiamondId}
                                    />
                                    )
                                    // let getpath = item?.feature?.map((data, i) => { return data })
                                    // // console.log("Wishlist?.includes(item?.id)",Wishlist?.includes(item?.id));
                                    // return (
                                    //     <div key={Math.random()} className="product-div find-img">
                                    //         <div className="product-div-div"
                                    //         >
                                    //             <div className="product-div-box" >
                                    //                 {Wishlist != '' &&
                                    //                  Wishlist?.includes(item?.id) == true ?
                                    //                     <span className="heart-span hand" onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : confirmRemovejewelry(item)}>
                                    //                         <svg width="24" height="21" viewBox="0 0 24 21" fill="#ca9e79" xmlns="http://www.w3.org/2000/svg">
                                    //                             <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z"
                                    //                                 stroke="#ca9e79"
                                    //                                 strokeOpacity="0.65"
                                    //                                 strokeWidth="2"
                                    //                                 strokeLinecap="round"
                                    //                                 strokeLinejoin="round">
                                    //                             </path>
                                    //                         </svg>
                                    //                     </span>
                                    //                     :
                                    //                     <span className="heart-span hand" onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : CallAddtoWishlistApi(item)}>
                                    //                         <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    //                             <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z"
                                    //                                 stroke="#ca9e79"
                                    //                                 strokeOpacity="0.65"
                                    //                                 strokeWidth="2"
                                    //                                 strokeLinecap="round"
                                    //                                 strokeLinejoin="round"
                                    //                             >
                                    //                             </path>
                                    //                         </svg>
                                    //                     </span>
                                    //                 }
                                    //                 <a
                                    //                     className=""
                                    //                     target='_blank'
                                    //                     aria-current="page"
                                    //                     style={{ textDecoration: "none" }}
                                    //                     href={buildYourOwn == true ?
                                    //                         DiamondId == null ? process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.slug}?jid=${item?.id}` : process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.slug}?jid=${item?.id}&did=${DiamondId}`
                                    //                         :
                                    //                         process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.slug}?jid=${item?.id}`}

                                    //                 >
                                    //                     <div className="product-div-list" style={{ pointerEvents: 'all' }}>
                                    //                         <img
                                    //                             id={item?.id}
                                    //                             onMouseOver={() => onMouseovr(item?.id)}
                                    //                             onMouseOut={() => onMouseout(item?.id)}
                                    //                             src={item?.id == imgHover ? getpath[1] == undefined ? item?.default?.path : getpath[1]?.path : item?.default?.path}
                                    //                             style={{ height: '100%', width: '100%' }}
                                    //                             onError={(e) => {
                                    //                                 e.target.onerror = null;
                                    //                                 e.target.src = noimage;
                                    //                             }}
                                    //                             className="inner-img-product change-image"
                                    //                         />

                                    //                     </div>

                                    //                     <div className="text-center show-viewbtn">
                                    //                         <h5 className="product-title pt-3 line1-doted-3">
                                    //                             {item?.title}
                                    //                         </h5>
                                    //                         <p className="product-title-price mb-0">
                                    //                             ${item?.settingprice}
                                    //                             {/* $ {item?.offerprice == null ? item?.price : item?.offerprice} */}
                                    //                         </p>
                                    //                         <div className="pt-3 hide-view-btn">
                                    //                             <div className='view-details-btn'>
                                    //                                 <span className="span-link">
                                    //                                     View Details
                                    //                                 </span>
                                    //                                 <span>&nbsp;
                                    //                                     <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10L6 5.5L1 1" stroke="#23282D" strokeWidth="1.5" strokeLinecap="round" /></svg>
                                    //                                 </span>
                                    //                             </div>
                                    //                         </div>
                                    //                     </div>

                                    //                 </a>
                                    //             </div>

                                    //         </div>
                                    //     </div>
                                    // )
                                })
                                :
                                count == 0 ?
                                <div
                                    style={{ display: "grid", flex: 1, justifyContent: "center", alignItems: 'center', backgroundColor: '#f1ecf0', padding:'30px' }}
                                >
                                    {/* <img
                                        src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/diamgif.gif"}
                                        alt="loading..." onError={(e)=>{
                                            e.target.onerror=null
                                            e.target.src=noimage
                                        }}
                                        style={{ width: 150, height: 150 }}
                                    /> */}
                                    <h1> No Data Found</h1>
                                </div> :  <DiamondGridSkeleton /> 
                        }
                    </div>
                    {
                        count > 1 &&
                        <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', paddingTop: 30 }}>
                            <Pagination
                                color='primary'
                                count={count}
                                size="large"
                                page={Currentpage}
                                variant="outlined"
                                shape="rounded"
                                onChange={handleChange}
                            />
                        </div>
                    }
                </div>

            </section >
            {/* <ToastContainer /> */}
        </div >
    )
}
