import { useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL, GetAPIUrl } from '../API/APIUrl'
import { fetch2, fetchGet } from '../helpers/fetch'
import Cookies from "universal-cookie";
import { CallCartCountApi, logout, updateUser } from '../reducers/userReducers'
import { confirmAddress, confirmCancelOrder, confirmLogout, confirmRemove, confirmReturn, confirmReturns, ShowErrorMessage, ShowMessage } from '../module/Tostify'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../module/LoadingSpinner'
import axios from 'axios'
import { useEffect } from 'react'
import { Colors } from '../constant/Constant'
import noimage from "../assets/images/product-details/noimage.jpg";
import { Messages } from '../constant/Messages'
import Swal from 'sweetalert2'
import { utf8_to_b64 } from '../helpers/Utility'
import moment from 'moment'
import { Button, Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import './MyAccount/myaccount.css'
import 'react-loading-skeleton/dist/skeleton.css';

const $ = window.$;

export default function Myaccount() {
    var FileSaver = require('file-saver');

    const token = localStorage.getItem('token')
    const userData = useSelector(state => state.user.data)
    // const publicIp = require("react-public-ip");
    let subTotal = 0;
    let grandTotal = 0;
    let shippingCharge = 0;

    const [state, setState] = useState({
        firstname: '',
        lastname: '',
        email: '',
    }
    );
    // const errorStyle={ color: Colors.red, height: 15, padding: 4 }
    const errorStyle={ color: Colors.red, marginBottom:0 }

    const { firstname, lastname, email, mobileno, oldpassword, newPassword, confirmPassword } = state;


    const [saveAddress, setSaveAddress] = useState({});
    const [ErrorsData, setErrorsData] = useState('')


    const { Addfirstname, Addlastname, Addmobileno, Addaddress, Addemail, pincode,
        Addcity, Addressid } = saveAddress;


    const [CountryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])

    const [country, setCountry] = useState('')
    const [Addstate, setAddstate] = useState('')
    const [mobCode, setMobCode] = useState('')

    const [changePass, setChangePass] = useState(true)
    const [errorResponse, setErrorResponse] = useState(true)

    const [oldPassShow, setOldPassShow] = useState(false)
    const [newPassShow, setNewPassShow] = useState(false)
    const [cPassShow, setCPassShow] = useState(false)
    const [showDetail, setShowDetail] = useState(false)

    const location = useLocation();

    const status = new URLSearchParams(location.search).get("status");
    const [selectedMenu, setSelectedMenu] = useState(status == null ? 'personal-id' : 'myorder-id')
    const menu = [
        {
            menuName: 'Personal Info',
            datavalue: 'personal-id',
            href: 'javascript:void(0)',
        },
        {
            menuName: 'My Address',
            datavalue: 'myaddress-id',
            href: 'javascript:void(0)',
        },
        {
            menuName: 'My Order',
            datavalue: 'myorder-id',
            href: 'javascript:void(0)',
        },
        {
            menuName: 'Log out',
            datavalue: '',
            href: 'javascript:void(0)',
        },
    ]
    const [defaultLoader, setDefaultLoader] = useState(true)
    const [Loading, setLoading] = useState(false)
    const [profileLoading, setProfileLoading] = useState(false)
    const [addressListLoading, setAddressListLoading] = useState(false)
    const [addressAddLoading, setAddressAddLoading] = useState(false)
    const [returnLoading, setReturnLoading] = useState(false)


    const [addressList, setAddressList] = useState([])
    const [SaveAddressLoading, setSaveAddressLoading] = useState(false)
    const [OrderList, setOrderList] = useState([])
    const [OrderListDetail, setOrderListDetail] = useState('')

    const [return_days, order_return_days] = useState('')
    const [return_date, order_return_date] = useState(null)

    // const [Addfirstname, setAddFirstName] = useState('')
    // const [Addlastname, setAddLastName] = useState('')
    // const [address, setAddress] = useState('')
    // const [CountryList, setCountryList] = useState([])
    // const [stateList, setStateList] = useState([])
    // const [states, setStates] = useState('')
    // const [city, setCity] = useState('')
    // const [pincode, setPincode] = useState('')
    const [isdefault, setisdefault] = useState(false)

    /////////////////
    //for Order Cancel Modal
    const [showCancelModal, setShowCancelModal] = useState(false);
    const handleCancelModalClose = () => setShowCancelModal(false);
    const handleCancelModalShow = (orderId,diaId='',jeweleryId='') => { 
        // console.log("orderId",orderId);
        // console.log("diaId",diaId);
        // console.log("jeweleryId",jeweleryId);

        setCancelOrderId(orderId);
        setCancelDiaId(diaId);
        setCancelJewelId(jeweleryId);
        setShowCancelModal(true); 
    };
    const [cancelReason, setCancelReason] = useState('');
    const [cancelOrderId, setCancelOrderId] = useState('');
    const [cancelDiaId, setCancelDiaId] = useState('');
    const [cancelJewelId, setCancelJewelId] = useState('');
    const [cancelResonErr, setCancelResonErr] = useState('');

    //for Order Return Modal
    const [showReturnModal, setShowReturnModal] = useState(false);
    const handleReturnModalClose = () => setShowReturnModal(false);
    const handleReturnModalShow = (orderId,diaId='',jeweleryId='') => { 
        // console.log("orderId",orderId);
        // console.log("diaId",diaId);
        // console.log("jeweleryId",jeweleryId);

        setReturnOrderId(orderId);
        setReturnDiaId(diaId);
        setReturnJewelId(jeweleryId);
        setShowReturnModal(true); 
    };
    const [returnReason, setReturnReason] = useState('');
    const [returnOrderId, setReturnOrderId] = useState('');
    const [returnDiaId, setReturnDiaId] = useState('');
    const [returnJewelId, setReturnJewelId] = useState('');
    const [returnResonErr, setReturnResonErr] = useState('');

    const handelCancelInputChange = (event) => {
        setCancelReason(event.target.value); 
    }

    const handleCancelOrder = async() => {
        if (cancelReason === '') { 
            setCancelResonErr('Please Enter Cancel Reason.');
        }else{
            setLoading(true);
            setCancelResonErr('');
            let param = {
                orderId: cancelOrderId,
                cancel_reason: cancelReason,
                diamondId : cancelDiaId,
                jewellryId : cancelJewelId
            }
            await axios.post(BASE_URL + GetAPIUrl.ORDER_CANCEL_URL, param, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => {
                    setLoading(false);
                    setShowCancelModal(false);
                    if(response.data.type === 'success')
                    {
                        Swal.fire({
                            title: "Order Cancel Request",
                            text: response.message,
                            icon: 'success',
                            confirmButtonText: "Ok",
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        });
                    }else{
                        Swal.fire({
                            title: "Order Cancel Request",
                            text: response.message,
                            icon: 'warning',
                            confirmButtonText: "Ok",
                        });
                    }
                }).catch((e) => {
                    // setErrorResponse(e.response.data.errors)
                    // setProfileLoading(false)
                }).finally(()=>{

                })

            // PostAPI(PostAPIUrl.cancelOrder,param).then((response) => {
            //     setLoading(false);
            //     setShowCancelModal(false);
            //     if(response.type == 'success')
            //     {
            //         Swal.fire({
            //             title: "Order Cancel Request",
            //             text: response.message,
            //             icon: 'success',
            //             confirmButtonText: "Ok",
            //         }).then((result) => {
            //             /* Read more about isConfirmed, isDenied below */
            //             if (result.isConfirmed) {
            //                 location.reload();
            //             }
            //         });
            //     }else{
            //         Swal.fire({
            //             title: "Order Cancel Request",
            //             text: response.message,
            //             icon: 'warning',
            //             confirmButtonText: "Ok",
            //         });
            //     }
            // });
        }
    }
    const handelReturnInputChange = (event) => {
        setReturnReason(event.target.value); 
    }

    const handleReturnOrder = async () => {
        if (returnReason === '') { 
            setReturnResonErr('Please Enter Cancel Reason.');
        }else{
            setLoading(true);
            setReturnResonErr('');
            let param = {
                orderId: returnOrderId,
                return_reason: returnReason,
                diamondId : returnDiaId,
                jewellryId : returnJewelId
            }

            await axios.post(BASE_URL + GetAPIUrl.ORDER_RETURN_URL, param, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => {
                    setLoading(false);
                    setShowReturnModal(false);
                    if(response.type == 'success')
                    {
                        Swal.fire({
                            title: "Order Return Request",
                            text: response.message,
                            icon: 'success',
                            confirmButtonText: "Ok",
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                location.reload();
                            }
                        });
                    }else{
                        Swal.fire({
                            title: "Order Return Request",
                            text: response.message,
                            icon: 'warning',
                            confirmButtonText: "Ok",
                        });
                    }
                }).catch((e) => {
                    // setErrorResponse(e.response.data.errors)
                    // setProfileLoading(false)
                }).finally(()=>{

                })

            // PostAPI(PostAPIUrl.returnOrderRequest,param).then((response) => {
            //     setLoading(false);
            //     setShowReturnModal(false);
            //     if(response.type == 'success')
            //     {
            //         Swal.fire({
            //             title: "Order Return Request",
            //             text: response.message,
            //             icon: 'success',
            //             confirmButtonText: "Ok",
            //         }).then((result) => {
            //             /* Read more about isConfirmed, isDenied below */
            //             if (result.isConfirmed) {
            //                 location.reload();
            //             }
            //         });
            //     }else{
            //         Swal.fire({
            //             title: "Order Return Request",
            //             text: response.message,
            //             icon: 'warning',
            //             confirmButtonText: "Ok",
            //         });
            //     }
            // });
        }
    }
    /////////////////

    const cookies = new Cookies();
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const Remember = cookies.get('email') == undefined ? false : true

    const authenticate = async () => {
        // dispatch(logout())
        setLoading(true)
        // const result = await fetch2(BASE_URL + GetAPIUrl.LOGOUT_URL, null, token)
        // console.log("result", result);
        await axios.post(BASE_URL + GetAPIUrl.LOGOUT_URL, null, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {

                if (response.data?.success == true) {
                    if (Remember == false) {
                        cookies.remove('email', { path: process.env.PUBLIC_URL + "/" });
                        cookies.remove('password', { path: process.env.PUBLIC_URL + "/" });
                    }
                    window.location.href = process.env.PUBLIC_URL + "/Login"
                    dispatch(logout())
                    // ShowMessage(response.data.message)
                    setLoading(false)
                    // window.location.pathname = '/Login'

                } else {
                    ShowErrorMessage(response.data.message)
                    setLoading(false)
                }
            });
    }
    const updateProfile = async () => {
        setProfileLoading(true)

        var formdata = new FormData();
        formdata.append("firstname", firstname);
        formdata.append("lastname", lastname);
        formdata.append("email", email);
        formdata.append("mobileno", mobileno == undefined ? '' : mobileno);
        formdata.append("change_password", changePass);

        if (changePass == true) {
            formdata.append("old_password", oldpassword == undefined ? '' : oldpassword);
            formdata.append("password", newPassword == undefined ? '' : newPassword);
            formdata.append("confirm_password", confirmPassword == undefined ? '' : confirmPassword);
        }

        await axios.post(BASE_URL + GetAPIUrl.UPDATEPROFILE_URL, formdata, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {

                if (response.data?.success == true) {
                    setErrorResponse(true)
                    localStorage.setItem('data', JSON.stringify(response.data.data))
                    dispatch(updateUser());
                    ShowMessage(response.data.message)
                    setProfileLoading(false)
                    // window.location.pathname = '/Login'
                    if (changePass == true) {
                        authenticate();
                    }

                } else {
                    ShowErrorMessage(response.data.message)
                    setProfileLoading(false)
                }
            }).catch((e) => {
                setErrorResponse(e.response.data.errors)
                setProfileLoading(false)
            })
    }

    const fetchMyAddress = async () => {
        setAddressListLoading(true)
        await axios.get(BASE_URL + GetAPIUrl.ADDRESS_URL, {
            headers: {
                'Accept': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        })
            .then(response => {

                if (response?.data?.success == true) {
                    setAddressList(response?.data?.userAdddress)
                } else {
                }
                setAddressListLoading(false)
            }).catch((error) => {
                ShowErrorMessage(error)
            }).finally(() => {
                setAddressListLoading(false)
            })
    }

    const SaveAddress = async () => {
       $('.BtnSave').attr('disabled','true');
        // const ipv4 = await publicIp.v4() || "";
        // const ipv6 = await publicIp.v6() || "";
        let data = {
            "id": Addressid != undefined || Addressid != '' ? Addressid : '',
            "firstname": Addfirstname,
            "lastname": Addlastname,
            "email": Addemail,
            "address": Addaddress,
            "city": Addcity,
            "state": Addstate,
            "mobcode": mobCode,
            "country": country,
            "zip": pincode,
            "mobileno": parseInt(Addmobileno),
            // "ip_address": ipv4 == undefined ? '' : ipv4,
            "ip_address": localStorage.getItem('ipaddress'),
            "isdefault": isdefault == true ? 1 : 0
        }
        await axios.post(BASE_URL + GetAPIUrl.ADDADDRESS_URL, data, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.code == 200) {
                    setAddressAddLoading(true)
                    $('#address-modal').modal('hide');
                    $('body').removeClass('modal-open');
                    $('.modal-backdrop').remove();

                    // ShowMessage(response.data.message)
                    resetAll()
                    fetchMyAddress()

                } else {
                    // $('#address-modal').modal('show');
                    // $('body').addClass('modal-open');
                    ShowErrorMessage(response.errors.message)
                }
                setAddressAddLoading(false)
            }).catch((error) => {
                $('.BtnSave').removeAttr('disabled');
                // alert('dgf')
                // setAddressAddLoading(false)
                // $('#address-modal').modal('show');
                // $('#address-modal').show();
                // $('body').addClass('modal-open');
                // $('#address-modal').css("display","block");
                // $('#address-modal').css("z-index","9999");
                // $('.modal-backdrop').add();
                // resetAll();
                setErrorsData(error.response.data.errors)
                
            }).finally(() => {
                setAddressAddLoading(false)
            })

    }


    const CallCountryApi = async () => {
        const result = await axios.get(BASE_URL + GetAPIUrl.COUNTRY)
        if (result.data.success == true) {
            setCountryList(result.data.data)
            setAddstate('')
        }
    }
    const CallStateApi = async (e,countryid) => {
        let countryCode = e.target[e.target.selectedIndex].getAttribute('data-code');
        setMobCode(countryCode);
        let data = {
            "countryid": countryid,
        }
        const result = await fetch2(BASE_URL + GetAPIUrl.STATE, data)
        if (result.success == true) {
            setStateList(result.data)
        }
        // console.log("result", result);
    }
    useEffect(() => {
        // console.log(userData)
        if (userData) {
            setState({ ...userData });
        }
        if (status != null) {
            CallCartCountApi(token)

        }
        CallFetchOrderListApi()
        fetchMyAddress()
        CallCountryApi()
    }, [userData]);


    const handleTextChange = e => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    }
    const handleAddressChange = e => {
        let { name, value } = e.target;
        setSaveAddress({ ...saveAddress, [name]: value });
    }
    const updateState = async (item) => {
        resetAll()
        let countryId = ''
        let stateId = ''

        let data = {
            "countryid": item?.country,
        }

        let result = await axios.get(BASE_URL + GetAPIUrl.COUNTRY)
        if (result.data.success == true) {
            setCountryList(result.data.data)
            // result.data.data.filter((tag) => tag?.id == item?.id ? flag = tag.name : null)
            result.data.data.filter((tag) => {
                if (tag?.id == item?.country) {
                    countryId = tag?.id

                }
            })
        }

        const stateResult = await fetch2(BASE_URL + GetAPIUrl.STATE, data)
        if (stateResult.success == true) {
            setStateList(stateResult?.data)
            stateResult?.data?.filter((tag) => {
                if (tag?.id == item?.state) {
                    stateId = tag?.id
                }
            })
        }

        setCountry(countryId)
        setAddstate(stateId)
        setMobCode(item?.mob_code)
        setSaveAddress({
            "Addressid": item?.id,
            "userid": item?.userid,
            "Addfirstname": item?.firstname,
            "Addlastname": item?.lastname,
            "Addemail": item?.email,
            "Addmobileno": item?.mobileno,
            "Addaddress": item?.address,
            "Addcity": item?.city,
            "pincode": item?.pincode,
            "Addmobcode": item?.mob_code,
        })

        setisdefault(item?.isdefault === 1 ? true : false)
    }

    const resetAll = () => {
        $("#address-modal").modal('hide')
        setCountry('')
        setAddstate('')
        setSaveAddress({
            "Addressid": '',
            "userid": '',
            "Addfirstname": '',
            "Addlastname": '',
            "Addemail": '',
            "Addmobileno": '',
            "Addaddress": '',
            "Addcity": '',
            "pincode": '',
            "Addmobcode": '',

        })
        setisdefault(false)
        setErrorsData('')
        setMobCode('')

    }

    const confirmLogoutBtn = () => {

        confirmLogout().then((result) => {
            if (result == true) {
                authenticate()
            }
        }

        )
    }
    const confirmRemoveAddress = (item) => {

        confirmRemove().then((result) => {
            if (result == true) {
                removeAddress(item)
            }
        }

        )
    }
    const removeAddress = async (item) => {
        let data = {
            "id": item.id,
        }
        await axios.post(BASE_URL + GetAPIUrl.REMOVEADDRESS_URL, data, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            if (response.data.code == 200) {
                // ShowMessage(response.data.message)
                fetchMyAddress()
            } else {
                ShowErrorMessage(response.data.message)
            }

            // result.data.data.filter((tag) => tag?.id == item?.id ? flag = tag.name : null)

        }).catch(() => {

        }).finally(() => {

        })
    }
    // $(function () {
    //     if ($("#address-modal").modal('hide')){
    //         resetAll()
    //     }
    // })
    const CallFetchOrderListApi = async () => {
        setAddressListLoading(true)
        // await axios.get(BASE_URL + GetAPIUrl.WISHLIST_URL, {
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + token
        //     }
        // })
        await axios({
            method: "GET",
            url: BASE_URL + GetAPIUrl.ORDERLIST_URL,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response?.data?.success == true) {
                    setOrderList(response?.data?.orders)
                    order_return_days(response?.data?.order_return_days)
                } else {
                    ShowErrorMessage(response?.data?.message)
                }
                setAddressListLoading(false)
            })
            .catch(error => {
                ShowErrorMessage(error?.message)
            }).finally(() => {
                setAddressListLoading(false)
            })
    }

    const confirmReturn = (item) => {

        confirmReturns().then((result) => {
            if (result.isConfirmed == true) {
                CallReturnOrderApi(item, result.value)
            }
        }

        )
    }

    const confirmCancel = (item) => {

        confirmCancelOrder().then((result) => {
            if (result.isConfirmed == true) {
                CallCancelOrderApi(item, result.value)
            }
        }

        )
    }
    const CallCancelOrderApi = async (item, reason) => {
        let data = {
            "order_id": item?.id,
            "reason": reason
        }
        await axios.post(BASE_URL + GetAPIUrl.ORDER_CANCEL_URL, data, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    CallFetchOrderListApi()
                    ShowMessage(response.data.message)
                } else {
                    ShowErrorMessage(response.data.message)
                }
            }).catch((error) => {
            }).finally(() => {

            })

    }
    const CallReturnOrderApi = async (item, reason) => {
        setReturnLoading(true)
        let data = {
            "order_id": item?.orderid,
            "order_detail_id": item?.id,
            "reason": reason
        }
        await axios.post(BASE_URL + GetAPIUrl.ORDER_RETURN_URL, data, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    CallFetchOrderListApi()
                    ShowMessage(response.data.message)
                } else {
                    ShowErrorMessage(response.data.message)
                }
            }).catch((error) => {
            }).finally(() => {
                setReturnLoading(false)
            })

    }

    const downloadTxtFile = async (item, index) => {
        setLoading(true)
        await axios.get(BASE_URL + GetAPIUrl.DOWNLOAD_INVOICE + `/${item?.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                // var file = new Blob([response], {type: 'application/pdf'});
                // var fileURL = URL.createObjectURL(file);
                // window.open(fileURL);

                if (response.data.success == true) {
                    FileSaver.saveAs(response?.data?.file, 'application/pdf');

                } else {

                }
            }).catch(error => {
            }).finally(() => {
                setLoading(false)
            })


        // const texts = ["line 1", "line 2", "line 3"]

        //  const file = new Blob(texts, {type: 'text/plain'});

        //  const element = document.createElement("a");
        //  element.href = URL.createObjectURL(file);
        //  element.download = "100ideas-" + Date.now() + ".txt";

        //  document.body.appendChild(element); 
        //  element.click();
    }
    return (
        <div>
            {/* {Loading || profileLoading || addressAddLoading || addressListLoading || SaveAddressLoading == true &&

                <LoadingSpinner />
            } */}
            {(Loading || profileLoading || addressAddLoading || addressListLoading) && defaultLoader ?
                <LoadingSpinner />
                :
                <>

            <div>
                <div className="container container-main">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to={'/'} className='desk-top-title-sm a-taglink' ><i className="fa fa-home" aria-hidden="true"></i></Link></li>
                            <li className="breadcrumb-item active" aria-current="page">My Account</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section className="jumbotron pt-3">
                <div className="container container-main">
                    <div>
                        <div className="row">
                            <div className="col-lg-3" style={{ display: 'grid' }} >
                                <div className="thumbsider" style={{ position: 'sticky', top: '100%' }}>
                                    <ul className="left-list ul-box-shadow ul-bg mar-b-10">

                                        {menu?.map((item, index) => {
                                            return (
                                                <a key={Math.random()} style={{ cursor: 'pointer' }}
                                                    className={selectedMenu == item.datavalue ?
                                                        "active-select-li active-list-li" : 'active-select-li'}
                                                    data-value={item.datavalue}
                                                    href={() => item.href}
                                                    onClick={() => { item.menuName == 'Log out' ? confirmLogoutBtn() : setSelectedMenu(item.datavalue) }} >
                                                    <li>{item.menuName}</li>
                                                </a>

                                            )
                                        })
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div>
                                    <div className="acc-right-box main-div" id="personal-id" style={{ display: selectedMenu != 'personal-id' && 'none' }}>
                                        <div>
                                            <h5 className="title-1">
                                                Personal Information
                                            </h5>
                                            {/* <p className="col777">
                                                {Messages.PERSONAL_INFO}
                                            </p> */}
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="form-group text-left">
                                                    <label className="lblmy">First Name<span className='mark_red'>*</span></label>
                                                    <input type="text"
                                                        className="form-control my-textbox1"
                                                        id="fname"
                                                        placeholder="First Name"
                                                        name="firstname"
                                                        value={firstname || ""}
                                                        onChange={handleTextChange}
                                                    />
                                                    {errorResponse?.firstname != undefined ? 
                                                    <p style={errorStyle}>
                                                        {errorResponse != '' && errorResponse?.firstname?.map((key, i) => key)}
                                                    </p> : null }
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group text-left">
                                                    <label className="lblmy">Last Name<span className='mark_red'>*</span></label>
                                                    <input type="text" className="form-control my-textbox1"
                                                        id="lname"
                                                        name="lastname"
                                                        placeholder="Last Name"
                                                        value={lastname}
                                                        onChange={handleTextChange}
                                                    />
                                                    {errorResponse?.lastname != undefined ? 
                                                    <p style={errorStyle}>
                                                        {errorResponse != '' && errorResponse?.lastname?.map((key, i) => key)}
                                                    </p> : null }
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="form-group text-left">
                                                    <label className="lblmy">Email Id<span className='mark_red'>*</span></label>
                                                    <input disabled={true} type="text" className="form-control my-textbox1"
                                                        id="emailid"
                                                        placeholder="Email Id"
                                                        name="email"
                                                        value={email || ""}
                                                        onChange={handleTextChange}
                                                    />
                                                    {errorResponse?.email != undefined ? 
                                                    <p style={errorStyle}>
                                                        {errorResponse != '' && errorResponse?.email?.map((key, i) => key)}
                                                    </p> : null }
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-4">
                                                <div className="form-group text-left">
                                                    <label className="lblmy">Mobile no:</label>
                                                    <input type="text"
                                                        className="form-control my-textbox1"
                                                        id="mobileno"
                                                        placeholder="Mobile Number"
                                                        name="mobileno"
                                                        value={mobileno || ""}
                                                        onChange={handleTextChange}
                                                    />
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <br /> */}
                                        <div>
                                            <div className="form-group mar-b-14">
                                                <label className="chk-sign mar-top-10" style={{ fontWeight: 500, display: 'inline-block' }} >
                                                    Change Password
                                                    <input type="checkbox" id="changepasschk"
                                                        name="changepasschk"
                                                        checked={changePass ? true : false}
                                                        onChange={() => setChangePass(!changePass)} />
                                                    <span className="checkmark-chk"></span>
                                                </label>
                                            </div>
                                            {changePass ?
                                                <div className="check-pass-div">
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="form-group text-left pos-relative">
                                                                <label className="lblmy">Old Password</label><span className='mark_red'>*</span>
                                                                <input type={oldPassShow ? "text" : 'password'}
                                                                    // style={{ font: oldPassShow && "small-caption", }}
                                                                    className="form-control my-textbox1"
                                                                    id="oldpassword"
                                                                    name="oldpassword"
                                                                    placeholder="Old Password"
                                                                    value={oldpassword || ""}
                                                                    onChange={handleTextChange}
                                                                />
                                                                <span className="show-pass hand" title={oldPassShow ? "Hide password" : "show password"} onClick={() => setOldPassShow(!oldPassShow)} style={{ padding: "3px",marginRight:"5px" }} >
                                                                    <i className={oldPassShow ? "fa fa-eye oldpassword" : 'fa fa-eye-slash'} aria-hidden="true"></i>
                                                                </span>
                                                                {errorResponse?.old_password != undefined ? 
                                                            <p style={errorStyle}>
                                                                {errorResponse != '' && errorResponse.old_password?.map((key, i) => key)}
                                                            </p> : null }
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="form-group text-left pos-relative">
                                                                <label className="lblmy">New Password</label><span className='mark_red'>*</span>
                                                                <input type={newPassShow ? "text" : 'password'}
                                                                    // style={{ font: newPassShow && "small-caption", }}
                                                                    className="form-control my-textbox1" id="newpassword"
                                                                    placeholder="New Password"
                                                                    name="newPassword"
                                                                    value={newPassword || ""}
                                                                    onChange={handleTextChange}
                                                                />
                                                                <span className="show-pass hand" title={newPassShow ? "Hide password" : "show password"} onClick={() => setNewPassShow(!newPassShow)} style={{ padding: "3px",marginRight:"5px" }}>
                                                                    <i className={newPassShow ? "fa fa-eye oldpassword" : 'fa fa-eye-slash'} aria-hidden="true"></i>
                                                                </span>
                                                            {errorResponse?.password != undefined ? 
                                                            <p style={errorStyle}>
                                                                {errorResponse != '' && errorResponse?.password?.map((key, i) => key)}
                                                            </p> : null }
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="form-group text-left pos-relative">
                                                                <label className="lblmy">Confirm Password</label><span className='mark_red'>*</span>
                                                                <input type={cPassShow ? "text" : 'password'}
                                                                    // style={{ font: cPassShow && "small-caption", }}
                                                                    className="form-control my-textbox1" id="confpassword"
                                                                    placeholder="Confirm Password"
                                                                    name="confirmPassword"
                                                                    value={confirmPassword || ""}
                                                                    onChange={handleTextChange}
                                                                />
                                                                <span className="show-pass hand" title={cPassShow ? "Hide password" : "show password"} onClick={() => setCPassShow(!cPassShow)} style={{ padding: "3px",marginRight:"5px" }}>
                                                                    <i className={cPassShow ? "fa fa-eye oldpassword" : 'fa fa-eye-slash'} aria-hidden="true"></i>
                                                                </span>
                                                            {errorResponse?.confirm_password != undefined ? 
                                                            <p style={errorStyle}>
                                                                {errorResponse != '' && errorResponse?.confirm_password?.map((key, i) => key)}
                                                            </p> : null }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : null}
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-lg-3" onClick={() => updateProfile()}>
                                                <a className="acc-save-nowbtn mb-2">
                                                    Save
                                                </a>
                                            </div>
                                            {/* <div className="col-lg-3">
                                                <a className="cancel-nowbtn mb-2">
                                                    Cancel
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="acc-right-box main-div" id="myaddress-id" style={{ display: selectedMenu != 'myaddress-id' && 'none' }}>
                                        <div>
                                            <h5 className="title-1">
                                                My Address
                                            </h5>
                                            {/* <p className="col777">
                                                It is a long established fact that a reader will be distracted by the readable content.
                                            </p> */}
                                        </div>
                                        <br />

                                        <div className="mar-bot-30">
                                            {addressList?.map((item, index) => {
                                                return (
                                                    item?.isdefault == 1 &&
                                                    <div className="row mar-bot-10" key={Math.random()}>
                                                        <div className="col-lg-8 col-md-7">
                                                            <h6 className="lsp1 colmain"> <b className="f-500">Default Billing Address</b></h6>
                                                        </div>
                                                        {/* <div className="col-lg-4 col-md-5">
                                                            <a className="change-add-btn mobile-1rem-bot" data-toggle="modal" data-target="#address-modal">
                                                                Change Address
                                                            </a>
                                                        </div> */}
                                                    </div>
                                                )
                                            })}
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="box-content">
                                                        {addressList?.map((item, index) => {
                                                            return (
                                                                item?.isdefault == 1 &&
                                                                <div className="add-p mar-bot-10" key={Math.random()}>
                                                                    <div className='col-lg-12 col-md-7 add-p'>
                                                                        <a className="change-add-btn mobile-1rem-bot" style={{float:'left !important'}} onClick={() => updateState(item)} data-toggle="modal" data-target="#address-modal">
                                                                            Change Address
                                                                        </a>
                                                                        <p>{item?.firstname} {item?.lastname}</p>
                                                                        <p>{item?.address}</p>
                                                                        <p>{item?.city}-{item?.pincode}</p>
                                                                        <p>Mobile No: <a className="color-80" href={`tel:${item?.mobileno}`}>{item.mobileno}</a>
                                                                        </p>
                                                                    </div>                                                                    
                                                                </div>
                                                                // :
                                                                // <span style={{ marginTop: 10, display: 'block' }} > You have no Default address entries in your address book. </span>

                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="hrmy" />
                                            <div className="row mar-bot-15">
                                                {addressList?.length == 0 ?
                                                    <div
                                                        style={{ display: "grid", justifyContent: "center", flex: 1, backgroundColor: '#f1ecf0' }}
                                                    >
                                                        <img
                                                            src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/diamgif.gif"}
                                                            onError={(e)=>{
                                                                e.target.onerror=null
                                                                e.target.src=noimage
                                                            }}
                                                            alt="loading..."
                                                            style={{ width: 150, height: 150 }}
                                                        />
                                                        <h4>No data Found</h4>
                                                    </div>
                                                    :
                                                    <div className="col-lg-12">

                                                        <h6 className="lsp1 colmain">
                                                            <b className="f-500">Additional Address Entries</b>
                                                        </h6>
                                                        {addressList?.map((item, index) => {
                                                            return (
                                                                item?.isdefault == 0 &&
                                                                <div className="row mar-bot-10" key={Math.random()}>
                                                                    <div className="col-lg-12 col-md-7 add-p">
                                                                        <a className="change-add-btn mobile-1rem-bot" onClick={() => updateState(item)} data-toggle="modal" data-target="#address-modal">
                                                                            Change Address
                                                                        </a>
                                                                        <p>{item?.firstname} {item?.lastname}</p>

                                                                        <p>{item?.address}</p>
                                                                        <p>{item?.city}-{item?.pincode}</p>
                                                                        <p>Mobile No: <a className="color-80" href={`tel:${item?.mobileno}`}>{item.mobileno}</a>
                                                                        </p>
                                                                        <button type="button" className="btn btn-danger" onClick={() => confirmRemoveAddress(item)}>Remove</button>

                                                                        <hr className="hrmy" />

                                                                    </div>
                                                                    {/* <div className="col-lg-2 col-md-5">
                                                                        <a className="change-add-btn mobile-1rem-bot" data-toggle="modal" data-target="#address-modal">
                                                                            Change Address
                                                                        </a>
                                                                    </div> */}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3"> <a data-toggle="modal" data-target="#address-modal"
                                                    className="acc-save-nowbtn mb-2 hand" onClick={() => resetAll()}
                                                    style={{ marginTop: 25 }}> Add New Address </a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="acc-right-box main-div" id="myorder-id" style={{ display: selectedMenu != 'myorder-id' && 'none' }}>

                                        <div className="ord-details-div" style={{ display: !showDetail && 'none' }} >
                                            <div>
                                                <h5 className="title-1" style={{ marginBottom: 20 }} >
                                                    <span className="show-ord-list-div hand" onClick={() => setShowDetail(false)}>
                                                        <i className="fa fa-chevron-left" aria-hidden="true"></i> Back to Order List
                                                    </span>
                                                </h5>
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <td className="text-left font-liberation-bold" colSpan="3">Order Details</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-fff">

                                                        <tr>
                                                            {/* {OrderListDetail?.order_detail?.map((item) => {
                                                                return (
                                                                    <td className="text-left" style={{ width: '30%' }}>
                                                                        <b>Order ID : </b> #{item?.orderid}
                                                                        <br />
                                                                        <b>Date Added : </b> {moment(item?.entrydate).format('DD-MM-YYYY')}
                                                                    </td>
                                                                )
                                                            })} */}
                                                            <td className="text-left" style={{ width: '30%' }}>
                                                                <b>Order ID : </b> #{OrderListDetail?.id}
                                                                <br />
                                                                <b>Date Added : </b> {moment(OrderListDetail?.entrydate).format('DD-MM-YYYY')}
                                                            </td>
                                                            <td className="text-left" style={{ width: '30%' }}>
                                                                <b>Payment Method : </b>{OrderListDetail?.order_payments_data?.payment_type}
                                                                <br />
                                                                <b>Shipping Method:</b> {(OrderListDetail?.shipping?.name != null && OrderListDetail?.shipping?.name != undefined) ? OrderListDetail?.shipping?.name : "Free Shipping"}
                                                            </td>
                                                            <td className="text-left" style={{ width: '30%' }}>
                                                                <b>Card Type : </b> {OrderListDetail?.order_payments_data?.card_type}
                                                                <br />
                                                                <b>Payment Transation Id :</b> {OrderListDetail?.order_payments_data?.payment_transaction_id}
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <table className="table table-bordered table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <td className="text-left font-liberation-bold" style={{ width: '50%', verticalAlign: 'top' }}>Shipping Address</td>
                                                            <td className="text-left font-liberation-bold" style={{ width: '50%', verticalAlign: 'top' }}>Billing Address</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-fff">
                                                        {
                                                            // console.log("OrderListDetail main data - ",OrderListDetail)
                                                        }
                                                        <tr>
                                                            {OrderListDetail?.order_addresses_data?.map((item) => {

                                                                return (
                                                                    <>
                                                                        {
                                                                            item?.type == 'Both' &&
                                                                            <>
                                                                                <td className="text-left">{item.firstname} {item.lastname}
                                                                                    <br />{item.email}
                                                                                    <br />{item.mobileno}
                                                                                    <br />{item.address}, {item.city} - {item.pincode}
                                                                                    <br />{item?.order_state_data?.name}, {item?.order_country_data?.name}
                                                                                </td>
                                                                                <td className="text-left">{item.firstname} {item.lastname}
                                                                                    <br />{item.email}
                                                                                    <br />{item.mobileno}
                                                                                    <br />{item.address}, {item.city} - {item.pincode}
                                                                                    <br />{item?.order_state_data?.name}, {item?.order_country_data?.name}
                                                                                </td>
                                                                            </>
                                                                        }

                                                                        {
                                                                            item?.type == 'Shipping' &&
                                                                            <td className="text-left">{item.firstname} {item.lastname}
                                                                                <br />{item.email}
                                                                                <br />{item.mobileno}
                                                                                <br />{item.address}, {item.city} - {item.pincode}
                                                                                <br />{item?.order_state_data?.name}, {item?.order_country_data?.name}
                                                                            </td>
                                                                        }
                                                                        {
                                                                            item.type == 'Billing' &&
                                                                            <td className="text-left">{item.firstname} {item.lastname}
                                                                                <br />{item.email}
                                                                                <br />{item.mobileno}
                                                                                <br />{item.address}, {item.city} - {item.pincode}
                                                                                <br />{item?.order_state_data?.name}, {item?.order_country_data?.name}
                                                                            </td>

                                                                        }
                                                                    </>
                                                                )
                                                            })}
                                                        </tr>


                                                    </tbody>
                                                </table>
                                                <div className="table-responsive mar-bot-25px thtable" style={{ boxShadow: ' 0 2px 7px 3px #dee2e6a6' }}>
                                                    <table id="order-table" className="table table-striped1" style={{ width: '100%', marginBottom: 0, boxShadow: ' 0 2px 7px 3px #e8e8e8' }}>
                                                        <thead className="font-auto tbl-header" style={{ width: '100%' }}>
                                                            <tr className="trth-center th-r-bor">
                                                                {/* <td style={{ width: '10%' }}></td> */}
                                                                <th className="text-left" style={{ minWidth: 250 }}>Product Name</th>
                                                                <th style={{ minWidth: 100 }}>Date</th>
                                                                <th style={{ minWidth: 100 }}>Status</th>
                                                                <th style={{ /*minWidth: 100*/ }}>Qty</th>
                                                                <th style={{ /*minWidth: 100*/ }}>Price</th>
                                                                <th style={{ /*minWidth: 100*/ }}>Total</th>
                                                                <th style={{ minWidth: 100 }}>Action</th>
                                                            </tr>
                                                        </thead>

                                                        {/* {returnLoading ?
                                                            <LoadingSpinner />
                                                            :
                                                            null
                                                        } */}
                                                        
                                                        <tbody className="tr-unhov-hand table-striped1-odd" >
                                                        {OrderListDetail?.order_detail?.map((item) => {
                                                            // console.log("sdfsfsfs dfsdf s --",item)

                                                            // console.log("sdfsfsfs dfsdf entrydate --",item?.jewelery?.order_current_status.entrydate)
                                                            //delivery_date
                                                            const date1 = new Date();
                                                            const date2 = new Date(item.delivery_date);
                                                            const diffTime = Math.abs(date2 - date1);
                                                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))-1; 
                                                            // console.log("first"+date1+"date2"+date2+" = "+diffDays);
                                                            if(item?.jewelery != null && item?.diamonds == null){
                                                                subTotal += item?.jewelery?.setting_price;
                                                            }else if(item?.jewelery == null && item?.diamonds != null){
                                                                subTotal += item?.diamonds?.diamondprice;
                                                            }else if(item?.jewelery != null && item?.diamonds != null){
                                                                subTotal += item?.jewelery?.setting_price;
                                                                subTotal += item?.diamonds?.diamondprice;
                                                            }
                                                            return (
                                                                <>
                                                                    {item?.jewelery != null && item?.diamonds == null &&
                                                                        <tr className="trtd-center trtd-13 tr-hov-td" style={{border:"2px solid #c1c1c1"}} title='The Border Indicate this is Separate Jewelry'>
                                                                            {/* <td style={{ width: '10%' }}></td> */}
                                                                            <td style={{ width: '30%' }} className="text-left">
                                                                            <div style={{display:"flex"}}>
                                                                                        <div style={{width:"40%"}}>
                                                                                            <img src={item?.jewelery?.default?.path == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg" : item?.jewelery?.default?.path} width="80%" className="max-150-img" onError={(e)=>{
                                                                                                e.target.onerror=null
                                                                                                e.target.src=noimage
                                                                                            }}/>
                                                                                        </div>
                                                                                        <div style={{width:"60%"}}>
                                                                                            <a href={process.env.PUBLIC_URL + `/JDetails/${item?.jewelryid}/${item?.jewelery?.title}?jid=${item?.jewelery?.id}`} className='desk-top-title-sm a-taglink'>
                                                                                                {item?.jewelery?.title}<hr style={{margin:0}}/>
                                                                                            </a>
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">Metal</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{item?.jewelery?.metal_stamp?.paraname} {item?.jewelery?.metal_type?.paraname}</span>
                                                                                            </div>
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">SKU</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">#{item?.jewelery?.itemcode}</span>
                                                                                            </div>
                                                                                            {
                                                                                                (item?.ring_size?.paraname != null || item?.ring_size != null) ?
                                                                                                    <div className="wishlist_plist">
                                                                                                        <span className="sourcesansreg wishlist_prd_left">{
                                                                            (item?.jewelery?.category?.name.toLowerCase() == "ring" 
                                                                || item?.jewelery?.category?.name.toLowerCase() == "rings"
                                                                || item?.jewelery?.category?.name.toLowerCase() == "engagement rings"
                                                                || item?.jewelery?.category?.name.toLowerCase() == "engagement ring"
                                                                || item?.jewelery?.category?.name.toLowerCase() == "wedding ring"
                                                                || item?.jewelery?.category?.name.toLowerCase() == "wedding rings") ? "Ring Size" : null
                                                                }
                                                                {
                                                                (item?.jewelery?.category?.name.toLowerCase() == "bracelets" || item?.jewelery?.category?.name.toLowerCase() == "bracelet") ? "Bracelet Length" : null
                                                                    }{
                                                                (item?.jewelery?.category?.name.toLowerCase() == "chains" || item?.jewelery?.category?.name.toLowerCase() == "chain") ? "Chain Length" : null
                                                                }</span>
                                                                                                        <span style={{ padding: 8 }}>:</span>
                                                                                                        <span className="wishlist_prd_right">{item?.ring_size?.paraname}</span>
                                                                                                    </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            <td>{moment(item?.entrydate).format('DD-MM-YYYY')}</td>
                                                                            <td><span className="pending-tag">{item?.jewelery?.order_current_status?.customerstatuscaption}</span></td>
                                                                            <td style={{textAlign:"right"}}>{item?.qty}</td>
                                                                            <td style={{textAlign:"right"}}>{OrderListDetail?.currency?.sign ?? "$"}{item?.jewelery?.setting_price/item?.qty}</td>
                                                                            <td style={{textAlign:"right"}}>{OrderListDetail?.currency?.sign ?? "$"}{(item?.jewelery?.setting_price).toFixed(2)}</td>
                                                                            {/* <td style={{textAlign:"right"}}>${item?.jewelery?.settingprice}</td> */}
                                                                            <td>
                                                                                {item?.jewelery?.order_current_status?.customerstatuscaption == "Delivered"  && return_days >= diffDays  ?
                                                                                    <a onClick={() => confirmReturn(item)} title="Return Order" href="javascript:void(0);"
                                                                                        className="btn btn-outline-info btn_cancel_order"> <i className="fa fa-reply hand" aria-hidden="true"></i>
                                                                                    </a>
                                                                                    : null
                                                                                }
                                                                                {
                                                                                    (item?.jewelery?.order_current_status?.customerstatuscaption == 'Order Placed' || item?.jewelery?.order_current_status?.customerstatuscaption == "Order Confirm") &&
                                                                                    <a onClick={() => handleCancelModalShow(item?.jewelery?.orderid,'',item?.jewelery?.itemcode)} className="btn btn btn-outline-warning btn_cancel_order" title="Cancel Order">
                                                                                        <i className="fa fa-times hand" aria-hidden="true"></i>
                                                                                    </a>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {item?.jewelery == null && item?.diamonds != null &&
                                                                        <tr className="trtd-center trtd-13 tr-hov-td" style={{border:"2px solid #c1c1c1"}} title='The Border Indicate this is Separate Diamond'>
                                                                            {/* <td style={{ width: '10%' }}></td> */}
                                                                            <td style={{ width: '30%' }} className="text-left">
                                                                            <div style={{display:"flex"}}>
                                                                                        <div style={{width:"40%"}}>
                                                                                            <img src={item?.diamonds?.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : item?.diamonds?.image} width="80%" className="max-150-img" onError={(e)=>{
                                                                                                e.target.onerror=null
                                                                                                e.target.src=noimage
                                                                                            }}/>
                                                                                        </div>
                                                                                        <div style={{width:"60%"}}>
                                                                                            {item?.diamonds?.title}<hr style={{margin:0}}/>
                                                                                            <p style={{display:'none'}}>Stock : #{item?.diamonds?.stockid}</p>
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">SKU</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{item?.diamonds?.loatno}</span>
                                                                                            </div>
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">Shape</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{item?.diamonds?.shape_name?.paraname}</span>
                                                                                            </div>
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">Carat</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{(item?.diamonds?.carat).toFixed(2)}</span>
                                                                                            </div>
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">Clarity</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{item?.diamonds?.clarity_name?.paraname}</span>
                                                                                            </div>

                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">Color</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{item?.diamonds?.color_name?.paraname}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                            </td>
                                                                            <td>{moment(item?.entrydate).format('DD-MM-YYYY')}</td>
                                                                            <td><span className="pending-tag">{item?.diamonds?.order_current_status?.customerstatuscaption}</span></td>
                                                                            <td style={{textAlign:"right"}}>1</td>
                                                                            <td style={{textAlign:"right"}}>{OrderListDetail?.currency?.sign ?? "$"}{item?.diamonds?.diamondprice}</td>
                                                                            <td style={{textAlign:"right"}}>{OrderListDetail?.currency?.sign ?? "$"}{item?.diamonds?.diamondprice}</td>
                                                                            <td>
                                                                                {item?.diamonds?.order_current_status?.customerstatuscaption == "Delivered"  ?
                                                                                    <a onClick={() => confirmReturn(item)} title="Return Order"
                                                                                        href="javascript:void(0);" className="btn btn btn-outline-warning btn_cancel_order">
                                                                                        <i className="fa fa-reply hand" aria-hidden="true"></i>
                                                                                    </a>
                                                                                    : null
                                                                                }
                                                                                {
                                                                                    (item?.diamonds?.order_current_status?.customerstatuscaption == 'Order Placed' || item?.diamonds?.order_current_status?.customerstatuscaption == "Order Confirm") &&
                                                                                    <a onClick={() => handleCancelModalShow(item?.diamonds?.orderid,item?.diamonds?.diamondid)} className="btn btn btn-outline-warning btn_cancel_order" title="Cancel Order">
                                                                                        <i className="fa fa-times hand" aria-hidden="true"></i>
                                                                                    </a>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {item?.jewelery != null && item?.diamonds != null &&
                                                                        <>
                                                                            <tr className="trtd-center trtd-13 tr-hov-td" style={{/*border:"2px solid #c1c1c1"*/}} title='This is Setting with Below shown Diamond'>
                                                                                {/* <td style={{ width: '10%' }}></td> */}
                                                                                <td style={{ width: '30%' }} className="text-left">
                                                                                    <div style={{display:"flex"}}>
                                                                                        <div style={{width:"40%"}}>
                                                                                        <img src={item?.jewelery?.default?.path == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg" : item?.jewelery?.default?.path} width="80%" className="max-150-img" onError={(e)=>{
                                                                                            e.target.onerror=null
                                                                                            e.target.src=noimage
                                                                                        }}/>
                                                                                        </div>
                                                                                        <div style={{width:"60%"}}>
                                                                                            <a href={process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.jewelryid}/${item?.jewelery?.title}?jid=${item?.jewelery?.id}`} className='desk-top-title-sm a-taglink'>
                                                                                                {item?.jewelery?.title}
                                                                                            </a>
                                                                                            <hr style={{margin:0}}/>                                                                                           
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">Metal</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{item?.jewelery?.metal_stamp?.paraname} {item?.jewelery?.metal_type?.paraname}</span>
                                                                                            </div>
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">SKU</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">#{item?.jewelery?.itemcode}</span>
                                                                                            </div>
                                                                                            {
                                                                                                (item?.ring_size?.paraname != null || item?.ring_size != null) ?
                                                                                                    <div className="wishlist_plist">
                                                                                                        <span className="sourcesansreg wishlist_prd_left">{
                                                                            (item?.jewelery?.category?.name.toLowerCase() == "ring" 
                                                                || item?.jewelery?.category?.name.toLowerCase() == "rings"
                                                                || item?.jewelery?.category?.name.toLowerCase() == "engagement rings"
                                                                || item?.jewelery?.category?.name.toLowerCase() == "engagement ring"
                                                                || item?.jewelery?.category?.name.toLowerCase() == "wedding ring"
                                                                || item?.jewelery?.category?.name.toLowerCase() == "wedding rings") ? "Ring Size" : null
                                                                }
                                                                {
                                                                (item?.jewelery?.category?.name.toLowerCase() == "bracelets" || item?.jewelery?.category?.name.toLowerCase() == "bracelet") ? "Bracelet Length" : null
                                                                    }{
                                                                (item?.jewelery?.category?.name.toLowerCase() == "chains" || item?.jewelery?.category?.name.toLowerCase() == "chain") ? "Chain Length" : null
                                                                }</span>
                                                                                                        <span style={{ padding: 8 }}>:</span>
                                                                                                        <span className="wishlist_prd_right">{item?.ring_size?.paraname}</span>
                                                                                                    </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                            </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{moment(item?.entrydate).format('DD-MM-YYYY')}</td>
                                                                                <td><span className="pending-tag">{item?.jewelery?.order_current_status?.customerstatuscaption}</span></td>
                                                                                {
                                                                                    console.log("item---------",item)
                                                                                }
                                                                                <td style={{textAlign:"right"}}>{item?.qty}</td>
                                                                                <td style={{textAlign:"right"}}>{OrderListDetail?.currency?.sign ?? "$"}{item?.jewelery?.setting_price}</td>
                                                                                <td style={{textAlign:"right"}}>{OrderListDetail?.currency?.sign ?? "$"}{(item?.jewelery?.setting_price*item?.qty)}</td>
                                                                                {/* <td style={{textAlign:"right"}}>${item?.jewelery?.settingprice}</td> */}
                                                                                <td>
                                                                                    {item?.jewelery?.order_current_status?.customerstatuscaption == "Delivered" && return_days >= diffDays ?
                                                                                        <a onClick={() => confirmReturn(item)} title="Return Order" href="javascript:void(0);" className="btn btn btn-outline-warning btn_cancel_order"> <i className="fa fa-reply hand" aria-hidden="true"></i>
                                                                                        </a>
                                                                                        : null
                                                                                    }
                                                                                    {
                                                                                        (item?.jewelery?.order_current_status?.customerstatuscaption == "Order Placed" || item?.jewelery?.order_current_status?.customerstatuscaption == "Order Confirm") && 
                                                                                        <a className="btn btn btn-outline-warning btn_cancel_order" onClick={() => handleCancelModalShow(item?.jewelery?.orderid,item?.diamonds?.diamondid,item?.jewelery?.itemcode)} title="Cancel Order">
                                                                                            <i className="fa fa-times hand" aria-hidden="true"></i>
                                                                                        </a>
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                            <tr title='This Line Indicate that this is Setting'>
                                                                                <td colSpan="8">
                                                                                    <hr className="hr-border" style={{margin:"auto",padding:0}} />
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="trtd-center trtd-13 tr-hov-td" style={{/*border:"2px solid #c1c1c1"*/}} title='This is Setting with Above shown Jewelry'>
                                                                                {/* <td style={{ width: '10%' }}></td> */}
                                                                                <td style={{ width: '30%' }} className="text-left">
                                                                                    <div style={{display:"flex"}}>
                                                                                        <div style={{width:"40%"}}>
                                                                                            <img src={item?.diamonds?.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : item?.diamonds?.image} width="80%" className="max-150-img" onError={(e)=>{
                                                                                                e.target.onerror=null
                                                                                                e.target.src=noimage
                                                                                            }}/>
                                                                                        </div>
                                                                                        <div style={{width:"60%"}}>
                                                                                            {item?.diamonds?.title}<hr style={{margin:0}}/>
                                                                                            <p style={{display:'none'}}>Stock : #{item?.diamonds?.stockid}</p>
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">SKU</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{item?.diamonds?.loatno}</span>
                                                                                            </div>
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">Shape</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{item?.diamonds?.shape_name?.paraname}</span>
                                                                                            </div>
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">Carat</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{(item?.diamonds?.carat).toFixed(2)}</span>
                                                                                            </div>
                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">Clarity</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{item?.diamonds?.clarity_name?.paraname}</span>
                                                                                            </div>

                                                                                            <div className="wishlist_plist">
                                                                                                <span className="sourcesansreg wishlist_prd_left">Color</span>
                                                                                                <span style={{ padding: 8 }}>:</span>
                                                                                                <span className="wishlist_prd_right">{item?.diamonds?.color_name?.paraname}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{moment(item?.entrydate).format('DD-MM-YYYY')}</td>
                                                                                <td>
                                                                                    {/* <span className="pending-tag">{item?.diamonds?.order_current_status?.customerstatuscaption}</span> */}
                                                                                </td>
                                                                                <td style={{textAlign:"right"}}>1</td>
                                                                                <td style={{textAlign:"right"}}>{OrderListDetail?.currency?.sign ?? "$"}{item?.diamonds?.diamondprice}</td>
                                                                                <td style={{textAlign:"right"}}>{OrderListDetail?.currency?.sign ?? "$"}{item?.diamonds?.diamondprice}</td>
                                                                                <td>
                                                                                    {/* {item?.diamonds?.order_current_status?.customerstatuscaption == "Delivered" && return_days >= diffDays ?
                                                                                        <a onClick={() => confirmReturn(item)} title="Return Order" href="javascript:void(0);" className="col-site add-row details-icon show-view"> <i className="fa fa-reply hand" aria-hidden="true"></i>
                                                                                        </a>
                                                                                        : null
                                                                                    } */}
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    }
                                                                    {/* {item?.jewelery != null &&
                                                                        <tr className="trtd-center trtd-13 tr-hov-td" style={{ borderBottomStyle: 'hidden' }}>
                                                                            <td className="f-div-cart" style={{ padding: 10, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <img src={item?.jewelery?.default?.path == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg" : item?.jewelery?.default?.path} width="50%" className="max-150-img" onError={(e)=>{
                                                                                    e.target.onerror=null
                                                                                    e.target.src=noimage
                                                                                }}/>
                                                                            </td>
                                                                            <td colSpan={2} className="s-div-cart" style={{ padding: 10, position: 'relative', textAlign: 'left' }}>
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">Metal</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.jewelery?.metal_stamp?.paraname} {item?.jewelery?.metal_type?.paraname}</span>
                                                                                </div>
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">SKU</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">#{item?.jewelery?.itemcode}</span>
                                                                                </div>
                                                                                {
                                                                                    (item?.ring_size?.paraname != null || item?.ring_size != null) ?
                                                                                        <div className="wishlist_plist">
                                                                                            <span className="sourcesansreg wishlist_prd_left">{
                                                                (item?.category?.name.toLowerCase() == "ring" 
                                                       || item?.category?.name.toLowerCase() == "rings"
                                                       || item?.category?.name.toLowerCase() == "engagement rings"
                                                       || item?.category?.name.toLowerCase() == "engagement ring"
                                                       || item?.category?.name.toLowerCase() == "wedding ring"
                                                       || item?.category?.name.toLowerCase() == "wedding rings") && "Ring Size" 
                                                       }
                                                       {
                                                       (item?.category?.name.toLowerCase() == "bracelets" || item?.category?.name.toLowerCase() == "bracelet") && "Bracelet Length"
                                                        }{
                                                       (item?.category?.name.toLowerCase() == "chains" || item?.category?.name.toLowerCase() == "chain") && "Chain Length"
                                                    }</span>
                                                                                            <span style={{ padding: 8 }}>:</span>
                                                                                            <span className="wishlist_prd_right">{item?.ring_size?.paraname}</span>
                                                                                        </div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                <h6 style={{ color: 'black',fontSize:'0.8rem' }}> Price: ${item?.jewelery?.settingprice} </h6>
                                                                            </td>
                                                                            <td colSpan={2} style={{ verticalAlign: 'bottom' }}>
                                                                                <div className="t-div-cart d-block  d-md-none">
                                                                                    <h4 className="mb-4"> Price: ${item?.jewelery?.settingprice}</h4>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {item?.jewelery != null && item?.diamonds != null ? <tr><td colSpan="6"><hr className="hr-border" /></td></tr> : <tr></tr>}
                                                                    {item?.diamonds != null &&
                                                                        <tr className="trtd-center trtd-13 tr-hov-td" style={{ borderTopStyle: 'hidden' }}>
                                                                            <td className="f-div-cart" style={{ padding: 10, position: 'relative', display: 'flex', borderTopStyle: 'hidden', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <img src={item?.diamonds?.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : item?.diamonds?.image} width="50%" className="max-150-img" onError={(e)=>{
                                                                                    e.target.onerror=null
                                                                                    e.target.src=noimage
                                                                                }}/>
                                                                            </td>
                                                                            <td colSpan={2} className="s-div-cart" style={{ padding: 10, position: 'relative', textAlign: 'left' }}>
                                                                                <p className="mb-1" style={{display:'none'}}> {item?.diamonds?.cut_name != null && item?.diamonds?.cut_name?.paraname + " " + "Cut " + "|"} {item?.diamonds?.color_name != null && item?.diamonds?.color_name?.paraname + " " + "Colour " + "|"} {item?.diamonds?.clarity_name != null && item?.diamonds?.clarity_name?.clarityname + " " + "Clarity"} </p>
                                                                                <p style={{display:'none'}}>Stock : #{item?.diamonds?.stockid}</p>
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">SKU</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.diamonds?.loatno}</span>
                                                                                </div>
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">Shape</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.diamonds?.shape_name?.paraname}</span>
                                                                                </div>
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">Carat</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.diamonds?.carat}</span>
                                                                                </div>
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">Clarity</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.diamonds?.clarity_name?.paraname}</span>
                                                                                </div>

                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">Color</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.diamonds?.color_name?.paraname}</span>
                                                                                </div>
                                                                                <h6 style={{ color: 'black',fontSize:'0.8rem' }}> Price: ${item?.diamonds?.diamondprice} </h6>
                                                                            </td>
                                                                            <td colSpan={2} style={{ verticalAlign: 'bottom' }}>
                                                                                <div className="t-div-cart d-block  d-md-none">
                                                                                    <h6 className="mb-4"> Price: ${item?.diamonds?.diamondprice} </h6>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    } */}
                                                                    </>
                                                                
                                                            )
                                                        })}
                                                        </tbody>

                                                        <tfoot className="myacc-tbl-fot" style={{ width: '100%' }}>
                                                            <tr className="subtotal">
                                                                <td></td>
                                                                <td></td>
                                                                <th colSpan="3" className="mark" scope="row">Subtotal:</th>
                                                                {/* <td className="" data-th="Subtotal"> <span className="price">{OrderListDetail?.currency?.sign ?? "$"}{OrderListDetail?.grossamt}</span> */}
                                                                <td className="" data-th="Subtotal"> <span className="price">{OrderListDetail?.currency?.sign ?? "$"}{subTotal.toFixed(2)}</span>
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr className="shipping">
                                                                <td></td>
                                                                <td></td>
                                                                <th colSpan="3" className="mark" scope="row">Shipping Charge:</th>
                                                                {
                                                                    // console.log("OrderListDetail",OrderListDetail)
                                                                }
                                                                <td className="" data-th="Shipping &amp; Handling"> <span className="price">{(parseFloat(OrderListDetail?.shippingcharge) > 0) ? (OrderListDetail?.currency?.sign ?? "$")+OrderListDetail?.shippingcharge : "Free"}</span>
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                            <tr className="grand_total">
                                                                <td></td>
                                                                <td></td>
                                                                <th colSpan="3" className="mark amount" scope="row"> <strong>Grand Total:</strong>
                                                                </th>
                                                                {/* <td className="amount" data-th="Grand Total"> <strong><span className="price col000">{OrderListDetail?.currency?.sign ?? "$"}{OrderListDetail?.totalamount}</span></strong> */}
                                                                <td className="amount" data-th="Grand Total"> <strong><span className="price col000">{OrderListDetail?.currency?.sign ?? "$"}{(OrderListDetail?.shippingcharge > 0) ? parseFloat(subTotal)+parseFloat(OrderListDetail?.shippingcharge) : subTotal.toFixed(2)}</span></strong>
                                                                </td>
                                                                <td></td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            OrderList?.length <= 0 ?
                                             <Skeleton
                                                count={5}
                                                inline
                                                width='100%'
                                                height='50px'
                                            /> 
                                            :
                                            <div className="ord-list-div" style={{ display: showDetail && 'none' }} >
                                                <div>
                                                    <h5 className="title-1">
                                                        My Order
                                                    </h5>
                                                    {/* <p className="col777">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
                                                    </p> */}
                                                </div>
                                                <hr className="hrmy" />
                                                {
                                                OrderList?.length == 0 ?
                                                    <div
                                                        style={{ display: "grid", justifyContent: "center", flex: 1, backgroundColor: '#f1ecf0' }}
                                                    >
                                                        <img
                                                            src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/diamgif.gif"}
                                                            onError={(e)=>{
                                                                e.target.onerror=null
                                                                e.target.src=noimage
                                                            }}
                                                            alt="loading..."
                                                            style={{ width: 150, height: 150 }}
                                                        />
                                                        <h4>No data Found</h4>
                                                    </div>
                                                    :

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="table-responsive wishlist-tbl">
                                                                <table className="w-100">
                                                                    <thead className="first-wish-th">
                                                                        <tr>
                                                                            <th className="text-left">Order No</th>
                                                                            <th>Order Date</th>
                                                                            <th className="text-left">No Of Item</th>
                                                                            <th>Order Total</th>
                                                                            {/* <th>Status</th> */}
                                                                            <th>Detail</th>
                                                                            <th>Download</th>
                                                                            {/* <th>Action</th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="tr-odd-even">
                                                                        {
                                                                            console.log("OrderList-->",OrderList)
                                                                        }
                                                                        {
                                                                        OrderList?.map((item, index) => {
                                                                            let totalPrice = 0;
                                                                            item?.order_detail?.map((orderDItem) => {
                                                                                if(orderDItem?.jewelery != null && orderDItem?.diamonds == null){
                                                                                    totalPrice += orderDItem?.jewelery?.setting_price;
                                                                                }else if(orderDItem?.jewelery == null && orderDItem?.diamonds != null){
                                                                                    totalPrice += orderDItem?.diamonds?.diamondprice;
                                                                                }else if(orderDItem?.jewelery != null && orderDItem?.diamonds != null){
                                                                                    totalPrice += orderDItem?.jewelery?.setting_price;
                                                                                    totalPrice += orderDItem?.diamonds?.diamondprice;
                                                                                }
                                                                            })

                                                                            return (
                                                                                <tr>
                                                                                    <td style={{textAlign:"center"}}>
                                                                                        {item?.id}
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        {moment(item?.entrydate).format('DD-MM-YYYY')}
                                                                                    </td>
                                                                                    <td className="text-left">
                                                                                        {item?.order_detail.length}
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        {/* <h6> {item?.currency?.sign ?? "$"}{item?.totalamount} </h6> */}
                                                                                        <h6> {item?.currency?.sign ?? "$"}{(item?.shippingcharge > 0) ? (parseFloat(totalPrice)+parseFloat(item?.shippingcharge)).toFixed() : totalPrice.toFixed(2)} </h6>
                                                                                    </td>
                                                                                    {/* <td className="text-center">
                                                                                        <span className="pending-tag">{item?.order_current_status?.customerstatuscaption}</span>
                                                                                    </td> */}
                                                                                    <td className="text-center">
                                                                                        <a className="action-btn show-details-div" onClick={() => { setShowDetail(true); setOrderListDetail(item) }}>
                                                                                            <i className="fa fa-eye hand"></i>
                                                                                        </a>
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        {
                                        item.customerInvoice != null ?
                                            <a title="Download Invoice" href={process.env.REACT_APP_BACKEND_URL+"/customer_invoice/" + item.customerInvoice} target="_blank" className="">
                                                <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/download.png"} className='action-btn' onError={(e)=>{
                                                                                        e.target.onerror=null
                                                                                        e.target.src=noimage
                                                                                    }}/>
                                            </a>
                                        : 
                                        <div className="btnDiv" onClick={() => downloadTxtFile(item, index)}>
                                                                                            <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/download.png"} className='action-btn' onError={(e)=>{
                                                                                        e.target.onerror=null
                                                                                        e.target.src=noimage
                                                                                    }}/>
                                                                                        </div>
                                    }
                                                                                        
                                                                                        {/* <button id="downloadBtn" onClick={() => downloadTxtFile(item, index)} value="download">Download</button> */}

                                                                                    </td>
                                                                                    {/* <td className="text-center">
                                                                                        {
                                                                                            console.log("item",item)
                                                                                        }
                                                                                        {item?.order_current_status?.customerstatuscaption == "Order Placed" || item?.order_current_status?.customerstatuscaption == "Order Confirm" ?
                                                                                            <a style={{ cursor: 'pointer' }} onClick={() => confirmCancel(item)} title="Cancel Order" className="col-site add-row details-icon"> <i className="fa fa-window-close-o" aria-hidden="true"></i>
                                                                                            </a>
                                                                                            : null
                                                                                        }

                                                                                    </td> */}
                                                                                </tr>
                                                                            )
                                                                        })}


                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>

                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* Cancel Order Modal */}
            <Modal show={showCancelModal} onHide={handleCancelModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="form_inquiry_new" style={{width: '100%'}} >
                        <div className="row mar-bot-20">                                                              
                            <div className="form-group mar-bot-15 col-lg-12">
                                <label className="control-label">Cancel Reason </label>
                                <textarea name="remark" id="remark" value={cancelReason} onChange={handelCancelInputChange} className="form-control new-input-box" rows="3" placeholder="Your Cancel Reason" maxLength="500" required=""></textarea>
                                {
                                    cancelResonErr != '' && <p className="text-danger"> { cancelResonErr } </p>
                                }
                            </div>

                            <div className="form-group mar-bot-15 col-lg-12">                                
                                <Button className="btn buy-now-btn mob-btn-47" onClick={()=>handleCancelOrder()}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>                 
            </Modal>

            {/* Return Order Modal */}
            <Modal show={showReturnModal} onHide={handleReturnModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Return Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="form_inquiry_new" style={{width: '100%'}} >
                        <div className="row mar-bot-20">                                                              
                            <div className="form-group mar-bot-15 col-lg-12">
                                <label className="control-label">Return Reason </label>
                                <textarea name="remark" id="remark" value={returnReason} onChange={handelReturnInputChange} className="form-control new-input-box" rows="3" placeholder="Your Return Reason" maxLength="500" required=""></textarea>
                                {
                                    returnResonErr != '' && <p className="text-danger"> { returnResonErr } </p>
                                }
                            </div>

                            <div className="form-group mar-bot-15 col-lg-12">                                
                                <Button className="btn buy-now-btn mob-btn-47" onClick={()=>handleReturnOrder()}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>                 
            </Modal>



            <div className="modal fade" id="address-modal" style={{ display: 'none',zIndex:9999 }} aria-hidden="true" >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title Feijoa">Add New Address</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"> <span aria-hidden="true" style={{ fontSize: 30 }}>×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ paddingLeft: 25, paddingRight: 25 }} >
                            <div className="row collf-14">
                                <div className="col-lg-6">
                                    <div className="form-group form-lablecss">
                                        <label className="lblmy">First Name<span className='mark_red'>*</span></label>
                                        <input type="text"
                                            className="form-control my-textbox1"
                                            placeholder=""
                                            name="Addfirstname"
                                            value={Addfirstname || ""}
                                            onChange={handleAddressChange}
                                        />
                                        <p style={errorStyle}>
                                            {ErrorsData.firstname != undefined && ErrorsData?.firstname?.map((key, i) => key)}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group form-lablecss">
                                        <label className="lblmy">Last Name<span className='mark_red'>*</span></label>
                                        <input type="text" className="form-control my-textbox1"
                                            name="Addlastname"
                                            value={Addlastname || ""}
                                            onChange={handleAddressChange}
                                        />
                                        <p style={errorStyle}>
                                            {ErrorsData.lastname != undefined && ErrorsData?.lastname?.map((key, i) => key)}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className='d-flex'>
                                         <div className="form-group form-lablecss">
                                            <label className="lblmy">Mobile Code<span className='mark_red'>*</span></label>
                                            <input type="text"
                                                className="form-control my-textbox1"
                                                name="mobcode"
                                                value={mobCode}                                               
                                                style={{width:'60%'}}
                                                disabled
                                            />                                            
                                        </div>
                                        <div className="form-group form-lablecss">
                                            <label className="lblmy">Mobile no<span className='mark_red'>*</span></label>
                                            <input type="text"
                                                maxLength={10}
                                                className="form-control my-textbox1"
                                                name="Addmobileno"
                                                value={Addmobileno || ""}
                                                onChange={handleAddressChange}
                                            />
                                            <p style={errorStyle}>
                                                {ErrorsData.mobileno != undefined && ErrorsData?.mobileno?.map((key, i) => key)}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group form-lablecss">
                                        <label className="lblmy">Email id<span className='mark_red'>*</span></label>
                                        <input type="text" className="form-control my-textbox1"
                                            name="Addemail"
                                            value={Addemail || ""}
                                            onChange={handleAddressChange}
                                        />
                                        <p style={errorStyle}>
                                            {ErrorsData.email != undefined && ErrorsData?.email?.map((key, i) => key)}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group form-lablecss">
                                        <label className="lblmy">Address<span className='mark_red'>*</span></label>
                                        <textarea rows="2" className="form-control my-textbox1" style={{ minHeight: 'auto' }}
                                            name="Addaddress"
                                            value={Addaddress || ""}
                                            onChange={handleAddressChange}
                                        ></textarea>
                                        <p style={errorStyle}>
                                            {ErrorsData.address != undefined && ErrorsData?.address?.map((key, i) => key)}
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="col-lg-6">
                                    <div className="form-group form-lablecss">
                                        <label className="lblmy">Country*</label>
                                        <select className="form-control my-textbox1">
                                            <option value="india">India</option>
                                            <option data-code="US" value="United States">United States</option>
                                            <option data-code="VN" value="Vietnam">Vietnam</option>
                                            <option data-code="AU" value="Australia">Australia</option>
                                            <option data-code="AT" value="Austria">Austria</option>
                                            <option data-code="AZ" value="Azerbaijan">Azerbaijan</option>
                                            <option data-code="BS" value="Bahamas">Bahamas</option>
                                            <option data-code="BH" value="Bahrain">Bahrain</option>
                                            <option data-code="BZ" value="Belize">Belize</option>
                                            <option data-code="BJ" value="Benin">Benin</option>
                                        </select>
                                    </div>
                                </div> */}
                                <div className="col-xl-6 col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <label className="lblmy">Country<span className='mark_red'>*</span></label>
                                        <select
                                            value={country}
                                            name="Country"
                                            id="Country"
                                            className="form-control my-textbox1 selectarrow-none"
                                            data-parsley-trigger="change"
                                            data-parsley-errors-container="#Country_msg"
                                            required=""
                                            onChange={(e) => { CallStateApi(e,e.target.value); setCountry(e.target.value) }}
                                        >
                                            <option value="" disabled="" data-code="" >Select Country</option>
                                            {CountryList?.map((item, index) => {
                                                return (
                                                    <option key={Math.random()} value={item?.id} data-value={item?.id} data-code={item?.countrycode} >{item?.name}</option>
                                                )
                                            })}

                                        </select>
                                        <p style={errorStyle}>
                                            {ErrorsData.country != undefined && ErrorsData?.country?.map((key, i) => key)}
                                        </p>
                                        {/* <p id="Country_msg"></p> */}

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group form-lablecss">
                                        <label className="lblmy">State<span className='mark_red'>*</span></label>
                                        <select disabled={country == '' ? true : false}
                                            value={Addstate}
                                            name="State" id="State" className="form-control my-textbox1"
                                            data-parsley-trigger="change"
                                            data-parsley-errors-container="#State_msg"
                                            onChange={(e) => setAddstate(e.target.value)}
                                        >
                                            <option value="" disabled="" >Select State</option>
                                            {stateList?.map((item, index) => {
                                                return (
                                                    <option key={Math.random()} value={item?.id} data-value={item?.id}>{item?.name}</option>
                                                )
                                            })}
                                        </select>
                                        <p style={errorStyle}>
                                            {ErrorsData.state != undefined && ErrorsData?.state?.map((key, i) => key)}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group form-lablecss">
                                        <label className="lblmy">City<span className='mark_red'>*</span></label>
                                        <input type="text"
                                            className="form-control my-textbox1"
                                            name="Addcity"
                                            value={Addcity}
                                            onChange={handleAddressChange}
                                        />
                                        <p style={errorStyle}>
                                            {ErrorsData.city != undefined && ErrorsData?.city?.map((key, i) => key)}
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group form-lablecss">
                                        <label className="lblmy">Pincode<span className='mark_red'>*</span></label>
                                        <input type="text" className="form-control my-textbox1"
                                            name="pincode"
                                            value={pincode}
                                            onChange={handleAddressChange}
                                        />
                                        <p style={errorStyle}>
                                            {ErrorsData.zip != undefined && ErrorsData?.zip?.map((key, i) => key)}
                                        </p>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    {/* <div className="form-group checkbox-wrapper">
                                        <div className="page__section">
                                            <div className="page__toggle">
                                                <label className="toggle">
                                                    <input className="toggle__input" type="checkbox" />
                                                    <span className="toggle__label">
                                                        <span className="toggle__text">Use as my default billing address</span>
                                                    </span>
                                                </label>
                                            </div>

                                        </div> */}
                                    <div className="form-group checkbox-wrapper">
                                        <label className="checkboxs"> Use as my default billing address
                                            <input type="checkbox"
                                                id="isdefault"
                                                name="isdefault"
                                                value="isdefault"
                                                checked={isdefault}
                                                onChange={() => setisdefault(!isdefault)} />
                                            <span className="checkmark"></span>
                                        </label>

                                    </div>

                                </div>
                                {/* <div className="col-lg-12">
                                    <div className="form-group">

                                        <label className="chk-sign mar-top-10" sstyle={{ fontWeight: 500, display: 'inline-block' }}>
                                            Use as my default shipping address
                                            <input type="checkbox" />
                                            <span className="checkmark-chk"></span>
                                        </label>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={() => resetAll()} data-dismiss="modal" className="btn cancel-nowbtn" style={{ minWidth: 120, borderRadius: 0 }}>Close</button>

                            <button type="button" onClick={() => SaveAddress()} className="btn acc-save-nowbtn BtnSave" disabled={addressAddLoading} style={{ minWidth: 120, borderRadius: 0 }}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
        }
        </div>
    )
}
