import React from "react";

function ErrorPage() {
    return (
        <div style={{}}>
            {/* <img  src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/nopage1.jpg"} /> */}
            <img height={400} src={process.env.PUBLIC_URL + "/public/assets/images/nopage1.jpg"} style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",

            }} />
            <a href={process.env.PUBLIC_URL + "/"} className="start-with-btn">
                Go Home
            </a >
        </div >
    );
}

export default ErrorPage;
