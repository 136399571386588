import axios from 'axios'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { BASE_URL, GetAPIUrl } from '../API/APIUrl'
import pr1 from '../assets/images/product-details/r1.jpg'
import pp1 from '../assets/images/product/p1.png'
import pp3 from '../assets/images/product/p3.png'
import noimage from "../assets/images/product-details/noimage.jpg";
import LoadingSpinner from '../module/LoadingSpinner'
import { confirmRemove, ShowErrorMessage, ShowMessage } from '../module/Tostify'
import logo from "../assets/images/logo-img.svg"
import { CallCartCountApi } from '../reducers/userReducers'
import { utf8_to_b64 } from '../helpers/Utility'
import { Colors } from '../constant/Constant'

const $ = window.$;

export default function Cart() {

    const token = localStorage.getItem('token')
    const is_b2b = JSON.parse(localStorage.getItem('data'))
    const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))

    const errDiamond =  Colors.red;
    
    const navigate=useNavigate();

    const [Cartlist, setCartlist] = useState([]);
    const [DiamondError, SetDiamondError] = useState([]);
    const [DiamondErrorMsg, SetDiamondErrorMsg] = useState(false);
    const [Count, setCount] = useState('');
    const [Cartgross, setCartgross] = useState('');
    const [shippingCharge, setShippingCharge] = useState('');

    const [TotalAmount, setTotalAmount] = useState('');

    const [isDisable, setIsDisable] = useState(false)
    const [IsCartLoading, setIsCartLoading] = useState(false)
    const [IsUpdateCartLoading, setIsUpdateCartLoading] = useState(false)

    const [IsWishlistLoading, setIsWishlistLoading] = useState(false)
    const [countQty, setCountQty] = useState(1);
    let customerGroup = JSON.parse(localStorage.getItem('customerGroup'));
    
    useEffect(() => {
        CallFetchCartListApi()
    }, [])

    const CallFetchCartListApi = async () => {
        const controller = new AbortController();
        let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;

        setIsCartLoading(true)
        await axios.get(BASE_URL + GetAPIUrl.CARTLIST_URL + '?is_b2b=' + getdata+'&currency_id='+currency_id, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {

                if (response?.data?.success == true) {
                    setCartlist(response?.data?.carts)
                    setCount(response?.data?.count)
                    setTotalAmount(response?.data?.cart_total)
                    setShippingCharge(response?.data?.shipping?.charge)
                    setCartgross(response?.data?.cart_gross)

                    CallCartCountApi(token)

                } else {
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)

            }).finally(() => {
                setIsCartLoading(false)
            })
        controller.abort()
    }


    const confirmRemoveJewelry = (item) => {

        confirmRemove("Are you Sure","You want to remove item from cart?").then((result) => {
            if (result == true) {
                CallDeleteCart(item)
            }
        })
    }
    const confirmRemoveDiamond = (item) => {

        confirmRemove("Are you Sure","You want to remove diamond from cart?").then((result) => {
            if (result == true) {
                CallDeleteDiamondCart(item)
            }
        })
    }
    const processCheckout = async () => {
        //diamondid
        const controller = new AbortController();

        let diamondIds=[]
        Cartlist.filter(function(item) {
            if(item.diamondid !== null){
                diamondIds.push(item.diamondid)
            }
            return diamondIds
          });
          if(diamondIds.length == 0)
          {
            navigate('/Checkout')
          }else{
            var form = new FormData();
            form.append("diamondid", diamondIds.toString());
            
            await axios.post(BASE_URL + GetAPIUrl.CHECKDIAMOND_URL, form, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => {
                    console.log(response)
                    if(response.data.count == 0){
                        navigate('/Checkout')
                        SetDiamondErrorMsg(false)
                    }else{
                        SetDiamondError(response.data.diamond)
                        SetDiamondErrorMsg(true)
                        ShowErrorMessage(response.data.errormesssage)
                    }
                })
                .catch(error => {
                    ShowErrorMessage(error.message)
                });
          }
        
        controller.abort()
    }

    const CallDeleteCart = async (item) => {
        const controller = new AbortController();
        var form = new FormData();

        form.append("id", item?.id);
        form.append("jewelryid", item?.CartJewelryData?.id);
        form.append("diamondid", "");

        // console.log("fff", [...form]);
        await axios.post(BASE_URL + GetAPIUrl.DELETECART_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {

                    ShowMessage(response.data.message)
                    CallFetchCartListApi()
                } else {

                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)

            });
        controller.abort()
    }
    const CallDeleteDiamondCart = async (item) => {
        const controller = new AbortController();
        var form = new FormData();

        form.append("id", item?.id);
        form.append("jewelryid", "");
        form.append("diamondid", item?.CartDiamondData?.id);

        // console.log("fff", [...form]);
        await axios.post(BASE_URL + GetAPIUrl.DELETECART_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {

                    ShowMessage(response.data.message)
                    CallFetchCartListApi()
                } else {

                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)

            });
        controller.abort()
    }


    const CallAddtoWishlistApi = async (item) => {
        $('.BtnSave').attr('disabled','true');
        setIsDisable(true)
        const controller = new AbortController();
        // setIsWishlistLoading(true)

        var form = new FormData();

        form.append("id", item?.id == null ? "" : item?.id);

        await axios.post(BASE_URL + GetAPIUrl.MOVETOWISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {

                    ShowMessage(response.data.message)
                    CallFetchCartListApi()
                    // window.location.pathname = 'Wishlist'
                } else {
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                ShowErrorMessage(error.message)
                $('.BtnSave').removeAttr('disabled');
            }).finally(() => {
                setIsWishlistLoading(false)
                setIsDisable(false)
            })
        controller.abort()

    }



    const CallUpdateCart = async (id, qty) => {
        const controller = new AbortController();
        setIsCartLoading(true)
        var form = new FormData();
        form.append("id", id);
        form.append("qty", qty == '' || qty == 0 ? 1 : qty);
        form.append("is_b2b", is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b);
        form.append("currency_id", defaultCurrency?.id == null ? null : defaultCurrency?.id);

        // console.log("fff", [...form]);
        await axios.post(BASE_URL + GetAPIUrl.UPDATECART_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    setCartlist(response?.data?.carts)
                    setCount(response?.data?.count)
                    setTotalAmount(response?.data?.cart_total)
                    setShippingCharge(response?.data?.shipping?.charge)
                    setCartgross(response?.data?.cart_gross)
                } else {
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)

            }).finally(() => {
                setIsCartLoading(false)
            });
        controller.abort()
    }
    const textInput = useRef(null);

    useEffect(() => {
        if (textInput.current) {
            textInput.current.focus();
        }
    }, []);
    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 1000);
        };
    };

    const optimizedFn = useCallback(debounce(CallUpdateCart), []);

    const updateQty = (item, qty,index) => {
        // console.log("item",Cartlist);
        //     console.log("",Cartlist.findIndex((obj => obj?.jewelryid == JSON.parse(item?.jewelryid) && obj?.RingSizeData?.id == item?.RingSizeData?.id)));
        if (qty < 100) {
            let objIndex = Cartlist.findIndex((obj => obj?.CartJewelryData?.id == JSON.parse(item?.CartJewelryData?.id)&& obj?.RingSizeData?.id == item?.RingSizeData?.id));

            // console.log("Before update: ", Cartlist[objIndex])

            Cartlist[objIndex].qty = qty

            // console.log("After update: ", Cartlist[objIndex])
            setCartlist(Cartlist => [...Cartlist, [Cartlist[objIndex]]])
            optimizedFn(item?.id, qty)
        }
    };

    return (
        <div>

            <div>
                <div className="container container-main">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={'/'} className='desk-top-title-sm a-taglink'><i className="fa fa-home" aria-hidden="true"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Cart</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="pb-3">
                <div className="container">
                    <div className="text-center max-80per">
                        <h5 className="title-1 mb-2">
                            Shopping Cart
                        </h5>
                        <p style={{ fontSize: 13, color: '#7a7a7a' }}>
                            Choose a diamond to complement the jewellery you wish to create. Our extensive selection of gems feature ten different shapes as well as a range of carat sizes to suit every preference. Each diamond featured in our catalogue is responsibly sourced and comes with certification from a leading grading organisation.
                        </p>
                    </div>
                </div>
            </section>

            <section className="jumbotron pt-2">
                <div className="container container-main">
                    <div>
                        <div className="row">
                            <div className="col-md-12 col-xl-9">
                                <div className="table-responsive mar-bot-25px">
                                    {IsCartLoading ?
                                        <LoadingSpinner /> :
                                        <table id="dia-table" className="table table-striped1" style={{ width: '100%' }}>
                                            <thead className="tbl-header-acc">
                                                <tr className="">
                                                    <th className="text-center" style={{ width: '15%' }}>
                                                        Image
                                                    </th>
                                                    <th className="min-200th" style={{ width: '41%' }}>
                                                        Product Details
                                                    </th>
                                                    <th className="text-center" style={{ minWidth: '110%' }}>
                                                        Quantity
                                                    </th>
                                                    <th className="text-center" style={{ minWidth: '110%' }}>
                                                        Price
                                                    </th>
                                                    <th className="text-center" style={{ minWidth: '110%' }}>
                                                        Sub Total
                                                    </th>
                                                    <th className="text-center" style={{ minWidth: '110%' }}>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            {
                                                Cartlist?.length > 0 ?
                                                    Cartlist?.map((item, index) => {
                                                        let settingPrice = item?.CartJewelryData?.setting_price;
                                                        let customerGroupDiscount = customerGroup != null ? customerGroup?.discount : 0;
                                                        let customerGroupType = customerGroup != null ? customerGroup?.group_type : null;
                                                        if(item?.CartJewelryData?.producttype == 76)
                                                        {
                                                            if(customerGroupDiscount > 0 && customerGroupType != null)
                                                            {
                                                                if(customerGroupType == "fulljewelry")
                                                                {
                                                                    settingPrice = settingPrice - ( (settingPrice * customerGroupDiscount)/100);
                                                                    settingPrice = settingPrice.toFixed(2);
                                                                }
                                                            }                                                            
                                                        }
                                                        
                                                        return (
                                                            <tbody className="tr-unhov-hand table-ver-midl" key={Math.random()}>
                                                                {item?.CartJewelryData != null &&
                                                                    <tr className="trtd-13">
                                                                        <td className="text-center" onContextMenu={(e)=>e.preventDefault()} >
                                                                            <img src={item?.CartJewelryData?.default?.path == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg" : item?.CartJewelryData?.default?.path} className="wish-img" 
                                                                            onError={(e)=>{
                                                                                e.target.onerror=null
                                                                                e.target.src=noimage
                                                                            }}/>
                                                                        </td>
                                                                        <td>
                                                                            {/* <a className="desk-top-title-sm a-taglink" target='_blank' href={process.env.PUBLIC_URL + `/JDetails/${utf8_to_b64(item?.CartJewelryData?.id)}/${item?.CartJewelryData?.slug}?jid=${item?.CartJewelryData?.id}`}> */}
                                                                            <a className="desk-top-title-sm a-taglink" target='_blank' href={process.env.PUBLIC_URL + `/JDetails/${item?.CartJewelryData?.id}/${item?.CartJewelryData?.slug}?jid=${item?.CartJewelryData?.id}`}>
                                                                                <h6 className="heading">
                                                                                    {item?.CartJewelryData?.title}
                                                                                </h6>
                                                                            </a>
                                                                            {/* <h6 className="heading">
                                                                                        {item?.CartJewelryData?.title}
                                                                                    </h6> */}
                                                                            <div className="descList m-w-220">
                                                                                {/* <div className="wishlist_plist">
                                                                                            <span className="sourcesansreg wishlist_prd_left">Stone Type</span>
                                                                                            <span style={{ padding: 8 }}>:</span>
                                                                                            <span className="wishlist_prd_right">Diamond</span>
                                                                                        </div> */}
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">Metal</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.CartJewelryData?.metal_stamp?.paraname} {item?.CartJewelryData?.metal_type?.paraname}</span>
                                                                                </div>
                                                                                {item?.CartJewelryData?.gem_color != null &&
                                                                                    <div className="wishlist_plist">
                                                                                        <span className="sourcesansreg wishlist_prd_left">Gem Color</span>
                                                                                        <span style={{ padding: "0 8px 0 8px" }}>:</span>
                                                                                        <span className="wishlist_prd_right">{item?.CartJewelryData?.gem_color?.paraname}</span>
                                                                                    </div>
                                                                                }
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">SKU</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.CartJewelryData?.itemcode}</span>
                                                                                </div>
                                                                                {
                                                                                        (
                                                                                            item?.CartJewelryData?.category?.name.toLowerCase() == "ring" 
                                                                                            || item?.CartJewelryData?.category?.name.toLowerCase() == "rings"
                                                                                            || item?.CartJewelryData?.category?.name.toLowerCase() == "engagement rings"
                                                                                            || item?.CartJewelryData?.category?.name.toLowerCase() == "engagement ring"
                                                                                            || item?.CartJewelryData?.category?.name.toLowerCase() == "wedding ring"
                                                                                            || item?.CartJewelryData?.category?.name.toLowerCase() == "wedding rings"
                                                                                            || item?.CartJewelryData?.category?.name.toLowerCase() == "chains"
                                                                                            || item?.CartJewelryData?.category?.name.toLowerCase() == "chain"
                                                                                            || item?.CartJewelryData?.category?.name.toLowerCase() == "bracelet"
                                                                                            || item?.CartJewelryData?.category?.name.toLowerCase() == "bracelets"
                                                                                        ) ?
                                                                                        <div className="wishlist_plist">
                                                                                            <span className="sourcesansreg wishlist_prd_left">{
                                                                                                (item?.CartJewelryData?.category?.name?.toLowerCase() == "chains" || item?.CartJewelryData?.category?.name?.toLowerCase() == "chain") ? "Chain" : null
                                                                                            }
                                                                                            { (item?.CartJewelryData?.category?.name?.toLowerCase() == "bracelet" || item?.CartJewelryData?.category?.name?.toLowerCase() == "bracelets") ? "Bracelet" : null }
                                                                                                { (item?.CartJewelryData?.category?.name?.toLowerCase() == "ring" || item?.CartJewelryData?.category?.name?.toLowerCase() == "ring"
                                                                                                || item?.CartJewelryData?.category?.name?.toLowerCase() == "engagement rings" || item?.CartJewelryData?.category?.name?.toLowerCase() == "engagement ring"
                                                                                                || item?.CartJewelryData?.category?.name?.toLowerCase() == "wedding rings" || item?.CartJewelryData?.category?.name?.toLowerCase() == "wedding ring"
                                                                                                ) ? "Ring" : null
                                                                                            } Size</span>
                                                                                            <span style={{ padding: 8 }}>:</span>
                                                                                            <span className="wishlist_prd_right">{item?.RingSizeData?.paraname}</span>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        {/* {item?.CartJewelryData != null && item?.CartDiamondData != null ? */}
                                                                        {item?.CartJewelryData?.producttype == 76 ?
                                                                            <td className="text-center">
                                                                                <div className="quantity buttons_added">
                                                                                    <input type="button" value="-" className="minus" onClick={() => { item?.qty > 1 && CallUpdateCart(item?.id, item?.qty - 1) }} />
                                                                                    <input autoFocus type="number" min={1} step="1" onChange={event => updateQty(item, event.target.value,index)}
                                                                                        name="quantity" title="Qty" ref={textInput} value={item?.qty}
                                                                                        className="input-text qty text" size="4" pattern="" inputMode="" />
                                                                                    <input type="button" value="+" className="plus" onClick={() => { item?.qty < 99 && CallUpdateCart(item?.id, item?.qty + 1) }} />
                                                                                </div>
                                                                            </td>

                                                                            :
                                                                            <td></td>
                                                                        }                                                                       
                                                                        <td className="text-center">
                                                                            {
                                                                                is_b2b?.is_b2b == 0 ? 
                                                                                <span>{defaultCurrency?.sign ?? "$"}{item?.b2c_price.toFixed(2)}</span>
                                                                                :  
                                                                                <span>{defaultCurrency?.sign ?? "$"}{item?.setting_price.toFixed(2)}</span> 
                                                                            }
                                                                            
                                                                        </td>
                                                                        <td className="text-center">
                                                                             {
                                                                                is_b2b?.is_b2b == 0 ? 
                                                                                <span>{defaultCurrency?.sign ?? "$"}{((item?.b2c_price)*item.qty).toFixed(2)}</span>
                                                                                : 
                                                                                 <span>{defaultCurrency?.sign ?? "$"}{((item?.setting_price)*item.qty).toFixed(2)}</span> 
                                                                             }                                                                         </td>
                                                                        <td className="text-center">
                                                                            <button type='button' disabled={isDisable} className="edit-cart BtnSave" title="Move to Wishlist" onClick={() => CallAddtoWishlistApi(item)} style={{border:'none'}}>
                                                                                <i className="fa fa-heart-o" aria-hidden="true"></i>
                                                                            </button>
                                                                            <button type='button' className="delete-cart" title="Delete" onClick={() => confirmRemoveJewelry(item)} style={{border:'none'}}>
                                                                                <i className="fa fa-trash"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {item?.CartJewelryData != null && item?.CartDiamondData != null ?
                                                                    <tr className="trtd-13">
                                                                        <td colSpan="6">
                                                                            <hr className="hr-border" style={{padding:"0",margin:"0"}} />
                                                                        </td>
                                                                    </tr>
                                                                    :
                                                                    <tr className="trtd-13 bor-trlast"></tr>
                                                                }
                                                                {item?.CartDiamondData != null &&
                                                                    <tr className="trtd-13 bor-trlast" >
                                                                        <td className="text-center">
                                                                            <img src={item?.CartDiamondData?.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : item?.CartDiamondData?.image} className="wish-img" 
                                                                            onError={(e)=>{
                                                                                e.target.onerror=null
                                                                                e.target.src=noimage
                                                                            }}/>
                                                                        </td>
                                                                        <td >
                                                                            <Link
                                                                                className="desk-top-title-sm a-taglink"
                                                                                target='_blank'
                                                                                aria-current="page"
                                                                                to={`/DiamondDetails/${utf8_to_b64(item?.CartDiamondData?.id)}/${utf8_to_b64(item?.CartDiamondData?.title)}?did=${item?.CartDiamondData?.id}`}
                                                                            >
                                                                                <h6 className="heading"> {item?.CartDiamondData?.title} </h6>
                                                                            </Link>
                                                                            {/* <h6 className="heading">
                                                                                        {item?.CartDiamondData?.title}
                                                                                    </h6> */}
                                                                            <div className="descList m-w-220">
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">SKU</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.CartDiamondData?.loatno}</span>
                                                                                </div>
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">Shape</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.CartDiamondData?.shape_name?.paraname}</span>
                                                                                </div>
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">Carat</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.CartDiamondData?.carat.toFixed(2)}</span>
                                                                                </div>
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">Clarity</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.CartDiamondData?.clarity_name?.paraname}</span>
                                                                                </div>

                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">Color</span>
                                                                                    <span style={{ padding: 8 }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.CartDiamondData?.color_name?.paraname}</span>
                                                                                </div>

                                                                            </div>
                                                                        </td>
                                                                        <td className="text-center">
                                                                            {
                                                                                DiamondError.includes(item?.CartDiamondData?.id) ? 
                                                                                <span style={{color:"red",fontWeight:"bold"}}>
                                                                                Out Of Stock
                                                                                </span>
                                                                                : null
                                                                            }
                                                                        </td>
                                                                        <td className="text-center" >
                                                                            <span>{defaultCurrency?.sign ?? "$"}{item?.CartDiamondData?.diamondprice}</span>
                                                                        </td>
                                                                        <td className="text-center" >
                                                                            <span>{defaultCurrency?.sign ?? "$"}{(item?.CartDiamondData?.diamondprice*item.qty).toFixed(2)}</span>
                                                                        </td>
                                                                        <td className="text-center" >
                                                                            {item?.CartJewelryData == null &&
                                                                                <button type='button' disabled={isDisable} className="edit-cart BtnSave" title="Move to Wishlist" onClick={() => CallAddtoWishlistApi(item)} style={{border:'none'}}>
                                                                                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                                                                                </button>
                                                                            }
                                                                            <button type='button' className="delete-cart" title="Delete" onClick={() => confirmRemoveDiamond(item)} style={{border:'none'}}>
                                                                                <i className="fa fa-trash"></i>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                            </tbody>
                                                        )
                                                    })


                                                    :
                                                    <tbody className="tr-unhov-hand table-ver-midl">

                                                        <tr>
                                                            <td colSpan={6}>
                                                                <div
                                                                    style={{ display: "grid", flex: 1, justifyContent: "center", alignItems: 'center', backgroundColor: '#f1ecf0' }}
                                                                >
                                                                    <img
                                                                        src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/diamgif.gif"}
                                                                        alt="loading..."
                                                                        style={{ width: 150, height: 150 }}
                                                                        onError={(e)=>{
                                                                            e.target.onerror=null
                                                                            e.target.src=noimage
                                                                        }}
                                                                    />
                                                                    <h4>No data Found</h4>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                            }


                                        </table>
                                    }
                                </div>
                            </div>
                            <div className="col-md-12 col-xl-3">
                                <div style={{ position: 'sticky', top: 100 }}>
                                    <div className="tbl-boxprice">
                                        <div className="page-title1-head mar-bt-15-0">
                                            <div className="cart-estimate-title title-head">
                                                <span>
                                                    <h6>
                                                        <span>Shopping Bag Totals</span>
                                                    </h6>
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <table className="table table-striped mar-bot-tbl pricefooter lasttr pricecolor" style={{border:"none"}}>
                                                <tfoot className="t-foot-cart cart-tb-price">
                                                    <tr className="bor-top-none">
                                                        <td className="text-left-tbl"><span>Sub-Total</span></td>
                                                        <td>
                                                            <span>:</span>
                                                        </td>
                                                        <td className="text-right-tbl fw-6">{defaultCurrency?.sign ?? "$"}{Cartgross}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td className="text-left-tbl"><span>Shipping Charge</span></td>
                                                        <td>
                                                            <span>:</span>
                                                        </td>
                                                        <td className="text-right-tbl fw-6">{shippingCharge != 0 && "$"}{shippingCharge == 0 ? 'Free' : shippingCharge}</td>
                                                    </tr> */}
                                                    <tr>
                                                        <td className="text-left-tbl"><span>No of Items</span></td>
                                                        <td>
                                                            <span>:</span>
                                                        </td>
                                                        <td className="text-right-tbl fw-6">{Count == 0 ? '-' : Count}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td className="text-left-tbl"><b>Discount (5%)</b></td>
                                                        <td>
                                                            <b>:</b>
                                                        </td>
                                                        <td className="text-right-tbl fw-6">$</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-left-tbl"><b>Shipping</b></td>
                                                        <td>
                                                            <b>:</b>
                                                        </td>
                                                        <td className="text-right-tbl fw-6">Free</td>
                                                    </tr> */}
                                                    <tr>
                                                        <td className="text-left-tbl col-333"><span>Total Price</span></td>
                                                        <td>

                                                        </td>
                                                        <td className="text-right-tbl col-333"><span>{defaultCurrency?.sign ?? "$"}{TotalAmount-shippingCharge}</span></td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                    <div >
                                        <Link
                                            className=""
                                            aria-current="page"
                                            to={`/Jewelry/Build-Your-Own/${localStorage.getItem("EngagmentName")}?cid=${localStorage.getItem("EngagmentID")}`}
                                        >
                                            <button className="add-cart-me btn-shadow-me w-100">Continue Shopping</button>
                                        </Link>

                                    </div>
                                    {Cartlist?.length > 0 &&
                                        <div>
                                            <button onClick={processCheckout} className="add-cart-me btn-shadow-me w-100">Proceed To Checkout</button>
                                            {/* <Link
                                                className=""
                                                aria-current="page"
                                                to={`/Checkout`}
                                            >
                                                <button className="add-cart-me btn-shadow-me w-100">Proceed To Checkout</button>
                                            </Link> */}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
