import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL, GetAPIUrl } from '../API/APIUrl';
import { Colors } from '../constant/Constant';
import { fetch2 } from '../helpers/fetch';
import LoadingSpinner from '../module/LoadingSpinner';
import { ShowErrorMessage } from '../module/Tostify';

const validation = {
    color: Colors.red,
    // height: 10
};
const Appointment = ({ authorized }) => {

    // const [countryname, setCountryName] = useState('')

    // const [countryid, setCountryId] = useState('')

    const [companyname, setCompanyName] = useState('')
    const [firstname, setFirstName] = useState('')
    const [lastname, setLastName] = useState('')
    const [address, setAddress] = useState('')
    const [country, setCountry] = useState('')
    const [CountryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [pincode, setPincode] = useState('')
    const [phoneNo, setPhoneNo] = useState('')

    const [whatsapp_no, setwhatsappNo] = useState('')
    const [website, setWebsite] = useState('')

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isShowPassword, setIsShowPassword] = useState(false)
    const [isShowConfiPassword, setIsShowConfiPassword] = useState(false)

    const [confirm_password, setConfirm_password] = useState('')
    const [agree, setAgree] = useState(false)
    let navigate = useNavigate();
    const [Loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.user)
    const [SignupB2BData, setSignupB2BData] = useState([])


    useEffect(() => {
        CallCountryApi()
    }, [])
    const authenticate = async event => {
        event.preventDefault()
        setLoading(true)
        // dispatch(signupB2BUser(
        //     {
        //         companyname, firstname, lastname, address, website,
        //         country, email, state, city, pincode, phoneNo,
        //         whatsapp_no, password, confirm_password
        //     }
        // ))
        let data = {
            "companyname": companyname,
            "firstname": firstname,
            "lastname": lastname,
            "address": address,
            "website": website,
            "country": country,
            "state": state,
            "city": city,
            "pincode": pincode,
            "mobileno": phoneNo,
            "whatsapp_no": whatsapp_no,
            "email": email,
            "password": password,
            "confirm_password": confirm_password,
        }
        const result = await fetch2(BASE_URL + GetAPIUrl.B2BSIGNUP_URL, data, userDetails.token)
        // console.log("B2BSIGNUP_URL", result);
        if (result?.success == true) {
            window.location.href = process.env.PUBLIC_URL + "/Login"
            setLoading(false)
        } else {
            ShowErrorMessage(result.message)
            setSignupB2BData(result.errors)
            setLoading(false)
        }

    }
    const CallCountryApi = async () => {
        const result = await axios.get(BASE_URL + GetAPIUrl.COUNTRY)
        if (result.data.success == true) {
            setCountryList(result.data.data)
        }
    }
    const CallStateApi = async (countryid) => {
        let data = {
            "countryid": countryid,
        }
        const result = await fetch2(BASE_URL + GetAPIUrl.STATE, data)
        if (result.success == true) {
            setStateList(result.data)
        }
        // console.log("result", result);
    }
    if (authorized) {
        return <Navigate to={process.env.PUBLIC_URL + "/"} />;
    }
    const ResetBtn = async (event) => {
        event.preventDefault()
        setCompanyName('')
        setFirstName('')
        setLastName('')
        setAddress('')
        setCountry('')
        setState('')
        setCity('')
        setPincode('')
        setPhoneNo('')
        setEmail('')
        setPassword('')
        setConfirm_password('')
        setwhatsappNo('')
        setWebsite('')

    }
    return (
        <>
            {Loading == true &&

                <LoadingSpinner />
            }
            <div className="main-section">
                <div className="loader" style={{ display: "none" }}>
                    <div className="diamondCon">
                        <ul className="diamond">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>

                <div className="main-section">
                    <section className="pt-5 pb-5 mob-ptpb-1">
                        <br />
                        <div className="container">
                            <div style={{ maxWidth: "800px", margin: "auto", boxShadow: "0px 2px 10px 0px #0530691a" }}>
                                <form className="input-form-div signup-div" id="signupForm" method="POST" onSubmit="return false" noValidate="">
                                    <input type="hidden" name="c_group_id" value="1" />
                                    <div className="mb-5 text-center">
                                        <h1 className="main-color">
                                            Take an Appointment
                                        </h1>
                                        <p>
                                            Please fill in the below details to create an account with Diora Adams. Make sure to log in on your next visit for faster checkout.
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h5 className="main-color">
                                                Company Information
                                            </h5>

                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">Company Name <sup>*</sup></label>
                                                    <input
                                                        className="form-control inputboxx"
                                                        id="CompanyName"
                                                        data-parsley-trigger="change"
                                                        data-parsley-errors-container="#CompanyName_msg"
                                                        name="CompanyName"
                                                        placeholder="Enter company name"
                                                        value={companyname}
                                                        onChange={e => setCompanyName(e.target.value)}
                                                        required="" />
                                                    <p id="CompanyName_msg" style={validation}>
                                                        {SignupB2BData?.companyname != undefined && SignupB2BData?.companyname?.map((key, i) => key)}
                                                    </p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <h5 className="main-color">
                                                Personal Information
                                            </h5>
                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">First Name <sup>*</sup></label>
                                                    <input
                                                        className="form-control inputboxx"
                                                        name="FirstName"
                                                        data-parsley-trigger="change"
                                                        minLength="3"
                                                        maxLength="30"
                                                        required=""
                                                        data-parsley-pattern="^[a-zA-Z]+$"
                                                        value={firstname}
                                                        onChange={e => setFirstName(e.target.value)}
                                                        placeholder="Enter first name" />
                                                    <p style={validation}>
                                                        {SignupB2BData?.firstname != undefined && SignupB2BData?.firstname?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">Last Name <sup>*</sup></label>
                                                    <input
                                                        className="form-control inputboxx"
                                                        name="LastName"
                                                        data-parsley-trigger="change"
                                                        minLength="3"
                                                        maxLength="30"
                                                        required=""
                                                        data-parsley-pattern="^[a-zA-Z]+$"
                                                        placeholder="Enter last name"
                                                        value={lastname}
                                                        onChange={e => setLastName(e.target.value)}
                                                    />
                                                    <p style={validation}>
                                                        {SignupB2BData?.lastname != undefined && SignupB2BData?.lastname?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">Address <sup>*</sup></label>
                                                    <textarea
                                                        className="form-control inputboxx"
                                                        name="Address"
                                                        data-parsley-trigger="change"
                                                        placeholder="Enter address"
                                                        required=""
                                                        value={address}
                                                        onChange={e => setAddress(e.target.value)}
                                                    > </textarea>
                                                    <p style={validation}>
                                                        {SignupB2BData?.address != undefined && SignupB2BData?.address?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label className="lblmy">Country <sup>*</sup></label>
                                                <select

                                                    name="Country"
                                                    id="Country"
                                                    className="form-control my-textbox selectarrow-none"
                                                    data-parsley-trigger="change"
                                                    data-parsley-errors-container="#Country_msg"
                                                    required=""
                                                    onChange={(e) => { CallStateApi(e.target.value); setCountry(e.target.value) }}
                                                >
                                                    <option value="" disabled="" selected=""> {country == '' ? "Select Country" : country} </option>
                                                    {CountryList?.map((item, i) => {
                                                        return (
                                                            <option key={Math.random()} value={item?.id} data-value={item?.id}>{item?.name}</option>
                                                        )
                                                    })}

                                                </select>
                                                <p id="Country_msg"></p>
                                                <p style={validation}>
                                                    {SignupB2BData?.country != '' && SignupB2BData?.country?.map((key, i) => key)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6">
                                            <div className="form-group">
                                                <label className="lblmy">State <sup>*</sup></label>
                                                <select disabled={country == '' ? true : false}
                                                    name="State" id="State" className="form-control my-textbox"
                                                    data-parsley-trigger="change"
                                                    data-parsley-errors-container="#State_msg"
                                                    onChange={(e) => setState(e.target.value)}
                                                >
                                                    <option value="" disabled="" selected=""> {state == '' ? "Select State" : state} </option>
                                                    {stateList?.map((item, i) => {
                                                        return (
                                                            <option key={Math.random()} value={item?.id} data-value={item?.id}>{item?.name}</option>
                                                        )
                                                    })}
                                                </select>
                                                <p id="State_msg"></p>
                                                <p style={validation}>
                                                    {SignupB2BData?.state != undefined && SignupB2BData?.state?.map((key, i) => key)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">City <sup>*</sup></label>
                                                    <input
                                                        className="form-control inputboxx"
                                                        name="City"
                                                        data-parsley-trigger="change"
                                                        placeholder="Enter city"
                                                        required=""
                                                        value={city}
                                                        onChange={e => setCity(e.target.value)}
                                                    />
                                                    <p style={validation}>
                                                        {SignupB2BData?.city != undefined && SignupB2BData?.city?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">Zip Code <sup>*</sup></label>
                                                    <input
                                                        className="form-control inputboxx"
                                                        name="ZipCode"
                                                        data-parsley-trigger="change"
                                                        placeholder="Enter zip code"
                                                        required=""
                                                        minLength="6"
                                                        maxLength="6"
                                                        value={pincode}
                                                        onChange={e => setPincode(e.target.value)}
                                                    />
                                                    <p style={validation}>
                                                        {SignupB2BData?.pincode != '' && SignupB2BData?.pincode?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">Phone No</label>
                                                    <input
                                                        className="form-control inputboxx"
                                                        name="PhoneNo"
                                                        data-parsley-trigger="change"
                                                        minLength="7" maxLength="12"
                                                        data-parsley-pattern="/^[0-9+]+$/"
                                                        placeholder="Enter Mobile no"
                                                        value={phoneNo}
                                                        onChange={e => setPhoneNo(e.target.value)}
                                                    />
                                                    <p style={validation}>
                                                        {SignupB2BData?.mobileno != undefined && SignupB2BData?.mobileno?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">Whatsapp No</label>
                                                    <input
                                                        max={12}
                                                        className="form-control inputboxx"
                                                        name="MobileNo"
                                                        data-parsley-trigger="change"
                                                        minLength="7" maxLength="12"
                                                        data-parsley-pattern="/^[0-9+]+$/"
                                                        placeholder="Enter Whatsapp no"
                                                        required=""
                                                        value={whatsapp_no}
                                                        onChange={e => setwhatsappNo(e.target.value)} />
                                                    <p style={validation}>
                                                        {SignupB2BData?.whatsapp_no != undefined && SignupB2BData?.whatsapp_no?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">Email Id <sup>*</sup></label>
                                                    <input className="form-control inputboxx"
                                                        type="email" name="Email"
                                                        data-parsley-trigger="change"
                                                        placeholder="Enter email id"
                                                        required=""
                                                        value={email}
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                    <p style={validation}>
                                                        {SignupB2BData?.email != undefined && SignupB2BData?.email?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">Website </label>
                                                    <input className="form-control inputboxx"
                                                        name="Website" data-parsley-trigger="change"
                                                        data-parsley-type="url"
                                                        placeholder="Enter website"
                                                        value={website}
                                                        onChange={e => setWebsite(e.target.value)}
                                                    />
                                                    <p style={validation}>
                                                        {SignupB2BData?.website != undefined && SignupB2BData?.website?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">Password <sup>*</sup></label>
                                                    <input className="form-control inputboxx"
                                                        style={{ font: isShowPassword && "small-caption", }}
                                                        autoComplete="new-password"
                                                        type={isShowPassword ? "text" : "password"}
                                                        name="Password"
                                                        id="Password"
                                                        data-parsley-trigger="change"
                                                        minLength="6" maxLength="16"
                                                        data-parsley-pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%^*?&amp;])[A-Za-z\d@$#!%^*?&amp;]{6,16}$" data-parsley-pattern-message="Password field should contain min. 6 and max. 16 characters. It must contain at least one number, one special character(!@#$%^&amp;*), one capital alphabet and one small alphabet."
                                                        placeholder="Enter password"
                                                        required=""
                                                        value={password}
                                                        onChange={e => setPassword(e.target.value)}
                                                    />
                                                    <span className="show-pass hand" title={isShowPassword ? "show password" : "Hide password"}
                                                        onClick={() => setIsShowPassword(!isShowPassword)}>
                                                        <i className={isShowPassword ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                    </span>
                                                    <p style={validation}>
                                                        {SignupB2BData?.password != undefined && SignupB2BData?.password?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div>
                                                <div className="form-group form-groupmy">
                                                    <label className="lblmy">Confirm Password <sup>*</sup></label>
                                                    <input className="form-control inputboxx"
                                                        style={{ font: isShowConfiPassword && "small-caption", }}
                                                        type={isShowConfiPassword ? "text" : "password"}
                                                        name="CPassword"
                                                        data-parsley-trigger="change"
                                                        minLength="6"
                                                        maxLength="16"
                                                        data-parsley-equalto="#Password"
                                                        data-parsley-pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%^*?&amp;])[A-Za-z\d@$#!%^*?&amp;]{6,16}$"
                                                        data-parsley-pattern-message="Password field should contain min. 6 and max. 16 characters. It must contain at least one number, one special character(!@#$%^&amp;*), one capital alphabet and one small alphabet."
                                                        placeholder="Enter confirm password"
                                                        required=""
                                                        value={confirm_password}
                                                        onChange={e => setConfirm_password(e.target.value)}
                                                    />
                                                    <span className="show-pass hand" title={isShowConfiPassword ? "show password" : "Hide password"}
                                                        onClick={() => setIsShowConfiPassword(!isShowConfiPassword)}>
                                                        <i className={isShowConfiPassword ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                    </span>
                                                    <p style={validation}>
                                                        {SignupB2BData?.confirm_password != undefined && SignupB2BData?.confirm_password?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6">
                                        <div>
                                            <div className="form-group form-groupmy">
                                                <label className="lblmy">Date of Birth</label>
                                                <input type="date" className="form-control signdob" name="DOB" placeholder="Enter date of birth" max="2022-08-04" />
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="col-lg-12">
                                            <div className="form-group checkbox-wrapper">
                                                <label className="checkboxs"> {"By signing up you agree to our" + ' '}
                                                    <a className="main-color"
                                                        href="https://www.rclgd.com/privacypolicy"
                                                        style={{ textDecoration: "underline" }} required="" >
                                                        Conditions &amp; Privacy Policy.
                                                    </a>
                                                    <input type="checkbox"
                                                        name="Check"
                                                        id="CheckTerms"
                                                        data-parsley-multiple="Check"
                                                        value={agree}
                                                        onChange={() => setAgree(!agree)}
                                                    // checked={isdefault}
                                                    // onChange={() => setisdefault(!isdefault)} 
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                        </div>
                                        {/* <div className="col-lg-12">
                                            <div className="form-group">
                                                <div className="g-recaptcha capcha" data-sitekey="6Lekop4eAAAAAImVd5GylAMA2tnJmiiq3bj5e6pc">
                                                    <div style={{ width: "304px", height: "78px" }}><div><iframe title="reCAPTCHA" src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6Lekop4eAAAAAImVd5GylAMA2tnJmiiq3bj5e6pc&amp;co=aHR0cHM6Ly93d3cucmNsZ2QuY29tOjQ0Mw..&amp;hl=en&amp;v=gWN_U6xTIPevg0vuq7g1hct0&amp;size=normal&amp;cb=rfx1vnjq5kx" width="304" height="78" role="presentation" name="a-z5k3l85wuis1" frameBorder="0" scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"></iframe></div><textarea id="g-recaptcha-response" name="g-recaptcha-response" className="g-recaptcha-response"
                                                        style={{ width: "250px", height: "40px", border: "1px solid rgb(193, 193, 193)", margin: "10px 25px", padding: "0px", resize: "none", display: "none" }}></textarea></div><iframe style={{ display: "none" }}></iframe></div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-lg-12" >
                                            <div style={{ display: 'flex' }}>
                                                <button disabled={true} type="submit"
                                                    className="btn shop-now-btn mob-100 mob-b-10"
                                                    style={{
                                                        backgroundColor: !agree && 'lightgray', minWidth: "150px"
                                                    }}>Sign up
                                                </button>
                                                <button type="reset" className="btn shop-now-btn mob-100 mob-b-10 reset-btn" onClick={ResetBtn}
                                                    style={{ minWidth: "150px" }}>Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <br />
                        <br />
                    </section>
                    <ToastContainer />
                </div >

            </div >
        </>
    )
}

export default Appointment