import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import Form from 'react-bootstrap/Form';

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BASE_URL, GetAPIUrl } from '../API/APIUrl'
import { Colors } from '../constant/Constant'
import { fetch2 } from '../helpers/fetch'
import LoadingSpinner from '../module/LoadingSpinner'
import { ShowErrorMessage, ShowMessage } from '../module/Tostify'
import { signupB2CUser } from '../reducers/userReducers'
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';

const Signup = ({ authorized }) => {
    const recaptchaRef = React.createRef();

    const [verify,setVerify]=useState(false)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileno, setMobileNo] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showPassShow, setShowPassShow] = useState(false)
    const [showConfiPassShow, setShowConfiPassShow] = useState(false)

    const [agree, setAgree] = useState(false)

    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.user)
    const [SignupData, setSignupData] = useState([])
    let navigate = useNavigate();
    const [Loading, setLoading] = useState(false)

    const authenticate = async event => {
        event.preventDefault()
        setLoading(true)
        // dispatch(signupB2CUser({ firstName, lastName, email, password, confirmPassword }))
        let data = {
            "firstname": firstName,
            "lastname": lastName,
            "email": email,
            "mobileno": mobileno,
            "password": password,
            "confirm_password": confirmPassword,
        }
        const result = await fetch2(BASE_URL + GetAPIUrl.B2CSIGNUP_URL, data, userDetails.token)
        if (result?.success == true) {
            setLoading(false)
            // ShowMessage(result.message)  
            Swal.fire({
                title: "Registration!",
                text: result.message,
                icon: 'success',
                confirmButtonText: "Ok",
            }).then((result) => {
                if (result.isConfirmed ) {
                    window.location.href = process.env.PUBLIC_URL + "/Login";
                }
            });          
            // setTimeout(function(){
            //     window.location.href = process.env.PUBLIC_URL + "/Login";
            // }, 2000);
            
        } else {
            ShowErrorMessage(result.message)
            setSignupData(result.errors)
            setLoading(false)
        }
    }

    function handleVerifyccapcha(value) {
        setVerify(true)
      }

    const URLCheck = localStorage.getItem('URL')

    if (authorized) {
       if(URLCheck != undefined || URLCheck != null){
            window.location.replace(process.env.PUBLIC_URL + URLCheck)
        }else{
            window.location.replace(process.env.PUBLIC_URL + "/")
        }
    }
    return (
        <>
            {Loading == true &&

                <LoadingSpinner />
            }

            <div>
                <div className="container container-main">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={'/'}><i className="fa fa-home" aria-hidden="true"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Signup</li>
                        </ol>
                    </nav>
                </div>
            </div>


            <div className="main-section">

                <div className="main-section">

                    <section className="pb-5 mob-ptpb-1">

                        <div className="container">

                            <div style={{ maxWidth: "600px", margin: "auto", boxShadow: "0px 2px 10px 0px #0530691a" }}>

                            <Form className="input-form-div signup-div" style={{padding:"1rem 1rem"}}>
                            <div className="text-center">
                                            <h3 className="main-color">
                                                Sign Up
                                            </h3>
                                        </div>
                            <div className="">
                                    <div className="row pad-col-5">
                                        <div className="col-xl-6">
                                            <div className="form-group">
                                                <label className="lblmy lblmy-active">First Name<span className='mark_red'>*</span></label>
                                                <input
                                                    autoFocus   
                                                    value={firstName}
                                                    onChange={e => setFirstName(e.target.value)}
                                                    type="text" className="form-control my-textbox1" placeholder="Enter First Name" />
                                                    {SignupData?.firstname != undefined ? 
                                                <p style={{ color: Colors.red, height: 8,marginBottom:0 }}>
                                                    {SignupData?.firstname != undefined && SignupData?.firstname?.map((key, i) => key)}
                                                </p> : null }
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="form-group">
                                                <label className="lblmy">Last Name<span className='mark_red'>*</span></label>
                                                <input
                                                    value={lastName}
                                                    onChange={e => setLastName(e.target.value)}
                                                    type="text" className="form-control my-textbox1" placeholder="Enter Last Name" />
                                                    {SignupData?.lastname != undefined ? 
                                                <p style={{ color: Colors.red, height: 8,marginBottom:0 }}>
                                                    {SignupData?.lastname != undefined && SignupData?.lastname?.map((key, i) => key)}
                                                </p> : null }
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className="lblmy">Email Id<span className='mark_red'>*</span></label>
                                                <input
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    type="text"
                                                    className="form-control my-textbox1"
                                                    placeholder="Enter Email Id" />
                                                    {SignupData?.email != undefined ? 
                                                <p style={{ color: Colors.red, height: 8,marginBottom:0 }}>
                                                    {SignupData?.email != undefined && SignupData?.email?.map((key, i) => key)}
                                                </p> : null }
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className="lblmy">Mobile No<span className='mark_red'>*</span></label>
                                                <input
                                                     className="form-control inputboxx1"
                                                     name="mobileno"
                                                     data-parsley-trigger="change"
                                                     minLength="7" maxLength="12"
                                                     data-parsley-pattern="/^[0-9+]+$/"
                                                     placeholder="Enter Mobile no"
                                                     value={mobileno}
                                                     onChange={e => setMobileNo(e.target.value)} />
                                                    {SignupData?.mobileno != undefined ? 
                                                <p style={{ color: Colors.red, height: 8,marginBottom:0 }}>
                                                    {SignupData?.mobileno != undefined && SignupData?.mobileno?.map((key, i) => key)}
                                                </p> : null }
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="form-group pos-relative">
                                                <label className="lblmy">Password<span className='mark_red'>*</span></label>
                                                <input
                                                    style={{ font: showPassShow && "small-caption", }}
                                                    value={password}
                                                    type={showPassShow ? "text" : 'password'}
                                                    id="showpassword"
                                                    onChange={e => setPassword(e.target.value)}
                                                    className="form-control my-textbox1" placeholder="Enter Password" />
                                                <span className="show-pass hand" title={showPassShow ? "show password" : "Hide password"} onClick={() => setShowPassShow(!showPassShow)}
                                                    style={{ paddingRight: "8px" }} >
                                                    <i className={showPassShow ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                </span>
                                                {SignupData?.password != undefined ? 
                                                <p style={{ color: Colors.red, height: 20,marginBottom:0 }}>
                                                    {SignupData?.password != undefined && SignupData?.password?.map((key, i) => key)}
                                                </p> : null }
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="form-group pos-relative">
                                                <label className="lblmy">Confirm Password<span className='mark_red'>*</span></label>
                                                <input
                                                    style={{ font: showConfiPassShow && "small-caption", }}
                                                    value={confirmPassword}
                                                    type={showConfiPassShow ? "text" : 'password'}
                                                    id="showconfipassword"
                                                    onChange={e => setConfirmPassword(e.target.value)}
                                                    className="form-control my-textbox1" placeholder="Enter Confirm Password" />
                                                <span className="show-pass hand" title={showConfiPassShow ? "show password" : "Hide password"} onClick={() => setShowConfiPassShow(!showConfiPassShow)}
                                                    style={{ paddingRight: "8px" }} >
                                                    <i className={showConfiPassShow ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                </span>
                                                {SignupData?.confirm_password != undefined ? 
                                                <p style={{ color: Colors.red, height: 20,marginBottom:0 }}>
                                                    {SignupData?.confirm_password != undefined && SignupData?.confirm_password?.map((key, i) => key)}
                                                </p> : null }
                                            </div>
                                        </div>
                                        <div className='col-xl-12'>
                                            <div className="form-group checkbox-wrapper">
                                                    <label className="checkboxs"> {"By signing up you agree to our" + ' '}
                                                        <a className="main-color" target='_blank'
                                                            href={process.env.PUBLIC_URL + '/Policy'}
                                                            style={{ textDecoration: "underline" }} required="" >
                                                            Conditions &amp; Privacy Policy.
                                                        </a>
                                                        <input type="checkbox"
                                                            name="Check"
                                                            id="CheckTerms"
                                                            data-parsley-multiple="Check"
                                                            value={agree}
                                                            onChange={() => setAgree(!agree)}
                                                        // checked={isdefault}
                                                        // onChange={() => setisdefault(!isdefault)} 
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                        </div>
                                        <div className='col-xl-12'>
                                            <ReCAPTCHA onExpired={() => {
              recaptchaRef.current.reset(); // here
            }}
                    sitekey={process.env.REACT_APP_SITE_KEY} 
                    onChange={handleVerifyccapcha}/>
                                        </div>
                                        <div className="col-xl-12 text-center">
                                            <button disabled={(agree == false || verify == false) ? true : false} onClick={authenticate}
                                                className="login-me btn-shadow-me min-w-200"
                                                // className="btn shop-now-btn mob-100 mob-b-10 text-center"
                                                style={{
                                                    backgroundColor: (agree == false || verify == false) && 'lightgray', minWidth: "150px"
                                                }}
                                            >Sign up</button>
                                        </div>
                                        <div className="w-100">
                                            <div className="col-xl-12 mt-2">
                                                <p className="text-center">
                                                    {"Have an account ?" + " "}
                                                    <Link
                                                        className="login-link"
                                                        aria-current="page"
                                                        to={'/Login'}
                                                    >
                                                        Login
                                                    </Link>
                                                </p>
                                            </div>
                                            <div className="col-xl-12 mt-2">
                                                <p className="text-center">
                                                    {" Own a Business ?" + " "}
                                                    <Link
                                                        className="login-link"
                                                        aria-current="page"
                                                        to={'/SignupB2B'}
                                                    >
                                                        Register here
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    
                            </Form>
                                
                            </div>
                        </div>
                        
                    </section>

                </div>

            </div>
            {/* <section className="jumbotron">
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">
                            <form className="login100-form validate-form">
                                <span className="login100-form-title p-b-34">Sign Up</span>
                                <div className="">
                                    <div className="row pad-col-5">
                                        <div className="col-xl-6">
                                            <div className="form-group">
                                                <label className="lblmy lblmy-active">First Name<span className='mark_red'>*</span></label>
                                                <input
                                                    autoFocus   
                                                    value={firstName}
                                                    onChange={e => setFirstName(e.target.value)}
                                                    type="text" className="form-control my-textbox" placeholder="Enter First Name" />
                                                <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                    {SignupData?.firstname != undefined && SignupData?.firstname?.map((key, i) => key)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="form-group">
                                                <label className="lblmy">Last Name<span className='mark_red'>*</span></label>
                                                <input
                                                    value={lastName}
                                                    onChange={e => setLastName(e.target.value)}
                                                    type="text" className="form-control my-textbox" placeholder="Enter Last Name" />
                                                <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                    {SignupData?.lastname != undefined && SignupData?.lastname?.map((key, i) => key)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className="lblmy">Email Id<span className='mark_red'>*</span></label>
                                                <input
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    type="text"
                                                    className="form-control my-textbox"
                                                    placeholder="Enter Email Id" />
                                                <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                    {SignupData?.email != undefined && SignupData?.email?.map((key, i) => key)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className="lblmy">Password<span className='mark_red'>*</span></label>
                                                <input
                                                    style={{ font: showPassShow && "small-caption", }}
                                                    value={password}
                                                    type={showPassShow ? "text" : 'password'}
                                                    id="showpassword"
                                                    onChange={e => setPassword(e.target.value)}
                                                    className="form-control my-textbox" placeholder="Enter Password" />
                                                <span className="show-pass hand" title={showPassShow ? "show password" : "Hide password"} onClick={() => setShowPassShow(!showPassShow)}
                                                    style={{ padding: "5px" }} >
                                                    <i className={showPassShow ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                </span>
                                                <p style={{ color: Colors.red, height: 15, padding: 4 }}>

                                                    {SignupData?.password != undefined && SignupData?.password?.map((key, i) => key)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className="lblmy">Confirm Password<span className='mark_red'>*</span></label>
                                                <input
                                                    style={{ font: showConfiPassShow && "small-caption", }}
                                                    value={confirmPassword}
                                                    type={showConfiPassShow ? "text" : 'password'}
                                                    id="showconfipassword"
                                                    onChange={e => setConfirmPassword(e.target.value)}
                                                    className="form-control my-textbox" placeholder="Enter Confirm Password" />
                                                <span className="show-pass hand" title={showConfiPassShow ? "show password" : "Hide password"} onClick={() => setShowConfiPassShow(!showConfiPassShow)}
                                                    style={{ padding: "5px" }} >
                                                    <i className={showConfiPassShow ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                </span>
                                                <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                    {SignupData?.confirm_password != undefined && SignupData?.confirm_password?.map((key, i) => key)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 text-center">
                                            <br />
                                            <button onClick={authenticate}
                                                className="login-me btn-shadow-me min-w-200"
                                            >Sign up</button>
                                        </div>
                                        <div className="w-100">
                                            <div className="col-xl-12 mt-2">
                                                <p className="text-center" style={{ fontWeight: "600" }}>
                                                    {"Have an account ?" + " "}
                                                    <Link
                                                        className="login-link"
                                                        aria-current="page"
                                                        to={'/Login'}
                                                    >
                                                        Login
                                                    </Link>
                                                </p>
                                            </div>
                                            <div className="col-xl-12 mt-2">
                                                <p className="text-center" style={{ fontWeight: "600" }}>
                                                    {" Own a Business ?" + " "}
                                                    <Link
                                                        className="login-link"
                                                        aria-current="page"
                                                        to={'/SignupB2B'}
                                                    >
                                                        Register here
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="login100-more d-none d-xl-block d-lg-block" style={{ backgroundImage: `url('./assets/images/login/login-banner.jpg')` }}></div>
                        </div>
                    </div>
                </div>
            </section> */}
            <ToastContainer />
        </>
    )
}

export default Signup