import React, { useEffect, useState, useRef } from 'react'
import Breadcrumb from "react-bootstrap/Breadcrumb"; //! npm install react-bootstrap bootstrap
import { FaHome } from "react-icons/fa";   //! npm install react-icons --save
import './CustomDesign.css'
import LeftSideFrom from './LeftSideFrom';
import RightSideFrom from './RightSideFrom';
import GuideText from './GuideText';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, GetAPIUrl } from '../../API/APIUrl';
import { Colors } from "../../constant/Constant";
import LoadingSpinner from '../../module/LoadingSpinner';
import { ShowErrorMessage, ShowMessage } from '../../module/Tostify';
import Multiselect from "multiselect-react-dropdown";
import Img from "./Img";
import SideStone from './Stone Filter/SideStone';
import { BiPlusMedical } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import ReCAPTCHA from 'react-google-recaptcha';

const validation = {
  color: Colors.red,
  // height: 10
};

const CustomDesign = (props) => {
  const recaptchaRef = React.createRef();
  const location = useLocation();

  localStorage.setItem('URL', location.pathname + location.search)

  const userData = JSON.parse(localStorage.getItem('data'))

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [CategoryList, setCategoryList] = useState([])
  const [SubCategoryList, setSubCategoryList] = useState([])
  const [file, setFile] = useState('')
  const [videofile, setVideoFile] = useState('')
  const [url, setUrl] = useState("");
  const [Budget, setBudget] = useState("");
  const [Engraving, setEngraving] = useState("");

  const [verify,setVerify]=useState(false)

  const [Weight, setWeight] = useState("");
  const [Remark, setRemark] = useState("");

  const [SelectedRingSize, setSelectedRingSize] = useState('');
  const [RingSize, setRingSize] = useState([]);

  const [SelectedBraceletSize, setSelectedBraceletSize] = useState('');
  const [BraceletSize, setBraceletSize] = useState([]);

  const [SelectedChainSize, setSelectedChainSize] = useState('');
  const [ChainSize, setChainSize] = useState([]);

  const [SelectedMetal_Type, setSelectedMetal_Type] = useState('');
  const [Metal_Type, setMetal_Type] = useState([]);

  const [SelectedMetal_Stamp, setSelectedMetal_Stamp] = useState('');
  const [Metal_Stamp, setMetal_Stamp] = useState([]);

  const [ErrorData, setErrorData] = useState([])
  const [Loading, setLoading] = useState(false)
  const [MediaError, setMediaError] = useState(false)

  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [mobileno, setMobileNo] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirm_password, setConfirm_password] = useState('')
  const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))



  const showSideStoneError = () => {
    if(sideStoneSelect === true){
      sideStone?.map((value, index) => {
        if(value?.shape == "" || value?.shape == 0){
          value.shapeError=true;
        }else{
          value.shapeError=false;
        }
        if(value?.color == "" || value?.color == 0){
          value.colorError=true;
        }else{
          value.colorError=false;
        }
        if(value?.clarity == "" || value?.clarity == 0){
          value.clarityError=true;
        }else{
          value.clarityError=false;
        }
        // if(value?.carat == "" || value?.carat == 0){
        //   value.caratError=true;
        // }else{
        //   value.caratError=false;
        // }
      })
    }
  }
  function handleVerifyccapcha(value) {
    setVerify(true)
  }
  const authenticate = async () => {
    setMediaError(false)

    let IsError=true;

    if((file == null || file == "") && (videofile == null || videofile == "") && (url == null || url == "")){
      ShowErrorMessage("Please select atleast one media",'top')
      setMediaError(true)
      IsError=false;
    }
    
    console.log("sideStoneSelect",sideStoneSelect);

    if(sideStoneSelect === true){
      sideStone?.map((value, index) => {
        if(value?.shape == "" || value?.shape == 0){
          value.shapeError=true;
          IsError=false;
        }else{
          value.shapeError=false;
        }
        if(value?.color == "" || value?.color == 0){
          value.colorError=true;
          IsError=false;
        }else{
          value.colorError=false;
        }
        if(value?.clarity == "" || value?.clarity == 0){
          value.clarityError=true;
          IsError=false;
        }else{
          value.clarityError=false;
        }
        // if(value?.carat == "" || value?.carat == 0){
        //   value.caratError=true;
        //   IsError=false;
        // }else{
        //   value.caratError=false;
        // }
      })
    }

    if(IsError == true){
      setLoading(true)
      let formData  = new FormData();
      formData.append("jewelrytype", selectedCategory?.id == undefined ? '' : selectedCategory?.id);
      formData.append("subcategory_id", selectedSubCategory?.id == undefined ? '' : selectedSubCategory?.id);
      formData.append("userid", (userData?.user_id == '' || userData?.user_id == undefined) ? '' : userData?.user_id);
      formData.append("metaltype", SelectedMetal_Type?.id);
      formData.append("metalstamp", SelectedMetal_Stamp?.id);
      formData.append("appxmetalWgt", Weight);
      formData.append("ringsize", (SelectedRingSize?.id == undefined) ? "" : SelectedRingSize?.id);
      formData.append("engraving", Engraving);
      formData.append("budget", Budget);
      formData.append("remark", Remark);
      formData.append("image", file);
      formData.append("video", videofile);
      formData.append("url", url);
      if(userData == null){
        //sign up
        formData.append("firstname", firstname);
        formData.append("lastname", lastname);
        formData.append("email", email);
        formData.append("mobileno", mobileno);
      }else{
        //already login
        formData.append("firstname", (userData?.firstname == undefined) ? "" : userData?.firstname);
        formData.append("lastname", (userData?.lastname == undefined) ? "" : userData?.lastname);
        formData.append("email", (userData?.email == undefined) ? "" : userData?.email);
        formData.append("mobileno", (userData?.mobileno == undefined) ? "" : userData?.mobileno);
      }
      
      formData.append("password", password);
      formData.append("confirm_password", confirm_password);
  
      formData.append("centerTabStatus", centerTabStatus);
      
      formData.append("centerStoneSelect", centerStoneSelect);
      formData.append("sideStoneSelect", sideStoneSelect);
  
      formData.append("selectedDiamShape", selectedDiamShape);
      formData.append("selectedFancyShape", selectedFancyShape);
      formData.append("selectedGemShape", selectedGemShape);
      
      formData.append("selectedDiamColor", selectedDiamColor);
      formData.append("selectedFancyColor", selectedFancyColor);
      formData.append("selectedGemColor", selectedGemColor);
      
      formData.append("selectedDiamClarity", selectedDiamClarity);
      formData.append("selectedGemClarity", selectedGemClarity);
      
      formData.append("selectedDiamCarat", (selectedDiamCarat?.value == undefined) ? '' : selectedDiamCarat?.value);
      formData.append("selectedFancyCarat", (selectedFancyCarat?.value == undefined) ? '' : selectedFancyCarat?.value);
      formData.append("selectedGemCarat", (selectedGemCarat?.value == undefined) ? '' : selectedGemCarat?.value);
  
      formData.append("diamMinSelectedCarat", diamMinSelectedCarat);
      formData.append("diamMaxSelectedCarat", diamMaxSelectedCarat);
  
      formData.append("fancyMinSelectedCarat", fancyMinSelectedCarat);
      formData.append("fancyMaxSelectedCarat", fancyMaxSelectedCarat);
  
      formData.append("gemMinSelectedCarat", gemMinSelectedCarat);
      formData.append("gemMaxSelectedCarat", gemMaxSelectedCarat);
      // formData.append("diamMinSelectedCarat", (diamMinSelectedCarat?.value == undefined) ? '' : diamMinSelectedCarat?.value);
      // formData.append("diamMaxSelectedCarat", (diamMaxSelectedCarat?.value == undefined) ? '' : diamMaxSelectedCarat?.value);
  
      // formData.append("fancyMinSelectedCarat", (fancyMinSelectedCarat?.value == undefined) ? '' : fancyMinSelectedCarat?.value);
      // formData.append("fancyMaxSelectedCarat", (fancyMaxSelectedCarat.value == undefined) ? '' : fancyMaxSelectedCarat.value);
  
      // formData.append("gemMinSelectedCarat", (gemMinSelectedCarat?.value == undefined) ? '' : gemMinSelectedCarat?.value);
      // formData.append("gemMaxSelectedCarat", (gemMaxSelectedCarat?.value == undefined) ? '' :gemMaxSelectedCarat?.value);
  
      formData.append("selectedGemStoneType", selectedGemStoneType);

      formData.append("currency_id", defaultCurrency?.id == null ? null : defaultCurrency?.id);
  
      sideStone?.map((value, index) => {
        formData.append('sideStone' + '[' + index + '][isgem]', value?.isgem)
        formData.append('sideStone' + '[' + index + '][shape]', value?.shape)
        formData.append('sideStone' + '[' + index + '][color]', value?.color)
        formData.append('sideStone' + '[' + index + '][clarity]', value?.clarity)
        // formData.append('sideStone' + '[' + index + '][carat]', value?.carat)
        // formData.append('sideStone' + '[' + index + '][pieces]', value?.pieces)
        // formData.append('sideStone' + '[' + index + '][weight]', value?.weight)
      })
      console.log("formData",formData);
      console.log("sideStone",sideStone);
  
      const config = {
        headers: {
          "Content-Type": 'multipart/form-data',
          "Accept": "application/json"
        },
      };
      await axios.post(BASE_URL + GetAPIUrl.CUSTOMJEWELRY_URL, formData, config)
        .then(response => {
          if (response.data.success == true) {
            ShowMessage(response?.data?.message)
            setErrorData("")
            resetData()
            window.grecaptcha.reset();
          }
        }).catch(error => {
          setErrorData(error?.response?.data?.errors)
          ShowErrorMessage(error?.response?.data?.message,'top')
        }).finally(() => {
          setLoading(false)
        })
    }
    
  }


  const resetData = () => {

    document.getElementById("image").value = "";
    document.getElementById("video").value = "";

    setVerify(false)

    setSelectedCategory('')
    setSelectedSubCategory('')
    setFile('')
    setVideoFile('')
    setUrl("")
    setBudget("")
    setEngraving("")
    setWeight("")
    setRemark("")
    setSelectedRingSize('')
    setSelectedBraceletSize('')
    setSelectedChainSize('')
    setSelectedMetal_Type('')
    setSelectedMetal_Stamp('')

    setCenterTabStatus('diam')
    setSideStoneSelect(false)
    setCenterStoneSelect(false)
    setSelectedDiamShape('')
    setSelectedFancyShape('')
    setSelectedGemShape('')
    setSelectedDiamColor('')
    setSelectedFancyColor('')
    setSelectedGemColor('')
    setSelectedDiamClarity('')
    setSelectedGemClarity('')
    setSelectedDiamCarat('')
    setSelectedFancyCarat('')
    setSelectedGemCarat('')

    setDiamMinSelectedCarat('')
    setDiamMaxSelectedCarat('')
    setFancyMinSelectedCarat('')
    setFancyMaxSelectedCarat('')
    setGemMinSelectedCarat('')
    setGemMaxSelectedCarat('')

    setSelectedGemStoneType('')

    setSideStone([
      {
        isgem:0,
        shape:0,
        color:0,
        clarity:0,
        carat:'',
        pieces:'',
        weight:'',
        shapeError:false,
        colorError:false,
        clarityError:false,
        caratError:false,
      }
    ])
    setFirstName('')
    setLastName('')
    setEmail('')
    setPassword('')
    setConfirm_password('')

  }

  const CallCategoryListApi = async () => {

    await axios.post(BASE_URL + GetAPIUrl.CATEGORY_URL, null, {
      headers: {
        'Accept': 'application/json',
      }
    })
      .then(response => {
        if (response.data.success == true) {
          setCategoryList(response?.data?.data);
          // setSelectedCategory(response?.data?.data[0])
        }
      }).catch(error => {
        // ShowErrorMessage(error.message)
      }).finally(() => {

      })
  }

  const CallRingsizeApi = async () => {

    const controller = new AbortController();

    axios.get(BASE_URL + GetAPIUrl.JEWELRY_FILTER_URL)
      .then(response => {
        if (response.data.success == true) {
          setBraceletSize(response.data.data.BraceletSize)
          setSelectedBraceletSize(response.data.data.BraceletSize[0])

          setChainSize(response.data.data.ChainSize)
          setSelectedChainSize(response.data.data.ChainSize[0])

          setRingSize(response.data.data.RingSize)
          setSelectedRingSize(response.data.data.RingSize[0])
          setMetal_Stamp(response.data.data.Metal_Stamp)
          setSelectedMetal_Stamp(response.data.data.Metal_Stamp[0])
          setMetal_Type(response.data.data.Metal_Type)
          setSelectedMetal_Type(response.data.data.Metal_Type[0])

        }
      }).catch(error => {
        // ShowErrorMessage(error.message)
      }).finally(() => {
        // setLoading(false);
      })
    controller.abort()
  }
  useEffect(() => {
    CallCategoryListApi()
    CallRingsizeApi()
    AllParameter()
    if(userData != null){
      setFirstName(userData?.firstname);
      setLastName(userData?.lastname);
      setMobileNo(userData?.mobileno);
    }
  }, [])

  const CategoryRef = useRef()
  const SelectedSubCategoryRef = useRef()
  const RingSizeSelectRef = useRef()
  const BreceletSizeSelectRef = useRef()
  const ChainSizeSelectRef = useRef()

  const MetalTypeelectRef = useRef()
  const MetalStampelectRef = useRef()

  const caratRef = useRef()

  const [centerTabStatus, setCenterTabStatus] = useState('diam')
  const [shapes, setShapes] = useState([])
  const [color, setColor] = useState([])
  const [clarity, setClarity] = useState([])
  const [fancyColor, setFancyColour] = useState([])
  const [GemStoneType, setGemStoneType] = useState([])
  const [GemClarity, setGemClarity] = useState([])
  const [GemColor, setGemColor] = useState([])
  const [selectedGemStoneType, setSelectedGemStoneType] = useState([])


  const [selectedDiamShape, setSelectedDiamShape] = useState('')
  const [selectedFancyShape, setSelectedFancyShape] = useState('')
  const [selectedGemShape, setSelectedGemShape] = useState('')


  const [selectedDiamColor, setSelectedDiamColor] = useState('')
  const [selectedFancyColor, setSelectedFancyColor] = useState('')
  const [selectedGemColor, setSelectedGemColor] = useState('')


  const [selectedDiamClarity, setSelectedDiamClarity] = useState('')
  const [selectedGemClarity, setSelectedGemClarity] = useState('')
  
  const [selectedDiamCarat, setSelectedDiamCarat] = useState('')
  const [selectedFancyCarat, setSelectedFancyCarat] = useState('')
  const [selectedGemCarat, setSelectedGemCarat] = useState('')

  const [diamMinSelectedCarat, setDiamMinSelectedCarat] = useState('')
  const [diamMaxSelectedCarat, setDiamMaxSelectedCarat] = useState('')
  const [fancyMinSelectedCarat, setFancyMinSelectedCarat] = useState('')
  const [fancyMaxSelectedCarat, setFancyMaxSelectedCarat] = useState('')
  const [gemMinSelectedCarat, setGemMinSelectedCarat] = useState('')
  const [gemMaxSelectedCarat, setGemMaxSelectedCarat] = useState('')

  const [sideStone, setSideStone] = useState([
    {
      isgem:0,
      shape:0,
      color:0,
      clarity:0,
      carat:'',
      pieces:'',
      weight:'',
      shapeError:false,
      colorError:false,
      clarityError:false,
      caratError:false,
    }
  ])

  const [centerStoneSelect, setCenterStoneSelect] = useState(false)
  const [sideStoneSelect, setSideStoneSelect] = useState(false)  

  const [CaratList, setCaratList] = useState([
    {
      name:"All",
      value:"0-15.00"
    },
    {
      name:"0.15-0.22",
      value:"0.15-0.22"
    },
    {
      name:"0.23-0.29",
      value:"0.23-0.29"
    },
    {
      name:"0.30-0.39",
      value:"0.30-0.39"
    },
    {
      name:"0.40-0.49",
      value:"0.40-0.49"
    },
    {
      name:"0.50-0.59",
      value:"0.50-0.59"
    },
    {
      name:"0.60-0.69",
      value:"0.60-0.69"
    },
    {
      name:"0.70-0.79",
      value:"0.70-0.79"
    },
    {
      name:"0.80-0.89",
      value:"0.80-0.89"
    },
    {
      name:"0.90-0.99",
      value:"0.90-0.99"
    },
    {
      name:"1.00-1.49",
      value:"1.00-1.49"
    },
    {
      name:"1.50-1.99",
      value:"1.50-1.99"
    },
    {
      name:"2.00-2.99",
      value:"2.00-2.99"
    },
    {
      name:"3.0-15.0",
      value:"3.0-15.0"
    },
  ])


  const handleImage = (e) => {
    const data = e.target.files[0]
    setFile(data)
  }
  const handleVideo = (e) => {
    const data = e.target.files[0]
    setVideoFile(data)
  }
  const handleSelect = (selectedList, selectedItem) => {
    setSelectedCategory(selectedItem);
    console.log("selectedItem",selectedItem);
    console.log("selectedItem sub",selectedItem.subcategory);
    setSelectedSubCategory('')
    setSubCategoryList([])
    setSubCategoryList(selectedItem.subcategory)
  };
  const handleSelectSubcategory = (selectedList, selectedItem) => {
    setSelectedSubCategory(selectedItem);
    console.log("selectedItem",selectedItem);
  };
  const handleChainSize = (selectedList, selectedItem) => {
    setSelectedChainSize(selectedItem);
    setSelectedRingSize('')
  };
  const handleBreceletize = (selectedList, selectedItem) => {
    setSelectedBraceletSize(selectedItem);
    setSelectedRingSize('')
  };
  const handleRingsize = (selectedList, selectedItem) => {
    setSelectedRingSize(selectedItem);
    setSelectedBraceletSize('')
  };
  const handleMetalType = (selectedList, selectedItem) => {
    setSelectedMetal_Type(selectedItem);
  };
  const handleMetalStamp = (selectedList, selectedItem) => {
    setSelectedMetal_Stamp(selectedItem);
  };
  const handleCenterStoneChange = (e) => {
    if(e.target.checked == true){
      setCenterStoneSelect(e.target.checked)
    }else{
      setCenterTabStatus('diam')
      setCenterStoneSelect(e.target.checked)
      setSelectedDiamShape('')
      setSelectedFancyShape('')
      setSelectedGemShape('')
      setSelectedDiamColor('')
      setSelectedFancyColor('')
      setSelectedGemColor('')
      setSelectedDiamClarity('')
      setSelectedGemClarity('')
      setSelectedDiamCarat('')
      setSelectedFancyCarat('')
      setSelectedGemCarat('')

      setDiamMinSelectedCarat('')
      setDiamMaxSelectedCarat('')
      setFancyMinSelectedCarat('')
      setFancyMaxSelectedCarat('')
      setGemMinSelectedCarat('')
      setGemMaxSelectedCarat('')

      setSelectedGemStoneType('')
    }
  };
  const handleSideStoneChange = (e) => {
    if(e.target.checked == true){
      setSideStoneSelect(e.target.checked)
    }else{
      setSideStoneSelect(e.target.checked)
      setSideStone([
        {
          isgem:0,
          shape:0,
          color:0,
          clarity:0,
          carat:'',
          pieces:'',
          weight:'',
          shapeError:false,
          colorError:false,
          clarityError:false,
          caratError:false,
        }
      ])
    }
  };

  const openCenterStoneType = (type) => {
    setCenterTabStatus(type)
    setSelectedDiamShape('')
    setSelectedFancyShape('')
    setSelectedGemShape('')
    setSelectedDiamColor('')
    setSelectedFancyColor('')
    setSelectedGemColor('')
    setSelectedDiamClarity('')
    setSelectedGemClarity('')
    setSelectedDiamCarat('')
    setSelectedFancyCarat('')
    setSelectedGemCarat('')

    setDiamMinSelectedCarat('')
    setDiamMaxSelectedCarat('')
    setFancyMinSelectedCarat('')
    setFancyMaxSelectedCarat('')
    setGemMinSelectedCarat('')
    setGemMaxSelectedCarat('')

    setSelectedGemStoneType('')
   console.log(type)
  };

  // console.log(" jewelry type", selectedCategory)
  // console.log("videofile", videofile)
  // console.log("file", file)

  // console.log("url", url)
  // console.log("Remark", Remark)
  // console.log("Budget", Budget)
  // console.log("Engraving", Engraving)
  // console.log("Weight ", Weight)
  // console.log("Ring size ", SelectedRingSize)
  // console.log("Metal Type ", Metal_Type)
  // console.log("Metal stamp ", Metal_Stamp)
    

  const AllParameter = async () => {
    const controller = new AbortController();
    var form = new FormData();
    form.append("type", "diamond");
    form.append("paraname[]", "Shape");
    form.append("paraname[]", "Cut");
    form.append("paraname[]", "Clarity");
    form.append("paraname[]", "Color");
    form.append("paraname[]", "GemColor");
    form.append("paraname[]", "GemClarity");
    form.append("paraname[]", "FancyColour");
    form.append("paraname[]", "GemStoneType");
    // form.append("paraname[]", "");
    // form.append("paraname[]", "");

    axios.post(BASE_URL + GetAPIUrl.PARAMETERS, form)
      .then(response => {
        if (response.data.success == true) {
          setShapes(response?.data?.data?.Shape)
          setColor(response?.data?.data?.Color)
          setClarity(response?.data?.data?.Clarity)
          setFancyColour(response?.data?.data?.FancyColour)
          setGemStoneType(response?.data?.data?.GemStoneType)
          setGemClarity(response?.data?.data?.GemClarity)
          setGemColor(response?.data?.data?.GemColor)
        } else {
          ShowErrorMessage(response?.data?.message,'top')
        }
      })
      .catch(error => {
        ShowErrorMessage(error.message,'top')
      });
    controller.abort()

  }

  const handleFilterDiamShape = (item) => {
    setSelectedDiamShape(item)
  }
  const handleFilterFancyShape = (item) => {
    setSelectedFancyShape(item)
  }
  const handleFilterGemShape = (item) => {
    setSelectedGemShape(item)
  }
  const handleFilterGemPrecious = (item) => {
    setSelectedGemStoneType(item)
  }
  const handleFilterDiamColor = (item) => {
    setSelectedDiamColor(item)
  }
  const handleFilterFancyColor = (item) => {
    setSelectedFancyColor(item)
  }
  const handleFilterGemColor = (item) => {
    setSelectedGemColor(item)
  }
  const handleFilterDiamClarity = (item) => {
    setSelectedDiamClarity(item)
  }
  const handleFilterGemClarity = (item) => {
    setSelectedGemClarity(item)
  }
  const handleDiamMinCaratValue = (item) => {
    console.log("item ----",item)
    setDiamMinSelectedCarat(item.target.value)
  }
  const handleFancyMinCaratValue = (item) => {
    console.log("item ----",item)
    setFancyMinSelectedCarat(item.target.value)
  }
  const handleGemMinCaratValue = (item) => {
    console.log("item ----",item)
    setGemMinSelectedCarat(item.target.value)
  }
  const handleDiamMaxCaratValue = (item) => {
    console.log("item ----",item)
    setDiamMaxSelectedCarat(item.target.value);
  }
  const handleFancyMaxCaratValue = (item) => {
    console.log("item ----",item)
    setFancyMaxSelectedCarat(item.target.value);
  }
  const handleGemMaxCaratValue = (item) => {
    console.log("item ----",item)
    setGemMaxSelectedCarat(item.target.value);
  }
  const handleDiamCarat = (item) => {
    setSelectedDiamCarat(item[0])
    setDiamMinSelectedCarat(item[0].value.split("-")[0])
    setDiamMaxSelectedCarat(item[0].value.split("-")[1])
  }
  const handleFancyCarat = (item) => {
    setSelectedFancyCarat(item[0])
    setFancyMinSelectedCarat(item[0].value.split("-")[0])
    setFancyMaxSelectedCarat(item[0].value.split("-")[1])
  }
  const handleGemCarat = (item) => {
    setSelectedGemCarat(item[0])
    setGemMinSelectedCarat(item[0].value.split("-")[0])
    setGemMaxSelectedCarat(item[0].value.split("-")[1])
  }
  const handleSideStoneAddRow = () => {
    let temp=[...sideStone]
    temp.push({
        isgem:0,
        shape:0,
        color:0,
        clarity:0,
        carat:'',
        pieces:'',
        weight:'',
        shapeError:false,
        colorError:false,
        clarityError:false,
        caratError:false,
      })
    setSideStone(temp)
    // console.log("temp",temp)
    // console.log("sideStone",sideStone)
  }
  const handleSideStoneData = (e,sideindex,ele) => {
    let number = e.target.value
    var array = [...sideStone]
    console.log("array",array);
    if(ele == "pieces"){
      array[sideindex].pieces=number
    }
    if(ele == "weight"){
      array[sideindex].weight=number
    }
    if(ele == "carat"){
      array[sideindex].carat=number
    }
    if(ele == "shape"){
      array[sideindex].shape=number
    }
    if(ele == "color"){
      array[sideindex].color=number
    }
    if(ele == "clarity"){
      array[sideindex].clarity=number
    }
    if(ele == "isgem"){
        array[sideindex].isgem=e.target.checked      
    }
    setSideStone(array)
    // console.log("sideStone",sideStone)
    showSideStoneError()
  }

  const removeRow = (sideindex) => {
    // console.log("sideindex",sideindex)
    var array = [...sideStone]
    // console.log("array",array)
    var index = array.indexOf(array[sideindex])
    // console.log("index",index)
    if (index !== -1) {
      array.splice(index, 1);
      setSideStone(array)
      // console.log("sideStone",sideStone)
    }
    
  }
  function onKeyPress(event) {
    if (isNaN(event.target.value)) {
      return;
    }
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
     if (/\+|-/.test(keyValue))
       event.preventDefault();
   }

  return (
    <>
      {Loading == true &&

        <LoadingSpinner />
      }

      <div className="container">
        <div className="container container-main">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to={'/'} className='desk-top-title-sm a-taglink' ><i className="fa fa-home" aria-hidden="true"></i></Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Custom Design</li>
            </ol>
          </nav>
        </div>
      </div>
      <section>
        <div className="container 1pd-30">
          <div className="pd-z">
            {/* <p className="Custom-title ">CUSTOMIZE YOUR DESIGN</p>
            <hr className="hr-line" />
            <p></p>
            <br /> */}
            <div className="row">
              <div className="col-md-8 ">
                {/* <LeftSideFrom selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} 
                selectedSubCategory={selectedSubCategory} setSelectedSubCategory={setSelectedSubCategory}
                CategoryList={CategoryList}
                  setCategoryList={setCategoryList}
                  SubCategoryList={SubCategoryList}
                  setSubCategoryList={setSubCategoryList}
                   file={file} setFile={setFile} videofile={videofile} setVideoFile={setVideoFile}
                  url={url} setUrl={setUrl} Budget={Budget} setBudget={setBudget} Engraving={Engraving} setEngraving={setEngraving} Weight={Weight} setWeight={setWeight} Remark={Remark}
                  setRemark={setRemark} SelectedRingSize={SelectedRingSize} setSelectedRingSize={setSelectedRingSize} RingSize={RingSize} setRingSize={setRingSize}
                  SelectedBraceletSize={SelectedBraceletSize} setSelectedBraceletSize={setSelectedBraceletSize} BraceletSize={BraceletSize} setBraceletSize={setBraceletSize}
                  SelectedMetal_Type={SelectedMetal_Type} setSelectedMetal_Type={setSelectedMetal_Type} Metal_Stamp={Metal_Stamp} setMetal_Stamp={setMetal_Stamp} Metal_Type={Metal_Type}
                  setMetal_Type={setMetal_Type} SelectedMetal_Stamp={SelectedMetal_Stamp} setSelectedMetal_Stamp={setSelectedMetal_Stamp}
                  ErrorData={ErrorData}
                  selectedGemStoneType={''}
                /> */}



<div className="col-md-12 custom-form fix-left">
        <br />
        <div>
          <div className="col-md-12">
              <div className="row">
                <div className="from_Group col-md-6" style={{}}>
                  <label className="">
                  Choose jewelry type
                  <span className="mark_red">*</span>
                  </label>
                  
                  <Multiselect singleSelect
                    disablePreSelectedValues={selectedCategory != '' ? true : false}
                    placeholder='Select'
                    style={{
                      chips: { color: '#000', fontSize: 14, },
                      searchBox: { "borderRadius": "0px" },

                    }}
                    ref={CategoryRef}

                    showArrow
                    loading={Loading ? true : false}
                    onSelect={handleSelect}
                    // onRemove={handleRemoveAddressValues} 
                    options={CategoryList}
                    selectedValues={selectedCategory != '' ? [selectedCategory] : []}
                    displayValue="name" />
                    <p style={validation}>
                    {ErrorData?.jewelrytype != undefined && ErrorData?.jewelrytype?.map((key, i) => key)}
                    </p>
                </div>
                <div className="from_Group col-md-6" style={{}}>
                  <label className="">
                  Sub Category
                  </label>
                  <Multiselect singleSelect
                disablePreSelectedValues={selectedSubCategory != '' ? true : false}
                placeholder=''
                style={{
                  chips: { color: '#000', fontSize: 14, },
                  searchBox: { "borderRadius": "0px" },

                }}
                ref={SelectedSubCategoryRef}

                showArrow
                loading={Loading ? true : false}
                onSelect={handleSelectSubcategory}
                // onRemove={handleRemoveAddressValues}
                options={SubCategoryList}
                selectedValues={selectedSubCategory != '' ? [selectedSubCategory] : []}
                displayValue="name" />
                </div>
				</div>

            {/* <div className="dropdown bootstrap-select show-tick" style={{ marginRight: 10, width: '-webkit-fill-available' }}>
              <label className="font-18">
                Choose jewelry type<span className="mark_red">*</span>
              </label>

              <Multiselect singleSelect
                disablePreSelectedValues={selectedCategory != '' ? true : false}
                placeholder='Select'
                style={{
                  chips: { color: '#000', fontSize: 14, },
                  searchBox: { "borderRadius": "0px" },

                }}
                ref={CategoryRef}

                showArrow
                loading={Loading ? true : false}
                onSelect={handleSelect}
                // onRemove={handleRemoveAddressValues}
                options={CategoryList}
                selectedValues={selectedCategory != '' ? [selectedCategory] : []}
                displayValue="name" />
            </div>
            {selectedCategory?.name === "Other" ? (
              <div className="from-group col-md-11 m-3">
                <lebel htmlFor="other_type">
                  Other Type<span className="mark_red">*</span>
                </lebel>
                <input
                  type="text"
                  className="form_control"
                  id="other_type"
                  placeholder="Other Type"
                />
              </div>
            ) : (
              ""
            )}

              <div className="dropdown bootstrap-select show-tick" style={{ marginRight: 10, width: '-webkit-fill-available' }}>
              <label className="font-18">
                Sub Category<span className="mark_red">*</span>
              </label>

              <Multiselect singleSelect
                disablePreSelectedValues={selectedSubCategory != '' ? true : false}
                placeholder=''
                style={{
                  chips: { color: '#000', fontSize: 14, },
                  searchBox: { "borderRadius": "0px" },

                }}
                ref={SelectedSubCategoryRef}

                showArrow
                loading={Loading ? true : false}
                onSelect={handleSelectSubcategory}
                // onRemove={handleRemoveAddressValues}
                options={SubCategoryList}
                selectedValues={selectedSubCategory != '' ? [selectedSubCategory] : []}
                displayValue="name" />
            </div> */}

          </div>
          <div className="col-md-12 ">
            <label className="font-18">
              Metal Detail
            </label>
            <div className="col-md-12">
              <div className="row">
                {/* <div className="from_Group col-md-6">
                  <label htmlFor="Metaltype">
                    Metal Type<span className="mark_red">*</span>
                  </label>
                  <select
                    className="form_control"
                    name="Metaltype"
                    id="Metaltype"
                  >
                    <option>18k white gold</option>
                  </select>
                </div> */}
                <div className="from_Group col-md-6" style={{}}>
                  <label className="">
                    Metal Type<span className="mark_red">*</span>
                  </label>

                  <Multiselect singleSelect
                    disablePreSelectedValues={SelectedMetal_Type != '' ? true : false}
                    placeholder=''
                    style={{
                      chips: { color: '#000', fontSize: 14, },
                      searchBox: { "borderRadius": "0px" },

                    }}
                    ref={MetalTypeelectRef}

                    showArrow
                    loading={Loading ? true : false}
                    onSelect={handleMetalType}
                    // onRemove={handleRemoveAddressValues}
                    options={Metal_Type}
                    selectedValues={SelectedMetal_Type != '' ? [SelectedMetal_Type] : []}
                    displayValue="name" />
                    <p style={validation}>
                      {ErrorData?.metaltype != undefined && ErrorData?.metaltype?.map((key, i) => key)}
                    </p>                    
                </div>
                <div className="from_Group col-md-6" style={{}}>
                  <label className="">
                    Metal Stamp<span className="mark_red">*</span>
                  </label>

                  <Multiselect singleSelect
                    disablePreSelectedValues={SelectedMetal_Stamp != '' ? true : false}
                    placeholder=''
                    style={{
                      chips: { color: '#000', fontSize: 14, },
                      searchBox: { "borderRadius": "0px" },

                    }}
                    ref={MetalStampelectRef}

                    showArrow
                    loading={Loading ? true : false}
                    onSelect={handleMetalStamp}
                    // onRemove={handleRemoveAddressValues}
                    options={Metal_Stamp}
                    selectedValues={SelectedMetal_Stamp != '' ? [SelectedMetal_Stamp] : []}
                    displayValue="name" />
                    <p style={validation}>
                    {ErrorData?.metalstamp != undefined && ErrorData?.metalstamp?.map((key, i) => key)}
                    </p>
                </div>

                <div className="from_Group col-md-6">
                  <label htmlFor="appMetal">
                    Appx Metal Weight (gm)<span className="mark_red">*</span>
                  </label>
                  <input
                    type="text"
                    className="form_control"
                    id="appMetal"
                    placeholder="Appx Metal Weight (gm)"
                    value={Weight}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                  <p id="CompanyName_msg" style={validation}>
                    {ErrorData?.appxmetalWgt != undefined && ErrorData?.appxmetalWgt?.map((key, i) => key)}
                  </p>
                </div>

{
  (selectedCategory?.name?.toLowerCase() == "ring" 
  || selectedCategory?.name?.toLowerCase() == "rings"
  || selectedCategory?.name?.toLowerCase() == "engagement rings"
  || selectedCategory?.name?.toLowerCase() == "engagement ring"
  || selectedCategory?.name?.toLowerCase() == "wedding ring"
  || selectedCategory?.name?.toLowerCase() == "wedding rings"
  || selectedCategory?.name?.toLowerCase() == "chains"
  || selectedCategory?.name?.toLowerCase() == "chain"
  || selectedCategory?.name?.toLowerCase() == "bracelet"
  || selectedCategory?.name?.toLowerCase() == "bracelets"
  ) &&
    <>
    {
      (selectedCategory?.name?.toLowerCase() == "chain" || selectedCategory?.name?.toLowerCase() == "chains") && (
                  <div className="from_Group col-md-6" style={{}}>
                    <label className="">
                      Bracelet Size
                    </label>

                    <Multiselect singleSelect
                      disablePreSelectedValues={SelectedChainSize != '' ? true : false}
                      placeholder=''
                      style={{
                        chips: { color: '#000', fontSize: 14, },
                        searchBox: { "borderRadius": "0px" },

                      }}
                      ref={ChainSizeSelectRef}

                      showArrow
                      loading={Loading ? true : false}
                      onSelect={handleChainSize}
                      // onRemove={handleRemoveAddressValues}
                      options={ChainSize}
                      selectedValues={SelectedChainSize != '' ? [SelectedChainSize] : []}
                      displayValue="name" />
                  </div>
                )
              }
              {
      (selectedCategory?.name?.toLowerCase() == "bracelet" || selectedCategory?.name?.toLowerCase() == "bracelets") && (
                  <div className="from_Group col-md-6" style={{}}>
                    <label className="">
                      Chain Size
                    </label>

                    <Multiselect singleSelect
                      disablePreSelectedValues={SelectedBraceletSize != '' ? true : false}
                      placeholder=''
                      style={{
                        chips: { color: '#000', fontSize: 14, },
                        searchBox: { "borderRadius": "0px" },

                      }}
                      ref={BreceletSizeSelectRef}

                      showArrow
                      loading={Loading ? true : false}
                      onSelect={handleBreceletize}
                      // onRemove={handleRemoveAddressValues}
                      options={BraceletSize}
                      selectedValues={SelectedBraceletSize != '' ? [SelectedBraceletSize] : []}
                      displayValue="name" />
                  </div>
                )
              }
                {
                  (selectedCategory?.name?.toLowerCase() == "rings" || selectedCategory?.name?.toLowerCase() == "ring"
                  || selectedCategory?.name?.toLowerCase() == "wedding rings" || selectedCategory?.name?.toLowerCase() == "wedding ring"
                  || selectedCategory?.name?.toLowerCase() == "engagement rings" || selectedCategory?.name?.toLowerCase() == "engagement ring") && (
                  <div className="from_Group col-md-6" style={{}}>
                    <label className="">
                      Ring Size
                    </label>

                    <Multiselect singleSelect
                      disablePreSelectedValues={SelectedRingSize != '' ? true : false}
                      placeholder=''
                      style={{
                        chips: { color: '#000', fontSize: 14, },
                        searchBox: { "borderRadius": "0px" },

                      }}
                      ref={RingSizeSelectRef}

                      showArrow
                      loading={Loading ? true : false}
                      onSelect={handleRingsize}
                      // onRemove={handleRemoveAddressValues}
                      options={RingSize}
                      selectedValues={SelectedRingSize != '' ? [SelectedRingSize] : []}
                      displayValue="name" />
                  </div>
                )
                }                
              </>
}
                {/* {selectedCategory?.name == "Bracelet" && (
                  // <div className="from_Group col-md-6">
                  //   <label htmlFor="bracelets">
                  //     Bracelets<span className="mark_red">*</span>
                  //   </label>
                  //   <select
                  //     className="form_control"
                  //     name="bracelets"
                  //     id="bracelets"
                  //   >
                  //     <option>Bracelets</option>
                  //   </select>
                  // </div>
                  <div className="from_Group col-md-6" style={{}}>
                    <label className="">
                      Bracelet size
                    </label>

                    <Multiselect singleSelect
                      disablePreSelectedValues={SelectedBraceletSize != '' ? true : false}
                      placeholder=''
                      style={{
                        chips: { color: '#000', fontSize: 14, },
                        searchBox: { "borderRadius": "0px" },

                      }}
                      ref={BreceletSizeSelectRef}

                      showArrow
                      loading={Loading ? true : false}
                      onSelect={handleBreceletize}
                      // onRemove={handleRemoveAddressValues}
                      options={BraceletSize}
                      selectedValues={SelectedBraceletSize != '' ? [SelectedBraceletSize] : []}
                      displayValue="name" />
                  </div>
                )}
                {selectedCategory?.name == "Rings" && (
                  <div className="from_Group col-md-6" style={{}}>
                    <label className="">
                      Ring size
                    </label>

                    <Multiselect singleSelect
                      disablePreSelectedValues={SelectedRingSize != '' ? true : false}
                      placeholder=''
                      style={{
                        chips: { color: '#000', fontSize: 14, },
                        searchBox: { "borderRadius": "0px" },

                      }}
                      ref={RingSizeSelectRef}

                      showArrow
                      loading={Loading ? true : false}
                      onSelect={handleRingsize}
                      // onRemove={handleRemoveAddressValues}
                      options={RingSize}
                      selectedValues={SelectedRingSize != '' ? [SelectedRingSize] : []}
                      displayValue="name" />
                  </div>
                )} */}
                {selectedCategory?.name == "Earring" &&
                  selectedCategory?.name == "Brooches" &&
                  selectedCategory?.name == "Necklace" &&
                  selectedCategory?.name == "Other" ? (
                  <div className="from_Group col-md-6">
                    <label htmlFor="engraving">
                      Engraving
                    </label>

                    <input
                      className="form_control"
                      type="text"
                      placeholder="Engraving"
                      value={Engraving}
                      onChange={(e) => setEngraving(e.target.value)}
                    />
                  </div>
                ) : (

                  <>
                    <div className="from_Group col-md-6">
                      <label htmlFor="engraving">
                        Engraving
                      </label>
                      <input
                        type="text"
                        className="form_control"
                        id="engraving"
                        placeholder="Engraving"
                        value={Engraving}
                        onChange={(e) => setEngraving(e.target.value)}
                      />
                    </div>
                    <div className="from_Group col-md-6">
                      <label htmlFor='budget'>Budget<span className='mark_red'>*</span></label>

                      <div className='input_group'>
                        <span className='input-group-addon' title={defaultCurrency?.currency_name ?? "In USD"}>{defaultCurrency?.sign ?? "$"}</span>
                        <input
                          className="form_control" title={defaultCurrency?.currency_name ?? "In USD"}
                          id="budget"
                          type="text"
                          placeholder="Your Budget"
                          value={Budget}
                          onChange={(e) =>{const result = e.target.value.replace(/\D/g, '');setBudget(result);} //setBudget(e.target.value)
                        }
                        />

                      </div>
                      <p id="CompanyName_msg" style={validation}>
                        {ErrorData?.budget != undefined && ErrorData?.budget?.map((key, i) => key)}
                      </p>
                    </div>

                    <div className="col-md-12">
                      <label htmlFor='remark'>Remark</label>
                      <textarea rows="8" cols="40" placeholder="Remarks" name="Remark" id="remark" className="form_control mb-30"
                        value={Remark}
                        onChange={(e) => setRemark(e.target.value)}
                      ></textarea>

                    </div>
                  </>

                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 ">
            <hr />
          </div>
          <div className="col-md-12 form-group">
            <label className="font-18">
            Diamond and Gemstone Detail
            </label>
            <div className="row">
              <div className="col-md-3">

              <label className="chk-sign mar-top-10"> Center Stone
              <input type="checkbox" className="show-all-chk" checked={centerStoneSelect} id="CenterStone" data-parsley-multiple="CenterStone" onChange={handleCenterStoneChange}/>
                        <span className="checkmark"></span>
                    </label>

                    </div>

                    <div className="col-md-12">
                    {centerStoneSelect == true ?
                                      <div style={{marginBottom:"2%"}}>
                                      {/* <!-- Tab links --> */}
                                        <div className="tab" style={{justifyContent: "center",textAlign: "center",display: "flex"}}>
                                          <button className={"tablinks " + (centerTabStatus == "diam" ? 'active' : '')} onClick={()=>openCenterStoneType('diam')}>Diamonds</button>
                                          <button className={"tablinks " + (centerTabStatus == "fancy" ? 'active' : '')} onClick={()=>openCenterStoneType('fancy')}>Fancy Diamonds</button>
                                          <button className={"tablinks " + (centerTabStatus == "gem" ? 'active' : '')} onClick={()=>openCenterStoneType('gem')}>GemStones</button>
                                        </div>

                                        {/* <!-- Tab content --> */}
                                        <div id="diam" className="tabcontent" style={(centerTabStatus == "diam" ? {display:"block",borderTop:"1px solid #ccc"} : {borderTop:"1px solid #ccc"})}>
                                          <h4>Shape</h4>
                                          <div style={{display: "flex",padding:"1%"}}>
                                            
                                                <div className="diamond-active dia-bot-5">
                                                      {
                                                    shapes?.map((item, index) => {
                                                      let selected = (selectedDiamShape == item.id) ? true : false;
                                                      return(
                                                        <label className={
                                                          selected
                                                            ? "add-non-active wi-9 bg-fff active-shape add-active"
                                                            : "add-non-active wi-9 bg-fff active-shape"
                                                        } title={item.name} name="Shape" key={Math.random()} data-value={item.name} onClick={() => { handleFilterDiamShape(item.id) }}>
                                                          <Img src={item.image} className="max-shape-w"/>
                                                              <p className="mar-bot-z0 title-shape">
                                                                {item.name}
                                                              </p>
                                                          </label>
                                                      )
                                                    })
                                                  }
                                                </div>
                                          </div>
                                          <h4>Color</h4>
                                          <div style={{display: "flex",padding:"1%"}}>

                                                <div className="diamond-active dia-bot-5">
                                                      {
                                                    color?.map((item, index) => {
                                                      let selected = (selectedDiamColor == item.id) ? true : false;
                                                      return(
                                                        <label className={
                                                            selected
                                                              ? "add-non-active wi-9 bg-fff  add-active"
                                                              : "add-non-active wi-9 bg-fff "
                                                          } name="Color" key={Math.random()} title={item.name} data-value={item.name} onClick={() => { handleFilterDiamColor(item.id) }}>
                                                          <span>{item.name}</span>
                                                      </label>
                                                      )
                                                    })
                                                  }
                                                </div>
                                          </div>
                                          <h4>Clarity</h4>
                                          <div style={{display: "flex",padding:"1%"}}>

                                                <div className="diamond-active dia-bot-5">
                                                      {
                                                    clarity?.map((item, index) => {
                                                      let selected = (selectedDiamClarity == item.id) ? true : false;
                                                      return(
                                                        <label className={
                                                            selected
                                                              ? "add-non-active wi-9 bg-fff  add-active"
                                                              : "add-non-active wi-9 bg-fff "
                                                          } name="Clarity" key={Math.random()} title={item.name} data-value={item.name} onClick={() => { handleFilterDiamClarity(item.id) }}>
                                                          <span>{item.name}</span>
                                                      </label>
                                                      )
                                                    })
                                                  }
                                                </div>
                                          </div>

                                          <h4>Carat</h4>
                                          <div className="row col-md-12" style={{marginBottom:"2%"}}>
                                            <div className="col-md-4">
                                              <Multiselect singleSelect
                                                  disablePreSelectedValues={selectedDiamCarat != '' ? true : false}
                                                  placeholder=''
                                                  style={{
                                                    chips: { color: '#000', fontSize: 14, },
                                                    searchBox: { "borderRadius": "0px" },

                                                  }}
                                                  ref={caratRef}

                                                  showArrow
                                                  loading={Loading ? true : false}
                                                  onSelect={handleDiamCarat}
                                                  // onRemove={handleRemoveAddressValues}
                                                  options={CaratList}
                                                  selectedValues={selectedDiamCarat != '' ? [selectedDiamCarat] : []}
                                                  displayValue="name" />
                                                  </div>
                                                  <div className="col-md-4" style={{display: "flex"}}>
                                                    <input type="number" value={diamMinSelectedCarat} onChange={handleDiamMinCaratValue} />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input type="number" value={diamMaxSelectedCarat} onChange={handleDiamMaxCaratValue} />
                                                  </div>
                                          </div>

                                        </div>

                                        <div id="fancy" className="tabcontent" style={(centerTabStatus == "fancy" ? {display:"block",borderTop:"1px solid #ccc"} : {borderTop:"1px solid #ccc"})}>
                                          <h4>Color</h4>
                                          <div style={{display: "flex",padding:"1%"}}>
                                            
                                                <div className="diamond-active dia-bot-5">
                                                      {
                                                    fancyColor?.map((item, index) => {
                                                      let selected = (selectedFancyColor == item.id) ? true : false;
                                                      return(
                                                        <label className={
                                                          selected
                                                            ? "add-non-active wi-9 bg-fff active-shape add-active"
                                                            : "add-non-active wi-9 bg-fff active-shape"
                                                        } title={item.name} name="Shape" key={Math.random()} data-value={item.name} onClick={() => { handleFilterFancyColor(item.id) }}>
                                                          <Img src={item.image} className="max-shape-w"/>
                                                              <p className="mar-bot-z0 title-shape">
                                                                {item.name}
                                                              </p>
                                                          </label>
                                                      )
                                                    })
                                                  }
                                                </div>
                                          </div>

                                          <h4>Shape</h4>
                                          <div style={{display: "flex",padding:"1%"}}>
                                            
                                                <div className="diamond-active dia-bot-5">
                                                      {
                                                    shapes?.map((item, index) => {
                                                      let selected = (selectedFancyShape == item.id) ? true : false;
                                                      return(
                                                        <label className={
                                                          selected
                                                            ? "add-non-active wi-9 bg-fff active-shape add-active"
                                                            : "add-non-active wi-9 bg-fff active-shape"
                                                        } title={item.name} name="Shape" key={Math.random()} data-value={item.name} onClick={() => { handleFilterFancyShape(item.id) }}>
                                                          <Img src={item.image} className="max-shape-w"/>
                                                              <p className="mar-bot-z0 title-shape">
                                                                {item.name}
                                                              </p>
                                                          </label>
                                                      )
                                                    })
                                                  }
                                                </div>
                                          </div>

                                          <h4>Carat</h4>
                                          <div className="row col-md-12" style={{marginBottom:"2%"}}>
                                            <div className="col-md-4">
                                              <Multiselect singleSelect
                                                  disablePreSelectedValues={selectedFancyCarat != '' ? true : false}
                                                  placeholder=''
                                                  style={{
                                                    chips: { color: '#000', fontSize: 14, },
                                                    searchBox: { "borderRadius": "0px" },

                                                  }}
                                                  ref={caratRef}

                                                  showArrow
                                                  loading={Loading ? true : false}
                                                  onSelect={handleFancyCarat}
                                                  // onRemove={handleRemoveAddressValues}
                                                  options={CaratList}
                                                  selectedValues={selectedFancyCarat != '' ? [selectedFancyCarat] : []}
                                                  displayValue="name" />
                                                  </div>
                                                  <div className="col-md-4" style={{display: "flex"}}>
                                                    <input type="number" value={fancyMinSelectedCarat} onChange={handleFancyMinCaratValue} />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input type="number" value={fancyMaxSelectedCarat} onChange={handleFancyMaxCaratValue} />
                                                  </div>
                                          </div>

                                        </div>

                                        <div id="gem" className="tabcontent" style={(centerTabStatus == "gem" ? {display:"block",borderTop:"1px solid #ccc"} : {borderTop:"1px solid #ccc"})}>
                                          
                                        
                                          <h4>Precious</h4>
                                          <div style={{display: "flex",padding:"1%"}}>
                                            
                                                <div className="diamond-active dia-bot-5">
                                                      {
                                                    GemStoneType?.map((item, index) => {
                                                      let selected = (selectedGemStoneType == item.id) ? true : false;
                                                      return(
                                                        <label className={
                                                          selected
                                                            ? "add-non-active wi-9 bg-fff active-shape add-active"
                                                            : "add-non-active wi-9 bg-fff active-shape"
                                                        } title={item.name} name="Precious" key={Math.random()} data-value={item.name} onClick={() => { handleFilterGemPrecious(item.id) }}>
                                                          <Img src={item.image} className="max-shape-w"/>
                                                              <p className="mar-bot-z0 title-shape">
                                                                {item.name}
                                                              </p>
                                                          </label>
                                                      )
                                                    })
                                                  }
                                                </div>
                                          </div>

                                          <h4>Stone Types</h4>
                                          <div style={{display: "flex",padding:"1%"}}>
                                            
                                                <div className="diamond-active dia-bot-5">
                                                      {
                                                    shapes?.map((item, index) => {
                                                      let selected = (selectedGemShape == item.id) ? true : false;
                                                      return(
                                                        <label className={
                                                          selected
                                                            ? "add-non-active wi-9 bg-fff active-shape add-active"
                                                            : "add-non-active wi-9 bg-fff active-shape"
                                                        } title={item.name} name="Shape" key={Math.random()} data-value={item.name} onClick={() => { handleFilterGemShape(item.id) }}>
                                                          <Img src={item.image} className="max-shape-w"/>
                                                              <p className="mar-bot-z0 title-shape">
                                                                {item.name}
                                                              </p>
                                                          </label>
                                                      )
                                                    })
                                                  }
                                                </div>
                                          </div>

                                          <h4>Color</h4>
                                          <div style={{display: "flex",padding:"1%"}}>
                                            
                                                <div className="diamond-active dia-bot-5">
                                                      {
                                                    GemColor?.map((item, index) => {
                                                      let selected = (selectedGemColor == item.id) ? true : false;
                                                      return(
                                                        <label className={
                                                          selected
                                                            ? "add-non-active wi-9 bg-fff active-shape add-active"
                                                            : "add-non-active wi-9 bg-fff active-shape"
                                                        } title={item.name} name="Shape" key={Math.random()} data-value={item.name} onClick={() => { handleFilterGemColor(item.id) }}>
                                                          <span>{item.name}</span>
                                                          </label>
                                                      )
                                                    })
                                                  }
                                                </div>
                                          </div>
                                          <h4>Clarity</h4>
                                          <div style={{display: "flex",padding:"1%"}}>

                                                <div className="diamond-active dia-bot-5">
                                                      {
                                                    GemClarity?.map((item, index) => {
                                                      let selected = (selectedGemClarity == item.id) ? true : false;
                                                      return(
                                                        <label className={
                                                            selected
                                                              ? "add-non-active wi-9 bg-fff  add-active"
                                                              : "add-non-active wi-9 bg-fff "
                                                          } name="GemClarity" key={Math.random()} title={item.name} data-value={item.name} onClick={() => { handleFilterGemClarity(item.id) }}>
                                                          <span>{item.name}</span>
                                                      </label>
                                                      )
                                                    })
                                                  }
                                                </div>
                                          </div>

                                          <h4>Carat</h4>
                                          <div className="row col-md-12" style={{marginBottom:"2%"}}>
                                            <div className="col-md-4">
                                              <Multiselect singleSelect
                                                  disablePreSelectedValues={selectedGemCarat != '' ? true : false}
                                                  placeholder=''
                                                  style={{
                                                    chips: { color: '#000', fontSize: 14, },
                                                    searchBox: { "borderRadius": "0px" },

                                                  }}
                                                  ref={caratRef}

                                                  showArrow
                                                  loading={Loading ? true : false}
                                                  onSelect={handleGemCarat}
                                                  // onRemove={handleRemoveAddressValues}
                                                  options={CaratList}
                                                  selectedValues={selectedGemCarat != '' ? [selectedGemCarat] : []}
                                                  displayValue="name" />
                                                  </div>
                                                  <div className="col-md-4" style={{display: "flex"}}>
                                                    <input type="number" value={gemMinSelectedCarat} onChange={handleGemMinCaratValue} />
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <input type="number" value={gemMaxSelectedCarat} onChange={handleGemMaxCaratValue} />
                                                  </div>
                                          </div>

                                        </div>

                                      </div>
                    :
                    null
                    }
                </div>
              <div className="col-md-3">
                    <label className="chk-sign mar-top-10"> Side Stone
                      <input type="checkbox" checked={sideStoneSelect} className="show-all-chk"onChange={handleSideStoneChange}/>
                              <span className="checkmark"></span>
                    </label>
                    </div>
                    <div className="col-md-12">

                      {sideStoneSelect == true ?
                      <>
                      {/* <SideStone></SideStone> */}
                      <div className="bg-color">
                              <div className="text-right m">
                                <button
                                  className="pdd new-add-btnn btnn"
                                  onClick={handleSideStoneAddRow}
                                >
                                  Add <BiPlusMedical />
                                </button>
                              </div>
                              <div className="table-responsive m mt-2">
                                <table className="table">
                                  <thead>
                                    <tr className="text-center">
                                      <th className="w-10">isGem</th>
                                      <th className="w-30">Shape</th>
                                      <th className="w-30">Color</th>
                                      <th className="w-30">Clarity</th>
                                      {/* <th className="w-11">Carat</th>
                                      <th className="w-11">Pieces</th>
                                      <th className="w-11">Weight</th> */}
                                      <th className="w-10">
                                        <RiDeleteBin6Line />
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                      sideStone?.map((sideitem, sideindex) => {
                                        return(
                                          <tr>
                                            <td>
                                                <label className="chk-sign mar-top-10">&nbsp;
                                                    <input type="checkbox" checked={sideitem.isgem == true ? true : false} onChange={(e)=>handleSideStoneData(e,sideindex,'isgem')}/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </td>
                                            <td>
                                                <select className="form-control custom-select-shape parsley-success" onChange={(e)=>handleSideStoneData(e,sideindex,'shape')}>
                                                  <option value={''}>Select</option>
                                                {
                                                    shapes?.map((item, index) => {
                                                      return(
                                                        <option defaultValue={sideitem.shape} selected={sideitem.shape == item.id ? "selected" : ""} key={Math.random()} value={item.id} >{item.name}</option>
                                                      )
                                                    })
                                                  }
                                                  </select>
                                                  {
                                                    sideitem.shapeError == true ?
                                                    <div className="col" style={{ display: 'flex' }}>
                                                    <p style={validation}>
                                                          Select Shape
                                                      </p>
                                                      </div>
                                                    : null
                                                    }
                                            </td>
                                            <td>
                                              <select className="form-control SColor custom-select-color parsley-success" onChange={(e)=>handleSideStoneData(e,sideindex,'color')}>
                                              <option value={''}>Select</option>
                                              {
                                                  sideitem.isgem == true ?
                                                    fancyColor?.map((item, index) => {
                                                      return(
                                                        <option defaultValue={sideitem.color} selected={sideitem.color == item.id ? "selected" : ""} key={Math.random()} value={item.id} >{item.name}</option>
                                                      )
                                                    })
                                                  :
                                                  color?.map((item, index) => {
                                                      return(
                                                        <option defaultValue={sideitem.color} selected={sideitem.color == item.id ? "selected" : ""} key={Math.random()} value={item.id} >{item.name}</option>
                                                      )
                                                    })
                                                  }
                                              </select>
                                              {
                                                    sideitem.colorError == true ?
                                                    <div className="col" style={{ display: 'flex' }}>
                                                    <p style={validation}>
                                                          Select Color
                                                      </p>
                                                      </div>
                                                    : null
                                                    }
                                            </td>
                                            <td>
                                                <select className="form-control SClarity custom-select-color" onChange={(e)=>handleSideStoneData(e,sideindex,'clarity')}>
                                                <option value={''}>Select</option>
                                                {
                                                  clarity?.map((item, index) => {
                                                      return(
                                                        <option defaultValue={sideitem.clarity} selected={sideitem.clarity == item.id ? "selected" : ""} key={Math.random()} value={item.id} >{item.name}</option>
                                                      )
                                                    })
                                                  }
                                                </select>
                                                {
                                                    sideitem.clarityError == true ?
                                                    <div className="col" style={{ display: 'flex' }}>
                                                    <p style={validation}>
                                                          Select Clarity
                                                      </p>
                                                      </div>
                                                    : null
                                                    }
                                            </td>
                                            {/* <td>
                                                <input type="number" min={0} value={sideitem.carat} onChange={(e)=>handleSideStoneData(e,sideindex,'carat')} id={'carat_'+sideindex} className="form-control max-w80 custom-select-textbox" />
                                                {
                                                    sideitem.caratError == true ?
                                                    <div className="col" style={{ display: 'flex' }}>
                                                    <p style={validation}>
                                                          Enter Carat
                                                      </p>
                                                      </div>
                                                    : null
                                                    }
                                            </td>
                                            <td>
                                                <input type="number" min={0} value={sideitem.pieces} onChange={(e)=>handleSideStoneData(e,sideindex,'pieces')} id={'pieces_'+sideindex} className="form-control max-w80 custom-select-textbox" />
                                            </td>
                                            <td>
                                                <input type="number" min={0} value={sideitem.weight} onChange={(e)=>handleSideStoneData(e,sideindex,'weight')} id={'weight_'+sideindex} className="form-control max-w80 custom-select-textbox" />
                                            </td> */}
                                            <td>
                                              <button tabIndex={-1}
                                                  className="pdd new-add-btnn btnn" style={{padding:"1px"}}
                                                  onClick={() =>{
                                                    removeRow(sideindex)
                                                  }}
                                                ><RiDeleteBin6Line /></button>
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }   
                                  </tbody>
                                  </table>
                                  </div>
                                  </div>
                                </>
                                :
                                null
                      }
              </div>
            </div>
          </div>

          

          <div className="col-md-12 ">
            <hr />
            </div>

          <div className="col-md-12 form-group">
            <label className="font-18">
              Upload Your Image
              <span className="mark_red">*</span>
            </label>
            <p className="font-12">
              You may upload image, video and URL of the product that you want
              us to design for you.
            </p>
          </div>

          <div className="d-block row">
            <div className="col" style={{ display: 'flex' }}>
              <label htmlFor="img" className="col-md-2">
                Image
              </label>
              <label htmlFor="upload" className="acc-save-nowbtn img_btn">
                <input type="file" id="image" accept="image/png, image/gif, image/jpeg" onChange={handleImage} />

                {/* {file &&

                  <div>
                    <img height={25} width={25} src={URL.createObjectURL(file)} />
                    <span>{file.name}</span>

                  </div>
                } */}
              </label>
              {/* <p className="font-12 ml-3">
                2Mb Max and 5 Images Max (JPG, JPEG and PNG)
              </p> */}

            </div>
            <div className="col" style={{ display: 'flex' }}>
              <label htmlFor="img" className="col-md-2">
                Video
              </label>
              <label htmlFor="upload" className="acc-save-nowbtn img_btn">
                <input type="file" id="video" accept="video/mp4,video/x-m4v,video/*" onChange={handleVideo} />

                {/* {videofile &&

                  <div>
                    <img height={25} width={25} src={URL.createObjectURL(videofile)} />
                    <span>{videofile.name}</span>

                  </div>
                } */}
              </label>
            </div>
            <div className="col" style={{ display: 'flex' }}>
              <label htmlFor="img" className="col-md-2">
                URL
              </label>
              <input
                className="form-control mx-400 mb-10"
                type="text"
                placeholder="Enter URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />

            </div>
            {
            MediaError == true ?
            <div className="col" style={{ display: 'flex' }}>
            <p style={validation}>
                  Please select a file or enter the url of an image/ video to proceed!
              </p>
              </div>
            : null
            }


          </div>

          <div>
          </div>
        </div>
      </div>


              </div>
              <div className="col-md-4">
                {/* <RightSideFrom firstname={firstname} setFirstName={setFirstName} lastname={lastname} setLastName={setLastName}
                  email={email} setEmail={setEmail} password={password} setPassword={setPassword} confirm_password={confirm_password}
                  setConfirm_password={setConfirm_password} authenticate={authenticate} ErrorData={ErrorData} Loading={Loading} setLoading={setLoading} /> */}
                  <div className='custom_box'>
            <div className="cont-title-hed text-center">
                <p className='font-18 ' style={{ display: 'inline' }}>
                    <b>Contact Information</b>
                </p>
            </div>
            <form autoComplete='off'>
                <div className='col-md-12'>
                    <div className='from_Group col-md-12'>
                        <label htmlFor='fname'>First Name<span className='mark_red'>*</span></label>
                        <input type="text" className="form_control" id="fname" value={firstname}
                            onChange={e => setFirstName(e.target.value)}
                            placeholder="Enter first name" />
                        <p id="CompanyName_msg" style={validation}>
                            {ErrorData?.firstname != undefined && ErrorData?.firstname?.map((key, i) => key)}
                        </p>
                    </div>
                    <div className='from_Group col-md-12'>
                        <label htmlFor='lname'>Last Name<span className='mark_red'>*</span></label>
                        <input type="text" className="form_control" id="lname" placeholder="Enter last name"
                            value={lastname}
                            onChange={e => setLastName(e.target.value)} />
                        <p id="CompanyName_msg" style={validation}>
                            {ErrorData?.lastname != undefined && ErrorData?.lastname?.map((key, i) => key)}
                        </p>
                    </div>
                      <div className="form-group col-md-12">
                          <label className="lblmy">Mobile No<span className='mark_red'>*</span></label>
                          <input type="text" className="form_control" id="lname" placeholder="Enter Mobile No"
                            value={mobileno}
                            onChange={e => setMobileNo(e.target.value)} minLength="7" maxLength="12"/>
                              {ErrorData?.mobileno != undefined ? 
                          <p style={{ color: Colors.red, height: 8,marginBottom:0 }}>
                              {ErrorData?.mobileno != undefined && ErrorData?.mobileno?.map((key, i) => key)}
                          </p> : null }
                      </div>
                    <div className='from_Group col-md-12'>
                        <label htmlFor='email'>Email Address<span className='mark_red'>*</span></label>
                        <input disabled={userData != null ? true : false} type="email" className="form_control" id="email" placeholder="Enter email id"
                            value={userData != null ? userData?.email : email}
                            onChange={e => setEmail(e.target.value)} />
                        <p id="CompanyName_msg" style={validation}>
                            {ErrorData?.email != undefined && ErrorData?.email?.map((key, i) => key)}
                        </p>
                    </div>
                    {userData == null &&
                        <>
                            <div className='from_Group col-md-12'>
                                <label htmlFor='password'>Password<span className='mark_red'>*</span></label>
                                <input disabled={userData != null ? true : false} type="password" className="form_control" id="password" placeholder='Password'
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    autoComplete='off' />
                                <p id="CompanyName_msg" style={validation}>
                                    {ErrorData?.password != undefined && ErrorData?.password?.map((key, i) => key)}
                                </p>
                            </div>
                            <div className='from_Group col-md-12'>
                                <label htmlFor='com-password'>Confirm Password<span className='mark_red'>*</span></label>
                                <input disabled={userData != null ? true : false} type="password" className="form_control" id="com-password" placeholder='Confirm Password'
                                    value={confirm_password}
                                    onChange={e => setConfirm_password(e.target.value)}
                                    autoComplete='off' />
                                <p id="CompanyName_msg" style={validation}>
                                    {ErrorData?.confirm_password != undefined && ErrorData?.confirm_password?.map((key, i) => key)}
                                </p>
                            </div>
                        </>
                    }
                    <div className='from_Group col-md-12'>
                        <ReCAPTCHA onExpired={() => {
              recaptchaRef.current.reset(); // here
            }}
                    sitekey={process.env.REACT_APP_SITE_KEY} 
                    onChange={handleVerifyccapcha} />
                    </div>

                    <div style={{ display: 'flex', padding: "10px", justifyContent: "center" }}>
                    <button type='button' disabled={(verify == false) ? true : false} onClick={authenticate}
                                                className="login-me btn-shadow-me min-w-200 hand"
                                                style={{
                                                    backgroundColor: (verify == false) && 'lightgray', minWidth: "150px",
                                                }}
                                            >Submit</button>
                        {/* <div disabled={(verify == false) ? true : false} className='acc-save-nowbtn' onClick={() => authenticate()}>Submit</div> */}
                    </div>
                </div>
            </form>
        </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 container" style={{margin:"20px"}}>
          <GuideText />
        </div>
      </section>
    </>
  );
}

export default CustomDesign