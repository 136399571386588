
export const BASE_URL = process.env.REACT_APP_BACKEND_API_URL;

// export const BASE_URL = 'http://192.168.1.220/stage_dioraadams/api';
// export const BASE_URL = 'https://laravel.weingenious.in/diora_adams/backend/api';

// export const API_KEY = 'UEHZU09NRTA0LTA4LTIWMJA1OJE2';


export const GetAPIUrl = {
  // Login_URL: 'user/login',
  CARTWISHLISTCOUNT_URL:'/count-wish-cart',
  B2CSIGNUP_URL: '/b2c-signup',
  B2BSIGNUP_URL: '/b2b-signup',
  LOGIN_URL: '/login',
  FORGETPASSWORD_URL:'/forgot-password',
  RESETPASSWORD_URL:'/password-reset',
  LOGOUT_URL: '/logout',
  COUNTRY: '/country',
  STATE: '/state',
  PARAMETERS: '/parameters',
  HOME_SLIDER_URL:'/home/slider',
  DIAMONDLIST_URL :'/diamonds',
  GALLERYLIST_URL:'/gallery',
  HOME_CATEGORY_URL:'/home/categories',
  HOME_COLLECTION_URL:'/home/collections',
  HOME_ARRIVAL_URL:'/home/new-arrivals',
  HOME_SIGNATURE_URL:'/home/singature-collection',
  JEWELRYLIST_URL:'/products',
  JEWELRY_FILTER_URL:'/Jewellery-filter',
  HOME_SUBSCRIBE_URL:'/home/subscribe-notification',
  DIAMONDDETAIL_URL :'/Diamond',
  JEWELRYDETAIL_URL :'/Jewellery',
  JEWELRYSHORTDETAIL_URL :'/JewelleryShort',
  ADDTOCART_URL :'/cart',
  CARTLIST_URL :'/cart',
  WISHLIST_URL :'/wishlist',
  UPDATEWISHLIST_URL :'/wishlist-update',

  MOVETOWISHLIST_URL :'/cart-move-to-wishlist',
  MOVETOCARTLIST_URL :'/wishlist-move-to-cart',

  RECALCULATE_URL :'/recalculate-jewelery-price',
  CHECKDIAMOND_URL :'/check-diamond-stock',

  DELETECART_URL :'/cart-delete-particular',
  UPDATECART_URL :'/cart-update',

  CURRENCYLIST_URL :'/currencies',
  CURRENCYCONVERT :'/currency-convert',
  CURRENCYCONVERT_URL :'/currency-convert-list',

  DELETEWISHLIST_URL :'/wishlist-delete-particular',
  SEARCH_URL :'/search',
  UPDATEPROFILE_URL :'/profile',
  ADDRESS_URL :'/my-address',
  ADDADDRESS_URL :'/my-address',
  CATEGORY_URL :'/category',
  REMOVEADDRESS_URL: '/my-address/delete',
  CLIENTTOKEN_URL: '/payment-client-token',
  ORDERPLACE_URL: '/order-create',
  ORDERLIST_URL: '/order-list',
  CONTACTUS_URL:'/contactus',
  ORDER_RETURN_URL:'/order-return-request',
  ORDER_CANCEL_URL:'/order-cancel',
  DOWNLOAD_INVOICE:'/order-invoice',
  ACCOUNTACTIVATION_URL :'/accountActivation',
  VERIFYTOKEN_URL:'/verify-token',
  SEOMETADATA_URL:'/seo-meta-details',
  CHECKSTATUS_URL:'/check-status',
  CUSTOMJEWELRY_URL:'/add-custom-jewelry',
  //Customer Group Detail API
  CUSTOMERGROUPDISCOUNT_URL : '/customerGroupDisc',

  STONEGROUPLIST_URL : '/stonegrouplist',
  GLOBALMARKUPTAG_URL : '/globalmarkuptag',
  STONGROUPPRICE_URL : '/stonegroupprice',
};

