// import React from 'react';
// import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'


// export const HomeSkeleton = () => {
//   return (

//     <SkeletonTheme baseColor="#202020" highlightColor="#444">
//         <div className="card">
//             <h1>
//               <Skeleton />
//             </h1>
//             <h2>
//               <Skeleton />
//             </h2>
//             <h3>
//               <Skeleton />
//             </h3>
//           </div>
//           <div className="card">
//             <h1>
//               <Skeleton />
//             </h1>
//             <h2>
//               <Skeleton />
//             </h2>
//             <h3>
//               <Skeleton />
//             </h3>
//           </div>
//           <div className="card">
//             <h1>
//               <Skeleton />
//             </h1>
//             <h2>
//               <Skeleton />
//             </h2>
//             <h3>
//               <Skeleton />
//             </h3>
//           </div>

//     </SkeletonTheme>
//   );
// }

// export const DiamondFilterSkeleton = () => {
//   return (

//     <SkeletonTheme color="#202020" highlightColor="#444">
//       <Skeleton height={50} width={50} />
//   </SkeletonTheme>
//   );
// }
import React from "react"
import ContentLoader from "react-content-loader"

export const DiamondFilterSkeleton = (props) => (
  <ContentLoader
    animate={true}
    speed={1}
    width={400}
    height={160}
    viewBox="0 0 400 160"
    backgroundColor="#e6e6e6"
    foregroundColor="#f3f3f3"
    {...props}
  >
    <rect x="0" y="8" rx="3" ry="3" width="45" height="40" />
    <rect x="49" y="8" rx="3" ry="3" width="45" height="40" />
    <rect x="97" y="8" rx="3" ry="3" width="45" height="40" />
    <rect x="145" y="8" rx="3" ry="3" width="45" height="40" />
    <rect x="193" y="8" rx="3" ry="3" width="45" height="40" />
    <rect x="241" y="8" rx="3" ry="3" width="45" height="40" />
    <rect x="289" y="8" rx="3" ry="3" width="45" height="40" />
    <rect x="337" y="8" rx="3" ry="3" width="45" height="40" />
    <rect x="0" y="55" rx="3" ry="3" width="45" height="40" />
    <rect x="49" y="55" rx="3" ry="3" width="45" height="40" />
    <rect x="97" y="55" rx="3" ry="3" width="45" height="40" />
    <rect x="145" y="55" rx="3" ry="3" width="45" height="40" />
    <rect x="193" y="55" rx="3" ry="3" width="45" height="40" />
    <rect x="241" y="55" rx="3" ry="3" width="45" height="40" />
    <rect x="289" y="55" rx="3" ry="3" width="45" height="40" />
    <rect x="337" y="55" rx="3" ry="3" width="45" height="40" />
  </ContentLoader>
)
export const DiamondListSkeleton = (props) => (
  <ContentLoader
    animate={true}
    speed={1}
    width='100%'
    height={1200}
    // viewBox="0 0 100% 1200"
    backgroundColor="#e6e6e6"
    foregroundColor="#f3f3f3"
    {...props}
  >
    <rect x="0" y="8" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="55" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="103" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="151" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="199" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="247" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="295" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="343" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="391" rx="3" ry="3" width="100%" height="40" />

    <rect x="0" y="439" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="487" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="535" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="583" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="631" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="679" rx="3" ry="3" width="100%" height="40" />

    <rect x="0" y="727" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="775" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="823" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="871" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="919" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="967" rx="3" ry="3" width="100%" height="40" />

    <rect x="0" y="1015" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="1063" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="1111" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="1159" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="1207" rx="3" ry="3" width="100%" height="40" />
    <rect x="0" y="1255" rx="3" ry="3" width="100%" height="40" />
  </ContentLoader>
)

export const ShapeSkeleton = (props) => (
  <ContentLoader
    animate={true}
    speed={1}
    width='100%'
    height={160}
    // viewBox="0 0 100% 160"
    backgroundColor="#e6e6e6"
    foregroundColor="#f3f3f3"
    {...props}
  >
    <rect x="0" y="8" rx="3" ry="0" width="10%" height="120" />
    <rect x="0" y="135" rx="3" ry="0" width="10%" height="20" />

    <rect x="12%" y="8" rx="3" ry="0" width="10%" height="120" />
    <rect x="12%" y="135" rx="3" ry="0" width="10%" height="20" />

    <rect x="24%" y="8" rx="3" ry="0" width="10%" height="120" />
    <rect x="24%" y="135" rx="3" ry="0" width="10%" height="20" />

    <rect x="36%" y="8" rx="3" ry="0" width="10%" height="120" />
    <rect x="36%" y="135" rx="3" ry="0" width="10%" height="20" />

    <rect x="48%" y="8" rx="3" ry="0" width="10%" height="120" />
    <rect x="48%" y="135" rx="3" ry="0" width="10%" height="20" />

    <rect x="60%" y="8" rx="3" ry="0" width="10%" height="120" />
    <rect x="60%" y="135" rx="3" ry="0" width="10%" height="20" />

    <rect x="72%" y="8" rx="3" ry="0" width="10%" height="120" />
    <rect x="72%" y="135" rx="3" ry="0" width="10%" height="20" />

    <rect x="84%" y="8" rx="3" ry="0" width="10%" height="120" />
    <rect x="84%" y="135" rx="3" ry="0" width="10%" height="20" />


  </ContentLoader>
)
export const CategorySkeleton = (props) => (
  <ContentLoader
    animate={true}
    speed={1}
    width='100%'
    height={420}
    // viewBox="0 0 100% 420"
    backgroundColor="#e6e6e6"
    foregroundColor="#f3f3f3"
    {...props}
  >
    <rect x="0" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="0" y="320" rx="3" ry="0" width="23%" height="30" />
    <rect x="0" y="360" rx="3" ry="0" width="23%" height="30" />

    <rect x="25%" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="25%" y="320" rx="3" ry="0" width="23%" height="30" />
    <rect x="25%" y="360" rx="3" ry="0" width="23%" height="30" />

    <rect x="50%" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="50%" y="320" rx="3" ry="0" width="23%" height="30" />
    <rect x="50%" y="360" rx="3" ry="0" width="23%" height="30" />

    <rect x="75%" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="75%" y="320" rx="3" ry="0" width="23%" height="30" />
    <rect x="75%" y="360" rx="3" ry="0" width="23%" height="30" />

    <rect x="100%" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="100%" y="320" rx="3" ry="0" width="23%" height="30" />
    <rect x="100%" y="360" rx="3" ry="0" width="23%" height="30" />
  </ContentLoader>
)
export const SigCollectionSkeleton = (props) => (
  <ContentLoader
    animate={true}
    speed={1}
    width='100%'
    height={420}
    // viewBox="0 0 100% 420"
    backgroundColor="#e6e6e6"
    foregroundColor="#f3f3f3"
    {...props}
  >
    <rect x="0" y="8" rx="3" ry="0" width="32%" height="300" />
    <rect x="0" y="320" rx="3" ry="0" width="32%" height="30" />
    <rect x="0" y="360" rx="3" ry="0" width="32%" height="30" />

    <rect x="33.33%" y="8" rx="3" ry="0" width="32%" height="300" />
    <rect x="33.33%" y="320" rx="3" ry="0" width="32%" height="30" />
    <rect x="33.33%" y="360" rx="3" ry="0" width="32%" height="30" />

    <rect x="66.66%" y="8" rx="3" ry="0" width="32%" height="300" />
    <rect x="66.66%" y="320" rx="3" ry="0" width="32%" height="30" />
    <rect x="66.66%" y="360" rx="3" ry="0" width="32%" height="30" />

    <rect x="100%" y="8" rx="3" ry="0" width="32%" height="300" />
    <rect x="100%" y="320" rx="3" ry="0" width="32%" height="30" />
    <rect x="100%" y="360" rx="3" ry="0" width="32%" height="30" />
  </ContentLoader>
)
export const Collection1Skeleton = (props) => (
  <ContentLoader
    animate={true}
    speed={1}
    width='100%'
    height={750}
    // viewBox="0 0 100% 758"
    backgroundColor="#e6e6e6"
    foregroundColor="#f3f3f3"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="0" width="49%" height="300" />

    <rect x="0" y="330" rx="3" ry="0" width="23%" height="350" />
    <rect x="26%" y="330" rx="3" ry="0" width="23%" height="350" />

    <rect x="51%" y="0" rx="3" ry="0" width="49%" height="680" />

    {/* <rect x="50%" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="50%" y="320" rx="3" ry="0" width="23%" height="30" />
    
    <rect x="75%" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="75%" y="320" rx="3" ry="0" width="23%" height="30" />

    <rect x="100%" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="100%" y="320" rx="3" ry="0" width="23%" height="30" /> */}

  </ContentLoader>
)
export const MainSliderLoading = (props) => (
  <ContentLoader
    animate={true}

    speed={1}
    width='100%'
    height={530}
    // viewBox="0 0 100% 530"
    backgroundColor="#e6e6e6"
    foregroundColor="#f3f3f3"
    {...props}
  >
    <rect x="0" y="0" rx="3" ry="0" width="100%" height="530" />

  </ContentLoader>
)
export const DiamondGridSkeleton = (props) => (
  <ContentLoader
    animate={true}
    speed={1}
    width='100%'
    height={3700}
    // viewBox="0 0 100% 3700"
    backgroundColor="#e6e6e6"
    foregroundColor="#f3f3f3"
    {...props}
  >
    {/* ============================1======================== */}
    <rect x="0" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="0" y="320" rx="3" ry="0" width="23%" height="30" />
    <rect x="0" y="360" rx="3" ry="0" width="23%" height="30" />

    <rect x="25%" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="25%" y="320" rx="3" ry="0" width="23%" height="30" />
    <rect x="25%" y="360" rx="3" ry="0" width="23%" height="30" />

    <rect x="50%" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="50%" y="320" rx="3" ry="0" width="23%" height="30" />
    <rect x="50%" y="360" rx="3" ry="0" width="23%" height="30" />

    <rect x="75%" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="75%" y="320" rx="3" ry="0" width="23%" height="30" />
    <rect x="75%" y="360" rx="3" ry="0" width="23%" height="30" />

    <rect x="100%" y="8" rx="3" ry="0" width="23%" height="300" />
    <rect x="100%" y="320" rx="3" ry="0" width="23%" height="30" />
    <rect x="100%" y="360" rx="3" ry="0" width="23%" height="30" />


    ============================2========================

    <rect x="0" y="420" rx="3" ry="0" width="23%" height="300" />
    <rect x="0" y="730" rx="3" ry="0" width="23%" height="30" />
    <rect x="0" y="770" rx="3" ry="0" width="23%" height="30" />

    <rect x="25%" y="420" rx="3" ry="0" width="23%" height="300" />
    <rect x="25%" y="730" rx="3" ry="0" width="23%" height="30" />
    <rect x="25%" y="770" rx="3" ry="0" width="23%" height="30" />

    <rect x="50%" y="420" rx="3" ry="0" width="23%" height="300" />
    <rect x="50%" y="730" rx="3" ry="0" width="23%" height="30" />
    <rect x="50%" y="770" rx="3" ry="0" width="23%" height="30" />

    <rect x="75%" y="420" rx="3" ry="0" width="23%" height="300" />
    <rect x="75%" y="730" rx="3" ry="0" width="23%" height="30" />
    <rect x="75%" y="770" rx="3" ry="0" width="23%" height="30" />

    <rect x="100%" y="420" rx="3" ry="0" width="23%" height="300" />
    <rect x="100%" y="730" rx="3" ry="0" width="23%" height="30" />
    <rect x="100%" y="770" rx="3" ry="0" width="23%" height="30" />


    {/* ============================3======================== */}

    <rect x="0" y="830" rx="3" ry="0" width="23%" height="300" />
    <rect x="0" y="1140" rx="3" ry="0" width="23%" height="30" />
    <rect x="0" y="1180" rx="3" ry="0" width="23%" height="30" />

    <rect x="25%" y="830" rx="3" ry="0" width="23%" height="300" />
    <rect x="25%" y="1140" rx="3" ry="0" width="23%" height="30" />
    <rect x="25%" y="1180" rx="3" ry="0" width="23%" height="30" />

    <rect x="50%" y="830" rx="3" ry="0" width="23%" height="300" />
    <rect x="50%" y="1140" rx="3" ry="0" width="23%" height="30" />
    <rect x="50%" y="1180" rx="3" ry="0" width="23%" height="30" />

    <rect x="75%" y="830" rx="3" ry="0" width="23%" height="300" />
    <rect x="75%" y="1140" rx="3" ry="0" width="23%" height="30" />
    <rect x="75%" y="1180" rx="3" ry="0" width="23%" height="30" />

    <rect x="100%" y="830" rx="3" ry="0" width="23%" height="300" />
    <rect x="100%" y="1140" rx="3" ry="0" width="23%" height="30" />
    <rect x="100%" y="1180" rx="3" ry="0" width="23%" height="30" />


    {/* ============================4======================== */}

    <rect x="0" y="1240" rx="3" ry="0" width="23%" height="300" />
    <rect x="0" y="1550" rx="3" ry="0" width="23%" height="30" />
    <rect x="0" y="1590" rx="3" ry="0" width="23%" height="30" />

    <rect x="25%" y="1240" rx="3" ry="0" width="23%" height="300" />
    <rect x="25%" y="1550" rx="3" ry="0" width="23%" height="30" />
    <rect x="25%" y="1590" rx="3" ry="0" width="23%" height="30" />

    <rect x="50%" y="1240" rx="3" ry="0" width="23%" height="300" />
    <rect x="50%" y="1550" rx="3" ry="0" width="23%" height="30" />
    <rect x="50%" y="1590" rx="3" ry="0" width="23%" height="30" />

    <rect x="75%" y="1240" rx="3" ry="0" width="23%" height="300" />
    <rect x="75%" y="1550" rx="3" ry="0" width="23%" height="30" />
    <rect x="75%" y="1590" rx="3" ry="0" width="23%" height="30" />

    <rect x="100%" y="1240" rx="3" ry="0" width="23%" height="300" />
    <rect x="100%" y="1550" rx="3" ry="0" width="23%" height="30" />
    <rect x="100%" y="1590" rx="3" ry="0" width="23%" height="30" />


    {/* ============================5======================== */}

    <rect x="0" y="1650" rx="3" ry="0" width="23%" height="300" />
    <rect x="0" y="1960" rx="3" ry="0" width="23%" height="30" />
    <rect x="0" y="2000" rx="3" ry="0" width="23%" height="30" />

    <rect x="25%" y="1650" rx="3" ry="0" width="23%" height="300" />
    <rect x="25%" y="1960" rx="3" ry="0" width="23%" height="30" />
    <rect x="25%" y="2000" rx="3" ry="0" width="23%" height="30" />

    <rect x="50%" y="1650" rx="3" ry="0" width="23%" height="300" />
    <rect x="50%" y="1960" rx="3" ry="0" width="23%" height="30" />
    <rect x="50%" y="2000" rx="3" ry="0" width="23%" height="30" />

    <rect x="75%" y="1650" rx="3" ry="0" width="23%" height="300" />
    <rect x="75%" y="1960" rx="3" ry="0" width="23%" height="30" />
    <rect x="75%" y="2000" rx="3" ry="0" width="23%" height="30" />

    <rect x="100%" y="1650" rx="3" ry="0" width="23%" height="300" />
    <rect x="100%" y="1960" rx="3" ry="0" width="23%" height="30" />
    <rect x="100%" y="2000" rx="3" ry="0" width="23%" height="30" />


    {/* ============================6======================== */}

    <rect x="0" y="2060" rx="3" ry="0" width="23%" height="300" />
    <rect x="0" y="2370" rx="3" ry="0" width="23%" height="30" />
    <rect x="0" y="2410" rx="3" ry="0" width="23%" height="30" />

    <rect x="25%" y="2060" rx="3" ry="0" width="23%" height="300" />
    <rect x="25%" y="2370" rx="3" ry="0" width="23%" height="30" />
    <rect x="25%" y="2410" rx="3" ry="0" width="23%" height="30" />

    <rect x="50%" y="2060" rx="3" ry="0" width="23%" height="300" />
    <rect x="50%" y="2370" rx="3" ry="0" width="23%" height="30" />
    <rect x="50%" y="2410" rx="3" ry="0" width="23%" height="30" />

    <rect x="75%" y="2060" rx="3" ry="0" width="23%" height="300" />
    <rect x="75%" y="2370" rx="3" ry="0" width="23%" height="30" />
    <rect x="75%" y="2410" rx="3" ry="0" width="23%" height="30" />

    <rect x="100%" y="2060" rx="3" ry="0" width="23%" height="300" />
    <rect x="100%" y="2370" rx="3" ry="0" width="23%" height="30" />
    <rect x="100%" y="2410" rx="3" ry="0" width="23%" height="30" />

    {/* ============================7======================== */}

    <rect x="0" y="2470" rx="3" ry="0" width="23%" height="300" />
    <rect x="0" y="2780" rx="3" ry="0" width="23%" height="30" />
    <rect x="0" y="2820" rx="3" ry="0" width="23%" height="30" />

    <rect x="25%" y="2470" rx="3" ry="0" width="23%" height="300" />
    <rect x="25%" y="2780" rx="3" ry="0" width="23%" height="30" />
    <rect x="25%" y="2820" rx="3" ry="0" width="23%" height="30" />

    <rect x="50%" y="2470" rx="3" ry="0" width="23%" height="300" />
    <rect x="50%" y="2780" rx="3" ry="0" width="23%" height="30" />
    <rect x="50%" y="2820" rx="3" ry="0" width="23%" height="30" />

    <rect x="75%" y="2470" rx="3" ry="0" width="23%" height="300" />
    <rect x="75%" y="2780" rx="3" ry="0" width="23%" height="30" />
    <rect x="75%" y="2820" rx="3" ry="0" width="23%" height="30" />

    <rect x="100%" y="2470" rx="3" ry="0" width="23%" height="300" />
    <rect x="100%" y="2780" rx="3" ry="0" width="23%" height="30" />
    <rect x="100%" y="2820" rx="3" ry="0" width="23%" height="30" />

    {/* ============================8======================== */}

    <rect x="0" y="2880" rx="3" ry="0" width="23%" height="300" />
    <rect x="0" y="3190" rx="3" ry="0" width="23%" height="30" />
    <rect x="0" y="3230" rx="3" ry="0" width="23%" height="30" />

    <rect x="25%" y="2880" rx="3" ry="0" width="23%" height="300" />
    <rect x="25%" y="3190" rx="3" ry="0" width="23%" height="30" />
    <rect x="25%" y="3230" rx="3" ry="0" width="23%" height="30" />

    <rect x="50%" y="2880" rx="3" ry="0" width="23%" height="300" />
    <rect x="50%" y="3190" rx="3" ry="0" width="23%" height="30" />
    <rect x="50%" y="3230" rx="3" ry="0" width="23%" height="30" />

    <rect x="75%" y="2880" rx="3" ry="0" width="23%" height="300" />
    <rect x="75%" y="3190" rx="3" ry="0" width="23%" height="30" />
    <rect x="75%" y="3230" rx="3" ry="0" width="23%" height="30" />

    <rect x="100%" y="2880" rx="3" ry="0" width="23%" height="300" />
    <rect x="100%" y="3190" rx="3" ry="0" width="23%" height="30" />
    <rect x="100%" y="3230" rx="3" ry="0" width="23%" height="30" />

    {/* ============================9======================== */}

    <rect x="0" y="3290" rx="3" ry="0" width="23%" height="300" />
    <rect x="0" y="3600" rx="3" ry="0" width="23%" height="30" />
    <rect x="0" y="3640" rx="3" ry="0" width="23%" height="30" />

    <rect x="25%" y="3290" rx="3" ry="0" width="23%" height="300" />
    <rect x="25%" y="3600" rx="3" ry="0" width="23%" height="30" />
    <rect x="25%" y="3640" rx="3" ry="0" width="23%" height="30" />

    <rect x="50%" y="3290" rx="3" ry="0" width="23%" height="300" />
    <rect x="50%" y="3600" rx="3" ry="0" width="23%" height="30" />
    <rect x="50%" y="3640" rx="3" ry="0" width="23%" height="30" />

    <rect x="75%" y="3290" rx="3" ry="0" width="23%" height="300" />
    <rect x="75%" y="3600" rx="3" ry="0" width="23%" height="30" />
    <rect x="75%" y="3640" rx="3" ry="0" width="23%" height="30" />

    <rect x="100%" y="3290" rx="3" ry="0" width="23%" height="300" />
    <rect x="100%" y="3600" rx="3" ry="0" width="23%" height="30" />
    <rect x="100%" y="3640" rx="3" ry="0" width="23%" height="30" />

  </ContentLoader>
)