import { Input, TextField } from "@material-ui/core";
import axios from "axios";
import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL, GetAPIUrl } from "../API/APIUrl";
import { BASENAME, utf8_to_b64 } from "../helpers/Utility";
import "./Modal.css";
import { ShowErrorMessage, ShowMessage } from "./Tostify";
import noimage from "../assets/images/product-details/noimage.jpg"
import { width } from "@mui/system";

export const SearchModal = ({ setOpenModal }) => {
    const [Loading, setLoading] = useState(false)

    const [category, setCategory] = useState([])
    const [diamond, setDiamond] = useState([])
    const [jewelry, setJewelry] = useState([])
    const [search, setSearch] = useState()

    // const debounce = (fun) => {
    //     let timer ;
    //     return function (...arg) {
    //         const context = this;
    //         if(timer) clearTimeout(timer)
    //         timer = setTimeout(handler:() => {

    //         },timeout:500);
    //     }
    // }
    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 1000);
        };
    };
    const is_b2b = JSON.parse(localStorage.getItem('data'))
    const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))

    const CallSearchAPI = async (item) => {
        setSearch(item)
        const controller = new AbortController();
        var form = new FormData();

        form.append("search", item);
        form.append("is_b2b", is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b);
        form.append("currency_id", defaultCurrency?.id == null ? null : defaultCurrency?.id);

        // console.log("fff", [...form]);
        if (item.length > 2) {
            setLoading(true)

            await axios.post(BASE_URL + GetAPIUrl.SEARCH_URL, form, {
                headers: {
                    'Accept': 'application/json',
                }
            })
                .then(response => {
                    if (response.data.success == true) {


                        setCategory(response?.data?.data?.category)
                        setDiamond(response?.data?.data?.diamond)
                        setJewelry(response?.data?.data?.jewelry)
                    } else {
                        ShowErrorMessage(response.data.message)
                    }
                }).catch(error => {
                    ShowErrorMessage(error.message)

                }).finally(() => {
                    setLoading(false)
                })
        }

        controller.abort()
    }

    const optimizedFn = useCallback(debounce(CallSearchAPI), []);



    const SearchInput = useRef(null);

    useEffect(() => {
        if (SearchInput.current) {
            SearchInput.current.focus();
        }
    }, []);
    return (
        <div className="show-search header_search"
            style={{
                opacity: 1,
                zIndex: 1000,
                width: "100%",
                height: "100%",
                position: 'fixed',
                top: 0,
                left: 0,
                backgroundColor: "rgba(255,255,255,0.85)",
                // opacity: 0,
            }}
            id="search"   >
            <button type="button"
                className="close"
                onClick={() => {
                    setOpenModal(false);
                }}>×</button>
            <div style={{
                position: "absolute",
                left: "50%",
                top: "25%",
                transform: "translate(-50%, -50%)",
                width: "70%"
            }}>
                <div className="col-md-12" style={{ padding: "5px 2px 10px 2px" }}>

                    <form name="SearchForm" autocomplete="off" id="SearchForm"
                        // action={process.env.PUBLIC_URL +`/SearchData?type=diamond&searchitem=${search}`}
                        className="centered clearfix"

                    >
                        <input type='text'
                            placeholder='Search here...'
                            style={{ borderBottom: "0.1px solid #d2c5c5", padding: "10px 10px 5px 40px" }}
                            className='search-text'
                            data-min-length='1'
                            // list='searchitem'
                            // name='searchitem'
                            // id='searchitem'
                            onChange={(e) => optimizedFn(e.target.value)}
                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            ref={SearchInput}
                            
                        />
                        {/* <datalist id="SearchProducts">
                        </datalist> */}
                        {Loading == true ?
                            <div className="">
                                <p className="searchList">
                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+'/assets/images/search_loder.gif'} style={{ verticalAlign: 'middle' }} height="20px" width="60px" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                </p>
                            </div>
                            :
                            category.length > 0 || diamond.length > 0 || jewelry.length > 0 ?
                                <div className="autocomplete-box row ">
                                    {category.length > 0 &&
                                        <div className="col">
                                            <div id="search" style={{ padding: "5px 2px 10px 2px" }}>
                                                <div style={{ backgroundColor: '#fafafa' }}>
                                                    <h5>Category</h5>
                                                </div>
                                                {category?.map((item, index) => {
                                                    return (
                                                        <a
                                                            key={Math.random()}
                                                            className="nav-link t"
                                                            style={{ textDecoration: "none", cursor: 'pointer' }}
                                                            aria-current="page"
                                                            // state={item}
                                                            href={process.env.PUBLIC_URL + `/Jewelry/${item?.name}}`}                                                            
                                                        >
                                                            <div key={Math.random()} type='text' placeholder='Search here...'
                                                                className='search-text' style={{ cursor: 'pointer', display: 'flex' }}>
                                                                {item?.name}
                                                            </div>
                                                        </a>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    }
                                    {diamond.length > 0 &&
                                        <div className="col">
                                            <div id="search" style={{ padding: "5px 2px 10px 2px" }}>
                                                <div style={{ backgroundColor: '#fafafa' }}>
                                                    <h5>Diamond</h5>
                                                </div>

                                                {diamond?.map((item, index) => {
                                                    return (
                                                        // <div key={Math.random()} type='text' placeholder='Search here...'
                                                        //     className=' search-text'
                                                        // >
                                                        //     {item?.name}
                                                        // </div>
                                                        <a
                                                            key={Math.random()}
                                                            className="nav-link t"
                                                            style={{ textDecoration: "none" }}
                                                            aria-current="page"
                                                            // state={item}
                                                            href={process.env.PUBLIC_URL + `/DiamondDetails/${utf8_to_b64(item?.id)}/${utf8_to_b64(item?.title)}?did=${item?.id}`}
                                                           
                                                        >
                                                            <div className="search-text" style={{ display: 'flex', cursor: 'pointer' }}>
                                                                <div style={{width:(jewelry.length == 0 || category.length == 0) ? "fit-content" :"fit-content"}}>
                                                                    <img src={item?.image == null ? noimage : item?.image} className="shapeImg" onError={(e)=>{
                                                                            e.target.onerror=null
                                                                            e.target.src=noimage
                                                                        }}/>
                                                                </div>
                                                                <div className="ml-3" style={{width:(jewelry.length == 0 || category.length == 0) ? "fit-content" : "fit-content"}}>
                                                                    <p className="search-title">{item?.title}</p>
                                                                    <p className="price">{defaultCurrency?.sign ?? "$"} {item?.diamondprice}</p>
                                                                </div>
                                                                {/* <label className="ml-3" style={{ cursor: 'pointer' }}>{item?.title}</label> */}
                                                            </div>
                                                        </a>
                                                    )
                                                })}
                                            </div>
                                            <a href={process.env.PUBLIC_URL + `/SearchData?type=diamond&searchitem=${search}`} className="col" style={{}}>
                                                <p className="searchList" style={{ textAlign: 'center' }}>See All</p>
                                            </a>
                                        </div>
                                    }
                                    {jewelry.length > 0 &&
                                        <div className="col">
                                            <div id="search"
                                                style={{ padding: "5px 2px 10px 2px", }}
                                            >
                                                <div style={{ backgroundColor: '#fafafa' }}>
                                                    <h5>Jewelry</h5>
                                                </div>

                                                {jewelry?.map((item, index) => {
                                                        return (
                                                        <a
                                                            key={Math.random()}
                                                            className="nav-link t"
                                                            style={{ textDecoration: "none" }}
                                                            aria-current="page"
                                                            // state={item}
                                                            // href={process.env.PUBLIC_URL + `/JDetails/${utf8_to_b64(item?.id)}/${item?.slug}?jid=${item?.id}`}
                                                            href={process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.slug}?jid=${item?.id}`}
                                                            onContextMenu={(e)=>e.preventDefault()}                                                            
                                                        >
                                                            <div className="search-text" style={{ display: 'flex', cursor: 'pointer' }}>
                                                                <div style={{width:(diamond.length == 0 || category.length == 0) ? "fit-content" : "fit-content"}}>
                                                                    <img src={item?.default?.path} className="shapeImg" onError={(e)=>{
                                                                            e.target.onerror=null
                                                                            e.target.src=noimage
                                                                        }}/>
                                                                </div>
                                                                <div className="ml-3" style={{width:(diamond.length == 0 || category.length == 0) ? "fit-content" :"fit-content"}}>
                                                                    <p className="search-title">{item?.title}</p>
                                                                    <p className="price">{defaultCurrency?.sign ?? "$"} {item?.settingprice}</p>
                                                                </div>
                                                                {/* <label className="ml-3xx" style={{ cursor: 'pointer' }}>{item?.title}</label> */}
                                                                {/* <p>$ {item?.settingprice}</p> */}
                                                            </div>
                                                        </a>

                                                    )
                                                })}
                                            </div>
                                            <a href={process.env.PUBLIC_URL + `/SearchData?type=jewellery&searchitem=${search}`} className="col" style={{}}>
                                                <p className="searchList" style={{ textAlign: 'center' }}>See All</p>
                                            </a>
                                        </div>
                                    }

                                </div>

                                :
                                <div className="" style={{}}>
                                    <p className="searchList">No results found</p>
                                </div>
                        }
                    </form>
                </div>

                {/* <div className="col-md-12 open" id="search" style={{ padding: "5px 2px 10px 2px" }}>
                    <button type="button" className="close">×</button>
                    <form name="SearchForm" autocomplete="off" id="SearchForm" action="http://localhost:3000/Jewelry" className="centered clearfix">
                        <input type="text" placeholder="Search here..." 
                        className="search-text" data-min-length="1" list="SearchProducts" 
                        name="SearchProduct" id="SearchProduct"/>
                            <datalist id="SearchProducts">
                            </datalist>
                    </form>
                </div> */}

            </div >
        </div >
    );
}

