
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css'
import { BASE_URL, GetAPIUrl } from '../API/APIUrl'




import LoadingSpinner from '../module/LoadingSpinner'



const Gallery = () => {

    const [List, setList] = useState([
        // { image: SIL_11_LR, name: '', price: '' },
        // { image: SIL_11A_LR_1, name: '', price: '' },
        // { image: SIL_11A_LR_2, name: '', price: '' },
        // { image: SIL_11A_LR_3, name: '', price: '' },
        // { image: SIL_11A_LR_4, name: '', price: '' },
        // { image: SIL_11A_LR_5, name: '', price: '' },
        // { image: SIL_133A_LR, name: '', price: '' },
        // { image: SIL_133A_LR_1, name: '', price: '' },
        // { image: SIL_133A_LR_2, name: '', price: '' },
        // { image: SIL_133A_LR_3, name: '', price: '' },
        // { image: SIL_133A_LR_4, name: '', price: '' },
        // { image: SIL_133A_LR_5, name: '', price: '' },
        // { image: SIL_133A_LR_6, name: '', price: '' },
        // { image: SLPN_1161_C, name: '', price: '' },
        // { image: SLPN_1161_C_1, name: '', price: '' },
        // { image: SLPN_1161_C_2, name: '', price: '' },
        // { image: SLPN_1161_C_3, name: '', price: '' },
        // { image: SLPN_1161_C_4, name: '', price: '' },
        // { image: SLPN_2090, name: '', price: '' },
        // { image: SLPN_2090_1, name: '', price: '' },
        // { image: SLPN_2090_2, name: '', price: '' },
        // { image: SLPN_2090_3, name: '', price: '' },
        // { image: SLPN_2090_4, name: '', price: '' },
        // { image: SLPN_2188, name: '', price: '' },
        // { image: SLPN_2188_1, name: '', price: '' },
        // { image: SLPN_2188_2, name: '', price: '' },
        // { image: SLPN_2188_3, name: '', price: '' },
        // { image: SLPN_2188_4, name: '', price: '' },

    ]);
    const [Loading, setLoading] = useState(true)

    useEffect(() => {
        CallGalleryListApi()
    }, [])


    const CallGalleryListApi = async () => {
        const controller = new AbortController();
        await axios.get(BASE_URL + GetAPIUrl.GALLERYLIST_URL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    setList(response.data.data);
                }
            }).catch(error => {
                // ShowErrorMessage(error.message)
            }).finally(() => {
                setLoading(false)
            })
        controller.abort()
    }




    return (
        <section className="jumbotron pt-3" >
            {Loading ?
                <LoadingSpinner />
                :
                null}
            <div className="container container-main" >
                <div className="product-page-div">


                    {List.map((item, index) => {
                        return (
                            <div key={Math.random()} className="product-div find-img">
                                <div className="product-div-div">
                                    <div className="product-div-box">
                                        {/* <span className="heart-span hand">
                                            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#3AB591" stroke-opacity="0.65" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </span> */}
                                        <div className="product-div-list">
                                            <img src={item?.image} className="inner-img-product change-image" />
                                        </div>
                                        <div className="text-center show-viewbtn">

                                            <h5 className="product-title pt-3 line1-doted-3">
                                                {item?.title}
                                            </h5>
                                            <p className="product-title-price mb-0">
                                                $ {item?.price}
                                            </p>
                                            {/* <div className="pt-3 hide-view-btn">
                                                <a className="view-details-btn">
                                                    <span className="span-link">
                                                        View Details
                                                    </span>
                                                    <span>&nbsp;
                                                        <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10L6 5.5L1 1" stroke="#23282D" stroke-width="1.5" stroke-linecap="round" /></svg>
                                                    </span>
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        )
                    })}

                    <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', paddingTop: 30 }}>
                        {/* <Pagination
                        color='primary'
                        count={count}
                        size="large"
                        page={Currentpage}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                    /> */}
                    </div>
                </div>
            </div>
        </section >

    )
}

export default Gallery