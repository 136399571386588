import React, { useState } from "react";
import "./contactus.css";
import { BsPatchQuestion, BsStars, BsArrowUpRight, BsArrowRight } from "react-icons/bs"; // !npm install react-icons 
import { TfiEmail } from "react-icons/tfi";
import { Link } from "react-router-dom";
import { fetch2 } from "../../helpers/fetch";
import { BASE_URL, GetAPIUrl } from "../../API/APIUrl";
import { ShowErrorMessage, ShowMessage } from "../../module/Tostify";
import { useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha"
import { Buttontelto } from "../../helpers/Utility";
import LoadingSpinner from "../../module/LoadingSpinner";

const ContactUs = () => {
  const recaptchaRef = React.createRef();

  const [verify,setVerify]=useState(false)
  const [success,setSuccess]=useState(false)
  const [msg,setMsg]=useState("")
  const [successMsg, setSuccessMsg] = useState('')
  const [contact, setcontact] = useState(
    {
      name: '',
      email: '',
      phone: '',
      message: ''
    }
  );
  const [newsEmail, setNewsEmail] = useState({ NewsEmail: '' })
  const [getValidEmail, setValidEmail] = useState('')
  const [joinMsg, setJoinMsg] = useState('')
  const [validation, setvalidation] = useState({
    name: '',
    email: '',
    message: ''
  })
  const checkValidation = () => {
    let error = validation;
    if (!contact.name.trim()) {
      error.name = "Name is required."
    }
    else {
      error.name = ''
    }
    if (!contact.email.trim()) {
      error.email = "Email is Required."
    }
    else if (!contact.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|("."))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      error.email = "Enter Valid Email."
    }
    else {
      error.email = ''
    }
    if (!contact.message.trim()) {
      error.message = "Message is Required."
    }
    else {
      error.message = ""
    }
    setvalidation(error)
  }


  const handleSubmit = (event) => {
    event.preventDefault()
    checkValidation()
    const { name, email, message } = validation
    if (name === '' && email === '' && message === '') {
      setSuccessMsg("Thank you for your message. It has been sent.")
    }
    setcontact({
      name: '',
      email: '',
      phone: '',
      message: ''

    })
  }
  // console.log("sdsafsafs:",joinMsg);

  const handelInputChange = (event) => {
    setcontact((preProps) => ({
      ...preProps, [event.target.name]: event.target.value
    }))
  }

  const JoinButtonChange = (event) => {
    event.preventDefault()
    let er = getValidEmail
    if (!newsEmail.NewsEmail.trim()) {
      er = "Email is Required."
    }
    else if (!newsEmail.NewsEmail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|("."))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      er = "Enter Valid Email."
    }
    else {
      er = ''
    }
    setValidEmail(er)
    if (newsEmail === '') {
      setJoinMsg("thank you")
    }

    setNewsEmail('')
  }

  function handleVerifyccapcha(value) {
    setVerify(true)
  }

  const getEmail = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    setNewsEmail(() => {
      return {
        ...newsEmail,
        [name]: value
      }
    })
  }
  const userDetails = useSelector(state => state.user)
  const [ErrorData, setErrorData] = useState([])
  const [Loading, setLoading] = useState(false)

  const authenticate = async event => {
    event.preventDefault()
    setLoading(true)
    // dispatch(signupB2CUser({ firstName, lastName, email, password, confirmPassword }))
    let data = {
      "name": contact.name,
      "email": contact.email,
      "mobile": parseInt(contact.phone),
      "message": contact.message,
    }
    const result = await fetch2(BASE_URL + GetAPIUrl.CONTACTUS_URL, data, userDetails.token)
    if (result?.success == true) {
      setLoading(false)
      // ShowMessage(result.message)
      setSuccess(true)
      setMsg("Thank you for reaching out to us. We appreciate your inquiry and will respond to you as soon as possible. Have a great day!")
      setErrorData([])
      setcontact({
        name: '',
        email: '',
        phone: '',
        message: ''
      })
      window.grecaptcha.reset();
      setVerify(false)
    } else {
      setVerify(true)
      // ShowErrorMessage(result.message)
      setErrorData(result.errors)
      setLoading(false)
    }
  }

  // console.log("asda",newsEmail);
  return (
    <>
    {Loading == true &&

<LoadingSpinner />
}
      <section className="">
        <div className="container">
          <div className="container container-main">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={'/'} className="desk-top-title-sm a-taglink"><i className="fa fa-home" aria-hidden="true"></i></Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Contact us</li>
              </ol>
            </nav>
          </div>
          <form onSubmit={authenticate}>
            <div className="row">
              {
                success == true?
                <>
                  <div className="col-lg-12 text-center" style={{color:'green',fontSize:"18px"}}>
                    {msg}
                  </div>
                </>
                 : null
              }
              <div className="col-lg-12">
                <div className="d-flex justify-content-center mr">
                  <h2 className="font text-center">Keep In Touch with Us</h2>
                </div>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <div className="mr">
                  <p className="text-center">
                    Our Customer Care team are available for support. Alternatively, look to our{' '}
                    <a className="hrefA" href={process.env.PUBLIC_URL + '/Faq'}>
                      FAQs{' '}
                    </a>
                    page for answers to common queries.
                  </p>
                </div>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-2"></div>
              <div className="col-lg-8">
                <div className="mr2">
                  <p className="text-center">
                    Contact us on <Buttontelto label={process.env.REACT_APP_CONTACT} telto={"tel:"+process.env.REACT_APP_CONTACT} /> or use the form below to
                    submit your email, and please note response times are
                    currently up to 48 hours during this busy period.
                  </p>
                </div>
              </div>
              <div className="col-lg-2"></div>
              <div className="col-lg-1"></div>
              <div className="col-lg-5">
                <span>
                  <input className="w-100 inpCls mb" placeholder="Your Name*" name="name" value={contact.name} onChange={handelInputChange} />
                  {ErrorData.name && <p className="text-danger">{ErrorData.name[0]}</p>}</span>
              </div>
              <div className="col-lg-5">
              <span>
                <input className="w-100 inpCls mb" placeholder="Your Email*" name="email" value={contact.email} onChange={handelInputChange} />
              {ErrorData.email && <p className="text-danger">{ErrorData.email[0]}</p>}
              </span>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <span>
                  <input maxLength={10} className="inpCls w-100 mb" placeholder="Your Mobile No.*" name="phone" value={contact.phone} onChange={handelInputChange} />
                {ErrorData.mobile && <p className="text-danger">{ErrorData.mobile[0]}</p>}
                  </span>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <br />
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <span className="mb-4">
                  <textarea className="inpCls w-100 mb" placeholder="Message..." cols="40" rows="10" name="message" value={contact.message} onChange={handelInputChange} />
                {ErrorData.message && <p className="text-danger">{ErrorData.message[0]}</p>}
                </span>
              </div>
              <div className="col-lg-1"></div>
            </div>
            <div className="row">
              <div className="col-lg-1"></div>
              <div className="col-lg-10">
                <span className="mb-4">
                <ReCAPTCHA ref={recaptchaRef} onExpired={() => {
              recaptchaRef.current.reset(); // here
            }}
                    sitekey={process.env.REACT_APP_SITE_KEY} 
                    onChange={handleVerifyccapcha}
                    /></span>
              </div>
              <div className="col-lg-1"></div>
            </div>
            
            <div className="row" style={{ justifyContent: 'center' }}>
              <div className="d-flex justify-content-center">
                <button className="btnCls transiton" type="submit" disabled={!verify} style={{cursor:`${verify == true ? "pointer" : "not-allowed"}`}}>Send Message</button>
              </div>
              <div className={`d-flex justify-content-center mt-4 text-success ${successMsg ? 'visible' : 'invisible'}`}><span className="border p-3 text-center">{successMsg}</span></div>
            </div>
          </form>
        </div>
      </section>
      <section className="m-4">
        <div className="row">
          <div className="col-md-4 mb">
            <div className="d-flex justify-content-center divHeight">
              <div className="text-center BoxCard">
                <a href={process.env.PUBLIC_URL + '/Faq'} className="anchorTag">
                  <div className="boxIcon"><BsPatchQuestion className="BsIcon" /></div>
                  <div className="boxTitle">FAQ</div>
                  <div className="boxContent">Most questions can be answered here.</div>
                  <div className="boxGoMore">GO TO FAQS<BsArrowUpRight className="pdB" /></div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb">
            <div className="d-flex justify-content-center divHeight">
              <div className="text-center BoxCard">
                <a href={"mailto:"+process.env.REACT_APP_EMAIL} className="anchorTag">
                  <div className="boxIcon"><TfiEmail className="BsIcon" /></div>
                  <div className="boxTitle">Email</div>
                  <div className="boxContent">Send us an email to speak to an agent directly.</div>
                  <div className="boxGoMore">{process.env.REACT_APP_EMAILUPPER}<BsArrowUpRight className="pdB" /></div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb">
            <div className="d-flex justify-content-center divHeight">
              <div className="text-center BoxCard">
                <a href={"tel:"+process.env.REACT_APP_CONTACT} className="anchorTag">
                  <div className="boxIcon"><BsStars className="BsIcon" /></div>
                  <div className="boxTitle">Address</div>
                  <div className="boxContent">
                     10544 Hounslow Dr, Woodstock, MD 21163
                    <br />
                    {process.env.REACT_APP_CONTACT}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="google-map map-style m-4">
          <div className="container">
            Map Component Place
          </div>
      </section> */}
      {/* <section className="newsletter">
        <div className="d-flex justify-content-center">
          <h1>Sign up for our newsletter</h1>
        </div>
        <div className="d-flex justify-content-center ">
          <p className="w-25 text-center p">NEW BLACK FRIDAY FLASH SALES Up to 50% off*</p>
        </div>
        <div className="row">
          <div className="col-lg-4"></div>
          <div className="col-lg-3 mb"><input className="w-100 inpCls" placeholder="Email Address..." name="NewsEmail" onChange={getEmail} />
            {getValidEmail && <p className="text-danger">{getValidEmail}</p>}
          </div>
          <div className="col-lg-2"><button className="joinBtn" onClick={JoinButtonChange}>Join<BsArrowRight className="ml" /></button>
          </div>
          <div className="col-lg-3"></div>
          <div className={`d-flex justify-content-center mt-4 text-success ${joinMsg ? 'visible' : 'invisible'}`}><span className="border p-3 text-center">{joinMsg}</span></div>
        </div>
        <div className="d-flex justify-content-center"><p className="w-50 mt-3 text-center">By signing up you agree with our Terms & Conditions and Privacy Policy. To opt out, click Unsubscribe in our emails.</p></div>
      </section> */}
    </>
  );
};

export default ContactUs;
