import { Link } from "react-router-dom";
import { SCREEN_DURATION } from "../constant/Constant";
import Payment from "payment";


// export const BASENAME = "diora_adams_frontend/"
export const BASENAME = "/"

export const getAPIHeader = async (token = null) => {
  // console.log('reduxObj', "=" + reduxObj)
  let data = {};
  // const ipAdd = await getLocalStoreData(LocalStorageKey.IpAddress).then(
  //   (val) => {
  //     var value = JSON.parse(val);
  //     return value;
  //   },
  // );
  // data['api-key'] = API_KEY;
  // data['clientip'] = ipAdd;
  data['Access-Control-Allow-Origin'] = '*';
  data['Content-Type'] = 'application/json';
  data['Accept'] = 'application/json';
  if (token == null) {
    data['logintoken'] = '';
  } else {
    data['logintoken'] = token;
  }
  return data;
};

export const utf8_to_b64 = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export const b64_to_utf8 = (str) => {
  return decodeURIComponent(escape(window.atob(str)));
}

export const gotoNextScreenwithData = (navigateData) => {
  window.location.pathname = process.env.PUBLIC_URL + navigateData
}
export const ButtonMailto = ({ mailto, label }) => {
  return (
    <Link
      to='#'
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};
export const Buttontelto = ({ telto, label }) => {
  return (
    <Link
      to='#'
      onClick={(e) => {
        window.location.href = telto;
        e.preventDefault();
      }}
    >
      {label}
    </Link>
  );
};

export function goToBackScreen(param_navigation_prop, isTimeout = false) {
  if (isTimeout) {
    setTimeout(() => {
      param_navigation_prop.goBack();
    }, SCREEN_DURATION);
  } else {
    param_navigation_prop.goBack();
  }
}




function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

