import noimage from "../../assets/images/product-details/noimage.jpg";

const Img = (props) => {
    
    return (
        <img src={props.src} onError={(e)=>{
            e.target.onerror=null
            e.target.src=noimage
        }} width={"27"} />
    )
}

export default Img