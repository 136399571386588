import axios from 'axios';
import {isMobile} from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import { useSelector } from 'react-redux';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import noimage from "../assets/images/product-details/noimage.jpg"
import { Link } from 'react-router-dom';
import { BASE_URL, GetAPIUrl } from '../API/APIUrl';
import { BASENAME } from '../helpers/Utility';
import LoadingSpinner from '../module/LoadingSpinner';
import { CategorySkeleton, Collection1Skeleton, MainSliderLoading, ShapeSkeleton } from '../module/Skeletons';
import { ShowErrorMessage, ShowMessage } from '../module/Tostify';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const $ = window.$;

const Home = () => {
    const token = useSelector((state) => state?.user?.token)

    useEffect(() => {
        CallBannerListApi()
        CallShapeListApi()
        CallCategoryListApi()
        CallCollectionList1Api()
        CallArrivalListApi()
        CallSignatureListApi()
    }, [])

    // if (!authorized) {
    //     return <Navigate to="/Login" />;                
    // } 

    // const cookies = new Cookies();

    // console.log("email", cookies.get('email'));
    // console.log("password", cookies.get('password'));

    const [mainSliderDetail, setMainSliderDetail] = useState([])
    const [IsmainSliderLoading, setIsmainSliderLoading] = useState(true)

    const [categoryList, setCategoryList] = useState([])
    const [IsCategoryLoading, setIsCategoryLoading] = useState(true)

    const [CollectionList4, setCollectionList4] = useState([])

    const [CollectionList1, setCollectionList1] = useState([])
    const [IsCollectionList1, setIsCollectionList1] = useState(true)

    const [CollectionList2, setCollectionList2] = useState([])
    // const [IsCollectionList2, setIsCollectionList2] = useState(true)
    const [SigCollectionData, setSigCollectionData] = useState([])

    const [SigCollection, setSigCollection] = useState([])
    const [IsSigCollection, setIsSigCollection] = useState(false)

    const [NewArrival, setNewArrival] = useState([])
    const [IsNewArrival, setIsNewArrival] = useState(true)

    const [Shapes, setShapes] = useState([])
    const [Loading, setLoading] = useState(true)

    const [email, setEmail] = useState('')
    const [subscribeLoad, setSubscribeLoad] = useState(false)

    const [engagmentID, setEngagmentID] = useState('');
    const [engagmentName, setEngagmentName] = useState('');

    const [weddingID, setWeddingID] = useState('');
    const is_b2b = JSON.parse(localStorage.getItem('data'))
    const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))

    const CallBannerListApi = async () => {

        await axios.get(BASE_URL + GetAPIUrl.HOME_SLIDER_URL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {

                if (response.data.success == true) {
                    setMainSliderDetail(response?.data);
                }
            }).catch(error => {
                // ShowErrorMessage(error.message)
            }).finally(() => {
                setIsmainSliderLoading(false)
            })
    }
    const CallCategoryListApi = async () => {

        await axios.get(BASE_URL + GetAPIUrl.HOME_CATEGORY_URL, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        })
            .then(response => {

                if (response.data.success == true) {
                    setCategoryList(response?.data?.category);

                    // response?.data?.category?.map((item) => {
                    //     if (item.name == 'Engagement Rings') {
                    //         setEngagmentID(item?.id)
                    //         setEngagmentName(item?.name)
                    //     }
                    //     if (item.name == 'Wedding Ring') {
                    //         setWeddingID(item?.id)
                    //     }

                    // })
                }

            }).catch(error => {
                // ShowErrorMessage(error.message)
            }).finally(() => {
                setIsCategoryLoading(false)
            })


    }
    const CallShapeListApi = async () => {

        const controller = new AbortController();

        var form = new FormData();
        form.append("type", "diamond");
        form.append("paraname[]", "Shape");
        form.append("is_b2b", is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b);

        await axios.post(BASE_URL + GetAPIUrl.PARAMETERS, form)
            .then(response => {
                if (response.data.success == true) {
                    setShapes(response.data.data.Shape)
                }
            }).catch(error => {
                // ShowErrorMessage(error.message)
            }).finally(() => {
                setLoading(false);
            })
        controller.abort()
    }
    const CallCollectionList1Api = async () => {
        const controller = new AbortController();

        var form = new FormData();
        form.append("section_no", "1,2,3,4");

        await axios.post(BASE_URL + GetAPIUrl.HOME_COLLECTION_URL, form)
            .then(response => {
                if (response.data.success == true) {
                    setCollectionList4(response.data.collection_section_1)

                    setCollectionList1(response.data.collection_section_3)
                    setCollectionList2(response.data.collection_section_4)
                    setSigCollectionData(response.data.collection_section_2)
                    // CallCollectionList2Api()
                }
            }).catch(error => {
                // ShowErrorMessage(error.message)
            }).finally(() => {
                setIsCollectionList1(false);
            })
        controller.abort()
    }

    const CallEmailApi = async () => {
        // setSubscribeLoad(true)
        // event.preventDefault()

        let data = {
            "email": email
        }
        // console.log("for",[...form]);
        await axios.post(BASE_URL + GetAPIUrl.HOME_SUBSCRIBE_URL, data, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    ShowMessage(response.data.message)
                } else {
                    ShowErrorMessage(response.errors.email)
                }
            }).catch(error => {
                ShowErrorMessage(error.response.data.errors.email[0])
            }).finally(() => {
                setSubscribeLoad(false)
            })

    }
    const CallArrivalListApi = async () => {
        let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b
        let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;

        const controller = new AbortController();

        await axios.get(BASE_URL + GetAPIUrl.HOME_ARRIVAL_URL + '?is_b2b=' + getdata+'&currency_id='+currency_id)
            .then(response => {
                if (response.status == 200) {
                    setNewArrival(response.data.data);
                }
            }).catch(error => {
                // ShowErrorMessage(error.message)
            }).finally(() => {
                setIsNewArrival(false)
            })
        controller.abort()
    }
    const CallSignatureListApi = async () => {
        let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b
        let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;

        const controller = new AbortController();
        await axios.get(BASE_URL + GetAPIUrl.HOME_SIGNATURE_URL + '?is_b2b=' + getdata+'&currency_id='+currency_id, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    setSigCollection(response.data.data);
                }
            }).catch(error => {
                // ShowErrorMessage(error.message)
            }).finally(() => {
                setIsSigCollection(false)
            })
        controller.abort()
    }




    return (
        < >
            <div className="">
                {subscribeLoad ?
                    <LoadingSpinner />
                    :
                    <main className="main-div">
                        {IsmainSliderLoading ?
                            <MainSliderLoading />
                            :
                            <div className="topbanner blue-hover">
                                {(mainSliderDetail?.mainSliderDetail?.length > 0) ?
                                    <OwlCarousel className='owl-theme' loop
                                        autoplayHoverPause='true' autoplay='true' margin={6} items={1}>
                                        {
                                            mainSliderDetail?.mainSliderDetail?.map((item, index) => {
                                                return (
                                                    <div className="carousel-item active" key={Math.random()}>
                                                        <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} className="d-none d-sm-noe d-md-none d-lg-block d-xl-block w-100" src={item?.image} alt="First slide" width="auto" height="auto" />
                                                        <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} className="d-md-block d-lg-none w-100" src={item?.mobile_img} alt="First slide" width="auto" height="auto" />
                                                        <div className="carousel-caption" >
                                                            <div className="f-left slider-div-box-left-left" style={{ maxWidth: "600px" }}>
                                                                <h2 className="matesc" style={{ letterSpacing: "0.5px", color: "#fff" }}>{item?.title_en}</h2>
                                                                <h6 className="mar-bot-20 f-400wt akkurat" style={{ color: "#fff", lineHeight: "1.5" }}>
                                                                    {item?.description_en}
                                                                </h6>
                                                                {item?.buttontext_en != null &&
                                                                    <a href={item?.buttonlink} className="hand explore-now-btn">
                                                                        <span>{item?.buttontext_en}</span>
                                                                    </a>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }
                                    </OwlCarousel>
                                    : ''
                                }

                            </div>
                        }

                        {
                            (categoryList?.length > 0) ?
                                <section className="jumbotron">
                                    <div className="max-explore-div mob-p-lf-15">
                                        <div style={{ position: "relative" }}>
                                            <div className="mb-4">
                                                <h3 className="explore-title mb-1">
                                                    Explore category
                                                </h3>
                                                <p>
                                                    Brilliant design and unparalleled craftsmanship.
                                                </p>
                                            </div>
                                            {IsCategoryLoading ?
                                                <CategorySkeleton />
                                                :
                                                (categoryList?.length > 0) ?
                                                    <OwlCarousel className='explore-slider explore-arrow explore-product'
                                                        autoplayHoverPause='true' autoplay='true' margin={6} items={(isMobile) ? 2 : 4} loop={categoryList.length > 4 ? true : false} >

                                                        {categoryList?.map((item, index) => {
                                                            return (

                                                                <div className='item' key={Math.random()} style={{ cursor: 'pointer' }} onClick={() => window.location.href = process.env.PUBLIC_URL + `/Jewelry/${item?.name}?cid=${item?.category_id}`}>
                                                                    <div className="text-center">

                                                                        <div className="" title={item?.name}>
                                                                            <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={item?.image} className="mb-3" />
                                                                        </div>
                                                                        <div className="explore-slider-text" title={item?.name}>
                                                                            <a>
                                                                                {item?.name}
                                                                            </a>
                                                                            <p className="line1-doted-2" title={item?.description}>
                                                                                {item?.description.substring(0,80)}
                                                                            </p>
                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            )
                                                        })
                                                        }
                                                    </OwlCarousel>
                                                    : ''
                                            }

                                        </div>
                                    </div>
                                </section>
                                : ''}
                        {CollectionList4?.map((item, index) => {
                            return (
                                <section className="jumbotron-my" key={Math.random()}>
                                    <div className="container">
                                        <div className="row" style={{ position: "relative" }}>
                                            <div className="col-lg-6">
                                                <div>
                                                    <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={item?.images[0]} width="100%" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div>
                                                    <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={item?.images[1]} width="100%" />
                                                </div>
                                            </div>
                                            <div className="custom-diamonds-section" style={{ /*maxWidth: '70%'*/ }}>
                                                <div>
                                                    <div className="mb-4">
                                                        <h3>
                                                            {item?.name}
                                                        </h3>
                                                        <p>
                                                            {item?.description}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        {/* <a className="start-with-btn" href={process.env.PUBLIC_URL + `/Jewelry/${utf8_to_b64("21")}`} onClick={() => { removeStartwithStorageData(); localStorage.setItem('Category', JSON.stringify({ categoryName: "Setting", categoryId: "21" })) }}>
                                                        Start With A Setting
                                                    </a> 
                                                     <a className="start-with-btn" href={process.env.PUBLIC_URL + `/DiamondList/W==/Natural`} onClick={() => { removeStartwithStorageData(); localStorage.setItem('Category', JSON.stringify({ categoryName: "Setting", categoryId: "21" })) }}>
                                                        Start With A Diamond
                                                    </a> */}
                                                        <a className="start-with-btn"
                                                            aria-current="page"
                                                            style={{ textDecoration: "none" }}
                                                            href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${localStorage.getItem("EngagmentName")}?cid=${localStorage.getItem("EngagmentID")}`} >
                                                            Start with a Setting
                                                        </a>

                                                        <a className="start-with-btn"
                                                            aria-current="page"
                                                            style={{ textDecoration: "none" }}
                                                            href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Natural`} >
                                                            Start with a Diamond
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )
                        })}

                        {Shapes?.length > 0 &&
                            <section className="jumbotron-my pt-3">
                                <div className="container container-main" style={{ maxWidth: "1200px" }}>
                                    <div className="mb-4 text-center shop-shape-title" style={{ maxWidth: "700px", margin: "auto" }}>
                                        <h4 className="title-1 mb-3">
                                            Shop By Shape
                                        </h4>
                                        <p className="title-sm">
                                            A Diamond Is Something That Lasts Forever. It Is Essential To Get The Right Shape That Suits The Jewelry. Shop For The Perfect One From Our Remarkably Vast Range.
                                        </p>
                                    </div>

                                    {Loading ?
                                        <ShapeSkeleton />
                                        :
                                        Shapes?.length > 0 ?
                                            <OwlCarousel className='owl-carousel owl-theme' responsiveClass='-'
                                                autoplayHoverPause='true' autoplay='true' margin={6} items={(isMobile) ? 2 : 9} loop>

                                                {Shapes?.map((item, index) => {
                                                    return (

                                                        <div className="item" key={Math.random()} style={{cursor:"pointer"}}>
                                                            {/* <a href={process.env.PUBLIC_URL + `/DiamondList/${item.name}?shape=${item.id}`} > */}
                                                                <div className="shape-div" style={{textAlign:"center"}}>
                                                                    <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} className='shapeImg' 
                                                                     style={{margin:'0 auto'}}
                                                                    src={item?.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : item?.image}
                                                                     />
                                                                    <label className="lbl-sld-shape">{item?.name}</label>
                                                                </div>
                                                            {/* </a> */}
                                                        </div>

                                                    )
                                                })
                                                }
                                            </OwlCarousel>
                                            : ''
                                    }

                                </div>

                            </section>
                        }
                        {
                            console.log("SigCollection--->",SigCollection)
                        }
                        {SigCollection?.length > 0 &&
                            <section className="jumbotron-my pt-3">
                                <div className="container">
                                    <div className="row">
                                        {SigCollectionData?.map((item, index) => {
                                            return (
                                                <div className="col-lg-4 hide-900" key={Math.random()}>
                                                    <div>
                                                        <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={item?.images[0]} width="100%" height="auto" />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className="col-lg-8">

                                            <div className="" style={{ position: "relative" }}>
                                                {SigCollectionData?.map((item, index) => {
                                                    return (
                                                        <div className="mb-4" key={Math.random()}>
                                                            <h3 className="explore-title mb-1">
                                                                {item?.name}
                                                            </h3>
                                                            <p>
                                                                {item?.description}
                                                            </p>
                                                        </div>

                                                    )
                                                })}

                                                <div>
                                                    {IsSigCollection ?
                                                        <Collection1Skeleton />
                                                        :
                                                        SigCollection?.length > 0 ?
                                                            <OwlCarousel className='owl-carousel owl-theme'
                                                                autoplayHoverPause='true' autoplay='true' margin={6} items={(isMobile) ? 2 : 3} >

                                                                {SigCollection?.map((item, index) => {
                                                                    return (
                                                                        <div className="item" key={Math.random()} style={{ cursor: 'pointer' }} onClick={() => window.open(process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.collection_name}?jid=${item?.id}`,'_blank')}>

                                                                            <div className="explore-slider-text" >
                                                                                <div className="">
                                                                                    <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={item.get_default_img == '' ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/gallery/pp2.jpg" : item.get_default_img} className="mb-3" />
                                                                                </div>
                                                                                <a target='_blank' href={process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.collection_name}?jid=${item?.id}`}>
                                                                                {item?.collection_name}
                                                                            </a>
                                                                            <p className="line1-doted-2">
                                                                                {item?.title}
                                                                            </p>
                                                                            <label className="price-label mt-3">
                                                                            {defaultCurrency?.sign ?? "$"}{item?.price}
                                                                            </label>
                                                                        </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                }
                                                </OwlCarousel>
                                                : ''

                                                    }

                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            </section>}

                {NewArrival?.length > 0 &&
                    <section className="jumbotron-my">
                        <div className="container">
                            <div className="mb-4 text-center shop-shape-title" style={{ maxWidth: "700px", margin: "auto" }}>
                                <h4 className="title-1 mb-3">
                                    Explore our new arrivals
                                </h4>
                                <p className="title-sm">
                                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.
                                </p>
                                <div className="mt-4">
                                    <a className="start-with-btn max-btn-150" href={process.env.PUBLIC_URL + `/Jewelry?collection=New Arrival`}>
                                        Shop Now
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="mt-5 mob-p-lf-15" style={{ marginLeft: '7%', marginRight: '7%' }}>
                            {IsNewArrival ?
                                <CategorySkeleton />
                                :
                                NewArrival?.length > 0 ?
                                    <OwlCarousel className='owl-carousel owl-theme'
                                        autoplayHoverPause='true' autoplay='true' margin={6} items={(isMobile) ? 2 : 5} loop>

                                        {NewArrival?.map((item, index) => {
                                            return (
                                                <div className="item" key={Math.random()} style={{ cursor: 'pointer' }} onClick={() =>  window.open(process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.slug}?jid=${item.id}&collection=New Arrival`,'_blank')} onContextMenu={(e)=>e.preventDefault()} >
                                                    <div className="">
                                                        <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={(item.default.path === '' || item.default.path == undefined || item.default.path == null) ? noimage : item.default.path} className="mb-3" />
                                                    </div>
                                                    <div className="explore-slider-text">

                                                        <a className="line1-doted-2">
                                                            {item?.title}
                                                        </a>
                                                        <label class="price-label mt-2">
                                                        {defaultCurrency?.sign ?? "$"}{item?.settingprice}
                                                        </label>
                                                    </div>
                                                </div>
                                            )

                                        })
                                        }
                                    </OwlCarousel>
                                    : ''


                            }
                        </div>

                    </section>
                }
                <section className="jumbotron-my" style={{ borderTop: "1px solid #C7C7C7", marginLeft: '7%', marginRight: '7%' }}>
                    <div className="container">
                        <div className="mb-4 text-center shop-shape-title" style={{ maxWidth: "700px", margin: "auto" }}>
                            <h4 className="title-1 mb-3">
                                For your peace of mind
                            </h4>
                            {/* <p className="title-sm">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.
                            </p> */}
                        </div>
                    </div>
                    <div className="pt-5 pb-5" style={{ background: "#FAF7F8" }}>
                        <div className="container">
                            <div className="row" style={{ justifyContent: "center", maxWidth: "1300px", margin: "auto" }}>
                                <div className="col-lg-2 col-6">
                                    <div className="peace-div text-center">
                                        <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/icons/peace1.png"} width="40" />
                                        <label>
                                            Ethical Sourcing
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-6">
                                    <div className="peace-div text-center">
                                        <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/icons/peace2.png"} width="40" />
                                        <label>
                                            GIA Certified Diamonds
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-6">
                                    <div className="peace-div text-center">
                                        <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/icons/peace3.png"} width="40" />
                                        <label>
                                            Lifetime Warranty
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-6">
                                    <div className="peace-div text-center">
                                        <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/icons/peace4.png"} width="40" />
                                        <label>
                                            Lifetime Free Resizing
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-6">
                                    <div className="peace-div text-center">
                                        <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/icons/peace5.png"} width="40" />
                                        <label>
                                            30 Days Returns
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {CollectionList1?.length > 0 &&
                    <section className="jumbotron-my pt-5 pb-2">
                        <div className="container">
                            <div className="">
                                <div className="css-1xi4blx">
                                    <div className="css-1984zml">

                                        {IsCollectionList1 ?
                                            <Collection1Skeleton />
                                            :
                                            CollectionList1?.map((item, index) => {
                                                return (
                                                    <div className="css-4tyedf" key={Math.random()}>
                                                        <div className="css-1dqogan">
                                                            <div className="css-1oi3a6q">
                                                                <div className="css-qsx0m4">
                                                                    <div className="css-1glzdui zap-fonts">{item?.collection}</div>
                                                                    <h2 className="css-q3hfqt zap-fonts">{item?.name}</h2>
                                                                    <div className="css-1xb0htl">{item?.description}
                                                                    <div class="mt-2">
                                                                        <a href={process.env.PUBLIC_URL + `/Jewelry?collection=`+item?.name} class="hand explore-now-btn"><span>Explore Now</span></a>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="css-1mzs0a5"></div>
                                                            <div className="css-1dyfciy">
                                                                <div className="css-11oyddp">
                                                                    <div className="image css-1qcefhn">
                                                                        <div className="css-43j6zp">
                                                                            <picture className="css-bqhx0s"> <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={item?.images[0]} alt="ring" width="100%" /> </picture>
                                                                        </div>
                                                                        <div className="titleInside css-1bz39lf">
                                                                            <h3 className="css-1vk18nx">sentimental styles</h3></div>
                                                                    </div>
                                                                    <div className="content css-teriou">
                                                                        <h3 className="css-6jy47c">sentimental styles</h3></div>
                                                                    <div className="css-16yku65">
                                                                        <div className="focus-indicator css-7hqtpb"></div>
                                                                    </div>
                                                                </div>
                                                                <div className="css-1mzs0a5"></div>
                                                                <div className="css-11oyddp">
                                                                    <div className="image css-1qcefhn">
                                                                        <div className="css-43j6zp">
                                                                            <picture className="css-bqhx0s"> <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={item?.images[1]} alt="ring" width="100%" /> </picture>
                                                                        </div>
                                                                        <div className="titleInside css-1bz39lf">
                                                                            <h3 className="css-1vk18nx">Extraordinary Gifts</h3></div>
                                                                    </div>
                                                                    <div className="content css-teriou">
                                                                        <h3 className="css-6jy47c">Extraordinary Gifts</h3></div>
                                                                    <div className="css-16yku65">
                                                                        <div className="focus-indicator css-7hqtpb"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="css-1mzs0a5"></div>
                                                        <div className="css-11oyddp">
                                                            <div className="image css-1hsqls8">
                                                                <div className="css-3ghw3q">
                                                                    <picture className="css-bqhx0s"> <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={item?.images[2]} alt="ring" width="100%" /> </picture>
                                                                </div>
                                                                <div className="titleInside css-1bz39lf">
                                                                    <h3 className="css-g1eabd">Classics for mom</h3></div>
                                                            </div>
                                                            <div className="content css-teriou">
                                                                <h3 className="css-6jy47c">Classics for mom</h3> </div>
                                                            <div className="css-16yku65">
                                                                <div className="focus-indicator css-7hqtpb"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                {CollectionList2?.length > 0 &&
                    <section className="jumbotron-my pb-3">
                        <div className="container">
                            {IsCollectionList1 ?
                                <Collection1Skeleton />
                                :

                                <div className="row" style={{ paddingLeft: "15px", paddingRight: "15px" }}>

                                    <div className="col-lg-6 p-0 mob-bot-20">
                                        <div>
                                            <div className="pmd-card-media">
                                                <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={CollectionList2[1]?.images[0]} width="100%" height="auto" />
                                            </div>
                                            <div className="custom-jewl-div">
                                                <h4>
                                                    {CollectionList2[0]?.name}
                                                </h4>
                                                <p>
                                                    {CollectionList2[0]?.description}
                                                </p>
                                                <div>
                                                    <a href={process.env.PUBLIC_URL + `/ContactUs`} className="start-with-btn">
                                                        Explore
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-lg-6 p-0">
                                        <div>
                                            <div className="custom-jewl-div pmd-card-media pmd-card-media-down">
                                                <h4>
                                                    {CollectionList2[1]?.name}
                                                </h4>
                                                <p style={{ maxWidth: "450px", marginLeft: "auto", marginRight: "auto" }}>
                                                    {CollectionList2[1]?.description}
                                                </p>
                                                <div>
                                                    <Link
                                                        className="start-with-btn"
                                                        aria-current="page"
                                                        to={'/CustomDesign'}
                                                    >
                                                        Get in touch with us
                                                    </Link>
                                                    {/* <a href={process.env.PUBLIC_URL + `/Appointment`} className="start-with-btn">
                                                                Get in touch with us
                                                            </a> */}
                                                </div>
                                            </div>
                                            <div>
                                                <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={CollectionList2[0]?.images[0]} width="100%" height="auto" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>
                    </section>
                }


                {/* <section className="jumbotron-my pb-5 pt-5" style={{ maxWidth: "1100px", margin: "auto" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 news-rightbor">
                                <div className="touch-div">
                                    <h3 className="">
                                        Let's Keep In Touch
                                    </h3>
                                    <p className="akkurat">
                                        Join our community of jewellery lovers, and receive once a week special offers, news and product launches straight to your inbox.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 news-leftbor">
                                <div>
                                    <div>
                                        <div className="input-group mb-3 sign-up-home" >
                                            <input
                                                type="text"
                                                className="form-control signplace"
                                                placeholder="Your Email Address"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                style={{ border: 0, boxShadow: 'unset' }}
                                            />
                                            <button className="btn Hypatia col-fff" type="button" style={{ padding: 0 }} onClick={() => CallEmailApi()}>
                                                <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.5 1L21 6.99977M21 6.99977L13.5 13M21 6.99977L5.24516e-07 6.99977" stroke="#23282D" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="p-tag">
                                            By submitting your details, you consent to receive marketing emails from dioraadams. You can unsubscribe anytime from this.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

            </main>
                }


        </div>
        </>
    );
};
export default Home;