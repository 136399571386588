import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';


import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Cookies from "universal-cookie";
import { BASE_URL, GetAPIUrl } from "../API/APIUrl";
import menuclose from '../assets/images/icons/menu-close.svg';
import menuopen from '../assets/images/icons/menu-open.svg';
import logo from '../assets/images/logo-img.svg';
import menuimg1 from '../assets/images/menu-img1.png';
import black from '../assets/images/menu-shape/color/black.png';
import blue from '../assets/images/menu-shape/color/blue.png';
import green from '../assets/images/menu-shape/color/green.png';
import olive from '../assets/images/menu-shape/color/olive.png';
import orange from '../assets/images/menu-shape/color/orange.png';
import pink from '../assets/images/menu-shape/color/pink.png';
import purple from '../assets/images/menu-shape/color/purple.png';
import smoky from '../assets/images/menu-shape/color/smoky.png';
import white from '../assets/images/menu-shape/color/white.png';
import yellow from '../assets/images/menu-shape/color/yellow.png';
import startdiamond from '../assets/images/start-diamond.png';
import startring from '../assets/images/start-ring.png';
import noimage from "../assets/images/product-details/noimage.jpg";
import { BASENAME, ButtonMailto, Buttontelto } from "../helpers/Utility";
import LoadingSpinner from "../module/LoadingSpinner";
import { SearchModal } from "../module/SearchModal";
import { ShowErrorMessage, ShowMessage } from "../module/Tostify";
import { addToken, logout } from "../reducers/userReducers";
import { Helmet, HelmetProvider } from 'react-helmet';
import Swal from "sweetalert2";
import { fetch2 } from "../helpers/fetch";

const $ = window.$;

export default function JewelryCard(props) {
    const slug=props.slug
    const item=props.item
    const Wishlist=props.Wishlist
    const token=props.token
    const buildYourOwn=props.buildYourOwn
    const DiamondId=props.DiamondId
    const confirmRemovejewelry=props.confirmRemovejewelry
    const CallAddtoWishlistApi=props.CallAddtoWishlistApi

    const globalJewelryMargin=props.globalJewelryMargin
    const globalMarkup=props.globalMarkup
    const globalTagFact=props.globalTagFact

    const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))
    const [imgHover, setImgHover] = useState(0)
    const [settingPrice, setSettingPrice] = useState(0)
    
    const [prodcutMetalWeight, setProdcutMetalWeight] = useState(0);
    const [finalLabourtCharge,setFinalLabourtCharge] = useState(0);
    
    const [centerDiaPrice,setCenterDiaPrice] = useState(0);
    const [finalGST,setFinalGST] = useState(0);
    const [sideDiaPrice,setSideDiaPrice] = useState(0);
    const [metalAmount,setMetalAmount] = useState(0);
    const [sideDiaData,setSideDiaData] = useState([]);

    const [sideDiaGrpId,setSideDiaGrpId] = useState(null);
    const [selectedSideDiaColor,setSelectedSideDiaColor] = useState('');
    const [selectedSideDiaClarity,setSelectedSideDiaClarity] = useState('');
    const [prodcutMetalPrice, setProdcutMetalPrice] = useState(0);
    const [prodcutSideDiaPrice, setProdcutSideDiaPrice] = useState(0);

    let getpath = item?.feature?.map((data, i) => { return data })
    const is_b2b = JSON.parse(localStorage.getItem('data'))
    let customerGroup = JSON.parse(localStorage.getItem('customerGroup'));    

    const onMouseovr = (id) => {
        document.getElementById(id).innerHTML = setImgHover(id);
    }
    const onMouseout = (id) => {
        document.getElementById(id).innerHTML = setImgHover();
    }
    
    const updatePrice = async (res,labourType,labourChargeIni=1,labourChargeMini=0,otherCharge=null)=> {
        // let metalPrice = prodcutMetalPrice;             
        let metalPrice = res.metalprice;             
        // let sideStonePrice = prodcutSideDiaPrice;        
        let sideStonePrice = res.sidestoneprice;
        let sideDiamondAmount = res.sidestoneprice;
        
        let centerStonePrice = res?.centerstoneprice;
        let markup = res?.mark_up;
        let tagfact = res?.tag_fact;
        
        // let metalWeight = prodcutMetalWeight;
        let metalWeight = res.metalweight;
        let grossWeight = res.grossweight;
        // console.log("metalWeight",metalWeight)
        // console.log("labourtCharge",labourChargeIni)
        // console.log("otherCharge",otherCharge)

        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "sidediamond")
            {
                let sideDiaDisc = customerGroup?.discount;
                let discontPrice = (res?.sidestoneprice * sideDiaDisc)/100;
                // console.log("customerGroup",customerGroup);
                // console.log("res?.sidestoneprice",res?.sidestoneprice);
                setSideDiaPrice((res?.sidestoneprice - discontPrice));
                sideDiamondAmount = (res?.sidestoneprice - discontPrice);
            }else{
                sideDiamondAmount = res?.sidestoneprice;
                setSideDiaPrice(res?.sidestoneprice);
            }   
        }else{
            sideDiamondAmount = res?.sidestoneprice;
            setSideDiaPrice(res?.sidestoneprice);
        }
        // console.log("sideDiamondAmount",sideDiamondAmount);
        sideStonePrice = sideDiamondAmount;
        // console.log("sideStonePrice",sideStonePrice);

        let chainWeight = (res?.chainweight != null) ? res?.chainweight : 0;
        // let totalWeight = metalWeight + chainWeight;
        let totalWeight = labourType == "gross" ? grossWeight : (metalWeight + chainWeight);
            totalWeight = totalWeight.toFixed(2);         
            
        let finalLabCharge =  labourChargeIni * totalWeight;
            finalLabCharge = finalLabCharge > labourChargeMini ? finalLabCharge : labourChargeMini;
        
        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "labour")
            {
                let labourDisc = customerGroup?.discount;
                let discontLabourPrice = (finalLabCharge * labourDisc)/100;
                finalLabCharge=finalLabCharge-discontLabourPrice;
            }
        }
        // console.log("labourCharge",finalLabCharge)

        let totalMetalPrice = metalPrice + finalLabCharge + otherCharge;
            finalLabCharge =  finalLabCharge.toFixed(2);
        let totalMFGCost = totalMetalPrice + sideStonePrice;
        // console.log("totalMFGCost",totalMFGCost)

        let GST = 0;   
        let finalPrice = 0;     
        if( markup > 0 && tagfact > 0){
            let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);
            let finalTagfact = finalMarkup/tagfact;
                finalPrice = finalTagfact + centerStonePrice;
                GST = finalTagfact - totalMFGCost;
                GST = GST.toFixed(2);
        }else if(markup > 0 && tagfact == 0){
            let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);          
                finalPrice = finalMarkup + centerStonePrice;
                 GST = finalMarkup - totalMFGCost;
                 GST = GST.toFixed(2);
        }else if(markup == 0 && tagfact > 0){           
            let finalTagfact = totalMFGCost/tagfact;
                finalPrice = finalTagfact + centerStonePrice;
                GST = finalTagfact - totalMFGCost;
                GST = GST.toFixed(2);
        }else{
               finalPrice = totalMFGCost + centerStonePrice;
        }

        let isb2bgetdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        // console.log("finalPrice before",finalPrice)
        console.log("globalJewelryMargin",globalJewelryMargin)
        console.log("isb2bgetdata",isb2bgetdata)
        if(globalJewelryMargin > 0 && (isb2bgetdata == 0 || token == null)){
            finalPrice = finalPrice + ((finalPrice*globalJewelryMargin)/100);
        }
        // console.log("finalPrice",finalPrice)

        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "fulljewelry")
            {
                let finalDisc = customerGroup?.discount;
                let discontfinalPrice = (finalPrice * finalDisc)/100;
                finalPrice=finalPrice-discontfinalPrice;
            }
        }
        
         setFinalGST(GST);
         setSideDiaPrice(sideStonePrice);
         setCenterDiaPrice(centerStonePrice);
         setMetalAmount(metalPrice);
         setProdcutMetalPrice(metalPrice);
         setSettingPrice(finalPrice.toFixed(2));
         let currencyAmount = defaultCurrency?.amount;
        //  console.log("before conversion-------->...",finalPrice)
         
         finalPrice = finalPrice * currencyAmount;
         finalPrice = finalPrice.toFixed(2);
        //  console.log("after conversion-------->...",finalPrice)
         setSettingPrice(finalPrice)

        //  await axios.post(BASE_URL + GetAPIUrl.CURRENCYCONVERT,{currency_id:defaultCurrency?.id == null ? null : defaultCurrency?.id,amount:finalPrice}, {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json',
        //             // 'Authorization': 'Bearer ' + token,
        //         }
        //     })
        //     .then(response => {
        //         if(response?.data?.success == true)
        //         {
        //             console.log("item id = ",item.id)
        //             setSettingPrice(response?.data?.amount)
        //         }  
        //     }).catch(error => {
        //     }).finally(() => {
                
        //     })
         
        //  setSideDiaData(jewelryDetail?.sideStone); 
        // setProdcutMetalPrice(jewelryDetail?.sync_metal_price);                
        // setProdcutSideDiaPrice(jewelryDetail?.sync_side_dia_price); 
        // setProdcutMetalWeight(jewelryDetail?.metalweight);
    }
    
    useEffect(() => {
        setPriceJewelry()
    },[])

    const setPriceJewelry = async ()=> {
        updatePrice(item,item?.labour_charge?.item,item?.labour_charge?.amount,item?.labour_charge?.minimum_amount,null);
        // if(is_b2b?.is_b2b == 1){
        //     let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        //     let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;

        //     await axios.get(BASE_URL + GetAPIUrl.JEWELRYSHORTDETAIL_URL + `/${item.id}` + '?is_b2b=' + getdata+'&currency_id='+currency_id, {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json',
        //         }
        //     })
        //         .then(response => {
        //             // console.log("response",response?.data)
        //             updatePrice(response?.data?.data,response?.data?.labourCharge,response?.data?.otherCharge)
        //         }).catch(error => {
                    
        //         }).finally(() => {
    
        //         })
        //     setSettingPrice(item.setting_price)
        // }else{
        //     // setSettingPrice(item.settingprice)
        //     setSettingPrice(item.b2c_price)
        // }
    }
    function decodeEntities(s){
        var str, temp= document.createElement('p');
        temp.innerHTML= s;
        str= temp.textContent || temp.innerText;
        temp=null;
        return str;
    }
    let urlSlug = decodeEntities(item?.slug);

    return (
        <>
        {
            // console.log("Wishlist?.includes(item?.id)",Wishlist?.includes(item?.id));
            // return (
                <div key={Math.random()} className="product-div find-img">
                    <div className="product-div-div"
                    >
                        <div className="product-div-box" >
                            {/* {Wishlist != '' &&
                                Wishlist?.includes(item?.id) == true ?
                                <span className="heart-span hand" onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : confirmRemovejewelry(item)}>
                                    <svg width="24" height="21" viewBox="0 0 24 21" fill="#ca9e79" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z"
                                            stroke="#ca9e79"
                                            strokeOpacity="0.65"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round">
                                        </path>
                                    </svg>
                                </span>
                                :
                                <span className="heart-span hand" onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : CallAddtoWishlistApi(item)}>
                                    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z"
                                            stroke="#ca9e79"
                                            strokeOpacity="0.65"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                        </path>
                                    </svg>
                                </span>
                            } */}
                            <a
                                className=""
                                target='_blank'
                                aria-current="page"
                                style={{ textDecoration: "none" }}
                                // href={buildYourOwn == true ?
                                //     DiamondId == null ? process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.id}/${item?.slug}?jid=${item?.id}` : process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.id}/${item?.slug}?jid=${item?.id}&did=${DiamondId}`
                                //     : slug =="onlysetting" ? process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.slug}?jid=${item?.id}&onlysetting=1`
                                //     : process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.slug}?jid=${item?.id}`}
                                href={buildYourOwn == true ?
                                        DiamondId == null ? process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.id}/${urlSlug}?jid=${item?.id}` : process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.id}/${urlSlug}?jid=${item?.id}&did=${DiamondId}`
                                        : slug =="onlysetting" ? process.env.PUBLIC_URL + `/JDetails/${item?.id}/${urlSlug}?jid=${item?.id}&onlysetting=1`
                                        : process.env.PUBLIC_URL + `/JDetails/${item?.id}/${urlSlug}?jid=${item?.id}`}

                            >
                                <div className="product-div-list" style={{ pointerEvents: 'all' }}>
                                    <img
                                        id={item?.id}
                                        onMouseOver={() => onMouseovr(item?.id)}
                                        onMouseOut={() => onMouseout(item?.id)}
                                        src={item?.id == imgHover ? getpath[1] == undefined ? item?.default?.path : getpath[1]?.path : item?.default?.path}
                                        style={{ height: '100%', width: '100%' }}
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = noimage;
                                        }}
                                        className="inner-img-product change-image"
                                    />

                                </div>

                                <div className="text-center show-viewbtn">
                                    <h5 className="product-title pt-3 line1-doted-3">
                                    <div dangerouslySetInnerHTML={{__html:  item?.title}} ></div>
                                        {/* {item?.title} */}
                                    </h5>
                                    <p className="product-title-price mb-0">
                                        {/* {
                                            (is_b2b?.is_b2b == 1) ?
                                                <>
                                                    {defaultCurrency?.sign ?? "$"} {settingPrice}
                                                </>
                                            :
                                            <>
                                            {defaultCurrency?.sign ?? "$"} {item?.settingprice}
                                            </>
                                        } */}
                                    {defaultCurrency?.sign ?? "$"}{settingPrice}
                                    {/* {defaultCurrency?.sign ?? "$"}{item?.settingprice} */}
                                        {/* $ {item?.offerprice == null ? item?.price : item?.offerprice} */}
                                    </p>
                                    <div className="pt-2 hide-view-btn">
                                        <div className='view-details-btn'>
                                            <span className="span-link">
                                                View Details
                                            </span>
                                            <span>&nbsp;
                                                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10L6 5.5L1 1" stroke="#23282D" strokeWidth="1.5" strokeLinecap="round" /></svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </a>
                        </div>

                    </div>
                </div>
            // )
        }
        </>
    );
}

