import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BASE_URL, GetAPIUrl } from '../API/APIUrl';
import noimage from "../assets/images/product-details/noimage.jpg";
import LoadingSpinner from '../module/LoadingSpinner';
import { confirmPayment, confirmRemove, ShowErrorMessage, ShowMessage } from '../module/Tostify';
import logo from "../assets/images/logo-img.svg"
import { CallCartCountApi } from '../reducers/userReducers';
import { Colors } from '../constant/Constant';
import { utf8_to_b64 } from '../helpers/Utility';

const $ = window.$;

const Wishlist = () => {
    const token = localStorage.getItem('token')
    const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))

    const [Wishlist, setWishlist] = useState([]);
    const [IsWishlistLoading, setIsWishlistLoading] = useState(false)
    const [isDisable, setIsDisable] = useState(false)
    const [SelectedringSize, setSelectedRingSize] = useState('')
    const [message, setmessage] = useState(false)
    const [WishlistId, setWishlistId] = useState(-1);
    const is_b2b = JSON.parse(localStorage.getItem('data'))


    useEffect(() => {
        CallFetchListApi()
    }, [])

    const CallFetchListApi = async () => {
        setIsWishlistLoading(true)
        // await axios.get(BASE_URL + GetAPIUrl.WISHLIST_URL, {
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //         'Authorization': 'Bearer ' + token
        //     }
        // })
        let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;
        await axios({
            method: "GET",
            url: BASE_URL + GetAPIUrl.WISHLIST_URL+'?currency_id='+currency_id,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response?.data?.success == true) {
                    CallCartCountApi(token)
                    setWishlistId(-1)
                    setWishlist(response?.data?.wishlists)

                } else {
                    ShowErrorMessage(response?.data?.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error?.message)


            }).finally(() => {
                setIsWishlistLoading(false)
            })
    }

    const confirmRemovejewelry = (item) => {

        confirmRemove("Are you Sure","You want to remove item from wishlist?").then((result) => {
            if (result == true) {
                CallDeletejewelry(item)
            }
        }

        )
    }
    const CallDeletejewelry = async (item) => {
        setIsWishlistLoading(true)
        const controller = new AbortController();
        var form = new FormData();

        form.append("id", item?.id);
        form.append("jewelryid", item?.WishlistJewelryData?.id);
        form.append("diamondid", "");

        // console.log("fff", [...form]);
        await axios.post(BASE_URL + GetAPIUrl.DELETEWISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {

                    ShowMessage(response.data.message)

                    CallFetchListApi()
                } else {

                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)

            }).finally(() => {
                setIsWishlistLoading(false)
            });
        controller.abort()
    }
    const confirmRemoveDiamond = (item) => {

        confirmRemove("Are you Sure","You want to remove diamond from wishlist?").then((result) => {
            if (result == true) {
                CallDeleteDiamond(item)
            }
        }

        )
    }
    const CallDeleteDiamond = async (item) => {
        setIsWishlistLoading(true)
        const controller = new AbortController();
        var form = new FormData();

        form.append("id", item?.id);
        form.append("jewelryid", "");
        form.append("diamondid", item?.WishlistDiamondData?.id);

        // console.log("fff", [...form]);
        await axios.post(BASE_URL + GetAPIUrl.DELETEWISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {

                    // ShowMessage(response.data.message)
                    CallCartCountApi(token)
                    CallFetchListApi()
                } else {

                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)

            }).finally(() => {
                setIsWishlistLoading(false)
            });
        controller.abort()
    }

    const updateRingData = async (id, ringsize) => {
        // setSelectedRingSize(ringsize)

        const controller = new AbortController();
        var form = new FormData();

        form.append("id", id == null ? "" : id);
        form.append("ringsize", ringsize)

        await axios.post(BASE_URL + GetAPIUrl.UPDATEWISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    CallFetchListApi()
                    // window.location.pathname = 'Wishlist'
                } else {
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)
            })
        controller.abort()
    }

    const validation = (item, index) => {
        if (item.wishlistRingData == null) {
            setWishlistId(index)
        } else {
            setWishlistId('')
            CallAddtoCartApi(item)
        }

        // if (SelectedringSize == '') {
        //     setmessage(true)

        // } else {
        //     setmessage(false)
        // }
        // if (item.WishlistJewelryData.id != '') {
        //     CallAddtoCartApi(item)
        // }
    }
    const CallAddtoCartApi = async (item) => {
        $('.BtnSave').attr('disabled','true');
        setIsWishlistLoading(true)
        setIsDisable(true)
        const controller = new AbortController();
        // setIsWishlistLoading(true)
        var form = new FormData();

        form.append("id", item?.id == null ? "" : item?.id);

        await axios.post(BASE_URL + GetAPIUrl.MOVETOCARTLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {

                    // ShowMessage(response.data.message)
                    CallFetchListApi()
                    CallCartCountApi(token)
                    // window.location.pathname = 'Wishlist'
                } else {
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                ShowErrorMessage(error.message)
                $('.BtnSave').removeAttr('disabled');
            }).finally(() => {
                setIsWishlistLoading(false)
                setIsDisable(false)
            })
        controller.abort()
        
    }

    return (

        <>
            <div>
                <div className="container container-main">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link
                                    className="desk-top-title-sm a-taglink"
                                    aria-current="page"
                                    to={'/'}
                                >
                                    <i className="fa fa-home" aria-hidden="true"></i>
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Wishlist</li>
                        </ol>
                    </nav>
                </div>
            </div>
           
            <section className="pb-3">
                <div className="container">
                    <div className="text-center max-80per">
                        <h5 className="title-1 mb-2">
                            Wishlist
                        </h5>
                        <p style={{ fontSize: "13px", color: "#7a7a7a" }}>
                            Choose a diamond to complement the jewellery you wish to create. Our extensive selection of gems feature ten different shapes as well as a range of carat sizes to suit every preference. Each diamond featured in our catalogue is responsibly sourced and comes with certification from a leading grading organisation.
                        </p>
                    </div>
                </div>
            </section>

            <section className="jumbotron pt-2">
                <div className="container container-main">
                    <div>
                        <div className="row">
                            <div className="col-md-12 col-xl-12">
                                <div className="table-responsive mar-bot-25px">
                                    {IsWishlistLoading == true ?
                                        <LoadingSpinner />
                                        :
                                        <table id="dia-table" className="table table-striped1" style={{ width: "100%" }}>
                                            <thead className="tbl-header-acc">
                                                <tr className="">
                                                    <th className="text-center" style={{ width: "15%" }}>
                                                        Image
                                                    </th>
                                                    <th className="min-200th" style={{ width: "41%" }}>
                                                        Product Details
                                                    </th>
                                                    <th className="text-center" style={{ minWidth: "110px" }}>
                                                        Price
                                                    </th>
                                                    <th className="text-center" style={{ minWidth: "110px" }}>
                                                        Status
                                                    </th>
                                                    <th className="text-center" style={{ minWidth: "110px" }}>
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>

                                            {Wishlist?.length > 0 ?

                                                Wishlist?.map((item, index) => {
                                                    return (
                                                        <tbody className="tr-unhov-hand table-ver-midl" key={Math.random()}>
                                                            {item?.WishlistJewelryData != null &&
                                                                <tr className="trtd-13">
                                                                    <td className="text-center" onContextMenu={(e)=>e.preventDefault()} >
                                                                        <img src={item?.WishlistJewelryData?.default?.path == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg" : item?.WishlistJewelryData?.default?.path} className="wish-img" 
                                                                        onError={(e)=>{
                                                                            e.target.onerror=null
                                                                            e.target.src=noimage
                                                                        }}/>
                                                                    </td>
                                                                    <td>
                                                                        {/* <a target='_blank' href={process.env.PUBLIC_URL + `/JDetails/${utf8_to_b64(item?.WishlistJewelryData?.id)}/${item?.WishlistJewelryData?.slug}?jid=${item?.WishlistJewelryData?.id}`} */}
                                                                        <a target='_blank' className="desk-top-title-sm a-taglink" href={process.env.PUBLIC_URL + `/JDetails/${item?.WishlistJewelryData?.id}/${item?.WishlistJewelryData?.slug}?jid=${item?.WishlistJewelryData?.id}`}
                                                                        >
                                                                            <h6 className="heading">
                                                                                {item?.WishlistJewelryData?.title}
                                                                            </h6>
                                                                        </a>
                                                                        <div className="descList m-w-220">
                                                                            <div className="wishlist_plist">
                                                                                <span className="sourcesansreg wishlist_prd_left">Metal</span>
                                                                                <span style={{ padding: "0 8px 0 8px" }}>:</span>
                                                                                <span className="wishlist_prd_right">{item?.WishlistJewelryData?.metal_stamp?.paraname} {item?.WishlistJewelryData?.metal_type?.paraname}</span>
                                                                            </div>
                                                                            {item?.WishlistJewelryData?.gem_color != null &&
                                                                                <div className="wishlist_plist">
                                                                                    <span className="sourcesansreg wishlist_prd_left">Gem Color</span>
                                                                                    <span style={{ padding: "0 8px 0 8px" }}>:</span>
                                                                                    <span className="wishlist_prd_right">{item?.WishlistJewelryData?.gem_color?.paraname}</span>
                                                                                </div>
                                                                            }
                                                                            <div className="wishlist_plist">
                                                                                <span className="sourcesansreg wishlist_prd_left">SKU</span>
                                                                                <span style={{ padding: "0 8px 0 8px" }}>:</span>
                                                                                <span className="wishlist_prd_right">{item?.WishlistJewelryData?.itemcode}</span>
                                                                            </div>
                                                                            {
                                                    (item?.WishlistJewelryData?.category?.name.toLowerCase() == "ring" 
                                                    || item?.WishlistJewelryData?.category?.name.toLowerCase() == "rings"
                                                    || item?.WishlistJewelryData?.category?.name.toLowerCase() == "engagement rings"
                                                    || item?.WishlistJewelryData?.category?.name.toLowerCase() == "engagement ring"
                                                    || item?.WishlistJewelryData?.category?.name.toLowerCase() == "wedding ring"
                                                    || item?.WishlistJewelryData?.category?.name.toLowerCase() == "wedding rings"
                                                    || item?.WishlistJewelryData?.category?.name.toLowerCase() == "chains"
                                                    || item?.WishlistJewelryData?.category?.name.toLowerCase() == "chain"
                                                    || item?.WishlistJewelryData?.category?.name.toLowerCase() == "bracelet"
                                                    || item?.WishlistJewelryData?.category?.name.toLowerCase() == "bracelets"
                                                    )
                                                    ?
                                                                            <div className="wishlist_plist">
                                                                                <span className="sourcesansreg wishlist_prd_left">{
                                                                (item?.WishlistJewelryData?.category?.name.toLowerCase() == "ring" 
                                                       || item?.WishlistJewelryData?.category?.name.toLowerCase() == "rings"
                                                       || item?.WishlistJewelryData?.category?.name.toLowerCase() == "engagement rings"
                                                       || item?.WishlistJewelryData?.category?.name.toLowerCase() == "engagement ring"
                                                       || item?.WishlistJewelryData?.category?.name.toLowerCase() == "wedding ring"
                                                       || item?.WishlistJewelryData?.category?.name.toLowerCase() == "wedding rings") && "Ring Size" 
                                                       }
                                                       {
                                                       (item?.WishlistJewelryData?.category?.name.toLowerCase() == "bracelets" || item?.WishlistJewelryData?.category?.name.toLowerCase() == "bracelet") && "Bracelet Length"
                                                        }{
                                                       (item?.WishlistJewelryData?.category?.name.toLowerCase() == "chains" || item?.WishlistJewelryData?.category?.name.toLowerCase() == "chain") && "Chain Length"
                                                    }</span>
                                                                                <span style={{ padding: "0 8px 0 8px" }}>:</span>
                                                                                {/* <span className="wishlist_prd_right">{item?.WishlistJewelryData?.ringsize}</span> */}
                                                                                <div className="wishlist_prd_right">
                                                                                    <select className="form-control1" onChange={(e) => updateRingData(item?.id, e.target.value)}>
                                                                                        {/* <option value="none" defaultValue={item?.WishlistJewelryData?.ringsize} >{item?.WishlistJewelryData?.ringsize}</option> */}
                                                                                        <option disabled={item?.wishlistRingData == null ? true : false} id={item?.wishlistRingData == null ? '' : item?.wishlistRingData?.id} defaultValue value={item?.wishlistRingData == null ? '' : item?.wishlistRingData?.id}>
                                                                                            {item?.wishlistRingData == null ? "Choose" : item?.wishlistRingData?.paraname}
                                                                                        </option>
                                                                                        {item?.WishlistJewelryData?.ringSize?.map((itemRing, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                {
                                                                                                    (itemRing?.id !== item?.wishlistRingData?.id) ? 
                                                                                                <option id={itemRing?.id} value={itemRing?.id} key={Math.random()}>
                                                                                                    {itemRing?.paraname}
                                                                                                </option>
                                                                                                : null
                                                                                                }
                                                                                                </>
                                                                                            )
                                                                                        })}
                                                                                    </select>
                                                                                    <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                                                        {WishlistId == index && "Please Choose Ringsize"}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            null
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {
                                                                            is_b2b?.is_b2b == 0 ? 
                                                                            <b>{defaultCurrency?.sign ?? "$"}{item?.WishlistJewelryData?.b2c_price}</b>
                                                                            :  
                                                                            <b>{defaultCurrency?.sign ?? "$"}{item?.WishlistJewelryData?.setting_price}</b> 
                                                                        }
                                                                        {/* <b>{defaultCurrency?.sign ?? "$"}{item?.WishlistJewelryData?.settingprice}</b> */}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <b className="inn-stock">{item?.WishlistJewelryData?.status}</b>
                                                                    </td>
                                                                    {item?.WishlistDiamondData != null &&
                                                                        item?.WishlistDiamondData?.status_name?.paraname == "Sold" ?
                                                                        <td className="text-center"></td>
                                                                        :
                                                                        <td className="text-center">
                                                                            <button type='button' disabled={isDisable} className="edit-cart BtnSave" title="Move to Cart" onClick={() => CallAddtoCartApi(item)} style={{border:'none'}}>
                                                                                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                                                            </button>
                                                                            <button type='button' className="delete-cart" title="Delete" onClick={() => confirmRemovejewelry(item)} style={{border:'none'}}>
                                                                                <i className="fa fa-trash"></i>
                                                                            </button>
                                                                        </td>
                                                                    }

                                                                </tr>
                                                            }
                                                            {item?.WishlistDiamondData != null && item?.WishlistJewelryData != null ?
                                                                <tr className="trtd-13">
                                                                    <td colspan="6">
                                                                        <hr className="hr-border" style={{padding:"0",margin:"0"}} />
                                                                    </td>
                                                                </tr>
                                                                :
                                                                <tr className="trtd-13 bor-trlast" ></tr>
                                                            }


                                                            {item?.WishlistDiamondData != null &&
                                                                <tr className="trtd-13 bor-trlast">
                                                                    <td className="text-center">
                                                                        <img src={item?.WishlistDiamondData?.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : item?.WishlistDiamondData?.image} className="wish-img" 
                                                                        onError={(e)=>{
                                                                            e.target.onerror=null
                                                                            e.target.src=noimage
                                                                        }}/>
                                                                    </td>
                                                                    <td>
                                                                        <Link
                                                                            className="desk-top-title-sm a-taglink"
                                                                            aria-current="page"
                                                                            target='_blank'
                                                                            to={`/DiamondDetails/${utf8_to_b64(item?.WishlistDiamondData?.id)}/${item?.WishlistDiamondData?.title}}?did=${item?.WishlistDiamondData?.id}`}
                                                                        >
                                                                            <h6 className="heading"> {item?.WishlistDiamondData?.title} </h6>

                                                                        </Link>

                                                                        {/* <h6 className="heading">
                                                                                        {item?.WishlistDiamondData?.carat} Carat {item?.WishlistDiamondData?.shape_name?.paraname} Diamond, {item?.WishlistDiamondData?.color_name?.paraname}-{item?.WishlistDiamondData?.clarity_name?.clarityname}
                                                                                    </h6> */}
                                                                        <div className="descList m-w-220">
                                                                            <div className="wishlist_plist">
                                                                                <span className="sourcesansreg wishlist_prd_left">Carat</span>
                                                                                <span style={{ padding: "0 8px 0 8px" }}>:</span>
                                                                                <span className="wishlist_prd_right">{item?.WishlistDiamondData?.carat}</span>
                                                                            </div>
                                                                            <div className="wishlist_plist">
                                                                                <span className="sourcesansreg wishlist_prd_left">Shape</span>
                                                                                <span style={{ padding: "0 8px 0 8px" }}>:</span>
                                                                                <span className="wishlist_prd_right">{item?.WishlistDiamondData?.shape_name?.paraname}</span>
                                                                            </div>
                                                                            <div className="wishlist_plist">
                                                                                <span className="sourcesansreg wishlist_prd_left">SKU</span>
                                                                                <span style={{ padding: "0 8px 0 8px" }}>:</span>
                                                                                <span className="wishlist_prd_right">{item?.WishlistDiamondData?.loatno}</span>
                                                                            </div>

                                                                            <div className="wishlist_plist">
                                                                                <span className="sourcesansreg wishlist_prd_left">Clarity</span>
                                                                                <span style={{ padding: "0 8px 0 8px" }}>:</span>
                                                                                <span className="wishlist_prd_right">{item?.WishlistDiamondData?.clarity_name?.paraname}</span>
                                                                            </div>
                                                                            <div className="wishlist_plist">
                                                                                <span className="sourcesansreg wishlist_prd_left">Color</span>
                                                                                <span style={{ padding: "0 8px 0 8px" }}>:</span>
                                                                                <span className="wishlist_prd_right">{item?.WishlistDiamondData?.color_name?.paraname}</span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <b>{defaultCurrency?.sign ?? "$"}{item?.WishlistDiamondData?.diamondprice}</b>
                                                                    </td>
                                                                    <td className="text-center">
                                                                        <b className="inn-stock" style={{ color: item?.WishlistDiamondData?.status_name?.paraname == "Sold" ? 'red' : 'green' }}>{item?.WishlistDiamondData?.status_name?.paraname}</b>
                                                                    </td>

                                                                    <td className="text-center">
                                                                        {item?.WishlistJewelryData == null &&
                                                                            item?.WishlistDiamondData?.status_name?.paraname != "Sold" &&
                                                                            <button type='button' disabled={isDisable} className="edit-cart BtnSave" title="Move to Cart" onClick={() => CallAddtoCartApi(item)} style={{border:'none'}}>
                                                                                <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                                                            </button>
                                                                        }
                                                                        <button type='button' className="delete-cart" title="Delete" onClick={() => confirmRemoveDiamond(item)} style={{border:'none'}}>
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    )
                                                })



                                                :
                                                <tbody className="tr-unhov-hand table-ver-midl">

                                                    <tr>
                                                        <td colSpan={5}>
                                                            <div
                                                                style={{ display: "grid", flex: 1, justifyContent: "center", alignItems: 'center', backgroundColor: '#f1ecf0' }}
                                                            >
                                                                <img
                                                                    src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/diamgif.gif"}
                                                                    alt="loading..."
                                                                    style={{ width: 150, height: 150 }}
                                                                    onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}
                                                                />
                                                                <h4>No data Found</h4>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>

                                            }




                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Wishlist