import React from "react";
import SideBar from "./SideBar";
import "./terms.css";

const ReturnPolicy = () => {
  return (
    <>
      <section className="bg padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 pd">
              <div>
                {/* <p className="head_title">The Expression of Love</p> */}
                <h2 className="title">Return And Shipping Policy</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 ">
              <SideBar />
            </div>
            <div className="col-lg-9 col-md-8 pd">
              <p className="head_title">Return Policy</p>
              <p className="txt">
                Returns can be made within 30 days of the delivery date for a refund, exchange, or store credit: Our return policy allows customers to return any product that they are not satisfied with within 30 days of the delivery date. This provides ample time for customers to inspect the product and decide whether it meets their expectations. Customers have the option to request a refund, exchange the product for another item, or receive store credit.
                <br />
                <br />
                The item(s) must be in their original condition and packaging, with all tags attached: To be eligible for a return, the item(s) must be in the same condition as when they were received. They must be unworn, undamaged, and in their original packaging with all tags attached. If the item(s) are not returned in their original condition, the return may be rejected.
                <br />
                <br />
                Returns must be accompanied by the original invoice or proof of purchase: Customers must include the original invoice or proof of purchase with their return. This helps us verify the purchase and process the return quickly and accurately.
                <br />
                <br />
                Shipping fees are non-refundable: Shipping fees are non-refundable and customers are responsible for the cost of shipping the item(s) back to us.
                <br />
                <br />
                Custom-made or engraved items are non-returnable: Custom-made or engraved items are non-returnable, as they are made specifically for the customer and cannot be resold.
              </p>
              <br />
              <p className="head_title">Shipping Policy</p>
              <p className="txt">
                We offer free shipping on all orders over [insert amount] to addresses within [insert country/region]: We offer free shipping on all orders over a certain amount to customers within a certain geographic region. This incentivizes customers to purchase more and helps us cover the cost of shipping.
                <br />
                <br />
                Orders are processed and shipped within 7 business days: We process and ship orders within a certain number of business days, which may vary depending on the product and the time of year. This information is provided to customers at the time of purchase, so they can anticipate when their order will be shipped.
                <br />
                <br />
                Shipping times may vary depending on the shipping method selected and the delivery location: Shipping times vary depending on the shipping method selected and the destination of the order. We provide estimated shipping times at the time of purchase, and customers can track their order to see when it will arrive.
                <br />
                <br />
                Orders are shipped via: We use a reputable shipping carrier to ensure that orders are delivered safely and on time. Customers can track their order using the tracking number provided.
                <br />
                <br />
                We provide a tracking number for all shipments: We provide customers with a tracking number for their order, so they can track its progress and anticipate when it will arrive.
                <br />
                <br />
                We are not responsible for lost or stolen packages or delays caused by the shipping carrier: While we do our best to ensure that orders are delivered on time and in good condition, we are not responsible for lost or stolen packages or delays caused by the shipping carrier.
                <br />
                <br />
                International customers are responsible for any customs duties, taxes, or fees associated with their order: International customers may be subject to customs duties, taxes, or fees associated with their order, which are not included in the purchase price. Customers are responsible for paying these fees to receive their order.
                <br />
                <br />
                In summary, our return and shipping policies are designed to provide customers with a positive shopping experience and ensure that they are satisfied with their purchase. We strive to be transparent and clear about our policies, so customers can make informed decisions and know what to expect when shopping with us.
              </p>
              {/* <p className="head_title">Buyback guarantee</p>
              <p className="txt">
                We provide a buyback guarantee to our customers for a maximum
                period of 15 days from the order date.
              </p>
              <p className="head_title">Non Refundable items</p>
              <ul className="pd-20">
                <li className="txt">
                  Return of Customized and engraved products are not allowed.
                </li>
                <li className="txt">
                  Special orders are not eligible for return or exchange. They
                  are final sale that cannot be returned.
                </li>
                <li className="txt">
                  Shipping fees are not eligible for a refund.
                </li>
                <li className="txt">
                  Returns without the return id will be refused immediately on
                  the receipt. If there is any missing paperwork or product, the
                  request won't be processed. It is advised that you
                  double-check everything before you return the items to us.
                </li>
                <li className="txt">
                  Please make sure to pick the correct jewelry size at the time
                  of purchase because they are non-refundable once customized or
                  altered.
                </li>
              </ul>
              <p className="head_title">Damaged items</p>
              <p className="txt">We make every possible effort to deliver all your orders in perfect condition. If you receive the order in a damaged condition, you can call us at <a href="tel:+1 (202) 985-2182">+1 (202) 985-2182</a>. You need to share the unboxing video with us to confirm the same. Keep all the packaging unless instructed by Meraya. If you want to claim for damaged or missing items, it must be reported immediately or within 7 days from the date you received your order. Please note, Meraya is not responsible for lost or damaged returned orders.</p>
              <p className="head_title">Exchange an order</p>
              <p className="txt">You are eligible to exchange the order within 15 days of the date when the order was shipped to you. First, you need to return the item for a refund. We will check whether the returned item is in the original and unused condition or not. After we confirm positively, your Meraya account will be credited. It should take around 7 days for the refund to be processed and the credit to reflect in your account. For any queries, <a href="!#"><b>get in touch</b></a></p>
              <p className="head_title">Return items in unused condition</p>
              <p className="txt">If you are returning the order, you need to make sure the jewelry is in the same condition as it was sent to you. That is it should be in the original packaging and tags. There should be no damage done to the order, otherwise, the return request would be canceled.</p>
              <h2 className="title">Meraya’s return process</h2>
              <p className="head_title">Get your return request number</p>
              <p className="txt">Call <a href="tel:+1 (202) 985-2182">+1 (202) 985-2182</a> to get in touch with a member of our customer care team. After the call, you will receive an email on your registered email id with all the instructions you need to follow while returning the order. Also, you will receive your unique return request number.</p>
              <p className="head_title">Get you shipping and insurance sorted</p>
              <p className="txt">You can take assistance regarding courier services from our service team. Alternatively, you can arrange the return. Make sure that your local carrier will insure your jewelry to the value that was paid.</p>
              <p className="head_title">Pack your items securely and with care</p>
              <p className="privacy-txt">Return the order in the same box and packaging you received it. Write the unique return request number on the shipping box to be returned. If your return is for refund or exchange and it is certified lab grown diamonds, please include received diamond certificate or all grading reports. Diamond grading reports and certificates are not required for repairs and resizing. If your return is without the diamond certificate, you will be charged for the replacement cost. Mention the address of Meraya’s office on the box.</p>
              <p className="head_title">Quality check on the receipt of the returned order</p>
              <p className="txt">We make every effort to quickly process your returns. Once we receive your shipment, you will get an email regarding the same. We will update the information on our return portal within 24 hours of the receipt. The order is sent to our quality assurance team. A thorough quality check will be conducted before processing your request. It takes 5 to 15 days to complete the process. Once your return request is approved, you will get an email for the confirmation and the credit will reflect in your bank account within 7 business working days.</p>
              <p className="head_title">Refund period</p>
              <p className="txt">The refund will be credited in your account within 7  business working days after the return request has been accepted.</p>
              <p className="head_title">Returns tracking</p>
              <p className="txt">Check the status of your returned order through the emails we will send on your registered email id.</p>
              <p className="head_title">Cancel or change order</p>
              <p className="privacy-txt">If you decide to cancel or modify the order, get in touch at (<a href="tel:+1 (202) 985-2182">+1 (202) 985-2182</a>).  We will make every effort to make the best possible action provided the order hasn’t been processed through our system. But, we cannot accommodate exchanges or cancellation on special order requests and the orders that have already been shipped. The rerouting of packages to alternate addresses is not possible once the order is in transit. You can contact us if you want the package to be held for collection. If you want to cancel the order that has already been shipped, please get in touch to set up a merchandised return once you receive the order.</p> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReturnPolicy;
