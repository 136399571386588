import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BASE_URL, GetAPIUrl } from "../API/APIUrl";
import LoadingSpinner from "../module/LoadingSpinner";
import { ShowErrorMessage, ShowMessage } from "../module/Tostify";
const AccountActivation = () => {

    let { id } = useParams();
    // console.log("id", id);
    const [isLoading, setIsLoading] = useState(true);
    const [Message, setMessage] = useState('');
    const [img, setimg] = useState('');

    useEffect(() => {
        CallActivationApi()
    }, [])
    const CallActivationApi = async () => {
        const controller = new AbortController();

        setIsLoading(true)
        await axios.get(BASE_URL + GetAPIUrl.ACCOUNTACTIVATION_URL + `/${id}`)
            .then(response => {
                if (response.data.success == true) {
                    setimg(response.data.image)
                    setMessage(response.data.message)
                    // ShowMessage(response.data.message)
                } else {
                    setMessage(response.data.message)
                    setimg(response.data.image)
                    // ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                setimg(error.response.data.image)
                setMessage(error.response.data.message)
                ShowErrorMessage(error.response.data.message)
            }).finally(() => {
                setIsLoading(false)
            })
        controller.abort()
    }
    // console.log("img", img);
    return (
        <div style={{}}>
            {isLoading ?
                <LoadingSpinner />
                :
                // <div className="mt-5 mb-5">
                //     <img height={img == '' || img == undefined ? 200 : 100} src={img == '' || img == undefined ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/diamgif.gif" : img} style={{
                //         display: "block",
                //         marginLeft: "auto",
                //         marginRight: "auto",

                //     }} />
                //     <label className="price-label mt-5 mb-5" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                //         {Message}
                //     </label>
                //     {/* <a href={process.env.PUBLIC_URL + "/Login?Loginid=NextPage"} className="start-with-btn">
                //         Go Login
                //     </a > */}
                // </div>
                <>
                <div className="main-section">

                    <div className="main-section">

                        <section className="pb-5 mob-ptpb-1">

                            <div className="container">

                                <div className="mt-5" style={{ maxWidth: "600px", margin: "auto", boxShadow: "0px 2px 10px 0px #0530691a",padding:"5px" }}>

                                    <div className="mt-5 mb-2">

                                        <div className="" style={{ padding:"30px",textAlign:"center" }}>
                                            {/* <img height={img == '' || img == undefined ? 200 : 100} src={img == '' || img == undefined ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/diamgif.gif" : img} style={{ */}
                                            <img width={100} height={img == '' || img == undefined ? 70 : 100} src={img} style={{
                                                display: "block",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                            }} />
                                            <label className="lblmy mt-3" >
                                                {Message}
                                            </label>
                                        </div>
                                        
                                    </div>

                                    <div className="mt-2 mb-5">
                                        <div className="text-center">
                                        <Link
                                            className="login-me btn-shadow-me"
                                             style={{width:"150px",display: "inline-block"}}
                                            aria-current="page"
                                            to={'/Login'}
                                        >
                                            Login
                                        </Link>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </section>
                    </div>
                </div>
                
                {/* <section className="jumbotron">
                    <div className="limiter">
                        <div className="container-login100">
                            <d iv className="wrap-login100">
                                <form className="login100-form validate-form" >

                                    <div className="" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                        <img height={img == '' || img == undefined ? 200 : 100} src={img == '' || img == undefined ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/diamgif.gif" : img} style={{
                                            display: "block",
                                            marginLeft: "auto",
                                            marginRight: "auto",

                                        }} />
                                        <label className="lblmy" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            {Message}
                                        </label>
                                    </div>

                                </form>
                                <div className="login100-more d-none d-xl-block d-lg-block" style={{ backgroundImage: `url('../assets/images/login/login-banner.jpg')` }}></div>

                            </d>
                        </div>
                    </div>
                </section> */}
                </>
            }
        </div >
    );
}

export default AccountActivation;
