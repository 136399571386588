import React, { useEffect, useRef, useState } from "react";
import { Router, useLocation,useNavigate } from 'react-router-dom';


import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Cookies from "universal-cookie";
import { BASE_URL, GetAPIUrl } from "../API/APIUrl";
// import menuclose from '../assets/images/icons/menu-close.svg';
// import menuopen from '../assets/images/icons/menu-open.svg';
import logo from '../assets/images/logo-img.svg';
import menuimg1 from '../assets/images/menu-img1.png';
// import black from '../assets/images/menu-shape/color/black.png';
// import blue from '../assets/images/menu-shape/color/blue.png';
// import green from '../assets/images/menu-shape/color/green.png';
// import olive from '../assets/images/menu-shape/color/olive.png';
// import orange from '../assets/images/menu-shape/color/orange.png';
// import pink from '../assets/images/menu-shape/color/pink.png';
// import purple from '../assets/images/menu-shape/color/purple.png';
// import smoky from '../assets/images/menu-shape/color/smoky.png';
// import white from '../assets/images/menu-shape/color/white.png';
// import yellow from '../assets/images/menu-shape/color/yellow.png';
import startdiamond from '../assets/images/start-diamond.png';
import startring from '../assets/images/start-ring.png';
import noimage from "../assets/images/product-details/noimage.jpg";
import { ButtonMailto, Buttontelto } from "../helpers/Utility";
import LoadingSpinner from "../module/LoadingSpinner";
import { SearchModal } from "../module/SearchModal";
import { ShowErrorMessage, ShowMessage } from "../module/Tostify";
import { addToken, logout } from "../reducers/userReducers";
import { Helmet, HelmetProvider } from 'react-helmet';
import Swal from "sweetalert2";
import Multiselect from "multiselect-react-dropdown";
import { AiOutlineClose } from "react-icons/ai";
import { Colors } from "../constant/Constant";
// import { fetch2 } from "../helpers/fetch";

const $ = window.$;

export default function Nav(props) {
    // const currencyData=props.currency;
    // console.log(currencyData);

    const navigate = useNavigate();
    const location = useLocation();
    const myArray = location.pathname.split("/");

    const user = useSelector((state) => state?.user?.data)
    const token = localStorage.getItem('token')
    const [Shapes, setShapes] = useState([])

    const dispatch = useDispatch()
    const currencyselectRef = useRef()

    const [Loading, setLoading] = useState(false)
    const cookies = new Cookies();
    const [Currency, SetCurrency] = useState([])
    const [defaultCurrency, SetDefaultCurrency] = useState('')
    const [metalType, setMetalType] = useState([])
    const [settingType, setSettingType] = useState([])
    const [diamondType, setDiamondType] = useState([])
    const [IsFilterLoading, setIsFilterLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    const [engagmentID, setEngagmentID] = useState('');
    const [engagmentName, setEngagmentName] = useState('');

    const [weddingID, setWeddingID] = useState('');
    const [weddingName, setWeddingName] = useState('');

    const [SilverJewelryID, setSilverJewelryID] = useState('');
    const [SilverJewelryName, setSilverJewelryName] = useState('');

    const [engagmentStyle, setEngagmentStyle] = useState(false);
    const [weddingStyle, SetWeddingStyle] = useState(false);
    const [diamondStyle, SetDiamondStyle] = useState(false);
    const [jewelryStyle, SetJewelryStyle] = useState(false);
    const [aboutStyle, SetAboutStyle] = useState(false);
    const [helmet, SetHelmet] = useState('');


    const handleEngagment = () => {
        setEngagmentStyle(!engagmentStyle)
    }
    const handleWedding = () => {
        SetWeddingStyle(!weddingStyle)
    }
    const handleDiamond = () => {
        SetDiamondStyle(!diamondStyle)
    }
    const handleJewelry = () => {
        SetJewelryStyle(!jewelryStyle)
    }
    const handleAbout = () => {
        SetAboutStyle(!aboutStyle)
    }

    const userData = useSelector(state => state.user.data)


    const Remember = cookies.get('email') == undefined ? false : true

    useEffect(() => {
        dispatch(addToken())
        if (Shapes != []) {
            CallFiltersApi()
        }
        CallCategoryListApi()
        CallSeoMetadataApi()
        if (token != null) {
            CallCheckStatusApi()
            CallCustomerGroupApi()
        }

    }, [])

    useEffect(() => {
        if (localStorage.getItem("defaultCurrency") === null || localStorage.getItem("defaultCurrency") === undefined) {
            CallCurrency(true)
        }else{
            CallCurrency(false)
            SetDefaultCurrency(JSON.parse(localStorage.getItem('defaultCurrency')))
        }
    },[])


    const authenticate = async () => {
        setLoading(true)

        // const response = await fetch2(BASE_URL + GetAPIUrl.LOGOUT_URL, new FormData(), token)
        // if (response.data?.success == true) {
        //     if (Remember == false) {
        //         cookies.remove('email', { path: "/" });
        //         cookies.remove('password', { path: "/" });
        //     }
        //     // window.location.href = process.env.PUBLIC_URL + "/Login"
        //     dispatch(logout())
        //     ShowMessage(response.data.message)
        //     setLoading(false)
        //     // window.location.pathname = '/Login'

        // } else {
        //     ShowErrorMessage(response.data.message)
        //     setLoading(false)
        // }
        // let formData = new FormData();
        // formData.append('logout','log')
        await axios.post(BASE_URL + GetAPIUrl.LOGOUT_URL, null, {
            headers: {
                // 'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data?.success == true) {
                    if (Remember == false) {
                        cookies.remove('email', { path: "/" });
                        cookies.remove('password', { path: "/" });
                    }
                    // window.location.href = process.env.PUBLIC_URL + "/Login"
                    dispatch(logout())
                    // ShowMessage(response.data.message)
                    setLoading(false)
                    // window.location.pathname = '/Login'

                } else {
                    ShowErrorMessage(response.data.message)
                    setLoading(false)
                }
            }).catch(error => {
                // ShowErrorMessage(error.message)
            }).finally(() => {
                setLoading(false)
            })
    }
    const CallFiltersApi = () => {
        axios.get(BASE_URL + GetAPIUrl.JEWELRY_FILTER_URL)
            .then(response => {
                if (response.data.success == true) {
                    setShapes(response.data.data.Shape);

                    setMetalType(response.data.data.Metal_Type)
                    setSettingType(response.data.data.Setting_Type)
                }
            }).catch(error => {
                // ShowErrorMessage(error.message)
            }).finally(() => {
                setIsFilterLoading(false)
            })
    }
    const [subcategoryList, setSubcategoryList] = useState([])

    /*var activeIds = [2, 5, 6, 8]
    let filteredArray = subcategoryList.filter(function (item) {
        return activeIds.indexOf(item.id) !== -1
    })*/
    let filteredArray = subcategoryList;

    const CallCategoryListApi = async () => {

        await axios.post(BASE_URL + GetAPIUrl.CATEGORY_URL, null, {
            headers: {
                'Accept': 'application/json',
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    setSubcategoryList(response?.data?.data);
                    response?.data?.data?.map((item) => {
                        if (item.name.toLowerCase() == 'engagement rings' || item.name.toLowerCase() == 'engagement ring') {
                            setEngagmentID(item?.id)
                            setEngagmentName(item?.name)
                            localStorage.setItem("EngagmentID", item?.id)
                            localStorage.setItem("EngagmentName", item?.name)
                        }
                        if (item.name.toLowerCase() == 'wedding rings' || item.name.toLowerCase() == 'wedding ring') {
                            setWeddingID(item?.id)
                            setWeddingName(item?.name)
                            localStorage.setItem("WeddingID", item?.id)
                            localStorage.setItem("WeddingName", item?.name)
                        }
                        if (item.name.toLowerCase() == 'silver jewelry') {
                            setSilverJewelryID(item?.id)
                            setSilverJewelryName(item?.name)
                        }
                        if(item.name.toLowerCase() == 'pendant'
                        || item.name.toLowerCase() == 'pendants'
                        ) {
                            localStorage.setItem("PendantID", item?.id)
                        }

                    })
                }
            }).catch(error => {
                // ShowErrorMessage(error.message)
            }).finally(() => {

            })
    }




    // const CallCategoryListApi = async () => {

    //     await axios.post(BASE_URL +  ( GetAPIUrl.CATEGORY_URL, {
    //         mode: 'no-cors',
    //         withCredentials: true,
    //         credentials: 'same-origin',
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json',
    //         }
    //     })
    //         .then(response => {

    //             if (response.data.success == true) {
    //                 setSubcategoryList(response?.data?.data);
    //             }
    //         });


    // }

    $(function () {
        $(window).on('load', function () {
            $(window).scroll(function () {
                var scroll = $(window).scrollTop();
                if (scroll >= 200) {
                    $("header").addClass("fix-header");
                }
                else {
                    $("header").removeClass("fix-header");
                }
            });

        });

    })
    $(function () {
        $(".dropdown-menu-sel li a").click(function () {
            var selText = $(this).text();
            $(this).parents('.cur-drop').find('.dropdown-toggle1').html(selText + ' <span className="caret"></span>');
        });


        $(".open-search").click(function () {
            $(".search-full").toggleClass("open");
        });

        $(".close-search").click(function () {
            $(".search-full").toggleClass("open");
        });

        $('.carousel').carousel({
            // interval: false,
            interval: false,
        });


        $(".box-filter-desig1").click(function () {
            $(this).toggleClass("selected1-filter");
        });

        $(".toggle-filter-div").on("click", function () {
            $(this).next(".toggle-div").toggle();
            $(this).find(".addright-icon i").toggleClass("fa-plus");
        });


        $(".mob-open-filter").on("click", function () {
            $(".filter-to-left").addClass("active");
        });

        $(".mob-close-filter").on("click", function () {
            $(".filter-to-left").removeClass("active");
        });


        $(document).ready(function () {
            setTimeout(function () {
                $(".loader-test").addClass("in");
            }, 300);
        });


        $(".show-td").click(function () {
            $(".div-toggle").toggle();
            $(".show-td .spanp").toggleClass("fa-angle-up");
            $(".show-td .spanp").toggleClass("fa-angle-down");
            $(this).toggleClass("borbotnone");
        });

        $(".show-certi").click(function () {
            $(".div-toggle-certi").toggle();
            $(".show-certi .spanp").toggleClass("fa-angle-up");
            $(this).toggleClass("borbotnone");
        });



        $(".submenu-edu").hide();
        $(".submenu").hide();

        $(".kml-navgation li").hover(
            function () {
                $(this).addClass("open");
            },
            function () {
                $(this).removeClass("open");
            }
        );


        $(".faq-div").on('click', function () {
            $(".show-div").hide();
            $(".sp").removeClass('fa-minus');
            $(this).next(".show-div").toggle();
            $(this).find(".sp").addClass('fa-plus' ? 'fa-minus' : 'fa-plus');
            // $(this).next().toggleClass('hide-div');
            // alert("hi");
        });


        if ($(window).width() < 900) {
            $(".a-rm").each(function () {
                if ($(this).hasClass("disabled1")) {
                    $(this).removeAttr("href");
                }
            });

            $(".submenu").hide();
            $('.click-menu').click(function () {
                $(this).toggleClass('menu-drop-toggle');
                $(this).next(".submenu").slideToggle();
                $(".submenu-edu").hide();
            });


            $(".submenu-edu").hide();
            $('.click-menu-edu').click(function () {
                $(this).toggleClass('menu-drop-toggle-edu');
                $(this).next(".submenu-edu").slideToggle();
                $(".submenu").hide();
            });

        };




        if ($(window).width() > 1023) {

            $(".menu-hover").click(function () {
                $(".menuifxed").toggle();
                $(".menu-hover").toggleClass("menu-close");
            });

            $(".menuifxed").mouseleave(function () {
                $(this).hide();
                $(".menu-hover").removeClass("menu-close");
                $(".menuli-click").removeClass("active");
            });

            $(".desktop-menu").mouseleave(function () {
                $(".menuifxed").hide();
            });

            $(".menuli-click").hover(function () {
                $(".menuifxed").show();
                $(".menuli-click").removeClass("active");
                $(this).addClass("active");
                var datact = $(this).data('value');
                $(".main-div-menu").hide();
                $("#" + datact).show();
            });
        }

        if ($(window).width() < 1023) {
            $(".mobile-menu-toggle").click(function () {
                $(this).next(".mob-menu-div").slideToggle();
            });
        }

        if ($(window).width() < 992) {
            $(".menuifxed").remove();
        }
        if ($(window).width() >= 992) {
            $(".mobmenu-hidein-desktop").remove();
        }
    })
    $(function () {

        // $(document).ready(function () {
        //     $(".menu-hover").click(function () {
        //         $(".menuifxed").toggle();
        //         $(".menu-hover").toggleClass("menu-close");
        //     });

        //     $(".menuifxed").mouseleave(function () {
        //         $(this).hide();
        //         $(".menu-hover").removeClass("menu-close");
        //     });

        //     $('.responsive-sld').slick({
        //         dots: true,
        //         infinite: true,
        //         speed: 1000,
        //         slidesToShow: 1,
        //         slidesToScroll: 1,
        //         autoplay: false,
        //         arrows: false,
        //         prevArrow: "<button type='button' className='slick-prev pull-left'><i className='fa fa-chevron-left home-slick-arrow' aria-hidden='true'></i></button>",
        //         nextArrow: "<button type='button' className='slick-next pull-right'><i className='fa fa-chevron-right home-slick-arrow' aria-hidden='true'></i></button>",
        //     });
        // });


        // $(document).ready(function () {
        //     $(window).scroll(function () {
        //         var scroll = $(window).scrollTop();
        //         if (scroll >= 1) {
        //             $(".header-rc").addClass("fixed");
        //             $(".dove-navgation-mobile").addClass("fixed");
        //             $(".dove-header").addClass("sticky");
        //         }
        //         else {
        //             $(".header-rc").removeClass("fixed");
        //             $(".dove-header").removeClass("sticky");
        //             $(".dove-navgation-mobile").removeClass("fixed");
        //         }
        //     });

        //     var headerHeight = $('.dove-navgation-mobile').outerHeight();
        //     $('.open-mobile-menu-list').css('top', headerHeight);

        //     $('.open-m-menu').click(function () {
        //         $(".open-mobile-menu").toggleClass('active-menu');
        //         // $(".main-div").toggleClass('open-m-menu');
        //     });

        //     $('.click-menu').click(function () {
        //         $(this).next(".mobile-submenu").slideToggle();
        //         $(this).toggleClass('bor-bot-0');
        //         $(this).toggleClass('menu-drop-toggle');
        //     });

        // });

        // $(document).scroll(function () {
        //     $(this).scrollTop() > 500 ? $(".back-to-top").fadeIn() : $(".back-to-top").fadeOut()
        // });
        $(".back-to-top").click(function () {
            return $("html, body").animate({
                scrollTop: 0
            }, 1500), !1
        });


        $(".click-toggle").click(function () {
            $(this).toggleClass('roted-90');
            $(this).next().toggle();
        });


        $(".faq-click-toggle").click(function () {
            $(this).toggleClass('roted-90');
            $(this).parent().toggleClass('active-bg-faq');
            $(this).next().toggle();
        });


        $(".clickselect-li").click(function () {
            $(".clickselect-li").removeClass("active-tab");
            $(this).addClass("active-tab");
            var datact = $(this).data('value');
            $(".main-div").hide();
            $("#" + datact).show();
        });


        $(".menuli-click").hover(function () {
            $(".menuli-click").removeClass("active");
            $(this).addClass("active");
            var datact = $(this).data('value');
            $(".main-div-menu").hide();
            $("#" + datact).show();
        });


        $(".mission-click").click(function () {
            $(".mission-click").removeClass("active-mission");
            $(this).addClass("active-mission");
            var datact = $(this).data('value');
            $(".main-div-mission").hide();
            $("#" + datact).show();
        });



        $(document).ready(function () {
            $(".add-new-address").show();
            $(".coupon_question").click(function () {
                if ($(this).is(":checked")) {
                    $(".add-new-address").show();
                } else {
                    $(".add-new-address").hide();
                }
            });

            $(".add-new-shippingaddress").hide();
            $(".checked-shiping-add").click(function () {
                if ($(this).is(":checked")) {
                    $(".add-new-shippingaddress").hide();
                } else {
                    $(".add-new-shippingaddress").show();
                }
            });
        });


        function PassowrdView(IdCls) {
            if ($('#' + IdCls).attr('type') == 'password') {
                $('#' + IdCls).attr('type', 'text');
                $('.' + IdCls).removeClass('fa-eye');
                $('.' + IdCls).addClass('fa-eye-slash');
            } else {
                $('#' + IdCls).attr('type', 'password');
                $('.' + IdCls).removeClass('fa-eye-slash');
                $('.' + IdCls).addClass('fa-eye');
            }
        }


        // $(window).on("load", function () {
        //     if ($(window).width() < 421) {
        //         $(".diamond-click-toggle").click(function () {
        //             $(this).toggleClass('roted-90');
        //             $(this).next().toggle();
        //         });

        //         $(".set-filter-click").click(function () {
        //             $(this).toggleClass('minus-ico');
        //             $(this).next().toggle();
        //         });
        //     }
        // });

        $(".images-thumb-click-img").click(function () {
            $(".thumb-videos").hide();
            $(".thumb-images").show();
        });

        $(".iframe-video-thumb").click(function () {
            $(".thumb-videos").show();
            $(".thumb-images").hide();
        });


        $(document).ready(function () {
            setTimeout(function () {
                if ($(window).width() > 901) {
                    $("#explore-collection").owlCarousel({
                        nav: false,
                        navText: ["<span>&#8592;</span>", "<span>&#10230;</span>"],
                        dots: false,
                        items: 3,
                        margin: 30,
                        // stagePadding: 210,
                        loop: false,
                        responsive: {
                            0: {
                                items: 2
                            },

                            600: {
                                items: 2
                            },

                            1024: {
                                items: 3
                            },

                            1366: {
                                items: 4
                            }
                        }
                    });
                    $("#recommended-items").owlCarousel({
                        nav: true,
                        navText: ["<span>&#10229;</span>", "<span>&#10230;</span>"],
                        dots: false,
                        items: 4,
                        margin: 30,
                        loop: true,
                        responsive: {
                            0: {
                                items: 1
                            },

                            600: {
                                items: 2
                            },

                            1024: {
                                items: 3
                            },

                            1366: {
                                items: 4
                            }
                        }
                    });
                    $("#new-arrival").owlCarousel({
                        nav: true,
                        navText: ["<span>&#10229;</span>", "<span>&#10230;</span>"],
                        dots: false,
                        items: 4,
                        margin: 30,
                        loop: true,
                        responsive: {
                            0: {
                                items: 2
                            },

                            600: {
                                items: 2
                            },

                            1024: {
                                items: 3
                            },

                            1366: {
                                items: 4
                            }
                        }
                    });
                }
                if ($(window).width() < 900) {
                    $("#explore-collection").owlCarousel({
                        nav: false,
                        navText: ["<span>&#8592;</span>", "<span>&#10230;</span>"],
                        dots: false,
                        items: 3,
                        margin: 15,
                        // stagePadding: 40,
                        loop: false,
                        responsive: {
                            0: {
                                items: 2
                            },

                            600: {
                                items: 2
                            },

                            1024: {
                                items: 3
                            },

                            1366: {
                                items: 3
                            }
                        }
                    });

                    $("#recommended-items").owlCarousel({
                        nav: true,
                        navText: ["<span>&#10229;</span>", "<span>&#10230;</span>"],
                        dots: false,
                        items: 4,
                        margin: 15,
                        loop: true,
                        responsive: {
                            0: {
                                items: 2
                            },

                            600: {
                                items: 2
                            },

                            1024: {
                                items: 3
                            },

                            1366: {
                                items: 4
                            }
                        }
                    });

                    $("#new-arrival").owlCarousel({
                        nav: true,
                        navText: ["<span>&#10229;</span>", "<span>&#10230;</span>"],
                        dots: false,
                        items: 4,
                        margin: 15,
                        loop: true,
                        responsive: {
                            0: {
                                items: 2
                            },

                            600: {
                                items: 2
                            },

                            1024: {
                                items: 3
                            },

                            1366: {
                                items: 4
                            }
                        }
                    });
                }
            }, 100);
        });
    })

    const CallCurrency = async (isSet) => {
        const controller = new AbortController();
        await axios.get(BASE_URL + GetAPIUrl.CURRENCYLIST_URL, null, )
            .then(response => {
                if (response.data.success == true) {
                    SetCurrency(response?.data?.data)
                    if(isSet == true){
                        response?.data?.data?.map((item) => {
                            if(item.is_default == 1){
                                SetDefaultCurrency(item)
                                localStorage.setItem('defaultCurrency',JSON.stringify(item))
                                console.log("defaultCurrency",defaultCurrency)
                            }
                        })
                    }
                } else {
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                // console.log("error", error);

            }).finally(() => {
            })
        controller.abort()
    }

    const handleCurrencyValues = (selectedList, selectedItem) => {
        console.log('in handle',selectedItem)
        setLoading(true)
        SetDefaultCurrency(selectedItem);
        localStorage.setItem('defaultCurrency',JSON.stringify(selectedItem))
        // navigate(0)
    };
    const handleRemoveCurrencyValues = (selectedList, selectedItem) => {
        console.log('remove in handle',selectedItem)
        SetDefaultCurrency('');
        localStorage.setItem('defaultCurrency',JSON.stringify(selectedItem))
    };


    const CallSeoMetadataApi = async () => {
        const controller = new AbortController();
        var form = new FormData();
        form.append("page", myArray[1] == '' ? 'Home' : myArray[1]);

        await axios.post(BASE_URL + GetAPIUrl.SEOMETADATA_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    SetHelmet(response?.data?.data)
                    // Callget(response?.data?.data)
                } else {
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                // console.log("error", error);

                // ShowErrorMessage(error.response.data.message)
            }).finally(() => {
            })
        controller.abort()
    }

    const CallCustomerGroupApi = async () => {
        const controller = new AbortController();

        await axios.get(BASE_URL + GetAPIUrl.CUSTOMERGROUPDISCOUNT_URL, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                console.log("response",response)
                if (response.data.success == true) {
                    localStorage.setItem('customerGroup',JSON.stringify(response?.data?.data));
                } else {

                }
            })
            .catch(error => {

            }).finally(() => {
            })
        controller.abort()
    }

    const CallCheckStatusApi = async () => {
        const controller = new AbortController();

        await axios.get(BASE_URL + GetAPIUrl.CHECKSTATUS_URL, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        footer: '<a href="">Why do I have this issue?</a>',
                        confirmButtonText: 'Ok',
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            authenticate()
                        }
                    })
                    // SetHelmet(response?.data?.data)
                    // Callget(response?.data?.data)
                } else {
                    // localStorage.clear();
                    // window.location.replace="/";
                    // ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                localStorage.clear();
                window.location.href = "/";
            }).finally(() => {
            })
        controller.abort()
    }

    return (
        <>
            <Helmet >
                <title>{helmet?.title}</title>
                <meta name="Keywords" content={helmet?.keywords}></meta>
                <meta name="Description" content={helmet?.description}></meta>
                <meta name="Robots" content={helmet?.robots}></meta>
            </Helmet>

            {Loading == true &&

                <LoadingSpinner />
            }
            <div className="header-top hide-900">
                <div className="">
                    <div className="top-header-flex">
                        <div className="header-top-ul">
                            <ul>
                                <li>
                                    <Buttontelto label={process.env.REACT_APP_CONTACT} telto={"tel:"+process.env.REACT_APP_CONTACT} />
                                </li>

                                <li>
                                    <ButtonMailto mailto={"mailto:"+process.env.REACT_APP_EMAIL} label={process.env.REACT_APP_EMAIL} />
                                </li>
                            </ul>
                        </div>
                        <div>
                            <span className="col-fff">Site is on Maintenance Mode, hence not accepting any Live Orders.</span>
                        </div>
                        <div className="header-top-ul">
                            <ul>
                                <li>
                                    <a href={process.env.PUBLIC_URL + '/Faq'} className="hand top-link" style={{}}>
                                        FAQs
                                    </a>
                                </li>
                                <li>
                                    <a href={process.env.PUBLIC_URL + '/ContactUs'} className="hand top-link" style={{}}>
                                        Support
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="#" className="hand top-link" style={{}}>
                                        Career
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* mobile-header myHeader fix-header */}
            {/* desktop-header myHeader desktop-menu */}
            <header className="desktop-menu myHeader" id="myHeader">
                <div className="container-fluid-lg pad-0lf">
                    <nav className="navbar navbar-expand-lg navbar-dark pad-0lf">
                        <div className="pad-lf-10px-mob d-lg-none" style={{ width: "100%" }}>
                            <button className="navbar-toggler menu-toggle" type="button" data-toggle="collapse" data-target="#navbarToggle" aria-controls="navbarToggle" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><i className="icon-open-menu" aria-hidden="true"></i></span>
                            </button>
                            <a className="navbar-brand d-lg-none" href={process.env.PUBLIC_URL + "/"}>
                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={logo} className="logo-max-170" width="120" height="auto" />
                            </a>
                            <div className="f-mob-right menu-currency">

                                {/* <a href="#">
                                    <label className="pad-mob-cart-menu mar-b-0">
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16 16L12.4584 12.4521M14.4211 7.71053C14.4211 9.49027 13.7141 11.1971 12.4556 12.4556C11.1971 13.7141 9.49027 14.4211 7.71053 14.4211C5.93078 14.4211 4.22394 13.7141 2.96547 12.4556C1.707 11.1971 1 9.49027 1 7.71053C1 5.93078 1.707 4.22394 2.96547 2.96547C4.22394 1.707 5.93078 1 7.71053 1C9.49027 1 11.1971 1.707 12.4556 2.96547C13.7141 4.22394 14.4211 5.93078 14.4211 7.71053Z" stroke="#4A4A4A" strokeWidth="1.5" strokeLinecap="round" />
                                        </svg>
                                    </label>
                                </a> */}
                                <label className="pad-mob-cart-menu mar-b-0" onClick={() => { setModalOpen(true) }}>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 16L12.4584 12.4521M14.4211 7.71053C14.4211 9.49027 13.7141 11.1971 12.4556 12.4556C11.1971 13.7141 9.49027 14.4211 7.71053 14.4211C5.93078 14.4211 4.22394 13.7141 2.96547 12.4556C1.707 11.1971 1 9.49027 1 7.71053C1 5.93078 1.707 4.22394 2.96547 2.96547C4.22394 1.707 5.93078 1 7.71053 1C9.49027 1 11.1971 1.707 12.4556 2.96547C13.7141 4.22394 14.4211 5.93078 14.4211 7.71053Z" stroke="#4A4A4A" strokeWidth="1.5" strokeLinecap="round" />
                                    </svg>
                                </label>
                                {token != '' && token != null && token != undefined &&
                                    <>
                                        <a href={process.env.PUBLIC_URL + "/Wishlist"}>
                                            <label className="pad-mob-cart-menu mar-b-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#4A4A4A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-heart"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                                                <span className="label label-theme sm-circle-value rounded-pill addtowishlistCountMob" style={{position:'absolute',top:'83px',right:'204px',background:'none'}} >{token != null ? localStorage.getItem('wishlistcount') == null ? 0 : JSON.parse(localStorage.getItem('wishlistcount')) : 0}</span>
                                            </label>
                                        </a>
                                        <a href={process.env.PUBLIC_URL + "/Cart"}>
                                            <label className="pad-mob-cart-menu mar-b-0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#4a4a4a" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-cart pe-2"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                                                <span className="label label-theme sm-circle-value rounded-pill addtocartCountMob" style={{position:'absolute',top:'83px',right:'161px',background:'none'}}>{token != null ? localStorage.getItem('cartcount') == null ? 0 : JSON.parse(localStorage.getItem('cartcount')) : 0}</span>
                                            </label>
                                        </a>

                                    </>
                                }


{
    location.pathname != "/Checkout" &&
                                <div className="account-list" style={{ display: 'flex',marginTop: "0.5rem" }}>
                                    <Multiselect singleSelect
                                            customCloseIcon={<AiOutlineClose size={15} style={{ paddingLeft: 2, color: 'red', }} />}
                                            style={{
                                                chips: { background: 'white', color: Colors.brown, fontSize: 14 },
                                                searchBox: { "borderRadius": "0px" },

                                            }}
                                            disablePreSelectedValues={true}
                                            ref={currencyselectRef}
                                            selectedValues={[defaultCurrency]}
                                            showArrow={true}
                                            onSelect={handleCurrencyValues}
                                            onRemove={handleRemoveCurrencyValues}
                                            options={Currency}
                                            displayValue="sign_caption" />
                                </div>
}

                                <div className="account-list" style={{ display: 'flex' }}>
                                    <a className="hand searchbtn btn btn-solid-default btn-spacing t-hov">
                                        <svg width="18" height="18" viewBox="0  0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5292 9.79296C11.2282 9.11936 10.7914 8.50749 10.2431 7.99146C9.69641 7.47393 9.04886 7.06131 8.33616 6.77638C8.32977 6.77337 8.32339 6.77186 8.31701 6.76884C9.31115 6.09045 9.95742 4.98543 9.95742 3.73869C9.95742 1.67337 8.18616 0 6 0C3.81384 0 2.04258 1.67337 2.04258 3.73869C2.04258 4.98543 2.68885 6.09045 3.68299 6.77035C3.67661 6.77337 3.67023 6.77487 3.66385 6.77789C2.94896 7.06281 2.30747 7.47136 1.75694 7.99297C1.20914 8.5094 0.772382 9.12116 0.470781 9.79447C0.174489 10.4536 0.0146918 11.1604 3.99029e-05 11.8764C-0.000386007 11.8925 0.00260125 11.9085 0.00882574 11.9235C0.0150502 11.9384 0.024386 11.9521 0.0362829 11.9636C0.0481797 11.9751 0.0623971 11.9843 0.0780971 11.9905C0.0937971 11.9968 0.110662 12 0.127699 12H1.08514C1.15535 12 1.2112 11.9472 1.2128 11.8824C1.24471 10.7186 1.73939 9.62864 2.61385 8.80251C3.51863 7.94774 4.72022 7.47739 6 7.47739C7.27978 7.47739 8.48137 7.94774 9.38615 8.80251C10.2606 9.62864 10.7553 10.7186 10.7872 11.8824C10.7888 11.9487 10.8446 12 10.9149 12H11.8723C11.8893 12 11.9062 11.9968 11.9219 11.9905C11.9376 11.9843 11.9518 11.9751 11.9637 11.9636C11.9756 11.9521 11.9849 11.9384 11.9912 11.9235C11.9974 11.9085 12.0004 11.8925 12 11.8764C11.984 11.1558 11.826 10.4548 11.5292 9.79296V9.79296ZM6 6.33166C5.26756 6.33166 4.5782 6.06181 4.05959 5.57186C3.54097 5.08191 3.25534 4.43065 3.25534 3.73869C3.25534 3.04673 3.54097 2.39548 4.05959 1.90553C4.5782 1.41558 5.26756 1.14573 6 1.14573C6.73244 1.14573 7.4218 1.41558 7.94041 1.90553C8.45903 2.39548 8.74466 3.04673 8.74466 3.73869C8.74466 4.43065 8.45903 5.08191 7.94041 5.57186C7.4218 6.06181 6.73244 6.33166 6 6.33166Z" fill="#4A4A4A"></path>
                                        </svg>

                                    </a>

                                    <div className="dropdown-menu dropdown-menu-login" id="user_login">
                                        <div className="dropdown-menu-inner" id="login-dropdown-context" style={{ background: '#fff' }}>
                                            <div id="user_authenticated">
                                                {token != null || token != undefined ?
                                                    <ul className="list-unstyled lh-28 mb15 user-menu-afterlogin">
                                                        {/* <li>
                                                            <a
                                                                className="nav-link t"
                                                                style={{ textDecoration: "none" }}
                                                                aria-current="page"
                                                                href={process.env.PUBLIC_URL + "/Gallery"}
                                                            >Gallery
                                                            </a>
                                                        </li> */}
                                                        <li>
                                                            <a
                                                                className="nav-link t"
                                                                style={{ textDecoration: "none" }}
                                                                aria-current="page"
                                                                href={process.env.PUBLIC_URL + "/Myaccount"}
                                                            >My Account
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="nav-link t" style={{cursor:"pointer"}} onClick={()=>authenticate()}>
                                                                Log Out
                                                            </a>
                                                            {/* <Link
                                                                className="nav-link t"
                                                                style={{ textDecoration: "none" }}
                                                                aria-current="page"
                                                                to={"/"}
                                                            >Log Out
                                                            </Link> */}
                                                            {/* <a href="/SignupB2B">
                                                            Sign up B2B
                                                        </a> */}
                                                        </li>
                                                    </ul>
                                                    :
                                                    <ul className="list-unstyled lh-28 mb15 user-menu-afterlogin">
                                                        <li>
                                                            <a
                                                                className="nav-link t"
                                                                style={{ textDecoration: "none" }}
                                                                aria-current="page"
                                                                href={process.env.PUBLIC_URL + "/Login"}
                                                            >Login
                                                            </a>
                                                        </li>
                                                        {/* <li>
                                                            <a
                                                                className="nav-link t"
                                                                style={{ textDecoration: "none" }}
                                                                aria-current="page"
                                                                href={process.env.PUBLIC_URL + "/Gallery"}
                                                            >Gallery
                                                            </a>
                                                        </li> */}
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse justify-content-between mobilemenubg mobile-menu" id="navbarToggle" >

                            {/* <Link
                                className="navbar-brand d-none d-lg-block"
                                aria-current="page"
                                style={{ textDecoration: "none" }}
                                to='/'
                            > */}
                            <a href={process.env.PUBLIC_URL + '/'}>
                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={logo} className="logo-max-170 lg-logo" width="200" height="auto" />
                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={logo} className="sm-logo" width="120" height="auto" />
                            </a>

                            {/* </Link> */}
                            <ul className="navbar-nav parent-menu">
                                <li className="nav-item dropdown mobile-dropdown" onClick={handleEngagment}>
                                    <a className="nav-link hand t-link-primary u-relative menu-drop menuli-click mobile-menu-toggle" data-value="mega-menu1">
                                        Engagement Ring 
                                        <span className="caret-mobile"></span>
                                    </a>
                                    <div className={engagmentStyle === true ? "show mobmenu-hidein-desktop dropdown-menu" : "mobmenu-hidein-desktop dropdown-menu"} >
                                        <div className="container container-menu">
                                            <div className="row desktop-megamenu-list">
                                                <div className="col-lg-3 mb-3">
                                                    <h6 className="desk-top-title"> DESIGN YOUR OWN ENGAGEMENT RING </h6>
                                                    <ul className="menu-ul-list mb-3">
                                                        {/* <li className="li-list" >
                                                            <a
                                                                className="desk-top-title-sm a-taglink"
                                                                aria-current="page"
                                                                style={{ textDecoration: "none" }}
                                                                href={process.env.PUBLIC_URL + '/DiamondList'}
                                                            >
                                                                <img src={startring} />&nbsp; Start With a Settings
                                                            </a>
                                                        </li> */}

                                                        <li className="li-list"
                                                        >
                                                            {
                                                                // console.log(engagmentName,engagmentID)
                                                            }
                                                            <a className="desk-top-title-sm a-taglink"
                                                                aria-current="page"
                                                                style={{ textDecoration: "none" }}
                                                                // href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${engagmentName}?cid=${engagmentID}`} 
                                                                href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/Engagement Ring?cid=27`} 
                                                            >
                                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startring} />&nbsp; Start with a Setting(Rings)
                                                            </a>
                                                        </li>
                                                        <li className="li-list"
                                                        >
                                                            {
                                                                // console.log(engagmentName,engagmentID)
                                                            }
                                                            <a className="desk-top-title-sm a-taglink"
                                                                aria-current="page"
                                                                style={{ textDecoration: "none" }}
                                                                href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/Pendant?cid=1`} >
                                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startring} />&nbsp; Start with a Setting(Pendant)
                                                            </a>
                                                        </li>

                                                        <li className="li-list">
                                                            <a
                                                                className="desk-top-title-sm a-taglink"
                                                                aria-current="page"
                                                                style={{ textDecoration: "none" }}
                                                                href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Natural`}

                                                            >
                                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startdiamond} alt="start-diamond" />&nbsp; Start with a Natural Diamond</a>
                                                        </li>
                                                        <li className="li-list">
                                                            <a
                                                                className="desk-top-title-sm a-taglink"
                                                                aria-current="page"
                                                                style={{ textDecoration: "none" }}
                                                                href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Lab`}

                                                            >
                                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startdiamond} alt="start-diamond" />&nbsp; Start with a Lab Diamond</a>
                                                        </li>

                                                    </ul>
                                                    <ul className="menu-ul-list mb-3">

                                                        <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + `/public/assets_backend/pdf/ring_sizer_diora adams.pdf`} target="_blank" className="desk-top-title-sm a-taglink">
                                                                <h6 className="desk-top-title">FIND YOUR RING SIZE </h6>
                                                            </a>
                                                        </li>
                                                        <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + '/JewelryCare'} className="desk-top-title-sm a-taglink">
                                                                <h6 className="desk-top-title">JEWELRY CARE </h6>
                                                            </a>
                                                        </li>



                                                    </ul>
                                                </div>
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <h6 className="desk-top-title"> SHOP BY SHAPE </h6>
                                                    </div>
                                                    <ul className="menu-ul-list menu-2-left" >
                                                        {Shapes?.map((item, index) => {
                                                            return (
                                                                <li className="li-list" key={Math.random()}>
                                                                    <a
                                                                        className="desk-top-title-sm a-taglink"
                                                                        aria-current="page"
                                                                        style={{ textDecoration: "none" }}

                                                                        href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${engagmentName}?cid=${engagmentID}&shape=${item.id}`}
                                                                    >
                                                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} className="shapeImg" src={item?.image} />&nbsp; {item.name}
                                                                    </a>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>

                                                <div className="col-lg-2" >
                                                    <h6 className="desk-top-title">SHOP BY METAL</h6>
                                                    <ul className="menu-ul-list" >

                                                        {metalType?.map((item, index) => {
                                                            return (
                                                                <li className="li-list" key={Math.random()}
                                                                >

                                                                    <a
                                                                        className="desk-top-title-sm a-taglink"
                                                                        aria-current="page"
                                                                        style={{ textDecoration: "none" }}
                                                                        href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${engagmentName}?cid=${engagmentID}&metal=${item.id}`}
                                                                    >
                                                                        { item?.name.toLowerCase() != 'silver' && item?.name }
                                                                    </a>
                                                                </li>
                                                            )
                                                        })}

                                                    </ul>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div>
                                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={menuimg1} width="100%" height="auto" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown mobile-dropdown" onClick={handleWedding} >
                                    <a className="nav-link hand t-link-primary u-relative menu-drop menuli-click mobile-menu-toggle" data-value="mega-menu2">
                                        Wedding Ring
                                        <span className="caret-mobile"></span>
                                    </a>
                                    <div className={weddingStyle === true ? "show mobmenu-hidein-desktop dropdown-menu" : "mobmenu-hidein-desktop dropdown-menu"}>
                                        <div className="container container-menu">
                                            <div className="row desktop-megamenu-list">
                                                <div className="col-lg-4 mb-3">
                                                    <div>
                                                        <h6 className="desk-top-title"> SHOP BY SHAPE </h6>
                                                    </div>
                                                    <ul className="menu-ul-list menu-2-left" >
                                                        {Shapes?.map((item, index) => {
                                                            return (
                                                                <li className="li-list" key={Math.random()}
                                                                >
                                                                    <a
                                                                        className="desk-top-title-sm a-taglink"
                                                                        aria-current="page"
                                                                        style={{ textDecoration: "none" }}

                                                                        href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${weddingName}?cid=${weddingID}&shape=${item.id}`}

                                                                    >
                                                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} className="shapeImg" src={item.image} />&nbsp; {item.name}
                                                                    </a>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>

                                                <div className="col-lg-2" >
                                                    <h6 className="desk-top-title">SHOP BY METAL </h6>
                                                    <ul className="menu-ul-list" >

                                                        {metalType?.map((item, index) => {
                                                            return (
                                                                <li className="li-list" key={Math.random()}
                                                                >

                                                                    <a
                                                                        className="desk-top-title-sm a-taglink"
                                                                        aria-current="page"
                                                                        style={{ textDecoration: "none" }}

                                                                        href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${weddingName}?cid=${weddingID}&metal=${item.id}`}
                                                                    >
                                                                        <span className="title-span"> { item?.name.toLowerCase() != 'silver' && item?.name } </span>

                                                                    </a>
                                                                </li>
                                                            )
                                                        })}

                                                    </ul>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div>
                                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={menuimg1} width="100%" height="auto" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown mobile-dropdown" onClick={handleDiamond} >
                                    <a className="nav-link hand t-link-primary u-relative menu-drop menuli-click mobile-menu-toggle" data-value="mega-menu3">
                                        Diamonds
                                        <span className="caret-mobile"></span>
                                    </a>
                                    <div className={diamondStyle === true ? "show mobmenu-hidein-desktop dropdown-menu" : "mobmenu-hidein-desktop dropdown-menu"}>
                                        <div className="container container-menu">
                                            <div className="row desktop-megamenu-list">

                                                <div className="col-lg-4 mb-3">
                                                    <h6 className="desk-top-title"> SHOP BY SHAPE </h6>
                                                    <ul className="menu-ul-list menu-2-left" >
                                                        {Shapes?.map((item, index) => {
                                                            return (
                                                                <li className="li-list" key={Math.random()}                                                                    >
                                                                    <a
                                                                        className="desk-top-title-sm a-taglink"
                                                                        aria-current="page"
                                                                        style={{ textDecoration: "none" }}

                                                                        href={process.env.PUBLIC_URL + `/DiamondList/${item.name}`}
                                                                    >
                                                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} className="shapeImg" src={item.image} />&nbsp; {item.name}
                                                                    </a>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <h6 className="desk-top-title"> CREATE YOUR OWN </h6>
                                                    <ul className="menu-ul-list mb-3">
                                                        <li className="li-list">
                                                            <a
                                                                className="desk-top-title-sm a-taglink"
                                                                aria-current="page"
                                                                style={{ textDecoration: "none" }}

                                                                href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${engagmentName}?cid=${engagmentID}`}
                                                            >

                                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startring} />&nbsp; Start With a Settings
                                                            </a>
                                                        </li>
                                                        
                                                        <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Natural`} className="desk-top-title-sm a-taglink">
                                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startdiamond} alt="start-diamond" />&nbsp; Start with a Natural Diamond </a>
                                                        </li>

                                                        <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Lab`} className="desk-top-title-sm a-taglink">
                                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startdiamond} alt="start-diamond" />&nbsp; Start with a Lab Diamond </a>
                                                        </li>
                                                        {/* <li className="li-list" >
                                                            <a href={process.env.PUBLIC_URL + '/DiamondList/Build-Your-Own'} className="desk-top-title-sm a-taglink">
                                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startdiamond} alt="start-diamond" />&nbsp; Start with a Lab Diamond </a>
                                                        </li> */}
                                                    </ul>

                                                </div>
                                                <div className="col-lg-2">
                                                    <h6 className="desk-top-title"> EDUCATION </h6>
                                                    <ul className="menu-ul-list mb-3">
                                                        <li className="li-list">
                                                            <a
                                                                className=""
                                                                aria-current="page"
                                                                style={{ textDecoration: "none" }}
                                                                href={process.env.PUBLIC_URL + '/DiamondGuide'}
                                                            >
                                                                Diamond Guide
                                                            </a>
                                                        </li>
                                                        {/* <li className="li-list">
                                                            <a
                                                                className=""
                                                                aria-current="page"
                                                                style={{ textDecoration: "none" }}

                                                                href={process.env.PUBLIC_URL + '/LabGrow'}

                                                            >
                                                                Lab Grown Diamonds
                                                            </a>
                                                        </li> */}
                                                        <li className="li-list">
                                                            <a
                                                                className=""
                                                                aria-current="page"
                                                                style={{ textDecoration: "none" }}
                                                                href={process.env.PUBLIC_URL + '/DiamondSustainabilty'}
                                                            >
                                                                Diamond Sustainabilty

                                                            </a>
                                                        </li>
                                                        <li className="li-list">
                                                            <a
                                                                className=""
                                                                aria-current="page"
                                                                style={{ textDecoration: "none" }}
                                                                href={process.env.PUBLIC_URL + '/ConflictFreeDiamonds'}
                                                            >
                                                                Conflict Free Diamonds
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div>
                                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={menuimg1} width="100%" height="auto" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown mobile-dropdown" onClick={handleJewelry}>
                                    <a className="nav-link hand t-link-primary u-relative menu-drop menuli-click mobile-menu-toggle" data-value="mega-menu4">
                                        Jewelry
                                        <span className="caret-mobile"></span>
                                    </a>
                                    <div className={jewelryStyle === true ? "show mobmenu-hidein-desktop dropdown-menu" : "mobmenu-hidein-desktop dropdown-menu"}>
                                        <div className="container container-menu">
                                            <div className="row desktop-megamenu-list">
                                            <div className="row col-lg-6">
                                    {
                                        // console.log("filteredArray - ",filteredArray)
                                    }
                                    {
                                        filteredArray?.map((item, index) => {
                                            if (item?.id != SilverJewelryID) {
                                            return (
                                                <div className="col-lg-6" key={Math.random()}>
                                                    <a
                                                                    className="desk-top-title-sm a-taglink"
                                                                    aria-current="page"
                                                                    style={{ textDecoration: "none" }}
                                                                    href={process.env.PUBLIC_URL + `/Jewelry/${item?.name}?cid=${item.id}`}
                                                                ><h6 className="desk-top-title"> {item?.name} </h6></a>
                                                    <ul className="menu-ul-list">

                                                        <div className="item" key={Math.random()} >

                                                            {item.subcategory.map((tag, i) => {
                                                                // console.log("tag",tag);
                                                                return (
                                                                    <li className="li-list" key={i}>
                                                                        <a
                                                                            className="desk-top-title-sm a-taglink"
                                                                            aria-current="page"
                                                                            style={{ textDecoration: "none" }}
                                                                            href={process.env.PUBLIC_URL + `/Jewelry/${tag?.name}?cid=${item.id}&sub=${tag.id}`}
                                                                        >{tag?.name}</a>
                                                                    </li>
                                                                )
                                                            })
                                                            }
                                                            {/* <li className="li-list" >
                                                                <a
                                                                    className="desk-top-title-sm a-taglink"
                                                                    aria-current="page"
                                                                    style={{ textDecoration: "none" }}

                                                                    href={process.env.PUBLIC_URL + `/Jewelry/${item?.name}?cid=${item.id}`}
                                                                >
                                                                    <h6 style={{ fontWeight: 'bold' }}> Shop All </h6>
                                                                </a>
                                                            </li> */}
                                                        </div>
                                                    </ul>
                                                </div>

                                            )
                                                        }
                                        })
                                    }
                                </div>
                                <div className="col-lg-2" >
                                <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none", cursor: 'pointer' }}
                                                href={process.env.PUBLIC_URL + `/Jewelry/${SilverJewelryName}?cid=${SilverJewelryID}`}
                                            ><h6 className="desk-top-title">SILVER JEWELRY </h6></a>

                                    <ul className="menu-ul-list">
                                        {
                                            // console.log("subcategoryList",subcategoryList)
                                        }
                                        {subcategoryList?.map((item, index) => {
                                            let selected = []
                                            if (item?.id == SilverJewelryID) {
                                                selected = item?.subcategory
                                            }
                                            // console.log("selected",selected)
                                            return (
                                                selected?.map((tag, i) => {
                                                    // console.log("tag",tag);
                                                    return (

                                                        <li className="li-list" key={i}>
                                                            <a href={process.env.PUBLIC_URL + `/Jewelry/${tag?.name}?cid=${item.id}&sub=${tag.id}`} className="desk-top-title-sm a-taglink"> {tag?.name} </a>
                                                        </li>
                                                    )
                                                }
                                                ))
                                        })}

                                        {/* <li className="li-list"
                                        >
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none", cursor: 'pointer' }}

                                                href={process.env.PUBLIC_URL + `/Jewelry/${SilverJewelryName}?cid=${SilverJewelryID}`}
                                            ><h6 style={{ fontWeight: 'bold' }}> Shop All </h6>
                                            </a>
                                        </li> */}
                                    </ul>
                                    <div style={{bottom:0}}>
                                        <a href={process.env.PUBLIC_URL + `/CustomDesign`} className="desk-top-title-sm a-taglink">
                                            <h6 className="desk-top-title">Bespoke Jewelry</h6>
                                        </a>
                                        <a href={process.env.PUBLIC_URL + '/ContactUs'} className="desk-top-title-sm a-taglink">
                                            <h6 className="desk-top-title">Get in touch with us</h6>
                                        </a>
                                    </div>
                                </div>
                                                <div className="col-lg-2">
                                                    <div>
                                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={menuimg1} width="100%" height="auto" />
                                                    </div>
                                                    {/* <ul className="menu-ul-list mb-3">
                                                        <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + `/CustomDesign`} className="desk-top-title-sm a-taglink">
                                                                Bespoke Jewelry
                                                            </a>
                                                        </li>
                                                        <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + '/ContactUs'} className="desk-top-title-sm a-taglink">
                                                                Get in touch with us
                                                            </a>
                                                        </li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown mobile-dropdown" onClick={handleAbout}>
                                    <a className="nav-link hand t-link-primary u-relative menu-drop menuli-click mobile-menu-toggle" data-value="mega-menu5">
                                        About us
                                        <span className="caret-mobile"></span>
                                    </a>
                                    <div className={aboutStyle === true ? "show mobmenu-hidein-desktop dropdown-menu" : "mobmenu-hidein-desktop dropdown-menu"}>
                                        <div className="container container-menu">
                                            <div className="row desktop-megamenu-list">
                                                <div className="col-lg-2">
                                                    <h6 className="desk-top-title"> ABOUT US </h6>
                                                    <ul className="menu-ul-list">

                                                        <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + "/Aboutus"} className="desk-top-title-sm a-taglink">
                                                                Why Diora Adams
                                                            </a>
                                                        </li>
                                                        {/* <li className="li-list">
                                                            <a href="#" className="desk-top-title-sm a-taglink">
                                                                Blogs
                                                            </a>
                                                        </li> */}

                                                    </ul>
                                                </div>
                                                <div className="col-lg-2">
                                                    <h6 className="desk-top-title"> Diamond Education  </h6>

                                                    <ul className="menu-ul-list">

                                                        <li className="li-list">
                                                        <a href={process.env.PUBLIC_URL + '/DiamondGuide'} className="desk-top-title-sm a-taglink"> Diamond Guide</a>
                                                        </li>
                                                        {/* <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + '/LabGrow'} className="desk-top-title-sm a-taglink"> Lab Grown Diamonds </a>
                                                        </li> */}
                                                        <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + '/DiamondSustainabilty'} className="desk-top-title-sm a-taglink">
                                                                Diamond Sustainabilty
                                                            </a>
                                                        </li>
                                                        <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + '/ConflictFreeDiamonds'} className="desk-top-title-sm a-taglink">
                                                                Conflict Free Diamonds
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-2">
                                                    <h6 className="desk-top-title"> Jewelry Education </h6>

                                                    <ul className="menu-ul-list">

                                                        <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + `/CustomDesign`} className="desk-top-title-sm a-taglink"> Find Your Ring Size </a>
                                                        </li>
                                                        <li className="li-list">
                                                            <a href={process.env.PUBLIC_URL + '/JewelryCare'} className="desk-top-title-sm a-taglink"> JEWELRY CARE </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-lg-2">
                                                    <div>
                                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={menuimg1} width="100%" height="auto" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <a href={process.env.PUBLIC_URL + '/ContactUs'} className="nav-link hand t-link-primary u-relative mobile-menu-toggle" data-value="mega-menu6">
                                        Contact us
                                        <span className="caret-mobile"></span>
                                    </a>

                                </li>
                            </ul>

                            <ul className="rightsideicon-menu">
                                {/* <div>
                                    <div className="container show-search header_search">
                                        <div className="col-md-12" id="search" style={{padding: "5px 2px 10px 2px"}}>
                                            <button type="button" className="close">×</button>
                                            <form name="SearchForm" autocomplete="off" id="SearchForm" className="centered clearfix">
                                                <input type='text' placeholder='Search here...' className=' search-text' data-min-length='1' list='SearchProducts' name='SearchProduct' id='SearchProduct' />
                                                <datalist id="SearchProducts">
                                                </datalist>
                                            </form>
                                        </div>
                                    </div>
                                </div> */}
                                <li className="nav-item d-none d-lg-block" >
                                    <div className="t-compare search_btn" style={{ textDecoration: "none" }}
                                        onClick={() => {
                                            setModalOpen(true);
                                        }}>
                                        <div className="cart-media">
                                            <div className="cart-icon">
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M16 16L12.4584 12.4521M14.4211 7.71053C14.4211 9.49027 13.7141 11.1971 12.4556 12.4556C11.1971 13.7141 9.49027 14.4211 7.71053 14.4211C5.93078 14.4211 4.22394 13.7141 2.96547 12.4556C1.707 11.1971 1 9.49027 1 7.71053C1 5.93078 1.707 4.22394 2.96547 2.96547C4.22394 1.707 5.93078 1 7.71053 1C9.49027 1 11.1971 1.707 12.4556 2.96547C13.7141 4.22394 14.4211 5.93078 14.4211 7.71053Z" stroke="#4A4A4A" strokeWidth="1.5" strokeLinecap="round" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {token != '' && token != null && token != undefined &&
                                    <>
                                        <li className="nav-item d-none d-lg-block" >
                                            {/* <NavLink
                                        // className="nav-link t"

                                        // aria-current="page"
                                        to={"/Cart"}
                                        // style={{ textDecoration: "none" }}
                                        style={({ isActive }) => ({
                                            color: isActive ? 'greenyellow' : 'white',
                                            textDecoration: "none"
                                        })}> */}
                                            {/* <NavLink to="/Wishlist"
                                        className="nav-link t"
                                        style={({ isActive }) => ({
                                            color: isActive ? '#4A4A4A' : 'black'
                                        })}> */}

                                            <a className="nav-link t" style={{ textDecoration: "none" }} href={process.env.PUBLIC_URL + "/Wishlist"}>

                                                <div className="cart-media">
                                                    <div className="cart-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#4A4A4A"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="feather feather-heart"
                                                        >
                                                            <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
                                                            </path>
                                                        </svg>
                                                        <span className="label label-theme sm-circle-value rounded-pill addtowishlistCount">{token != null ? localStorage.getItem('wishlistcount') == null ? 0 : JSON.parse(localStorage.getItem('wishlistcount')) : 0}</span>
                                                    </div>
                                                </div>
                                            </a>
                                            {/* </NavLink> */}
                                            {/* <div style={{ margin: '10px' }}>
                                            <NavLink to="/"
                                                style={({ isActive }) => ({
                                                    color: isActive ? 'greenyellow' : 'white'
                                                })}>
                                                Home
                                            </NavLink>
                                        </div> */}
                                        </li>
                                        <li className="nav-item d-none d-lg-block" >
                                            {/* <NavLink
                                        className="nav-link t"
                                        aria-current="page"
                                        style={{ textDecoration: "none" }}
                                        to={"/Cart"}
                                    > */}
                                            <a className="nav-link t" style={{ textDecoration: "none" }} href={process.env.PUBLIC_URL + "/Cart"}>
                                                <div className="cart-media">
                                                    <div className="cart-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20"
                                                            height="20" viewBox="0 0 24 24" fill="none"
                                                            stroke="#4A4A4A" strokeWidth="2" strokeLinecap="round"
                                                            strokeLinejoin="round" className="feather feather-shopping-cart pe-2">
                                                            <circle cx="9" cy="21" r="1"></circle>
                                                            <circle cx="20" cy="21" r="1"></circle>
                                                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                                                        </svg>
                                                        <span className="label label-theme sm-circle-value rounded-pill addtocartCount">{token != null ? localStorage.getItem('cartcount') == null ? 0 : JSON.parse(localStorage.getItem('cartcount')) : 0}</span>

                                                    </div>
                                                </div>
                                            </a>
                                            {/* </NavLink> */}
                                            <a href="#" className="t" style={{ textDecoration: "none" }}>


                                            </a>
                                        </li>
                                    </>

                                }
                                {/* <li className="nav-item d-none d-lg-block showsmall-cart cart-dropdown">
                                    <a href="#" className="t" style={{ textDecoration: "none" }}>
                                        <span className="btn-solid-default btn-spacing">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-shopping-cart pe-2"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                                        </span>
                                    </a>
                                </li> */}

                                <li className="nav-item d-none d-lg-block account-list" style={{ marginLeft: "25px" }}>
                                    <a className="hand searchbtn btn btn-solid-default btn-spacing t-hov">
                                        <svg width="18" height="18" viewBox="0  0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.5292 9.79296C11.2282 9.11936 10.7914 8.50749 10.2431 7.99146C9.69641 7.47393 9.04886 7.06131 8.33616 6.77638C8.32977 6.77337 8.32339 6.77186 8.31701 6.76884C9.31115 6.09045 9.95742 4.98543 9.95742 3.73869C9.95742 1.67337 8.18616 0 6 0C3.81384 0 2.04258 1.67337 2.04258 3.73869C2.04258 4.98543 2.68885 6.09045 3.68299 6.77035C3.67661 6.77337 3.67023 6.77487 3.66385 6.77789C2.94896 7.06281 2.30747 7.47136 1.75694 7.99297C1.20914 8.5094 0.772382 9.12116 0.470781 9.79447C0.174489 10.4536 0.0146918 11.1604 3.99029e-05 11.8764C-0.000386007 11.8925 0.00260125 11.9085 0.00882574 11.9235C0.0150502 11.9384 0.024386 11.9521 0.0362829 11.9636C0.0481797 11.9751 0.0623971 11.9843 0.0780971 11.9905C0.0937971 11.9968 0.110662 12 0.127699 12H1.08514C1.15535 12 1.2112 11.9472 1.2128 11.8824C1.24471 10.7186 1.73939 9.62864 2.61385 8.80251C3.51863 7.94774 4.72022 7.47739 6 7.47739C7.27978 7.47739 8.48137 7.94774 9.38615 8.80251C10.2606 9.62864 10.7553 10.7186 10.7872 11.8824C10.7888 11.9487 10.8446 12 10.9149 12H11.8723C11.8893 12 11.9062 11.9968 11.9219 11.9905C11.9376 11.9843 11.9518 11.9751 11.9637 11.9636C11.9756 11.9521 11.9849 11.9384 11.9912 11.9235C11.9974 11.9085 12.0004 11.8925 12 11.8764C11.984 11.1558 11.826 10.4548 11.5292 9.79296V9.79296ZM6 6.33166C5.26756 6.33166 4.5782 6.06181 4.05959 5.57186C3.54097 5.08191 3.25534 4.43065 3.25534 3.73869C3.25534 3.04673 3.54097 2.39548 4.05959 1.90553C4.5782 1.41558 5.26756 1.14573 6 1.14573C6.73244 1.14573 7.4218 1.41558 7.94041 1.90553C8.45903 2.39548 8.74466 3.04673 8.74466 3.73869C8.74466 4.43065 8.45903 5.08191 7.94041 5.57186C7.4218 6.06181 6.73244 6.33166 6 6.33166Z" fill="#4A4A4A"></path>
                                        </svg>
                                        {userData != null &&
                                            <div style={{ color: 'black' }}>&nbsp;&nbsp;{"Hi" + " " + userData?.firstname}</div>
                                        }
                                    </a>

                                    <div className="dropdown-menu dropdown-menu-login" id="user_login">
                                        <div className="dropdown-menu-inner" id="login-dropdown-context" style={{ background: '#fff' }}>
                                            <div id="user_authenticated">
                                                {token != null || token != undefined ?
                                                    <ul className="list-unstyled lh-28 mb15 user-menu-afterlogin">
                                                        {/* <li>
                                                            <a
                                                                className="nav-link t"
                                                                style={{ textDecoration: "none" }}
                                                                aria-current="page"
                                                                href={process.env.PUBLIC_URL + "/Gallery"}
                                                            >Gallery
                                                            </a>
                                                        </li> */}
                                                        <li>
                                                            <a
                                                                className="nav-link t"
                                                                style={{ textDecoration: "none" }}
                                                                aria-current="page"
                                                                href={process.env.PUBLIC_URL + "/Myaccount"}
                                                            >My Account
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a className="nav-link t" style={{cursor:"pointer"}} onClick={()=>authenticate()}>
                                                                Log Out
                                                            </a>
                                                            {/* <Link
                                                                className="nav-link t"
                                                                style={{ textDecoration: "none" }}
                                                                aria-current="page"
                                                                to={"/"}
                                                            >Log Out
                                                            </Link> */}
                                                            {/* <a href="/SignupB2B">
                                                            Sign up B2B
                                                        </a> */}
                                                        </li>
                                                    </ul>
                                                    :
                                                    <ul className="list-unstyled lh-28 mb15 user-menu-afterlogin">
                                                        <li>
                                                            <a
                                                                className="nav-link t"
                                                                style={{ textDecoration: "none" }}
                                                                aria-current="page"
                                                                href={process.env.PUBLIC_URL + "/Login"}
                                                            >Login
                                                            </a>
                                                        </li>
                                                        {/* <li>
                                                            <a
                                                                className="nav-link t"
                                                                style={{ textDecoration: "none" }}
                                                                aria-current="page"
                                                                href={process.env.PUBLIC_URL + "/Gallery"}
                                                            >Gallery
                                                            </a>
                                                        </li> */}
                                                    </ul>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </li>

{
    location.pathname != "/Checkout" &&
    <li className="nav-item d-none d-lg-block account-list" style={{marginLeft:"25px"}}>
                                    <Multiselect singleSelect
                                            customCloseIcon={<AiOutlineClose size={15} style={{ paddingLeft: 2, color: 'red', }} />}
                                            style={{
                                                chips: { background: 'white', color: Colors.brown, fontSize: 14 },
                                                searchBox: { "borderRadius": "0px" },

                                            }}
                                            disablePreSelectedValues={true}
                                            ref={currencyselectRef}
                                            selectedValues={[defaultCurrency]}
                                            showArrow={true}
                                            onSelect={handleCurrencyValues}
                                            onRemove={handleRemoveCurrencyValues}
                                            options={Currency}
                                            displayValue="sign_caption" />
                                </li>
}

                            </ul>
                            {token ?
                                ''
                                :
                                <div className="d-lg-none text-center" style={{ padding: 13, display: 'flex' }}>
                                    <a href={process.env.PUBLIC_URL + "/Login"} className="text-center mobile-bot-10 shop-now-btn hand Hypatia" style={{ color: "#fff", width: "48%" }}>
                                        Login
                                    </a>
                                    &nbsp;
                                    <a href={process.env.PUBLIC_URL + "/Signup"} className="anone text-center mobile-bot-10 shop-now-btn hand Hypatia" style={{ width: "48%" }}>
                                        Sign up
                                    </a>
                                </div>
                            }
                        </div>

                    </nav>
                </div>
                <div className="menuifxed" style={{ display: "none" }}>
                    <div id="mega-menu1" className="desktop-megamenu main-div-menu" style={{display:"none",overflowY:"auto",overflowX:"hidden",maxHeight:"500px"}}>
                        <div className="container container-menu">
                            <div className="row desktop-megamenu-list">
                                <div className="col-lg-2 border-right" style={{maxHeight:"450px"}}>
                                    <h6 className="desk-top-title"> CREATE YOUR OWN </h6>
                                    <ul className="menu-ul-list mb-3">
                                        <li className="li-list">
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}
                                                // href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${engagmentName}?cid=${engagmentID}`}
                                                href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/Engagement Ring?cid=27`}
                                            >
                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startring} />&nbsp; Start with a Setting(Rings)
                                            </a>

                                        </li>

                                        <li className="li-list">
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}
                                                // href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${engagmentName}?cid=${engagmentID}`}
                                                href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/Pendant?cid=1`}
                                            >
                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startring} />&nbsp; Start with a Setting(Pendants)
                                            </a>

                                        </li>

                                        <li className="li-list" >
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}

                                                href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Natural`}

                                            >
                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startdiamond} alt="start-diamond" />&nbsp; Start with a Natural Diamond</a>
                                        </li>
                                        <li className="li-list" >
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}

                                                href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Lab`}

                                            >
                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startdiamond} alt="start-diamond" />&nbsp; Start with a Lab Diamond</a>
                                        </li>
                                        {/* <li className="li-list" >
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}

                                                href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own`}

                                            >
                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startdiamond} alt="start-diamond" />&nbsp; Start with Lab Diamond
                                            </a>
                                        </li> */}
                                        {/* <li className="li-list" >
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}

                                                href={process.env.PUBLIC_URL + `/Jewelry/onlysetting?cid=${engagmentID}`}

                                            >
                                                Buy only Setting (without diamond)
                                            </a>
                                        </li> */}

                                    </ul>
                                    <ul className="menu-ul-list mb-3">

                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + `/public/assets_backend/pdf/ring_sizer_diora adams.pdf`} target="_blank" className="desk-top-title-sm a-taglink">
                                                <h6 className="desk-top-title">FIND YOUR RING SIZE </h6>
                                            </a>
                                        </li>
                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + '/JewelryCare'} className="desk-top-title-sm a-taglink">
                                                <h6 className="desk-top-title">JEWELRY CARE </h6>
                                            </a>
                                        </li>

                                        {/*   <li className="li-list">
                                            <a href="#" className="desk-top-title-sm a-taglink">
                                                New Arrivals
                                            </a>
                                        </li> */}

                                    </ul>
                                </div>
                                <div className="col-lg-2 border-right" style={{maxHeight:"450px"}}>
                                    <h6 className="desk-top-title"> SHOP BY SHAPE </h6>
                                    <ul className="menu-ul-list menu-2-left" >
                                        {Shapes?.map((item, index) => {
                                            return (
                                                <li className="li-list" key={Math.random()}                                                >
                                                    <a
                                                        className="desk-top-title-sm a-taglink"
                                                        aria-current="page"
                                                        style={{ textDecoration: "none" }}

                                                        href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${engagmentName}?cid=${engagmentID}&shape=${item.id}`}
                                                    >
                                                        <img className="shapeImg" onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }}  src={item.image} />&nbsp; {item.name}
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                               
                                <div className="col-lg-2 border-right" style={{maxHeight:"450px",overflowX:'auto'}}>
                                    {
                                        settingType?.length > 0 &&
                                        <>
                                            <h6 className="desk-top-title">SHOP BY STYLE </h6>
                                            <ul className="menu-ul-list">
                                                {settingType?.map((item, index) => {                                                    
                                                    return (
                                                        <div className="item" key={Math.random()}>
                                                            <li className="li-list" key={Math.random()} >
                                                                <a
                                                                    href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${item?.name}?styleid=${item?.id}`}
                                                                    className="desk-top-title-sm a-taglink"> {item?.name} </a>
                                                            </li>
                                                        </div>
                                                    )
                                                })
                                                }

                                            </ul>
                                        </>
                                    }
                                </div>

                                <div className="col-lg-2 border-right" style={{maxHeight:"450px"}}>
                                    <h6 className="desk-top-title">SHOP BY METAL </h6>
                                    <ul className="menu-ul-list" >

                                        {metalType?.map((item, index) => {
                                            return (
                                                <li className="li-list" key={Math.random()}>
                                                    <a
                                                        className="desk-top-title-sm a-taglink"
                                                        aria-current="page"
                                                        style={{ textDecoration: "none" }}
                                                        href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${engagmentName}?cid=${engagmentID}&metal=${item.id}`}>
                                                        { item?.name.toLowerCase() != 'silver' && item?.name }
                                                    </a>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                </div>
                                <div className="col-lg-2 " style={{maxHeight:"450px"}}>
                                    <div>
                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={menuimg1} width="100%" height="auto" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mega-menu2" className="desktop-megamenu main-div-menu" style={{display:"none",overflowY:"auto",overflowX:"hidden",maxHeight:"500px"}}>
                        <div className="container container-menu">

                            <div className="row desktop-megamenu-list">
                                <div className="col-lg-2  border-right" style={{maxHeight:"450px"}}>
                                    <h6 className="desk-top-title"> SHOP BY SHAPE </h6>
                                    <ul className="menu-ul-list menu-2-left" >
                                        {Shapes?.map((item, index) => {
                                            return (
                                                <li className="li-list" key={Math.random()}
                                                >
                                                    <a
                                                        className="desk-top-title-sm a-taglink"
                                                        aria-current="page"
                                                        style={{ textDecoration: "none" }}
                                                        // href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${weddingName}?cid=${weddingID}&shape=${item.id}`}
                                                        href={process.env.PUBLIC_URL + `/Jewelry/${weddingName}?cid=${weddingID}&shape=${item.id}`}
                                                    >
                                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} className="shapeImg" src={item.image} />&nbsp; {item.name}
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className="col-lg-2 border-right" style={{maxHeight:"450px",overflowX:'auto'}}>
                                {
                                    settingType?.length > 0 &&
                                    <>
                                        <h6 className="desk-top-title"> SHOP BY STYLE </h6>
                                        <ul className="menu-ul-list">
                                            {settingType?.map((item, index) => {                                               
                                                return (
                                                    <div className="item" key={Math.random()}>
                                                        <div className="item" key={Math.random()}>
                                                            <li className="li-list" key={Math.random()} >
                                                                <a
                                                                    // href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${weddingName}?cid=${weddingID}&styleid=${item?.id}`}
                                                                    href={process.env.PUBLIC_URL + `/Jewelry/${weddingName}?cid=${weddingID}&styleid=${item?.id}`}
                                                                    className="desk-top-title-sm a-taglink"> {item?.name} </a>
                                                            </li>
                                                        </div>
                                                    </div>
                                                )
                                             })
                                            }                                           
                                        </ul>
                                    </>
                                }  
                                </div>
                                <div className="col-lg-2 border-right"  style={{maxHeight:"450px"}}>
                                    <h6 className="desk-top-title">SHOP BY METAL </h6>
                                    <ul className="menu-ul-list" >

                                        {metalType?.map((item, index) => {
                                            return (
                                                <li className="li-list" key={Math.random()}
                                                >
                                                    <a
                                                        className="desk-top-title-sm a-taglink"
                                                        aria-current="page"
                                                        style={{ textDecoration: "none" }}
                                                        // href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${weddingName}?cid=${weddingID}&metal=${item.id}`}
                                                        href={process.env.PUBLIC_URL + `/Jewelry/${weddingName}?cid=${weddingID}&metal=${item.id}`}
                                                    >
                                                        { item?.name.toLowerCase() != 'silver' && item?.name }
                                                    </a>
                                                </li>
                                            )
                                        })}

                                    </ul>
                                    <ul className="menu-ul-list mb-3">
                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + `/public/assets_backend/pdf/ring_sizer_diora adams.pdf`} target="_blank" className="desk-top-title-sm a-taglink">
                                                <h6 className="desk-top-title">FIND YOUR RING SIZE </h6>
                                            </a>
                                        </li>
                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + '/JewelryCare'} className="desk-top-title-sm a-taglink">
                                                <h6 className="desk-top-title">JEWELRY CARE </h6>
                                            </a>
                                        </li>

                                        {/*   <li className="li-list">
                                            <a href="#" className="desk-top-title-sm a-taglink">
                                                New Arrivals
                                            </a>
                                        </li> */}

                                    </ul>
                                </div>

                                <div className="col-lg-2 " style={{maxHeight:"450px"}}>
                                    <div>
                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={menuimg1} width="100%" height="auto" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mega-menu3" className="desktop-megamenu main-div-menu" style={{display:"none",overflowY:"auto",overflowX:"hidden",maxHeight:"500px"}}>
                        <div className="container container-menu">
                            <div className="row desktop-megamenu-list">

                                <div className="col-lg-2 border-right" style={{maxHeight:"450px"}}>
                                    <h6 className="desk-top-title"> SHOP BY SHAPE </h6>
                                    <ul className="menu-ul-list menu-2-left" >
                                        {Shapes?.map((item, index) => {
                                            return (
                                                <li className="li-list" key={Math.random()}
                                                >
                                                    <a
                                                        className="desk-top-title-sm a-taglink"
                                                        aria-current="page"
                                                        style={{ textDecoration: "none" }}
                                                        href={process.env.PUBLIC_URL + `/DiamondList/${item.name}?shape=${item.id}`}>
                                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} className="shapeImg" src={item.image} />&nbsp; {item.name}
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                                <div className="col-lg-2 border-right" style={{maxHeight:"450px"}}>
                                    <h6 className="desk-top-title"> CREATE YOUR OWN </h6>
                                    <ul className="menu-ul-list mb-3">
                                        <li className="li-list" >
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}
                                                href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Natural`}>
                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startdiamond} alt="start-diamond" />&nbsp; Start with a Natural Diamond
                                                </a>

                                        </li>
                                        <li className="li-list" >
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}
                                                href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Lab`}>
                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startdiamond} alt="start-diamond" />&nbsp; Start with a Lab Diamond
                                                </a>

                                        </li>
                                        {/* <li className="li-list" >
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}

                                                href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own`}
                                            // state={{ fromDashboard: false }}
                                            >
                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startdiamond} alt="start-diamond" />&nbsp; Start with Lab Diamond
                                            </a>
                                        </li> */}
                                        <li className="li-list">

                                            <a href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${engagmentName}?cid=${engagmentID}`} style={{ textDecoration: "none" }} className="desk-top-title-sm a-taglink">
                                                <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={startring} />&nbsp; Start with a Setting
                                            </a>

                                        </li>

                                    </ul>
                                </div>
                                <div className="col-lg-2 border-right" style={{maxHeight:"450px"}}>
                                    <h6 className="desk-top-title"> EDUCATION </h6>
                                    <ul className="menu-ul-list mb-3">
                                        <li className="li-list">
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}
                                                href={process.env.PUBLIC_URL + '/DiamondGuide'}
                                            >
                                                Diamond Guide
                                            </a>
                                        </li>
                                        {/* <li className="li-list">
                                            <a
                                                className=""
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}

                                                href={process.env.PUBLIC_URL + '/LabGrow'}

                                            >
                                                Lab Grown Diamonds
                                            </a>
                                        </li> */}
                                        <li className="li-list">
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}
                                                href={process.env.PUBLIC_URL + '/DiamondSustainabilty'}
                                            >
                                                Diamond Sustainabilty

                                            </a>
                                        </li>
                                        <li className="li-list">
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none" }}
                                                href={process.env.PUBLIC_URL + '/ConflictFreeDiamonds'}
                                            >
                                                Conflict Free Diamonds
                                            </a>
                                        </li>


                                    </ul>
                                </div>

                                <div className="col-lg-2" style={{maxHeight:"450px"}}>
                                    <div>
                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={menuimg1} width="100%" height="auto" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="mega-menu4" className="desktop-megamenu main-div-menu" style={{display:"none",overflowY:"auto",overflowX:"hidden",maxHeight:"500px"}}>
                        <div className="container container-menu">
                            <div className="row desktop-megamenu-list">
                                <div className="row col-lg-7" style={{maxHeight:"450px"}}>                                   
                                    {
                                        filteredArray?.map((item, index) => {
                                            if (item?.id != SilverJewelryID && item?.ismenu == 'Active' && item?.subcategory?.length > 0) {
                                            return (
                                                <div className="col-lg-2 border-right" key={Math.random()}>
                                                    <a
                                                        className="desk-top-title-sm a-taglink"
                                                        aria-current="page"
                                                        style={{ textDecoration: "none" }}
                                                        href={process.env.PUBLIC_URL + `/Jewelry/${item?.name}?cid=${item.id}`}
                                                    ><h6 className="desk-top-title"> {item?.name} </h6></a>
                                                    <ul className="menu-ul-list">

                                                        <div className="item" key={Math.random()} >

                                                            {item.subcategory.map((tag, i) => {
                                                                // console.log("tag",tag);
                                                                return (
                                                                    <li className="li-list" key={i}>
                                                                        <a
                                                                            className="desk-top-title-sm a-taglink"
                                                                            aria-current="page"
                                                                            style={{ textDecoration: "none" }}
                                                                            href={process.env.PUBLIC_URL + `/Jewelry/${tag?.name}?cid=${item.id}&sub=${tag.id}`}
                                                                        >{tag?.name}</a>
                                                                    </li>
                                                                )
                                                            })
                                                            }
                                                            {/* <li className="li-list" >
                                                                <a
                                                                    className="desk-top-title-sm a-taglink"
                                                                    aria-current="page"
                                                                    style={{ textDecoration: "none" }}

                                                                    href={process.env.PUBLIC_URL + `/Jewelry/${item?.name}?cid=${item.id}`}
                                                                >
                                                                    <h6 style={{ fontWeight: 'bold' }}> Shop All </h6>
                                                                </a>
                                                            </li> */}
                                                        </div>
                                                    </ul>
                                                </div>

                                            )
                                                        }
                                        })
                                    }
                                </div>
                                <div className="col-lg-2" style={{maxHeight:"450px"}}>
                                <a
                                    className="desk-top-title-sm a-taglink"
                                    aria-current="page"
                                    style={{ textDecoration: "none", cursor: 'pointer' }}
                                    href={process.env.PUBLIC_URL + `/Jewelry/${SilverJewelryName}?cid=${SilverJewelryID}`}
                                ><h6 className="desk-top-title">SILVER JEWELRY </h6></a>

                                    <ul className="menu-ul-list">
                                        {subcategoryList?.map((item, index) => {
                                            let selected = []
                                            if (item?.id == SilverJewelryID) {
                                                selected = item?.subcategory
                                            }
                                            return (
                                                selected?.map((tag, i) => {
                                                    return (
                                                        <li className="li-list" key={i}>
                                                            <a href={process.env.PUBLIC_URL + `/Jewelry/${tag?.name}?cid=${item.id}&sub=${tag.id}`} className="desk-top-title-sm a-taglink"> {tag?.name} </a>
                                                        </li>
                                                    )
                                                }
                                                ))
                                        })}

                                        {/* <li className="li-list"
                                        >
                                            <a
                                                className="desk-top-title-sm a-taglink"
                                                aria-current="page"
                                                style={{ textDecoration: "none", cursor: 'pointer' }}

                                                href={process.env.PUBLIC_URL + `/Jewelry/${SilverJewelryName}?cid=${SilverJewelryID}`}
                                            ><h6 style={{ fontWeight: 'bold' }}> Shop All </h6>
                                            </a>
                                        </li> */}
                                    </ul>

                                    <div style={{position:"absolute",bottom:0}}>
                                        <a href={process.env.PUBLIC_URL + `/CustomDesign`} className="desk-top-title-sm a-taglink">
                                            <h6 className="desk-top-title">Bespoke Jewelry</h6>
                                        </a>
                                        <a href={process.env.PUBLIC_URL + '/ContactUs'} className="desk-top-title-sm a-taglink">
                                            <h6 className="desk-top-title">Get in touch with us</h6>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-2"  style={{maxHeight:"450px"}}>
                                    <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={menuimg1} width="100%" height="auto" />
                                    {/* <ul className="menu-ul-list mb-3 mt-3">
                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + `/CustomDesign`} className="desk-top-title-sm a-taglink">
                                                Bespoke Jewelry
                                            </a>
                                        </li>
                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + '/ContactUs'} className="desk-top-title-sm a-taglink">
                                                Get in touch with us
                                            </a>
                                        </li>
                                    </ul> */}
                                </div>

                            </div>

                        </div>
                    </div>
                    <div id="mega-menu5" className="desktop-megamenu main-div-menu" style={{ display: "none",overflowY:"auto",overflowX:"hidden",maxHeight:"500px" }}>
                        <div className="container container-menu">
                            <div className="row desktop-megamenu-list">
                                <div className="col-lg-2 border-right">
                                    <h6 className="desk-top-title"> ABOUT US </h6>
                                    <ul className="menu-ul-list">

                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + "/Aboutus"} className="desk-top-title-sm a-taglink">
                                                Why Diora Adams
                                            </a>
                                        </li>
                                        {/* <li className="li-list">
                                            <a href="#" className="desk-top-title-sm a-taglink">
                                                Blogs
                                            </a>
                                        </li> */}

                                    </ul>
                                </div>
                                <div className="col-lg-2 border-right">
                                    <h6 className="desk-top-title"> Diamond Education  </h6>

                                    <ul className="menu-ul-list">

                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + '/DiamondGuide'} className="desk-top-title-sm a-taglink"> Diamond Guide</a>
                                        </li>
                                        {/* <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + '/LabGrow'} className="desk-top-title-sm a-taglink"> Lab Grown Diamonds </a>
                                        </li> */}
                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + '/DiamondSustainabilty'} className="desk-top-title-sm a-taglink">
                                                Diamond Sustainabilty
                                            </a>
                                        </li>
                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + '/ConflictFreeDiamonds'} className="desk-top-title-sm a-taglink">
                                                Conflict Free Diamonds
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-2 border-right">
                                    <h6 className="desk-top-title"> Jewelry Education </h6>

                                    <ul className="menu-ul-list">

                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + `/CustomDesign`} className="desk-top-title-sm a-taglink"> Find Your Ring Size </a>
                                        </li>
                                        <li className="li-list">
                                            <a href={process.env.PUBLIC_URL + '/JewelryCare'} className="desk-top-title-sm a-taglink"> JEWELRY CARE </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-2 ">
                                    <div>
                                        <img onError={ (e)=>{ e.target.onerror=null; e.target.src=noimage; }} src={menuimg1} width="100%" height="auto" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </header >

            <ToastContainer />
            {modalOpen && <SearchModal setOpenModal={setModalOpen} />}
        </>
    );
}

