import axios from 'axios'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { BASE_URL, GetAPIUrl } from '../API/APIUrl'
import callimg from '../assets/images/icons/callimg.png'
import email from '../assets/images/icons/emailimg.png'
import hours from '../assets/images/icons/hoursimg.png'
import noimage from "../assets/images/product-details/noimage.jpg"
import { Colors } from '../constant/Constant'
import { b64_to_utf8, BASENAME, utf8_to_b64 } from '../helpers/Utility'
import LoadingSpinner from '../module/LoadingSpinner'
import { confirmRemove, ShowErrorMessage, ShowMessage } from '../module/Tostify'
import PDFViewer from 'pdf-viewer-reactjs'

import { Viewer, Worker } from "@react-pdf-viewer/core";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// Import styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { fetch2 } from '../helpers/fetch'
import { isMobile } from 'react-device-detect'
import { CategorySkeleton } from '../module/Skeletons'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const $ = window.$;

export default function JewellryDetails() {
    const location = useLocation();
    // const pathname = location.pathname == '/JDetails/Build-Your-Own'
    // console.log("pathname", pathname);
    // const slug = new URLSearchParams(location.search).get("slug");
    const params = useParams();
    // var JewelryId = new URLSearchParams(location.search).get("jid");
    var JewelryId = params?.jid;
    var DiamondId = new URLSearchParams(location.search).get("did");
    var onlysetting = new URLSearchParams(location.search).get("onlysetting");
    console.log("onlysetting",onlysetting)
    const [urls, setUrls] = useState(new URLSearchParams(location.search).get("jid"))


    localStorage.setItem('URL', location.pathname + location.search)

    const [getAllData, setGetAllData] = useState(JSON.parse(localStorage.getItem('All')) ? JSON.parse(localStorage.getItem('All')) : '')

    const [Wishlist, setWishlist] = useState('');
    const [isByWithLab,setIsByWithLab] = useState(false)
    const token = localStorage.getItem('token')
    // let { slug } = useParams();

    // const [isVideoLoading, setIsVideoLoading] = useState(true)
    const [isVideoLoading, setIsVideoLoading] = useState(false)

    const [totalDiamondWeight, setTotalDiamondWeight] = useState(0)
    const [totalGemStoneWeight, setTotalGemStoneWeight] = useState(0)

    const [isGemStone, setIsGemStone] = useState(false)
    const [isCenterStone, setIsCenterStone] = useState(false)
    const [isOnlySetting, setOnlySetting] = useState(onlysetting)

    const [isValidChooseDiamond, setIsValidChooseDiamond] = useState(false)

    const [jewelryDetail, setjewelryDetail] = useState({})
    const [breadCrumpJewelSku, setBreadCrumpJewelSku] = useState('');
    const [breadCrumpCat, setBreadCrumpCat] = useState('');
    const [jewelryVariants, setjewelryVariants] = useState([])
    const [ringsizehelp, setRingSizeHelp] = useState([])
    const [ringSize, setRingSize] = useState([])
    const [ringSizeAvailable, setRingSizeAvailable] = useState([])
    const [jewerlryProductType, setJewerlryProductType] = useState([])
    const [ringSizeQty, setRingSizeQty] = useState(true)

    const [SelectedringSize, setSelectedRingSize] = useState('')

    const [defaultVariant, setDefaultVariant] = useState('')
    const [DefaultMetalVariant, setDefaultMetalVariant] = useState('')

    const [selectedImage, setselectedImage] = useState('')
    const [recommended, setRecommended] = useState([])
    const [similar, setSimilar] = useState([])
    const [message, setmessage] = useState(false)
    const buildYourOwn = location.pathname.split("/")[2] == "Build-Your-Own"

    // const Images = location?.state?.jewelryData?.images
    // const get_default_img = location?.state?.jewelryData?.get_default_img

    // console.log("Images", Images);

    const [IsLoading, setIsLoading] = useState(false)

    const [IsWishlistLoading, setIsWishlistLoading] = useState(false)
    const [IsCartLoading, setIsCartLoading] = useState(false)

    const [countQty, setCountQty] = useState(1);
    const key = 'id';
    const [arrayUniqueByKey, setArrayUniqueByKey] = useState([])
    const [arrayUniqueMetalKey, setArrayUniqueMetalKey] = useState([])

    const [lgShow, setLgShow] = useState(false);
    const [DiamondDetail, setDiamondDetail] = useState([])

    const [stoneGroupList,setStoneGroupList] = useState([]);

    const [prodcutMetalWeight, setProdcutMetalWeight] = useState(0);
    const [finalAmount,setFinalAmount] = useState(0);
    const [labourtType,setLabourtType] = useState('');
    const [labourtCharge,setLabourtCharge] = useState(0);
    const [finalLabourtCharge,setFinalLabourtCharge] = useState(0);
    
    const [otherCharge,setOtherCharge] = useState(0);
    const [centerDiaPrice,setCenterDiaPrice] = useState(0);
    const [finalGST,setFinalGST] = useState(0);
    const [sideDiaPrice,setSideDiaPrice] = useState(0);
    const [metalAmount,setMetalAmount] = useState(0);
    const [sideDiaData,setSideDiaData] = useState([]);

    const [sideDiaGrpId,setSideDiaGrpId] = useState(null);
    const [selectedSideDiaColor,setSelectedSideDiaColor] = useState('');
    const [selectedSideDiaClarity,setSelectedSideDiaClarity] = useState('');
    const [prodcutMetalPrice, setProdcutMetalPrice] = useState(0);
    const [prodcutSideDiaPrice, setProdcutSideDiaPrice] = useState(0);
    const [imageUrl, setImageUrl] = useState('');    
    
    const [globalJewelryMargin,setGlobalJewelryMargin] = useState(0);
    const [globalMarkup,setGlobalMarkup] = useState(0);
    const [globalTagFact,setglobalTagFact] = useState(1);

    const [sideStoneColorClarityChange,setSideStoneColorClarityChange] = useState(false);
    const [sideStoneColorChange,setSideStoneColorChange] = useState('');
    const [sideStoneClarityChange,setSideStoneClarityChange] = useState('');

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const is_b2b = JSON.parse(localStorage.getItem('data'))
    const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))
    const [isSkeleton,setIsSkeleton] = useState(false);
    let customerGroup = JSON.parse(localStorage.getItem('customerGroup'));
    
    const [data, setData] = useState({
        type: '',
        mincaret: '',
        maxcaret: '',
    })

    const getStoneGroupList = async() => {        
        let diaType = isByWithLab ? 'Lab' : 'Natural';
        await axios.get(BASE_URL + GetAPIUrl.STONEGROUPLIST_URL+'?diaType='+diaType, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                if(response.data.type == 'success')
                {
                    setStoneGroupList(response.data.data);                    
                    if(isByWithLab){
                        setSideDiaGrpId(response?.data?.data?.[0]?.id)
                    }else{
                        setSideDiaGrpId(jewelryDetail?.sideDiaColGrp);
                    }
                }
            }).catch(error => {
            }).finally(() => {

            })
    }

    useEffect(()=>{
        getStoneGroupList();  
    },[isByWithLab]); 
    
    useEffect(()=>{
        handleStoneGrp();  
    },[sideDiaGrpId]);

    const updatePrice = async (res,sideDiamondAmount)=> {
        // let metalPrice = prodcutMetalPrice;             
        let metalPrice = res.metalprice;             
        // let sideStonePrice = prodcutSideDiaPrice;        
        let sideStonePrice = sideDiamondAmount;
        console.log("metalPrice",metalPrice)
        
        let centerStonePrice = res?.centerstoneprice;
        if(isByWithLab){
            centerStonePrice = res?.centerstoneprice_lab;
        }
        console.log("isByWithLab",isByWithLab)
        console.log("centerStonePrice123--->",centerStonePrice)
        let markup = res?.mark_up;
        let tagfact = res?.tag_fact;
        
        // let metalWeight = prodcutMetalWeight;
        let metalWeight = res.metalweight;
        let grossWeight = res.grossweight;
        console.log("metalWeight",metalWeight)
        console.log("labourtCharge",labourtCharge)
        console.log("otherCharge",otherCharge)

        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "sidediamond")
            {
                let sideDiaDisc = customerGroup?.discount;
                let discontPrice = (sideDiamondAmount * sideDiaDisc)/100;
                console.log("customerGroup",customerGroup);
                console.log("res?.sidestoneprice",res?.sidestoneprice);
                setSideDiaPrice((sideDiamondAmount - discontPrice));
                sideStonePrice = (sideDiamondAmount - discontPrice);

                // let discontPrice = (res?.sidestoneprice * sideDiaDisc)/100;
                // console.log("customerGroup",customerGroup);
                // console.log("res?.sidestoneprice",res?.sidestoneprice);
                // setSideDiaPrice((res?.sidestoneprice - discontPrice));
                // sideStonePrice = (res?.sidestoneprice - discontPrice);
            }else{
                // sideStonePrice = res?.sidestoneprice;
                // setSideDiaPrice(res?.sidestoneprice);
                setSideDiaPrice(sideDiamondAmount);
            }   
        }else{
            // sideStonePrice = res?.sidestoneprice;
            // setSideDiaPrice(res?.sidestoneprice);
            setSideDiaPrice(sideDiamondAmount);
        }
        console.log("sideDiamondAmount",sideDiamondAmount);
        // sideStonePrice = sideDiamondAmount;
        console.log("sideStonePrice",sideStonePrice);

        let chainWeight = (res?.chainweight != null) ? res?.chainweight : 0;
        // let totalWeight = metalWeight + chainWeight;
        let totalWeight = labourtType == "gross" ? grossWeight : (metalWeight + chainWeight);
            totalWeight = totalWeight.toFixed(2);
        let finalLabCharge =  labourtCharge * totalWeight;
        let labourChargeMini = res?.labour_charge?.minimum_amount;
            finalLabCharge = finalLabCharge > labourChargeMini ? finalLabCharge : labourChargeMini;
        
        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "labour")
            {
                let labourDisc = customerGroup?.discount;
                let discontLabourPrice = (finalLabCharge * labourDisc)/100;
                finalLabCharge=finalLabCharge-discontLabourPrice;
            }
        }
        console.log("labourCharge",finalLabCharge)

        let totalMetalPrice = metalPrice + finalLabCharge + otherCharge;
            finalLabCharge =  finalLabCharge?.toFixed(2);
        let totalMFGCost = totalMetalPrice + sideStonePrice;
        console.log("totalMFGCost",totalMFGCost)

        let GST = 0;   
        let finalPrice = 0;     
        if( markup > 0 && tagfact > 0){
            let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);
            let finalTagfact = finalMarkup/tagfact;
                finalPrice = finalTagfact + centerStonePrice;
                GST = finalTagfact - totalMFGCost;
                GST = GST.toFixed(2);
        }else if(markup > 0 && tagfact == 0){
            let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);          
                finalPrice = finalMarkup + centerStonePrice;
                 GST = finalMarkup - totalMFGCost;
                 GST = GST.toFixed(2);
        }else if(markup == 0 && tagfact > 0){           
            let finalTagfact = totalMFGCost/tagfact;
                finalPrice = finalTagfact + centerStonePrice;
                GST = finalTagfact - totalMFGCost;
                GST = GST.toFixed(2);
        }else{
               finalPrice = totalMFGCost + centerStonePrice;
        }

        let isb2bgetdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        console.log("finalPrice before",finalPrice)
        console.log("globalJewelryMargin;;;;;;;;;;;;;;;;;;;;;;;",globalJewelryMargin)
        console.log("isb2bgetdata",isb2bgetdata)
        if(globalJewelryMargin > 0 && isb2bgetdata == 0){
            finalPrice = finalPrice + ((finalPrice*globalJewelryMargin)/100);
        }
        console.log("finalPrice",finalPrice)

        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "fulljewelry")
            {
                let finalDisc = customerGroup?.discount;
                let discontfinalPrice = (finalPrice * finalDisc)/100;
                finalPrice=finalPrice-discontfinalPrice;
            }
        }
        
         setFinalGST(GST);
        //  setFinalAmount(finalPrice.toFixed(2));
         setSideDiaPrice(sideStonePrice);
         setCenterDiaPrice(centerStonePrice);
         setMetalAmount(metalPrice);
         setProdcutMetalPrice(metalPrice);

         await axios.post(BASE_URL + GetAPIUrl.CURRENCYCONVERT,{currency_id:defaultCurrency?.id == null ? null : defaultCurrency?.id,amount:finalPrice}, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                console.log("response",response)
                if(response?.data?.success == true)
                {
                    setFinalAmount(response?.data?.amount)
                }  
            }).catch(error => {
            }).finally(() => {
                setIsLoading(false);
            })
         
        //  setSideDiaData(jewelryDetail?.sideStone); 
        // setProdcutMetalPrice(jewelryDetail?.sync_metal_price);                
        // setProdcutSideDiaPrice(jewelryDetail?.sync_side_dia_price); 
        // setProdcutMetalWeight(jewelryDetail?.metalweight);
        console.log("final",finalPrice.toFixed(2))
    }

    const convertCurrency = async (amount) => {

        await axios.post(BASE_URL + GetAPIUrl.CURRENCYCONVERT,{currency_id:defaultCurrency?.id == null ? null : defaultCurrency?.id,amount:amount}, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                console.log("response",response)
                if(response?.data?.success == true)
                {
                    setFinalAmount(response?.data?.amount)
                }  
            }).catch(error => {
            }).finally(() => {
            })
    }

    const handleStoneGrp = async () => {
        setIsSkeleton(true);

        let StoneGrop =$('#sideDiaSelect');
        let sideColor = $(StoneGrop).find(':selected').data('color');
        let sideClarity = $(StoneGrop).find(':selected').data('clarity');
        console.log('colorClarity',sideColor,sideClarity);
        setSelectedSideDiaColor(sideColor);
        setSelectedSideDiaClarity(sideClarity);
        // console.log("color",$(StoneGrop).find(':selected').data('color'))
        // setSelectedSideDiaColor(e.target.options[e.target.selectedIndex].dataset.color);   
        // setSelectedSideDiaClarity(e.target.options[e.target.selectedIndex].dataset.clarity);   
        // setIsLoading(true)
        setSideStoneColorClarityChange(true)
        setSideStoneColorChange(sideColor)
        setSideStoneClarityChange(sideClarity)
        
        let stoneGrpId = StoneGrop.val();    
        // const dropdownElement = document.getElementById("sideDiaSelect")
        // alert(dropdownElement.value) // selected value (B in this example)
        // alert(stoneGrpId) 
        // localStorage.getItem("sideDiaColGrp")
        if(stoneGrpId == null || stoneGrpId == "null" || stoneGrpId == "Select"){
            // CalljewelryDetailApi(JewelryId)
            // setIsLoading(false);
            setTimeout(() => {
                setIsSkeleton(false);                
            }, 1000);
        }else{
            setSideDiaGrpId(stoneGrpId);
            localStorage.setItem("sideDiaColGrp", stoneGrpId);

            // let tempSideDiaData = sideDiaData?.get_side_diamond != undefined && sideDiaData?.get_side_diamond.length > 0 ? sideDiaData?.get_side_diamond : [];
            //     tempSideDiaData = tempSideDiaData.length == 0 ? sideDiaData : tempSideDiaData;
            let tempSideDiaData = jewelryDetail?.jewelry_diamonds.filter(td => td.diamondtype === 'Side');
            console.log("tempSideDiaData",tempSideDiaData)
            

            await axios.post(BASE_URL + GetAPIUrl.STONGROUPPRICE_URL,{stoneGrpId}, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Authorization': 'Bearer ' + token,
                }
            })
                .then(response => {
                    console.log("response",response)
                    if(response?.data?.type == "success")
                    {
                        let stoneGrpDetail = response?.data?.data?.stone_group_detail;            
                        let sideDiamondAmount = 0;
                        $.each(tempSideDiaData,function(sideIndex,sideItem){   
                            console.log("sideItem --- ",sideItem);
                            let sideStoneSize = sideItem?.fromsize_name != null ? sideItem?.fromsize_name : null;

                            let sideStoneShape = sideItem?.shape != undefined ? sideItem?.shape : null;
                            let sideWeight = sideItem?.totalweight != undefined ? sideItem?.totalweight : null;
                            console.log("sideStoneSize",sideStoneSize)
                            console.log("sideStoneShape",sideStoneShape)
                            console.log("sideWeight",sideWeight)
                            // let sideStoneSize = sideItem?.side_diam_data?.side_stone_size != undefined ? sideItem?.side_diam_data?.side_stone_size : [];
                            //     sideStoneSize = $.isEmptyObject(sideStoneSize) === true ? sideItem?.side_stone_size : sideStoneSize;
                            
                            // let sideStoneShape = sideItem?.side_diam_data?.shape?.id != undefined ? sideItem?.side_diam_data?.shape?.id : null;                
                            //     sideStoneShape = sideStoneShape === null ? sideItem?.shape?.id : sideStoneShape;
            
                            // let sideWeight = sideItem?.side_diam_data?.totalweight != undefined ? sideItem?.side_diam_data?.totalweight : null;
                            //     sideWeight = sideWeight === null ? sideItem?.totalweight : sideWeight;
                            $.each(stoneGrpDetail,function(index,item){   
                                console.log("inner item",item)
                                //  if(item?.size?.paraname == sideStoneSize?.paraname && item?.shape == sideStoneShape)
                                if(item?.size?.paraname == sideStoneSize?.paraname && item?.shape == sideStoneShape)
                                {       
                                    console.log("inner item  amount  - ",item?.amount)
                                    sideDiamondAmount = sideDiamondAmount + (item?.amount * sideWeight);
                                }
            
                            });
                        });
                        console.log("sideDiamondAmount1",sideDiamondAmount)
                        sideDiamondAmount =  sideDiamondAmount + (globalMarkup * sideDiamondAmount / 100);
                        sideDiamondAmount =  sideDiamondAmount / globalTagFact ;
                        console.log("sideDiamondAmount2>>>",sideDiamondAmount)

                        setProdcutSideDiaPrice(sideDiamondAmount);
                        updatePrice(jewelryDetail,sideDiamondAmount)
                    }  
                }).catch(error => {
                }).finally(() => {  
                    setTimeout(() => {
                        setIsSkeleton(false);                        
                    }, 1000);                  
                })
        }
    }
    
    

    // useEffect(()=>{        
    //     let metalPrice = prodcutMetalPrice;             
    //     let sideStonePrice = prodcutSideDiaPrice;        
        
    //     let centerStonePrice = diaTypeCenterPrice > 0 ? diaTypeCenterPrice : jewelryDetail?.centerstoneprice;
    //     let markup = jewelryDetail?.mark_up;
    //     let tagfact = jewelryDetail?.tag_fact;//`metalprice`, `centerstoneprice`, `sidestoneprice`
        
    //     let metalWeight = prodcutMetalWeight;

    //     let chainWeight = (jewelryDetail?.chainweight != null) ? jewelryDetail?.chainweight : 0;
    //     let totalWeight = metalWeight + chainWeight;
    //         totalWeight = totalWeight.toFixed(2);
    //     let finalLabCharge =  labourtCharge * totalWeight;
    //     let totalMetalPrice = metalPrice + finalLabCharge + otherCharge;
    //         finalLabCharge =  finalLabCharge.toFixed(2);
    //     let totalMFGCost = totalMetalPrice + sideStonePrice;

    //     let GST = 0;   
    //     let finalPrice = 0;     
    //     if( markup > 0 && tagfact > 0){
    //         let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);
    //         let finalTagfact = finalMarkup/tagfact;
    //             finalPrice = finalTagfact + centerStonePrice;
    //             GST = finalTagfact - totalMFGCost;
    //             GST = GST.toFixed(2);
    //     }else if(markup > 0 && tagfact == 0){
    //         let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);          
    //             finalPrice = finalMarkup + centerStonePrice;
    //              GST = finalMarkup - totalMFGCost;
    //              GST = GST.toFixed(2);
    //     }else if(markup == 0 && tagfact > 0){           
    //         let finalTagfact = totalMFGCost/tagfact;
    //             finalPrice = finalTagfact + centerStonePrice;
    //             GST = finalTagfact - totalMFGCost;
    //             GST = GST.toFixed(2);
    //     }else{
    //            finalPrice = totalMFGCost + centerStonePrice;
    //     }        
        
        
    //      setFinalGST(GST);
    //      setFinalAmount(finalPrice.toFixed(2));
    //      setSideDiaPrice(sideStonePrice);
    //      setCenterDiaPrice(centerStonePrice);
    //      setMetalAmount(metalPrice);
    //      setfinalLabourCharge(finalLabCharge);
         
    //     //  setSideDiaData(jewelryDetail?.sideStone); 
    //     // setProdcutMetalPrice(jewelryDetail?.sync_metal_price);                
    //     // setProdcutSideDiaPrice(jewelryDetail?.sync_side_dia_price); 
    //     // setProdcutMetalWeight(jewelryDetail?.metalweight);
    //     console.log("final",finalPrice.toFixed(2))

    // },[]);

    const url =
        "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/cves%2F12f5c05d-1c8e-42bf-a650-397424e8e5e8.pdf?alt=media&token=d2e9675a-5198-47a0-b86b-70e25cd5d970";
    // Create new plugin instance
    
    const selectionFun = (item, index) => {

        const object = jewelryDetail?.zoom.find(obj => obj.id === item.id);
        setselectedImage(object)
    }
    const selectVariant = async (item, index) => {
        setDefaultVariant(item.gem_color.id)
        setDefaultMetalVariant({
            'metal_stamp': item?.metal_stamp?.paraname,
            'metal_type': item?.metal_type?.paraname,
        })
        setjewelryDetail(item)

        let metalprice=item?.metalprice;
        // let labourCharge=item?.labourCharge;      

        let labourFlag=item?.data?.labour_charge?.item;
        let labourCharge=item?.data?.labour_charge?.amount;
        let labourChargeMini=item?.data?.labour_charge?.minimum_amount;
        let otherCharge=item?.otherCharge;
        let centerStonePrice=item?.centerstoneprice;
        let markup=item?.mark_up;
        let tagfact=item?.tag_fact;
        let metalWeight=item?.metalweight;
        let grossWeight=item?.grossweight;

        //////////////////////////////////////////////////////////////////////////
        let sideDiamondAmount=0;
        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "sidediamond")
            {
                let sideDiaDisc = customerGroup?.discount;
                let discontPrice = (item?.sidestoneprice * sideDiaDisc)/100;
                console.log("customerGroup",customerGroup);
                console.log("item?.sidestoneprice",item?.sidestoneprice);
                setSideDiaPrice((item?.sidestoneprice - discontPrice));
                sideDiamondAmount = (item?.sidestoneprice - discontPrice);
            }else{
                sideDiamondAmount = item?.sidestoneprice;
                setSideDiaPrice(item?.sidestoneprice);
            }   
        }else{
            sideDiamondAmount = item?.sidestoneprice;
            setSideDiaPrice(item?.sidestoneprice);
        }
        console.log("sideDiamondAmount",sideDiamondAmount);
        let sideStonePrice = sideDiamondAmount;
        console.log("sideStonePrice",sideStonePrice);

        

        let chainWeight = (item?.chainweight != null) ? item?.chainweight : 0;
        // let totalWeight = metalWeight + chainWeight;
        let totalWeight = labourFlag == "gross" ? grossWeight : (metalWeight + chainWeight);
            totalWeight = totalWeight.toFixed(2);
        let finalLabCharge =  labourCharge * totalWeight;
            finalLabCharge = finalLabCharge > labourChargeMini ? finalLabCharge : labourChargeMini;

        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "labour")
            {
                let labourDisc = customerGroup?.discount;
                let discontLabourPrice = (finalLabCharge * labourDisc)/100;
                finalLabCharge=finalLabCharge-discontLabourPrice;
            }
        }
        console.log("labourCharge",finalLabCharge)

        let totalMetalPrice = metalprice + finalLabCharge + otherCharge;
            finalLabCharge =  finalLabCharge?.toFixed(2);
        let totalMFGCost = totalMetalPrice + sideStonePrice;
        console.log("totalMFGCost",totalMFGCost)

        let GST = 0;   
        let finalPrice = 0;     
        if( markup > 0 && tagfact > 0){
            let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);
            let finalTagfact = finalMarkup/tagfact;
                finalPrice = finalTagfact + centerStonePrice;
                GST = finalTagfact - totalMFGCost;
                GST = GST.toFixed(2);
        }else if(markup > 0 && tagfact == 0){
            let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);          
                finalPrice = finalMarkup + centerStonePrice;
                GST = finalMarkup - totalMFGCost;
                GST = GST.toFixed(2);
        }else if(markup == 0 && tagfact > 0){           
            let finalTagfact = totalMFGCost/tagfact;
                finalPrice = finalTagfact + centerStonePrice;
                GST = finalTagfact - totalMFGCost;
                GST = GST.toFixed(2);
        }else{
            finalPrice = totalMFGCost + centerStonePrice;
        }

        let isb2bgetdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        console.log("finalPrice before",finalPrice)
        console.log("globalJewelryMargin",globalJewelryMargin)
        console.log("isb2bgetdata",isb2bgetdata)
        if(globalJewelryMargin > 0 && isb2bgetdata == 0){
            finalPrice = finalPrice + ((finalPrice*globalJewelryMargin)/100);
        }
        console.log("finalPrice",finalPrice)
        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "fulljewelry")
            {
                let finalDisc = customerGroup?.discount;
                let discontfinalPrice = (finalPrice * finalDisc)/100;
                finalPrice=finalPrice-discontfinalPrice;
            }
        }
        
        setFinalGST(GST);
        // setFinalAmount(finalPrice.toFixed(2));
        setSideDiaPrice(sideStonePrice);
        setCenterDiaPrice(centerStonePrice);
        setMetalAmount(metalprice);
        setProdcutMetalPrice(metalprice);
        setLabourtType(labourFlag);
        setLabourtCharge(labourCharge);
        setFinalLabourtCharge(finalLabCharge);
        //////////////////////////////////////////////////////////////////////////

        await axios.post(BASE_URL + GetAPIUrl.CURRENCYCONVERT,{currency_id:defaultCurrency?.id == null ? null : defaultCurrency?.id,amount:finalPrice}, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                console.log("response",response)
                if(response?.data?.success == true)
                {
                    setFinalAmount(response?.data?.amount)
                }  
            }).catch(error => {
            }).finally(() => {
            })
        
        // setFinalAmount(item?.settingprice);
        // setSideDiaPrice(item?.sidestoneprice);
        // setCenterDiaPrice(item?.centerstoneprice);
        // setMetalAmount(item?.metalprice);
        // setProdcutMetalPrice(item?.metalprice);
        // setProdcutMetalWeight(item?.metalweight); 

        let gemstonecount=0;
        let centerstonecount=0;
        let gemstonetotalweight=0;
        let diamondtotalweight=0;
        item?.jewelry_diamonds?.map((itemv, index) => {
            if(itemv?.diamondtype == "Center" && itemv.shape != null && itemv.mincaret != null &&  itemv.maxcaret != null){
                setIsValidChooseDiamond(false)
            }
            if(itemv?.diamondtype == "Center" && itemv?.isgemstone == 1){
                gemstonecount++;
                // gemstonetotalweight=gemstonetotalweight+itemv?.fromsize_name?.paraname;
                gemstonetotalweight=gemstonetotalweight+itemv?.mincaret;
            }
            if(itemv?.diamondtype == "Center" && (itemv?.isgemstone == null || itemv?.isgemstone == "0" || itemv?.isgemstone == 0)){
                centerstonecount++
            // }
            // if(itemv?.diamondtype == "Center" && itemv?.isgemstone == 0){
                diamondtotalweight=diamondtotalweight+itemv?.mincaret;
            }
            if(itemv?.diamondtype == "Side"){
                diamondtotalweight=diamondtotalweight+itemv?.totalweight;
            }
        })
        if(gemstonecount > 0) {setIsGemStone(true); setIsCenterStone(false);}
        if(centerstonecount > 0) {setIsCenterStone(true); setIsGemStone(false);}

        setTotalDiamondWeight(diamondtotalweight)
        setTotalGemStoneWeight(gemstonetotalweight)

        setselectedImage(item?.default)
        resetZoom()
    }

    const selectMetalVariant = async (item, index) => {

        // console.log("item jewelryVariants---------",jewelryVariants);
        // console.log("item varant---------",item);

        let dataTemp = ''
        jewelryVariants?.map((tag, i) => {
            if (tag?.metal_stamp?.paraname == item?.metal_stamp && tag?.metal_type.paraname == item?.metal_type) {
                dataTemp = tag
            }
        })
        if (dataTemp?.gem_color != null) {
            setGemsFromMetal(dataTemp)
            setDefaultVariant(dataTemp?.gem_color?.id)
        }
        setDefaultMetalVariant({
            'metal_stamp': item?.metal_stamp,
            'metal_type': item?.metal_type,
        })
        // setjewelryDetail(dataTemp)
        // setIsLoading(true)
        setIsSkeleton(true);

        console.log("item ------",dataTemp)
        let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;

        await axios.get(BASE_URL + GetAPIUrl.JEWELRYDETAIL_URL + `/${dataTemp.id}` + '?is_b2b=' + getdata+'&currency_id='+currency_id)
            .then(response => {
                if (response.data.success == true) {
                    console.log("dataTempRes response   ==",response)
                    let dataTempRes=response?.data?.data;
                    console.log("dataTempRes",dataTempRes)
                    setjewelryDetail(dataTemp)
                    if(dataTempRes?.category?.name.toLowerCase() != "pendant" || dataTempRes?.category?.name.toLowerCase() != "pendants")
                    {
                        setIsValidChooseDiamond(true);
                    }
                    let metalprice=dataTempRes?.metalprice;
                    // let labourCharge=response?.data?.labourCharge;
                    let labourFlag=response?.data?.data?.labour_charge?.item;
                    let labourCharge=response?.data?.data?.labour_charge?.amount;
                    let labourChargeMini=response?.data?.data?.labour_charge?.minimum_amount;
                    let otherCharge=response?.data?.otherCharge;
                    let centerStonePrice=dataTempRes?.centerstoneprice;
                    let markup=dataTempRes?.mark_up;
                    let tagfact=dataTempRes?.tag_fact;
                    let metalWeight=dataTempRes?.metalweight;
                    let grossWeight=dataTempRes?.grossweight;
            
                    //////////////////////////////////////////////////////////////////////////
                    let sideDiamondAmount=0;
                    if(is_b2b?.is_b2b == 1){
                        if(customerGroup != null && customerGroup?.group_type == "sidediamond")
                        {
                            let sideDiaDisc = customerGroup?.discount;
                            let discontPrice = (dataTempRes?.sidestoneprice * sideDiaDisc)/100;
                            console.log("customerGroup",customerGroup);
                            console.log("item?.sidestoneprice",dataTempRes?.sidestoneprice);
                            setSideDiaPrice((dataTempRes?.sidestoneprice - discontPrice));
                            sideDiamondAmount = (dataTempRes?.sidestoneprice - discontPrice);
                        }else{
                            sideDiamondAmount = dataTempRes?.sidestoneprice;
                            setSideDiaPrice(dataTempRes?.sidestoneprice);
                        }   
                    }else{
                        sideDiamondAmount = dataTempRes?.sidestoneprice;
                        setSideDiaPrice(dataTempRes?.sidestoneprice);
                    }
                    console.log("sideDiamondAmount",sideDiamondAmount);
                    let sideStonePrice = sideDiamondAmount;
                    console.log("sideStonePrice",sideStonePrice);
            
                    
            
                    let chainWeight = (dataTempRes?.chainweight != null) ? dataTempRes?.chainweight : 0;
                    // let totalWeight = metalWeight + chainWeight;
                    let totalWeight = labourFlag == "gross" ? grossWeight : (metalWeight + chainWeight);
                        totalWeight = totalWeight.toFixed(2);
                    let finalLabCharge =  labourCharge * totalWeight;
                        finalLabCharge = finalLabCharge > labourChargeMini ? finalLabCharge : labourChargeMini;
                        // console.log('heeelo Labor',typeof labourChargeMini == "undefined")
            
                    if(is_b2b?.is_b2b == 1){
                        if(customerGroup != null && customerGroup?.group_type == "labour")
                        {
                            let labourDisc = customerGroup?.discount;
                            let discontLabourPrice = (finalLabCharge * labourDisc)/100;
                            finalLabCharge=finalLabCharge-discontLabourPrice;
                        }
                    }
                    console.log("labourCharge",finalLabCharge)
            
                    let totalMetalPrice = metalprice + finalLabCharge + otherCharge;
                        finalLabCharge =  finalLabCharge?.toFixed(2);
                    let totalMFGCost = totalMetalPrice + sideStonePrice;
                    console.log("totalMFGCost",totalMFGCost)
            
                    let GST = 0;   
                    let finalPrice = 0;     
                    if( markup > 0 && tagfact > 0){
                        let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);
                        let finalTagfact = finalMarkup/tagfact;
                            finalPrice = finalTagfact + centerStonePrice;
                            GST = finalTagfact - totalMFGCost;
                            GST = GST.toFixed(2);
                    }else if(markup > 0 && tagfact == 0){
                        let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);          
                            finalPrice = finalMarkup + centerStonePrice;
                            GST = finalMarkup - totalMFGCost;
                            GST = GST.toFixed(2);
                    }else if(markup == 0 && tagfact > 0){           
                        let finalTagfact = totalMFGCost/tagfact;
                            finalPrice = finalTagfact + centerStonePrice;
                            GST = finalTagfact - totalMFGCost;
                            GST = GST.toFixed(2);
                    }else{
                        finalPrice = totalMFGCost + centerStonePrice;
                    }
            
                    let isb2bgetdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
                    console.log("finalPrice before",finalPrice)
                    console.log("globalJewelryMargin",globalJewelryMargin)
                    console.log("isb2bgetdata",isb2bgetdata)
                    if(globalJewelryMargin > 0 && isb2bgetdata == 0){
                        finalPrice = finalPrice + ((finalPrice*globalJewelryMargin)/100);
                    }
                    console.log("finalPrice",finalPrice)
                    if(is_b2b?.is_b2b == 1){
                        if(customerGroup != null && customerGroup?.group_type == "fulljewelry")
                        {
                            let finalDisc = customerGroup?.discount;
                            let discontfinalPrice = (finalPrice * finalDisc)/100;
                            finalPrice=finalPrice-discontfinalPrice;
                        }
                    }
                    
                    setFinalGST(GST);
                    convertCurrency(finalPrice.toFixed(2))
                    // setFinalAmount(finalPrice.toFixed(2));
                    setSideDiaPrice(sideStonePrice);
                    setCenterDiaPrice(centerStonePrice);
                    setMetalAmount(metalprice);
                    setProdcutMetalPrice(metalprice);
                    setLabourtType(labourFlag);
                    setLabourtCharge(labourCharge);
                    setFinalLabourtCharge(finalLabCharge);
                    //////////////////////////////////////////////////////////////////////////
                    setSideStoneColorClarityChange(false);
                    setSideDiaGrpId(dataTempRes?.sideDiaColGrp);
                    setSideDiaData(dataTempRes?.sideStone); 

                    // handleStoneGrp()
                }
            }).finally(() => {
                // setIsLoading(false)
                setIsSkeleton(false);
            });

        

        // setFinalAmount(dataTemp?.settingprice);
        // setSideDiaPrice(dataTemp?.sidestoneprice);
        // setCenterDiaPrice(dataTemp?.centerstoneprice);
        // setMetalAmount(dataTemp?.metalprice);
        // setProdcutMetalPrice(dataTemp?.metalprice);
        // setProdcutMetalWeight(dataTemp?.metalweight); 

        let gemstonecount=0;
        let centerstonecount=0;
        let diamondtotalweight=0;
        let gemstonetotalweight=0;
        dataTemp?.jewelry_diamonds?.map((item, index) => {
            if(item?.diamondtype == "Center" && item.shape != null && item.mincaret != null &&  item.maxcaret != null){
                setIsValidChooseDiamond(true)
            }
            if(item?.diamondtype == "Center" && item?.isgemstone == 1){
                gemstonecount++;
                // gemstonetotalweight=gemstonetotalweight+item?.fromsize_name?.paraname;
                gemstonetotalweight=gemstonetotalweight+item?.mincaret;
            }
            if(item?.diamondtype == "Center" && (item?.isgemstone == null || item?.isgemstone == "0" || item?.isgemstone == 0)){
                centerstonecount++
            // }
            // if(item?.diamondtype == "Center"){
                diamondtotalweight=diamondtotalweight+item?.mincaret;
            }
            if(item?.diamondtype == "Side"){
                diamondtotalweight=diamondtotalweight+item?.totalweight;
            }
        })
        if(gemstonecount > 0) {setIsGemStone(true); setIsCenterStone(false);}
        if(centerstonecount > 0) {setIsCenterStone(true); setIsGemStone(false);}

        setTotalDiamondWeight(diamondtotalweight)
        setTotalGemStoneWeight(gemstonetotalweight)

        //commented below line
        setjewelryVariants(jewelryVariants)

        setselectedImage(dataTemp?.default)
        
        // console.log("dataTemp",dataTemp)
        if(dataTemp?.producttype == 77){
            //semifinish
            window.history.replaceState(null, "New Page Title", DiamondId == null ? process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${dataTemp?.id}/${dataTemp?.slug}?jid=${dataTemp?.id}` : process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${dataTemp?.id}/${dataTemp?.slug}?jid=${dataTemp?.id}&did=${DiamondId}`)
        }else{
            //finish
            window.history.replaceState(null, "New Page Title", DiamondId == null ? process.env.PUBLIC_URL + `/JDetails/${dataTemp?.id}/${dataTemp?.slug}?jid=${dataTemp?.id}` : process.env.PUBLIC_URL + `/JDetails/${dataTemp?.id}/${dataTemp?.slug}?jid=${dataTemp?.id}&did=${DiamondId}`)
        }
        
        setUrls(dataTemp?.id)
        resetZoom()
        // handleStoneGrp()
    }

    const setGemsFromMetal = (item) => {
        let getdata = jewelryVariants?.filter((tag) => {
            if (tag.metal_stamp.paraname == item.metal_stamp.paraname) {
                return tag.gem_color
            }
        })
        setArrayUniqueByKey([...new Map(getdata?.map(item =>
            [item?.gem_color[key], item])).values()])
    }
    const backgroundcolor=isVideoLoading ? "black" : "white";
    const handleIframeLoad = (e) => {
        // console.log('rrrrr',e)
        setIsVideoLoading(false);
        document.getElementsByClassName('')
      };    

      function removeTags(str) {
        if ((str===null) || (str===''))
            return false;
        else
            str = str.toString();
        return str.replace( /(<([^>]+)>)/ig, '');
    }

    useEffect(() => {
        getGlobalSettings();
        
        if (DiamondId != null) {
            CallDiamondDetailApi()
        }
        if (token != null) {
            CallCartCountApi()
        }
        getStoneGroupList();
        // handleStoneGrp();
    }, [])
    // useEffect(() => {
    //     getGlobalSettings();
    // }, [globalJewelryMargin,jewelryDetail])

    
    const getGlobalSettings = async () => {   
        setIsLoading(true)
        await axios.post(BASE_URL + GetAPIUrl.GLOBALMARKUPTAG_URL, null)
            .then(response => {
                if(response?.data?.type == "success")
                {
                    if(response?.data?.data.length > 0 )
                    {
                        setGlobalMarkup(response?.data?.data[0].side_stone_mark_up);
                        setglobalTagFact(response?.data?.data[0].side_stone_tag_fact);
                        setGlobalJewelryMargin(response?.data?.data[0].b2c_margin_rate);
                        console.log("0000000000000000000000000000000000000000",response?.data?.data[0].b2c_margin_rate)
                    }
                    CalljewelryDetailApi(response?.data?.data[0].b2c_margin_rate);
                }  
            }).catch(error => {
            }).finally(() => {
                // setIsLoading(false)
            })
    }

    

    const CallCartCountApi = async () => {
        const controller = new AbortController();

        await axios.get(BASE_URL + GetAPIUrl.CARTWISHLISTCOUNT_URL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                
                if (response.data.success == true) {
                    setWishlist(response?.data?.wishlist?.jewelry_ids)
                    localStorage.setItem('wishlistcount', JSON.stringify(response.data.wishlist.count))
                    localStorage.setItem('cartcount', JSON.stringify(response.data.cart.count))
                    $(function () {
                        $('.addtocartCount').html(JSON.stringify(response.data.cart.count));
                        $('.addtowishlistCount').html(JSON.stringify(response.data.wishlist.count));
                    })
                } else {
                    localStorage.setItem('wishlistcount', JSON.stringify(0))
                    localStorage.setItem('cartcount', JSON.stringify(0))
                }
            }).catch(error => {
            }).finally(() => {

            })
        controller.abort()
    }

    const CalljewelryDetailApi = async (globalmargin) => {
        const controller = new AbortController();
        let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;

        setIsLoading(true)
        await axios.get(BASE_URL + GetAPIUrl.JEWELRYDETAIL_URL + `/${JewelryId}` + '?is_b2b=' + getdata+'&currency_id='+currency_id)
            .then(response => {
                if (response.data.success == true) {
                    setRecommended(response?.data?.data?.recommended)
                    setSimilar(response?.data?.data?.similar)
                    setselectedImage(response?.data?.data?.default)
                    // console.log("aasdasdasdf",response?.data?.data)
                    setjewelryDetail(response?.data?.data);
                    setSideDiaGrpId(response?.data?.data?.sideDiaColGrp);
                    localStorage.setItem("sideDiaColGrp", response?.data?.data?.sideDiaColGrp);
                    console.log('Navin REsponse-->',response.data);
                    let sidestoneprice=response?.data?.data?.sidestoneprice;
                    let settingprice=response?.data?.data?.settingprice;
                    let metalprice=response?.data?.data?.metalprice;
                    // let labourCharge=response?.data?.labourCharge;
                    let labourFlag=response?.data?.data?.labour_charge?.item;
                    let labourCharge=response?.data?.data?.labour_charge?.amount;
                    let labourChargeMini=response?.data?.data?.labour_charge?.minimum_amount;
                    let otherCharge=response?.data?.otherCharge;
                    let centerStonePrice=response?.data?.data?.centerstoneprice;
                    let markup=response?.data?.data?.mark_up;
                    let tagfact=response?.data?.data?.tag_fact;
                    let metalWeight=response?.data?.data?.metalweight;
                    let grossWeight=response?.data?.data?.grossweight;

                    //////////////////////////////////////////////////////////////////////////
                    let sideDiamondAmount=0;
                    if(is_b2b?.is_b2b == 1){
                        if(customerGroup != null && customerGroup?.group_type == "sidediamond")
                        {
                            let sideDiaDisc = customerGroup?.discount;
                            let discontPrice = (response?.data?.data?.sidestoneprice * sideDiaDisc)/100;
                            console.log("customerGroup",customerGroup);
                            console.log("response?.data?.data?.sidestoneprice",response?.data?.data?.sidestoneprice);
                            setSideDiaPrice((response?.data?.data?.sidestoneprice - discontPrice));
                            sideDiamondAmount = (response?.data?.data?.sidestoneprice - discontPrice);
                        }else{
                            sideDiamondAmount = response?.data?.data?.sidestoneprice;
                            setSideDiaPrice(response?.data?.data?.sidestoneprice);
                        }   
                    }else{
                        sideDiamondAmount = response?.data?.data?.sidestoneprice;
                        setSideDiaPrice(response?.data?.data?.sidestoneprice);
                    }
                    console.log("sideDiamondAmount",sideDiamondAmount);
                    let sideStonePrice = sideDiamondAmount;
                    console.log("sideStonePrice",sideStonePrice);

                    let chainWeight = (response?.data?.data?.chainweight != null) ? response?.data?.data?.chainweight : 0;
                    // let totalWeight = metalWeight + chainWeight;
                    let totalWeight = labourFlag == "gross" ? grossWeight : (metalWeight + chainWeight);

                        totalWeight = totalWeight.toFixed(2);

                    let labCharge =  labourCharge * totalWeight;

                    // let finalLabCharge =  labourCharge * totalWeight;
                    let finalLabCharge = labCharge > labourChargeMini ? labCharge : labourChargeMini;
                    if(is_b2b?.is_b2b == 1){
                        if(customerGroup != null && customerGroup?.group_type == "labour")
                        {
                            let labourDisc = customerGroup?.discount;
                            let discontLabourPrice = (finalLabCharge * labourDisc)/100;
                            finalLabCharge=finalLabCharge-discontLabourPrice;
                        }
                    }
                    console.log("labourCharge",finalLabCharge)

                    let totalMetalPrice = metalprice + finalLabCharge + otherCharge;
                        finalLabCharge =  finalLabCharge.toFixed(2);
                    let totalMFGCost = totalMetalPrice + sideStonePrice;
                    console.log("totalMFGCost",totalMFGCost)
                    console.log("centerstonePric",centerStonePrice)

                    let GST = 0;   
                    let finalPrice = 0;     
                    if( markup > 0 && tagfact > 0){
                        let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);
                        let finalTagfact = finalMarkup/tagfact;
                            finalPrice = finalTagfact + centerStonePrice;
                            GST = finalTagfact - totalMFGCost;
                            GST = GST.toFixed(2);
                    }else if(markup > 0 && tagfact == 0){
                        let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);          
                            finalPrice = finalMarkup + centerStonePrice;
                            GST = finalMarkup - totalMFGCost;
                            GST = GST.toFixed(2);
                    }else if(markup == 0 && tagfact > 0){           
                        let finalTagfact = totalMFGCost/tagfact;
                            finalPrice = finalTagfact + centerStonePrice;
                            GST = finalTagfact - totalMFGCost;
                            GST = GST.toFixed(2);
                    }else{
                        finalPrice = totalMFGCost + centerStonePrice;
                    }

                    let isb2bgetdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
                    console.log("finalPrice before",finalPrice)
                    console.log("globalJewelryMargin",globalJewelryMargin)
                    console.log("globalmargin",globalmargin)
                    console.log("isb2bgetdata",isb2bgetdata)
                    if(globalmargin > 0 && isb2bgetdata == 0){
                        finalPrice = finalPrice + ((finalPrice*globalmargin)/100);
                    }
                    console.log("finalPrice",finalPrice)
                    if(is_b2b?.is_b2b == 1){
                        if(customerGroup != null && customerGroup?.group_type == "fulljewelry")
                        {
                            let finalDisc = customerGroup?.discount;
                            let discontfinalPrice = (finalPrice * finalDisc)/100;
                            finalPrice=finalPrice-discontfinalPrice;
                        }
                    }
                    
                    setFinalGST(GST);
                    convertCurrency(finalPrice.toFixed(2))
                    // setFinalAmount(finalPrice.toFixed(2));
                    setSideDiaPrice(sideStonePrice);
                    setCenterDiaPrice(centerStonePrice);
                    setMetalAmount(metalprice);
                    setProdcutMetalPrice(metalprice);
                    setLabourtType(labourFlag);
                    setLabourtCharge(labourCharge);
                    setFinalLabourtCharge(finalLabCharge);
                    //////////////////////////////////////////////////////////////////////////

                    // if(is_b2b?.is_b2b == 1){
                    //     if(customerGroup != null && customerGroup?.jewellDiscType == "sidediamond")
                    //     {
                    //         let sideDiaDisc = customerGroup?.discount;
                    //         let discontPrice = (response?.data?.data?.sidestoneprice * sideDiaDisc)/100;
                    //         setSideDiaPrice((response?.data?.data?.sidestoneprice - discontPrice));
                    //     }else{
                    //         setSideDiaPrice(response?.data?.data?.sidestoneprice);
                    //     }   
                    // }
                    
                    // setSideDiaPrice(response?.data?.data?.sidestoneprice);
                    // setFinalAmount(response?.data?.data?.settingprice);
                    
                    // setCenterDiaPrice(response?.data?.data?.centerstoneprice);
                    // setMetalAmount(response?.data?.data?.metalprice);
                    // setProdcutMetalPrice(response?.data?.data?.metalprice);
                    setProdcutMetalWeight(response?.data?.data?.metalweight); 

                    // setLabourtCharge(response?.data?.labourCharge);
                    setOtherCharge(response?.data?.otherCharge);

                    setjewelryVariants(response?.data?.data?.variants);
                    setRingSize(response?.data?.data?.ringSize);
                    setRingSizeHelp(response?.data?.data?.ringSizeFile);
                    setJewerlryProductType(response?.data?.data?.producttype);
                    setBreadCrumpJewelSku(response?.data?.data?.itemcode);
                    setBreadCrumpCat(response?.data?.data?.category?.name);

                    setRingSizeAvailable(response?.data?.data?.jewelry_ring_sizes);
                    // console.log(response?.data?.data?.jewelry_ring_sizes);


                    setDefaultVariant(response?.data?.data?.gem_color != null ? response?.data?.data?.gem_color?.id : '')

                    setDefaultMetalVariant({
                        'metal_stamp': response?.data?.data?.metal_stamp?.paraname,
                        'metal_type': response?.data?.data?.metal_type?.paraname,
                    })
                    // let data = ''
                    const unique = response?.data?.data?.variants?.map((item) =>
                        item.metal_type.paraname + '-' + item.metal_stamp.paraname

                    ).filter((value, index, self) => self.indexOf(value) === index);

                    const datas = [];
                    unique?.map((item) =>
                        datas.push({
                            'metal_type': item.split(/-/)[0],
                            'metal_stamp': item.split(/-/)[1]

                        })
                    );

                    setArrayUniqueMetalKey(datas)
                    let getdata = response?.data?.data?.gem_color != null ?
                        response?.data?.data?.variants?.filter((tag) => {
                            if (tag.metal_stamp.paraname == response?.data?.data?.metal_stamp.paraname) {
                                return tag.gem_color
                            }
                        })
                        : []

                    setArrayUniqueByKey(response?.data?.data?.gem_color != null ? [...new Map(getdata?.map(item =>
                        [item?.gem_color[key], item])).values()] : [])

                        let gemstonecount=0;
                        let centerstonecount=0;

                        if(response?.data?.data?.category?.name.toLowerCase() == "pendant" 
                            || response?.data?.data?.category?.name.toLowerCase() == "pendants"){
                                setOnlySetting(1)
                            }else{
                                setIsValidChooseDiamond(true);
                                if(onlysetting != 1) // iif alreday onlysetting then we not need to chnage with 0
                                {
                                    setOnlySetting(0)
                                }
                            }
                            // console.log("isOnlySetting",isOnlySetting)
                            let gemstonetotalweight=0;
                            let diamondtotalweight=0;
                    response?.data?.data?.jewelry_diamonds?.map((item, index) => {
                        if(item?.diamondtype == "Center" && item.shape != null &&  item.mincaret != null &&  item.maxcaret != null){
                            setIsValidChooseDiamond(true)
                        }

                        if(item?.diamondtype == "Center" && item?.isgemstone == 1){
                            gemstonecount++;
                            // gemstonetotalweight=gemstonetotalweight+item?.fromsize_name?.paraname;
                            gemstonetotalweight=gemstonetotalweight+item?.mincaret;
                        }
                        if(item?.diamondtype == "Center" && (item?.isgemstone == null || item?.isgemstone == "0" || item?.isgemstone == 0)){
                            centerstonecount++
                            diamondtotalweight=diamondtotalweight+item?.mincaret;
                        }
                        if (item?.diamondtype == 'Center') {
                            setData({
                                type: item?.shape == null ? '' : item?.shape,
                                mincaret: item?.mincaret == null ? '' : item?.mincaret,
                                maxcaret: item?.maxcaret == null ? '' : item?.maxcaret,
                            });
                        }
                        else {
                            if (item?.diamondtype == 'Side') {
                                setData({
                                    type: response?.data?.data?.jewelry_diamonds[0]?.shape == null ? '' : response?.data?.data?.jewelry_diamonds[0]?.shape,
                                    mincaret: response?.data?.data?.jewelry_diamonds[0]?.mincaret == null ? '' : response?.data?.data?.jewelry_diamonds[0]?.mincaret,
                                    maxcaret: response?.data?.data?.jewelry_diamonds[0]?.maxcaret == null ? '' : response?.data?.data?.jewelry_diamonds[0]?.maxcaret,
                                });
                                diamondtotalweight=diamondtotalweight+item?.totalweight;
                            }
                        }

                    })
                    if(gemstonecount > 0) {setIsGemStone(true); setIsCenterStone(false);}
                    if(centerstonecount > 0) {setIsCenterStone(true); setIsGemStone(false);}

                    setTotalDiamondWeight(diamondtotalweight)
                    setTotalGemStoneWeight(gemstonetotalweight)

                } else {
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)
            }).finally(() => {
                setIsLoading(false)
            })
        controller.abort()
    }

    const CallDiamondDetailApi = async () => {
        setIsLoading(true)
        let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;
        const controller = new AbortController();

        axios.get(BASE_URL + GetAPIUrl.DIAMONDDETAIL_URL + `/${DiamondId}`+ '?is_b2b=' + getdata+'&currency_id='+currency_id)
            .then(response => {
                if (response.data.success == true) {
                    setDiamondDetail(response.data.data);
                    // setSelecteImage(response.data.data.images[0])
                } else {
                    ShowErrorMessage(response.data.message)
                }
            }).catch(error => {
                ShowErrorMessage(error.message)
            }).finally(() => {
                setIsLoading(false)
            })
        controller.abort()
    }

    



    const Cartvalidation = () => {
        if(jewelryDetail?.category?.name.toLowerCase() == "ring" 
        || jewelryDetail?.category?.name.toLowerCase() == "rings"
        || jewelryDetail?.category?.name.toLowerCase() == "engagement rings"
        || jewelryDetail?.category?.name.toLowerCase() == "engagement ring"
        || jewelryDetail?.category?.name.toLowerCase() == "wedding ring"
        || jewelryDetail?.category?.name.toLowerCase() == "wedding rings"
        || jewelryDetail?.category?.name.toLowerCase() == "chains"
        || jewelryDetail?.category?.name.toLowerCase() == "chain"
        || jewelryDetail?.category?.name.toLowerCase() == "bracelet"
        || jewelryDetail?.category?.name.toLowerCase() == "bracelets"
        )
        {
            // console.log('in if.............................')
            if (SelectedringSize == '') {
                setmessage(true)
    
            } else {
                setmessage(false)
                CallAddtoCart()
            }
            // if (SelectedringSize != '') {
            //     CallAddtoCart()
            // }
            // else{
            //     CallAddtoCart()
        // }
        }else{
            // console.log('in else.............................')
            CallAddtoCart()
        }
        
    }
    const CallAddtoCart = async () => {
        setIsCartLoading(true)
        const controller = new AbortController();

        var form = new FormData();
        form.append("jewelryid", JewelryId == null ? "" : JewelryId);
        form.append("diamondid", DiamondId == null ? "" : DiamondId);
        form.append("ringsize", SelectedringSize.id != undefined ? SelectedringSize.id : null)
        form.append('qty', countQty)
        form.append('centerStonePrice', centerDiaPrice)
        form.append('sideDiaGrpId', sideDiaGrpId == null ? null : sideDiaGrpId)
        form.append('settingPrice', finalAmount)
        form.append('currency_id', defaultCurrency?.id == null ? null : defaultCurrency?.id)

        // const result = await fetch2(BASE_URL + GetAPIUrl.ADDTOCART_URL, form, token)
        // console.log("add to cart result",result)
        // //message
        // if (result.success == true) {
        //     ShowMessage(result.data.message)
        //     CallCartCountApi()
        //     setmessage(false)
        //     setIsCartLoading(true)
        // }else{
        //     setIsCartLoading(false)
        //     ShowErrorMessage(result.data.message)
        // }
    await axios.post(BASE_URL + GetAPIUrl.ADDTOCART_URL, form, {
            headers: {
                // 'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    ShowMessage(response.data.message)
                    CallCartCountApi()
                    setmessage(false)
                    setIsCartLoading(true)
                    // window.location.pathname = '/Cart'
                } else {
                    setIsCartLoading(false)
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)
            }).finally(() => {
                setIsCartLoading(false)
            })
        controller.abort()
    }

    const validation = () => {
        if(jewelryDetail?.category?.name.toLowerCase() == "ring" 
        || jewelryDetail?.category?.name.toLowerCase() == "rings"
        || jewelryDetail?.category?.name.toLowerCase() == "engagement rings"
        || jewelryDetail?.category?.name.toLowerCase() == "engagement ring"
        || jewelryDetail?.category?.name.toLowerCase() == "wedding ring"
        || jewelryDetail?.category?.name.toLowerCase() == "wedding rings"
        || jewelryDetail?.category?.name.toLowerCase() == "chains"
        || jewelryDetail?.category?.name.toLowerCase() == "chain"
        || jewelryDetail?.category?.name.toLowerCase() == "bracelet"
        || jewelryDetail?.category?.name.toLowerCase() == "bracelets"
        )
        {
            // console.log('in if.............................')
            if (SelectedringSize == '') {
                setmessage(true)
    
            } else {
                setmessage(false)
                CallAddtoWishlistApi(jewelryDetail)
            }
            // if (SelectedringSize != '') {
    
            //     CallAddtoWishlistApi(jewelryDetail)
            // }else{
            //     CallAddtoWishlistApi(jewelryDetail)
            // }
        }else{
            // console.log('in else.............................')
            CallAddtoWishlistApi(jewelryDetail)
        }
        
    }
    const confirmRemovejewelry = () => {

        confirmRemove("Are you Sure","You want to remove item from wishlist?").then((result) => {
            if (result == true) {
                CallDeletejewelry()

            }
        }

        )
    }
    const CallDeletejewelry = async () => {
        
        const controller = new AbortController();
        var form = new FormData();

        form.append("id", "");
        form.append("jewelryid", jewelryDetail?.id);
        form.append("diamondid", "");

        // console.log("fff", [...form]);
        await axios.post(BASE_URL + GetAPIUrl.DELETEWISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {

                    // ShowMessage(response.data.message)
                    CallCartCountApi()
                } else {

                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)

            });
        controller.abort()
    }


    const ChooseDiamondvalidation = () => {
        let diaType = isByWithLab ? 'Lab' : 'Natural';
        if(jewelryDetail?.category?.name.toLowerCase() == "ring" 
        || jewelryDetail?.category?.name.toLowerCase() == "rings"
        || jewelryDetail?.category?.name.toLowerCase() == "engagement rings"
        || jewelryDetail?.category?.name.toLowerCase() == "engagement ring"
        || jewelryDetail?.category?.name.toLowerCase() == "wedding ring"
        || jewelryDetail?.category?.name.toLowerCase() == "wedding rings"
        || jewelryDetail?.category?.name.toLowerCase() == "chains"
        || jewelryDetail?.category?.name.toLowerCase() == "chain"
        || jewelryDetail?.category?.name.toLowerCase() == "bracelet"
        || jewelryDetail?.category?.name.toLowerCase() == "bracelets"
        )
        {
            
            if (SelectedringSize == '') {
                setmessage(true)
            }else{
                let str = process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${urls}/${diaType}?jid=${urls}&famount=${finalAmount}`
                    str = str + `&ring=${SelectedringSize.id}`
                if (data.type != '') {
                    str = str + `&shape=${data.type}`
                }
                if (data.mincaret != '') {
                    str = str + `&mincarat=${data.mincaret}`
                }
                if (data.maxcaret != '') {
                    str = str + `&maxcarat=${data.maxcaret}`
                }
                window.location.href = str;
            }
            
        }else{
            let str = process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own?jid=${urls}&famount=${finalAmount}`
            if (SelectedringSize != '') {
                str = str + `&ring=${SelectedringSize.id}`
            }
            if (data.type != '') {
                str = str + `&shape=${data.type}`
            }
            if (data.mincaret != '') {
                str = str + `&mincarat=${data.mincaret}`
            }
            if (data.maxcaret != '') {
                str = str + `&maxcarat=${data.maxcaret}`
            }
            window.location.href = str;
        }
        // process.env.PUBLIC_URL + `/DiamondList/${utf8_to_b64(type)}/${utf8_to_b64(mincaret)}/${utf8_to_b64(maxcaret)}/${tag?.jewelryData?.title}`
        // if (SelectedringSize == '') {
        //     setmessage(true)

        // } else {
        //     setmessage(false)
        // }
        // if (SelectedringSize != '') {
        //     let str = process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own?jid=${urls}&ring=${SelectedringSize?.id}`
        //     if (data.type != '') {
        //         str = str + `&shape=${data.type}`
        //     } else if (data.mincaret != '') {
        //         str = str + `&mincarat=${data.mincaret}`
        //     } else if (data.maxcaret != '') {
        //         str = str + `&maxcarat=${data.maxcaret}`
        //     }
        //     window.location.href = str;
        // }else{
        //     let str = process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own?jid=${urls}`
        //     if (data.type != '') {
        //         str = str + `&shape=${data.type}`
        //     } else if (data.mincaret != '') {
        //         str = str + `&mincarat=${data.mincaret}`
        //     } else if (data.maxcaret != '') {
        //         str = str + `&maxcarat=${data.maxcaret}`
        //     }
        //     window.location.href = str;
        // }
    }


    const CallAddtoWishlistApi = async (JewelryData) => {

        const controller = new AbortController();
        setIsWishlistLoading(true)

        var form = new FormData();
        
        form.append("jewelryid", JewelryData?.id == null ? "" : JewelryData?.id);
        form.append("diamondid", DiamondId == null ? "" : DiamondId);
        form.append("ringsize", SelectedringSize.id != undefined ? SelectedringSize.id : "")
        form.append('qty', countQty)
        form.append('centerStonePrice', centerDiaPrice)
        form.append('sideDiaGrpId', localStorage.getItem('sideDiaColGrp'))
        form.append('settingPrice', finalAmount)
        form.append('currency_id', defaultCurrency?.id == null ? null : defaultCurrency?.id)

        await axios.post(BASE_URL + GetAPIUrl.WISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    setIsWishlistLoading(false)
                    // ShowMessage(response.data.message)
                    CallCartCountApi()
                    setmessage(false)
                    // window.location.pathname = 'Wishlist'
                } else {
                    setIsWishlistLoading(false)
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                console.error('There was an error!', error);
                setIsWishlistLoading(false)
                ShowErrorMessage(error.message)
            });
        controller.abort()

    }
    const resetZoom = () => {
        var $easyzoom = $('.easyzoom').easyZoom();
        var api1 = $easyzoom.filter('.easyzoom--with-thumbnails').data('easyZoom');
        var $this = $('.thumbnails .active-bor-z a');
        // api1.swap($this.data('standard'), $this.attr('href'));
    }
    $(function () {
        $('.f-click').on({
            'click': function () {
                // $('.change-image').attr('src','images/product/p1.png');
                $(this).parent().closest('.find-img').find('.change-image').attr('src', $(this).data('src'));
                // $(this).toggleClass('active-1');
                $(this).addClass("active-1").siblings().removeClass("active-1");
            }
        });

        // // Instantiate EasyZoom instances
        // var $easyzoom = $('.easyzoom').easyZoom();
        // // Setup thumbnails example
        // var api1 = $easyzoom.filter('.easyzoom--with-thumbnails').data('easyZoom');

        // $('.thumbnails').on('click', 'a', function (e) {
        //     var $this = $(this);
        //     e.preventDefault();
        //     // Use EasyZoom's `swap` method
        //     api1.swap($this.data('standard'), $this.attr('href'));
        // });
        // // Setup toggles example
        // var api2 = $easyzoom.filter('.easyzoom--with-toggle').data('easyZoom');

        // $('.toggle').on('click', function () {
        //     var $this = $(this);

        //     if ($this.data("active") === true) {
        //         $this.text("Switch on").data("active", false);
        //         api2.teardown();
        //     } else {
        //         $this.text("Switch off").data("active", true);
        //         api2._init();
        //     }
        // });

        $(".add-active li").click(function () {
            $("li").removeClass("active-bor-z");
            $(this).addClass("active-bor-z");
        });
        $(window).scroll(function () {
            var pos = $(window).scrollTop();
            if (pos >= 100) {
                var explorefix = $('.myHeader').css("height");
                $(".thumbsider").css({
                    position: 'sticky',
                });
                $('.thumbsider').css("top", parseInt(explorefix) + 10);
            }
            else {
                $(".thumbsider").css({
                    position: 'inherit',
                    top: '0px'
                });

            }
        })
    });

    $(function () {
        $(".click-tab-li2").click(function () {
            $(".click-tab-li2").removeClass("activelitab2");
            $(this).addClass("activelitab2");
            var datact = $(this).data('value');
            $(".main-div2").hide();
            $("#" + datact).show();
        });
    });

    const handleRingsize = (e) => {
        const { name, value } = e.target
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option = el.getAttribute('id');

        setSelectedRingSize({ id: option, value: value })
        setCountQty(1);
    }
    var FileSaver = require('file-saver');

    const openRingsizehelp = () => {
        FileSaver.saveAs(ringsizehelp, 'application/pdf');
        // setLgShow(true)
    }



    // Create handleIncrement event handler
    const handleIncrement = () => {
        if (countQty < 99) {

            setCountQty(prevCount => prevCount + 1);
        }
        // console.log("handleIncrement",SelectedringSize);
        // console.log("JewerlryProductType++",jewerlryProductType);
        setRingSizeQty(true);
        if(jewerlryProductType == 76 && SelectedringSize){
            var validQty=0;
            let newArray = ringSizeAvailable.filter(function (el){
                                                        return el.ringsize == SelectedringSize.id;
                                                    });
            if(newArray.length > 0){
                validQty=newArray[0]?.qty;
            }
            // console.log("countQty",countQty);
            // console.log("validQty",validQty);
            if((countQty+1) > validQty){
                setRingSizeQty(false);
            }
        }
    };

    //Create handleDecrement event handler
    const handleDecrement = () => {
        if (countQty > 1) {
            setCountQty(prevCount => prevCount - 1);
        }
        // console.log("handleDecrement",SelectedringSize);
        // console.log("JewerlryProductType--",jewerlryProductType);
        setRingSizeQty(true);
        if(jewerlryProductType == 76 && SelectedringSize){
            var validQty=0;
            let newArray = ringSizeAvailable.filter(function (el){
                                                        return el.ringsize == SelectedringSize.id;
                                                    });
            if(newArray.length > 0){
                validQty=newArray[0]?.qty;
            }
            // console.log("countQty",countQty);
            // console.log("validQty",validQty);
            if((countQty-1) > validQty){
                setRingSizeQty(false);
            }
        }
    };

    const handleOnChange = (event) => {
        let data = JSON.parse(event.target.value)
        if (data > 100) {
            setCountQty(99)
        } else if (data < 0) {
            setCountQty(1)

        } else {
            setCountQty(data)
        }
    };

    // console.log(is_b2b?.is_b2b,is_b2b)
    // let sum = parseFloat(jewelryDetail?.settingprice) + parseFloat(DiamondDetail?.diamondprice)
    // if(is_b2b?.is_b2b == 0)
    // {
    //     let sum = parseFloat(jewelryDetail?.b2c_price) + parseFloat(DiamondDetail?.diamondprice)
    //     // console.log("in if")
    // }else{
    //     let sum = parseFloat(jewelryDetail?.settingprice) + parseFloat(DiamondDetail?.diamondprice)
    //     // console.log("in else")
    // }
    let sum = parseFloat(finalAmount) + parseFloat(DiamondDetail?.diamondprice)
    if(is_b2b?.is_b2b == 0)
    {
        let sum = parseFloat(finalAmount) + parseFloat(DiamondDetail?.diamondprice)
        // console.log("in if")
    }else{
        let sum = parseFloat(finalAmount) + parseFloat(DiamondDetail?.diamondprice)
        // console.log("in else")
    }

    const [backPostion, setBackPostion] = useState({
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: '0% 0%'
    });
    
      const handleZoomImg = e => {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        setBackPostion(
          {
            backgroundImage: `url(${imageUrl})`,
            backgroundPosition: `${x}% ${y}%`
          }
        )
      }
    
      const handleZoomImgOut = e => {
        setBackPostion({
          backgroundImage: ``,
          backgroundPosition: '0% 0%'
        })
      }

      useEffect(()=>{
        setImageUrl(jewelryDetail?.default?.feature);
      },[jewelryDetail])

    return (
        <div>
            {IsLoading || IsWishlistLoading || IsCartLoading ?
                <LoadingSpinner />
                :
                <>
                    <div>
                        <div>
                            <div className="container container-main">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb" style={{fontSize:"14px",paddingBottom:0}}>
                                        <li className="breadcrumb-item">
                                            <a href={process.env.PUBLIC_URL + '/'} className='desk-top-title-sm a-taglink'><i className="fa fa-home" aria-hidden="true"></i></a>
                                        </li>
                                        {/* {
                                            buildYourOwn == true ?
                                        <li className="breadcrumb-item active" aria-current="page">
                                        <a href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${jewelryDetail?.category?.name}?cid=${jewelryDetail?.category?.id}`}>
                                            Jewelry
                                            </a>
                                            </li> 
                                            : 
                                            null
                                            // <li className="breadcrumb-item active" aria-current="page">
                                            // <a href={process.env.PUBLIC_URL + `/Jewelry`}>
                                            // Jewelry
                                            // </a>
                                            // </li>
                                            } */}
                                        {
                                            ((buildYourOwn == true || jewelryDetail?.producttype == 77)) ?
                                              <li className="breadcrumb-item active" aria-current="page">
                                                <a href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${jewelryDetail?.category?.name}?cid=${jewelryDetail?.category?.id}`} className='desk-top-title-sm a-taglink'>
                                                Build Your Own
                                                </a>
                                              </li> 
                                              : (jewelryDetail?.producttype == 77) ?
                                              <li className="breadcrumb-item active" aria-current="page">
                                                <a href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${jewelryDetail?.category?.name}?cid=${jewelryDetail?.category?.id}`} className='desk-top-title-sm a-taglink'>
                                                {breadCrumpCat}
                                                </a>
                                                </li> 
                                                // : (jewelryDetail?.producttype == 77 && isOnlySetting == 1) ?
                                                // <li className="breadcrumb-item active" aria-current="page">
                                                // <a href={process.env.PUBLIC_URL + `/Jewelry/onlysetting?cid=${jewelryDetail?.category?.id}`}>
                                                // onlysetting
                                                // </a>
                                                // </li> 
                                                : 
                                                <li className="breadcrumb-item active" aria-current="page">
                                                <a href={process.env.PUBLIC_URL + `/Jewelry/${jewelryDetail?.category?.name}?cid=${jewelryDetail?.category?.id}`} className='desk-top-title-sm a-taglink'>
                                                {breadCrumpCat}
                                                </a>
                                                </li> 
                                        }
                                        <li className="breadcrumb-item active" aria-current="page">
                                            {breadCrumpJewelSku}
                                            </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div>
                            {
                            ((buildYourOwn == true || jewelryDetail?.producttype == 77) && isValidChooseDiamond == true) &&

                                <section className="mobile-view-none" >
                                    <div className="container container-main">
                                        <div className="wizard2-steps mb-3">
                                            <div className="step wizard2-steps-heading active-step keep-left">
                                                <div className="node">
                                                    <div className="node-skin">
                                                        <div className="cont">
                                                            <h2 className="nostyle-heading">Build Your Own Ring</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {JewelryId == null ?
                                                <div className="cyo-bar-step step step-item ">
                                                    <div className="node" >
                                                        <div className="node-skin">
                                                            <div className="pho">
                                                                <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg"} alt="setting" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                            </div>
                                                            <div className="cont">
                                                                <div className="action help-tips">
                                                                    <a href="#" className="td-u bar-action line1-doted-2"></a>
                                                                </div>
                                                                <div className="heading">
                                                                    <div className="action help-tips">
                                                                        <a href="#" className="td-u bar-action">Choose</a>
                                                                    </div>
                                                                    <h2 className="nostyle-heading">setting</h2>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="cyo-bar-step step step-item active-step keep-left">
                                                    <div className="node" >
                                                        <div className="node-skin">
                                                            <div className="pho">

                                                                {/* <img style={{ background: 'rgba(76, 175, 80, 0.1)' }} src={jewelryDetail?.default?.path == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg" : jewelryDetail?.default?.path} alt="setting" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/> */}
                                                                <img style={{ background: 'rgba(76, 175, 80, 0.1)' }} src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg"} alt="setting" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>

                                                            </div>
                                                            <div className="cont">
                                                                <div className="heading"><h2 className="nostyle-heading">Setting</h2>
                                                                    <div className="action help-tips">
                                                                        <div className="td-u bar-action">{jewelryDetail?.title}
                                                                            {/* <aside>Price :{defaultCurrency?.sign ?? "$"}{jewelryDetail?.settingprice}</aside> */}
                                                                            <aside>Price :{defaultCurrency?.sign ?? "$"}{finalAmount}</aside>
                                                                        </div>
                                                                    </div>
                                                                    <div className="action double-action" style={{ display: 'flex' }}>
                                                                        <a href={process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${jewelryDetail?.id}/${jewelryDetail?.title}?jid=${jewelryDetail?.id}`}>
                                                                        View Details
                                                                            {/* <button variant="primary" type="button" className="">View Details</button> */}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {DiamondId == null || DiamondId == undefined ?
                                                <div className="cyo-bar-step step step-item ">
                                                    <div className="node" >
                                                        <div className="node-skin">
                                                            <div className="pho">
                                                                <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg"} alt="diamond" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                            </div>
                                                            <div className="cont">
                                                                <div className="action help-tips">
                                                                    <a href="#" className="td-u bar-action line1-doted-2"></a>
                                                                </div>
                                                                <div className="heading">
                                                                    <div className="action help-tips">
                                                                        <a href="#" className="td-u bar-action">Choose</a>
                                                                    </div>
                                                                    <h2 className="nostyle-heading">Diamond</h2>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                :
                                                <div className="cyo-bar-step step step-item  active-step keep-left">
                                                    <div className="node" >
                                                        <div className="node-skin">
                                                            <div className="pho">
                                                                <img src={DiamondDetail.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : DiamondDetail.image} alt="diamond" style={{ backgroundColor: "white" }} onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                            </div>
                                                            <div className="cont">
                                                                <div className="action help-tips">
                                                                    <a href="#" className="td-u bar-action line1-doted-2"></a>
                                                                </div>
                                                                <div className="heading">
                                                                    <h2 className="nostyle-heading">Diamond</h2>
                                                                    <div className="action help-tips">
                                                                        <div className="td-u bar-action">{DiamondDetail.title}
                                                                            <aside>Price :{defaultCurrency?.sign ?? "$"}{DiamondDetail.diamondprice}</aside></div>
                                                                    </div>
                                                                    <div className="action double-action" style={{ display: 'flex' }}>
                                                                        <a target='_blank' href={process.env.PUBLIC_URL + `/DiamondDetails/Build-Your-Own/${DiamondDetail.title}?did=${DiamondDetail.id}`}>
                                                                        View Details
                                                                            {/* <button variant="primary" type="button" className="">View Details</button> */}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {JewelryId != null && DiamondId != null ?
                                                <div className="step step-item invariant-color active-step keep-left">
                                                    <div className="node">
                                                        <div className="node-skin">
                                                            <div className="pho">
                                                                <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/ring.svg"} alt="ring" style={{ backgroundColor: "white" }} onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                            </div>
                                                            <div className="cont">
                                                                <div className="action help-tips">TOTAL</div>
                                                                <div className="heading"><h2 className="nostyle-heading">{defaultCurrency?.sign ?? "$"}{sum.toFixed(2)}</h2></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="step step-item invariant-color">
                                                    <div className="node">
                                                        <div className="node-skin">
                                                            <div className="pho">
                                                                <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/ring.svg"} alt="ring" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                            </div>
                                                            <div className="cont">
                                                                <div className="heading"><h2 className="nostyle-heading">TOTAL</h2></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </section>

                            }
                        </div>
                        <section className="jumbotron pt-3">
                            <div className="container container-main">
                                <div className="product-details">
                                    <div className="row">
                                        {
                                            isSkeleton ?
                                            <div className="col-lg-6">
                                                <Skeleton
                                                    count={1}
                                                    inline
                                                    width='100%'
                                                    height='550px'
                                                /> 
                                            </div>
                                            :
                                            <>
                                                <div className="col-lg-6">
                                                    <div className="thumbsider" id="sidebar">
                                                        {selectedImage?.type == "Image" ?
                                                            jewelryDetail?.zoom?.map((item, index) => {
                                                                let selected = selectedImage?.id == item?.id
                                                                return (
                                                                    selected &&
                                                                    <div className="boximg-shadot box-h-w mar-l-94">
                                                                        <figure onContextMenu={(e)=>e.preventDefault()} onMouseMove={handleZoomImg} onMouseLeave={handleZoomImgOut} style={backPostion}>
                                                                            <img src={item?.path} loading="lazy" alt="img1" title="img1" className="img-w-100" 
                                                                                onError={(e)=>{
                                                                                    e.target.onerror=null
                                                                                    e.target.src=noimage
                                                                                }}
                                                                            />
                                                                        </figure>
                                                                    </div>                                                            
                                                                )
                                                            })

                                                            :
                                                            jewelryDetail?.videourl?.map((item, index) => {
                                                                // console.log("item ----",item);
                                                                return (
                                                                    <div className="boximg-shadot box-h-w mar-l-94" onContextMenu={(e)=>e.preventDefault()} key={Math.random()} style={{padding:'65px 0'}}>
                                                                        <style>{`body { background-color: ${backgroundcolor}; }`}</style>
                                                                        {isVideoLoading && <LoadingSpinner />}
                                                                        <video width="100%" height="100%" controls autoPlay muted  >
                                                                            <source src={item?.path} ></source>
                                                                        </video>
                                                                        {/* <iframe onLoad={handleIframeLoad} style={{ width: "100%", minHeight: "573px",border:"1px solid #dedede" }} allow="autoplay"
                                                                            src={item?.path+'?transparent=0'} id="jvideo" className='jVideo' allowtransparency="true"></iframe> */}
                                                                    </div>
                                                                )
                                                            })}

                                                        <ul className="ul-start thumbnails add-active1 set-thumb" onContextMenu={(e)=>e.preventDefault()} >
                                                            {jewelryDetail?.thumb?.map((item, index) => {
                                                                return (
                                                                    <li key={Math.random()} className={selectedImage?.id == item?.id ? "active-bor-z" : "boximg-shado"}
                                                                        onClick={() => {selectionFun(item, index); setImageUrl(item?.path);}}>
                                                                        <a className='img-link' data-standard={item.path}>
                                                                            <img 
                                                                            onError={(e)=>{
                                                                                e.target.onerror=null
                                                                                e.target.src=noimage
                                                                            }}
                                                                            src={item?.path} alt="" />
                                                                        </a>
                                                                    </li>
                                                                )
                                                            })}
                                                            {jewelryDetail?.videourl?.map((item, index) => {
                                                                return (
                                                                    <li key={Math.random()} className={selectedImage?.id == item?.id ? "active-bor-z" : "boximg-shado"} >
                                                                        <span href={item?.path} className="iframe-video-thumb hand"
                                                                            data-standard={item.path} onClick={() => selectionFun(item, index)}>
                                                                            <img src="https://cdn.shopify.com/s/files/1/0057/0736/6467/files/prod-video.png?v=1611210220"
                                                                                className="" onError={(e)=>{
                                                                                    e.target.onerror=null
                                                                                    e.target.src=noimage
                                                                                }}
                                                                                alt="https://cdn.shopify.com/s/files/1/0057/0736/6467/files/prod-video.png?v=1611210220" />
                                                                        </span>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        <div className="col-lg-6 pl-5 mob-pl-15 mob-mt-30">
                                            <div className="">
                                                <h4 className="lsp1 Hypatia-bold tit-color mb-3 pr-5">

                                                    {
                                                        isSkeleton ?
                                                        <Skeleton
                                                            count={1}
                                                            inline
                                                            width='100%'
                                                            height='30px'
                                                        /> :
                                                        <>
                                                            { jewelryDetail?.title }
                                                            {
                                                                Wishlist != '' &&
                                                                Wishlist?.includes(jewelryDetail?.id) == true ?
                                                            
                                                                <span className="hand details-heart-fixed" onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : confirmRemovejewelry()}>
                                                                    <svg width="24" height="21" viewBox="0 0 24 21" fill="#ca9e79" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#AB7C94" strokeOpacity="0.65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    </svg>
                                                                </span>
                                                                :
                                                                <span className="hand details-heart-fixed" onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : validation()}>
                                                                    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#AB7C94" strokeOpacity="0.65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    </svg>
                                                                </span>
                                                            }
                                                        </>
                                                    }
                                                    {/* <span className="hand details-heart-fixed" onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : validation()}>
                                                        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#AB7C94" strokeOpacity="0.65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </span> */}
                                                    {
                                                        // console.log('Wishlist',Wishlist)
                                                    }
                                                    {
                                                        // console.log('jewelryDetail',jewelryDetail)
                                                    }
                                                    
                                                </h4>
                                                <h6 className="lists-h5 mb-3">
                                                    {
                                                        isSkeleton ?
                                                        <Skeleton
                                                            count={1}
                                                            inline
                                                            width='100%'
                                                            height='30px'
                                                        /> :
                                                        <>
                                                            <span className="t-color">SKU :</span> {jewelryDetail?.itemcode}
                                                        </>
                                                    }
                                                </h6>

                                                {
                                                    isSkeleton ?
                                                    <Skeleton
                                                        count={1}
                                                        inline
                                                        width='100%'
                                                        height='30px'
                                                    /> :
                                                    <>
                                                        {
                                                            DiamondId != null ?
                                                            <div className="mt-4 selected-boxdiv"><h5><b>Your Diamond</b></h5>
                                                                <div className="row mt-3 mb-3" style={{ alignItems: "center" }}>
                                                                    <div className="col-xl-2 col-lg-2 col-sm-1 col-2" style={{ paddingRight: "5px" }}>
                                                                        <img className='img-responsive product-image' src={DiamondDetail.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : DiamondDetail.image} alt="diamond" style={{ backgroundColor: "white" }} width="100%" onError={(e)=>{
                                                                                e.target.onerror=null
                                                                                e.target.src=noimage
                                                                            }}/>

                                                                    </div>
                                                                    <div className="col-xl-10 col-lg-10 col-sm-11 col-10">
                                                                        <h6 className="lsp1 Hypatia-bold mb-2" >{DiamondDetail?.title}</h6><br />
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="column" >
                                                                        <h6>Your Diamond </h6>
                                                                        <h6>Your Ring </h6>
                                                                        <h6>Total </h6>

                                                                    </div>
                                                                    <div className="column" >
                                                                        <h6>: {defaultCurrency?.sign ?? "$"}{DiamondDetail.diamondprice}</h6>
                                                                        {/* <h6>: {defaultCurrency?.sign ?? "$"}{jewelryDetail.settingprice}</h6> */}
                                                                        <h6>: {defaultCurrency?.sign ?? "$"}{finalAmount}</h6>
                                                                        <h6>: {defaultCurrency?.sign ?? "$"}{sum.toFixed(2)}</h6>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            <h3 className="lists-h2-price">
                                                                {/* {defaultCurrency?.sign ?? "$"}{jewelryDetail?.settingprice} */}
                                                                {defaultCurrency?.sign ?? "$"}{finalAmount}
                                                            </h3>
                                                        }
                                                    </>
                                                 }

                                                <div className="mt-4" style={{marginBottom:"-17px"}}>                                                    
                                                    <div>
                                                        {
                                                            isSkeleton ?
                                                            <Skeleton
                                                                count={1}
                                                                inline
                                                                width='100%'
                                                                height='30px'
                                                            /> :
                                                            <p className="f-weight-400 mb-2">Metal - <span>{jewelryDetail?.metal_stamp?.paraname} {jewelryDetail?.metal_type?.paraname}</span></p>
                                                        }
                                                    </div>
                                                    {/* <div style={{ display: 'flex' }}>
                                                <ul className="metal-color-li click-li">
                                                    <li data-toggle="tooltip"
                                                        title={jewelryDetail?.metal_stamp?.paraname + ' ' + jewelryDetail?.metal_type?.paraname}
                                                        // className={jewelryDetail?.metal_type?.paraname.replace(/\s/g, '').toLowerCase() + '-bg'}
                                                        className={DefaultMetalVariant == item?.metal_type?.id ? `${selectClass} activeli` : selectClass}

                                                        onClick={() => selectVariant(unique)}
                                                    >
                                                        {jewelryDetail?.metal_stamp?.paraname}
                                                    </li>
                                                </ul>
                                            </div> */}
                                                    <div style={{ display: 'flex',overflowX:'scroll' }} className='metal-list' >
                                                        {arrayUniqueMetalKey?.map((item, index) => {
                                                            let selectClass = ''
                                                            if (item.metal_type.toLowerCase() == 'rose white' || item.metal_type.toLowerCase() == 'rosewhite') {
                                                                selectClass = 'rosewhite-bg'
                                                            }
                                                            if (item.metal_type.toLowerCase() == 'rose yellow' || item.metal_type.toLowerCase() == 'roseyellow') {
                                                                selectClass = 'roseyellow-bg'
                                                            }
                                                            if (item.metal_type.toLowerCase() == 'rose yellow white' || item.metal_type.toLowerCase() == 'roseyellowwhite') {
                                                                selectClass = 'roseyellowwhite-bg'
                                                            }
                                                            if (item.metal_type.toLowerCase() == 'yellow white' || item.metal_type.toLowerCase() == 'yellowwhite') {
                                                                selectClass = 'yellowwhite-bg'
                                                            }
                                                            if (item.metal_type.toLowerCase() == 'rose gold' || item.metal_type.toLowerCase() == 'rosegold') {
                                                                selectClass = 'rosegold-bg'
                                                            }
                                                            if (item.metal_type.toLowerCase() == 'yellow gold' || item.metal_type.toLowerCase() == 'yellowgold') {
                                                                selectClass = 'yellow-bg'
                                                            }
                                                            if (item.metal_type.toLowerCase() == 'white gold' || item.metal_type.toLowerCase() == 'whitegold') {
                                                                selectClass = 'silver-bg'
                                                            }
                                                            if (item.metal_type.toLowerCase() == 'silver') {
                                                                selectClass = 'silver-bg'
                                                            }
                                                            let data = {
                                                                'metal_stamp': item?.metal_stamp,
                                                                'metal_type': item?.metal_type,
                                                            }

                                                            return (
                                                                <ul className="metal-color-li click-li" key={Math.random()}>
                                                                    <li
                                                                        data-toggle="tooltip"
                                                                        title={item?.metal_stamp + ' ' + item?.metal_type}
                                                                        className={DefaultMetalVariant?.metal_stamp == item?.metal_stamp && DefaultMetalVariant?.metal_type == item?.metal_type ? `${selectClass} activeli` : selectClass}
                                                                        onClick={() => JSON.stringify(DefaultMetalVariant) != JSON.stringify(data) && selectMetalVariant(item, index)}>
                                                                        {item?.metal_stamp}
                                                                    </li>
                                                                </ul>
                                                            )
                                                        })}
                                                    </div>
                                                </div>

                                                {jewelryDetail?.gem_color != null &&
                                                    <div className="mt-4">
                                                        <div>
                                                            <p className="f-weight-400 mb-2">Gems Stone - <span>{jewelryDetail?.gem_color?.paraname}</span></p>
                                                        </div>
                                                        <div style={{ display: 'flex' }}>

                                                            {arrayUniqueByKey?.map((item, index) => {
                                                                return (
                                                                    <ul className="metal-color-li click-li" key={Math.random()}>

                                                                        <li data-toggle="tooltip"
                                                                            title={item?.gem_color?.paraname}
                                                                            className={defaultVariant == item?.gem_color?.id ? `activeli` : null}
                                                                            onClick={() => defaultVariant != item?.gem_color?.id && selectVariant(item, index)}
                                                                        >
                                                                            <img src={item?.gem_color?.image} width='30px' onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                                        </li>
                                                                    </ul>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>

                                                }
                                                {DiamondId == null && buildYourOwn == false && jewelryDetail?.producttype == 76 ?
                                                    <div className="mt-5">
                                                        <div className="text-div">
                                                            <p className="f-weight-400">Quantity</p>
                                                        </div>
                                                        <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <td className="text-center">
                                                                <div className="quantity buttons_added">
                                                                    <input type="button" value="-" className="minus" onClick={() => handleDecrement()} />
                                                                    <input type="number" step="1" disabled={true}
                                                                        name="quantity" value={countQty} title="Qty" onChange={handleOnChange}
                                                                        className="input-text qty text" size="4" pattern="" inputmode="" />
                                                                    {/* <input disabled={!ringSizeQty} type="button" value="+" className="plus" onClick={() => handleIncrement()} /> */}
                                                                    <input type="button" value="+" className="plus" onClick={() => handleIncrement()} />
                                                                </div>
                                                            </td>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                                }

                                                {
                                                    (jewelryDetail?.category?.name.toLowerCase() == "ring" 
                                                    || jewelryDetail?.category?.name.toLowerCase() == "rings"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "engagement rings"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "engagement ring"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "wedding ring"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "wedding rings"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "chains"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "chain"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "bracelet"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "bracelets"
                                                    )
                                                    ?
                                                    <div className="mt-2">
                                                        {
                                                            // console.log("ringSizeAvailable -- ",ringSizeAvailable.length)
                                                        }
                                                        {
                                                            // console.log("ringSize -- ",ringSize)
                                                        }
                                                    <div className="text-div">
                                                        { (jewelryDetail?.category?.name.toLowerCase() == "ring" 
                                                    || jewelryDetail?.category?.name.toLowerCase() == "rings"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "engagement rings"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "engagement ring"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "wedding ring"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "wedding rings") ? "Ring Size" : null}
                                                    {(jewelryDetail?.category?.name.toLowerCase() == "bracelets" || jewelryDetail?.category?.name.toLowerCase() == "bracelet") ? "Bracelet Length" : null}
                                                    {(jewelryDetail?.category?.name.toLowerCase() == "chains" || jewelryDetail?.category?.name.toLowerCase() == "chain") ? "Chain Length" : null}
                                                    </div>
                                                    <div className="" style={{ display: 'flex', alignItems: 'center' }}>

                                                        <select style={{ width: "100px" }} value={SelectedringSize?.value} className="form-control" onChange={(e) => handleRingsize(e)}>
                                                            <option disabled={true} selected>Choose
                                                            { (jewelryDetail?.category?.name.toLowerCase() == "ring" 
                                                    || jewelryDetail?.category?.name.toLowerCase() == "rings"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "engagement rings"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "engagement ring"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "wedding ring"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "wedding rings") ? "Ring Size" : null}
                                                    {(jewelryDetail?.category?.name.toLowerCase() == "bracelets" || jewelryDetail?.category?.name.toLowerCase() == "bracelet") ? "Bracelet Length" : null}
                                                    {(jewelryDetail?.category?.name.toLowerCase() == "chains" || jewelryDetail?.category?.name.toLowerCase() == "chain") ? "Chain Length" : null}
                                                    </option>
                                                            {/* {ringSize?.map((item, index) => {
                                                                return (
                                                                    <option id={item?.id} value={item?.paraname} key={Math.random()} >
                                                                        {item?.paraname}
                                                                    </option>
                                                                )
                                                            })} */}
                                                            {
                                                                ringSizeAvailable.length == 0 ?
                                                                ringSize?.map((item, index) => {
                                                                    return (
                                                                        <option id={item?.id} value={item?.paraname} key={Math.random()} >
                                                                            {item?.paraname}
                                                                        </option>
                                                                    )
                                                                })
                                                                :
                                                                ringSizeAvailable?.map((item, index) => {
                                                                    return (
                                                                        <option id={item?.para_name.id} value={item?.para_name?.paraname} key={Math.random()} >
                                                                            {item?.para_name?.paraname}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                            {/* {ringSizeAvailable?.map((item, index) => {
                                                                return (
                                                                    <option id={item?.para_name.id} value={item?.para_name?.paraname} key={Math.random()} >
                                                                        {item?.para_name?.paraname}
                                                                    </option>
                                                                )
                                                            })} */}
                                                        </select>
                                                        {
                                                            (jewelryDetail?.category?.name.toLowerCase() == "ring" 
                                                            || jewelryDetail?.category?.name.toLowerCase() == "rings"
                                                            || jewelryDetail?.category?.name.toLowerCase() == "engagement rings"
                                                            || jewelryDetail?.category?.name.toLowerCase() == "engagement ring"
                                                            || jewelryDetail?.category?.name.toLowerCase() == "wedding ring"
                                                            || jewelryDetail?.category?.name.toLowerCase() == "wedding rings"
                                                            ) ? 
                                                            // <a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => openRingsizehelp()} className="text-div ml-2" >Item Size Help</a>
                                                            <>
                                                                <button type="button" className="btn" data-toggle="modal" data-target=".bd-example-modal-lg">Ring Size Guide</button>
                                                                <div id="ringsizehelp" className="modal bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                                                    <div className="modal-dialog modal-lg">
                                                                        <div className="modal-content">
                                                                        <iframe src={ringsizehelp} height={500} width={800}></iframe>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                </>
                                                            :
                                                            null
                                                        }
                                                        
                                                        
                                                    </div>


                                                    <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                        {message == true && "Please Choose Size"}
                                                    </p>
                                                </div>
                                                    : null
                                                    
                                                }                                               
                                                {/* buildYourOwn && isValidChooseDiamond == true && */}                                                
                                                <div class="mt-1 product-natural-price mob-d-flex-check">
                                                    <label className='hand' for="diamond_type_natural">
                                                        <input type="radio" className="hand" value="natural" id="diamond_type_natural" name="diamond_type" onClick={()=>setIsByWithLab(false)} checked={!isByWithLab} /> Natural Diamond
                                                    </label>
                                                    <label className='hand pl-2' for="diamond_type_lab">
                                                        <input type="radio" className="hand" value="lab" id="diamond_type_lab" name="diamond_type" onClick={()=>setIsByWithLab(true)} checked={isByWithLab} /> Lab Diamond
                                                    </label>
                                                    {/* <input type="checkbox" value="lab" name="diamond_type" id="byWithNatural" onChange={(e)=>setIsByWithLab(e?.target?.checked)}/>  */}
                                                    {/* <span style={{ background : '#dedfdf', padding:'10px' }}>
                                                        You can buy this product with Lab Diamonds 
                                                    </span> */}
                                                </div>

                                                <div className="mt-2 mb-3">
                                                    <div className="text-div">Diamond Quality</div>
                                                    <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <select className="form-control custom-select-text sideDiaSelect" id='sideDiaSelect' style={{ maxWidth: "150px", minWidth: "100px"}} onChange={handleStoneGrp} value={sideDiaGrpId}>
                                                        <option value={null}>Select</option>
                                                            {
                                                                stoneGroupList.map((item,index)=>{
                                                                let color = 
                                                                item?.color_to?.paraname == undefined ? item?.color_from?.paraname : item?.color_from?.paraname + '-' + item?.color_to?.paraname;

                                                                let clarity = 
                                                                item?.clarity_to?.paraname == undefined ? item?.clarity_from?.paraname : item?.clarity_from?.paraname + '-' + item?.clarity_to?.paraname;

                                                                if(sideDiaGrpId == item.id){
                                                                    localStorage.setItem("sideDiaColGrp", sideDiaGrpId);
                                                                }
                                                                return(<option key={index} value={item.id} data-color={color} data-clarity={clarity} selected={sideDiaGrpId == item.id ? 'selected' : ''} >{color}/{clarity}</option>)
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                                                             
                                                {
                                                jewelryDetail.status == "Active" && (jewelryDetail.isdeleted == 0 || jewelryDetail.isdeleted == "0") ?
                                                
                                                <div className="mt-1" style={{display:"flex",justifyContent: "space-between"}}>

                                                    {
                                                    ((buildYourOwn == true || jewelryDetail?.producttype == 77)) ?
                                                        JewelryId != null && DiamondId != null ?
                                                            <>
                                                                {DiamondDetail.diamond_type == 'Lab' ?
                                                                    <a href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Lab`} className="start-with-diamonds">
                                                                        Change Your Selection
                                                                    </a>
                                                                    :
                                                                    <a href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Natural`} className="start-with-diamonds">
                                                                        Change Your Selection
                                                                    </a>
                                                                }
                                                                
                                                                {ringSizeQty == true ? 
                                                                    null
                                                                : 
                                                                    <>
                                                                    <div style={{ display: 'flex',color:'red',/*padding:"2%",*/fontSize:"x-large" }}>
                                                                        Out of Stock!
                                                                    </div>
                                                                    <div style={{ display: 'flex',color:'red',padding:"1%" }}>
                                                                    As this item is out of stock at the moment, it may take longer than usual to ship.
                                                                    </div>
                                                                    </>
                                                                    }
                                                                    <div disabled={IsCartLoading} onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : Cartvalidation()}
                                                                        >
                                                                        <a className="start-with-diamonds" style={{border:((buildYourOwn == true || jewelryDetail?.producttype == 77)) ? '1px solid #a97c56' : 'none'}}>
                                                                            Add to Cart
                                                                        </a>
                                                                    </div>
                                                                
                                                                    </>
                                                                : (isValidChooseDiamond == true && (buildYourOwn == true || jewelryDetail?.producttype == 77)) ?
                                                                <>
                                                                    <a
                                                                        className="start-with-diamonds"
                                                                        aria-current="page"
                                                                        style={{ textDecoration: "none", marginRight: 10 }}
                                                                        onClick={() => ChooseDiamondvalidation()}
                                                                    // href={SelectedringSize != '' &&
                                                                    //     process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${utf8_to_b64(data.type)}?jid=${JewelryId}&shape=${data.type}&mincarat=${data.mincaret}&maxcarat=${data.maxcaret}`}
                                                                    >
                                                                        Choose Your Diamond
                                                                    </a>
                                                                {(jewelryDetail?.category?.name.toLowerCase() == "ring" 
                                                                || jewelryDetail?.category?.name.toLowerCase() == "rings"
                                                                || jewelryDetail?.category?.name.toLowerCase() == "engagement rings"
                                                                || jewelryDetail?.category?.name.toLowerCase() == "engagement ring"
                                                                || jewelryDetail?.category?.name.toLowerCase() == "wedding ring"
                                                                || jewelryDetail?.category?.name.toLowerCase() == "wedding rings"
                                                                ) ?
                                                                <div onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : Cartvalidation()}>
                                                                    <a className="start-with-diamonds">
                                                                        Buy Only Setting
                                                                    </a>
                                                                </div> 
                                                                : null
                                                                }
                                                                </>
                                                                : 
                                                                    <div onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : Cartvalidation()}>
                                                                        <a className="start-with-diamonds" style={{border:((buildYourOwn == true || jewelryDetail?.producttype == 77)) ? '1px solid #a97c56' : 'none'}}>
                                                                            Add to Cart
                                                                        </a>
                                                                    </div> 
                                                                    
                                                        :
                                                        <>
                                                        {ringSizeQty == true ? 
                                                            null
                                                            : 
                                                            <>
                                                            <div style={{ display: 'flex',color:'red',/*padding:"2%",*/fontSize:"x-large" }}>
                                                                Out of Stock!
                                                            </div>
                                                            <div style={{ display: 'flex',color:'red',padding:"1%" }}>
                                                            As this item is out of stock at the moment, it may take longer than usual to ship.
                                                            </div>
                                                            </>
                                                        }
                                                        <div onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : Cartvalidation()}>
                                                            <a className="start-with-diamonds" style={{border:((buildYourOwn == true || jewelryDetail?.producttype == 77)) ? '1px solid #a97c56' : 'none'}}>
                                                                Add to Cart
                                                            </a>
                                                        </div>
                                                        
                                                        </>
                                                    }

                                                </div>

                                                :
                                                <div style={{ display: 'flex',color:'red',padding:"2%",fontSize:"x-large" }}>
                                                    Jewelry Not Available
                                                </div>
                                                }


                                                <div>
                                                    <p className="ptag-text" dangerouslySetInnerHTML={{__html: jewelryDetail.description}}>
                                                        {/* {removeTags(jewelryDetail.description)} */}
                                                    </p>
                                                </div>
                                                <div className="mt-4">
                                                    <table>
                                                        <tbody className="details-table">
                                                            {/* <tr>
                                                                <td>
                                                                    <span>
                                                                        <img
                                                                            width="25"
                                                                            height="25"
                                                                            src={hours} />
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    24 / 7 Customer Service
                                                                    <span className="accent-delivery-date">Live Chat</span>
                                                                </td>
                                                            </tr> */}
                                                            <tr>
                                                                <td>
                                                                    <span>
                                                                        <img
                                                                            width="25"
                                                                            height="25"
                                                                            src={email}
                                                                            alt="Email@3x" onError={(e)=>{
                                                                                e.target.onerror=null
                                                                                e.target.src=noimage
                                                                            }}/>
                                                                    </span>
                                                                </td>
                                                                <td className="learn">
                                                                    <a href={"mailto:"+process.env.REACT_APP_EMAIL} className="google-rating-static">
                                                                        <span className="accent-delivery">Have a question? </span>
                                                                        <span className="accent-delivery-date">Send us an email</span>
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <span>
                                                                        <img
                                                                            width="25"
                                                                            height="25"
                                                                            src={callimg} onError={(e)=>{
                                                                                e.target.onerror=null
                                                                                e.target.src=noimage
                                                                            }}/>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <a className="accent-delivery" href={"tel:"+process.env.REACT_APP_CONTACT} >Call us at
                                                                        <span className="accent-delivery-date">{process.env.REACT_APP_CONTACT}</span> (10am - 5pm PST)
                                                                    </a>
                                                                </td>
                                                            </tr>                                                           

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* <section className="jumbotron" style={{ background: "#F8F8F8" }}>
                            <div className="container container-main">

                                {JewelryId != null && DiamondId != null ?
                                    <div>
                                        <ul className="tab-ul2" style={{justifyContent:"center"}}>
                                            <li className="click-tab-li2 activelitab2" data-value="jewelry-id" >
                                                Jewelry
                                            </li>
                                            <li className="click-tab-li2" data-value="diamond-id" >
                                                Diamond
                                            </li>
                                        </ul>
                                    </div>
                                    :
                                    JewelryId == null ?
                                        <div>
                                            <ul className="tab-ul2" style={{justifyContent:"center"}}>

                                                <li className="click-tab-li2 activelitab2" data-value="diamond-id" >
                                                    Diamond
                                                </li>
                                            </ul>
                                        </div>
                                        :
                                        <div>
                                            <ul className="tab-ul2" style={{justifyContent:"center"}}>
                                                <li className="click-tab-li2" data-value="jewelry-id" >
                                                    Jewelry
                                                </li>

                                            </ul>
                                        </div>
                                }

                                {JewelryId != null && DiamondId != null ?
                                    <div className="div-tab-bg">

                                        <div className="main-div2" id="jewelry-id" style="">
                                            <h4 style={{textAlign:"center"}}>
                                                Product Information
                                            </h4>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div>
                                                        <p className="ptag-text">
                                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-8">
                                                    <div className="box-news">
                                                        <div>
                                                            <dl className="order-summary-me-2 pad-10-dd-me">
                                                                <dd>
                                                                    Shape
                                                                    <strong>Pear</strong>
                                                                </dd>
                                                                <dd>
                                                                    Carat Size
                                                                    <strong>5 And Above</strong>
                                                                </dd>
                                                                <dd>
                                                                    Quality
                                                                    <strong>AAA</strong>
                                                                </dd>
                                                                <dd>
                                                                    Color
                                                                    <strong>Jet Black</strong>
                                                                </dd>
                                                                <dd>
                                                                    Number Of Diamonds
                                                                    <strong>1 Pcs</strong>
                                                                </dd>
                                                                <dd>
                                                                    Clarity
                                                                    <strong>OPAQUE</strong>
                                                                </dd>
                                                                <dd>
                                                                    Certification
                                                                    <strong>If Customer Need</strong>
                                                                </dd>
                                                                <dd>
                                                                    Diamond Type
                                                                    <strong>Natural</strong>
                                                                </dd>
                                                                <dd>
                                                                    Diamond Size
                                                                    <strong>18.77*29*9 mm</strong>
                                                                </dd>
                                                                <dd>
                                                                    Cut
                                                                    <strong>Very Good</strong>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="main-div2" id="diamond-id" style={{ display: "none" }}>
                                            <div>
                                                <h4>
                                                    Diamonds Information
                                                </h4>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div>
                                                            <p className="ptag-text">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-8">
                                                        <div className="box-news">
                                                            <div>
                                                                <dl className="order-summary-me-2 pad-10-dd-me">
                                                                    <dd>
                                                                        Ring Size
                                                                        <strong>K 1/2</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Min # of Diamonds
                                                                        <strong>25</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Total Carat Weight
                                                                        <strong>0.98</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Colour
                                                                        <strong>I</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Number Of Diamonds
                                                                        <strong>1 Pcs</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Clarity
                                                                        <strong>SI2</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Size
                                                                        <strong>2.1mm</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Diamond Type
                                                                        <strong>Natural</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Shape
                                                                        <strong>Round</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Cut
                                                                        <strong>Very Good</strong>
                                                                    </dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    JewelryId != null ?
                                        <div>
                                            <ul className="tab-ul2" style={{justifyContent:"center"}}>

                                                <li className="click-tab-li2 activelitab2" data-value="diamond-id" >
                                                    Diamond
                                                </li>
                                            </ul>
                                        </div>
                                        :
                                        <div>
                                            <ul className="tab-ul2" style={{justifyContent:"center"}}>
                                                <li className="click-tab-li2" data-value="jewelry-id" >
                                                    Jewelry
                                                </li>

                                            </ul>
                                        </div>

                                }


                            </div>
                        </section> */}
                        

                        {
                            isSkeleton ?
                            <Skeleton count={1} inline width='100%' height='550px' />
                             :
                            ((buildYourOwn == true || jewelryDetail?.producttype == 77)) ?
                                <section className="jumbotron" style={{ backgroundColor: "#F8F8F8" }}>

                                    <div className="container container-main">
                                        <div>

                                            {JewelryId != null && DiamondId != null ?

                                                <ul className="tab-ul2" style={{justifyContent:"center"}}>
                                                    <li className="click-tab-li2 activelitab2" data-value="jewelry-id" >
                                                        Jewelry
                                                    </li>
                                                    <li className="click-tab-li2 activelitab21" data-value="jewelry-side-stone" >
                                                            Side Stone
                                                    </li>
                                                    {
                                                    jewelryDetail?.jewelry_diamonds?.diamondtype == "Side" &&
                                                        <li className="click-tab-li2" data-value="sidestone-id" >
                                                            Side Stone
                                                        </li>
                                                    }

                                                    <li className="click-tab-li2" data-value="diamond-id" >
                                                        Diamond
                                                    </li>

                                                    {jewelryDetail?.producttype == 76 &&
                                                        <>
                                                        {
                                                            isGemStone == true &&
                                                        <li className="click-tab-li2" data-value="gemstone-id" >
                                                            Gem Stone
                                                        </li>
                                                        }
                                                        {
                                                            isCenterStone == true &&
                                                        <li className="click-tab-li2" data-value="centerstone-id" >
                                                            Center Stone
                                                        </li>
                                                        }
                                                        </>
                                                    }
                                                </ul>

                                                :
                                                JewelryId == null ?
                                                <ul className="tab-ul2" style={{justifyContent:"center"}}>

                                                        <li className="click-tab-li2 activelitab2" data-value="diamond-id" >
                                                            Diamond
                                                        </li>
                                                    </ul>
                                                    :
                                                    <ul className="tab-ul2" style={{justifyContent:"center"}}>
                                                        <li className="click-tab-li2 activelitab2" data-value="jewelry-id" >
                                                            Jewelry
                                                        </li>
                                                        <li className="click-tab-li2 activelitab21" data-value="jewelry-side-stone" >
                                                            Side Stone
                                                        </li>
                                                        {jewelryDetail?.jewelry_diamonds?.diamondtype == "Side" &&
                                                            <li className="click-tab-li2" data-value="sidestone-id" >
                                                                Side Stone
                                                            </li>
                                                        }
                                                        {jewelryDetail?.producttype == 76 &&
                                                            <>
                                                            {
                                                            isGemStone == true &&
                                                            <li className="click-tab-li2" data-value="gemstone-id" >
                                                                Gem Stone
                                                            </li>
                                                            }
                                                            {
                                                                isCenterStone == true &&
                                                            <li className="click-tab-li2" data-value="centerstone-id" >
                                                                Center Stone
                                                            </li>
                                                            }
                                                            </>
                                                        }
                                                    </ul>


                                            }
                                        </div>
                                        <div className="div-tab-bg">
                                            <div className="main-div2" id="jewelry-id" style={{}}>
                                            <h4 style={{textAlign:"center"}}>
                                                    Product Information
                                                </h4>
                                                <div className="row">
                                                    <div className="col-lg-2"></div>
                                                    <div className="col-lg-8">
                                                        <div className="box-news">
                                                            <div>
                                                                <dl className="order-summary-me-2 pad-10-dd-me">
                                                                    <dd>
                                                                        SKU
                                                                        <strong>{jewelryDetail?.itemcode == null ? "-" : jewelryDetail?.itemcode}</strong>
                                                                    </dd>

                                                                    <dd>
                                                                        Metal
                                                                        <strong>{jewelryDetail?.metal_stamp?.paraname} {jewelryDetail?.metal_type?.paraname}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Metal Weight
                                                                        <strong>{jewelryDetail?.metalweight == null ? "-" : jewelryDetail?.metalweight.toFixed(2)}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Setting Type
                                                                        <strong>{jewelryDetail?.setting_type?.paraname == null ? "-" : jewelryDetail?.setting_type?.paraname}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Total Diamond Weight
                                                                        <strong>{totalDiamondWeight.toFixed(2)}</strong>
                                                                    </dd>{
                                                                        console.log("totalGemStoneWeight",totalGemStoneWeight)}
                                                                    {
                                                                        totalGemStoneWeight > 0 && 
                                                                        <dd>
                                                                            Total Gem Stone Weight
                                                                            <strong>{totalGemStoneWeight.toFixed(2)}</strong>
                                                                        </dd>
                                                                    }
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="main-div2" id="jewelry-side-stone" style={{ display: "none" }}>
                                                <h4 style={{textAlign:"center"}}>
                                                Side Stone Detail
                                                </h4>
                                                <div className="row">
                                                    <div className="col-lg-2"></div>
                                                    <div className="col-lg-8">
                                                        <div className="box-news">
                                                        {jewelryDetail?.jewelry_diamonds?.map((item, index) => {
                                                            console.log('first side stone',item);
                                                            if(item?.diamondtype == "Side"){
                                                                return (<div>
                                                                    <dl className="order-summary-me-2 pad-10-dd-me">
                                                                        <dd>
                                                                            Shape
                                                                            <strong>{item?.shape_name?.paraname == null ? "" : item?.shape_name?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Size
                                                                            <strong>{item?.fromsize_name?.paraname == null ? "-" : item?.fromsize_name?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Color
                                                                            <strong>
                                                                                {
                                                                                    sideStoneColorClarityChange == true ?
                                                                                    sideStoneColorChange
                                                                                    :
                                                                                    <>
                                                                                    {item?.stone_group?.color_from?.paraname == null ? "-" : item?.stone_group?.color_from?.paraname }
                                                                                    {item?.stone_group?.color_to?.paraname == null ? "" : " - "+ item?.stone_group?.color_to?.paraname }
                                                                                    </>
                                                                                }
                                                                            </strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Total Diamonds
                                                                            <strong>{item?.totaldiamonds == null ? "-" : item?.totaldiamonds}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Clarity
                                                                            <strong> 
                                                                            {
                                                                                    sideStoneColorClarityChange == true ?
                                                                                    sideStoneClarityChange
                                                                                    :
                                                                                    <>
                                                                                    {item?.stone_group?.clarity_from?.paraname == null ? "-" : item?.stone_group?.clarity_from?.paraname }
                                                                                    {item?.stone_group?.clarity_to?.paraname == null ? "" : " - "+ item?.stone_group?.clarity_to?.paraname }
                                                                                </>
                                                                            }
                                                                            </strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Total Weight
                                                                            <strong>{item?.totalweight == null ? "-" : item?.totalweight}</strong>
                                                                        </dd>
                                                                        
                                                                    {/* <dd>
                                                                        Min Carat
                                                                        <strong>{item?.mincaret == null ? "-" : item?.mincaret}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Max Carat
                                                                        <strong>{item?.maxcaret == null ? "-" : item?.maxcaret}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        From Size
                                                                        <strong>{jewelryDetail?.fromsize_name?.paraname == null ? "-" : jewelryDetail?.fromsize_name?.paraname}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        To Size
                                                                        <strong>{jewelryDetail?.tosize_name?.paraname == null ? "-" : jewelryDetail?.tosize_name?.paraname}</strong>
                                                                    </dd> */}
    
                                                                    </dl>
                                                                </div>)
                                                            }
                                                            })
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="main-div2" id="centerstone-id" style={{ display: "none" }}>
                                            <h4 style={{textAlign:"center"}}>
                                                    Center Stone
                                                </h4>
                                                <div className="row">
                                                    <div className="col-lg-2">
                                                    </div>
                                                    <div className="col-lg-8">
                                                    <div className="box-news">
                                                        {jewelryDetail?.jewelry_diamonds?.map((item, index) => {
                                                            
                                                            // if(item?.diamondtype == "Center" && item?.isgemstone == 0){
                                                            if(item?.diamondtype == "Center"){
                                                                
                                                                return (<div>
                                                                    <dl className="order-summary-me-2 pad-10-dd-me">
                                                                        <dd>
                                                                            Shape
                                                                            <strong>{item?.shape_name?.paraname == null ? '-' : item?.shape_name?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Color
                                                                            <strong>{item?.color?.paraname == null ? '-' : item?.color?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Clarity
                                                                            <strong>{item?.clarity_name?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Carat
                                                                            <strong>{item?.mincaret == null ? "" : item?.mincaret}
                                                                             {/* -  {item?.maxcaret == null ? "" : item?.maxcaret} */}
                                                                             </strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Certificate
                                                                            <a href={item.certiurl} target="_blank" style={{ color: 'blue', float: "right" }}><>{DiamondDetail?.certino}</></a>
                                                                        </dd>
                                                                        <dd>
                                                                            Cut
                                                                            <strong>{item?.cut_name?.paraname == null ? '-' : DiamondDetail?.cut_name?.paraname}</strong>
                                                                        </dd>
                                                                    </dl>
                                                                </div>)
                                                            }
                                                            })
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="main-div2" id="gemstone-id" style={{ display: "none" }}>
                                            <h4 style={{textAlign:"center"}}>
                                                    Gem Stone
                                                </h4>
                                                <div className="row">
                                                    <div className="col-lg-2">
                                                    </div>

                                                    <div className="col-lg-8">
                                                    <div className="box-news">
                                                        {jewelryDetail?.jewelry_diamonds?.map((item, index) => {
                                                            
                                                            if(item?.diamondtype == "Center" && item?.isgemstone == 1){
                                                                return (<div>
                                                                    <dl className="order-summary-me-2 pad-10-dd-me">
                                                                        <dd>
                                                                            Gem Stone Intensity
                                                                            <strong>{item?.gem_stone_intensity?.paraname == null ? "-" : item?.gem_stone_intensity?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Gem Stone Type
                                                                            <strong>{item?.gem_stone_type?.paraname == null ? "-" : item?.gem_stone_type?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Color
                                                                            <strong>
                                                                                {item?.color?.paraname == null ? "-" : item?.color?.paraname }
                                                                            </strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Clarity
                                                                            <strong> 
                                                                                {item?.clarity_name?.paraname == null ? "-" : item?.clarity_name?.paraname }
                                                                            </strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Weight
                                                                            <strong>{item?.fromsize_name?.paraname == null ? "-" : item?.fromsize_name?.paraname}</strong>
                                                                        </dd>
    
                                                                    </dl>
                                                                </div>)
                                                            }
                                                            })
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="main-div2" id="sidestone-id" style={{ display: "none" }}>
                                            <h4 style={{textAlign:"center"}}>
                                                    Product Information
                                                </h4>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                    </div>
                                                    <div className="col-lg-8">
                                                        <div className="box-news">
                                                            <div>
                                                                <dl className="order-summary-me-2 pad-10-dd-me">

                                                                    <dd>
                                                                        Total Diamonds
                                                                        <strong>{jewelryDetail?.totaldiamonds == null ? "-" : jewelryDetail?.totaldiamonds}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Total Weight
                                                                        <strong>{jewelryDetail?.totalweight == null ? "-" : jewelryDetail?.totalweight}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Min Carat
                                                                        <strong>{jewelryDetail?.mincaret == null ? "-" : jewelryDetail?.mincaret}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Max Carat
                                                                        <strong>{jewelryDetail?.maxcaret == null ? "-" : jewelryDetail?.maxcaret}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        From Size
                                                                        <strong>{jewelryDetail?.fromsize == null ? "-" : jewelryDetail?.fromsize}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        To Size
                                                                        <strong>{jewelryDetail?.tosize == null ? "-" : jewelryDetail?.tosize}</strong>
                                                                    </dd>

                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            <div className="main-div2" id="diamond-id" style={{ display: "none" }}>
                                                <div>
                                                    <h4 style={{textAlign:"center"}}>
                                                        Diamonds Information
                                                    </h4>
                                                    <div className="row">
                                                        <div className="col-lg-2"></div>
                                                        <div className="col-lg-8">
                                                            <div className="box-news">
                                                                <div>
                                                                    <dl className="order-summary-me-2 pad-10-dd-me">
                                                                        <dd>
                                                                            Shape
                                                                            <strong>{DiamondDetail?.shape_name?.paraname == null ? "-" : DiamondDetail?.shape_name?.paraname}
                                                                            {/* {DiamondDetail?.ShapeName == null ? "-" : DiamondDetail?.ShapeName} */}
                                                                            </strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Carat
                                                                            <strong>{DiamondDetail?.carat == null ? "-" : DiamondDetail?.carat}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Color
                                                                            <strong>
                                                                            {DiamondDetail?.color_name?.paraname == null ? "-" : DiamondDetail?.color_name?.paraname}
                                                                                {/* {DiamondDetail?.ColorName == null ? "-" : DiamondDetail?.ColorName} */}
                                                                                </strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Clarity
                                                                            <strong>
                                                                            {DiamondDetail?.clarity_name?.paraname == null ? "-" : DiamondDetail?.clarity_name?.paraname}
                                                                                {/* {DiamondDetail?.ClarityName == null ? "-" : DiamondDetail?.ClarityName} */}
                                                                                </strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Lab
                                                                            <strong>
                                                                            {DiamondDetail?.lab_name?.paraname == null ? "-" : DiamondDetail?.lab_name?.paraname}
                                                                                {/* {DiamondDetail?.LabName == null ? "-" : DiamondDetail?.LabName} */}
                                                                                </strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Certificate
                                                                            <strong>{DiamondDetail?.cert_no == null ? "-" : DiamondDetail?.cert_no}</strong>
                                                                        </dd>

                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </section>
                                :
                                <section className="jumbotron" style={{ backgroundColor: "#F8F8F8" }}>
                                    <div className="container container-main">
                                        <div>

                                        <ul className="tab-ul2" style={{justifyContent:"center"}}>
                                                <li className="click-tab-li2 activelitab2" data-value="jewelry-id" >
                                                    Jewelry
                                                </li>                                                
                                                <li className="click-tab-li2 activelitab21" data-value="jewelry-side-stone" >
                                                            Side Stone
                                                </li>
                                                {jewelryDetail?.jewelry_diamonds?.diamondtype == "Side" &&
                                                    <li className="click-tab-li2" data-value="sidestone-id" >
                                                        Side Stone
                                                    </li>
                                                }
                                                {jewelryDetail?.producttype == 76 &&
                                                        <>
                                                        {
                                                            isGemStone == true &&
                                                        <li className="click-tab-li2" data-value="gemstone-id" >
                                                            Gem Stone
                                                        </li>
                                                        }
                                                        {
                                                            isCenterStone == true &&
                                                        <li className="click-tab-li2" data-value="centerstone-id" >
                                                            Center Stone
                                                        </li>
                                                        }                                                        
                                                        </>
                                                    }
                                            </ul>
                                        </div>
                                        <div className="div-tab-bg">
                                            <div className="main-div2" id="jewelry-id" style={{}}>
                                            <h4 style={{textAlign:"center"}}>
                                                    Product Information
                                                </h4>
                                                <div className="row">
                                                <div className="col-lg-2"></div>
                                                    <div className="col-lg-8">
                                                        
                                                        <div className="box-news">
                                                            <div>
                                                                <dl className="order-summary-me-2 pad-10-dd-me">
                                                                    <dd>
                                                                        SKU
                                                                        <strong>{jewelryDetail?.itemcode == null ? "-" : jewelryDetail?.itemcode}</strong>
                                                                    </dd>

                                                                    <dd>
                                                                        Metal
                                                                        <strong>{jewelryDetail?.metal_stamp?.paraname} {jewelryDetail?.metal_type?.paraname}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Metal Weight
                                                                        <strong>{jewelryDetail?.metalweight == null ? "-" : jewelryDetail?.metalweight.toFixed(2)}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Setting Type
                                                                        <strong>{jewelryDetail?.setting_type?.paraname == null ? "-" : jewelryDetail?.setting_type?.paraname}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Total Diamond Weight
                                                                        <strong>{totalDiamondWeight.toFixed(2)}</strong>
                                                                    </dd>
                                                                    {
                                                                        jewelryDetail?.grossweight > 0 && 
                                                                        <dd>
                                                                            Gross Weight
                                                                            <strong>{jewelryDetail?.grossweight}</strong>
                                                                        </dd>
                                                                     }                                                                    
                                                                     {
                                                                        totalGemStoneWeight > 0 && 
                                                                        <dd>
                                                                            Total Gem Stone Weight
                                                                            <strong>{totalGemStoneWeight.toFixed(2)}</strong>
                                                                        </dd>
                                                                     }
                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="main-div2" id="jewelry-side-stone" style={{ display: "none" }}>
                                                <h4 style={{textAlign:"center"}}>
                                                    Side Stone Detail
                                                </h4>
                                                <div className="row">                                                   
                                                    <div className="col-lg-2"></div>
                                                    <div className="col-lg-8">
                                                        <div className="box-news">
                                                        {
                                                        jewelryDetail?.jewelry_diamonds?.map((item, index) => {
                                                            console.log('second side stone',item);
                                                            if(item?.diamondtype == "Side"){
                                                            return (<div>
                                                                <dl className="order-summary-me-2 pad-10-dd-me">
                                                                    <dd>
                                                                        Shape
                                                                        <strong>{item?.shape_name?.paraname == null ? "" : item?.shape_name?.paraname}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Size
                                                                        <strong>{item?.fromsize_name?.paraname == null ? "-" : item?.fromsize_name?.paraname}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Color
                                                                        <strong>
                                                                        {
                                                                            sideStoneColorClarityChange == true ?
                                                                            sideStoneColorChange
                                                                            :
                                                                            <>
                                                                                {item?.stone_group?.color_from?.paraname == null ? "-" : item?.stone_group?.color_from?.paraname }
                                                                                {item?.stone_group?.color_to?.paraname == null ? "" : " - "+ item?.stone_group?.color_to?.paraname }
                                                                            </>
                                                                        }
                                                                        </strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Total Diamonds
                                                                        <strong>{item?.totaldiamonds == null ? "-" : item?.totaldiamonds}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Clarity
                                                                        <strong> 
                                                                        {
                                                                            sideStoneColorClarityChange == true ?
                                                                            sideStoneClarityChange
                                                                            :
                                                                            <>
                                                                            {item?.stone_group?.clarity_from?.paraname == null ? "-" : item?.stone_group?.clarity_from?.paraname }
                                                                            {item?.stone_group?.clarity_to?.paraname == null ? "" : " - "+ item?.stone_group?.clarity_to?.paraname }
                                                                            </>
                                                                        }
                                                                        </strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Total Weight
                                                                        <strong>{item?.totalweight == null ? "-" : item?.totalweight}</strong>
                                                                    </dd>
                                                                    {/* <dd>
                                                                        Min Carat
                                                                        <strong>{item?.mincaret == null ? "-" : item?.mincaret}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Max Carat
                                                                        <strong>{item?.maxcaret == null ? "-" : item?.maxcaret}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        From Size
                                                                        <strong>{jewelryDetail?.fromsize_name?.paraname == null ? "-" : jewelryDetail?.fromsize_name?.paraname}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        To Size
                                                                        <strong>{jewelryDetail?.tosize_name?.paraname == null ? "-" : jewelryDetail?.tosize_name?.paraname}</strong>
                                                                    </dd> */}

                                                                </dl>
                                                            </div>)
                                                            }
                                                        })
                                                    }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="main-div2" id="centerstone-id" style={{ display: "none" }}>
                                                <h4 style={{textAlign:"center"}}>
                                                    Center Stone
                                                </h4>
                                                <div className="row">
                                                    <div className="col-lg-2"></div>
                                                    <div className="col-lg-8">
                                                    <div className="box-news">
                                                        {jewelryDetail?.jewelry_diamonds?.map((item, index) => {
                                                            // console.log("item",item);
                                                            // if(item?.diamondtype == "Center" && item?.isgemstone == 0){
                                                            if(item?.diamondtype == "Center"){
                                                                
                                                                return (<div>
                                                                    <dl className="order-summary-me-2 pad-10-dd-me">
                                                                        <dd>
                                                                            Shape
                                                                            <strong>{item?.shape_name?.paraname == null ? '-' : item?.shape_name?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Color
                                                                            <strong>{item?.color?.paraname == null ? '-' : item?.color?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Clarity
                                                                            <strong>{item?.clarity_name?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Carat
                                                                            <strong>{item?.mincaret == null ? "" : item?.mincaret}
                                                                             {/* -  {item?.maxcaret == null ? "" : item?.maxcaret} */}
                                                                             </strong>
                                                                        </dd>
                                                                        
                                                                        <dd>
                                                                            Cut
                                                                            <strong>{item?.cut_name?.paraname == null ? '-' : item?.cut_name?.paraname}</strong>
                                                                        </dd>
                                                                        {
                                                                            item?.certino !== null &&
                                                                            <dd>
                                                                                Certificate
                                                                                <a href={item.certiurl} target="_blank" style={{ color: 'blue', float: "right" }}><>{item?.certino}</></a>
                                                                            </dd>
                                                                        }                                                                        
                                                                        {
                                                                            item?.measurement !== null &&
                                                                            <dd>
                                                                                Measurement
                                                                                <strong>{item?.measurement}</strong>
                                                                            </dd>
                                                                        }
                                                                    </dl>
                                                                </div>)
                                                            }
                                                            })
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="main-div2" id="gemstone-id" style={{ display: "none" }}>
                                                <h4 style={{textAlign:"center"}}>
                                                    Gem Stone
                                                </h4>
                                                <div className="row">
                                                    <div className="col-lg-2"></div>
                                                    <div className="col-lg-8">
                                                    <div className="box-news">
                                                        {jewelryDetail?.jewelry_diamonds?.map((item, index) => {
                                                            
                                                            if(item?.diamondtype == "Center" && item?.isgemstone == 1){
                                                                return (<div>
                                                                    <dl className="order-summary-me-2 pad-10-dd-me">
                                                                        <dd>
                                                                            Gem Stone Intensity
                                                                            <strong>{item?.gem_stone_intensity?.paraname == null ? "-" : item?.gem_stone_intensity?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Gem Stone Type
                                                                            <strong>{item?.gem_stone_type?.paraname == null ? "-" : item?.gem_stone_type?.paraname}</strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Color
                                                                            <strong>
                                                                                {item?.color?.paraname == null ? "-" : item?.color?.paraname }
                                                                            </strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Clarity
                                                                            <strong> 
                                                                                {item?.clarity_name?.paraname == null ? "-" : item?.clarity_name?.paraname }
                                                                            </strong>
                                                                        </dd>
                                                                        <dd>
                                                                            Weight
                                                                            <strong>{item?.fromsize_name?.paraname == null ? "-" : item?.fromsize_name?.paraname}</strong>
                                                                        </dd>
    
                                                                    </dl>
                                                                </div>)
                                                            }
                                                            })
                                                        }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="main-div2" id="sidestone-id" style={{ display: "none" }}>
                                                <h4 style={{textAlign:"center"}}>
                                                    Product Information
                                                </h4>
                                                <div className="row">
                                                    <div className="col-lg-8">
                                                        <div className="box-news">
                                                            <div>
                                                                <dl className="order-summary-me-2 pad-10-dd-me">

                                                                    <dd>
                                                                        Total Diamonds
                                                                        <strong>{jewelryDetail?.jewelry_diamonds?.totaldiamonds == null ? "-" : jewelryDetail?.jewelry_diamonds?.totaldiamonds}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Total Weight
                                                                        <strong>{jewelryDetail?.jewelry_diamonds?.totalweight}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Min Carat
                                                                        <strong>{jewelryDetail?.jewelry_diamonds?.mincaret == null ? "-" : jewelryDetail?.jewelry_diamonds?.mincaret}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        Max Carat
                                                                        <strong>{jewelryDetail?.jewelry_diamonds?.maxcaret == null ? "-" : jewelryDetail?.jewelry_diamonds?.maxcaret}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        From Size
                                                                        <strong>{jewelryDetail?.jewelry_diamonds?.fromsize == null ? "-" : jewelryDetail?.jewelry_diamonds?.fromsize}</strong>
                                                                    </dd>
                                                                    <dd>
                                                                        To Size
                                                                        <strong>{jewelryDetail?.jewelry_diamonds?.tosize == null ? "-" : jewelryDetail?.jewelry_diamonds?.tosize}</strong>
                                                                    </dd>

                                                                </dl>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>

                                    </div>

                                </section>
                        }

                        
                        {
                            similar?.length > 0 &&
                            <section className="jumbotron">
                                <div className="pad-left-210">
                                    <div className="mb-4">
                                        <h4 className="title-1">
                                            Similar Items
                                        </h4>
                                    </div>
                                </div>

                                <div className="mt-2 mob-p-lf-15" style={{ marginLeft: '7%', marginRight: '7%' }} onContextMenu={(e)=>e.preventDefault()} >
                                    {
                                        similar?.length > 0 ?
                                            <OwlCarousel className='owl-carousel owl-theme'
                                                autoplayHoverPause='true' autoplay='true' margin={6} items={(isMobile) ? 2 : 5}>

                                                {similar?.map((item, index) => {
                                                    return (
                                                        <div className="item" key={Math.random()} style={{ cursor: 'pointer' }} onClick={() =>  window.open(process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.slug}?jid=${item.id}&collection=New Arrival`,'_blank')}>
                                                            <div className="">
                                                            <a className="line1-doted-2" target='_blank' href={item.producttype == 77 ? 
                                                                    process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.id}/${item?.slug}?jid=${item?.id}` 
                                                                    : process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.slug}?jid=${item?.id}`}>
                                                                <img src={(item.default.path === '' || item.default.path == undefined || item.default.path == null) ? noimage : item.default.path} className="mb-3" 
                                                                onError={(e)=>{
                                                                                e.target.onerror=null
                                                                                e.target.src=noimage
                                                                            }} />
                                                                            </a>
                                                            </div>
                                                            <div className="explore-slider-text">
                                                                <a className="line1-doted-2" target='_blank' href={item.producttype == 77 ? 
                                                                    process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.id}/${item?.slug}?jid=${item?.id}` 
                                                                    : process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.slug}?jid=${item?.id}`}>
                                                                    {item?.title}
                                                                </a>
                                                                <label class="price-label mt-2">
                                                                    ${item?.settingprice}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )

                                                })
                                                }
                                            </OwlCarousel>
                                            : ''
                                    }
                                </div>
                            </section>
                        }


                        {
                            (recommended.length > 0) ?
                            <section className="jumbotron">
                                <div>
                                    <div className="collection-div-bg" id="test" style={{ position: "relative" }}>
                                        <div className="pad-left-210">

                                            <div className="mb-4">
                                                <h4 className="title-1">
                                                    Recommended Items
                                                </h4>
                                            </div>

                                            {
                                                (recommended.length > 0) ?
                                                    <OwlCarousel className='explore-slider explore-arrow explore-product'
                                                        autoplayHoverPause='true' autoplay='true' margin={6} items={7} >
                                                        {
                                                            recommended?.map((item, index) => {
                                                                return (

                                                                    <div className='item' key={Math.random()} style={{}} onContextMenu={(e)=>e.preventDefault()} >

                                                                        <div className="text-center">
                                                                            <a target='_blank' href={buildYourOwn == true ? DiamondId == null ? process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.id}/${item?.slug}?jid=${item?.id}` : process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.id}/${item?.slug}?jid=${item?.id}&did=${DiamondId}` : process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.slug}?jid=${item?.id}`}>
                                                                                <img src={item?.default?.path == null ? noimage : item?.default?.path} onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>

                                                                                {/* <h5 className="product-title pt-3 line1-doted-3">
                                                                            {item?.title}
                                                                        </h5> */}
                                                                                <div className="">
                                                                                    <div className='view-details-btn' style={{ paddingBottom: '10px' }}>
                                                                                        <span className="span-link product-title pt-3 line1-doted-3" style={{ textDecoration: 'none' }}>
                                                                                        {/* {item.title.length > 100 ?
                                                                                            `${item.title.substring(0, 100)}...` 
                                                                                            : item.title
                                                                                        } */}
                                                                                        {item.title}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>


                                                                                <p className="product-title-price mb-0">
                                                                                    ${item?.settingprice}
                                                                                </p>
                                                                                <div className="pt-3 ">
                                                                                    <div className='view-details-btn'>
                                                                                        <span className="span-link">
                                                                                            View Details
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </OwlCarousel>
                                                    : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                            : ''
                        }
                        {/* <ToastContainer /> */}
                    </div >
                </>
            }
        </div >
    )
}

