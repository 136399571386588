
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { CallCartCountApi, loginUser } from '../reducers/userReducers'

import Form from 'react-bootstrap/Form';

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Colors } from '../constant/Constant'
import Cookies from 'universal-cookie';
import { AiOutlineBorder, AiOutlineCheckSquare } from "react-icons/ai";
import { BASE_URL, GetAPIUrl } from '../API/APIUrl'
import axios from 'axios'
import { ShowErrorMessage, ShowMessage } from '../module/Tostify'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const Login = ({ authorized }) => {
    const cookies = new Cookies();


    const location = useLocation();

    const Loginid = new URLSearchParams(location.search).get("Loginid");

    // console.log("email", cookies.get('email'));
    // console.log("password", cookies.get('password'));

    const classes = useStyles()
    const [email, setEmail] = useState(cookies.get('email') == undefined ? '' : cookies.get('email'))

    const [password, setPassword] = useState(cookies.get('password') == undefined ? '' : cookies.get('password'))
    const [Remember, setRemember] = useState(cookies.get('email') == undefined ? false : true)
    const [showPassShow, setShowPassShow] = useState(false)

    const dispatch = useDispatch()
    const userData = useSelector(state => state.user)
    const navigate = useNavigate();

    const token = useSelector((state) => state?.user?.token)

    // const validation = event => {
    //     event.preventDefault()
    //     let message = "";
    //     if (email == "") {
    //         message = Messages.EMAIL_REQUIRED;
    //         setEmailMessage(message)
    //     }
    //     else if (regx.EMAIL.test(email) == false) {
    //         message = Messages.EMAIL_INVALID;
    //         setEmailMessage(message)
    //     }
    //     else {
    //         setEmailMessage('')
    //     }
    //     if (password == "") {
    //         message = Messages.PASSWORD_REQUIRED;
    //         setPasswordMessage(message)
    //     } else {
    //         setPasswordMessage('')
    //     }
    //     if (message == '') {
    //         console.log("yoooo.!");
    //         authenticate()
    //     }
    // }


    const authenticate = async (event) => {

        event.preventDefault()

        dispatch(loginUser({ email, password, Remember }))
        // var form = new FormData();
        // form.append("email", email);
        // form.append("password", password);
        // await axios.post(BASE_URL +GetAPIUrl.LOGIN_URL, form)  
        //     .then(response => {
        //         console.log("response",response.token);
        //         if (response.data.success == true) {   
        //             if (Remember == true) {
        //                 cookies.set('email', email, { path: '/' });
        //                 cookies.set('password', password, { path: '/' });
        //                 localStorage.setItem('data', JSON.stringify(response.data.data))
        //                 localStorage.setItem('token', response.data.token)
        //                 window.location.pathname = '/'  
        //                 ShowMessage(response.data.message)

        //             } else {
        //                 if (cookies.get('email') != undefined) {
        //                     cookies.remove('email', { path: '/' });
        //                     cookies.remove('password', { path: '/' });
        //                 }
        //             }
        //             ShowMessage(response.data.message)
        //         } else {
        //             ShowErrorMessage(response.message)
        //         }
        //     }).catch((error) => {
        //         ShowErrorMessage(error)
        //     })
    }

    const changeHandle = event => {
        event.preventDefault()
        setRemember(!Remember)
    }
    const URLCheck = localStorage.getItem('URL')

    if (authorized) {
        if(URLCheck != undefined || URLCheck != null){
            window.location.replace(process.env.PUBLIC_URL + URLCheck)
           
        }else{
            window.location.replace(process.env.PUBLIC_URL + "/")
        }
        // if (Loginid == "NextPage") {

        //     return navigate('/');
        // } else {
        //     window.location.reload(false)
        //     return navigate(-1);
        // }
    }
    return (
        <>
            {/* {userData.status == true &&
                <Backdrop className={classes.backdrop} open>
                    <CircularProgress color="inherit" />
                </Backdrop>

            } */}

            <div>
                <div className="container container-main">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={'/'}><i className="fa fa-home" aria-hidden="true"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Login</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="main-section">

                <div className="main-section">

                    <section className="pb-5 mob-ptpb-1">

                        <div className="container">

                            <div style={{ maxWidth: "500px", margin: "auto", boxShadow: "0px 2px 10px 0px #0530691a" }}>

                            <Form className="input-form-div signup-div" style={{padding:"1rem 1rem"}}>
                                <div className="text-center">
                                            <h3 className="main-color">
                                                Login
                                            </h3>
                                        </div>
                                    <Form.Group>
                                        <Form.Label>Email</Form.Label>
                                        <input
                                                autoFocus
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                type="text"
                                                className="form-control my-textbox1"
                                                placeholder="Enter Email Id" />
                                                {userData?.error?.email != '' ? 
                                            <p style={{ color: Colors.red, height: 8 }}>
                                                {userData?.error != '' && userData?.error?.email?.map((key, i) => key)}
                                            </p> : null}
                                    </Form.Group>

                                    <Form.Group className='pos-relative'>
                                        <Form.Label>Password</Form.Label>
                                        <input
                                            // style={{ font: showPassShow && "small-caption", }}
                                            value={password}
                                            type={showPassShow ? "text" : 'password'}
                                            id="showpassword"
                                            onChange={e => setPassword(e.target.value)}
                                            className="form-control my-textbox1" placeholder="Enter Password" />
                                        <span className="show-pass hand" title={showPassShow ? "show password" : "Hide password"} onClick={() => setShowPassShow(!showPassShow)}>
                                            <i className={showPassShow ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                        </span>
                                        {userData?.error?.password != '' ? 
                                        <p style={{ color: Colors.red, height: 8 }}>
                                            {userData?.error != '' && userData?.error?.password?.map((key, i) => key)}
                                        </p>
                                        : null}
                                    </Form.Group>
                                    <Form.Group>
                                    <label><button type='button' style={{ border: 0, backgroundColor: 'white' }} onClick={changeHandle}>
                                                        {Remember == true ?
                                                            <h3>  <AiOutlineCheckSquare /></h3>
                                                            :
                                                            <h3>  <AiOutlineBorder /></h3>
                                                        }</button>
                                    {/* <input type='checkbox' style={{ border: 0, backgroundColor: 'white' }} onClick={changeHandle} /> */}
                                                            {/* {Remember == true ?
                                                                <h3>  <AiOutlineCheckSquare /></h3>
                                                                :
                                                                <h3>  <AiOutlineBorder /></h3>
                                                            } */}
                                                         <span  style={{ paddingLeft:10}}>Remember me</span></label>
                                    </Form.Group>
                                    <div className='text-center'>
                                        <button variant="primary" type="submit" onClick={authenticate} className="login-me btn-shadow-me min-w-200">Log in</button>
                                        <div className="col-xl-12 mt-2">
                                                    <p className="text-center" >
                                                        {" Do not have an account with us ?" + " "}
                                                        <a
                                                            className="login-link"
                                                            aria-current="page"
                                                            href={process.env.PUBLIC_URL +'/Signup'}
                                                        >
                                                            {"Sign Up"}
                                                        </a>
                                                    </p>
                                                </div>
                                                <div className="col-xl-12">
                                                    <p className="text-center" >
                                                        {"Forget Password ?" + " "}
                                                        <Link
                                                            className="login-link"
                                                            aria-current="page"
                                                            to={'/ForgetPassword'}
                                                        >
                                                            {"Click here"}
                                                        </Link>
                                                    </p>
                                                </div>
                                    </div>
                                </Form>


                                {/* <form className="input-form-div signup-div" method="POST" style={{padding:"1rem 6rem"}}>
                                    <div className="mb-5 text-center">
                                        <h3 className="main-color">
                                            Login
                                        </h3>
                                    </div>
                                    <div className="row">

                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className="lblmy lblmy-active">Email<span className='mark_red'>*</span></label>
                                                
                                            </div>
                                        </div>

                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className="lblmy">Password<span className='mark_red'>*</span></label>
                                                <input
                                                    style={{ font: showPassShow && "small-caption", }}
                                                    value={password}
                                                    type={showPassShow ? "text" : 'password'}
                                                    id="showpassword"
                                                    onChange={e => setPassword(e.target.value)}
                                                    className="form-control my-textbox" placeholder="Enter Password" />
                                                <span className="show-pass hand" title={showPassShow ? "show password" : "Hide password"} onClick={() => setShowPassShow(!showPassShow)}
                                                    style={{ padding: "5px" }} >
                                                    <i className={showPassShow ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                </span>
                                                <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                    {userData?.error != '' && userData?.error?.password?.map((key, i) => key)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-6">
                                            <div className="form-check-inline">

                                                <label className="container-checkbox" style={{ paddingLeft:12,alignItems:'center',display:'flex'}}>
                                                    <button style={{ border: 0, backgroundColor: 'white' }} onClick={changeHandle}>
                                                        {Remember == true ?
                                                            <h3>  <AiOutlineCheckSquare /></h3>
                                                            :
                                                            <h3>  <AiOutlineBorder /></h3>
                                                        }</button>
                                                    <label style={{ paddingLeft:10}}> Remember me</label>

                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 text-center">
                                            <button onClick={authenticate} className="login-me btn-shadow-me min-w-200">Log in</button>
                                        </div>
                                        <div className="w-100">
                                            <div className="col-xl-12 mt-2">
                                                <p className="text-center" style={{ fontWeight: 600 }}>
                                                    {" Do not have an account with us ?" + " "}
                                                    <a
                                                        className="login-link"
                                                        aria-current="page"
                                                        href={process.env.PUBLIC_URL +'/Signup'}
                                                    >
                                                        {"Sign Up"}
                                                    </a>
                                                </p>
                                            </div>
                                            <div className="col-xl-12 mt-2">
                                                <p className="text-center" style={{ fontWeight: 600 }}>
                                                    {"Forget Password ?" + " "}
                                                    <Link
                                                        className="login-link"
                                                        aria-current="page"
                                                        to={'/ForgetPassword'}
                                                    >
                                                        {"Click here"}
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    
                                    <div className="row mt-3">
                                        <div className="col-lg-12" >
                                            <div style={{ display: 'flex' }}>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </form> */}
                            </div>
                        </div>
                        
                    </section>
                    <ToastContainer />
                </div>

            </div>
            {/* <section className="jumbotron">
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">
                            <form className="login100-form validate-form">
                                <span className="login100-form-title p-b-34">Login</span>
                                <div className="">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className="lblmy lblmy-active">Email<span className='mark_red'>*</span></label>
                                                <input
                                                    autoFocus
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    type="text"
                                                    className="form-control my-textbox"
                                                    placeholder="Enter Email Id" />
                                                <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                    {userData?.error != '' && userData?.error?.email?.map((key, i) => key)}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className="lblmy">Password<span className='mark_red'>*</span></label>
                                                <input
                                                    style={{ font: showPassShow && "small-caption", }}
                                                    value={password}
                                                    type={showPassShow ? "text" : 'password'}
                                                    id="showpassword"
                                                    onChange={e => setPassword(e.target.value)}
                                                    className="form-control my-textbox" placeholder="Enter Password" />
                                                <span className="show-pass hand" title={showPassShow ? "show password" : "Hide password"} onClick={() => setShowPassShow(!showPassShow)}
                                                    style={{ padding: "5px" }} >
                                                    <i className={showPassShow ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                </span>
                                                <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                    {userData?.error != '' && userData?.error?.password?.map((key, i) => key)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-6">
                                            <div className="form-check-inline">

                                                <label className="container-checkbox" style={{ paddingLeft:12,alignItems:'center',display:'flex'}}>
                                                    <button style={{ border: 0, backgroundColor: 'white' }} onClick={changeHandle}>
                                                        {Remember == true ?
                                                            <h3>  <AiOutlineCheckSquare /></h3>
                                                            :
                                                            <h3>  <AiOutlineBorder /></h3>
                                                        }</button>
                                                    <label style={{ paddingLeft:10}}> Remember me</label>

                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 text-center">
                                            <br />

                                            <button onClick={authenticate} className="login-me btn-shadow-me min-w-200">Log in</button>

                                        </div>
                                        <div className="w-100">
                                            <div className="col-xl-12 mt-2">
                                                <p className="text-center" style={{ fontWeight: 600 }}>
                                                    {" Do not have an account with us ?" + " "}
                                                    <a
                                                        className="login-link"
                                                        aria-current="page"
                                                        href={process.env.PUBLIC_URL +'/Signup'}
                                                    >
                                                        {"Sign Up"}
                                                    </a>
                                                </p>
                                            </div>
                                            <div className="col-xl-12 mt-2">
                                                <p className="text-center" style={{ fontWeight: 600 }}>
                                                    {"Forget Password ?" + " "}
                                                    <Link
                                                        className="login-link"
                                                        aria-current="page"
                                                        to={'/ForgetPassword'}
                                                    >
                                                        {"Click here"}
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="login100-more d-none d-xl-block d-lg-block" style={{ backgroundImage: `url('./assets/images/login/login-banner.jpg')` }}></div>
                        </div>
                    </div>
                </div>
            </section> */}

            
            {/* <ToastContainer /> */}
        </>

    )
}

export default Login