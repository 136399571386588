import React, { useState } from "react";
import noimage from "../assets/images/product-details/noimage.jpg";
import axios from "axios";
import { useEffect } from "react";
import { Link, Navigate, useLocation, useParams } from "react-router-dom";
import igi_cert from "../assets/igi_cert.jpg";
import { Colors } from "../constant/Constant";
import { fetch2 } from "../helpers/fetch";
import { BASE_URL, GetAPIUrl } from "../API/APIUrl";
import { useSelector } from "react-redux";
import { CategorySkeleton, DiamondFilterSkeleton, DiamondGridSkeleton, DiamondListSkeleton } from "../module/Skeletons";
import { ShowErrorMessage, ShowMessage } from "../module/Tostify";
import { Pagination } from "@material-ui/lab";
import { b64_to_utf8, utf8_to_b64 } from "../helpers/Utility";
import Slider from "@mui/material/Slider";
import LoadingSpinner from "../module/LoadingSpinner";



const $ = window.$;


const DiamondList = () => {
  let { did, mincarat, maxcarat, slug } = useParams();
  // console.log("slug--->",did, mincarat, maxcarat, slug)
  // console.log("dianmon.search", did == undefined ? "" : b64_to_utf8(did), slug, mincarat == undefined ? "" : b64_to_utf8(mincarat), maxcarat == undefined ? "" : b64_to_utf8(maxcarat),);

  const location = useLocation();
  // const state = location?.state?.fromDashboard;
  // console.log("state", state.fromDashboard);
  localStorage.setItem('URL', location.pathname + location.search)
  const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))


  const JewelryId = new URLSearchParams(location.search).get("jid");
  const JewelFinalAmt = new URLSearchParams(location.search).get("famount");
  const DiamondId = new URLSearchParams(location.search).get("did");
  const ShapeIds = new URLSearchParams(location.search).get("shape");
  const setting_mincarat = new URLSearchParams(location.search).get("mincarat");
  const setting_maxcarat = new URLSearchParams(location.search).get("maxcarat");
  const ring = new URLSearchParams(location.search).get("ring");

  const [DiamondDetail, setDiamondDetail] = useState([])

  const buildYourOwn = location.pathname.split("/")[2] == "Build-Your-Own"

  const [List, setList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filterCount, setFilterCount] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [minPrice, setMinPrice] = useState(0);

  const [maxPrice, setMaxPrice] = useState(0);

  const [minPricetemp, setMinPricetemp] = useState(0);

  const [maxPricetemp, setMaxPricetemp] = useState(0);

  const [fixedMinPrice, setFixedMinPrice] = useState(0);
  const [fixedMaxPrice, setFixedMaxPrice] = useState(0);

  const [minCarat, setMinCarat] = useState(0);

  const [maxCarat, setMaxCarat] = useState(0);

  const [fixedMinCarat, setFixedMinCarat] = useState(0);
  const [fixedMaxCarat, setFixedMaxCarat] = useState(0);

  const [minTable, setMinTable] = useState(0);

  const [maxTable, setMaxTable] = useState(0);
  const [fixedMinTable, setFixedMinTable] = useState(0);
  const [fixedMaxTable, setFixedMaxTable] = useState(0);

  const [minDepth, setMinDepth] = useState(0);

  const [maxDepth, setMaxDepth] = useState(0);
  const [fixedMinDepth, setFixedMinDepth] = useState(0);
  const [fixedMaxDepth, setFixedMaxDepth] = useState(0);


  const [activeFilter, setActiveFilter] = useState(false);  //This state for hide/show filter
  const [activeFilterAll, setActiveFilterAll] = useState(true);  //This state for hide/show filter All

  // let { minPrice, maxPrice, minCarat, maxCarat, minTable, maxTable, minDepth, maxDepth } = state


  const [hide, setHide] = useState(true);
  const [hideAll, setHideAll] = useState(false);

  const [selectedShape, setSelectedShape] = useState(ShapeIds == null ? [] : [{ id: JSON.parse(ShapeIds) }]);

  const [jewelryDetail, setjewelryDetail] = useState({})

  const [selectedClarity, setSelectedClarity] = useState([]);
  const [selectedColor, setSelectedColor] = useState([]);
  const [selectedCut, setSelectedCut] = useState([]);
  const [selectedFluor, setSelectedFluor] = useState([]);
  const [selectedPolish, setSelectedPolish] = useState([]);
  const [selectedSym, setSelectedSym] = useState([]);
  const [selectedLab, setSelectedLab] = useState([]);

  const [prodSelected, setProdSelected] = useState(false);

  const [prodSelectedIndex, setProdSelectedIndex] = useState(-1);

  const [selectedDiaType, setSelectedDiaType] = useState(slug == "Lab" || slug == "Natural" ? slug : 'Natural');
  // const [selectedDiaType, setSelectedDiaType] = useState("Lab");
  const [shapeId, setShapeId] = useState(ShapeIds == null ? [] : [JSON.parse(ShapeIds)]);

  const [ClarityId, setClarityId] = useState([]);
  const [ColorId, setColorId] = useState([]);
  const [CutId, setCutId] = useState([]);
  const [SymId, setSymId] = useState([]);
  const [FluorescenceId, setFluorescenceId] = useState([]);
  const [PolishId, setPolishId] = useState([]);
  const [LabId, setLabId] = useState([]);
  const [Currentpage, setCurrentpage] = useState(1);
  const [length, setLength] = useState(28);

  const PER_PAGE = length;

  const count = Math.ceil(filterCount / PER_PAGE);

  const [shapes, setShapes] = useState([])
  const [Clarity, setClarity] = useState([])
  const [Color, setColor] = useState([])
  const [Cut, setCut] = useState([])
  const [Fluorescence, setFluorescence] = useState([])
  const [Polish, setPolish] = useState([])
  const [Sym, setSym] = useState([])
  const [Lab, setLab] = useState([])
  const [SortColumn, setSortColumn] = useState('')
  const [SortOrder, setSortOrder] = useState("ASC")

  const token = useSelector((state) => state?.user?.token)

  const [globalJewelryMargin,setGlobalJewelryMargin] = useState(0);
  const [globalMarkup,setGlobalMarkup] = useState(0);
  const [globalTagFact,setglobalTagFact] = useState(1);

  const [sideDiaGrpId,setSideDiaGrpId] = useState(null);
  const [prodcutMetalPrice, setProdcutMetalPrice] = useState(0);
  const [prodcutSideDiaPrice, setProdcutSideDiaPrice] = useState(0);
  const [prodcutMetalWeight, setProdcutMetalWeight] = useState(0);
  const [finalAmount,setFinalAmount] = useState(0);
  const [labourtCharge,setLabourtCharge] = useState(0);
  
  const [otherCharge,setOtherCharge] = useState(0);
  const [centerDiaPrice,setCenterDiaPrice] = useState(0);
  const [finalGST,setFinalGST] = useState(0);
  const [sideDiaPrice,setSideDiaPrice] = useState(0);
  const [metalAmount,setMetalAmount] = useState(0);
  const [isSkeleton, setIsSkeleton] =useState(false);

  const [Loading, setLoading] = useState(false)
  const is_b2b = JSON.parse(localStorage.getItem('data'))
  let customerGroup = JSON.parse(localStorage.getItem('customerGroup'));

  const DiaType = [
    {
      id: 66,
      type: "Natural",
      DiaName: "Natural",
    },
    {
      id: 67,
      type: "Lab",
      DiaName: "Lab Down",
    },
  ];


  // const _DATA = usePagination(List, PER_PAGE);

  const handleChange = (e, p) => {
    setCurrentpage(p);
    setProdSelected(false)
    // _DATA.jump(p);
  };


  useEffect(() => {
    getGlobalSettings();
    
    if (DiamondId != null) {
      CallDiamondDetailApi()
    }
    
    AllParameter()
    // $(function () {

    //   $(".show-adv-option").click(function () {
    //     $(".more-option-filter").slideToggle();
    //     $(".hideadvtxt").toggle();
    //     $(".showadvtxt").toggle();
    //     $(".downcl").toggle();
    //     $(".upcl").toggle();
    //   });
    // })

    $(function () {

      $(".clickli .col-list").click(function () {
        $(this).toggleClass("all-active");
      });
      $(".clickli-shape").click(function () {
        $(this).toggleClass("all-active-shape");
      });
      $(window).on("load", function () {
        if ($(window).width() < 767) {
          $(".first-afilter a:nth-child(2)").addClass("ataglist-thumb-active");
        }
        if ($(window).width() > 768) {
          $(".first-afilter a:first-child").addClass("ataglist-thumb-active");
        }
      });     
        

      $(".table-list-click").click(function () {
        $(".ataglist-thumb").removeClass("ataglist-thumb-active");
        $(".thumblist-view-data").hide();
        $(".pair-tableview-data").hide();
        $(".table-view-data").show();
        $(this).addClass("ataglist-thumb-active");
      });
      $(".thumblist-list-click").click(function () {
        $(".ataglist-thumb").removeClass("ataglist-thumb-active");
        $(".table-view-data").hide();
        $(".pair-tableview-data").hide();
        $(".thumblist-view-data").show();
        $(this).addClass("ataglist-thumb-active");
      });

      $(".pair-tablelist-click").click(function () {
        $(".ataglist-thumb").removeClass("ataglist-thumb-active");
        $(".table-view-data").hide();
        $(".thumblist-view-data").hide();
        $(".pair-tableview-data").show();
        $(this).addClass("ataglist-thumb-active");
      });
    })

    // $(function () {
    //   $(".show-all-adv-option").click(function () {
    //     $(".hide-all-setfilter").slideToggle();
    //     $(".hidealladvtxt").toggle();
    //     $(".showalladvtxt").toggle();
    //     $(".downclall").toggle();
    //     $(".upclall").toggle();
    //   });

    // })

  }, [globalJewelryMargin])

  
  const getGlobalSettings = async () => {   
      await axios.post(BASE_URL + GetAPIUrl.GLOBALMARKUPTAG_URL, {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ' + token,
          }
      })
          .then(response => {
              if(response?.data?.type == "success")
              {
                  if(response?.data?.data.length > 0 )
                  {
                      setGlobalMarkup(response?.data?.data[0].side_stone_mark_up);
                      setglobalTagFact(response?.data?.data[0].side_stone_tag_fact);
                      setGlobalJewelryMargin(response?.data?.data[0].b2c_margin_rate);
                      console.log("0000000000000000000000000000000000000000",response?.data?.data[0].b2c_margin_rate)
                      if (JewelryId != null) {
                        CalljewelryDetailApi(response?.data?.data[0].b2c_margin_rate)
                      }
                  }
              }  
          }).catch(error => {
          }).finally(() => {

          })
  }

  const slideToggles = () => {
    $(".hideadvtxt").toggle();
    $(".showadvtxt").toggle();
    $(".downcl").toggle();
    $(".upcl").toggle();
  }

  React.useEffect(() => {

    const getSliderFilterData = setTimeout(() => {
      DiamondListsApi(Currentpage, '', '');      
    }, 800)
    return () => clearTimeout(getSliderFilterData)

    
  }, [Currentpage, shapeId, minPrice, maxPrice, ColorId, CutId, ClarityId, PolishId, SymId, LabId, FluorescenceId, maxCarat, minCarat, minDepth, maxDepth, minTable, maxTable, selectedDiaType])
   

  const AllParameter = async () => {
    const controller = new AbortController();
    setLoading(true)
    var form = new FormData();
    form.append("type", "diamond");
    form.append("paraname[]", "Shape");
    form.append("paraname[]", "Cut");
    form.append("paraname[]", "Clarity");
    form.append("paraname[]", "color");
    form.append("paraname[]", "Fluor");
    form.append("paraname[]", "Polish");
    form.append("paraname[]", "Sym");
    form.append("paraname[]", "Lab");

    if(ShapeIds != null){
      form.append("shape_id", ShapeIds);
    }
    if(setting_mincarat != null){
      form.append("setting_mincarat", setting_mincarat);
    }
    if(setting_maxcarat != null){
      form.append("setting_maxcarat", setting_maxcarat);
    }

    form.append("is_b2b", is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b);
    form.append("currency_id", defaultCurrency?.id == null ? null : defaultCurrency?.id);

    axios.post(BASE_URL + GetAPIUrl.PARAMETERS, form)
      .then(response => {
        if (response.data.success == true) {
          setLoading(false)
          setShapes(response?.data?.data?.Shape)
          setClarity(response?.data?.data?.Clarity)
          setColor(response?.data?.data?.Color)
          setCut(response?.data?.data?.Cut)
          setFluorescence(response?.data?.data?.Fluor)
          setPolish(response?.data?.data?.Polish)
          setSym(response?.data?.data?.Sym)
          setLab(response?.data?.data?.Lab)
          // console.log("min", typeof setting_mincarat)
          // console.log("max", typeof response?.data?.data?.Price.max)
          // console.log("Carat", typeof response?.data?.data?.Carat.min)
          // console.log("Caratmax", typeof response?.data?.data?.Carat.min)


          setMinPrice(response?.data?.data?.Price.min)
          setMaxPrice(response?.data?.data?.Price.max)

          setMinPricetemp(response?.data?.data?.Price.min)
          setMaxPricetemp(response?.data?.data?.Price.max)


          // setMinPrice(mincarat == undefined ? "" : b64_to_utf8(mincarat) == "null" ? response?.data?.data?.Price.min : b64_to_utf8(mincarat))
          // setMaxPrice(maxcarat == undefined ? "" : b64_to_utf8(maxcarat) == "null" ? response?.data?.data?.Price.max : b64_to_utf8(maxcarat))
          setFixedMinPrice(parseFloat(response?.data?.data?.Price.min))
          setFixedMaxPrice(parseFloat(response?.data?.data?.Price.max))

          setMinCarat(setting_mincarat == null ? response?.data?.data?.Carat.min : JSON.parse(setting_mincarat))
          setMaxCarat(setting_maxcarat == null ? response?.data?.data?.Carat.max : JSON.parse(setting_maxcarat))

          setFixedMinCarat(setting_mincarat == null ? response?.data?.data?.Carat.min : JSON.parse(setting_mincarat))
          setFixedMaxCarat(setting_maxcarat == null ? response?.data?.data?.Carat.max : JSON.parse(setting_maxcarat))

          // setFixedMinCarat(response?.data?.data?.Carat.min)
          // setFixedMaxCarat(response?.data?.data?.Carat.max)

          setMinTable(response?.data?.data?.Table.min)
          setMaxTable(response?.data?.data?.Table.max)
          setFixedMinTable(response?.data?.data?.Table.min)
          setFixedMaxTable(response?.data?.data?.Table.max)

          setMinDepth(response?.data?.data?.Depth.min)
          setMaxDepth(response?.data?.data?.Depth.max)
          setFixedMinDepth(response?.data?.data?.Depth.min)
          setFixedMaxDepth(response?.data?.data?.Depth.max)


        } else {
          setLoading(false)
          ShowErrorMessage(response?.data?.message)
        }
      })
      .catch(error => {
        ShowErrorMessage(error.message)

      });
    controller.abort()

  }


  const updatePrice = async (res,labrCharge,othrCharge,sideDiamondAmount,marginrate)=> {
    // let metalPrice = prodcutMetalPrice;             
    let metalPrice = res.metalprice;             
    // let sideStonePrice = prodcutSideDiaPrice;        
    console.log("metalPrice",metalPrice)
    // console.log("sideStonePrice",sideStonePrice)
    let sideStonePrice = sideDiamondAmount;
    
    let centerStonePrice = res?.centerstoneprice;
    let markup = res?.mark_up;
    let tagfact = res?.tag_fact;

    console.log("jewelryDetail",res)
    console.log("centerStonePrice",centerStonePrice)
    console.log("markup",markup)
    console.log("tagfact",tagfact)
    
    let metalWeight = res.metalweight;
    console.log("metalWeight",metalWeight)
    console.log("labourtCharge",labrCharge)
    console.log("otherCharge",othrCharge)

    if(is_b2b?.is_b2b == 1){
        if(customerGroup != null && customerGroup?.group_type == "sidediamond")
        {
            let sideDiaDisc = customerGroup?.discount;
            let discontPrice = (sideStonePrice * sideDiaDisc)/100;
            console.log("customerGroup",customerGroup);
            console.log("res?.sidestoneprice",sideStonePrice);
            setSideDiaPrice((sideStonePrice - discontPrice));
            sideStonePrice = (sideStonePrice - discontPrice);
        }else{
            setSideDiaPrice(sideStonePrice);
        }   
    }else{
        setSideDiaPrice(sideStonePrice);
    }


    let chainWeight = (res?.chainweight != null) ? res?.chainweight : 0;
    let totalWeight = metalWeight + chainWeight;
        totalWeight = totalWeight.toFixed(2);
    let finalLabCharge =  labrCharge * totalWeight;

    if(is_b2b?.is_b2b == 1){
        if(customerGroup != null && customerGroup?.group_type == "labour")
        {
            let labourDisc = customerGroup?.discount;
            let discontLabourPrice = (finalLabCharge * labourDisc)/100;
            finalLabCharge=finalLabCharge-discontLabourPrice;
        }
    }
    console.log("labourCharge",finalLabCharge)

    let totalMetalPrice = metalPrice + finalLabCharge + othrCharge;
        finalLabCharge =  finalLabCharge.toFixed(2);
    let totalMFGCost = totalMetalPrice + sideStonePrice;
    console.log("totalMFGCost",totalMFGCost)

    let GST = 0;   
    let finalPrice = 0;     
    if( markup > 0 && tagfact > 0){
        let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);
        let finalTagfact = finalMarkup/tagfact;
            finalPrice = finalTagfact + centerStonePrice;
            GST = finalTagfact - totalMFGCost;
            GST = GST.toFixed(2);
    }else if(markup > 0 && tagfact == 0){
        let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);          
            finalPrice = finalMarkup + centerStonePrice;
             GST = finalMarkup - totalMFGCost;
             GST = GST.toFixed(2);
    }else if(markup == 0 && tagfact > 0){           
        let finalTagfact = totalMFGCost/tagfact;
            finalPrice = finalTagfact + centerStonePrice;
            GST = finalTagfact - totalMFGCost;
            GST = GST.toFixed(2);
    }else{
           finalPrice = totalMFGCost + centerStonePrice;
    }

    let isb2bgetdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
    // console.log("globalJewelryMargin",globalJewelryMargin)
    console.log("globalJewelryMargin",marginrate)
    console.log("isb2bgetdata",isb2bgetdata)
    if(marginrate > 0 && isb2bgetdata == 0){
        finalPrice = finalPrice + ((finalPrice*marginrate)/100);
    }

    console.log("finalPrice",finalPrice)

    if(is_b2b?.is_b2b == 1){
        if(customerGroup != null && customerGroup?.group_type == "fulljewelry")
        {
            let finalDisc = customerGroup?.discount;
            let discontfinalPrice = (finalPrice * finalDisc)/100;
            finalPrice=finalPrice-discontfinalPrice;
        }
    }
    
     setFinalGST(GST);
    //  setFinalAmount(finalPrice.toFixed(2));
     setSideDiaPrice(sideStonePrice);
     setCenterDiaPrice(centerStonePrice);
    //  setMetalAmount(metalPrice);
    //  setProdcutMetalPrice(metalPrice);
     setMetalAmount(res.metalprice);
     setProdcutMetalPrice(res.metalprice);
     
     await axios.post(BASE_URL + GetAPIUrl.CURRENCYCONVERT,{currency_id:defaultCurrency?.id == null ? null : defaultCurrency?.id,amount:finalPrice}, {
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          // 'Authorization': 'Bearer ' + token,
      }
  })
      .then(response => {
          console.log("response",response)
          if(response?.data?.success == true)
          {
              setFinalAmount(response?.data?.amount)
          }  
      }).catch(error => {
      }).finally(() => {
          setIsLoading(false);
      })
     
    //  setSideDiaData(jewelryDetail?.sideStone); 
    // setProdcutMetalPrice(jewelryDetail?.sync_metal_price);                
    // setProdcutSideDiaPrice(jewelryDetail?.sync_side_dia_price); 
    // setProdcutMetalWeight(jewelryDetail?.metalweight);
    console.log("final",finalPrice.toFixed(2))
}


  const setSideStonePrice = async (res,labourCharge,otherCharge,marginrate)=> {
    setIsLoading(true);
    let tempSideDiaData = res?.jewelry_diamonds.filter(td => td.diamondtype === 'Side');
    console.log("tempSideDiaData",tempSideDiaData)

    let stoneGrpId = localStorage.getItem("sideDiaColGrp");

    await axios.post(BASE_URL + GetAPIUrl.STONGROUPPRICE_URL,{stoneGrpId}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token,
        }
    })
        .then(response => {
            console.log("response",response)
            if(response?.data?.type == "success")
            {
                let stoneGrpDetail = response?.data?.data?.stone_group_detail;            
                let sideDiamondAmount = 0;
                $.each(tempSideDiaData,function(sideIndex,sideItem){   
                    console.log("sideItem --- ",sideItem);
                    let sideStoneSize = sideItem?.fromsize_name != null ? sideItem?.fromsize_name : null;

                    let sideStoneShape = sideItem?.shape != undefined ? sideItem?.shape : null;
                    let sideWeight = sideItem?.totalweight != undefined ? sideItem?.totalweight : null;
                    console.log("sideStoneSize",sideStoneSize)
                    console.log("sideStoneShape",sideStoneShape)
                    console.log("sideWeight",sideWeight)
                    // let sideStoneSize = sideItem?.side_diam_data?.side_stone_size != undefined ? sideItem?.side_diam_data?.side_stone_size : [];
                    //     sideStoneSize = $.isEmptyObject(sideStoneSize) === true ? sideItem?.side_stone_size : sideStoneSize;
                    
                    // let sideStoneShape = sideItem?.side_diam_data?.shape?.id != undefined ? sideItem?.side_diam_data?.shape?.id : null;                
                    //     sideStoneShape = sideStoneShape === null ? sideItem?.shape?.id : sideStoneShape;
    
                    // let sideWeight = sideItem?.side_diam_data?.totalweight != undefined ? sideItem?.side_diam_data?.totalweight : null;
                    //     sideWeight = sideWeight === null ? sideItem?.totalweight : sideWeight;
                       $.each(stoneGrpDetail,function(index,item){   
                        console.log("inner item",item)
                        //  if(item?.size?.paraname == sideStoneSize?.paraname && item?.shape == sideStoneShape)
                         if(item?.size?.paraname == sideStoneSize?.paraname && item?.shape == sideStoneShape)
                         {       
                            console.log("inner item  amount  - ",item?.amount)
                            sideDiamondAmount = sideDiamondAmount + (item?.amount * sideWeight);
                         }
    
                       });
                });
                console.log("sideDiamondAmount1",sideDiamondAmount)
                sideDiamondAmount =  sideDiamondAmount + (globalMarkup * sideDiamondAmount / 100);
                sideDiamondAmount =  sideDiamondAmount / globalTagFact ;
                console.log("sideDiamondAmount2>>>",sideDiamondAmount)
                
                setProdcutSideDiaPrice(sideDiamondAmount);
                updatePrice(res,labourCharge,otherCharge,sideDiamondAmount,marginrate)
            }  
        }).catch(error => {
        }).finally(() => {
            setIsLoading(false);
        })
}

  const CalljewelryDetailApi = async (marginrate) => {
    const controller = new AbortController();
    let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
    let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;

    setIsLoading(true)
    await axios.get(BASE_URL + GetAPIUrl.JEWELRYDETAIL_URL + `/${JewelryId}` + '?is_b2b=' + getdata+'&currency_id='+currency_id)
      .then(response => {
        if (response.data.success == true) {
          
          console.log("res",response?.data?.data)

          setjewelryDetail(response?.data?.data);
          setSideDiaGrpId(localStorage.getItem("lastname"));
          console.log("response?.data?.data",response?.data?.data);
          // setFinalAmount(response?.data?.data?.settingprice);
          // setFinalAmount(response?.data?.data?.settingprice);
          setSideDiaPrice(response?.data?.data?.sidestoneprice);
          setCenterDiaPrice(response?.data?.data?.centerstoneprice);
          setMetalAmount(response?.data?.data?.metalprice);
          setProdcutMetalPrice(response?.data?.data?.metalprice);
          setProdcutMetalWeight(response?.data?.data?.metalweight); 

          setLabourtCharge(response?.data?.labourCharge);
          setOtherCharge(response?.data?.otherCharge);

          setSideStonePrice(response?.data?.data,response?.data?.labourCharge,response?.data?.otherCharge,marginrate);
        } else {
          ShowErrorMessage(response.data.message)
        }
      })

      .catch(error => {
        ShowErrorMessage(error.message)
      }).finally(() => {
        setIsLoading(false)
        
      })
    controller.abort()
  }


  const CallDiamondDetailApi = async () => {
    setIsLoading(true)
    const controller = new AbortController();

    axios.get(BASE_URL + GetAPIUrl.DIAMONDDETAIL_URL + `/${DiamondId}`)
      .then(response => {
        if (response.data.success == true) {
          setDiamondDetail(response.data.data);
          // setSelecteImage(response.data.data.images[0])
        } else {
          ShowErrorMessage(response.data.message)
        }
      }).catch(error => {
        ShowErrorMessage(error.message)
      }).finally(() => {
        setIsLoading(false)
      })
    controller.abort()
  }
  const DiamondListsApi = async (Currentpage, SortColumn, SortOrder) => {

    const controller = new AbortController
    let count = length * (Currentpage - 1)

    // setIsLoading(true);

    setIsSkeleton(true);
    var form = new FormData();
    form.append("draw", Currentpage);
    form.append("start", Currentpage == 1 ? 0 : count);
    form.append("length", length);
    form.append("filter[Shape]", shapeId);
    form.append("filter[ColorName]", ColorId);
    form.append("filter[ClarityName]", ClarityId);
    form.append("filter[CutName]", CutId);
    form.append("filter[PolishName]", PolishId);
    form.append("filter[SymName]", SymId);
    form.append("filter[Fluor]", FluorescenceId);
    form.append("filter[LAB]", LabId);
    form.append("filter[DiaType][]", selectedDiaType);
    form.append("filter[CaratMin]", minCarat);
    form.append("filter[CaratMax]", maxCarat);
    form.append("filter[PriceMin]", minPrice);
    form.append("filter[PriceMax]", maxPrice);
    form.append("filter[DepthMin]", minDepth);
    form.append("filter[DepthMax]", maxDepth);
    form.append("filter[TableMin]", minTable);
    form.append("filter[TableMax]", maxTable);
    form.append("filter[SortColumn]", SortColumn);
    form.append("filter[SortOrder]", SortOrder);
    form.append("is_b2b", is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b);
    form.append("currency_id", defaultCurrency?.id == null ? null : defaultCurrency?.id);


    await axios.post(BASE_URL + GetAPIUrl.DIAMONDLIST_URL, form).then((json) => {
      if (json.status == 200) {
        setFilterCount(json.data.recordsFiltered);
        setList(json.data.data);
        setFilterList(json.data.data);
        // setIsLoading(false);
        setIsSkeleton(false);
      } else {
        // setIsLoading(false);
        setIsSkeleton(false);
        ShowErrorMessage(json.data.message)
      }

    }).catch(error => {
      ShowErrorMessage(error.message)
    });
    controller.abort()
  };




  const handleFilterShape = (item) => {
    if (buildYourOwn == true) {
      if (ShapeIds == null) {

        let array = [...selectedShape];
        let selectedIndex = array.findIndex((d) => d.id == item.id);
        if (array?.length > 0 && selectedIndex >= 0) {
          array.splice(selectedIndex, 1);
        } else {
          array.push(item);
        }
        let ShapeId = array?.map((item) => {
          return item.id;
        });
        setShapeId([...ShapeId]);
        setCurrentpage(1);

        setSelectedShape(array);
      }
    } else {
      if (ShapeIds != null) {

        let array = [...selectedShape];
        let selectedIndex = array.findIndex((d) => d.id == item.id);
        if (array?.length > 0 && selectedIndex >= 0) {
          array.splice(selectedIndex, 1);
        } else {
          array.push(item);
        }
        let ShapeId = array?.map((item) => {
          return item.id;
        });
        setShapeId([...ShapeId]);
        setCurrentpage(1);

        setSelectedShape(array);
      }
    }

  };
  const handleFilterClarity = (item) => {
    let array = [...selectedClarity];
    let selectedIndex = array.findIndex((d) => d.id == item.id);
    if (array?.length > 0 && selectedIndex >= 0) {
      array.splice(selectedIndex, 1);
    } else {
      array.push(item);
    }
    let ClarityId = array?.map((item) => {
      return item.id;
    });
    setClarityId(ClarityId);
    setCurrentpage(1);

    setSelectedClarity(array);
  }

  const handleFilterColor = (item) => {
    let array = [...selectedColor];
    let selectedIndex = array.findIndex((d) => d.id == item.id);
    if (array?.length > 0 && selectedIndex >= 0) {
      array.splice(selectedIndex, 1);
    } else {
      array.push(item);
    }
    let ColorId = array?.map((item) => {
      return item.id;
    });
    setColorId(ColorId);
    setCurrentpage(1);

    setSelectedColor(array);
  };

  const handleFilterCut = (item) => {
    let array = [...selectedCut];
    let selectedIndex = array.findIndex((d) => d.id == item.id);
    if (array?.length > 0 && selectedIndex >= 0) {
      array.splice(selectedIndex, 1);
    } else {
      array.push(item);
    }
    let CutId = array?.map((item) => {
      return item.id;
    });
    setCutId(CutId);
    setCurrentpage(1);

    setSelectedCut(array);
  };

  const handleInputChange = (event,type,flag) => {
    let value = event.target.value;
    if(type=='price' && flag=="min"){
      setMinPrice(value);
    }else if(type=='price' && flag=="max"){
      setMaxPrice(value);
    }else if(type=='carat' && flag=="min"){
      setMinCarat(value);
    }else if(type=='carat' && flag=="max"){
      setMaxCarat(value);
    }else if(type=='table' && flag=="min"){
      setMinTable(value);
    }else if(type=='table' && flag=="max"){
      setMaxTable(value);
    }else if(type=='depth' && flag=="min"){
      setMinDepth(value);
    }else if(type=='depth' && flag=="max"){
      setMaxDepth(value);
    }

  }

  const handleTextChange = (event) => {
    event.preventDefault()
    if(event.target.value == "" && maxPrice == ""){
      setMinPrice(minPricetemp);
      setMaxPrice(maxPricetemp);
    }else{
      if(event.target.value == ""){
        setMinPrice(minPricetemp);
      }else{
        // if(minPricetemp < event.target.value) {setMinPrice(event.target.value);}
        // else{setMinPrice(minPricetemp);}
        if(event.target.value < fixedMinPrice) {setMinPrice(fixedMinPrice);}
        else if(event.target.value > fixedMaxPrice){setMinPrice(fixedMinPrice)}
      }
    }
    setCurrentpage(1);

  };
  const handleTextChange2 = (event) => {
    event.preventDefault()
    if(minPrice == "" && event.target.value == ""){
      setMinPrice(minPricetemp);
      setMaxPrice(maxPricetemp);
    }else{
      if(event.target.value == ""){
        setMaxPrice(maxPricetemp);
      }else{
        // if(maxPricetemp > event.target.value) {setMaxPrice(event.target.value);}
        // else{setMaxPrice(maxPricetemp);}
        if(event.target.value < fixedMinPrice) {setMaxPrice(fixedMaxPrice);}
        else if(event.target.value > fixedMaxPrice){setMaxPrice(fixedMaxPrice)}
      }
    }
    setCurrentpage(1);
  };

  const handleChange3 = (event, newValue) => {
    event.preventDefault()
    setMinPrice(newValue[0]);
    setMaxPrice(newValue[1]);
    setCurrentpage(1);
  };

  const handleCaratChange = (event, newValue) => {
    event.preventDefault()
    setMinCarat(newValue[0]);
    setMaxCarat(newValue[1]);
    setCurrentpage(1);
  };
  const handleCaratTextChange = (event) => {
    event.preventDefault()
    if(maxCarat == "" && event.target.value == ""){
      setMinCarat(fixedMinCarat);
      setMaxCarat(fixedMaxCarat);
    }else{
      if(event.target.value == ""){
        setMinCarat(fixedMinCarat);
      }else{
        if(event.target.value < fixedMinCarat) {setMinCarat(fixedMinCarat);}
        else if(event.target.value > fixedMaxCarat){setMinCarat(fixedMinCarat)}
        // if(fixedMinCarat < event.target.value) {setMinCarat(event.target.value);}
        // else{setMinCarat(fixedMinCarat);}
      }
      
    }
    setCurrentpage(1);

  };
  const handleCaratTextChange2 = (event) => {
    event.preventDefault()
    if(minCarat == "" && event.target.value == ""){
      setMinCarat(fixedMinCarat);
      setMaxCarat(fixedMaxCarat);
    }else{
      if(event.target.value == ""){
        setMaxCarat(fixedMaxCarat);
      }else{
        if(event.target.value < fixedMinCarat) {setMaxCarat(fixedMaxCarat);}
        else if(event.target.value > fixedMaxCarat){setMaxCarat(fixedMaxCarat)}
        // if(fixedMaxCarat > event.target.value) {setMaxCarat(event.target.value);}
        // else{setMaxCarat(fixedMaxCarat);}
      }
      
    }
    setCurrentpage(1);
  };


  const handleTableChange = (event, newValue) => {
    event.preventDefault()
    setMinTable(newValue[0]);
    setMaxTable(newValue[1]);
    setCurrentpage(1);
  };
  const handleTableTextChange = (event) => {
    event.preventDefault()
    if(event.target.value == "" && maxTable == ""){
      setMinTable(fixedMinTable);
      setMaxTable(fixedMaxTable);
    }else{
      if(event.target.value == ""){
        setMinTable(fixedMinTable);
      }else{
        if(event.target.value < fixedMinTable) {setMinTable(fixedMinTable);}
        else if(event.target.value > fixedMaxTable){setMinTable(fixedMinTable)}
      }
    }
    setCurrentpage(1);

  };
  const handleTableTextChange2 = (event) => {
    event.preventDefault()
    if(event.target.value == "" && minTable == ""){
      setMinTable(fixedMinTable);
      setMaxTable(fixedMaxTable);
    }else{
      if(event.target.value == ""){
        setMaxTable(fixedMaxTable);
      }else{
        if(event.target.value < fixedMinTable) {setMaxTable(fixedMaxTable);}
        else if(event.target.value > fixedMaxTable){setMaxTable(fixedMaxTable)}
      }      
    }
    setCurrentpage(1);
  };

  const handleDepthChange = (event, newValue) => {
    event.preventDefault()
    setMinDepth(newValue[0]);
    setMaxDepth(newValue[1]);
  };
  const handleDepthTextChange = (event) => {
    event.preventDefault()
    if(event.target.value == "" && maxDepth == ""){
      setMinDepth(fixedMinDepth);
      setMaxDepth(fixedMaxDepth);
    }else{
      if(event.target.value == ""){
        setMinDepth(fixedMinDepth);
      }else{
        if(event.target.value < fixedMinDepth) {setMinDepth(fixedMinDepth);}
        else if(event.target.value > fixedMaxDepth){setMinDepth(fixedMinDepth)}
      }      
    }

  };
  const handleDepthTextChange2 = (event) => {
    event.preventDefault()
    if(event.target.value == "" && minDepth == ""){
      setMinDepth(fixedMinDepth);
      setMaxDepth(fixedMaxDepth);
    }else{
      if(event.target.value == ""){
        setMaxDepth(fixedMaxDepth);
      }else{
        if(event.target.value < fixedMinDepth) {setMaxDepth(fixedMaxDepth);}
        else if(event.target.value > fixedMaxDepth){setMaxDepth(fixedMaxDepth)}
      }      
    }
  };



  const handleFilterFluor = (item) => {
    let array = [...selectedFluor];
    let selectedIndex = array.findIndex((d) => d.id == item.id);
    if (array?.length > 0 && selectedIndex >= 0) {
      array.splice(selectedIndex, 1);
    } else {
      array.push(item);
    }
    let FluorescenceId = array?.map((item) => {
      return item.id;
    });
    setFluorescenceId(FluorescenceId);
    setCurrentpage(1);

    setSelectedFluor(array);
  };

  const handleFilterPolish = (item) => {
    let array = [...selectedPolish];
    let selectedIndex = array.findIndex((d) => d.id == item.id);
    if (array?.length > 0 && selectedIndex >= 0) {
      array.splice(selectedIndex, 1);
    } else {
      array.push(item);
    }
    let PolishId = array?.map((item) => {
      return item.id;
    });
    setPolishId(PolishId);
    setCurrentpage(1);

    setSelectedPolish(array);
  };

  const handleFilterSym = (item) => {
    let array = [...selectedSym];
    let selectedIndex = array.findIndex((d) => d.id == item.id);
    if (array?.length > 0 && selectedIndex >= 0) {
      array.splice(selectedIndex, 1);
    } else {
      array.push(item);
    }
    let SymId = array?.map((item) => {
      return item.id;
    });
    setSymId(SymId);
    setCurrentpage(1);

    setSelectedSym(array);
  };


  const handleFilterLab = (item) => {
    let array = [...selectedLab];
    let selectedIndex = array.findIndex((d) => d.id == item.id);
    if (array?.length > 0 && selectedIndex >= 0) {
      array.splice(selectedIndex, 1);
    } else {
      array.push(item);
    }
    let LabId = array?.map((item) => {
      return item.id;
    });
    setLabId(LabId);
    setCurrentpage(1);

    setSelectedLab(array);
  };

  const openDetail = (index) => {
    let array = [...List];
    array.some((item, tag) => {
      if (tag == index) {
        setProdSelectedIndex(index);
        setProdSelected(!prodSelected);
      }
    });
  };



  const HandleSorting = (e) => {
    //  [ColorName,ClarityName,CutName,carat]
    if (e.target.name === SortColumn) {
      setSortOrder(SortOrder == "DESC" ? "ASC" : "DESC")
      DiamondListsApi(Currentpage, e.target.name, SortOrder == "DESC" ? "ASC" : "DESC")
    } else {
      DiamondListsApi(Currentpage, e.target.name, "DESC")
      setSortOrder("DESC")

    }
    setSortColumn(e.target.name)
  }

  const resetFilter = () => {
    setSelectedShape(ShapeIds == null ? [] : [{ id: JSON.parse(ShapeIds) }])
    setShapeId(ShapeIds == null ? [] : [JSON.parse(ShapeIds)])

    setClarityId([])
    setSelectedClarity([])

    setColorId([])
    setSelectedColor([])

    setCurrentpage(1)

    setCutId([])
    setSelectedCut([])

    setFluorescenceId([])
    setSelectedFluor([])

    setPolishId([])
    setSelectedPolish([])

    setSymId([])
    setSelectedSym([])

    // setMinPrice('')
    // setMaxPrice('')
    // setMinCarat('')
    // setMaxCarat('')
    // setMinTable('')
    // setMaxTable('')
    // setMinDepth('')
    // setMaxDepth('')

    setLabId([])
    setSelectedLab([])

    // setSelectedDiaType(slug == "Lab" || slug == "Natural" ? slug : 'Lab')
    // setSelectedDiaType(slug == "Natural" || slug == "Natural" ? slug : 'Natural')
    AllParameter()

  }
  // let sum = parseFloat(jewelryDetail?.settingprice) + parseFloat(DiamondDetail?.diamondprice)
  let sum = parseFloat(finalAmount) + parseFloat(DiamondDetail?.diamondprice)

  function onKeyPress(event) {
    if (isNaN(event.target.value)) {
      return;
    }
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
     if (/\+|-/.test(keyValue))
       event.preventDefault();
   }

    let BuildYourOwnUrl = process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own?jid=${JewelryId}`
    if (ring != '') {
      BuildYourOwnUrl = BuildYourOwnUrl + `&ring=${ring}`
    }
    if (ring != '') {
      BuildYourOwnUrl = BuildYourOwnUrl + `&shape=${ShapeIds}`
    }
    if (setting_mincarat != '') {
      BuildYourOwnUrl = BuildYourOwnUrl + `&mincarat=${setting_mincarat}`
    }
    if (setting_maxcarat != '') {
      BuildYourOwnUrl = BuildYourOwnUrl + `&maxcarat=${setting_maxcarat}`
    }

    useEffect(()=>{
      setTimeout(() => {
        $(".diamond-click-toggle").click(function () {
            $(this).toggleClass('roted-90');
            $(this).next().toggle();
        });
        
        $(".set-filter-click").click(function () {
            $(this).toggleClass('minus-ico');
            $(this).next().toggle();
        });   
      }, 2000);
    },[])

  return (
    <>

      <div className="container container-main">
        {
          isLoading == true &&
          <LoadingSpinner />
        }
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href={process.env.PUBLIC_URL + '/'} className="desk-top-title-sm a-taglink"><i className="fa fa-home" aria-hidden="true"></i></a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            {buildYourOwn == true ?
            <a href={BuildYourOwnUrl} className="desk-top-title-sm a-taglink">
            Diamond
            </a>
                  :
            <a href={process.env.PUBLIC_URL + '/DiamondList'} className="desk-top-title-sm a-taglink">
            Diamond
            </a>
            }
                
              </li>
          </ol>
        </nav>
      </div>
      {buildYourOwn == true ?

        <section className="mobile-view-none" >
          <div className="container container-main">
            <div className="wizard2-steps mb-3">
              <div className="step wizard2-steps-heading active-step keep-left">
                <div className="node">
                  <div className="node-skin">
                    <div className="cont">
                      <h2 className="nostyle-heading">Build Your Own Ring</h2>
                    </div>
                  </div>
                </div>
              </div>
              {JewelryId == null ?
                <div className="cyo-bar-step step step-item ">
                  <div className="node" >
                    <div className="node-skin">
                      <div className="pho">
                        <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg"} alt="setting" 
                        onError={(e)=>{
                          e.target.onerror=null
                          e.target.src=noimage
                      }}/>
                      </div>
                      <div className="cont">
                        <div className="action help-tips">
                          <a href="#" className="td-u bar-action line1-doted-2"></a>
                        </div>
                        <div className="heading">
                          <div className="action help-tips">
                            <a href="#" className="td-u bar-action">Choose</a>
                          </div>
                          <h2 className="nostyle-heading">setting</h2>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="cyo-bar-step step step-item active-step keep-left">
                  <div className="node" >
                    <div className="node-skin">
                      <div className="pho">

                        <img style={{ background: 'rgba(76, 175, 80, 0.1)' }} src={jewelryDetail?.default?.path == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg" : jewelryDetail?.default?.path} alt="setting" 
                        onError={(e)=>{
                          e.target.onerror=null
                          e.target.src=noimage
                      }}/>


                      </div>
                      <div className="cont">
                        <div className="heading"><h2 className="nostyle-heading">Setting</h2>
                          <div className="action help-tips">
                            <div className="td-u bar-action">{jewelryDetail?.title}
                              {/* <aside>Price :{defaultCurrency?.sign ?? "$"}{jewelryDetail?.settingprice}</aside> */}
                              {/* <aside>Price :{defaultCurrency?.sign ?? "$"}{finalAmount}</aside> */}
                              <aside>Price :{defaultCurrency?.sign ?? "$"}{JewelFinalAmt}</aside>
                            </div>
                          </div>
                          <div className="action double-action" style={{ display: 'flex' }}>
                            <a target="_blank" href={process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${jewelryDetail?.id}/${jewelryDetail?.title}?jid=${jewelryDetail?.id}`}>
                            View Details
                            {/* <button variant="primary" type="button" className="">View Details</button> */}
                              </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {DiamondId == null || DiamondId == undefined ?
                <div className="cyo-bar-step active-step step step-item ">
                  <div className="node" >
                    <div className="node-skin">
                      <div className="pho">
                        <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg"} alt="diamond" 
                        onError={(e)=>{
                          e.target.onerror=null
                          e.target.src=noimage
                      }}/>
                      </div>
                      <div className="cont">
                        <div className="action help-tips">
                          <a className="td-u bar-action line1-doted-2"></a>
                        </div>
                        <div className="heading">
                          <div className="action help-tips">
                            <a className="td-u bar-action">Choose</a>
                          </div>
                          <h2 className="nostyle-heading">Diamond</h2>


                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                :
                <div className="cyo-bar-step step step-item  active-step keep-left">
                  <div className="node" >
                    <div className="node-skin">
                      <div className="pho">
                        <img src={DiamondDetail.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : DiamondDetail.image} alt="diamond" style={{ backgroundColor: "white" }} 
                        onError={(e)=>{
                          e.target.onerror=null
                          e.target.src=noimage
                      }}/>
                      </div>
                      <div className="cont">
                        <div className="action help-tips">
                          <a href="#" className="td-u bar-action line1-doted-2"></a>
                        </div>
                        <div className="heading">
                          <h2 className="nostyle-heading">Diamond</h2>
                          <div className="action help-tips">
                            <div className="td-u bar-action">{DiamondDetail.title}
                              <aside>Price :{defaultCurrency?.sign ?? "$"}{DiamondDetail.diamondprice}</aside></div>
                          </div>
                          <div className="action double-action" style={{ display: 'flex' }}>
                                <a target='_blank' href={process.env.PUBLIC_URL + `/DiamondDetails/Build-Your-Own/${DiamondDetail.title}?did=${DiamondDetail.id}`}>
                                View Details
                                    {/* <button variant="primary" type="button" className="">View Details</button> */}
                                </a>
                          </div>
                        </div>                       

                      </div>
                    </div>
                  </div>
                </div>
              }

              {JewelryId != null && DiamondId != null ?
                <div className="step step-item invariant-color active-step keep-left">
                  <div className="node">
                    <div className="node-skin">
                      <div className="pho">
                        <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/ring.svg"} alt="ring" style={{ backgroundColor: "white" }} 
                        onError={(e)=>{
                          e.target.onerror=null
                          e.target.src=noimage
                      }}/>
                      </div>
                      <div className="cont">
                        <div className="action help-tips">TOTAL</div>
                        <div className="heading"><h2 className="nostyle-heading">{defaultCurrency?.sign ?? "$"}{sum.toFixed(2)}</h2></div>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="step step-item invariant-color">
                  <div className="node">
                    <div className="node-skin">
                      <div className="pho">
                        <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/ring.svg"} alt="ring" 
                        onError={(e)=>{
                          e.target.onerror=null
                          e.target.src=noimage
                      }}/>
                      </div>
                      <div className="cont">
                        <div className="heading"><h2 className="nostyle-heading">TOTAL</h2></div>
                      </div>
                    </div>
                  </div>
                </div>
              }

            </div>
          </div>
        </section>
        :
        <section>
          <div className="container">
            <div className="text-center max-80per">
              <h5 className="title-1 mb-2">
                Diamonds
              </h5>
              <p style={{ fontSize: "13px", color: "#7a7a7a" }}>
                Choose a diamond to complement the jewellery you wish to create. Our extensive selection of gems feature ten different shapes as well as a range of carat sizes to suit every preference. Each diamond featured in our catalogue is responsibly sourced and comes with certification from a leading grading organisation.
              </p>
            </div>
          </div>
        </section>

      }
      {
        !buildYourOwn &&
        <section className="pt-3 pb-5">
            <div className="text-center dia-tabs">
                <a className={"deactive-tab "+ (selectedDiaType == 'Natural' ? 'active-tab' : '') } onClick={()=>setSelectedDiaType('Natural')} >
                    Natural
                </a>
                <a className={"deactive-tab "+ (selectedDiaType == 'Lab' ? 'active-tab' : '') } onClick={()=>setSelectedDiaType('Lab')} >
                    Lab-Grown
                </a>            
            </div>
        </section>
      }

      <section className="pb-4">
        <div className="main-div" id="natural-id">
          <div className="set-filter-div set-filter-click">
            <h6>
              Set Filters <span className="s1">+</span>
              <span className="s2">−</span>
            </h6>
          </div>

          {
            activeFilterAll ?
          <div
            className="container container-main hide-all-setfilter"
            style={{transition: "all .5s ease-in-out"}}
          >
            <div className="">
              <div className="row">
                <div className="col-lg-4 mb-3 mob-sm-mb-10">
                  <div>
                    <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                      Shape
                      <span
                        className="question"
                        data-toggle="tooltip"
                        title="Choose a diamond to complement the jewellery you wish to create. Our extensive selection of gems feature ten different shapes as well as a range of carat sizes to suit every preference."
                      >
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                    </h6>
                    <ul className="ul-left-0 color-list mob-filter-none">
                      {Loading == true ?
                        <DiamondFilterSkeleton />
                        :
                        shapes?.map((item, index) => {
                          let selected = selectedShape?.some((tag) => {
                            return JSON.parse(tag.id) === item.id;
                          });
                          return (
                            <li
                              key={Math.random()}
                              className={
                                selected
                                  ? "all-active-shape"
                                  : "hand line-h-2 clickli-shape"
                              }
                              data-toggle="tooltip"
                              title={item.description == null || item.description == "" ? item.name : item.description}
                            >
                              {/* <div style={{ cursor: 'default' }}
                                className="shapes"
                                onClick={() => { handleFilterShape(item) }}
                              > */}
                              <div style={{ cursor: buildYourOwn == true ? ShapeIds == null ? 'pointer' : 'default' : ShapeIds != null ? 'pointer' : 'default' }}
                                className="shapes"
                                onClick={() => { handleFilterShape(item) }}
                              >
                                <img src={item.image} width="27" 
                                onError={(e)=>{
                                  e.target.onerror=null
                                  e.target.src=noimage
                              }}/>
                              </div>
                              <p className="mb-0">{item.name}</p>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>

                <div className="col-lg-4 mb-3 mob-sm-mb-10">
                  <div>
                    <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                      Clarity
                      <span
                        className="question"
                        data-toggle="tooltip"
                        title="Inclusions are birthmarks that give each diamond its own personality and add up to form the diamond's clarity. Gemologists rate the clarity of gemstones on a scale of FL to I3, with FL being the highest level of clarity. The clarity of diamonds is determined by the microscopic imperfections or flaws in the diamond throughout the growing process."
                      >
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                    </h6>
                    <ul className="ul-left-0 color-list mob-filter-none">
                      {Loading == true ?
                        <DiamondFilterSkeleton />
                        :
                        Clarity?.map((item, index) => {
                          let selected = selectedClarity.some((tag) => {
                            return tag.id === item.id;
                          });

                          return (
                            <li
                              key={Math.random()}
                              className="hand line-h-2 clickli-shape"
                              data-toggle="tooltip"
                              title={item.description == null || item.description == "" ? item.name : item.description}
                            >
                              <div
                                className={
                                  selected ? "col-list all-active" : "col-list"
                                }
                                onClick={() => handleFilterClarity(item)}
                              >
                                <span>{item.name}</span>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 mb-3 mob-sm-mb-10">
                  <div>
                    <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                      Color
                      <span
                        className="question"
                        data-toggle="tooltip"
                        title="The color of a diamond is the soft tinge of yellow/brown that may be observed inside the diamond. Diamond color is graded by evaluating the diamond's body color face down on a pure white backdrop. All diamonds on the GIA D through Z scale are considered white, albeit the lower end may have a yellow tint."
                      >
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                    </h6>
                    <ul className="ul-left-0 color-list mob-filter-none">
                      {Loading == true ?
                        <DiamondFilterSkeleton />
                        :
                        Color?.map((item, index) => {
                          let selected = selectedColor?.some((tag) => {
                            return tag.id === item.id;
                          });
                          return (
                            <li
                              key={Math.random()}
                              className="hand line-h-2 clickli"
                              onClick={() => handleFilterColor(item)}
                              title={item.description == null || item.description == "" ? item.name : item.description}
                            >
                              <div
                                className={
                                  selected ? "col-list all-active" : "col-list"
                                }
                              >
                                <span>{item.name}</span>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 mb-3 mob-sm-mb-10">
                  <div>
                    <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                      Cut
                      <span
                        className="question"
                        data-toggle="tooltip"
                        title="Diamonds are cut to optimize a diamond's sparkle, fire, brilliance, and overall aesthetic appeal. The cut is a measurement of how light performs when it strikes a diamond. Light performance causes the radiance of diamonds; cutting determines quantity of light performance achieved."
                      >
                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                      </span>
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                    </h6>
                    <ul className="ul-left-0 color-list mob-filter-none">
                      {Loading == true ?
                        <DiamondFilterSkeleton />
                        :
                        Cut?.map((item, index) => {
                          let selected = selectedCut?.some((tag) => {
                            return tag.id === item.id;
                          });
                          return (
                            <li
                              key={Math.random()}
                              className="hand line-h-2 clickli"
                              title={item.description == null || item.description == "" ? item.name : item.description}
                              onClick={() => handleFilterCut(item)}
                            >
                              <div
                                className={
                                  selected ? "col-list all-active" : "col-list"
                                }
                              >
                                <span>{item.name}</span>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 mb-3 mob-sm-mb-10" >
                  <div>
                    <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                      Price
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>

                    </h6>
                    <div className="mob-filter-none">
                    <Slider
                      step={1}
                      className=""
                      value={[minPrice, maxPrice]}
                      min={fixedMinPrice}
                      max={fixedMaxPrice}
                      onChange={handleChange3}
                    />
                    <div className='grid grid-cols-2 space-x-14 row' style={{ justifyContent: 'space-between' }}>
                      <div style={{marginLeft:"10px"}}>
                        <span className='absolute'>{defaultCurrency?.sign ?? "$"}</span><input
                          className='ml-2 border relative px-2 dia-filter-input'
                          type="number"
                          id="min"
                          name="min"
                          value={minPrice}
                          onChange={(e) => handleInputChange(e,'price','min')}
                          // onKeyPress={onKeyPress}
                          onBlur={handleTextChange}
                        />
                      </div>
                      <div style={{ justifyContent: 'end', display: 'flex',marginRight:"10px" }}>
                        <span className='absolute'>{defaultCurrency?.sign ?? "$"}</span><input
                          className='ml-2 border relative px-2 dia-filter-input'
                          type="number"
                          id="max"
                          name="max"
                          value={maxPrice}
                          onChange={(e) => handleInputChange(e,'price','max')}
                          // onKeyPress={onKeyPress}
                          onBlur={handleTextChange2}

                        />
                      </div>
                    </div>
                    </div>

                  </div>
                </div>
                <div className="col-lg-4 mb-3 mob-sm-mb-10">
                  <div>
                    <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                      Carat
                      <span>
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </span>
                    </h6>
                    <div className="mob-filter-none">
                      <Slider
                        step={0.01}
                        value={[minCarat, maxCarat]}
                        min={fixedMinCarat}
                        max={fixedMaxCarat}
                        onChange={handleCaratChange}
                      />

                      <div className='grid grid-cols-2 space-x-14 row' style={{ justifyContent: 'space-between' }}>
                        <div style={{/*marginLeft:"10px"*/}}>
                          <span className='absolute'></span><input
                            className='ml-2 border relative px-2 dia-filter-input'
                            type="number"
                            id="min"
                            name="min"
                            value={minCarat}
                            onChange={(e) => handleInputChange(e,'carat','min')}
                            onBlur={handleCaratTextChange}
                            // onKeyPress={onKeyPress}
                          />
                        </div>
                        <div style={{ justifyContent: 'end', display: 'flex', marginRight:"10px" }}>
                          <span className='absolute'></span><input
                            className='ml-2 border relative px-2 dia-filter-input'
                            type="number"
                            id="max"
                            name="max"
                            value={maxCarat}
                            onChange={(e) => handleInputChange(e,'carat','max')}
                            onBlur={handleCaratTextChange2}
                            // onChange={handleCaratTextChange2}
                            // onKeyPress={onKeyPress}
                          />
                          {/* <InputRange
                        step={0.01}
                        maxValue={maxCarat}
                        minValue={minCarat}
                        value={sliderCarat}
                        onChange={(value) => setSliderCarat(value)}
                      /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                {
                  activeFilter ?
                <div className="more-option-filter" style={{ /*display: "none"*/transition: "all .5s ease-in-out" }}>
                  <div className="row">
                    <div className="col-lg-4 mb-3 mob-sm-mb-20">
                      <div>
                        <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                          Fluorescence
                          <span>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </h6>
                        <ul className="ul-left-0 color-list mob-filter-none">
                          {Loading == true ?
                            <DiamondFilterSkeleton />
                            :
                            Fluorescence?.map((item, index) => {
                              let selected = selectedFluor?.some((tag) => {
                                return tag.id === item.id;
                              });
                              return (
                                <li
                                  key={Math.random()}
                                  className="hand line-h-2 clickli"
                                  title={item.description == null || item.description == "" ? item.name : item.description}
                                  onClick={() => handleFilterFluor(item)}
                                >
                                  <div
                                    className={
                                      selected
                                        ? "col-list all-active"
                                        : "col-list"
                                    }
                                  >
                                    <span>{item.name}</span>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3 mob-sm-mb-20">
                      <div>
                        <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                          Polish
                          <span>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </h6>
                        <ul className="ul-left-0 color-list mob-filter-none">
                          {Loading == true ?
                            <DiamondFilterSkeleton />
                            :
                            Polish?.map((item, index) => {
                              let selected = selectedPolish?.some((tag) => {
                                return tag.id === item.id;
                              });
                              return (
                                <li
                                  key={Math.random()}
                                  className="hand line-h-2 clickli"
                                  title={item.description == null || item.description == "" ? item.name : item.description}
                                  onClick={() => handleFilterPolish(item)}
                                >
                                  <div
                                    className={
                                      selected
                                        ? "col-list all-active"
                                        : "col-list"
                                    }
                                  >
                                    <span>{item.name}</span>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3 mob-sm-mb-20">
                      <div>
                        <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                          Symmetry
                          <span>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </h6>
                        <ul className="ul-left-0 color-list mob-filter-none">
                          {Loading == true ?
                            <DiamondFilterSkeleton />
                            :
                            Sym?.map((item, index) => {
                              let selected = selectedSym?.some((tag) => {
                                return tag.id === item.id;
                              });
                              return (
                                <li
                                  key={Math.random()}
                                  className="hand line-h-2 clickli"
                                  title={item.description == null || item.description == "" ? item.name : item.description}
                                  onClick={() => handleFilterSym(item)}
                                >
                                  <div
                                    className={
                                      selected
                                        ? "col-list all-active"
                                        : "col-list"
                                    }
                                  >
                                    <span>{item.name}</span>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3 mob-sm-mb-10">
                      <div>
                        <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                          Table %{" "}
                          <span>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </h6>
                        <div className="mob-filter-none">
                        <div className='w-72'>
                          <Slider
                            step={0.1}
                            value={[minTable, maxTable]}
                            min={fixedMinTable}
                            max={fixedMaxTable}
                            onChange={handleTableChange}
                          />
                        </div>
                        <div className='grid grid-cols-2 space-x-14 row' style={{ justifyContent: 'space-between' }}>
                          <div>
                            <span className='absolute'></span>
                            <input
                              className='ml-2 border relative px-2 dia-filter-input'
                              type="number"
                              id="min"
                              name="min"
                              value={minTable}
                              // onChange={handleTableTextChange}
                              onChange={(e) => handleInputChange(e,'table','min')}
                              // onKeyPress={onKeyPress}
                              onBlur={handleTableTextChange}
                            />
                          </div>
                          <div style={{marginRight:"10px"}}>
                            <span className='absolute'></span><input
                              className='ml-2 border relative px-2 dia-filter-input'
                              type="number"
                              id="max"
                              name="max"
                              value={maxTable}
                              // onChange={handleTableTextChange2}
                              onChange={(e) => handleInputChange(e,'table','max')}
                              // onKeyPress={onKeyPress}
                              onBlur={handleTableTextChange2}
                            />
                          </div>
                        </div>
                        </div>
                        {/* <InputRange
                          formatLabel={(value) => `${value}%`}
                          step={0.01}
                          maxValue={maxTable}
                          minValue={minTable}
                          value={sliderTable}
                          onChange={(value) => setSliderTable(value)}
                        /> */}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3 mob-sm-mb-10">
                      <div>
                        <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                          Depth %{" "}
                          <span>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </h6>
                        <div className="mob-filter-none">
                          <div className='w-72'>
                            <Slider
                              step={0.1}
                              value={[minDepth, maxDepth]}
                              min={fixedMinDepth}
                              max={fixedMaxDepth}
                              onChange={handleDepthChange}
                            />
                            </div>
                          <div className='grid grid-cols-2 space-x-14 row' style={{ justifyContent: 'space-between' }}>
                            <div>
                              <span className='absolute'></span><input
                                className='ml-2 border relative px-2 dia-filter-input'
                                type="number"
                                id="min"
                                name="min"
                                value={minDepth}
                                // onChange={handleDepthTextChange}
                                onChange={(e) => handleInputChange(e,'depth','min')}
                                // onKeyPress={onKeyPress}
                                onBlur={handleDepthTextChange}
                              />
                            </div>
                            <div style={{marginRight:"10px"}}>
                              <span className='absolute'></span><input
                                className='ml-2 border relative px-2 dia-filter-input'
                                type="number"
                                id="max"
                                name="max"
                                value={maxDepth}
                                // onChange={handleDepthTextChange2}
                                onChange={(e) => handleInputChange(e,'depth','max')}
                                // onKeyPress={onKeyPress}
                                onBlur={handleDepthTextChange2}
                              />
                            </div>
                            {/* <InputRange
                              formatLabel={(value) => `${value}%`}
                              step={0.01}
                              maxValue={maxDepth}
                              minValue={minDepth}
                              value={sliderDepth}
                              onChange={(value) => setSliderDepth(value)}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 mb-3 mob-sm-mb-10">
                      <div>
                        <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                          Lab
                          <span>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </h6>
                        <ul className="ul-left-0 color-list mob-filter-none">
                          {Loading == true ?
                            <DiamondFilterSkeleton />
                            :
                            Lab?.map((item, index) => {
                              let selected = selectedLab?.some((tag) => {
                                return tag.id === item.id;
                              });
                              return (
                                <li
                                  key={Math.random()}
                                  className="hand line-h-2 clickli"
                                  title={item.name}
                                  onClick={() => handleFilterLab(item)}
                                >
                                  <div
                                    className={
                                      selected
                                        ? "col-list all-active"
                                        : "col-list"
                                    }
                                  >
                                    <span>{item.name}</span>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 mb-3 mob-sm-mb-10">
                      <div>
                        <h6 className="mb-2 mobile-diamond-title title-upper diamond-click-toggle">
                          Diamond Type
                          <span>
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </h6>
                        <ul className="ul-left-0 color-list mob-filter-none">
                          {DiaType?.map((item, index) => {
                            return (
                              <li
                                key={Math.random()}
                                className="hand line-h-2 clickli"
                                title={item.type}
                                onClick={() => setSelectedDiaType(item.type)}
                              >
                                <div
                                  className={
                                    item.type == selectedDiaType
                                      ? "col-list all-active"
                                      : "col-list"
                                  }
                                >
                                  <span>{item.DiaName}</span>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
                :
                null
                  }

              
              <div className="row">
                <div className="col-lg-12 mb-1">
                  <div className="bor-top-filter">

                    <a className="more-filter-btn advance-btn hand mar-bot-20 show-adv-option" onClick={() => setActiveFilter(!activeFilter)}>
                      { activeFilter ? 
                        <>
                          <span className="showadvtxt" style={{ /*display: 'none',*/ lineHeight: 1, fontFamily: 'Jost Medium' }}>Hide Filters Options &nbsp;</span>
                          <i className="fa fa-chevron-up upcl" aria-hidden="true" style={{ /*display: 'none',*/ fontSize: 12 }}></i>
                        </>
                      :
                        <>
                          <span className="hideadvtxt" style={{ lineHeight: 1, fontFamily: 'Jost Medium' }}>Show Filters Options &nbsp;</span>
                          <i className="fa fa-chevron-down downcl" aria-hidden="true" style={{ fontSize: 12 }}></i>
                        </>
                      }
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>
          :
          null
          }


        </div>
        <div
          style={{
            // display: "flex",
            justifyContent: "flex-end",
            // paddingRight:135
          }}
        >
          <div className="row" style={{
            // paddingRight: 20,
          }}>
            <div className="col-lg-6 mb-1" >
              <div className="bor-top-filter">

                <a className="more-filter-btn advance-btn hand mar-bot-20 show-all-adv-option" onClick={() => setActiveFilterAll(!activeFilterAll)}>
                  { activeFilterAll ? 
                    <>
                      <span className="showalladvtxt" style={{ lineHeight: 1, fontFamily: 'Jost Medium' }}>Hide Filters &nbsp;</span>
                      <i className="fa fa-chevron-up upclall" aria-hidden="true" style={{ fontSize: 12 }}></i>
                    </>
                  :
                    <>
                      <span className="hidealladvtxt" style={{ /*display: 'none',*/ lineHeight: 1, fontFamily: 'Jost Medium' }}>Show Filters &nbsp;</span>
                      <i className="fa fa-chevron-down downclall" aria-hidden="true" style={{ /*display: 'none',*/ fontSize: 12 }}></i>
                    </>
                  }
                </a>
              </div>
            </div>
            <div className="col-lg-6 mb-1">
              <div className="bor-top-filter1">

                <a className="more-reset-filter-btn advance-btn hand mar-bot-20 " onClick={() => resetFilter()}>
                  <span className="" style={{ lineHeight: 1, textAlign: 'center', fontFamily: 'Jost Medium' }}>Reset Filters &nbsp;</span>
                  <i className="fa fa-reply" aria-hidden="true" style={{ fontSize: 12 }}></i>
                </a>
              </div>
            </div>
          </div>

        </div>
        
      </section>
      <div className="container container-main">
        <div
          style={{
            backgroundColor: "#efefef",
            padding: 15,
            marginBottom: 10,
          }}
        >
          <div className="searchflex-div">
            <div>
              <h6 style={{ fontWeight: 600, marginBottom: 0, fontSize: 14 }}>SEARCH RESULT ({filterCount})</h6>
            </div>
            <div className="thumb-flex-div first-afilter">
              <a className="table-list-click hand ataglist-thumb ataglist-thumb-active" data-toggle="tooltip" title="List View">
                <i className="fa fa-list" aria-hidden="true"></i>
              </a>
              <a className="thumblist-list-click hand ataglist-thumb" data-toggle="tooltip" title="Grid View">
                <i className="fa fa-th" aria-hidden="true"></i>
              </a>

            </div>
          </div>

        </div>
      </div>
      <>
        <div className="container container-main dia-list-divbg mb-5 table-view-data" style={{ backgroundColor: 'white' }}>
          <div className="row">
            <div className="col-lg-12" style={{ backgroundColor: 'white', paddingRight: '0%', paddingLeft: '0%' }}>
              <div className="table-responsive details-table mar-bot-25px search-result-tbl-main">
                {isLoading || isSkeleton ?
                  <DiamondListSkeleton />
                  : List?.length > 0 ?
                    <table
                      id="dia-table"
                      className="table table-striped1"
                      style={{ width: "100%" }}
                    >
                      <thead className="font-auto tbl-header">
                        <tr className="trth-center th-r-bor">
                          <th>SHAPE</th>
                          <th>Diamond Type</th>
                          <th style={{ cursor: 'pointer' }} scope="col"><a name='carat' onClick={(e) => HandleSorting(e)} className="sort-by">CARAT</a></th>
                          <th style={{ cursor: 'pointer' }} scope="col"><a name='ColorName' onClick={(e) => HandleSorting(e)} className="sort-by">COLOR</a></th>
                          <th style={{ cursor: 'pointer' }} scope="col"><a name='ClarityName' onClick={(e) => HandleSorting(e)} className="sort-by">CLARITY</a></th>
                          <th style={{ cursor: 'pointer' }} scope="col"><a name='CutName' onClick={(e) => HandleSorting(e)} className="sort-by">CUT</a></th>
                          <th>LAB
                            {/* <div>
                              <button>▲</button>
                              <button>▼</button>
                            </div>LAB */}
                          </th>
                          <th>CERTIFICATE NO.</th>
                          <th>MEASUREMENT</th>
                          <th>DISCOUNT</th>
                          <th style={{ cursor: 'pointer' }} scope="col"><a name='amount' onClick={(e) => HandleSorting(e)} className="sort-by">PRICE</a></th>
                          <th>VIEW</th>
                        </tr>
                      </thead>
                      {List?.map((item, index) => {
                        // console.log("item",item.video);
                        return (
                          <>
                            
                            <tbody className="tr-unhov-hand table-striped1-odd"  key={Math.random()}>
                              <tr
                                key={Math.random()}
                                style={{ cursor: 'pointer' }} onClick={() => openDetail(index)}
                                className="tr-hover trtd-center trtd-13 tr-hov-td add-row rm-row roted-180"
                              >
                                <td>{item.ShapeName}</td>
                                <td>{item.diamond_type}</td>
                                <td>{item.carat.toFixed(2)}</td>
                                <td>{item.ColorName}</td>
                                <td>{item.ClarityName}</td>
                                <td>{item.CutName}</td>
                                <td>{item.LabName}</td>
                                <td> <a href={item.cert_location} target="_blank">{item.cert_no}</a></td>
                                <td>{item.measurement}</td>
                                <td>{item.discount}%</td>
                                <td>{defaultCurrency?.sign ?? "$"}{item.diamondprice}</td>
                                {prodSelected == true &&
                                  prodSelectedIndex == index ? (
                                  <td>
                                    <a
                                      className="table-atag toggle-tr add-row"
                                      onClick={() => openDetail(index)}
                                    >
                                      <i className="fa fa-chevron-up  hand"></i>
                                    </a>
                                  </td>
                                ) : (
                                  <td>
                                    <a
                                      className="table-atag toggle-tr add-row"
                                      onClick={() => openDetail(index)}
                                    >
                                      <i className="fa fa-chevron-down  hand"></i>
                                    </a>
                                  </td>
                                )}
                              </tr>
                              {prodSelected == true && prodSelectedIndex == index && (
                                <tr
                                  className="row-detail bg-diamond-details"
                                  style={{ backgroundColor: "#fff" }}
                                >

                                  <td colSpan="12">
                                    {/* <Link
                                    aria-current="page"                                  
                                    to={process.env.PUBLIC_URL +`/DiamondDetails/${utf8_to_b64(item?.id)}/${item?.carat}-Carat-${item?.ShapeName} Diamond, ${item?.ColorName}-${item?.ClarityName}}`}
                                  > */}
                                    <div
                                      className="text-left"
                                      style={{ padding: "15px" }}
                                    >
                                      <div className="row m-0">
                                        <div className="col-lg-5">
                                          {item.video == null || item.video == '' ?
                                            <a href='https://media.giphy.com/media/cccpTlG85cCdEtyybs/giphy.mp4'>
                                              <iframe
                                                className="DVideoFrame" allow="autoplay"
                                                style={{
                                                  width: "100%",
                                                  minHeight: "300px",
                                                }}
                                                src='https://media.giphy.com/media/cccpTlG85cCdEtyybs/giphy.mp4'
                                              ></iframe>
                                            </a>
                                            :
                                            <div className="boximg-shadot box-h-w mar-l-94" key={Math.random()}>
                                              <iframe style={{ minWidth: "100%", minHeight: "350px" }} allow="autoplay"
                                                src={item?.video == null ? noimage : item?.video}></iframe>
                                            </div>
                                            // <a href={item.video}>
                                            //   <iframe
                                            //     className="DVideoFrame"
                                            //     allow="autoplay"
                                            //     style={{
                                            //       width: "100%",
                                            //       minHeight: "300px",
                                            //     }}
                                            //     src={item?.video}
                                            //   ></iframe>
                                            // </a>
                                          }
                                        </div>
                                        <div className="col-lg-7 order-lg-1">
                                          <div className="row">
                                            <div className="col-lg-8">
                                              {/* <h5>
                                                {item.carat} Carat {item.ShapeName}{" "}
                                                {item.diamond_type} Diamond
                                              </h5> */}
                                              <ul className="ulli-list-li">
                                                <li>Sku: {item.loatno}</li>
                                                <li>Diamond Type: {item.diamond_type}</li>
                                                <li>Shape: {item.ShapeName}</li>
                                                <li>Color: {item.ColorName}</li>
                                                <li>Clarity: {item.ClarityName}</li>
                                                <li>
                                                  Certifying Lab: {item.LabName}
                                                </li>
                                                <li>Cut: {item.CutName}</li>
                                                <li>Polish: {item.PolishName}</li>
                                                <li>Symmetry: {item.SymName}</li>
                                                <li>Fluor: {item.FluorName}</li>
                                                <li>
                                                  Measurement: {item.measurement}
                                                </li>
                                                <li>
                                                  Tabel Area(%): {item.tablearea}
                                                </li>
                                                <li>
                                                  Table Depth(%): {item.tabledepth}
                                                </li>
                                              </ul>
                                              <div
                                                className="pt-4"
                                                style={{ clear: "both" }}
                                              >
                                                <Link
                                                  className="view-diamond-btn"
                                                  aria-current="page"
                                                  target="_blank"
                                                  style={{
                                                    backgroundColor: Colors.peach,
                                                    padding: "5px 15px",
                                                    color: "black",
                                                    fontSize: "14px",
                                                  }}
                                                  // buildYourOwn == true ?
                                                  // did == null ? process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.slug}?jid=${item?.id}` : 
                                                  // process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${item?.slug}?jid=${item?.id}&did=${did}`
                                                  // :
                                                  // process.env.PUBLIC_URL + `/JDetails/${item?.id}/${item?.slug}`}
                                                  to={buildYourOwn == true ?
                                                    JewelryId == null ? `/DiamondDetails/Build-Your-Own/${utf8_to_b64(item?.id)}/${item?.title}?did=${item.id}&famount=${JewelFinalAmt}`
                                                      : `/DiamondDetails/Build-Your-Own/${utf8_to_b64(item?.id)}/${item?.title}?jid=${JewelryId}&did=${item.id}&ring=${ring}&famount=${JewelFinalAmt}`
                                                    :
                                                    `/DiamondDetails/${utf8_to_b64(item?.id)}/${item?.title}?did=${item.id}`
                                                  }
                                                >
                                                { buildYourOwn == true ? 'Choose Diamond' : 'View Details' }
                                                </Link>
                                                {/* <a
                                              className="view-diamond-btn"
                                              href="/DiamondDetails"
                                              style={{
                                                backgroundColor: Colors.peach,
                                                padding: "5px 15px",
                                                color: "black",
                                                fontSize: "14px",
                                              }}
                                            >
                                              View Details
                                            </a> */}
                                              </div>
                                            </div>
                                            <div className="col-lg-4">
                                              <a href={item.cert_location} target="_blank">
                                                <div className="igipdf-div">
                                                  <img
                                                    className="css-8atqhb"
                                                    src={
                                                      item.image == null
                                                        ? igi_cert
                                                        : item.image
                                                    }
                                                    alt="Diamond Foundry Certificate"
                                                    width="100%"
                                                    onError={(e)=>{
                                                      e.target.onerror=null
                                                      e.target.src=noimage
                                                  }}
                                                  />
                                                  <div
                                                    className="igi-link"
                                                    color="#000000"
                                                    style={{
                                                      cursor: 'pointer',
                                                      position: 'absolute',
                                                      width: '1.6rem',
                                                      height: '1.6rem',
                                                      left: '100px',
                                                    }}
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      height="15"
                                                      viewBox="0 0 12 11"
                                                      width="15"
                                                      role="img"
                                                    >
                                                      <title>
                                                        arrow-new-window
                                                      </title>
                                                      <path
                                                        d="m10.8391647.00024955c-.0237858.00070418-.0475108.00265368-.0710642.00583936h-3.10812503c-.23020519-.00304973-.44433052.11024764-.5603832.29650834-.11605269.18626069-.11605269.41662732 0 .60288801.11605268.18626069.33017801.29955806.5603832.29650833h1.65068514l-5.93199301 5.55675254c-.16675628.14997678-.23392949.37268354-.17561329.58223009.0583162.20954656.23300929.37318903.45670627.42781626s.46144282-.00829686.62154729-.16450467l5.93199273-5.55675224v1.54626737c-.0032556.21564309.1176925.41622348.3165312.52493502.1988386.10871154.4447616.10871154.6436002 0 .1988387-.10871154.3197869-.30929193.3165312-.52493502v-2.91384978c.0257311-.17425026-.0317435-.35027464-.1571283-.48122829-.1253847-.13095365-.3059601-.20355224-.4936702-.19847532zm-9.56250212.00577931c-.69773619 0-1.27666258.54236527-1.27666258 1.19596473v8.37133273c0 .65359958.57892639 1.19590468 1.27666258 1.19590468h8.93663802c.6977363 0 1.2767267-.5423051 1.2767267-1.19590468v-3.58771402c.0031916-.21564309-.1177566-.41622348-.3165953-.52493502-.1988386-.10871154-.4447616-.10871154-.6436002 0-.1988387.10871154-.3197868.30929193-.3165312.52493502v3.58771402h-8.93663802v-8.37133273h3.82998773c.23020519.00304973.44433052-.11024764.5603832-.29650833.11605269-.18626069.11605269-.41662732 0-.60288801-.11605268-.1862607-.33017801-.29955807-.5603832-.29656839z"
                                                        transform="translate(.252263)"
                                                      ></path>
                                                    </svg>
                                                  </div>
                                                </div>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* </Link> */}
                                  </td>

                                </tr>
                              )}
                            </tbody>
                          </>
                        );
                      })}
                    </table>
                    :
                    <div
                      style={{ display: "grid", justifyContent: "center", flex: 1, backgroundColor: '#f1ecf0' }}
                    >
                      <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                      <img
                        src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/diamgif.gif"}
                        alt="loading..."
                        style={{ width: 150, height: 150 }}
                        onError={(e)=>{
                          e.target.onerror=null
                          e.target.src=noimage
                      }}
                      />
                      </div>
                      <h4 style={{ color: '#502c1e' }}>No Diamond data Found</h4>
                    </div>

                }
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', paddingTop: 30 }}>
            <Pagination
              color='primary'
              count={count}
              size="large"
              page={Currentpage}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>


        </div>

        <div className="thumblist-view-data mb-5 ">
          <div className="container container-main ">
            <div className="product-page-div">
              {isLoading ?
                // <p style={{ textAlign: "center" }}>Processing...</p>
                <DiamondGridSkeleton />
                : List?.length == 0 ?
                  <div
                    style={{ display: "grid", justifyContent: "center", flex: 1, backgroundColor: '#f1ecf0' }}
                  >
                    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <img
                      src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/diamgif.gif"}
                      alt="loading..."
                      style={{ width: 150, height: 150 }}
                      onError={(e)=>{
                        e.target.onerror=null
                        e.target.src=noimage
                    }}
                    />
                    </div>
                    <h4 style={{ color: '#502c1e' }}>No Diamond data Found</h4>
                  </div>
                  :
                  List?.map((item, index) => {
                    return (
                      <div className="product-div find-img" key={Math.random()}>
                        <div className="product-div-div">
                          <Link
                            className="view-diamond-btn"
                            aria-current="page"
                            style={{
                              // padding: "5px 15px",
                              // color: "black",
                              // fontSize: "14px",
                            }}
                            target="_blank"
                            // to={JewelryId == null ? `/DiamondDetails/${utf8_to_b64(item?.id)}/${item?.title}}` : `/DiamondDetails/Build-Your-Own/${utf8_to_b64(item?.id)}/${item?.title}?jid=${JewelryId}&did=${item.id}`}
                            to={buildYourOwn == true ?
                              JewelryId == null ? `/DiamondDetails/Build-Your-Own/${utf8_to_b64(item?.id)}/${item?.title}?did=${item.id}`
                                : `/DiamondDetails/Build-Your-Own/${utf8_to_b64(item?.id)}/${item?.title}?jid=${JewelryId}&did=${item.id}&ring=${ring}`
                              :
                              `/DiamondDetails/${utf8_to_b64(item?.id)}/${item?.title}?did=${item.id}`
                            }
                          >
                            <div className="product-div-box">
                              <div className="product-div-list">
                                <img
                                  src={item.image == null ? noimage : item.image}
                                  className="inner-img-product"
                                  onError={(e)=>{
                                    e.target.onerror=null
                                    e.target.src=noimage
                                }}
                                />
                              </div>
                              <div className="text-center show-viewbtn">
                                <h5 className="product-title pt-3 line1-doted-3">
                                  {item.title}
                                  {/* {parseFloat(item.carat).toFixed(2)} Carat {item.ShapeName}{" "}
                                  {item.diamond_type} Diamond {" "} */}
                                  <br />
                                  Certificate no: {item.cert_no}
                                </h5>
                                <p className="product-title-price mb-0">
                                {defaultCurrency?.sign ?? "$"}{item.diamondprice}
                                </p>
                                <div className="pt-2 view-details-btn"
                                >
                                  <span className="span-link">
                                    { buildYourOwn == true ? 'Choose Diamond' : 'View Details' }
                                  </span>
                                  <span>
                                    &nbsp;
                                    <svg
                                      width="8"
                                      height="11"
                                      viewBox="0 0 8 11"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M1 10L6 5.5L1 1"
                                        stroke="#23282D"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                      />
                                    </svg>
                                  </span>

                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}

            </div>
            <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', paddingTop: 30 }}>
              <Pagination
                color='primary'
                count={count}
                size="large"
                page={Currentpage}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </div>

          </div>
        </div>
      </>


    </>
  );
};

export default DiamondList;
