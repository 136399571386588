import axios from "axios";
import React, { useState } from "react";
import { BASE_URL, GetAPIUrl } from "../API/APIUrl";
import logo from '../assets/images/logo-img.svg'
import { ShowErrorMessage, ShowMessage } from "../module/Tostify";
import moment from "moment";
import { Link } from "react-router-dom";

const $ = window.$;

export default function Nav(props) {
    const [email, setEmail] = useState('')
    const [disable, setDisable] = useState(false)
    const [disableMsg, setDisableMsg] = useState('Subscribe')

    const CallEmailApi = async () => {
        setDisable(true)
        setDisableMsg("Please Wait...")
        let data = {
            "email": email
        }
        await axios.post(BASE_URL + GetAPIUrl.HOME_SUBSCRIBE_URL, data, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(response => {

            if (response.data.success == true) {
                setEmail("")
                ShowMessage(response.data.message)
                setDisable(false)
                setDisableMsg("Subscribe")
            }
             else {
                setDisable(false)
                ShowErrorMessage(response.errors.email)
                setDisableMsg("Subscribe")
            }
        }).catch(error => {
            setDisable(false)
            if(error?.response?.data?.errors?.email != undefined){
                ShowErrorMessage(error.response.data.errors.email[0])
            }
            else if(error?.response?.data?.message != undefined){
                setEmail("")
                ShowErrorMessage(error.response.data.message)
            }
            setDisableMsg("Subscribe")
        })
    }

    return (
        <div>
            <section className="footer jumbotron footer-border">
                <div className="container insta-container">
                    <div className="row">
                        <div className="col-lg-7 col-sm-12">
                            <div className="row">
                                <div className="col-lg-4 col-sm-4">
                                    <div className="mobile-mb-30 text-center mob-left-right-auto" style={{ maxWidth: '75%' }}>
                                        <a href="/"><img src={logo} width="130" /></a>
                                        <div className="pt-4">
                                            <div className="footer-social so-icon-none">
                                                <p>
                                                    <a href="https://www.youtube.com/@dioradamsjewels" target="_blank">
                                                        <i className="fa fa-youtube-play" aria-hidden="true"></i>
                                                    </a>
                                                    <a href="https://instagram.com/dioraadams" target="_blank">
                                                        <i className="fa fa-instagram" aria-hidden="true"></i>
                                                    </a>
                                                    <a href="https://www.facebook.com/dioradamsjewels" target="_blank">
                                                        <i className="fa fa-facebook-square" aria-hidden="true"></i>
                                                    </a>
                                                    {/* <a href="#">
                                                        <i className="fa fa-twitter" aria-hidden="true"></i>
                                                    </a> */}
                                                    <a href="https://in.pinterest.com/dioraadams" target="_blank">
                                                        <i className="fa fa-pinterest-p" aria-hidden="true"></i>
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-4">
                                    <div className="mobile-mb-30">
                                        <label className="footer-title text-upp">About Us</label>
                                        <ul className="footer-ul">
                                            <li>
                                                <a href={process.env.PUBLIC_URL + "/Aboutus"} className="desk-top-title-sm a-taglink">
                                                    Why Diora Adams
                                                </a>
                                            </li>
                                            <li>
                                                <a href={process.env.PUBLIC_URL + "/DeliveryInfo"} className="desk-top-title-sm a-taglink">
                                                    Delivery Information
                                                </a>
                                            </li>
                                            <li>
                                                <a href={process.env.PUBLIC_URL + "/ReturnPolicy"} className="desk-top-title-sm a-taglink">
                                                    Return and Shipping Policy
                                                </a>
                                            </li>
                                            <li>
                                                <a href={process.env.PUBLIC_URL + "/Policy"} className="desk-top-title-sm a-taglink">
                                                    Privacy Policy
                                                </a>
                                            </li>
                                            <li>
                                                <Link to="/terms" className="desk-top-title-sm a-taglink">Terms & Conditions</Link>
                                                {/* <a href={process.env.PUBLIC_URL + "/terms"} className="desk-top-title-sm a-taglink">
                                                    Terms & Conditions
                                                </a> */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-4">
                                    <div className="mobile-mb-30">
                                        <label className="footer-title text-upp">Shop Online</label>
                                        <ul className="footer-ul">
                                            <li>
                                                <a href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${localStorage.getItem("EngagmentName")}?cid=${localStorage.getItem("EngagmentID")}`} className="desk-top-title-sm a-taglink">
                                                    Engagement Rings
                                                </a>
                                            </li>
                                            <li>
                                                <a href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${localStorage.getItem("WeddingName")}?cid=${localStorage.getItem("WeddingID")}`} className="desk-top-title-sm a-taglink">
                                                    Wedding
                                                </a>
                                            </li>
                                            <li>
                                                <a href={process.env.PUBLIC_URL + "/Jewelry/Build-Your-Own"} className="desk-top-title-sm a-taglink">
                                                    Jewellery
                                                </a>
                                            </li>
                                            <li>
                                                <a href={process.env.PUBLIC_URL + "/CustomDesign"} className="desk-top-title-sm a-taglink">
                                                    Bespoke
                                                </a>
                                            </li>
                                            <li>
                                                <a href={process.env.PUBLIC_URL + `/DiamondList/Build-Your-Own/${localStorage.getItem('EngagmentID')}/Natural`} className="desk-top-title-sm a-taglink">
                                                    Loose Diamonds
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="col-lg-5  col-sm-12">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div>
                                        <label className="footer-title text-upp">Guides & Education</label>
                                        <ul className="footer-ul">
                                            <li>
                                                <a href={process.env.PUBLIC_URL + '/DiamondGuide'} className="desk-top-title-sm a-taglink">
                                                    Diamond Guide
                                                </a>
                                            </li>
                                            <li>
                                                <a href={process.env.PUBLIC_URL + '/JewelryCare'} className="desk-top-title-sm a-taglink">
                                                    Jewellry Care
                                                </a>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div>
                                        <label className="footer-title text-upp">CONTACT US</label>
                                        <ul className="footer-ul">
                                            <li>
                                                <a href={process.env.PUBLIC_URL + '/ContactUs'} className="desk-top-title-sm a-taglink">
                                                    Book Virtual Appointment
                                                </a>
                                            </li>
                                            <li>
                                                <a href={"tel:"+process.env.REACT_APP_CONTACT} className="desk-top-title-sm a-taglink">
                                                     {process.env.REACT_APP_CONTACT} 
                                                </a>
                                            </li>
                                            <li>
                                                <a href={"mailto:"+process.env.REACT_APP_EMAIL} className="desk-top-title-sm a-taglink">
                                                    CONTACT@DIORADAMS.COM
                                                </a>
                                            </li>
                                            <li>
                                                <a href="javascript:void(Tawk_API.toggle())" className="desk-top-title-sm a-taglink">
                                                    Live Chat
                                                </a>
                                            </li>
                                            {/* <li>
                                                <a href="#" className="desk-top-title-sm a-taglink">
                                                    Track Your Order
                                                </a>
                                            </li> */}
                                        </ul>

                                    </div>
                                </div>
                            </div>
                            <div className="offer-input-div mobile-mt-30">
                                <label className="footer-title text-upp">GET EXCLUSIVE OFFERS AND NEWS</label>
                                <div>
                                    <input className="submit-input" placeholder="Your Email"
                                        type="text" name=""
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <button disabled={disable} onClick={() => CallEmailApi()} className="btn submit-btn" id="sub-subscribe">{disableMsg}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="last-footer-div">
                <div className="container">
                    <div>
                        ©{moment().format('YYYY')} Diora Adams All Rights Reserved | Developed &amp; Managed by <a className="white-link" target="_blank" href="https://www.weingenious.com">Weingenious Technocrats.</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

