import React from "react";
import "../Diamond Guide/DiamondGuide.css";

const DiamondSustainabilty = () => {

  return (
    <>
      <section className="why-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div>

                <div className="mb-4">
                  <h2 className="sub_title-guide">Sustainability: Our Commitment to a Better Future</h2>
                  <p>At Diora Adams Diamond Jewelry, we consider that beauty need to now not come at the price of our planet. That's why we are dedicated to sustainability in each element of our business. We attempt to create earrings that not only captivates and enchants however additionally minimizes our ecological footprint and promotes accountable practices.</p>
                </div>

                <div className="mb-4">
                  <h2 className="sub_title-subguide">Sustainable Sourcing of Materials</h2>
                  <p >
                  One of the key pillars of our sustainability commitment is the accountable sourcing of materials. We carefully pick out diamonds, gems, and valuable metals that meet stringent ethical and environmental standards. Our diamonds are war-unfastened, sourced from official providers who follow the Kimberley Process Certification Scheme. We also prioritize suppliers who follow sustainable mining practices and work closer to minimizing the environmental effect in their operations.
                  </p>
                  <p >
                  In addition, we searching for out recycled or reclaimed treasured metals whenever possible. By the usage of recycled gold and silver, we help lessen the need for new mining, that could have large environmental consequences. This way, we make sure that our earrings is not only beautiful but also supports the conservation of our planet's sources.
                  </p>
                </div>

                <div className="mb-4">
                  <h2 className="sub_title-subguide">Craftsmanship and Durability</h2>
                  <p >
                  At Diora Adams, we accept as true with in growing earrings that stands the test of time. We prioritize incredible craftsmanship and durability in each piece we produce. By carefully designing and constructing our earrings, we aim to decrease waste and create lasting pieces that may be treasured for generations.
                  </p>
                  <p >
                  Our artisans are skilled in conventional techniques that prioritize best and longevity. We agree with that by means of creating rings that withstands the check of time, we will assist lessen the call for for brand new pieces and make contributions to a greater sustainable method to fashion.
                  </p>
                </div>

                <div className="mb-4">
                  <h2 className="sub_title-subguide">Reducing Our Carbon Footprint</h2>
                  <p >
                  We are committed to lowering our carbon footprint and minimizing our effect at the surroundings. We actively searching for out ways to improve our operational practices, such as optimizing electricity usage, decreasing waste, and imposing recycling packages.
                  </p>
                  <p >
                  In addition, we try to associate with shipping and logistics vendors who share our commitment to sustainability. By selecting environmentally aware delivery techniques and offsetting our carbon emissions wherein possible, we goal to decrease the environmental effect of turning in our jewelry to clients round the sector.
                  </p>
                </div>

                <div className="mb-4">
                  <h2 className="sub_title-subguide">Social Responsibility</h2>
                  <p>
                  Our commitment to sustainability extends past environmental considerations. We strongly agree with in social obligation and the fair remedy of all individuals concerned in our supply chain. We work with providers who provide secure running conditions and fair wages, ensuring that the those who make a contribution to the introduction of our earrings are treated with dignity and respect.
                  </p>
                </div>

                <div className="mb-4">
                  <h2 className="sub_title-subguide">Giving Back to the Community</h2>
                  <p>
                  As part of our sustainability efforts, we actively help projects that advantage our local and international groups. We collaborate with charitable businesses and make a contribution to reasons aligned with our values, including environmental conservation, training, and empowerment. By giving back, we goal to make a effective impact and contribute to a extra sustainable and equitable future.
                  </p>
                </div>

                <div className="mb-4">
                  <h2 className="sub_title-subguide">Choose Sustainability with Diora Adams</h2>
                  <p>
                  When you pick out Diora Adams Diamond Jewelry, you select beauty with a judgment of right and wrong. Our dedication to sustainability is woven into each issue of our commercial enterprise, from responsible sourcing and superb craftsmanship to reducing our carbon footprint and supporting social initiatives. We believe that sustainable practices are not handiest vital for the nicely-being of our planet but also for the long-time period beauty and cost of our rings.
                  </p>
                  <p>
                  Join us in embracing a extra sustainable destiny. Explore our series of first rate and eco-friendly earrings, knowing that your purchase displays your dedication to making responsible selections. Together, we will create a global wherein splendor and sustainability cross hand in hand.
                  </p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DiamondSustainabilty;
