
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css'
import { BASE_URL, GetAPIUrl } from '../API/APIUrl'
import { ShowErrorMessage, confirmRemove } from '../module/Tostify'
import InfiniteScroll from 'react-infinite-scroll-component'
import { BASENAME, utf8_to_b64 } from '../helpers/Utility'
import LoadingSpinner from '../module/LoadingSpinner'
import noimage from "../assets/images/product-details/noimage.jpg"
import JewelryCard from './JewelryCard'

const $ = window.$;

const style = {
    height: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8
};

const SearchData = () => {
    const location = useLocation();

    const type = new URLSearchParams(location.search).get("type");
    const searchitem = new URLSearchParams(location.search).get("searchitem");
    const DiamondId = new URLSearchParams(location.search).get("did");

    const [List, setList] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [filterCount, setFilterCount] = useState();
    const [Currentpage, setCurrentpage] = useState(1);
    const [length, setLength] = useState(28);
    const [Loading, setLoading] = useState(false)
    const [isLoading, seIstLoading] = useState(false)

    const [globalJewelryMargin,setGlobalJewelryMargin] = useState(0);
    const [globalMarkup,setGlobalMarkup] = useState(0);
    const [globalTagFact,setglobalTagFact] = useState(1);

    const buildYourOwn = location.pathname.split("/")[2] == "Build-Your-Own";
    const breadCrumpCat = location.pathname.split("/")[2];

    const [IsWishlistLoading, setIsWishlistLoading] = useState(false)

    const {slug} = useParams()
    console.log("slug",slug)
    const [Wishlist, setWishlist] = useState('');

    const [category, setCategory] = useState([])
    const [diamond, setDiamond] = useState([])
    const [jewelry, setJewelry] = useState([])

    const PER_PAGE = length;
    const count = Math.ceil(filterCount / PER_PAGE);
    const timeout = useRef()

    const token = useSelector((state) => state?.user?.token)

    const is_b2b = JSON.parse(localStorage.getItem('data'))
    const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))


    const CallCartCountApi = async () => {
        const controller = new AbortController();

        await axios.get(BASE_URL + GetAPIUrl.CARTWISHLISTCOUNT_URL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                
                if (response.data.success == true) {
                    setWishlist(response?.data?.wishlist?.jewelry_ids)
                    localStorage.setItem('wishlistcount', JSON.stringify(response.data.wishlist.count))
                    localStorage.setItem('cartcount', JSON.stringify(response.data.cart.count))
                    $(function () {
                        $('.addtocartCount').html(JSON.stringify(response.data.cart.count));
                        $('.addtowishlistCount').html(JSON.stringify(response.data.wishlist.count));
                    })
                } else {
                    localStorage.setItem('wishlistcount', JSON.stringify(0))
                    localStorage.setItem('cartcount', JSON.stringify(0))
                }
            }).catch(error => {
            }).finally(() => {

            })
        controller.abort()
    }


    const CallAddtoWishlistApi = async (item) => {
        setIsWishlistLoading(true)
        const controller = new AbortController();
        var form = new FormData();

        // console.log("item",item)
        form.append("jewelryid", item?.id);
        // form.append("ringsize", JewelryId);
        form.append('centerStonePrice', null)
        form.append('sideDiaGrpId', null)
        form.append('settingPrice', item.settingprice)
        form.append('currency_id', defaultCurrency?.id == null ? null : defaultCurrency?.id)


        await axios.post(BASE_URL + GetAPIUrl.WISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    setIsWishlistLoading(false)
                    // ShowMessage(response.data.message)
                    CallCartCountApi()
                } else {
                    setIsWishlistLoading(false)
                    ShowErrorMessage(response.data.message)
                }
            })
            // await fetch( GetAPIUrl.WISHLIST_URL, {
            //     method: "POST",
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Accept": "application/json",
            //         "Authorization": 'Bearer' + ' ' + token
            //     },
            //     body: JSON.stringify(data)
            // }).then(response => response.json())
            //     .then(data => {
            //         console.log("wishlisr resposnes", data);
            //         if (data.success == true) {
            //             setIsWishlistLoading(false)
            //             ShowMessage(data.message)
            //         } else {
            //             setIsWishlistLoading(false)
            //             ShowErrorMessage(data.message)
            //         }
            //     })


            .catch(error => {

                ShowErrorMessage(error.message)
                setIsWishlistLoading(false)
                // window.location.pathname = '/Login'

            });
        controller.abort()
    }

    const confirmRemovejewelry = (item) => {

        confirmRemove("Are you Sure","You want to remove item from wishlist?").then((result) => {
            if (result == true) {
                CallDeletejewelry(item)

            }
        }

        )
    }
    const CallDeletejewelry = async (item) => {
        
        const controller = new AbortController();
        var form = new FormData();

        form.append("id", "");
        form.append("jewelryid", item?.id);
        form.append("diamondid", "");

        // console.log("fff", [...form]);
        await axios.post(BASE_URL + GetAPIUrl.DELETEWISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {

                    // ShowMessage(response.data.message)
                    CallCartCountApi()
                } else {

                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)

            });
        controller.abort()
    }

    useEffect(() => {
        // if (params?.slug != undefined) {
        //     console.log("state", decodeURIComponent(escape(window.atob(params.slug))));
        //     let categoryId = decodeURIComponent(escape(window.atob(params.slug)))

        // }
        // CallFiltersApi()
        CallSearchAPI()
        getGlobalSettings()

    }, [])

    const getGlobalSettings = async () => {   
        await axios.post(BASE_URL + GetAPIUrl.GLOBALMARKUPTAG_URL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                if(response?.data?.type == "success")
                {
                    if(response?.data?.data.length > 0 )
                    {
                        setGlobalMarkup(response?.data?.data[0].side_stone_mark_up);
                        setglobalTagFact(response?.data?.data[0].side_stone_tag_fact);
                        setGlobalJewelryMargin(response?.data?.data[0].b2c_margin_rate);
                        console.log("0000000000000000000000000000000000000000",response?.data?.data[0].b2c_margin_rate)
                    }
                }  
            }).catch(error => {
            }).finally(() => {

            })
    }

    const CallSearchAPI = async () => {

        const controller = new AbortController();
        var form = new FormData();

        form.append("search", searchitem);
        form.append("page", Currentpage);
        form.append("type", type);
        form.append("is_b2b", is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b);
        form.append("currency_id", defaultCurrency?.id == null ? null : defaultCurrency?.id);

        // console.log("fff", [...form]);
        if (searchitem.length > 2) {
            setLoading(true)

            await axios.post(BASE_URL + GetAPIUrl.SEARCH_URL, form, {
                headers: {
                    'Accept': 'application/json',
                }
            })
                .then(response => {
                    if (response.data.success == true) {


                        setCategory(response?.data?.data?.category)
                        if (diamond?.length <= response?.data?.data?.diamond_count) {
                            setDiamond([...diamond, ...response?.data?.data?.diamond])
                            setCurrentpage(pre => pre + 1);
                        }
                        // setJewelry(response?.data?.data?.jewelry)

                        if (jewelry?.length <= response?.data?.data?.jewelry_count) {
                            setJewelry([...jewelry, ...response?.data?.data?.jewelry])
                            setCurrentpage(pre => pre + 1);
                        }

                    } else {
                        ShowErrorMessage(response.data.message)
                    }
                }).catch(error => {
                    ShowErrorMessage(error.message)

                }).finally(() => {
                    setLoading(false)
                })
        }

        controller.abort()
    }

    return (
        <section className="jumbotron pt-3" >


            {
                type == 'jewellery' &&
                // jewelry.map((item, index) => {
                //     return (
                //         <div key={Math.random()} className="product-div find-img">
                //             <div className="product-div-div">
                //                 <div className="product-div-box">
                //                     {/* <span className="heart-span hand">
                //                     <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                //                         <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#3AB591" stroke-opacity="0.65" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"></path>
                //                     </svg>
                //                 </span> */}
                //                     <div className="product-div-list">
                //                         <img src={item?.default?.path} className="inner-img-product change-image" />
                //                     </div>
                //                     <div className="text-center show-viewbtn">

                //                         <h5 className="product-title pt-3 line1-doted-3">
                //                             Petite Twist Diamond Eternity Ring in 18K White Gold
                //                         </h5>
                //                         <p className="product-title-price mb-0">
                //                             $ 1099
                //                         </p>
                //                         <div className="pt-3 hide-view-btn">
                //                             <a className="view-details-btn" href="product-details.html">
                //                                 <span className="span-link">
                //                                     View Details
                //                                 </span>
                //                                 <span>&nbsp;
                //                                     <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10L6 5.5L1 1" stroke="#23282D" strokeWidth="1.5" strokeLinecap="round" /></svg>
                //                                 </span>
                //                             </a>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>


                //         </div>
                //     )
                // })
                <InfiniteScroll
                    dataLength={jewelry.length}
                    loader={Loading && <center><img width='100px' src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/loader5.gif"} alt="loading..." onError={(e)=>{
                        e.target.onerror=null
                        e.target.src=noimage
                    }}/></center>}
                    next={CallSearchAPI}
                    hasMore={true}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            <b>Yay! You have seen it all</b>
                        </p>
                    }
                >
                    <div className="container container-main" >
                        <div className="product-page-div">
                            {/* <div className='row'> */}
                                {jewelry.map((item, index) => {
                                    return (
                                    <JewelryCard key={index}
                                    slug={slug} 
                                    item={item} 
                                    Wishlist={Wishlist}
                                    token={token}
                                    globalJewelryMargin={globalJewelryMargin}
                                    globalMarkup={globalMarkup}
                                    globalTagFact={globalTagFact}
                                    confirmRemovejewelry={confirmRemovejewelry}
                                    CallAddtoWishlistApi={CallAddtoWishlistApi}
                                    buildYourOwn={buildYourOwn}
                                    DiamondId={DiamondId}
                                    />
                                        // <div key={Math.random()} className="product-div find-img" onClick={() => {window.open(`JDetails/${utf8_to_b64(item?.id)}/${item?.slug}?jid=${item?.id}`,"_blank") }}>
                                        //     <div className="product-div-div">
                                        //         <div className="product-div-box">
                                        //             {/* <span className="heart-span hand">
                                        //     <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        //         <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#3AB591" stroke-opacity="0.65" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"></path>
                                        //     </svg>
                                        // </span> */}
                                        //             <div className="product-div-list">
                                        //                 <img src={(item?.default?.path == null || item?.default?.path == undefined) ? noimage : item?.default?.path} className="inner-img-product change-image" onError={(e)=>{
                                        //                                 e.target.onerror=null
                                        //                                 e.target.src=noimage
                                        //                             }}/>
                                        //             </div>
                                        //             <div className="text-center show-viewbtn">

                                        //                 <h5 className="product-title pt-3 line1-doted-3">
                                        //                     {item?.title}
                                        //                 </h5>
                                        //                 <p className="product-title-price mb-0">
                                        //                 {defaultCurrency?.sign ?? "$"} {item?.settingprice}
                                        //                 </p>
                                        //                 <div className="pt-2 hide-view-btn">
                                        //                     <a className="view-details-btn" target="_blank" href={BASENAME + `JDetails/${utf8_to_b64(item?.id)}/${item?.slug}?jid=${item?.id}`}>
                                        //                         <span className="span-link">
                                        //                             View Details
                                        //                         </span>
                                        //                         <span>&nbsp;
                                        //                             <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10L6 5.5L1 1" stroke="#23282D" strokeWidth="1.5" strokeLinecap="round" /></svg>
                                        //                         </span>
                                        //                     </a>
                                        //                 </div>
                                        //             </div>
                                        //         </div>
                                        //     </div>


                                        // </div>

                                    )
                                })}
                            {/* </div> */}
                        </div>
                    </div>
                </InfiniteScroll>
            }
            {type == 'diamond' &&
                // diamond.map((item, index) => {
                //     return (
                //         <div key={Math.random()} className="product-div find-img">
                //             <div className="product-div-div">
                //                 <div className="product-div-box">
                //                     {/* <span className="heart-span hand">
                //                             <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                //                                 <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#3AB591" stroke-opacity="0.65" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"></path>
                //                             </svg>
                //                         </span> */}
                //                     <div className="product-div-list">
                //                         <img src={item?.image} className="inner-img-product change-image" />
                //                     </div>
                //                     <div className="text-center show-viewbtn">

                //                         <h5 className="product-title pt-3 line1-doted-3">
                //                             Petite Twist Diamond Eternity Ring in 18K White Gold
                //                         </h5>
                //                         <p className="product-title-price mb-0">
                //                             $ 1099
                //                         </p>
                //                         <div className="pt-3 hide-view-btn">
                //                             <a className="view-details-btn" href="product-details.html">
                //                                 <span className="span-link">
                //                                     View Details
                //                                 </span>
                //                                 <span>&nbsp;
                //                                     <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10L6 5.5L1 1" stroke="#23282D" strokeWidth="1.5" strokeLinecap="round" /></svg>
                //                                 </span>
                //                             </a>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </div>


                //         </div>
                //     )
                // })
                <div
                    id="scrollableDiv"
                    style={{
                        // height: 300,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}
                >
                    <InfiniteScroll
                        dataLength={diamond.length}
                        loader={Loading && <h3><center> Loading...</center></h3>}
                        next={CallSearchAPI}
                        hasMore={true}
                        endMessage={
                            <p style={{ textAlign: "center" }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                    >
                        <div className="container container-main" >
                            <div className="product-page-div">
                                <div className='row'>
                                    {diamond.map((item, index) => {
                                        return (

                                            <div key={Math.random()} className="product-div find-img" onClick={() => {window.open(BASENAME + `DiamondDetails/${utf8_to_b64(item?.id)}/${utf8_to_b64(item?.title)}?did=${item?.id}`,'_blank') }}>
                                                <div className="product-div-div">
                                                    <div className="product-div-box">
                                                        {/* <span className="heart-span hand">
                                            <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#3AB591" stroke-opacity="0.65" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </span> */}
                                                        <div className="product-div-list">
                                                            <img src={(item?.image == null || item?.image == undefined) ? noimage : item?.image} className="inner-img-product change-image" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                        </div>
                                                        <div className="text-center show-viewbtn">

                                                            <h5 className="product-title pt-3 line1-doted-3">
                                                                {item?.title}
                                                            </h5>
                                                            <p className="product-title-price mb-0">
                                                            {defaultCurrency?.sign ?? "$"} {item?.diamondprice}
                                                            </p>
                                                            <div className="pt-3 hide-view-btn">
                                                                <a className="view-details-btn" href="product-details.html">
                                                                    <span className="span-link">
                                                                        View Details
                                                                    </span>
                                                                    <span>&nbsp;
                                                                        <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 10L6 5.5L1 1" stroke="#23282D" strokeWidth="1.5" strokeLinecap="round" /></svg>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>

                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </InfiniteScroll>
                </div>
            }


        </section >

    )
}

export default SearchData