import './App.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from "react-router-dom";

import Cart from "./components/Cart";
import Checkout from "./components/Checkout";
import DiamondDetails from "./components/DiamondDetails";
import DiamondList from "./components/DiamondList";
import Footer from "./components/Footer";
import Home from "./components/Home";
import JewellryDetails from "./components/JewellryDetails";
import Jewelry from "./components/Jewelrylist";
import Login from "./components/Login";
import Myaccount from "./components/Myaccount";
import Nav from "./components/Nav";
import PrivateRoutes, { PrivateRoute } from "./components/PrivateRoute";
import Signup from "./components/Signup";
import SignupB2B from "./components/SignupB2B";
import Wishlist from "./components/Wishlist";
import { addToken } from "./reducers/userReducers";
import { useSelector, useDispatch } from 'react-redux'
import ErrorPage from "./components/ErrorPage";
import ForgetPassword from "./components/ForgetPassword";
import { BASENAME } from "./helpers/Utility";
import ResetPassword from "./components/ResetPassword";
import Gallery from "./components/Gallery";
import SearchData from "./components/SearchData";
import AboutUs from "./components/AboutUs/AboutUs";
import Appointment from "./components/Appointment";
import LabGrow from "./components/Lab Grow/LabGrow";
import CustomDesign from "./components/CustomDesign/CustomDesign";
import ContactUs from "./components/ContactUs/ContactUs";
import AccountActivation from "./components/AccountActivation ";

import DiamondSustainabilty from "./components/DiamondSustainabilty/DiamondSustainabilty";
import ConflictFreeDiamonds from "./components/ConflictFreeDiamonds/ConflictFreeDiamonds";
import Faq from "./components/Faq/Faq";

import { Helmet, HelmetProvider } from 'react-helmet';
import { ShowErrorMessage } from "./module/Tostify";
import axios from "axios";
import { BASE_URL, GetAPIUrl } from "./API/APIUrl";
import Policy from "./components/Terms/Policy";
import DeliveryInfo from "./components/Terms/DeliveryInfo";
import ReturnPolicy from "./components/Terms/ReturnPolicy";
import Terms from "./components/Terms/Terms";
import DiamondGuide from "./components/Diamond Guide/DiamondGuide";
import JewelryCare from "./components/Jewelry Care/JewelryCare";


const App = () => {
  const user = useSelector((state) => state?.user?.data)
  const token = useSelector((state) => state?.user?.token)

  /*var Address6 = require('ip-address').Address6;
  var address = new Address6('2001:0:ce49:7601:e866:efff:62c3:fffe');
  var teredo = address.inspectTeredo();
  localStorage.setItem('ipaddress', teredo.client4);*/


  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(addToken())
  }, [])

  // const [currency,setCurrency]=useState({})
  // useEffect(() => {
  //     async function fetchCurrency() {
  //         try{
  //             const response = await fetch(`https://api.ipregistry.co/?key=${process.env.REACT_APP_IPREGISTRY_KEY}`);
  //             const data = await response.json();
  //             console.log("country data",data)
  //             const info={
  //                 countryname:data.location.country.code,
  //                 countrycode:data.location.country.calling_code,
  //                 countryflag:data?.location?.country?.flag?.twemoji,
  //                 currencycode:data?.currency?.code,
  //                 currencyname:data?.currency?.name,
  //                 currencysymbol:data?.currency?.symbol
  //             }
  //             setCurrency(info)
  //             localStorage.setItem('currencyData',JSON.stringify(info))
  //         }catch(error){
  //             ShowErrorMessage("Ip Registry Key Expire")
  //         }
          
  //     }
  //     const countryData=localStorage.getItem('currencyData')
  //     if(countryData){
  //         setCurrency(JSON.parse(countryData))
  //     }else{
  //         fetchCurrency();
  //     }
  //   }, []);



  // { console.log("token === ", token) }


  return (
    <Router
      basename={BASENAME}
    >
      <Nav />
    
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route element={<PrivateRoutes />}>

            <Route path="Wishlist" element={<Wishlist />} />
            <Route path="Checkout" element={<Checkout />} />
            <Route path="Myaccount" element={<Myaccount />} />
            <Route path="Cart" element={<Cart />} />

          </Route>

          <Route path="AccountActivation/:id" element={<AccountActivation />} />
          <Route path="ResetPassword" element={<ResetPassword authorized={token == null ? false : true} />} />
          <Route path="ForgetPassword" element={<ForgetPassword authorized={token == null ? false : true} />} />
          <Route path="Login" element={<Login authorized={token == null ? false : true} />} />
          <Route path="Signup" element={<Signup authorized={token == null ? false : true} />} />
          <Route path="SignupB2B" element={<SignupB2B authorized={token == null ? false : true} />} />

          <Route path="Gallery" element={<Gallery />} />
          
          <Route path="CustomDesign" element={<CustomDesign />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="Policy" element={<Policy />} />

          <Route path="DeliveryInfo" element={<DeliveryInfo />} />
          <Route path="ReturnPolicy" element={<ReturnPolicy />} />
          <Route path="Terms" element={<Terms />} />
          
          <Route path="Aboutus" element={<AboutUs />} />
          <Route path="LabGrow" element={<LabGrow />} />
          <Route path="DiamondGuide" element={<DiamondGuide />} />
          <Route path="JewelryCare" element={<JewelryCare />} />

          <Route path="DiamondSustainabilty" element={<DiamondSustainabilty />} />
          <Route path="ConflictFreeDiamonds" element={<ConflictFreeDiamonds />} />
          <Route path="Faq" element={<Faq />} />

          <Route path="Appointment" element={<Appointment />} />

          <Route path="SearchData" element={<SearchData />} />
          <Route path="SearchData/:slug" element={<SearchData />} />

          <Route path="DiamondList" element={<DiamondList />} />
          <Route path="DiamondList/:did" element={<DiamondList />} />
          <Route path="DiamondList/:name/:slug" element={<DiamondList />} />
          <Route path="DiamondList/:did/:mincarat/:maxcarat/:slug" element={<DiamondList />} />

          <Route path="DiamondList/Build-Your-Own" element={<DiamondList />} />
          <Route path="DiamondList/Build-Your-Own/:did" element={<DiamondList />} />
          <Route path="DiamondList/Build-Your-Own/:name/:slug" element={<DiamondList />} />
          <Route path="DiamondList/Build-Your-Own/:mincarat/:maxcarat/:slug" element={<DiamondList />} />


          <Route path="DiamondDetails/:slug" element={<DiamondDetails />} />
          <Route path="DiamondDetails/:sid/:slug" element={<DiamondDetails />} />

          <Route path="DiamondDetails/Build-Your-Own/:slug" element={<DiamondDetails />} />
          <Route path="DiamondDetails/Build-Your-Own/:sid/:slug" element={<DiamondDetails />} />

          <Route path="Jewelry" element={<Jewelry />} />
          <Route path="Jewelry/:slug" element={<Jewelry />} />
          <Route path="Jewelry/:slug/:sid" element={<Jewelry />} />
          <Route path="Jewelry/:slug/:sid" element={<Jewelry />} />

          <Route path="Jewelry/Build-Your-Own" element={<Jewelry />} />
          <Route path="Jewelry/Build-Your-Own/:slug" element={<Jewelry />} />
          <Route path="Jewelry/Build-Your-Own/:slug/:sid" element={<Jewelry />} />
          <Route path="Jewelry/Build-Your-Own/:slug/:sid" element={<Jewelry />} />

          <Route path="JDetails" element={<JewellryDetails />} />
          <Route path="JDetails/:slug" element={<JewellryDetails />} />
          <Route path="JDetails/:jid/:slug" element={<JewellryDetails />} />


          <Route path="JDetails/Build-Your-Own" element={<JewellryDetails />} />
          <Route path="JDetails/Build-Your-Own/:slug" element={<JewellryDetails />} />
          <Route path="JDetails/Build-Your-Own/:jid/:slug" element={<JewellryDetails />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>



      </div>
      <Footer />
    </Router>
  );
};

export default App;
