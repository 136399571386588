import React from "react";
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import loader from '../assets/images/loader5.gif'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    color: '#fff',
  },
}));
export default function LoadingSpinner() {
  const classes = useStyles()

  return (
    <Backdrop className={classes.backdrop} open>
      {/* <CircularProgress color="inherit" /> */}
      <img height="15%" src={loader} alt="loading..." />
    </Backdrop>
  );
}

