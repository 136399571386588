import React, { useEffect } from "react";
import "./AboutUs.css";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ExploreCard from "./ExploreCard";
import { Link } from "react-router-dom";

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <section id="wrapper">
            <div className="container">
                <div className="container container-main">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={'/'} className="desk-top-title-sm a-taglink" ><i className="fa fa-home" aria-hidden="true"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">About us </li>
                        </ol>
                    </nav>
                </div>
                <section id="main">
                    <div className="row pb">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-sp-12">
                            <div className="block">
                                <img className="img-fliud" src="https://cdn.shopify.com/s/files/1/0905/2012/files/about-00.jpg" alt="main_img" width="100%" height="auto" />
                            </div>
                        </div>
                    </div>
                    <div className="row pb homemade">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-sp-12">
                            <div className="block has-pattern-button">
                                <h4 className="title_">JEWELLERY THAT RESPECTS NATURE</h4>
                                <div className="sub_title">Crafting Timeless And Sustainable Diamond Jewelry With Exceptional Quality And Customer Service</div>
                            </div>
                        </div>
                    </div>
                    <div className="row flex-w pb-120">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-sp-12 mob-mb-15">
                            <img className="img-fliud" src="https://cdn.shopify.com/s/files/1/0905/2012/files/about-01.jpg" alt="main_img" width="100%" height="auto" />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-sp-12">
                            <div className="text-w right-side">
                                <h4 className="title_">HOW WE STARTED</h4>
                                <div className="sub_title">Exquisite Handmade Diamond Jewelry.</div>
                                <p>Diora Adam is a company that specializes in creating exquisite diamond-studded jewelry. Established in 2002, our founder has over 20 years of experience in the jewelry industry. Our mission is to create timeless and unique jewelry pieces that captivate and enchant jewelry lovers worldwide.</p>
                                <p>Our company is an expert in creating handmade jewelry using natural diamonds, gemstones, and high-quality gold and silver. We also specialize in hip-hop jewelry, creating bold and distinctive designs that reflect the vibrant culture of the hip-hop community. We have a successful track record in exporting diamond jewelry to international markets, showcasing our commitment to delivering outstanding craftsmanship and exceptional quality to customers around the world.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div className="bg-color">
                <div className="container position">
                    <div className="row pt-120 pb-120 about-ceo">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-sp-12">
                            <p className="ceo-quotes mt-10 mob-mt-30">At Diora Adams, we are committed to ethical and sustainable practices in jewelry manufacturing. We source diamonds and gemstones from reputable suppliers who adhere to ethical mining practices and ensure their workers are treated fairly and work in safe conditions. We prioritize using conflict-free diamonds and gemstones to support responsible sourcing and prevent the sale of conflict diamonds. We strive to minimize our impact on the environment by using sustainable materials, recycling waste, and encouraging our customers to recycle their jewelry and packaging. We also promote sustainability by offering customized jewelry and repair services to our customers.</p>
                            {/* <img className="ceo-sign" src="https://cdn.shopify.com/s/files/1/0905/2012/files/southam.svg" alt="sign"/> */}
                            <div className="ceo-name">–Diora Adams, CEO</div>
                        </div>
                    </div>
                    <div className="mt-30 top top-responsive mob-pb-20">
                        <img className="img-fluid" width="auto" height="auto" src="https://cdn.shopify.com/s/files/1/0905/2012/files/about-03.jpg" alt="ceo_top_positionImg" />
                    </div>
                    <div className="buttom bottom-responsive mob-img-none">
                        <img className="img-fluid" width="auto" height="auto" src="https://cdn.shopify.com/s/files/1/0905/2012/files/about-10.png" alt="ceo_bottom_positionImg" />
                    </div>
                    <div className="right mt-30 mb right-responsive mob-img-none">
                        <img className="img-fluid" width="auto" height="auto" src="https://cdn.shopify.com/s/files/1/0905/2012/files/about-02.jpg" alt="ceo_right_positionImg" />
                    </div>
                </div>
            </div>
            <div className="container mob-mt-30">
                <div className="sub_title pt-120">more about diora adams</div>

                <div className="row mt-res">
                    <ExploreCard />
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-sp-12">
                    <div className="appointement pt-120">
                        <h4 className="title_ pt">TELL US WHAT YOUR DESIRE</h4>
                        <div className="sub_title lower">we prioritize our customers, offering personalized recommendations to find the perfect piece of jewelry. our knowledgeable customer service team is readily available to assist in selecting jewelry, whether for a special occasion or just for self-indulgence.</div>
                        <a href={process.env.PUBLIC_URL + `/ContactUs`} className="link-primary">TAKE AN APPOINTEMENT</a>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default AboutUs;
