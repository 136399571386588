import axios from 'axios';
export const fetch2 = async (api, body, token) => {
    // console.log("= =",api, body, token);
    if(token == null){
        return await axios.post(api,body,{
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                // console.log("response ------ ",response);
                // console.log("response ------ ",response.data);
                // console.log("response ------ ",response.data.data);
                return response.data;
                // return response;
            }).catch(error => {
                return error.response.data;
                // console.log("error ------ ",error);
            }).finally(() => { 
            });
    }else{
        return await axios.post(api,body,{
            headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer'+ ' ' + token,
            }
        })
            .then(response => {
                // console.log("response ------ ",response);
                // console.log("response ------ ",response.data);
                // console.log("response ------ ",response.data.data);
                return response.data;
                // return response;
            }).catch(error => {
                return error.response.data;
                // console.log("error ------ ",error);
            }).finally(() => { 
            });
    }
    
    // const res = await fetch(api, {
    //     // mode: 'no-cors',
    //     //  mode: 'cors',
    //     // cache: 'no-cache',
    //     method: "POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Accept": "application/json",
    //         "Authorization": 'Bearer'+ ' ' + token
    //     },
    //     body: JSON.stringify(body)
    // })
    // // console.log("res", res);
    // return await res.json()
}


export const fetchGet = async (api) => {
    // console.log("api,body", api);
    const res =await axios.get(api, {
        headers: {
            'Content-Type': 'application/json',
            // 'Accept': 'application/json',
        }
    })
        .then(response => {
            // console.log("response ------ ",response);
            // console.log("response ------ ",response.data);
            // console.log("response ------ ",response.data.data);
            return response.data;
            // return response;
        }).catch(error => {
            return error.response.data;
            console.log("error ------ ",error);
        }).finally(() => { 
        });
        console.log(res);
    // const res = await fetch(api, {
    //     method: 'GET',
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Accept": "application/json",
    //         //  "Authorization":localStorage.getItem('token')
    //     },
    //     mode: 'cors',
    //     cache: 'no-cache',
    // })
    // // console.log("res", res);
    // return await res.json()
}

