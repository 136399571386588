import axios from 'axios';
import Multiselect from 'multiselect-react-dropdown';
import React, { memo, useEffect, useRef, useState } from 'react'
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import noimage from "../assets/images/product-details/noimage.jpg";
import { BASE_URL, GetAPIUrl } from '../API/APIUrl';
import { Colors, regx } from '../constant/Constant';
import { fetch2 } from '../helpers/fetch';
import { FailedPayment, ShowErrorMessage, ShowMessage, confirmPayment } from '../module/Tostify';
import { CallCartCountApi } from '../reducers/userReducers';
import Payment from '../module/Payment';
import { Messages } from '../constant/Messages';
import { utf8_to_b64 } from '../helpers/Utility';
import logo from "../assets/images/logo-img.svg"
import LoadingSpinner from '../module/LoadingSpinner';

const $ = window.$;
let stripePromise;

const Checkout = () => {   
    const token = localStorage.getItem('token')
    const is_b2b = JSON.parse(localStorage.getItem('data'))
    const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))

    const [selectedpayment, setselectedpayment] = useState(null)

    const [searchParams, setSearchParams] = useSearchParams();

    const [addressListLoading, setAddressListLoading] = useState(false)
    const [addressList, setAddressList] = useState([])
    const [SelectedAddress, setSelectedAddress] = useState('');
    const [SaveNewAddress, setSaveNewAddress] = useState('');

    const [SelectedBillingAddress, setSelectedBillingAddress] = useState('');

    const [Cartlist, setCartlist] = useState([]);
    
    const [DiamondError, SetDiamondError] = useState([]);
    const [DiamondErrorMsg, SetDiamondErrorMsg] = useState(false);

    const [cartIds, setCartIds] = useState('');
    const [Count, setCount] = useState('');
    const [TotalAmount, setTotalAmount] = useState('');
    const [Cartgross, setCartgross] = useState('');
    const [shippingCharge, setShippingCharge] = useState('');
    const [shippingId, setShippingId] = useState(null);

    const [shippingChargePay, SetshippingChargePay] = useState('');
    const [TotalAmountPay, SetTotalAmountPay] = useState('');
    const [CartgrossPay, SetCartgrossPay] = useState('');

    const [IsCartLoading, setIsCartLoading] = useState(false)
    const [IsLoading, setLoading] = useState(false)

    const [noAddress, setNoAddress] = useState(false)

    const [inrCurrency, SetInrCurrency] = useState([])


    const [saveAddress, setSaveAddress] = useState({});
    const [ErrorsData, setErrorsData] = useState('')
    const [BillingErrorsData, setBillingErrorsData] = useState('')


    // const publicIp = require("react-public-ip");

    const [CountryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])
    const [billingCountryList, setBillingCountryList] = useState([])
    const [billingStateList, setBillingStateList] = useState([])

    const [country, setCountry] = useState('')
    const [Addstate, setAddstate] = useState('')

    const [ShippingCountry, setShippingCountry] = useState('')
    const [Shippingstate, setShippingstate] = useState('')

    const [changePass, setChangePass] = useState(true)
    const [errorResponse, setErrorResponse] = useState(true)

    const [oldPassShow, setOldPassShow] = useState(false)
    const [newPassShow, setNewPassShow] = useState(false)
    const [cPassShow, setCPassShow] = useState(false)
    const [showDetail, setShowDetail] = useState(false)

    const [isdefault, setisdefault] = useState(true)

    const [isDisable, setIsDisable] = useState(true)
    const [isBillingDisable, setIsBillingDisable] = useState(true)

    const [braintree_customer_id, setbraintree_customer_id] = useState('')
    const [paymentURL, setPaymentURL] = useState(`${BASE_URL}/make-payment`);

    const [values, setValues] = useState({
        clientToken: null,
        success: '',
        error: '',
        instance: ''
    })



    useEffect(() => {
        CallCountryApi()
        fetchMyAddress()
        // CallFetchCartListApi()
        // fetchClientToken()
        if (searchParams.get("orderStatus") !== undefined && searchParams.get("orderStatus") !== null && searchParams.get("orderMsg") !== undefined && searchParams.get("orderMsg") !== null) {
            if(searchParams.get("orderStatus") == 'success'){
                Swal.fire({
                    title: "Create Order Request",
                    text: searchParams.get("orderMsg"),
                    icon: 'success',
                    confirmButtonText: "Ok",
                }).then((result)=>{
                    if(result.isConfirmed == true)
                    {
                        navigate('/Myaccount?status=completed');
                    }
                });
            }else{
                Swal.fire({
                    title: "Create Order Request",
                    text: searchParams.get("orderMsg"),
                    icon: 'warning',
                    confirmButtonText: "Ok",
                }).then((result)=>{
                    if(result.isConfirmed == true)
                    {
                        navigate('/Myaccount?status=completed');
                    }
                });
            }
        }
    }, [])
    // const fetchClientToken = async () => {
    //     setLoading(true)
    //     await axios.get(BASE_URL + GetAPIUrl.CLIENTTOKEN_URL, {
    //         headers: {
    //             'Accept': 'application/json',
    //             'Authorization': "Bearer " + localStorage.getItem('token')
    //         }
    //     })
    //         .then(response => {
    //             if (response?.data?.success == true) {
    //                 setValues({ ...values, clientToken: response.data.token })

    //                 setbraintree_customer_id(response?.data?.braintree_customer_id)
    //             } else {
    //             }
    //         }).catch((error) => {
    //             ShowErrorMessage(error)
    //         }).finally(() => {
    //             setLoading(false)
    //         })
    // }
    const CallStateApi = async (countryid,type='ship') => {
        let data = {
            "countryid": countryid,
        }
        const result = await fetch2(BASE_URL + GetAPIUrl.STATE, data)
        if (result.success == true) {
            if(type == 'ship'){
                setStateList([])
                setStateList(result.data)
            }else{
                setBillingStateList([])
                setBillingStateList(result.data)
            }
        }
        // console.log("result", result);
    }

    const CallFetchCartListApi = async (country = null) => {
        setLoading(true)
        // console.warn(SelectedAddress)
        // console.warn(SelectedAddress.country)
        const controller = new AbortController();
        let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b
        // let country = SelectedAddress.country != undefined ? SelectedAddress.country : null
        // let country1 = (country == null) ? SelectedAddress.country : null
        let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;

        setIsCartLoading(true)
        await axios.get(BASE_URL + GetAPIUrl.CARTLIST_URL + '?is_b2b=' + getdata+'&country='+country+'&currency_id='+currency_id, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {

                if (response.data.success == true) {
                    setCartlist(response?.data?.carts)
                    setCount(response?.data?.count)
                    setTotalAmount(response?.data?.cart_total)
                    setShippingId(response?.data?.shipping?.id)
                    setShippingCharge(response?.data?.shipping?.charge)
                    setCartgross(response?.data?.cart_gross)

                    CallCartCountApi(token)

                    let tempcart_id=response?.data?.carts?.map((item) => { return item?.id })
                    setCartIds(tempcart_id.join(","))

                } else {
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)

            }).finally(() => {
                setIsCartLoading(false)
                setLoading(false)
            })
        controller.abort()
    }

    const fetchMyAddress = async () => {
        setAddressListLoading(true)
        await axios.get(BASE_URL + GetAPIUrl.ADDRESS_URL, {
            headers: {
                'Accept': 'application/json',
                'Authorization': "Bearer " + localStorage.getItem('token')
            }
        })
            .then(response => {
                // console.log("response?.data", response?.data?.count);

                if (response?.data?.success == true) {
                    setAddressList(response?.data?.userAdddress)
                    let address = ''

                    if (response?.data?.count == 0) {
                        setIsDisable(false)
                        setNoAddress(true)
                    }
                    response?.data?.userAdddress.filter((item, index) => {
                        if (item?.isdefault == 1) {
                            address = item
                            setStateList([])
                            CallStateApi(item?.country,'ship')
                            
                        }
                    })
                    setSelectedAddress(address)
                    setSelectedBillingAddress(address)
                    // setTimeout(() => {
                        CallFetchCartListApi(address?.country);
                    // }, 2000);
                } else {
                }
            }).catch((error) => {
                ShowErrorMessage(error)
            }).finally(() => {
                setAddressListLoading(false)
            })
    }    

    const handleAddressChange = e => {
        let { name, value } = e.target;
        setSaveAddress({ ...saveAddress, [name]: value });
    }
    const handleShippingAddressChange = e => {
        let { name, value } = e.target;
        setSelectedAddress({ ...SelectedAddress, [name]: value });
    }
    const handleBillingAddressChange = e => {
        let { name, value } = e.target;
        setSelectedBillingAddress({ ...SelectedBillingAddress, [name]: value });
    }
    const CallCountryApi = async () => {
        setLoading(true)
        const result = await axios.get(BASE_URL + GetAPIUrl.COUNTRY)
        if (result.data.success == true) {
            setCountryList(result.data.data)
            setBillingCountryList(result.data.data)
            setAddstate('')
        }
    }

    // const processCheckout = ()=>{
    //     // console.log("setErrorsData",ErrorsData)
    //     // console.log("setBillingErrorsData",BillingErrorsData)

    //     if(SelectedAddress.id ==  undefined && SelectedBillingAddress.id ==  undefined){
    //         if(Object.keys(ErrorsData).length == 0 && Object.keys(BillingErrorsData).length == 0){
    //             alert('now go2222');
    //             // document.getElementById("hdsub").click();
    //         }
    //     }
    //     if(SelectedAddress.id !=  undefined && SelectedBillingAddress.id ==  undefined){
    //         if(Object.keys(BillingErrorsData).length == 0){
    //             alert('now go1111');
    //             // document.getElementById("hdsub").click();
    //         }
    //     }
    //     if(SelectedAddress.id ==  undefined && SelectedBillingAddress.id !=  undefined){
    //         if(Object.keys(ErrorsData).length == 0){
    //             alert('now go11111');
    //             // document.getElementById("hdsub").click();
    //         }
    //     }
    // }

    const processPayment = async () => {
        // console.log("SelectedAddress",SelectedAddress)
        // console.log("SelectedBillingAddress",SelectedBillingAddress)
        setLoading(true)
        if(SelectedAddress.id !=  undefined && SelectedBillingAddress.id !=  undefined){
            gotoPayment();
            // document.getElementById("hdsub").click();
        }
        if(SelectedAddress.id ==  undefined)
        {
            let data = {
                "id": SelectedAddress?.id == undefined ? '' : SelectedAddress?.id,
                "firstname": SelectedAddress?.firstname,
                "lastname": SelectedAddress?.lastname,
                "email": SelectedAddress?.email,
                "address": SelectedAddress?.address,
                "city": SelectedAddress?.city,
                "state": SelectedAddress?.state,
                "country": SelectedAddress?.country,
                "zip": SelectedAddress?.pincode,
                "mobileno": SelectedAddress?.mobileno,
                // "ip_address": ipv4 == undefined ? '' : ipv4,
                "ip_address": localStorage.getItem('ipaddress'),
                "isdefault": 0,
            }
    
            await axios.post(BASE_URL + GetAPIUrl.ADDADDRESS_URL, data, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(response => {
                    if (response.data.code == 200) {
                        // ShowMessage("Shipping "+response.data.message)
                        setLoading(false)
                        setErrorsData('')
                        gotoPayment();
                        // document.getElementById("hdsub").click();
                    } else {
                        ShowErrorMessage(response.errors.message)
                    }
                }).catch((error) => {
                    setErrorsData(error.response.data.errors)
                }).finally(() => {
                    setLoading(false)
                })
        }
    };

    const ValidateCart = async () => {
        // console.log("Cartlist",Cartlist)
        setLoading(true)
        let diamondIds=[]
        Cartlist.filter(function(item) {
            if(item.diamondid !== null){
                diamondIds.push(item.diamondid)
            }
            return diamondIds
          });
        //   console.log("diamondIds",diamondIds)
          if(diamondIds.length == 0)
          {
            processPayment()
          }else{
            var form = new FormData();
            form.append("diamondid", diamondIds.toString());
            
            await axios.post(BASE_URL + GetAPIUrl.CHECKDIAMOND_URL, form, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response => {
                // console.log(response)
                // console.log(response.data.count)
                if(response.data.count == 0){
                    // navigate('/Checkout')
                    processPayment()
                    SetDiamondErrorMsg(false)
                }else{
                    SetDiamondError(response.data.diamond)
                    SetDiamondErrorMsg(true)
                    ShowErrorMessage(response.data.errormesssage)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)
            }).finally(() => {
                setLoading(false)
            });
        }
    };

    const continuebutton = async () => {
        // console.log(SelectedAddress);
        // console.log(SelectedAddress.country)
        CallFetchCartListApi(SelectedAddress.country)
        ValidateCart()
        // setLoading(true)
        // if(SelectedAddress.id !=  undefined && SelectedBillingAddress.id !=  undefined){
        //     gotoPayment();
        //     // document.getElementById("hdsub").click();
        // }
        // if(SelectedAddress.id ==  undefined)
        // {
        //     let data = {
        //         "id": SelectedAddress?.id == undefined ? '' : SelectedAddress?.id,
        //         "firstname": SelectedAddress?.firstname,
        //         "lastname": SelectedAddress?.lastname,
        //         "email": SelectedAddress?.email,
        //         "address": SelectedAddress?.address,
        //         "city": SelectedAddress?.city,
        //         "state": SelectedAddress?.state,
        //         "country": SelectedAddress?.country,
        //         "zip": SelectedAddress?.pincode,
        //         "mobileno": SelectedAddress?.mobileno,
        //         // "ip_address": ipv4 == undefined ? '' : ipv4,
        //         "ip_address": localStorage.getItem('ipaddress'),
        //         "isdefault": 0,
        //     }
    
        //     await axios.post(BASE_URL + GetAPIUrl.ADDADDRESS_URL, data, {
        //         headers: {
        //             'Accept': 'application/json',
        //             'Authorization': 'Bearer ' + token
        //         }
        //     })
        //         .then(response => {
        //             if (response.data.code == 200) {
        //                 // ShowMessage("Shipping "+response.data.message)
        //                 setLoading(false)
        //                 setErrorsData('')
        //                 gotoPayment();
        //                 // document.getElementById("hdsub").click();
        //             } else {
        //                 ShowErrorMessage(response.errors.message)
        //             }
        //         }).catch((error) => {
        //             setErrorsData(error.response.data.errors)
        //         }).finally(() => {
        //             setLoading(false)
        //         })
        // }
        // document.getElementById("hdsub").click();
    }
    
    const setUnsetDefault = () => {
        // alert(isdefault);
        if(isdefault == true){
            setSelectedBillingAddress('')
        }else{
            addressList?.filter((item, index) => {
                if (item?.isdefault == 1) {
                    setSelectedBillingAddress(item)
                }
            })
        }
        setTimeout(() => {
            CallFetchCartListApi(SelectedAddress.country);
        }, 1000);
    }
    
    const backToCheckout =  ()=>{
        $(".shopping-main-div").show();
        $(".payment-method").hide();
    }

    const placeOrder = async ()=>{
        // if(selectedpayment == null || selectedpayment == ""){
        //     ShowErrorMessage('Please select payment method')
        // }else{
            // if(selectedpayment == "cash"){
            //     setLoading(true)
            //     // console.log("Cartlist",Cartlist);
            //     // console.log("Count",Count);
            //     // console.log("TotalAmount",TotalAmount);
            //     // console.log("Cartgross",Cartgross);
            //     // console.log("shippingCharge",shippingCharge);
    
            //     // console.log("SelectedAddress",SelectedAddress);
            //     // console.log("SelectedBillingAddress",SelectedBillingAddress);
                
            //     let cart_id=Cartlist?.map((item) => { return item?.id })
    
            //     var form = new FormData();
            //     form.append("firstname", SelectedAddress.firstname);
            //     form.append("lastname", SelectedAddress.lastname);
            //     form.append("email", SelectedAddress.email);
            //     form.append("address", SelectedAddress.address);
            //     form.append("city", SelectedAddress.city);
            //     form.append("state", SelectedAddress.state);
            //     // form.append("state_name", SelectedAddress.shipstate);
    
            //     form.append("country", SelectedAddress.country);
            //     // form.append("country_name", shipcountry);
    
            //     form.append("zip", SelectedAddress.pincode);
            //     form.append("mobileno", SelectedAddress.mobileno);
    
            //     form.append("samebilling", false);
            //     if(SelectedAddress.id == SelectedBillingAddress.id){
            //         form.append("samebilling", true);
            //     }
                
            //     form.append("billing_firstname", SelectedBillingAddress?.firstname);
            //     form.append("billing_lastname", SelectedBillingAddress?.lastname);
            //     form.append("billing_email", SelectedBillingAddress?.email);
            //     form.append("billing_companyname", SelectedBillingAddress?.company);
            //     form.append("billing_address", SelectedBillingAddress?.address);
            //     form.append("billing_city", SelectedBillingAddress?.city);
            //     form.append("billing_state", SelectedBillingAddress?.state);
            //     form.append("billing_country", SelectedBillingAddress?.country);
    
    
            //     // form.append("billing_state_name", billstate);
            //     // form.append("billing_country_name", billcountry);
    
            //     form.append("billing_zip", SelectedBillingAddress?.pincode);
            //     form.append("billing_mobileno", SelectedBillingAddress?.mobileno);
    
            //     form.append("grossamt", Cartgross);
            //     form.append("discountamt", 0);
            //     form.append("totalamount", TotalAmount);
            //     form.append("shipping_charge_id", shippingId);
            //     form.append("shippingcharge", shippingCharge);
            //     form.append("netamount", TotalAmount);
    
    
            //     form.append("cart_id", cart_id.join(","));
            //     // form.append("ip_address", ipv4);
            //     form.append("ip_address", localStorage.getItem('ipaddress'));
            //     form.append("currency_id", defaultCurrency?.id == null ? null : defaultCurrency?.id);
    
            //     // console.log("form",form);
    
            //     await axios.post(BASE_URL + GetAPIUrl.ORDERPLACE_URL, form, {
            //         headers: {
            //             'Accept': 'application/json',
            //             'Content-Type': 'application/json',
            //             'Authorization': 'Bearer ' + localStorage.getItem('token')
            //         }
            //      })
            //         .then(response => {
            //             console.log("response>>>>>",response);
    
            //             if (response?.data?.type == "success") {
            //                 confirmPayment()
            //                 navigate('/Myaccount?status=completed')
            //             }else if (response?.data?.type == "error") {
            //                 FailedPayment()
            //                 ShowErrorMessage(response?.data?.message)
            //                 navigate('/Myaccount?status=failed')
            //             }else if (response?.data?.type == "status_error") {
            //                 ShowErrorMessage(response?.data?.message)                               
            //             }else {
            //                 ShowErrorMessage(response?.data?.message)    
            //             }
            //         }).catch((error) => {
            //             // console.log("error",error);
    
            //             ShowErrorMessage(error.response.data.message)
            //             navigate('/Checkout')
            //             // navigate('/Myaccount?status=catch')
            //         }).finally(() => {
            //             setLoading(false)
            //         })
            // }else if(selectedpayment == "ccavenue" || selectedpayment == "stripe"){
                // if(defaultCurrency.sign == "Rs." || defaultCurrency.sign == "₹"){
                if(defaultCurrency.sign == "USD" || defaultCurrency.sign == "$"){
                    setLoading(true)
                    SetshippingChargePay(shippingCharge)
                    SetTotalAmountPay(TotalAmount)
                    SetCartgrossPay(Cartgross)
                    
                    setTimeout(() => {
                        document.getElementById("hdsub").click();
                    }, 1000);
                    // setLoading(false)
                }else{
                    setLoading(true)
                    const controller = new AbortController();
                    await axios.get(BASE_URL + GetAPIUrl.CURRENCYLIST_URL, null, )
                        .then(response => {
                            if (response.data.success == true) {
                                let foundCurrencyData=null
                                let foundCurrency=false
                                response?.data?.data?.map((item) => {
                                    if(item.sign == "Rs." || item.sign == "₹"){
                                        foundCurrencyData=item
                                        SetInrCurrency(item)
                                        foundCurrency=true
                                    }
                                })
                                if(foundCurrency == true){
                                    setInrDataForPayment(foundCurrencyData)
                                }
                            } else {
                                ShowErrorMessage(response.data.message)
                            }
                        })
                        .catch(error => {
                            // console.log("error", error);
    
                        }).finally(() => {
                            // setLoading(false)
                        })
                    controller.abort()
                }
            // }
        // }        
    };

    const setInrDataForPayment = async (foundCurrencyData) => {
        setLoading(true)
        console.log("foundCurrencyData",foundCurrencyData)
        var form = new FormData();
        form.append("currency_id", foundCurrencyData?.id);
        form.append("shippingCharge", shippingCharge);
        form.append("TotalAmount", TotalAmount);
        form.append("Cartgross", Cartgross);

        await axios.post(BASE_URL + GetAPIUrl.CURRENCYCONVERT_URL, form,null)
        .then(response => {
            if (response.data.success == true) {
                console.log(response.data)
                SetshippingChargePay(response.data.shippingCharge)
                SetTotalAmountPay(response.data.TotalAmount)
                SetCartgrossPay(response.data.Cartgross)
                setTimeout(() => {
                    // setLoading(true)
                    document.getElementById("hdsub").click();
                    // setLoading(false)
                }, 1000);
                // callCCAvenue()
            } else {
                ShowErrorMessage(response.data.message)
            }
        })
        .catch(error => {
            // console.log("error", error);
        }).finally(() => {
            // setLoading(false)
        })
    }

    const callCCAvenue = () => {
        
    }
    const gotoPayment = () => {
        // SelectedAddress
        // window.location='http://192.168.1.220/stage_dioraadams/make-payment';
        $(".shopping-main-div").hide();
        $(".payment-method").show();
    }
    const resetAll = () => {
        setCountry('')
        setAddstate('')
        setSaveAddress({
            "Addressid": '',
            "userid": '',
            "Addfirstname": '',
            "Addlastname": '',
            "Addemail": '',
            "Addmobileno": '',
            "Addaddress": '',
            "Addcity": '',
            "pincode": '',
        })
        setisdefault(false)
        setErrorsData('')

    }
    const AddresselectRef = useRef()
    const BillingAddresselectRef = useRef()

    const [IsFilterLoading, setIsFilterLoading] = useState(false);

    const handleAddressValues = (selectedList, selectedItem) => {
        // console.log(selectedList, selectedItem)
        setStateList([])
        CallStateApi(selectedItem.country,'ship')
        setSelectedAddress(selectedItem)
        setIsDisable(true)
        setErrorsData('')
        if(isdefault){
            setSelectedBillingAddress(selectedItem)
        }
        setTimeout(() => {
            CallFetchCartListApi(selectedItem.country);
        }, 1000);
    };
    const handleRemoveAddressValues = (selectedList, selectedItem) => {
        AddresselectRef.current.resetSelectedValues();
        setSelectedAddress('')
    };

    //Billing Address
    const handleBillingAddressValues = (selectedList, selectedItem) => {
        // console.log(selectedList, selectedItem)
        setStateList([])
        setBillingStateList([])
        CallStateApi(selectedItem.country,'bill');
        setSelectedBillingAddress(selectedItem)
        setIsBillingDisable(true)
        setBillingErrorsData('')
        setTimeout(() => {
            CallFetchCartListApi(SelectedAddress.country);
        }, 1000);
    };
    const handleRemoveBillingAddressValues = (selectedList, selectedItem) => {
        BillingAddresselectRef.current.resetSelectedValues();
        setSelectedBillingAddress('')
    };
    //Billing Address

    const addNewAddress = () => {
        if(isdefault){

        }else{
            setSelectedBillingAddress(''); 
            setIsBillingDisable(false)
        }
        setTimeout(() => {
            CallFetchCartListApi(SelectedAddress.country);
        }, 1000);
    }

    const [errorFirstname, setErrorFirst] = useState('')
    const [errorLastname, setErrorLastname] = useState('')

    const [errorEmail, setErrorEmail] = useState('')
    const [errorMobile, setErrorMobile] = useState('')
    const [errorAddress, setErrorAddress] = useState('')
    const [errorCountry, setErrorCountry] = useState('')
    const [errorState, setErrorState] = useState('')
    const [errorCity, setErrorCity] = useState('')
    const [errorZip, setErrorZip] = useState('')
    const navigate = useNavigate();


    const validation = () => {
        let message = "";

        if (SelectedBillingAddress.firstname == "") {
            message = Messages.FIRSTNAME_REQUIRED;
            setErrorFirst(message)
            // setErrorSelectedBillingAddress({ ...ErrorSelectedBillingAddress, billing_firstname: message })
        }
        else {
            setErrorFirst('')
        }

        if (SelectedBillingAddress.lastname == "") {
            message = Messages.LASTNAME_REQUIRED;
            setErrorLastname(message)
            // setErrorSelectedBillingAddress({ ...ErrorSelectedBillingAddress, billing_lastname: message })
        }
        else {
            setErrorLastname('')
        }

        if (SelectedBillingAddress?.email == "") {
            message = Messages.EMAIL_REQUIRED;
            setErrorEmail(message)
        }
        else if (regx.EMAIL.test(SelectedBillingAddress?.email) == false) {
            message = Messages.EMAIL_INVALID;
            setErrorEmail(message)
        }
        else {
            setErrorEmail('')
            // setErrorSelectedBillingAddress({ ...ErrorSelectedBillingAddress, billing_email: message })
        }
        if (SelectedBillingAddress.mobileno == "") {
            message = Messages.MOBILE_REQUIRED;
            setErrorMobile(message)
        }
        else {
            setErrorMobile('')
        }
        if (SelectedBillingAddress.address == "") {
            message = Messages.ADDRESS_REQUIRED;
            setErrorAddress(message)
        }
        else {
            setErrorAddress('')
        }
        if (SelectedBillingAddress.country == "") {
            message = Messages.COUNTRY_REQUIRED;
            setErrorCountry(message)
        }
        else {
            setErrorCountry('')
        }
        if (SelectedBillingAddress.state == "") {
            message = Messages.STATE_REQUIRED;
            setErrorState(message)
        }
        else {
            setErrorState('')
        }
        if (SelectedBillingAddress.city == "") {
            message = Messages.CITY_REQUIRED;
            setErrorCity(message)
        }
        else {
            setErrorCity('')
        }
        if (SelectedBillingAddress.pincode == "") {
            message = Messages.ZIP_REQUIRED;
            setErrorZip(message)
        } else if (regx.ZIP.test(SelectedBillingAddress.pincode) == false) {
            message = Messages.ZIP_INVALID;
            setErrorZip(message)
        }
        else {
            setErrorZip('')
        }

        if (message == '') {
            // gotoPayment();
            fetchMyAddress()
            // alert('now go')
        }
    }

    $(function () {

        $(".show-ship-address").click(function () {
            $(".payment-method").hide();
            $(".shopping-main-div").show();
        });

        $(".show-payment-method").click(function () {
            $(".shopping-main-div").hide();
            $(".payment-method").show();
        });
        $('#BillingAddress').on('change', function () {
            if ($(this).is(':checked')) {
                $('.billing-address').css('display', 'none');
            } else {
                $('.billing-address').css('display', 'block');
            }
        });
        $(".active-payment").click(function () {
            $(".payment-method-box").removeClass('payment-method-box-active');
            $(this).toggleClass('payment-method-box-active');
        });
    })

    useEffect(()=>{
        if(defaultCurrency?.currency_name?.toLowerCase() == "inr"){
            setPaymentURL(`${BASE_URL}/make-payment`);
        }else{
            setPaymentURL(`${BASE_URL}/make-payment/stripe`);
        }
    },[defaultCurrency]);

    return (
        <>
        {IsLoading ?
                <LoadingSpinner />
                :
                null
        }
            <div>
                <div className="container container-main">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href={process.env.PUBLIC_URL + "/"}><i className="fa fa-home" aria-hidden="true"></i></a></li>
                            <li className="breadcrumb-item active" aria-current="page">Checkout</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <section className="jumbotron pt-0" id="checkout_section" >
                <div className="container container-main">
                    <div>
                        <div className="row">
                            <div className="col-lg-7 order-2 order-lg-1 mb-4">
                                <div style={{ boxShadow: "0 2px 7px 3px #e9e9e9", padding: "15px" }}>
                                    <div className="shopping-main-div">
                                        <div className="shipping-address">
                                            <div className="row" style={{ justifyContent: 'space-between', paddingLeft: 20, paddingRight: 20 }}>
                                                <h4 className="title-1">
                                                    <i className="fa fa-home" style={{ paddingRight: "7px" }}></i> Shipping Address

                                                </h4>
                                                <a className="start-with-btn mobile-1rem-bot mr-0"
                                                    // data-toggle="modal"
                                                    //  data-target="#address-modal"
                                                    onClick={() => { addNewAddress(); setSelectedAddress(''); setIsDisable(false) }}
                                                >
                                                    Add New Address
                                                </a>
                                            </div>
                                            {/* <br /> */}
                                            <div className="row">

                                            <div className="col-lg-12 mb-3">
                                                    <div className="form-group">
                                                    <label className="lblmy">Select Your Existing Address</label>
                                            {addressList.length > 0 &&
                                                // <div className="dropdown bootstrap-select show-tick" style={{ marginRight: 10, width: '-webkit-fill-available' }}>
                                                //     <label className="lblmy">Select Your Existing Address:</label>

                                                    <Multiselect singleSelect
                                                        disablePreSelectedValues={SelectedAddress != '' ? true : false}
                                                        placeholder=''
                                                        style={{
                                                            chips: { background: 'white', color: Colors.brown, fontSize: 14 },
                                                            searchBox: { "borderRadius": "0px" },

                                                        }}
                                                        ref={AddresselectRef}

                                                        showArrow
                                                        loading={IsFilterLoading ? true : false}
                                                        onSelect={handleAddressValues}
                                                        onRemove={handleRemoveAddressValues}
                                                        options={addressList}
                                                        selectedValues={SelectedAddress != '' ? [SelectedAddress] : []}
                                                        // selectedValues={[addressList?.map((item) => { if (item?.isdefault == 1) { return item } })]}
                                                        displayValue="name" />
                                            }
                                            </div>
                                            </div>
                                            
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="lblmy">First Name <span className='mark_red'>*</span></label>
                                                        <input type="text"
                                                            className="form-control my-textbox"
                                                            name='firstname'
                                                            id="firstname"
                                                            autoFocus
                                                            disabled={isDisable ? true : false}
                                                            placeholder={SelectedAddress?.firstname == '' || SelectedAddress?.firstname == undefined && 'Enter Firstname'}
                                                            value={SelectedAddress?.firstname || ""}
                                                            onChange={handleShippingAddressChange}
                                                        />
                                                        <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                            {ErrorsData.firstname != undefined && ErrorsData?.firstname?.map((key, i) => key)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="lblmy">Last Name <span className='mark_red'>*</span></label>
                                                        <input type="text"
                                                            disabled={isDisable ? true : false}

                                                            placeholder={SelectedAddress?.lastname == '' || SelectedAddress?.lastname == undefined && 'Enter Lastname'}
                                                            className="form-control my-textbox" id="lastname" name='lastname'
                                                            value={SelectedAddress?.lastname || ""}
                                                            onChange={handleShippingAddressChange}
                                                        />
                                                        <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                            {ErrorsData.lastname != undefined && ErrorsData?.lastname?.map((key, i) => key)}
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="lblmy">Company Name:</label>
                                                        <input type="text" className="form-control my-textbox" id="cname"
                                                            value={SelectedAddress?.lastname || ""}
                                                            onChange={handleShippingAddressChange}
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="lblmy">Email Id <span className='mark_red'>*</span></label>
                                                        <input type="email"
                                                            disabled={isDisable ? true : false}

                                                            placeholder={SelectedAddress?.email == '' || SelectedAddress?.email == undefined && 'Enter Email'}
                                                            className="form-control my-textbox" id="email" name='email'
                                                            value={SelectedAddress?.email || ""}
                                                            onChange={handleShippingAddressChange}
                                                        />
                                                        <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                            {ErrorsData.email != undefined && ErrorsData?.email?.map((key, i) => key)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className='d-flex'>
                                                        <div className="form-group">
                                                            <label className="lblmy">Mobile Code  <span className='mark_red'>*</span></label>
                                                            <input type="text"
                                                                disabled={isDisable ? true : false}                                 
                                                                className="form-control my-textbox" id="mobcode" name='mobcode'
                                                                value={SelectedAddress?.mob_code || ""}
                                                                onChange={handleShippingAddressChange} />                                                        
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="lblmy">Mobile No.  <span className='mark_red'>*</span></label>
                                                            <input type="text"
                                                                disabled={isDisable ? true : false}
                                                                maxLength={10}
                                                                placeholder={SelectedAddress?.mobileno == '' || SelectedAddress?.mobileno == undefined && 'Enter Mobile No'}
                                                                className="form-control my-textbox" id="mobileno" name='mobileno'
                                                                value={SelectedAddress?.mobileno || ""}
                                                                onChange={handleShippingAddressChange} />
                                                            <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                                {ErrorsData.mobileno != undefined && ErrorsData?.mobileno?.map((key, i) => key)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <label className="lblmy">Address <span className='mark_red'>*</span></label>
                                                        <textarea className="form-control my-textbox"
                                                            disabled={isDisable ? true : false}

                                                            placeholder={SelectedAddress?.address == '' || SelectedAddress?.address == undefined && 'Enter Address'}
                                                            rows="2" id="address" name='address'
                                                            style={{ minHeight: "auto" }}
                                                            value={SelectedAddress?.address || ""}
                                                            onChange={handleShippingAddressChange}>
                                                        </textarea>
                                                        <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                            {ErrorsData.address != undefined && ErrorsData?.address?.map((key, i) => key)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="lblmy">Country <span className='mark_red'>*</span></label>
                                                        <select
                                                            disabled={isDisable ? true : false}

                                                            value={SelectedAddress?.country || ""}
                                                            name="country"
                                                            id="country"
                                                            className="form-control my-textbox selectarrow-none"
                                                            data-parsley-trigger="change"
                                                            data-parsley-errors-container="#Country_msg"
                                                            required=""
                                                            onChange={(e) => { handleShippingAddressChange(e); setStateList([]); CallStateApi(e.target.value,'ship'); setTimeout(() => {
                                                                CallFetchCartListApi(e.target.value);
                                                            }, 1000); }}
                                                        >
                                                            <option value="" disabled="">Select Country</option>
                                                            {CountryList?.map((item, index) => {
                                                                return (
                                                                    <option key={Math.random()} value={item?.id} data-value={item?.id}>{item?.name}</option>
                                                                )
                                                            })}

                                                        </select>
                                                        <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                            {ErrorsData.country != undefined && ErrorsData?.country?.map((key, i) => key)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">

                                                    <div className="form-group">
                                                        <label className="lblmy">State <span className='mark_red'>*</span></label>

                                                        <select
                                                            disabled={isDisable == true || SelectedAddress?.country == '' || SelectedAddress?.country == undefined ? true : false}

                                                            value={SelectedAddress?.state || ""}
                                                            name="state"
                                                            id="state"
                                                            className="form-control my-textbox"
                                                            data-parsley-trigger="change"
                                                            data-parsley-errors-container="#State_msg"
                                                            onChange={(e) => { handleShippingAddressChange(e) }}
                                                        >
                                                            <option value="" disabled="" >Select State</option>
                                                            {stateList?.map((item, index) => {
                                                                return (
                                                                    <option key={Math.random()} value={item?.id} data-value={item?.id}>{item?.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                        <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                            {ErrorsData.state != undefined && ErrorsData?.state?.map((key, i) => key)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="lblmy">Town/City <span className='mark_red'>*</span></label>
                                                        <input type="text" disabled={isDisable ? true : false}
                                                            placeholder={SelectedAddress?.city == '' || SelectedAddress?.city == undefined && 'Enter Town/City'}

                                                            className="form-control my-textbox" id="city" name="city"
                                                            value={SelectedAddress?.city || ""}
                                                            onChange={handleShippingAddressChange}
                                                        />
                                                        <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                            {ErrorsData.city != undefined && ErrorsData?.city?.map((key, i) => key)}
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <label className="lblmy">Zip <span className='mark_red'>*</span></label>
                                                        <input disabled={isDisable ? true : false}
                                                            placeholder={SelectedAddress?.pincode == '' || SelectedAddress?.pincode == undefined && 'Enter Zip Code'}
                                                            type="text" className="form-control my-textbox" id="zip" name="pincode"
                                                            value={SelectedAddress?.pincode || ""}
                                                            onChange={handleShippingAddressChange} />
                                                        <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                            {ErrorsData.zip != undefined && ErrorsData?.zip?.map((key, i) => key)}
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        {/* <br /> */}
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {/* <div className="form-group mar-b-14">
                                                    <label className="chk-sign mar-top-10 color-80">Use this address for Billing
                                                        <input type="checkbox" id="BillingAddress" name="BillingAddress" value="1"
                                                         defaultChecked data-parsley-multiple="BillingAddress"
                                                          data-parsley-id="46" />
                                                        <span className="checkmark-chk"></span>
                                                    </label>
                                                </div> */}
                                                <div className="form-group checkbox-wrapper">
                                                    <label className="checkboxs"> Use as my default billing address
                                                        <input type="checkbox"
                                                            id="isdefault"
                                                            name="isdefault"
                                                            value="isdefault"
                                                            checked={isdefault}
                                                            onChange={() => {setisdefault(!isdefault); setUnsetDefault();}} />
                                                        <span className="checkmark"></span>
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                        {!isdefault &&
                                            <div className="billing-address mt-3" >
                                                <h4 className="title-1">
                                                    <i className="fa fa-home" style={{ paddingRight: "7px" }}></i> Billing Address
                                                </h4>
                                                {/* <br /> */}
                                                {addressList.length > 0 &&
                                                <div className="dropdown bootstrap-select show-tick" style={{ marginRight: 10, width: '-webkit-fill-available' }}>
                                                    <label className="lblmy">Select Your Existing Address:</label>

                                                    <Multiselect singleSelect
                                                        disablePreSelectedValues={SelectedBillingAddress != '' ? true : false}
                                                        placeholder=''
                                                        style={{
                                                            chips: { background: 'white', color: Colors.brown, fontSize: 14 },
                                                            searchBox: { "borderRadius": "0px" },

                                                        }}
                                                        ref={BillingAddresselectRef}

                                                        showArrow
                                                        loading={IsFilterLoading ? true : false}
                                                        onSelect={handleBillingAddressValues}
                                                        onRemove={handleRemoveBillingAddressValues}
                                                        options={addressList}
                                                        selectedValues={SelectedBillingAddress != '' ? [SelectedBillingAddress] : []}
                                                        // selectedValues={[addressList?.map((item) => { if (item?.isdefault == 1) { return item } })]}
                                                        displayValue="name" />
                                                    {/* <Multiselect placeholder='Select Weight type' showArrow options={settingType} displayValue="name" /> */}
                                                </div>
                                            }

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="lblmy">First Name <span className='mark_red'>*</span></label>
                                                            <input disabled={true} type="text" className="form-control my-textbox" name='firstname' id="firstname"
                                                                value={SelectedBillingAddress?.firstname || ""}
                                                                onChange={handleBillingAddressChange}
                                                            />
                                                            <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                                {BillingErrorsData.firstname != undefined && BillingErrorsData?.firstname?.map((key, i) => key)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="lblmy">Last Name <span className='mark_red'>*</span></label>
                                                            <input disabled={true}  type="text" className="form-control my-textbox" name='lastname' id="lastname"
                                                                value={SelectedBillingAddress?.lastname || ""}
                                                                onChange={handleBillingAddressChange} />
                                                            <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                                {BillingErrorsData.lastname != undefined && BillingErrorsData?.lastname?.map((key, i) => key)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="lblmy">Email Id <span className='mark_red'>*</span></label>
                                                            <input disabled={true}  type="email" className="form-control my-textbox" name='email' id="email"
                                                                value={SelectedBillingAddress?.email || ""}
                                                                onChange={handleBillingAddressChange} />
                                                            <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                                {BillingErrorsData.email != undefined && BillingErrorsData?.email?.map((key, i) => key)}
                                                            </p>
                                                        </div>


                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className='d-flex'>
                                                            <div className="form-group">
                                                                <label className="lblmy">Mobile Code  <span className='mark_red'>*</span></label>
                                                                <input type="text"
                                                                    disabled={isDisable ? true : false}                                 
                                                                    className="form-control my-textbox" id="billmobcode" name='billmobcode'
                                                                    value={SelectedBillingAddress?.mob_code || ""}
                                                                    onChange={handleShippingAddressChange} />                                                        
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="lblmy">Mobile No.  <span className='mark_red'>*</span></label>
                                                                <input disabled={true}  type="text" maxLength={10} className="form-control my-textbox" name='mobileno' id="mobileno"
                                                                    value={SelectedBillingAddress?.mobileno || ""}
                                                                    onChange={handleBillingAddressChange}
                                                                />
                                                                <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                                    {BillingErrorsData.mobileno != undefined && BillingErrorsData?.mobileno?.map((key, i) => key)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="lblmy">Company Name:</label>
                                                            <input type="text" className="form-control my-textbox" id="cname" />
                                                        </div>
                                                    </div> */}

                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label className="lblmy">Address <span className='mark_red'>*</span></label>
                                                            <textarea disabled={true}  className="form-control my-textbox" rows="2"
                                                                name='address' id="address"
                                                                value={SelectedBillingAddress?.address || ""}
                                                                onChange={handleBillingAddressChange}
                                                                style={{ minHeight: "auto" }}></textarea>
                                                            <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                                {BillingErrorsData.address != undefined && BillingErrorsData?.address?.map((key, i) => key)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="lblmy">Country  <span className='mark_red'>*</span></label>
                                                            <select disabled={true} 
                                                                value={SelectedBillingAddress?.country || ""}
                                                                name="country"
                                                                id="country"
                                                                className="form-control my-textbox selectarrow-none"
                                                                data-parsley-trigger="change"
                                                                data-parsley-errors-container="#Country_msg"
                                                                required=""
                                                                onChange={(e) => { setBillingStateList([]); CallStateApi(e.target.value,'bill');  handleBillingAddressChange(e) }}
                                                            >
                                                                <option value="" disabled="">Select Country</option>
                                                                {billingCountryList?.map((item, index) => {
                                                                    return (
                                                                        <option key={Math.random()} value={item?.id} data-value={item?.id}>{item?.name}</option>
                                                                    )
                                                                })}

                                                            </select>
                                                            <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                                {BillingErrorsData.country != undefined && BillingErrorsData?.country?.map((key, i) => key)}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="lblmy">State <span className='mark_red'>*</span></label>
                                                            <select  disabled={true} 

                                                                value={SelectedBillingAddress?.state || ""}
                                                                name="state"
                                                                id="state"
                                                                className="form-control my-textbox"
                                                                data-parsley-trigger="change"
                                                                data-parsley-errors-container="#State_msg"
                                                                onChange={(e) => { handleBillingAddressChange(e) }}
                                                            >
                                                                <option value="" disabled="" >Select State</option>
                                                                {billingStateList?.map((item, index) => {
                                                                    return (
                                                                        <option key={Math.random()} value={item?.id} data-value={item?.id}>{item?.name}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                                {BillingErrorsData.state != undefined && BillingErrorsData?.state?.map((key, i) => key)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="lblmy">Town/City <span className='mark_red'>*</span></label>
                                                            <input disabled={true}  type="text" className="form-control my-textbox" id="city" name="city"
                                                                value={SelectedBillingAddress?.city || ""}
                                                                onChange={handleBillingAddressChange}
                                                            />
                                                            <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                                {BillingErrorsData.city != undefined && BillingErrorsData?.city?.map((key, i) => key)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group">
                                                            <label className="lblmy">Zip <span className='mark_red'>*</span></label>
                                                            <input disabled={true}  type="text" className="form-control my-textbox"
                                                                id="pincode" name="pincode"
                                                                value={SelectedBillingAddress?.pincode || ""}
                                                                onChange={handleBillingAddressChange}
                                                            />
                                                            <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                                {BillingErrorsData.zip != undefined && BillingErrorsData?.zip?.map((key, i) => key)}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                        {/* <br /> */}
                                        <div className="row">
                                        <div className="col-lg-6">
                                                        {/* <input className='checkout-nowbtn mb-3' type='submit' 
                                                name="submit" value="Save Address"
                                                style={{width:"100%"}}
                                                onClick={() => {SaveAddress();}}/> */}

                                                <button type='button' className='checkout-nowbtn mb-3' 
                                                name="submit" value="Continue to Payment"
                                                style={{width:"100%"}}
                                                onClick={continuebutton}>Continue to Payment</button>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <a href={process.env.PUBLIC_URL + `/Cart`} className="continue-nowbtn mb-3">
                                                        Back to Cart
                                                        </a>
                                                    </div>
                                                    

                                                
                                        {/* {
                                                    noAddress == true || isDisable == false ?
                                                    <div className="col-lg-12">
                                                        <input className='checkout-nowbtn mb-3' type='submit' 
                                                name="submit" value="Save Address"
                                                style={{width:"100%"}}
                                                onClick={() => {SaveAddress();}}/>
                                                    </div>
                                                    : 
                                                    <>
                                                    <div className="col-lg-6">
                                                <form action={`${BASE_URL}/make-payment`} method='POST'>
                                            <input type="hidden" name="merchant_id" value={process.env.CC_MERCHANT_ID}/>
    <input type="hidden" name="language" value="EN"/>
    <input type="hidden" name="amount" value="1"/>
    <input type="hidden" name="currency" value="INR"/> 
    <input type="hidden" name="redirect_url" value={`${process.env.PUBLIC_URL}/Checkout`}/> 
    <input type="hidden" name="cancel_url" value={`${process.env.PUBLIC_URL}/Checkout`}/> 
    <input type="hidden" name="billing_name" value={`${paymentAddress.firstname} ${paymentAddress.lastname}`}  className="form-field" Placeholder="Billing Name"/> 
    <input type="hidden" name="billing_address" value={paymentAddress.address} className="form-field" Placeholder="Billing Address"/>
    <input type="hidden" name="billing_state" value={paymentAddress.state} className="form-field" Placeholder="State"/> 
    <input type="hidden" name="billing_zip" value={paymentAddress.pincode} className="form-field" Placeholder="Zipcode"/>
    <input type="hidden" name="billing_country" value={paymentAddress.country} className="form-field" Placeholder="Country"/>
    <input type="hidden" name="billing_tel" value={paymentAddress.mobileno} className="form-field" Placeholder="Phone"/>
    <input type="hidden" name="billing_email" value={paymentAddress.email} className="form-field" Placeholder="Email"/>
                                                <button type='button' className='checkout-nowbtn mb-3' 
                                                name="submit" value="Continue to Payment"
                                                style={{width:"100%"}}
                                                onClick={() => {
                                                    isDisable && isdefault && console.log('here');
                                                    ((!isDisable && isdefault) || (!isDisable && !isdefault)) && SaveAddress();
                                                    isDisable && !isdefault && validation();
                                                    // isDisable && isdefault && SaveAddress();
                                                }
                                                }
                                                >Continue to Payment</button>
                                                </form>
                                            </div>
                                            <div className="col-lg-6">
                                                <a href={process.env.PUBLIC_URL + `/Cart`} className="continue-nowbtn mb-3">
                                                    Back to Cart
                                                </a>
                                            </div>
                                            </>
                                                } */}
                                            
                                        </div>
                                    </div>
                                    <div className="payment-method" style={{ display: "none" }}>
                                        <h4 className="title-1">
                                            <i className="fa fa-credit-card-alt" style={{ paddingRight: "7px" }}></i> Shipping Summary
                                        </h4>
                                        {/* <p className="col777">
                                            Choose payment method below
                                        </p>
                                        <br />
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 mb-2">
                                                <div className="text-center payment-method-box payment-method-box-active active-payment">
                                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/checkout/paypal.png"} width="110" height="auto" />
                                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/checkout/selected-reg.png"} className="active-i" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 mb-2">
                                                <div className="text-center payment-method-box active-payment">
                                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/checkout/paytm.png"} width="110" height="auto" />
                                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/checkout/selected-reg.png"} className="active-i" />
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 mb-2">
                                                <div className="text-center payment-method-box active-payment">
                                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/checkout/razorpay.png"} width="110" height="auto" />
                                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/checkout/selected-reg.png"} className="active-i" />
                                                </div>
                                            </div>                                           
                                        </div> */}
                                        {/* <p className="col777">
                                            Choose payment method below
                                        </p> */}
                                        <br />
                                        <div className='row'>
                                            <div className='col-md-12 mb-2'>
                                                <h1 className='title-1'>
                                                    Shipping Information
                                                </h1>
                                            </div>

                                            <div className='col-md-6 shiping-info-div'>
                                                <p>
                                                    <span className='t-color span-b'>First Name: </span> {SelectedAddress?.firstname}                                                   
                                                </p>
                                                <p>
                                                    <span className='t-color span-b'>Email: </span> {SelectedAddress?.email}                                                 
                                                </p>
                                                <p>
                                                    <span className='t-color span-b'>Country: </span> {SelectedAddress?.country}                                                  
                                                </p>
                                                <p>
                                                    <span className='t-color span-b'>Town/City: </span> {SelectedAddress?.city}                                                  
                                                </p>
                                                <p>
                                                    <span className='t-color span-b'>Address: </span> {SelectedAddress?.address}                                                 
                                                </p>
                                            </div>
                                            <div className='col-md-6 shiping-info-div'>                                                
                                                <p>
                                                    <span className='t-color span-b'>Last Name: </span> {SelectedAddress?.lastname}                                                 
                                                </p>
                                                <p>
                                                    <span className='t-color span-b'>Mobile No: </span> {SelectedAddress?.mob_code}{" "}{SelectedAddress?.mobileno}                                                  
                                                </p>
                                                <p>
                                                    <span className='t-color span-b'>State: </span> {SelectedAddress?.state}                                                  
                                                </p>
                                                <p>
                                                    <span className='t-color span-b'>Zip: </span> {SelectedAddress?.pincode}                                                  
                                                </p>                                                
                                            </div>

                                            {
                                                isdefault == false &&
                                                <>
                                                <div className='col-md-12 mb-2 mt-3'>
                                                    <h1 className='title-1'>
                                                        Billing Information
                                                    </h1>
                                                </div>

                                                <div className='col-md-6 shiping-info-div'>
                                                    <p>
                                                        <span className='t-color span-b'>First Name: </span> {SelectedBillingAddress?.firstname}                                                   
                                                    </p>
                                                    <p>
                                                        <span className='t-color span-b'>Email: </span> {SelectedBillingAddress?.email}                                                 
                                                    </p>
                                                    <p>
                                                        <span className='t-color span-b'>Country: </span> {SelectedBillingAddress?.country}                                                  
                                                    </p>
                                                    <p>
                                                        <span className='t-color span-b'>Town/City: </span> {SelectedBillingAddress?.city}                                                  
                                                    </p>
                                                    <p>
                                                        <span className='t-color span-b'>Address: </span> {SelectedBillingAddress?.address}                                                 
                                                    </p>
                                                </div>
                                                <div className='col-md-6 shiping-info-div'>                                                
                                                    <p>
                                                        <span className='t-color span-b'>Last Name: </span> {SelectedBillingAddress?.lastname}                                                 
                                                    </p>
                                                    <p>
                                                        <span className='t-color span-b'>Mobile No: </span> {SelectedBillingAddress?.mob_code}{" "}{SelectedBillingAddress?.mobileno}                                                  
                                                    </p>
                                                    <p>
                                                        <span className='t-color span-b'>State: </span> {SelectedBillingAddress?.state}                                                  
                                                    </p>
                                                    <p>
                                                        <span className='t-color span-b'>Zip: </span> {SelectedBillingAddress?.pincode}                                                  
                                                    </p>                                                
                                                </div>
                                                </>
                                            }

                                        </div>

                                        {/* <div className="row" >
                                            <div className="col-lg-4 col-md-4 mb-2">
                                                <div 
                                                className={"text-center " + ((selectedpayment == "cash") ? 'payment-method-box payment-method-box-active active-payment' : '')}
                                                onClick={()=> setselectedpayment('cash')}
                                                >
                                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+"/assets/images/checkout/cod.jpg"} width="110" height="auto" />
                                                    {
                                                        (selectedpayment == "cash") ?
                                                            <img src={process.env.REACT_APP_PUBLIC_PATH_URL+"/assets/images/checkout/selected-reg.png"} className="active-i" />
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                            
                                            {
                                                defaultCurrency.sign == "USD" || defaultCurrency.sign == "$" ?
                                                <div className="col-lg-4 col-md-4 mb-2">
                                                    <div 
                                                    className={"text-center " + ((selectedpayment == "stripe") ? 'payment-method-box payment-method-box-active active-payment' : '')}
                                                    // className="text-center payment-method-box payment-method-box-active active-payment"
                                                    onClick={()=> {setselectedpayment('stripe'); setPaymentURL(`${BASE_URL}/make-payment/stripe`); }}
                                                    >
                                                        <img src={process.env.REACT_APP_PUBLIC_PATH_URL+"/assets/images/checkout/stripe.png"} width="110" height="auto" />
                                                        {
                                                            (selectedpayment == "stripe") ?
                                                                <img src={process.env.REACT_APP_PUBLIC_PATH_URL+"/assets/images/checkout/selected-reg.png"} className="active-i" />
                                                            : null
                                                        }
                                                    </div>
                                                </div> :
                                                <div className="col-lg-4 col-md-4 mb-2">
                                                    <div 
                                                    className={"text-center " + ((selectedpayment == "ccavenue") ? 'payment-method-box payment-method-box-active active-payment' : '')}
                                                    // className="text-center payment-method-box payment-method-box-active active-payment"
                                                    onClick={()=>{setselectedpayment('ccavenue'); setPaymentURL(`${BASE_URL}/make-payment`);}}
                                                    >
                                                        <img src={process.env.REACT_APP_PUBLIC_PATH_URL+"/assets/images/checkout/ccavenue.png"} width="110" height="auto" />
                                                        {
                                                            (selectedpayment == "ccavenue") ?
                                                                <img src={process.env.REACT_APP_PUBLIC_PATH_URL+"/assets/images/checkout/selected-reg.png"} className="active-i" />
                                                            : null
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div> */}

                                        <div className="row">
                                            <form action={paymentURL} method='POST'>
                                                <input type="hidden" name="merchant_id" value={process.env.REACT_APP_CC_MERCHANT_ID}/>
                                                <input type="hidden" name="language" value="EN"/>
                                                <input type="hidden" name="cart_id" value={cartIds}/>
                                                <input type="hidden" name="user_id" value={is_b2b?.user_id}/>
                                                <input type="hidden" name="amount" value={TotalAmountPay}/>
                                                <input type="hidden" name="totalamount" value={TotalAmountPay}/>
                                                <input type="hidden" name="grossamt" value={CartgrossPay} className="form-field" />
                                                <input type="hidden" name="discountamt" value={0} className="form-field" />
                                                {/* <input type="hidden" name="totalamount" value={TotalAmountPay} className="form-field" /> */}
                                                <input type="hidden" name="totalamount" value={TotalAmount} className="form-field" />

                                                <input type="hidden" name="shipping_charge_id" value={shippingId} className="form-field" />
                                                
                                                {/* <input type="hidden" name="shippingcharge" value={shippingChargePay} className="form-field" />*/}
                                                <input type="hidden" name="shippingcharge" value={shippingCharge} className="form-field" />
                                                <input type="hidden" name="netamount" value={TotalAmountPay} className="form-field" />
                                                    
                                                <input type="hidden" name="currency_id" value={defaultCurrency?.id == null ? null : defaultCurrency?.id}/>

                                                {/* <input type="hidden" name="currency" value={process.env.REACT_APP_CC_CURRENCY} />  */}
                                                <input type="hidden" name="currency" value={defaultCurrency?.currency_name == null ? null : defaultCurrency?.currency_name} /> 

                                                <input type="hidden" name="redirect_url" value={process.env.REACT_APP_CC_REDIRECT_URL}/> 

                                                <input type="hidden" name="cancel_url" value={process.env.REACT_APP_CC_CANCEL_URL}/> 
                                                <input type="hidden" name="billing_name" value={`${SelectedBillingAddress?.firstname} ${SelectedBillingAddress?.lastname}`}  className="form-field" /> 
                                                <input type="hidden" name="billing_address" value={SelectedBillingAddress?.address} className="form-field" />
                                                <input type="hidden" name="billing_state" value={SelectedBillingAddress?.state} className="form-field" /> 
                                                <input type="hidden" name="billing_zip" value={SelectedBillingAddress?.pincode} className="form-field" />
                                                <input type="hidden" name="billing_country" value={SelectedBillingAddress?.country} className="form-field" />
                                                <input type="hidden" name="billing_tel" value={SelectedBillingAddress?.mobileno} className="form-field" />
                                                <input type="hidden" name="billing_email" value={SelectedBillingAddress?.email} className="form-field" />
                                                <input type="hidden" name="billing_firstname" value={SelectedBillingAddress?.firstname} className="form-field" />
                                                <input type="hidden" name="billing_lastname" value={SelectedBillingAddress?.lastname} className="form-field" />
                                                <input type="hidden" name="billing_companyname" value={SelectedBillingAddress?.company} className="form-field" />
                                                <input type="hidden" name="billing_city" value={SelectedBillingAddress?.city} className="form-field" />
                                                <input type="hidden" name="billing_mobcode" value={SelectedBillingAddress?.mob_code} className="form-field" />
                                                <input type="hidden" name="billing_mobileno" value={SelectedBillingAddress?.mobileno} className="form-field" />
                                                
                                                <input type="hidden" name="ip_address" value={localStorage.getItem('ipaddress')} className="form-field" />

                                                <input type="hidden" name="firstname" value={SelectedAddress.firstname} className="form-field" />
                                                <input type="hidden" name="lastname" value={SelectedAddress.lastname} className="form-field" />
                                                <input type="hidden" name="email" value={SelectedAddress.email} className="form-field" />
                                                <input type="hidden" name="address" value={SelectedAddress.address} className="form-field" />
                                                <input type="hidden" name="city" value={SelectedAddress.city} className="form-field" />
                                                <input type="hidden" name="state" value={SelectedAddress.state} className="form-field" />
                                                <input type="hidden" name="country" value={SelectedAddress.country} className="form-field" />
                                                <input type="hidden" name="zip" value={SelectedAddress.pincode} className="form-field" />
                                                <input type="hidden" name="mobcode" value={SelectedAddress.mob_code} className="form-field" />
                                                <input type="hidden" name="mobileno" value={SelectedAddress.mobileno} className="form-field" />
                                                <input type="hidden" name="samebilling" value={(SelectedAddress.id == SelectedBillingAddress.id) ? true : false} className="form-field" />
                                                    <input type='submit' style={{display:'none'}} className='checkout-nowbtn mb-3' 
                                                    name="submit" value="Continue to Payment" id="hdsub"/>
                                             </form>
                                            <div className="col-lg-6">
                                                <button className="checkout-nowbtn mb-3 form-actions" style={{ width: 'inherit' }} onClick={placeOrder}>
                                                    Make A Payment
                                                </button>
                                            </div>
                                            <div className="col-lg-6">
                                                <button className="checkout-nowbtn mb-3 form-actions" style={{ width: 'inherit' }} onClick={backToCheckout}>
                                                    Back To Checkout
                                                </button>
                                            </div>
                                        </div>
                                        {/* <Payment braintree_customer_id={braintree_customer_id}
                                            values={values}
                                            CountryList={CountryList} stateList={stateList}
                                            Cartlist={Cartlist}
                                            TotalAmount={TotalAmount}
                                            SaveNewAddress={SaveNewAddress} SelectedAddress={SelectedAddress}
                                            isdefault={isdefault}
                                            isDisable={isDisable}
                                            SelectedBillingAddress={SelectedBillingAddress}
                                            navigate={navigate}
                                        /> */}

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 order-1 order-lg-2 mb-4">
                                <div>
                                    <div style={{ boxShadow: "0 2px 7px 3px #e9e9e9", padding: "15px", background: "#f6f6f5" }}>
                                        <h6 className="title-1">
                                            <i className="fa fa-check" style={{ paddingRight: "7px" }}></i> Order Review
                                        </h6>
                                        {/* <br /> */}
                                        <div className="table-responsive wishlist-tbl">
                                            <table className="w-100">
                                                <thead className="first-wish-th">
                                                    <tr>
                                                        <th>Img</th>
                                                        <th className="text-left">Product Name</th>
                                                        <th>Price</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="tr-unhov-hand table-ver-midl">
                                                {
                                                    Cartlist?.length > 0 ?
                                                        Cartlist?.map((item, index) => {
                                                            return (
                                                                <>
                                                                {/* <tbody className="tr-unhov-hand table-ver-midl" key={Math.random()}> */}
                                                                    {item?.CartJewelryData != null &&
                                                                        <tr key={Math.random()}>
                                                                            <td onContextMenu={(e)=>e.preventDefault()} >
                                                                                <img src={item?.CartJewelryData?.default?.path == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg" : item?.CartJewelryData?.default?.path} width="100" height="auto" className="imgg-box" 
                                                                                onError={(e)=>{
                                                                                    e.target.onerror=null
                                                                                    e.target.src=noimage
                                                                                }}/>
                                                                            </td>
                                                                            <td className="text-left" style={{ verticalAlign: "top" }}>
                                                                                <div className="div-p-table">
                                                                                    <a href={process.env.PUBLIC_URL + `/JDetails/${item?.CartJewelryData?.id}/${item?.CartJewelryData?.slug}?jid=${item?.CartJewelryData?.id}`}
                                                                                        target='_blank'
                                                                                    >
                                                                                        <span className="heading">
                                                                                            {item?.CartJewelryData?.title}
                                                                                        </span>
                                                                                    </a>
                                                                                    <p>
                                                                                        <span className="t-color span-b">SKU : </span>
                                                                                        {item?.CartJewelryData?.itemcode}
                                                                                    </p>
                                                                                    {/* <p>
                                                                            <span className="t-color span-b">Shape :</span>
                                                                            {item?.CartJewelryData?.itemcode}
                                                                        </p> */}
                                                                                    <p>
                                                                                        <span className="t-color span-b">Metal : </span>
                                                                                        {item?.CartJewelryData?.metal_stamp?.paraname} {item?.CartJewelryData?.metal_type?.paraname}
                                                                                    </p>
                                                                                    {
                                                    (item?.CartJewelryData?.category?.name.toLowerCase() == "ring" 
                                                    || item?.CartJewelryData?.category?.name.toLowerCase() == "rings"
                                                    || item?.CartJewelryData?.category?.name.toLowerCase() == "engagement rings"
                                                    || item?.CartJewelryData?.category?.name.toLowerCase() == "engagement ring"
                                                    || item?.CartJewelryData?.category?.name.toLowerCase() == "wedding ring"
                                                    || item?.CartJewelryData?.category?.name.toLowerCase() == "wedding rings"
                                                    || item?.CartJewelryData?.category?.name.toLowerCase() == "chains"
                                                    || item?.CartJewelryData?.category?.name.toLowerCase() == "chain"
                                                    || item?.CartJewelryData?.category?.name.toLowerCase() == "bracelet"
                                                    || item?.CartJewelryData?.category?.name.toLowerCase() == "bracelets"
                                                    )
                                                    ?
                                                                                    <p>
                                                                                        <span className="t-color span-b">{
                                                       (item?.CartJewelryData?.category?.name.toLowerCase() == "ring" 
                                                       || item?.CartJewelryData?.category?.name.toLowerCase() == "rings"
                                                       || item?.CartJewelryData?.category?.name.toLowerCase() == "engagement rings"
                                                       || item?.CartJewelryData?.category?.name.toLowerCase() == "engagement ring"
                                                       || item?.CartJewelryData?.category?.name.toLowerCase() == "wedding ring"
                                                       || item?.CartJewelryData?.category?.name.toLowerCase() == "wedding rings") ? "Ring Size" : null}
                                                       {
                                                       (item?.CartJewelryData?.category?.name.toLowerCase() == "bracelets" || item?.CartJewelryData?.category?.name.toLowerCase() == "bracelet") ? "Bracelet Length"  : null}
                                                       {
                                                       (item?.CartJewelryData?.category?.name.toLowerCase() == "chains" || item?.CartJewelryData?.category?.name.toLowerCase() == "chain") ? "Chain Length"  : null
                                                    } :</span>
                                                                                        {item?.RingSizeData?.paraname}
                                                                                    </p>
                                                                                    :
                                                                                    null
                                                        }
                                                                                    {/* <p>
                                                                                        <span className="t-color span-b">Quantity : </span>
                                                                                        <span style={{fontWeight:"bolder",color:"#23282d"}}>{item?.qty}</span>
                                                                                    </p> */}
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <span>
                                                                                     {defaultCurrency?.sign ?? "$"}
                                                                                    {
                                                                                        is_b2b?.is_b2b == 0 ?
                                                                                        item?.b2c_price?.toFixed(2) :
                                                                                        item?.setting_price?.toFixed(2)
                                                                                    }                                                                               
                                                                               
                                                                                </span><br/>X ({item?.qty})
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <span>
                                                                                {defaultCurrency?.sign ?? "$"}

                                                                                {
                                                                                    is_b2b?.is_b2b == 0 ?
                                                                                    (item?.b2c_price * item?.qty).toFixed(2) :
                                                                                    (item?.setting_price * item?.qty).toFixed(2)
                                                                                }   
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                    {item?.CartDiamondData != null &&
                                                                        <tr key={Math.random()}>
                                                                            <td>
                                                                                <img src={item?.CartDiamondData?.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : item?.CartDiamondData?.image} width="100" height="auto" className="imgg-box" 
                                                                                onError={(e)=>{
                                                                                    e.target.onerror=null
                                                                                    e.target.src=noimage
                                                                                }}/>
                                                                            </td>
                                                                            <td className="text-left" style={{ verticalAlign: "top" }}>
                                                                                <div className="div-p-table">
                                                                                    <Link
                                                                                        className=""
                                                                                        aria-current="page"
                                                                                        target="_blank"
                                                                                        to={`/DiamondDetails/${utf8_to_b64(item?.CartDiamondData?.id)}/${item?.CartDiamondData?.title}?did=${item?.CartDiamondData?.id}`}
                                                                                    >
                                                                                        <span className="heading"> {item?.CartDiamondData?.title} </span>
                                                                                    </Link>
                                                                                        {
                                                                                            DiamondError.includes(item?.CartDiamondData?.id) ?
                                                                                            <p>
                                                                                            <span style={{color:"red",fontWeight:"bold"}}>
                                                                                            Out Of Stock (For Proceed further please remove this diamond from Cart.)</span>
                                                                                            </p> 
                                                                                            : null
                                                                                        }

                                                                                    <p>
                                                                                        <span className="t-color span-b">SKU : </span>
                                                                                        {item?.CartDiamondData?.loatno}
                                                                                    </p>
                                                                                    <p>
                                                                                        <span className="t-color span-b">Shape : </span>
                                                                                        {item?.CartDiamondData?.shape_name?.paraname}
                                                                                    </p>
                                                                                    <p>
                                                                                        <span className="t-color span-b">Carat : </span>
                                                                                        {parseFloat(item?.CartDiamondData?.carat).toFixed(2)}
                                                                                    </p>
                                                                                    <p>
                                                                                        <span className="t-color span-b">Clarity : </span>
                                                                                        {item?.CartDiamondData?.clarity_name?.paraname}
                                                                                    </p>
                                                                                    <p>
                                                                                        <span className="t-color span-b">Color : </span>
                                                                                        {item?.CartDiamondData?.color_name?.paraname}
                                                                                    </p>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <span>
                                                                                {defaultCurrency?.sign ?? "$"}{item?.CartDiamondData?.diamondprice}
                                                                                </span>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <span>
                                                                                {defaultCurrency?.sign ?? "$"}{item?.CartDiamondData?.diamondprice}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    }
                                                                {/* </tbody> */}
                                                                </>
                                                            )
                                                        })
                                                        :
                                                        // <tbody className="tr-unhov-hand table-ver-midl">
                                                            <tr>
                                                                <td colSpan={4}>
                                                                    <div
                                                                        style={{ display: "grid", flex: 1, justifyContent: "center", alignItems: 'center', backgroundColor: '#f1ecf0' }}
                                                                    >
                                                                        <img
                                                                            src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/diamgif.gif"}
                                                                            alt="loading..."
                                                                            style={{ width: 150, height: 150 }} 
                                                                            onError={(e)=>{
                                                                                e.target.onerror=null
                                                                                e.target.src=noimage
                                                                            }}
                                                                        />
                                                                        <h4>No data Found</h4>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        // {/* </tbody> */}
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <br /> */}
                                        <div>
                                            <dl className="order-summary-me-price pad-10-dd-me dd-even" style={{ marginBottom: "10px", background: "#fff" }}>
                                                <dd>
                                                    Sub-Total
                                                    <strong>{defaultCurrency?.sign ?? "$"}<span id="subTotal">{Cartgross}</span></strong>
                                                </dd>
                                                <dd>
                                                    Shipping Charge
                                                    <strong>
                                                        <span id="TxtAmount">
                                                            {shippingCharge == 0 ? 'Free' : 
                                                            <>
                                                            {defaultCurrency?.sign ?? "$"}{shippingCharge}
                                                            </>}
                                                        </span>
                                                        </strong>
                                                </dd>
                                                <dd>
                                                    <span>No of Items</span>
                                                    <strong><span id="ShippingAmt">{Count == 0 ? '-' : Count}</span></strong>
                                                </dd>
                                                <dd>
                                                    <span className="color-000 lsp1">Total</span>
                                                    <strong className=""><span>{defaultCurrency?.sign ?? "$"}<span id="TotalAmt">{TotalAmount}</span></span></strong>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default Checkout