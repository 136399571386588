import axios from 'axios';
import {isMobile} from 'react-device-detect';
import React, { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { BASE_URL, GetAPIUrl } from '../API/APIUrl';
import { confirmRemove, ShowErrorMessage, ShowMessage } from '../module/Tostify';
import noimage from "../assets/images/product-details/noimage.jpg";
import ReactPlayer from 'react-player';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PlayerControls from '../module/PlayerControls';
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import screenful from "screenfull";
import LoadingSpinner from '../module/LoadingSpinner';
import { useSelector } from 'react-redux';
import { b64_to_utf8, BASENAME, Buttontelto, utf8_to_b64 } from '../helpers/Utility';
import ReactOwlCarousel from 'react-owl-carousel';


const $ = window.$;


const DiamondDetails = () => {
    let { sid, slug } = useParams();

    // const DiamondId = sid == undefined ? "" : b64_to_utf8(sid)
    // const JewelryId = localStorage.getItem('JewelryId')
    const location = useLocation();
    localStorage.setItem('URL', location.pathname + location.search)
    const [defaultCurrency, SetDefaultCurrency] = useState(JSON.parse(localStorage.getItem('defaultCurrency')))

    const JewelryId = new URLSearchParams(location.search).get("jid");
    const DiamondId = new URLSearchParams(location.search).get("did");
    const ring = new URLSearchParams(location.search).get("ring");
    const JewelFinalAmt = new URLSearchParams(location.search).get("famount");

    const [jewelryDetail, setjewelryDetail] = useState('')
    const [ringName, setRingName] = useState('')
    const [DiamondDetail, setDiamondDetail] = useState([])
    const [Recommended, setRecommended] = useState([])

    const [selecteImage, setSelecteImage] = useState('')
    const [IsLoading, setIsLoading] = useState(false)
    const [IsWishlistLoading, setIsWishlistLoading] = useState(false)
    const [IsCartLoading, setIsCartLoading] = useState(false)
    const [breadCrumpDiaLoatNo, setBreadCrumpDiaLoatNo] = useState(false)

    const token = localStorage.getItem('token')
    const buildYourOwn = location.pathname.split("/")[2] == "Build-Your-Own";

    const [globalJewelryMargin,setGlobalJewelryMargin] = useState(0);
    const [globalMarkup,setGlobalMarkup] = useState(0);
    const [globalTagFact,setglobalTagFact] = useState(1);
    
    const is_b2b = JSON.parse(localStorage.getItem('data'))
    let customerGroup = JSON.parse(localStorage.getItem('customerGroup'));

    const [sideDiaGrpId,setSideDiaGrpId] = useState(null);
    const [prodcutMetalPrice, setProdcutMetalPrice] = useState(0);
    const [prodcutSideDiaPrice, setProdcutSideDiaPrice] = useState(0);
    const [prodcutMetalWeight, setProdcutMetalWeight] = useState(0);
    const [finalAmount,setFinalAmount] = useState(0);
    const [labourtCharge,setLabourtCharge] = useState(0);
    
    const [otherCharge,setOtherCharge] = useState(0);
    const [centerDiaPrice,setCenterDiaPrice] = useState(0);
    const [finalGST,setFinalGST] = useState(0);
    const [sideDiaPrice,setSideDiaPrice] = useState(0);
    const [metalAmount,setMetalAmount] = useState(0);

    const [Wishlist, setWishlist] = useState('');

    // let { id } = useParams();
    useEffect(() => {
        getGlobalSettings();
        CallDiamondDetailApi()
        
        if (token != null) {
            CallCartCountApi()
        }
        
    }, [])

    
    const getGlobalSettings = async () => {   
        await axios.post(BASE_URL + GetAPIUrl.GLOBALMARKUPTAG_URL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                if(response?.data?.type == "success")
                {
                    if(response?.data?.data.length > 0 )
                    {
                        setGlobalMarkup(response?.data?.data[0].side_stone_mark_up);
                        setglobalTagFact(response?.data?.data[0].side_stone_tag_fact);
                        setGlobalJewelryMargin(response?.data?.data[0].b2c_margin_rate);
                        console.log("0000000000000000000000000000000000000000",response?.data?.data[0].b2c_margin_rate)
                        if (JewelryId != null) {
                            CalljewelryDetailApi(response?.data?.data[0].b2c_margin_rate)
                        }
                    }
                }  
            }).catch(error => {
            }).finally(() => {

            })
    }

    

    const CallDiamondDetailApi = async () => {
        setIsLoading(true)
        let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;

        const controller = new AbortController();

        axios.get(BASE_URL + GetAPIUrl.DIAMONDDETAIL_URL + `/${DiamondId}` + '?is_b2b=' + getdata+'&currency_id='+currency_id)
            .then(response => {
                if (response.data.success == true) {
                    setIsLoading(false)
                    setDiamondDetail(response?.data?.data);
                    setBreadCrumpDiaLoatNo(response?.data?.data?.loatno);
                    setSelecteImage(response?.data?.data?.images[0])
                    setRecommended(response?.data?.data?.recommended);

                } else {
                    setIsLoading(false)
                    ShowErrorMessage(response.data.message)
                }
            }).catch(error => {
                ShowErrorMessage(error.message)
            });
        controller.abort()
    }

    const updatePrice = async (res,labrCharge,othrCharge,sideDiamondAmount,marginrate)=> {
        // let metalPrice = prodcutMetalPrice;             
        let metalPrice = res.metalprice;             
        // let sideStonePrice = prodcutSideDiaPrice;        
        console.log("metalPrice",metalPrice)
        // console.log("sideStonePrice",sideStonePrice)
        let sideStonePrice = sideDiamondAmount;
        
        let centerStonePrice = res?.centerstoneprice;
        let markup = res?.mark_up;
        let tagfact = res?.tag_fact;
    
        console.log("jewelryDetail",res)
        console.log("centerStonePrice",centerStonePrice)
        console.log("markup",markup)
        console.log("tagfact",tagfact)
        
        let metalWeight = res.metalweight;
        console.log("metalWeight",metalWeight)
        console.log("labourtCharge",labrCharge)
        console.log("otherCharge",othrCharge)
    
        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "sidediamond")
            {
                let sideDiaDisc = customerGroup?.discount;
                let discontPrice = (sideStonePrice * sideDiaDisc)/100;
                console.log("customerGroup",customerGroup);
                console.log("res?.sidestoneprice",sideStonePrice);
                setSideDiaPrice((sideStonePrice - discontPrice));
                sideStonePrice = (sideStonePrice - discontPrice);
            }else{
                setSideDiaPrice(sideStonePrice);
            }   
        }else{
            setSideDiaPrice(sideStonePrice);
        }
    
    
        let chainWeight = (res?.chainweight != null) ? res?.chainweight : 0;
        let totalWeight = metalWeight + chainWeight;
            totalWeight = totalWeight.toFixed(2);
        let finalLabCharge =  labrCharge * totalWeight;
    
        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "labour")
            {
                let labourDisc = customerGroup?.discount;
                let discontLabourPrice = (finalLabCharge * labourDisc)/100;
                finalLabCharge=finalLabCharge-discontLabourPrice;
            }
        }
        console.log("labourCharge",finalLabCharge)
    
        let totalMetalPrice = metalPrice + finalLabCharge + othrCharge;
            finalLabCharge =  finalLabCharge.toFixed(2);
        let totalMFGCost = totalMetalPrice + sideStonePrice;
        console.log("totalMFGCost",totalMFGCost)
    
        let GST = 0;   
        let finalPrice = 0;     
        if( markup > 0 && tagfact > 0){
            let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);
            let finalTagfact = finalMarkup/tagfact;
                finalPrice = finalTagfact + centerStonePrice;
                GST = finalTagfact - totalMFGCost;
                GST = GST.toFixed(2);
        }else if(markup > 0 && tagfact == 0){
            let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);          
                finalPrice = finalMarkup + centerStonePrice;
                 GST = finalMarkup - totalMFGCost;
                 GST = GST.toFixed(2);
        }else if(markup == 0 && tagfact > 0){           
            let finalTagfact = totalMFGCost/tagfact;
                finalPrice = finalTagfact + centerStonePrice;
                GST = finalTagfact - totalMFGCost;
                GST = GST.toFixed(2);
        }else{
               finalPrice = totalMFGCost + centerStonePrice;
        }
    
        let isb2bgetdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        // console.log("globalJewelryMargin",globalJewelryMargin)
        console.log("globalJewelryMargin",marginrate)
        console.log("isb2bgetdata",isb2bgetdata)
        if(marginrate > 0 && isb2bgetdata == 0){
            finalPrice = finalPrice + ((finalPrice*marginrate)/100);
        }
    
        console.log("finalPrice",finalPrice)
    
        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "fulljewelry")
            {
                let finalDisc = customerGroup?.discount;
                let discontfinalPrice = (finalPrice * finalDisc)/100;
                finalPrice=finalPrice-discontfinalPrice;
            }
        }
        
         setFinalGST(GST);
        //  setFinalAmount(finalPrice.toFixed(2));
         setSideDiaPrice(sideStonePrice);
         setCenterDiaPrice(centerStonePrice);
        //  setMetalAmount(metalPrice);
        //  setProdcutMetalPrice(metalPrice);
         setMetalAmount(res.metalprice);
         setProdcutMetalPrice(res.metalprice);
         
         await axios.post(BASE_URL + GetAPIUrl.CURRENCYCONVERT,{currency_id:defaultCurrency?.id == null ? null : defaultCurrency?.id,amount:finalPrice}, {
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              // 'Authorization': 'Bearer ' + token,
          }
      })
          .then(response => {
              console.log("response",response)
              if(response?.data?.success == true)
              {
                  setFinalAmount(response?.data?.amount)
              }  
          }).catch(error => {
          }).finally(() => {
              setIsLoading(false);
          })
         
        //  setSideDiaData(jewelryDetail?.sideStone); 
        // setProdcutMetalPrice(jewelryDetail?.sync_metal_price);                
        // setProdcutSideDiaPrice(jewelryDetail?.sync_side_dia_price); 
        // setProdcutMetalWeight(jewelryDetail?.metalweight);
        console.log("final",finalPrice.toFixed(2))
    }
    
    const updatePrice11 = async (res,labrCharge,othrCharge,sideDiamondAmount,marginrate)=> {
        let metalPrice = prodcutMetalPrice;             
        // let sideStonePrice = prodcutSideDiaPrice;        
        console.log("metalPrice",metalPrice)
        console.log("res.metalprice",res.metalprice)
        // console.log("sideStonePrice",sideStonePrice)
        
        let centerStonePrice = res?.centerstoneprice;
        let markup = res?.mark_up;
        let tagfact = res?.tag_fact;

        console.log("jewelryDetail",res)
        console.log("centerStonePrice",centerStonePrice)
        console.log("markup",markup)
        console.log("tagfact",tagfact)
        
        let metalWeight = res.metalweight;
        console.log("metalWeight",metalWeight)
        console.log("labourtCharge",labrCharge)
        console.log("otherCharge",othrCharge)

        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "sidediamond")
            {
                let sideDiaDisc = customerGroup?.discount;
                let discontPrice = (res?.sidestoneprice * sideDiaDisc)/100;
                console.log("customerGroup",customerGroup);
                console.log("res?.sidestoneprice",res?.sidestoneprice);
                setSideDiaPrice((res?.sidestoneprice - discontPrice));
                sideDiamondAmount = (res?.sidestoneprice - discontPrice);
            }else{
                sideDiamondAmount = res?.sidestoneprice;
                setSideDiaPrice(res?.sidestoneprice);
            }   
        }else{
            sideDiamondAmount = res?.sidestoneprice;
            setSideDiaPrice(res?.sidestoneprice);
        }

        // let sideDiamondAmount=0;
        // if(is_b2b?.is_b2b == 1){
        //     if(customerGroup != null && customerGroup?.group_type == "sidediamond")
        //     {
        //         let sideDiaDisc = customerGroup?.discount;
        //         let discontPrice = (res?.sidestoneprice * sideDiaDisc)/100;
        //         console.log("customerGroup",customerGroup);
        //         console.log("res?.sidestoneprice",res?.sidestoneprice);
        //         // setSideDiaPrice((res?.sidestoneprice - discontPrice));
        //         sideDiamondAmount = (res?.sidestoneprice - discontPrice);
        //     }else{
        //         // setSideDiaPrice(sideDiamondAmount);
        //     }   
        // }else{
        //     // setSideDiaPrice(sideDiamondAmount);
        // }

        let chainWeight = (res?.chainweight != null) ? res?.chainweight : 0;
        let totalWeight = metalWeight + chainWeight;
            totalWeight = totalWeight.toFixed(2);
        let finalLabCharge =  labrCharge * totalWeight;
        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "labour")
            {
                let labourDisc = customerGroup?.discount;
                let discontLabourPrice = (finalLabCharge * labourDisc)/100;
                finalLabCharge=finalLabCharge-discontLabourPrice;
            }
        }
        console.log("labourCharge",finalLabCharge)
        
        let totalMetalPrice = res.metalprice + finalLabCharge + othrCharge;
            finalLabCharge =  finalLabCharge.toFixed(2);
        let totalMFGCost = totalMetalPrice + sideDiamondAmount;
        console.log("totalMFGCost",totalMFGCost)

        let GST = 0;   
        let finalPrice = 0;     
        if( markup > 0 && tagfact > 0){
            let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);
            let finalTagfact = finalMarkup/tagfact;
                finalPrice = finalTagfact + centerStonePrice;
                GST = finalTagfact - totalMFGCost;
                GST = GST.toFixed(2);
        }else if(markup > 0 && tagfact == 0){
            let finalMarkup = totalMFGCost + ((totalMFGCost * markup)/100);          
                finalPrice = finalMarkup + centerStonePrice;
                GST = finalMarkup - totalMFGCost;
                GST = GST.toFixed(2);
        }else if(markup == 0 && tagfact > 0){           
            let finalTagfact = totalMFGCost/tagfact;
                finalPrice = finalTagfact + centerStonePrice;
                GST = finalTagfact - totalMFGCost;
                GST = GST.toFixed(2);
        }else{
            finalPrice = totalMFGCost + centerStonePrice;
        }

        let isb2bgetdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        console.log("globalJewelryMargin",globalJewelryMargin)
        console.log("globalJewelryMargin",marginrate)
        console.log("isb2bgetdata",isb2bgetdata)
        if(marginrate > 0 && isb2bgetdata == 0){
            finalPrice = finalPrice + ((finalPrice*marginrate)/100);
        }

        if(is_b2b?.is_b2b == 1){
            if(customerGroup != null && customerGroup?.group_type == "fulljewelry")
            {
                let finalDisc = customerGroup?.discount;
                let discontfinalPrice = (finalPrice * finalDisc)/100;
                finalPrice=finalPrice-discontfinalPrice;
            }
        }
        
        setFinalGST(GST);
        // setFinalAmount(finalPrice.toFixed(2));
        setSideDiaPrice(sideDiamondAmount);
        setCenterDiaPrice(centerStonePrice);
        // setMetalAmount(metalPrice);
        // setProdcutMetalPrice(metalPrice);
        setMetalAmount(res.metalprice);
        setProdcutMetalPrice(res.metalprice);

        await axios.post(BASE_URL + GetAPIUrl.CURRENCYCONVERT,{currency_id:defaultCurrency?.id == null ? null : defaultCurrency?.id,amount:finalPrice}, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                console.log("response",response)
                if(response?.data?.success == true)
                {
                    setFinalAmount(response?.data?.amount)
                }  
            }).catch(error => {
            }).finally(() => {
                setIsLoading(false);
            })
        
        //  setSideDiaData(jewelryDetail?.sideStone); 
        // setProdcutMetalPrice(jewelryDetail?.sync_metal_price);                
        // setProdcutSideDiaPrice(jewelryDetail?.sync_side_dia_price); 
        // setProdcutMetalWeight(jewelryDetail?.metalweight);
        console.log("final",finalPrice.toFixed(2))
    }

  const setSideStonePrice = async (res,labourCharge,otherCharge,marginrate)=> {
        setIsLoading(true);
        let tempSideDiaData = res?.jewelry_diamonds.filter(td => td.diamondtype === 'Side');
        console.log("tempSideDiaData",tempSideDiaData)

        let stoneGrpId = localStorage.getItem("sideDiaColGrp");

        await axios.post(BASE_URL + GetAPIUrl.STONGROUPPRICE_URL,{stoneGrpId}, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                console.log("response",response)
                if(response?.data?.type == "success")
                {
                    let stoneGrpDetail = response?.data?.data?.stone_group_detail;            
                    let sideDiamondAmount = 0;
                    $.each(tempSideDiaData,function(sideIndex,sideItem){   
                        console.log("sideItem --- ",sideItem);
                        let sideStoneSize = sideItem?.fromsize_name != null ? sideItem?.fromsize_name : null;

                        let sideStoneShape = sideItem?.shape != undefined ? sideItem?.shape : null;
                        let sideWeight = sideItem?.totalweight != undefined ? sideItem?.totalweight : null;
                        console.log("sideStoneSize",sideStoneSize)
                        console.log("sideStoneShape",sideStoneShape)
                        console.log("sideWeight",sideWeight)
                        // let sideStoneSize = sideItem?.side_diam_data?.side_stone_size != undefined ? sideItem?.side_diam_data?.side_stone_size : [];
                        //     sideStoneSize = $.isEmptyObject(sideStoneSize) === true ? sideItem?.side_stone_size : sideStoneSize;
                        
                        // let sideStoneShape = sideItem?.side_diam_data?.shape?.id != undefined ? sideItem?.side_diam_data?.shape?.id : null;                
                        //     sideStoneShape = sideStoneShape === null ? sideItem?.shape?.id : sideStoneShape;
        
                        // let sideWeight = sideItem?.side_diam_data?.totalweight != undefined ? sideItem?.side_diam_data?.totalweight : null;
                        //     sideWeight = sideWeight === null ? sideItem?.totalweight : sideWeight;
                        $.each(stoneGrpDetail,function(index,item){   
                            console.log("inner item",item)
                            //  if(item?.size?.paraname == sideStoneSize?.paraname && item?.shape == sideStoneShape)
                            if(item?.size?.paraname == sideStoneSize?.paraname && item?.shape == sideStoneShape)
                            {       
                                console.log("inner item  amount  - ",item?.amount)
                                sideDiamondAmount = sideDiamondAmount + (item?.amount * sideWeight);
                            }
        
                        });
                    });
                    console.log("sideDiamondAmount1",sideDiamondAmount)
                    console.log("globalMarkup",globalMarkup)
                    console.log("globalTagFact",globalTagFact)
                    sideDiamondAmount =  sideDiamondAmount + (globalMarkup * sideDiamondAmount / 100);
                    sideDiamondAmount =  sideDiamondAmount / globalTagFact;
                    console.log("sideDiamondAmount2>>>",sideDiamondAmount)
                    
                    setProdcutSideDiaPrice(sideDiamondAmount);
                    updatePrice(res,labourCharge,otherCharge,sideDiamondAmount,marginrate)
                }  
            }).catch(error => {
            }).finally(() => {
                setIsLoading(false);
            })
    }

    

    const CalljewelryDetailApi = async (marginrate) => {
        const controller = new AbortController();
        let getdata = is_b2b?.is_b2b == undefined ? 0 : is_b2b?.is_b2b;
        let currency_id = defaultCurrency?.id == null ? null : defaultCurrency?.id;

        setIsLoading(true)
        await axios.get(BASE_URL + GetAPIUrl.JEWELRYDETAIL_URL + `/${JewelryId}` + '?is_b2b=' + getdata+'&currency_id='+currency_id)
            .then(response => {
                if (response.data.success == true) {
                    setjewelryDetail(response?.data?.data);

                    setSideDiaGrpId(localStorage.getItem("lastname"));
                    console.log("response?.data?.data",response?.data?.data);
                    // setFinalAmount(response?.data?.data?.settingprice);
                    // setFinalAmount(response?.data?.data?.settingprice);
                    setSideDiaPrice(response?.data?.data?.sidestoneprice);
                    setCenterDiaPrice(response?.data?.data?.centerstoneprice);
                    setMetalAmount(response?.data?.data?.metalprice);
                    setProdcutMetalPrice(response?.data?.data?.metalprice);
                    setProdcutMetalWeight(response?.data?.data?.metalweight); 

                    setLabourtCharge(response?.data?.labourCharge);
                    setOtherCharge(response?.data?.otherCharge);

                    response?.data?.data?.ringSize.map((item)  => {
                        if(item?.id == ring){
                            setRingName(item?.paraname)
                        }
                    })
                    setSideStonePrice(response?.data?.data,response?.data?.labourCharge,response?.data?.otherCharge,marginrate);
                } else {
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)
            }).finally(() => {
                setIsLoading(false)
            })
        controller.abort()
    }
    

    const selectionFun = (item, index) => {
        setSelecteImage(item)
    }


    const CallAddtoWishlistApi = async () => {
        setIsWishlistLoading(true)
        const controller = new AbortController();
        var form = new FormData();

        form.append("jewelryid", JewelryId == null ? "" : JewelryId);
        form.append("diamondid", DiamondId == null ? "" : DiamondId);
        form.append('ringsize', ring == null ? '' : ring)
        form.append('qty', 1)
        form.append('centerStonePrice', centerDiaPrice)
        form.append('sideDiaGrpId', localStorage.getItem('sideDiaColGrp'))
        form.append('settingPrice', finalAmount)
        form.append('currency_id', defaultCurrency?.id == null ? null : defaultCurrency?.id)

        await axios.post(BASE_URL + GetAPIUrl.WISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    setIsWishlistLoading(false)
                    // ShowMessage(response.data.message)
                    CallCartCountApi()
                    // window.location.pathname = '/Wishlist'
                } else {
                    setIsWishlistLoading(false)
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                setIsWishlistLoading(false)
                ShowErrorMessage(error.message)
            });
        controller.abort()
    }
    const CallAddtoCart = async () => {
        setIsCartLoading(true)
        const controller = new AbortController();
        
        var form = new FormData();

        form.append("jewelryid", JewelryId == null ? "" : JewelryId);
        form.append("diamondid", DiamondId == null ? "" : DiamondId);
        form.append('ringsize', ring == null ? '' : ring)
        form.append('qty', 1)
        form.append('centerStonePrice', centerDiaPrice)
        form.append('sideDiaGrpId', localStorage.getItem('sideDiaColGrp'))
        form.append('settingPrice', finalAmount)
        form.append('currency_id', defaultCurrency?.id == null ? null : defaultCurrency?.id)

        await axios.post(BASE_URL + GetAPIUrl.ADDTOCART_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    setIsCartLoading(false)
                    ShowMessage(response.data.message)
                    CallCartCountApi()
                    // window.location.pathname = '/Cart'
                } else {
                    setIsCartLoading(false)
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                setIsCartLoading(false)
                ShowErrorMessage(error.message)
            });
        controller.abort()
    }


    $(function () {
        $('.f-click').on({
            'click': function () {
                // $('.change-image').attr('src','images/product/p1.png');
                $(this).parent().closest('.find-img').find('.change-image').attr('src', $(this).data('src'));
                // $(this).toggleClass('active-1');
                $(this).addClass("active-1").siblings().removeClass("active-1");
            }
        });
        // Instantiate EasyZoom instances
        var $easyzoom = $('.easyzoom').easyZoom();
        // Setup thumbnails example
        var api1 = $easyzoom.filter('.easyzoom--with-thumbnails').data('easyZoom');

        $('.thumbnails').on('click', 'a', function (e) {
            var $this = $(this);
            e.preventDefault();
            // Use EasyZoom's `swap` method
            api1.swap($this.data('standard'), $this.attr('href'));
        });
        // Setup toggles example
        var api2 = $easyzoom.filter('.easyzoom--with-toggle').data('easyZoom');

        $('.toggle').on('click', function () {
            var $this = $(this);

            if ($this.data("active") === true) {
                $this.text("Switch on").data("active", false);
                api2.teardown();
            } else {
                $this.text("Switch off").data("active", true);
                api2._init();
            }
        });

        $(".add-active li").click(function () {
            $("li").removeClass("active-bor-z");
            $(this).addClass("active-bor-z");
        });
        $(window).scroll(function () {
            var pos = $(window).scrollTop();
            if (pos >= 100) {
                var explorefix = $('.myHeader').css("height");
                $(".thumbsider").css({
                    position: 'sticky',
                });
                $('.thumbsider').css("top", parseInt(explorefix) + 10);
            }
            else {
                $(".thumbsider").css({
                    position: 'inherit',
                    top: '0px'
                });

            }
        })
    });
    

    // let sum = parseFloat(jewelryDetail?.settingprice) + parseFloat(DiamondDetail?.diamondprice)
    // let sum = parseFloat(finalAmount) + parseFloat(DiamondDetail?.diamondprice)
    let sum = parseFloat(JewelFinalAmt) + parseFloat(DiamondDetail?.diamondprice)

    const CallCartCountApi = async () => {
        const controller = new AbortController();

        await axios.get(BASE_URL + GetAPIUrl.CARTWISHLISTCOUNT_URL, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(response => {
                if (response.data.success == true) {
                    setWishlist(response?.data?.wishlist?.diamond_ids)
                    localStorage.setItem('wishlistcount', JSON.stringify(response.data.wishlist.count))
                    localStorage.setItem('cartcount', JSON.stringify(response.data.cart.count))
                    $(function () {
                        $('.addtocartCount').html(JSON.stringify(response.data.cart.count));
                        $('.addtowishlistCount').html(JSON.stringify(response.data.wishlist.count));
                    })
                } else {
                    localStorage.setItem('wishlistcount', JSON.stringify(0))
                    localStorage.setItem('cartcount', JSON.stringify(0))
                }
            }).catch(error => {
            }).finally(() => {

            })
        controller.abort()
    }

    const confirmRemovejewelry = () => {

        confirmRemove("Are you Sure","You want to remove diamond from wishlist?").then((result) => {
            if (result == true) {
                CallDeletejewelry()

            }
        }

        )
    }
    const CallDeletejewelry = async () => {
        const controller = new AbortController();
        var form = new FormData();

        form.append("id", "");
        form.append("jewelryid", '');
        form.append("diamondid", DiamondId);

        await axios.post(BASE_URL + GetAPIUrl.DELETEWISHLIST_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
            .then(response => {
                if (response.data.success == true) {

                    // ShowMessage(response.data.message)
                    CallCartCountApi()
                } else {

                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                ShowErrorMessage(error.message)

            });
        controller.abort()
    }
    return (
        <>
            {IsLoading || IsWishlistLoading || IsCartLoading ?
                < LoadingSpinner />
                :
            <>
                <div>
                    <div className="container container-main">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href={process.env.PUBLIC_URL + '/'} className='desk-top-title-sm a-taglink'><i className="fa fa-home" aria-hidden="true"></i></a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                {buildYourOwn == true ?
                                <a href={process.env.PUBLIC_URL + '/DiamondList/Build-Your-Own'} className='desk-top-title-sm a-taglink'>
                                    Diamond
                                    </a>
                                    :
                                    <a href={process.env.PUBLIC_URL + '/'} className='desk-top-title-sm a-taglink'>
                                    Diamond
                                    </a>
                                }
                                    </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {breadCrumpDiaLoatNo}
                                    </li>
                            </ol>
                        </nav>

                    </div>
                </div>


                {buildYourOwn == true &&

                    <section className="mobile-view-none" >
                        <div className="container container-main">
                            <div className="wizard2-steps mb-3">
                                <div className="step wizard2-steps-heading active-step keep-left">
                                    <div className="node">
                                        <div className="node-skin">
                                            <div className="cont">
                                                <h2 className="nostyle-heading">Build Your Own Ring</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {JewelryId == null ?
                                    <div className="cyo-bar-step step step-item ">
                                        <div className="node" >
                                            <div className="node-skin">
                                                <div className="pho">
                                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg"} alt="setting" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                </div>
                                                <div className="cont">
                                                    <div className="action help-tips">
                                                        <a href="#" className="td-u bar-action line1-doted-2"></a>
                                                    </div>
                                                    <div className="heading">
                                                        <div className="action help-tips">
                                                            <a href="#" className="td-u bar-action">Choose</a>
                                                        </div>
                                                        <h2 className="nostyle-heading">setting</h2>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="cyo-bar-step step step-item active-step keep-left">
                                        <div className="node" >
                                            <div className="node-skin">
                                                <div className="pho">
                                                    {/* <img style={{ background: 'rgba(76, 175, 80, 0.1)' }} src={jewelryDetail?.default?.path == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg" : jewelryDetail?.default?.path} alt="setting" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/> */}
                                                                    <img style={{ background: 'rgba(76, 175, 80, 0.1)' }} src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg"} alt="setting" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                </div>
                                                <div className="cont">
                                                    <div className="heading"><h2 className="nostyle-heading">Setting</h2>
                                                        <div className="action help-tips">
                                                            <div className="td-u bar-action">{jewelryDetail?.title}
                                                                {/* <aside>Price :{defaultCurrency?.sign ?? "$"}{jewelryDetail?.settingprice}</aside> */}
                                                                {/* <aside>Price :{defaultCurrency?.sign ?? "$"}{finalAmount}</aside> */}
                                                                <aside>Price :{defaultCurrency?.sign ?? "$"}{JewelFinalAmt}</aside>
                                                            </div>
                                                        </div>
                                                        <div className="action double-action" style={{ display: 'flex' }}>
                                                            <a href={process.env.PUBLIC_URL + `/JDetails/Build-Your-Own/${jewelryDetail?.id}/${jewelryDetail?.title}?jid=${jewelryDetail?.id}`}>
                                                            View Details
                                                                {/* <button variant="primary" type="button" className="">View Details</button> */}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {DiamondId == null || DiamondId == null ?
                                    <div className="cyo-bar-step step step-item ">
                                        <div className="node" >
                                            <div className="node-skin">
                                                <div className="pho">
                                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg"} alt="diamond" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                </div>
                                                <div className="cont">
                                                    <div className="action help-tips">
                                                        <a href="#" className="td-u bar-action line1-doted-2"></a>
                                                    </div>
                                                    <div className="heading">
                                                        <div className="action help-tips">
                                                            <a href="#" className="td-u bar-action">Choose</a>
                                                        </div>
                                                        <h2 className="nostyle-heading">Diamond</h2>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    :
                                    <div className="cyo-bar-step step step-item  active-step keep-left">
                                        <div className="node" >
                                            <div className="node-skin">
                                                <div className="pho">
                                                    <img src={DiamondDetail.image == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/diamonds.svg" : DiamondDetail.image} alt="diamond" style={{ backgroundColor: "white" }} onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                </div>
                                                <div className="cont">
                                                    <div className="action help-tips">
                                                        <a href="#" className="td-u bar-action line1-doted-2"></a>
                                                    </div>
                                                    <div className="heading">
                                                        <h2 className="nostyle-heading">Diamond</h2>
                                                        <div className="action help-tips">
                                                            <div className="td-u bar-action">{DiamondDetail.title}
                                                                <aside>Price :{defaultCurrency?.sign ?? "$"}{DiamondDetail.diamondprice}</aside></div>
                                                        </div>
                                                        <div className="action double-action" style={{ display: 'flex' }}>
                                                            <a target='_blank' href={process.env.PUBLIC_URL + `/DiamondDetails/Build-Your-Own/${DiamondDetail.title}?did=${DiamondDetail.id}`}>
                                                            View Details
                                                                {/* <button variant="primary" type="button" className="">View Details</button> */}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {JewelryId != null && DiamondId != null ?
                                    <div className="step step-item invariant-color active-step keep-left">
                                        <div className="node">
                                            <div className="node-skin">
                                                <div className="pho">
                                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/ring.svg"} alt="ring" style={{ backgroundColor: "white" }} onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                </div>
                                                <div className="cont">
                                                    <div className="action help-tips">TOTAL</div>
                                                    <div className="heading"><h2 className="nostyle-heading">{defaultCurrency?.sign ?? "$"}{sum.toFixed(2)}</h2></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="step step-item invariant-color">
                                        <div className="node">
                                            <div className="node-skin">
                                                <div className="pho">
                                                    <img src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/ring.svg"} alt="ring" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                </div>
                                                <div className="cont">
                                                    <div className="heading"><h2 className="nostyle-heading">TOTAL</h2></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </section>

                }

                <section className="jumbotron pt-3">
                    <div className="container container-main">
                        <div className="product-details">
                            <div className="row">

                                <div className="col-lg-6">
                                    <div className="thumbsider" id="sidebar">
                                        {
                                            selecteImage?.type == "Image" ?
                                                <div className="easyzoom easyzoom--overlay easyzoom--with-thumbnails boximg-shadot box-h-w mar-l-94">

                                                    <a href={selecteImage?.path}>
                                                        <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={selecteImage?.path == null ? noimage : selecteImage?.path}
                                                            alt=""
                                                            className="img-w-100" />

                                                    </a>
                                                </div>
                                                :
                                                DiamondDetail?.video?.map((item, index) => {
                                                    return (
                                                        // <div
                                                        //     onMouseMove={handleMouseMove}
                                                        //     onMouseLeave={hanldeMouseLeave}
                                                        //     ref={playerContainerRef}
                                                        //     className={classes.playerWrapper}
                                                        //     // className="boximg-shadot box-h-w mar-l-94"
                                                        //      key={item.id}
                                                        // >
                                                        //     <ReactPlayer
                                                        //         ref={playerRef}
                                                        //         width="100%"
                                                        //         height="100%"
                                                        //         url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                                                        //         pip={pip}
                                                        //         playing={playing}
                                                        //         controls={false}
                                                        //         light={light}
                                                        //         loop={loop}
                                                        //         playbackRate={playbackRate}
                                                        //         volume={volume}
                                                        //         muted={muted}
                                                        //         onProgress={handleProgress}
                                                        //         config={{
                                                        //             file: {
                                                        //                 attributes: {
                                                        //                     crossorigin: "anonymous",
                                                        //                 },
                                                        //             },
                                                        //         }}
                                                        //     />

                                                        //     <PlayerControls
                                                        //         ref={controlsRef}
                                                        //         onSeek={handleSeekChange}
                                                        //         onSeekMouseDown={handleSeekMouseDown}
                                                        //         onSeekMouseUp={handleSeekMouseUp}
                                                        //         onDuration={handleDuration}
                                                        //         onRewind={handleRewind}
                                                        //         onPlayPause={handlePlayPause}
                                                        //         onFastForward={handleFastForward}
                                                        //         playing={playing}
                                                        //         played={played}
                                                        //         elapsedTime={elapsedTime}
                                                        //         totalDuration={totalDuration}
                                                        //         onMute={hanldeMute}
                                                        //         muted={muted}
                                                        //         onVolumeChange={handleVolumeChange}
                                                        //         onVolumeSeekDown={handleVolumeSeekDown}
                                                        //         onChangeDispayFormat={handleDisplayFormat}
                                                        //         playbackRate={playbackRate}
                                                        //         onPlaybackRateChange={handlePlaybackRate}
                                                        //         onToggleFullScreen={toggleFullScreen}
                                                        //         volume={volume}
                                                        //     />
                                                        // </div>

                                                        <div className="boximg-shadot box-h-w mar-l-94" key={Math.random()}>
                                                            <iframe style={{ minWidth: "100%", minHeight: "350px" }} allow="autoplay"
                                                                src={item?.path == null ? noimage : item?.path}></iframe>
                                                        </div>
                                                    )
                                                })}

                                        <ul className="ul-start thumbnails add-active1 set-thumb" >
                                            {
                                                DiamondDetail?.images?.map((item, index) => {
                                                    return (
                                                        <li key={Math.random()} className={selecteImage?.id == item?.id ?
                                                            "active-bor-z" :
                                                            "boximg-shado"} onClick={() => selectionFun(item, index)}>
                                                            <a data-standard={item?.path} >
                                                                <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} src={item?.path == null ? noimage : item?.path}
                                                                    alt="" />
                                                            </a>
                                                        </li>
                                                    )
                                                })}
                                            {
                                                DiamondDetail?.video?.map((item, index) => {
                                                    return (
                                                        <li key={Math.random()} className={selecteImage?.id == item?.id ?
                                                            "active-bor-z" : "boximg-shado"} >
                                                            <a href={item?.path} className="iframe-video-thumb"
                                                                onClick={() => selectionFun(item, index)}>
                                                                <img
                                                                    src="https://cdn.shopify.com/s/files/1/0057/0736/6467/files/prod-video.png?v=1611210220"
                                                                    alt="https://cdn.shopify.com/s/files/1/0057/0736/6467/files/prod-video.png?v=1611210220" onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>
                                                            </a>
                                                        </li>
                                                    )
                                                })}
                                        </ul>
                                    </div>

                                </div>
                                <div className="col-lg-6 pl-5 mob-pl-15 mob-mt-30">
                                    <div className="">

                                        <h4 className="lsp1 Hypatia-bold tit-color mb-3 pr-5">
                                            {DiamondDetail?.title}
                                            {/* {DiamondDetail?.status_name?.paraname == 'Available' &&
                                                <span className="hand details-heart-fixed" onClick={() => token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : CallAddtoWishlistApi()}>
                                                    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#AB7C94" strokeOpacity="0.65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                            } */}
                                            {DiamondDetail?.status_name?.paraname == 'Available' &&
                                                Wishlist != '' ?
                                                Wishlist?.includes(DiamondId) == true ?

                                                    <span className="hand details-heart-fixed" onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : confirmRemovejewelry()}>
                                                        <svg width="24" height="21" viewBox="0 0 24 21" fill="#ca9e79" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#AB7C94" strokeOpacity="0.65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </span>
                                                    :
                                                    <span className="hand details-heart-fixed" onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : CallAddtoWishlistApi()}>
                                                        <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#AB7C94" strokeOpacity="0.65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                        </svg>
                                                    </span>
                                                :
                                                <span className="hand details-heart-fixed" onClick={() => token == null || token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : CallAddtoWishlistApi()}>
                                                    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.5 1C3.4629 1 1 3.36095 1 6.27377C1 8.62513 1.9625 14.2057 11.4368 19.8471C11.6065 19.9471 11.8013 20 12 20C12.1987 20 12.3935 19.9471 12.5632 19.8471C22.0375 14.2057 23 8.62513 23 6.27377C23 3.36095 20.5371 1 17.5 1C14.4629 1 12 4.19623 12 4.19623C12 4.19623 9.5371 1 6.5 1Z" stroke="#AB7C94" strokeOpacity="0.65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                    </svg>
                                                </span>
                                            }
                                        </h4>
                                        <h6 className="lists-h5 mb-3">
                                            <span className="t-color">SKU :</span> {DiamondDetail?.loatno}
                                        </h6>


                                        {JewelryId != null ?
                                            <div className="mt-4 mb-3 selected-boxdiv" ><h3><b>Your Ring</b></h3>
                                                <div className="row mt-3 mb-3" style={{ alignItems: "center" }}>
                                                    <div className="col-xl-2 col-lg-2 col-sm-1 col-2" style={{ paddingRight: "5px" }}>
                                                        {/* <img className="img-responsive product-image" src="https://diora.blob.core.windows.net/diora/M00245/M00245_0002.png" width="100%" alt=" 14K Rose Gold Comfort Fit Engagement Ring" /> */}
                                                        {/* <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} className='img-responsive product-image' style={{ background: 'rgba(76, 175, 80, 0.1)' }} src={jewelryDetail?.default?.path == null ? process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg" : jewelryDetail?.default?.path} alt="setting" width="100%" /> */}
                                                        <img onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }} className='img-responsive product-image' style={{ background: 'rgba(76, 175, 80, 0.1)' }} src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/steps/setting.svg"} alt="setting" width="100%" />

                                                    </div>
                                                    <div className="col-xl-10 col-lg-10 col-sm-11 col-10">
                                                        <h6 className="lsp1 Hypatia-bold mb-2">{jewelryDetail?.title}</h6>
                                                        {
                                                    (jewelryDetail?.category?.name.toLowerCase() == "ring" 
                                                    || jewelryDetail?.category?.name.toLowerCase() == "rings"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "engagement rings"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "engagement ring"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "wedding ring"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "wedding rings"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "chains"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "chain"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "bracelet"
                                                    || jewelryDetail?.category?.name.toLowerCase() == "bracelets"
                                                    )
                                                    ?
                                                    <>
                                                    <h6><b>{
                                                       (jewelryDetail?.category?.name.toLowerCase() == "ring" 
                                                       || jewelryDetail?.category?.name.toLowerCase() == "rings"
                                                       || jewelryDetail?.category?.name.toLowerCase() == "engagement rings"
                                                       || jewelryDetail?.category?.name.toLowerCase() == "engagement ring"
                                                       || jewelryDetail?.category?.name.toLowerCase() == "wedding ring"
                                                       || jewelryDetail?.category?.name.toLowerCase() == "wedding rings") ? "Ring Size" : null
                                                       (jewelryDetail?.category?.name.toLowerCase() == "bracelets" || jewelryDetail?.category?.name.toLowerCase() == "bracelet") ? "Bracelet Length" : null
                                                       (jewelryDetail?.category?.name.toLowerCase() == "chains" || jewelryDetail?.category?.name.toLowerCase() == "chain") ? "Chain Length" : null
                                                    }  : </b>{ringName}</h6> 
                                                    </>
                                                    : null
                                                    }
                                                       
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="column" >
                                                        <h6>Your Diamond </h6>
                                                        <h6>Your Ring </h6>
                                                        <h6>Total </h6>

                                                    </div>
                                                    <div className="column" >
                                                        <h6>: {defaultCurrency?.sign ?? "$"}{DiamondDetail?.diamondprice}</h6>
                                                        {/* <h6>: {defaultCurrency?.sign ?? "$"}{jewelryDetail?.settingprice}</h6> */}
                                                        {/* <h6>: {defaultCurrency?.sign ?? "$"}{finalAmount}</h6> */}
                                                        <h6>: {defaultCurrency?.sign ?? "$"}{JewelFinalAmt}</h6>
                                                        <h6>: {defaultCurrency?.sign ?? "$"}{sum.toFixed(2)}</h6>

                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <h3 className="lists-h2-price">
                                                {defaultCurrency?.sign ?? "$"}{DiamondDetail?.diamondprice}
                                            </h3>

                                        }


                                        {
                                        DiamondDetail.status == "Active" && (DiamondDetail.isdeleted == 0 || DiamondDetail.isdeleted == "0") ?
                                            <div className="mt-4" style={{display:"flex",gap:"1rem"}}>

                                                {buildYourOwn == true ?
                                                    JewelryId != null && DiamondId != null ?
                                                        <>
                                                            {DiamondDetail.diamond_type == 'Lab' ?
                                                                <a href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${localStorage.getItem("EngagmentName")}?cid=${localStorage.getItem("EngagmentID")}`} className="start-with-diamonds">
                                                                    Change Your Selection
                                                                </a>
                                                                :
                                                                <a href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own/${localStorage.getItem("EngagmentName")}?cid=${localStorage.getItem("EngagmentID")}`} className="start-with-diamonds">
                                                                    Change Your Selection
                                                                </a>
                                                            }


                                                            {DiamondDetail?.status_name?.paraname == 'Available' &&

                                                                <div style={{ display: 'flex', }}>
                                                                    {/* <div onClick={() => token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : CallAddtoWishlistApi()}>
                                                                        <a style={{ marginRight: 10 }} className="start-with-diamonds">
                                                                            Add to Wishlist
                                                                        </a>
                                                                    </div> */}

                                                                    <div onClick={() => token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : CallAddtoCart()}>
                                                                        {/* <Link
                                                                    className="start-with-diamonds"
                                                                    aria-current="page"
                                                                    style={{ textDecoration: "none", marginRight: 10 }}

                                                                    to={process.env.PUBLIC_URL +`/Cart`}

                                                                >
                                                                    Add to Cart
                                                                </Link> */}
                                                                        <a className="start-with-diamonds" style={{border:(buildYourOwn == true) ? '1px solid #a97c56' : 'none'}}>
                                                                            Add to Cart
                                                                        </a>
                                                                    </div>

                                                                </div>
                                                            }
                                                        </>

                                                        :

                                                        <div style={{ display: 'flex' }}>
                                                            {DiamondDetail?.status_name?.paraname == 'Available' ?
                                                            <>
                                                            <a
                                                                className="start-with-diamonds"
                                                                aria-current="page"
                                                                style={{ textDecoration: "none", marginRight: 10 }}

                                                                href={process.env.PUBLIC_URL + `/Jewelry/Build-Your-Own?shape=${DiamondDetail?.shape_name?.id}&cid=${localStorage.getItem("EngagmentID")}&did=${DiamondId}&carat=${DiamondDetail?.carat}`}
                                                            >
                                                                Choose Your Setting
                                                            </a>
                                                            
                                                                <div onClick={() => token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : CallAddtoCart()}>
                                                                    {/* <Link
                                                                    className="start-with-diamonds"
                                                                    aria-current="page"
                                                                    style={{ textDecoration: "none", marginRight: 10 }}

                                                                    to={process.env.PUBLIC_URL +`/Cart`}

                                                                >
                                                                    Add to Cart
                                                                </Link> */}
                                                                    <a className="start-with-diamonds" style={{border:(buildYourOwn == true) ? '1px solid #a97c56' : 'none'}}>
                                                                        Add to Cart
                                                                    </a>

                                                                </div>
                                                                </>
                                                                :

                                                                <div >
                                                                    <a className="sold-out" >
                                                                        SOLD OUT
                                                                    </a>
                                                                </div>
                                                            }
                                                        </div>
                                                    :
                                                    DiamondDetail?.status_name?.paraname == 'Available' ?

                                                        <div onClick={() => token == "" || token == undefined ? window.location.pathname = BASENAME + "Login" : CallAddtoCart()}>
                                                            {/* <Link
                                                                className="start-with-diamonds"
                                                                aria-current="page"
                                                                style={{ textDecoration: "none", marginRight: 10 }}

                                                                to={process.env.PUBLIC_URL +`/Cart`}

                                                            >
                                                                Add to Cart
                                                            </Link> */}
                                                            <a className="start-with-diamonds" style={{border:(buildYourOwn == true) ? '1px solid #a97c56' : 'none'}}>
                                                                Add to Cart
                                                            </a>

                                                        </div>
                                                        :

                                                        <div >
                                                            <a className="sold-out" >
                                                                SOLD OUT
                                                            </a>
                                                        </div>
                                                }
                                            </div>
                                        :
                                            <div style={{ display: 'flex',color:'red',padding:"2%",fontSize:"x-large" }}>
                                                Diamond Not Available
                                            </div>
                                        }

                                        {/* <div>
                                            <p className="ptag-text">
                                                Whether for an engagement, anniversary, or a new family addition, this stunning Diora Adams Diamond will be a part of your moment Diora Adams. Personalize it with one of our options or purchase the affordable loose diamond individually.
                                            </p>
                                        </div> */}
                                        <div>
                                            <h6>
                                            Diamond Information
                                            </h6>
                                            <div>
                                                {
                                                    console.log("DiamondDetail",DiamondDetail)
                                                }
                                                <dl className="order-summary-me-2 pad-10-dd-me">
                                                    <dd>
                                                        Shape
                                                        <strong>{DiamondDetail?.originalShapeName}</strong>
                                                    </dd>
                                                    <dd>
                                                        Color
                                                        <strong>{DiamondDetail?.color_name?.paraname}</strong>
                                                    </dd>
                                                    <dd>
                                                        Clarity
                                                        <strong>{DiamondDetail?.clarity_name?.paraname}</strong>
                                                    </dd>
                                                    <dd>
                                                        Carat
                                                        <strong>{parseFloat(DiamondDetail?.carat).toFixed(2)}</strong>
                                                    </dd>
                                                    <dd>
                                                        Certificate
                                                        <a href={DiamondDetail.cert_location} target="_blank" style={{ color: 'blue', float: "right" }}><>{DiamondDetail?.cert_no}</></a>
                                                    </dd>
                                                    <dd>
                                                        Cut
                                                        <strong>{DiamondDetail?.cut_name?.paraname == null ? '-' : DiamondDetail?.cut_name?.paraname}</strong>
                                                    </dd>
                                                    <dd>
                                                        Polish
                                                        <strong>{DiamondDetail?.polish_name?.paraname == null ? '-' : DiamondDetail?.polish_name?.paraname}</strong>
                                                    </dd>
                                                    <dd>
                                                        Symmetry
                                                        <strong>{DiamondDetail?.sym_name?.paraname == null ? '-' : DiamondDetail?.sym_name?.paraname}</strong>
                                                    </dd>
                                                    <dd>
                                                        Measurement
                                                        <strong>{DiamondDetail?.measurement == null ? '-' : DiamondDetail?.measurement}</strong>
                                                    </dd>
                                                    <dd>
                                                        Depth
                                                        <strong>{DiamondDetail?.tabledepth}</strong>
                                                    </dd>
                                                    <dd>
                                                        Table
                                                        <strong>{DiamondDetail?.tablearea}</strong>
                                                    </dd>
                                                    <dd>
                                                        Lab
                                                        <strong>{DiamondDetail?.lab_name?.paraname}</strong>
                                                    </dd>
                                                    <dd>
                                                        Rapnet Price({defaultCurrency?.sign ?? "$"})
                                                        <strong>{DiamondDetail?.raprate}</strong>
                                                    </dd>
                                                    <dd>
                                                        Price Per Carat({defaultCurrency?.sign ?? "$"})
                                                        <strong>{DiamondDetail?.priceperct}</strong>
                                                    </dd>
                                                </dl>
                                            </div>
                                        </div>
                                        
                                        {/* <p className="ptag-text">
                                            The videos and images used are for presentations purposes only, for more information on this exact stone, please contact us
                                        </p>
                                        <div className="mt-3">
                                            <h5>
                                                Shipping
                                            </h5>
                                            <p className="ptag-text">
                                                Diora Adams Diamonds ships across the United States. Most pieces will ship within two business days, custom creations can take around three weeks. This item is boxed for safe keeping or gift giving, we understand that jewelry is often given as a special surprise and we want to maintain your surprise. All orders are packed in earth friendly packaging and the label doesn't give it away!
                                            </p>
                                            <p className="ptag-text">
                                                Shipments are 100% fully insured and require a signature upon arrival.
                                            </p>
                                            <p className="ptag-text">
                                                If you have any questions, please call customer service at <Buttontelto label={process.env.REACT_APP_CONTACT} telto={"tel:"+process.env.REACT_APP_CONTACT} /> or chat with us.
                                            </p>
                                            <p className="ptag-text">
                                                We offer a range of settings, usually in the shape for a round diamond. However, each setting is made for the loose diamond shape you choose. Helpful hints for picking a diamond shape:
                                            </p>

                                            <ul className="ulli-list">
                                                <li className="ptag-text">
                                                    Round diamonds are by far the most popular choice due to their brilliance, fire, and offer maximum sparkle.
                                                </li>
                                                <li className="ptag-text">
                                                    Pear shaped cut diamonds are more distinctive shapes, and help fingers look long and slender due to their elongated appearance.
                                                </li>
                                                <li className="ptag-text">
                                                    Heart shaped diamonds reflect lots of light, the color of a heart shaped diamond is harder to distinguish but the not the universal symbol of romantic love.
                                                </li>
                                            </ul>
                                        </div> */}

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
                {(Recommended.length > 0) ?
                    <section className="jumbotron">
                        <div>
                            <div className="collection-div-bg" id="test" style={{ position: "relative" }}>
                                <div className="pad-left-210">

                                    <div className="mb-4">
                                        <h4 className="title-1">
                                            Recommended Items
                                        </h4>
                                    </div>

                                    {
                                        (Recommended.length > 0) ?
                                            <ReactOwlCarousel className='explore-slider explore-arrow explore-product'
                                                autoplayHoverPause='true' autoplay='true' margin={6} items={(isMobile) ? 2 : 7}>
                                                {
                                                    Recommended?.map((item, index) => {
                                                        return (
                                                            <div className='item' key={Math.random()} style={{}}>

                                                                <div className="text-center">
                                                                    <a target='_blank' href={buildYourOwn == true ? JewelryId == null ?
                                                                        process.env.PUBLIC_URL + `/DiamondDetails/Build-Your-Own/${utf8_to_b64(item?.id)}/${item?.title}?did=${item.id}`
                                                                        : process.env.PUBLIC_URL + `/DiamondDetails/Build-Your-Own/${utf8_to_b64(item?.id)}/${item?.title}?jid=${JewelryId}&did=${item.id}&ring=${ring}`
                                                                        :
                                                                        process.env.PUBLIC_URL + `/DiamondDetails/${utf8_to_b64(item?.id)}/${item?.title}?did=${item.id}`}

                                                                    >
                                                                        <img src={item?.image == null ? noimage : item?.image} onError={(e)=>{
                                                                        e.target.onerror=null
                                                                        e.target.src=noimage
                                                                    }}/>

                                                                        {/* <h5 className="product-title pt-3 line1-doted-3">
                                                                            {item?.title}
                                                                        </h5> */}
                                                                        <div className="">
                                                                            <div className='view-details-btn' style={{ paddingBottom: '10px' }}>
                                                                                <span className="span-link product-title pt-3 line1-doted-3" style={{ textDecoration: 'none' }}>
                                                                                    {item?.title}
                                                                                </span>
                                                                            </div>
                                                                        </div>


                                                                        <p className="product-title-price mb-0">
                                                                        {defaultCurrency?.sign ?? "$"}{item?.diamondprice}
                                                                        </p>
                                                                        <div className="pt-3">
                                                                            <div className='view-details-btn'>
                                                                                <span className="span-link">
                                                                                    View Details
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </ReactOwlCarousel>
                                            : ''
                                    }



                                </div>
                            </div>
                        </div>
                    </section>
                    : ''}
            </>
            }


        </>
    )
}
export default DiamondDetails