
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { loginUser } from '../reducers/userReducers'

import Form from 'react-bootstrap/Form';

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Colors } from '../constant/Constant'
import Cookies from 'universal-cookie';
import { AiOutlineBorder, AiOutlineCheckSquare } from "react-icons/ai";
import { BASE_URL, GetAPIUrl } from '../API/APIUrl'
import axios from 'axios'
import { ShowErrorMessage, ShowMessage } from '../module/Tostify'
import LoadingSpinner from '../module/LoadingSpinner'



const ForgetPassword = ({ authorized }) => {

    const [email, setEmail] = useState('')

    const [emailLoad, setEmailLoad] = useState(false)

    const authenticate = async (event) => {
        event.preventDefault()
        setEmailLoad(true)
        var form = new FormData();
        form.append("email", email);
        await axios.post(BASE_URL + GetAPIUrl.FORGETPASSWORD_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response?.data?.status == true) {
                    ShowMessage(response?.data?.message)
                } else {
                    ShowErrorMessage(response?.message)
                }
            }).catch((error) => {
                ShowErrorMessage(error?.response?.data?.message)
            }).finally(() => {
                setEmailLoad(false)
            })

    }

    return (
        <>

            <div>
                <div className="container container-main">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={'/'}><i className="fa fa-home" aria-hidden="true"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Forget Password</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {emailLoad == true ?
                <LoadingSpinner />
                :
                null}

            <div className="main-section">

            <div className="main-section">

                <section className="pb-5 mob-ptpb-1">

                    <div className="container">

                        <div style={{ maxWidth: "500px", margin: "auto", boxShadow: "0px 2px 10px 0px #0530691a" }}>

                        <Form className="input-form-div signup-div" style={{padding:"1rem 1rem"}}>
                        <div className="text-center">
                                            <h3 className="main-color">
                                                Forgot Password
                                            </h3>
                                        </div>
                        <div className="">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className="lblmy lblmy-active">Email<span className='mark_red'>*</span></label>
                                                <input
                                                autoFocus
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    type="text"
                                                    className="form-control my-textbox"
                                                    placeholder="Enter Email Id" />
                                            </div>
                                        </div>



                                        <div className="col-xl-12 text-center">
                                            <button
                                                onClick={authenticate}
                                                className="login-me btn-shadow-me min-w-200">Submit</button>
                                                <br />
                                                <br />
                                                    <Link
                                                        className="login-me btn-shadow-me min-w-200"
                                                        aria-current="page"
                                                        to={'/Login'}
                                                    >
                                                        {"Log in"}
                                                    </Link>
                                        </div>

                                    </div>
                                </div>                        
                        </Form>
                            
                        </div>
                    </div>
                    
                </section>

            </div>

            </div>


            {/* <section className="jumbotron">
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100">
                            <form className="login100-form validate-form">
                                <span className="login100-form-title p-b-34">Forget Password</span>
                                <div className="">
                                    <div className="row">
                                        <div className="col-xl-12">
                                            <div className="form-group">
                                                <label className="lblmy lblmy-active">Email<span className='mark_red'>*</span></label>
                                                <input
                                                autoFocus
                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                    type="text"
                                                    className="form-control my-textbox"
                                                    placeholder="Enter Email Id" />
                                                <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                </p>
                                            </div>
                                        </div>



                                        <div className="col-xl-12 text-center">
                                            <br />

                                            <button
                                                onClick={authenticate}
                                                className="login-me btn-shadow-me min-w-200">Submit</button>
                                                <br />
                                                <br />
                                                    <Link
                                                        className="login-me btn-shadow-me min-w-200"
                                                        aria-current="page"
                                                        to={'/Login'}
                                                    >
                                                        {"Log in"}
                                                    </Link>
                                        </div>

                                    </div>
                                </div>
                            </form>
                            <div className="login100-more d-none d-xl-block d-lg-block" style={{ backgroundImage: `url('./assets/images/login/login-banner.jpg')` }}></div>
                        </div>
                    </div>
                </div>
            </section> */}

            <ToastContainer />

        </>

    )
}

export default ForgetPassword