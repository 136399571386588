import React from 'react'
import "../Diamond Guide/DiamondGuide.css";

const FAQ = () => {
    const dataAbout=[
        {
            "heading":"Q1: What types of jewelry do you offer?",
            "desc":"A1: We offer a wide range of jewelry, including diamond rings, necklaces, earrings, bracelets, and engagement rings. We also have a selection of fine gemstone jewelry and wedding bands."
        },
        {
            "heading":"Q2: Are your diamonds conflict-free?",
            "desc":"A2: Absolutely. We are committed to sourcing and selling only conflict-free diamonds. Our diamonds are obtained from trusted suppliers who adhere to the Kimberley Process, an international certification system that ensures diamonds are ethically and responsibly sourced."
        },
        {
            "heading":"Q3: What are the quality standards of your diamonds?",
            "desc":"A3: We pride ourselves on offering high-quality diamonds that meet rigorous standards. Our diamonds are graded by reputable gemological laboratories, such as the Gemological Institute of America (GIA), based on the 4Cs - carat weight, cut, color, and clarity. We provide detailed information about each diamond's specifications to help you make an informed decision."
        },
        {
            "heading":"Q4: Can I customize the jewelry I purchase?",
            "desc":"A4: Yes, we offer customization options for many of our jewelry pieces. Whether you want to personalize a diamond ring with an engraving or create a custom design from scratch, our expert jewelers can assist you in bringing your vision to life."
        },
        {
            "heading":"Q5: Do you provide certificates of authenticity?",
            "desc":"A5: Yes, all our diamonds come with certificates of authenticity from renowned gemological laboratories. These certificates provide essential details about the diamond's characteristics, ensuring transparency and trust in your purchase."
        },
        {
            "heading":"Q6: What metals are used in your jewelry?",
            "desc":"A6: We offer jewelry crafted from various precious metals, including gold (in different karats like 14K, 18K, and 24K), platinum, and sterling silver. Each metal has its unique properties, and our knowledgeable staff can guide you in selecting the right metal for your jewelry."
        },
        {
            "heading":"Q7: Do you offer jewelry repairs and maintenance services?",
            "desc":"A7: Yes, we provide jewelry repair and maintenance services. Our skilled jewelers can assist you with resizing rings, fixing prongs, restringing necklaces, and more. Additionally, we offer professional cleaning and inspection to keep your jewelry in pristine condition."
        },
        {
            "heading":"Q8: What is your return policy?",
            "desc":"A8: We strive for customer satisfaction, and if for any reason you are not completely satisfied with your purchase, we offer a flexible return policy. Please refer to our detailed return policy on our website or contact our customer service for specific instructions and eligibility criteria."
        },
        {
            "heading":"Q9: Do you offer financing options?",
            "desc":"A9: Yes, we provide financing options to make your purchase more convenient. Our financing programs may vary, so please inquire with our staff or check our website for more information on the available options."
        },
        {
            "heading":"Q10: How can I contact your customer service?",
            "desc":"A10: You can reach our customer service team by phone, email, or through the contact form on our website. Our representatives are available to assist you with any inquiries, concerns, or requests you may have."
        }
    ]
    const dataAboutDioraAdams=[
        {
            "heading":"Q1: When was Diora Adams established?",
            "desc":"A1: Diora Adams was established in 2002, and we have been creating exquisite diamond-studded jewelry for over two decades."
        },
        {
            "heading":"Q2: What is the experience level of your founder?",
            "desc":"A2: Our founder has over 20 years of experience in the jewelry industry, bringing a wealth of knowledge and expertise to the craftsmanship of our jewelry pieces."
        },
        {
            "heading":"Q3: What is the mission of Diora Adams?",
            "desc":"A3: Our mission is to create timeless and unique jewelry pieces that captivate and enchant jewelry lovers worldwide. We strive to deliver exceptional quality and craftsmanship that reflects our dedication to excellence."
        },
        {
            "heading":"Q4: What materials are used in your jewelry?",
            "desc":"A4: We specialize in handmade jewelry using natural diamonds, gemstones, and high-quality gold and silver. Our materials are carefully selected to ensure durability, beauty, and elegance in every piece we create."
        },
        {
            "heading":"Q5: Do you offer hip-hop jewelry?",
            "desc":"A5: Yes, we specialize in hip-hop jewelry design. Our hip-hop jewelry pieces feature bold and distinctive designs that reflect the vibrant culture of the hip-hop community. We combine artistic flair with high-quality materials to create unique and eye-catching pieces."
        },
        {
            "heading":"Q6: Do you export your diamond jewelry internationally?",
            "desc":"A6: Yes, we have a successful track record in exporting diamond jewelry to international markets. Our commitment to outstanding craftsmanship and exceptional quality extends to customers around the world."
        },
        {
            "heading":"Q7: Can I request custom-made jewelry?",
            "desc":"A7: Absolutely! We offer customization services where we can work with you to bring your vision to life. Whether you want to modify an existing design or create a completely new piece, our skilled artisans can create a custom-made jewelry piece that is tailored to your preferences."
        },
        {
            "heading":"Q8: How can I purchase Diora Adams jewelry?",
            "desc":"A8: You can purchase our jewelry directly from our website or visit our authorized retailers. We strive to provide a seamless and secure shopping experience for our customers, both online and offline."
        },
        {
            "heading":"Q9: What is your commitment to quality?",
            "desc":"A9: At Diora Adams, we have a strong commitment to delivering exceptional quality in every piece of jewelry we create. We meticulously select the finest materials and employ skilled artisans who pay attention to even the smallest details, ensuring that our jewelry meets the highest standards of craftsmanship."
        }
    ]
    const dataOrderPayment=[
        {
            "heading":"Q1: How can I place an order?",
            "desc":"A1: To place an order, simply browse our selection of products on our website or visit our store. Once you have selected the item(s) you wish to purchase, follow the prompts to add them to your cart and proceed to the checkout process. Alternatively, you can contact our customer service team for assistance in placing your order."
        },
        {
            "heading":"Q2: What payment methods do you accept?",
            "desc":"A2: We accept various payment methods, including credit cards (Visa, Mastercard, American Express), debit cards, PayPal, and bank transfers. The available payment options will be displayed during the checkout process."
        },
        {
            "heading":"Q3: Is it safe to make a payment on your website?",
            "desc":"A3: Yes, we prioritize the security of our customers' information. Our website utilizes secure encryption technology to protect your payment details. Additionally, we adhere to strict privacy policies to safeguard your personal information."
        },
        {
            "heading":"Q4: Can I cancel or modify my order after it has been placed?",
            "desc":"A4: We strive to process orders promptly; however, if you need to cancel or modify your order, please contact our customer service team as soon as possible. We will do our best to accommodate your request, but please note that once an order has been shipped, we may not be able to make changes."
        },
        {
            "heading":"Q5: How will I know if my order has been successfully placed?",
            "desc":"A5: After successfully placing your order, you will receive an order confirmation email. This email will include details such as your order number, item(s) purchased, billing and shipping information, and an estimated delivery date."
        },
        {
            "heading":"Q6: Do you offer international shipping?",
            "desc":"A6: Yes, we offer international shipping to many countries. During the checkout process, you can select your country for shipping, and the applicable shipping charges and estimated delivery times will be displayed."
        },
        {
            "heading":"Q7: What is your return policy?",
            "desc":"A7: We have a comprehensive return policy outlined on our website. Generally, we offer a certain timeframe for returns or exchanges, provided the item is in its original condition and accompanied by the necessary documentation. Please review our return policy or contact our customer service team for specific details and instructions."
        },
        {
            "heading":"Q8: Will I be charged sales tax?",
            "desc":"A8: Sales tax may be applicable depending on your shipping address and the local tax regulations. The sales tax amount, if applicable, will be calculated and added to your total during the checkout process."
        },
        {
            "heading":"Q9: Can I track the status of my order?",
            "desc":"A9: Yes, once your order has been shipped, you will receive a shipping confirmation email with a tracking number. You can use this tracking number to monitor the progress of your shipment online through the designated courier's website."
        },
        {
            "heading":"Q10: What should I do if I encounter any issues with my order or payment?",
            "desc":"A10: If you encounter any issues or have questions regarding your order or payment, please contact our customer service team immediately. We are here to assist you and resolve any concerns you may have."
        }
    ]
    const dataShipDelivery=[
        {
            "heading":"Q1: Where do you offer shipping?",
            "desc":"A1: We offer shipping to various locations worldwide. During the checkout process on our website, you can select your country for shipping. The applicable shipping charges and estimated delivery times will be displayed accordingly."
        },
        {
            "heading":"Q2: What are the shipping options available?",
            "desc":"A2: We offer several shipping options, including standard shipping, express shipping, and in-store pickup (if applicable). The available options will be presented during the checkout process, and you can choose the one that best suits your needs."
        },
        {
            "heading":"Q3: How long does it take to process and ship an order?",
            "desc":"A3: We strive to process and ship orders as quickly as possible. The processing time may vary depending on factors such as product availability, customization requirements, and order volume. Once your order has been processed, it will be shipped according to the selected shipping option and the estimated delivery timeframe associated with it."
        },
        {
            "heading":"Q4: How can I track the status of my shipment?",
            "desc":"A4: Once your order has been shipped, you will receive a shipping confirmation email containing a tracking number. You can use this tracking number to monitor the progress of your shipment. Simply visit the designated courier's website and enter the tracking number to obtain real-time updates on the location and status of your package."
        },
        {
            "heading":"Q5: Do you offer free shipping?",
            "desc":"A5: We may offer free shipping promotions from time to time. Please check our website or subscribe to our newsletter for updates on any ongoing shipping offers or promotions."
        },
        {
            "heading":"Q6: Can I change the shipping address after placing an order?",
            "desc":"A6: If you need to change the shipping address after placing an order, please contact our customer service team as soon as possible. We will do our best to accommodate your request. However, please note that once an order has been shipped, it may not be possible to change the shipping address."
        },
        {
            "heading":"Q7: Do you ship to P.O. boxes or APO/FPO addresses?",
            "desc":"A7: We can ship to some P.O. boxes and APO/FPO addresses, depending on the shipping carrier's policies. Please ensure that you provide the accurate and complete address details during checkout to avoid any delivery issues."
        },
        {
            "heading":"Q8: Will I be responsible for customs duties or import taxes?",
            "desc":"A8: Customs duties and import taxes may apply to international shipments, depending on the destination country's regulations. These additional charges are the responsibility of the recipient. Please familiarize yourself with the customs policies of your country and be prepared to settle any applicable fees upon delivery."
        },
        {
            "heading":"Q9: What should I do if my package is damaged or lost during transit?",
            "desc":"A9: In the unfortunate event that your package is damaged or lost during transit, please contact our customer service team immediately. We will work with you to resolve the issue and initiate any necessary claims or investigations with the shipping carrier."
        },
        {
            "heading":"Q10: Can I request expedited shipping for urgent orders?",
            "desc":"A10: Yes, if you require expedited shipping for urgent orders, we offer express shipping options. During the checkout process, you can choose the express shipping option that best suits your needs. Please note that additional charges may apply for expedited shipping services."
        }
    ]


  return (
    <>
    <section className="why-section" style={{padding:"2rem 0rem"}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
                <h1 className='sub_title-guide text-center'>Frequently Asked Questions</h1>
              <div>

                <div className="mb-4">
                  <h2 className="sub_title-subguide">About Our Diamonds & Jewelry</h2>
                </div>
                <div className="panel-group" id="accordionGroupClosed" role="tablist" aria-multiselectable="true">
                    {dataAbout?.map((item, index) => {
                        return (
                            <div className="panel panel-default" key={Math.random()}>
                                <div className="panel-heading" role="tab" id={"habout"+(index+1)}>
                                    <h4 className="panel-title">
                                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupClosed" href={"#tababout"+(index+1)} aria-expanded={(index == 0) ? "true" : "false"} aria-controls={"tababout"+(index+1)}>
                                        {item.heading}
                                        </a>
                                    </h4>
                                </div>
                                <div id={"tababout"+(index+1)} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"habout"+(index+1)}>
                                    <div className="panel-body">
                                    {item.desc}
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    
                </div>


                <div className="mb-4 mt-4">
                  <h2 className="sub_title-subguide">FAQ - ABOUT DIORA ADAMS</h2>
                </div>
                <div className="panel-group" id="accordionGroupClosed" role="tablist" aria-multiselectable="true">
                    {dataAboutDioraAdams?.map((item, index) => {
                        return (
                            <div className="panel panel-default" key={Math.random()}>
                                <div className="panel-heading" role="tab" id={"haboutdiora"+(index+1)}>
                                    <h4 className="panel-title">
                                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupClosed" href={"#tababoutdiora"+(index+1)} aria-expanded={(index == 0) ? "true" : "false"} aria-controls={"tababoutdiora"+(index+1)}>
                                        {item.heading}
                                        </a>
                                    </h4>
                                </div>
                                <div id={"tababoutdiora"+(index+1)} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"haboutdiora"+(index+1)}>
                                    <div className="panel-body">
                                    {item.desc}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>



                <div className="mt-4 mb-4">
                  <h2 className="sub_title-subguide">FAQ - ORDERS & PAYMENTS</h2>
                </div>
                <div className="panel-group" id="accordionGroupClosed" role="tablist" aria-multiselectable="true">
                    {dataOrderPayment?.map((item, index) => {
                        return (
                            <div className="panel panel-default" key={Math.random()}>
                                <div className="panel-heading" role="tab" id={"horder"+(index+1)}>
                                    <h4 className="panel-title">
                                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupClosed" href={"#taborder"+(index+1)} aria-expanded={(index == 0) ? "true" : "false"} aria-controls={"taborder"+(index+1)}>
                                        {item.heading}
                                        </a>
                                    </h4>
                                </div>
                                <div id={"taborder"+(index+1)} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"horder"+(index+1)}>
                                    <div className="panel-body">
                                    {item.desc}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>



                <div className="mt-4 mb-4">
                  <h2 className="sub_title-subguide">FAQ - SHIPPING & DELIVERY</h2>
                </div>
                <div className="panel-group" id="accordionGroupClosed" role="tablist" aria-multiselectable="true">
                    {dataShipDelivery?.map((item, index) => {
                        return (
                            <div className="panel panel-default" key={Math.random()}>
                                <div className="panel-heading" role="tab" id={"hshipping"+(index+1)}>
                                    <h4 className="panel-title">
                                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupClosed" href={"#tabshipping"+(index+1)} aria-expanded={(index == 0) ? "true" : "false"} aria-controls={"tabshipping"+(index+1)}>
                                        {item.heading}
                                        </a>
                                    </h4>
                                </div>
                                <div id={"tabshipping"+(index+1)} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"hshipping"+(index+1)}>
                                    <div className="panel-body">
                                    {item.desc}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="mb-4 mt-4">
                    <p >
                        Please note that the information provided here is general, and specific details may vary. We recommend contacting our customer service for the most accurate and up-to-date information regarding our diamonds and jewelry.
                    </p>
                </div>


              </div>
            </div>
          </div>
        </div>
      </section>




    {/* <div className='container aboutus product-listwrap pt-0'>
    <div className="faq">
      <div className="container container-2">
        <div className="faq-innr">
          <h1 className='mt-5 text-center'>Frequently Asked Questions</h1>

            <h1>About Our Lab Grown Diamonds & Jewelry</h1>

            <h3>Collapser D</h3>

            <div className="panel-group" id="accordionGroupClosed" role="tablist" aria-multiselectable="true">
                <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupClosed" href="#collapseCloseOne" aria-expanded="true" aria-controls="collapseCloseOne">
                        Collapsible Group Item #1 Closed on Load
                        </a>
                    </h4>
                    </div>
                    <div id="collapseCloseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                    <div className="panel-body">
                        <p>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                        on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                        raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </p>
                    </div>
                    </div>
                </div>
                <div className="panel panel-default">
                    <div className="panel-heading" role="tab" id="headingTwo">
                    <h4 className="panel-title">
                        <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordionGroupClosed" href="#collapseCloseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Collapsible Group Item #2 Closed on Load
                        </a>
                    </h4>
                    </div>
                    <div id="collapseCloseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                    <div className="panel-body">
                    <p>
                        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird
                        on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table,
                        raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </p>
                    </div>
                    </div>
                </div>
            </div>
    
      <div className="accordion" id="faqaccordion">
      <h6>About Our Lab Grown Diamonds & Jewelry</h6>
        <div className="accordion-item">
            <div className="accordion-header" id="1">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne"> Q1: What types of jewelry do you offer?  </button>
            </div>
            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="1" data-bs-parent="#faqaccordion">
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A1: We offer a wide range of jewelry, including diamond rings, necklaces, earrings, bracelets, and engagement rings. We also have a selection of fine gemstone jewelry and wedding bands.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="2">
                <button className="accordion-button s3" type="button"  data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false"
                    aria-controls="collapseTwo">
                    Q2: Are your diamonds conflict-free?
                </button>
            </div>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="2">
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A2: Absolutely. We are committed to sourcing and selling only conflict-free diamonds. Our diamonds are obtained from trusted suppliers who adhere to the Kimberley Process, an international certification system that ensures diamonds are ethically and responsibly sourced.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="3">
                <button  className="accordion-button collapsed s3"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                    aria-expanded="false" aria-controls="collapseThree"  >
                    Q3: What are the quality standards of your diamonds?
                </button>
            </div>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="3"
                data-bs-parent="#faqaccordion" >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A3: We pride ourselves on offering high-quality diamonds that meet rigorous standards. Our diamonds are graded by reputable gemological laboratories, such as the Gemological Institute of America (GIA), based on the 4Cs - carat weight, cut, color, and clarity. We provide detailed information about each diamond's specifications to help you make an informed decision.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="4">
                <button  className="accordion-button collapsed s3"  type="button"  data-bs-toggle="collapse" data-bs-target="#collapsefour"
                    aria-expanded="false"  aria-controls="collapsefour">
                    Q4: Can I customize the jewelry I purchase?
                </button>
            </div>
            <div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="4" data-bs-parent="#faqaccordion">
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A4: Yes, we offer customization options for many of our jewelry pieces. Whether you want to personalize a diamond ring with an engraving or create a custom design from scratch, our expert jewelers can assist you in bringing your vision to life.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="5">
                <button  className="accordion-button collapsed s3" type="button"  data-bs-toggle="collapse"data-bs-target="#collapsefive"
                    aria-expanded="false" aria-controls="collapsefive" >
                    Q5: Do you provide certificates of authenticity?
                </button>
            </div>
            <div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="5" data-bs-parent="#faqaccordion">
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A5: Yes, all our diamonds come with certificates of authenticity from renowned gemological laboratories. These certificates provide essential details about the diamond's characteristics, ensuring transparency and trust in your purchase.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="6">
                <button className="accordion-button collapsed s3" type="button"  data-bs-toggle="collapse" data-bs-target="#collapsesix"
                    aria-expanded="false"aria-controls="collapsesix">
                    Q6: What metals are used in your jewelry?
                </button>
            </div>
            <div id="collapsesix"className="accordion-collapse collapse" aria-labelledby="6" data-bs-parent="#faqaccordion" >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A6: We offer jewelry crafted from various precious metals, including gold (in different karats like 14K, 18K, and 24K), platinum, and sterling silver. Each metal has its unique properties, and our knowledgeable staff can guide you in selecting the right metal for your jewelry.                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="7">
                <button className="accordion-button collapsed s3"  type="button"data-bs-toggle="collapse" data-bs-target="#collapseseven"
                    aria-expanded="false" aria-controls="collapseseven" >
                    Q7: Do you offer jewelry repairs and maintenance services?
                </button>
            </div>
            <div id="collapseseven" className="accordion-collapse collapse" aria-labelledby="8" data-bs-parent="#faqaccordion">
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A7: Yes, we provide jewelry repair and maintenance services. Our skilled jewelers can assist you with resizing rings, fixing prongs, restringing necklaces, and more. Additionally, we offer professional cleaning and inspection to keep your jewelry in pristine condition.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="8">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseight"
                    aria-expanded="false" aria-controls="collapseight">
                    Q8: What is your return policy?
                </button>
            </div>
            <div id="collapseight"  className="accordion-collapse collapse" aria-labelledby="8"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A8: We strive for customer satisfaction, and if for any reason you are not completely satisfied with your purchase, we offer a flexible return policy. Please refer to our detailed return policy on our website or contact our customer service for specific instructions and eligibility criteria.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="9">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine"
                    aria-expanded="false" aria-controls="collapsenine">
                    Q9: Do you offer financing options?
                </button>
            </div>
            <div id="collapsenine"  className="accordion-collapse collapse" aria-labelledby="9"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A9: Yes, we provide financing options to make your purchase more convenient. Our financing programs may vary, so please inquire with our staff or check our website for more information on the available options.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="10">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseten"
                    aria-expanded="false" aria-controls="collapseten">
                    Q10: How can I contact your customer service?
                </button>
            </div>
            <div id="collapseten"  className="accordion-collapse collapse" aria-labelledby="10"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A10: You can reach our customer service team by phone, email, or through the contact form on our website. Our representatives are available to assist you with any inquiries, concerns, or requests you may have.
                    </p>
                    <p className="b2 faqtxt">
                    Please note that the information provided here is general, and specific details may vary. We recommend contacting our customer service or visiting our store for the most accurate and up-to-date information regarding our diamonds and jewelry.
                    </p>
                </div>
            </div>
        </div>
        <h6 className='mb-4 mt-4'>FAQ - ABOUT DIORA ADAMS</h6>
        <div className="accordion-item">
            <div className="accordion-header" id="11">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15"
                    aria-expanded="true" aria-controls="collapse15"> Q1: When was Diora Adams established?   </button>
            </div>
            <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="11" data-bs-parent="#faqaccordion">
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A1: Diora Adams was established in 2002, and we have been creating exquisite diamond-studded jewelry for over two decades.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="12">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16"
                    aria-expanded="false" aria-controls="collapse16">
                    Q2: What is the experience level of your founder?
                </button>
            </div>
            <div id="collapse16"  className="accordion-collapse collapse" aria-labelledby="12"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A2: Our founder has over 20 years of experience in the jewelry industry, bringing a wealth of knowledge and expertise to the craftsmanship of our jewelry pieces.                    </p>
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <div className="accordion-header" id="13">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16"
                    aria-expanded="false" aria-controls="collapse16">
                      Q3: What is the mission of Diora Adams?
                </button>
            </div>
            <div id="collapse16"  className="accordion-collapse collapse" aria-labelledby="13"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A3: Our mission is to create timeless and unique jewelry pieces that captivate and enchant jewelry lovers worldwide. We strive to deliver exceptional quality and craftsmanship that reflects our dedication to excellence.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="14">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16"
                    aria-expanded="false" aria-controls="collapse16">
                    Q4: What materials are used in your jewelry?
                </button>
            </div>
            <div id="collapse16"  className="accordion-collapse collapse" aria-labelledby="14"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A4: We specialize in handmade jewelry using natural diamonds, gemstones, and high-quality gold and silver. Our materials are carefully selected to ensure durability, beauty, and elegance in every piece we create.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="15">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16"
                    aria-expanded="false" aria-controls="collapse16">
                    Q5: Do you offer hip-hop jewelry?
                </button>
            </div>
            <div id="collapse16"  className="accordion-collapse collapse" aria-labelledby="15"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A5: Yes, we specialize in hip-hop jewelry design. Our hip-hop jewelry pieces feature bold and distinctive designs that reflect the vibrant culture of the hip-hop community. We combine artistic flair with high-quality materials to create unique and eye-catching pieces.
                      </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="16">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16"
                    aria-expanded="false" aria-controls="collapse16">
                    Q6: Do you export your diamond jewelry internationally?
                </button>
            </div>
            <div id="collapse16"  className="accordion-collapse collapse" aria-labelledby="16"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A6: Yes, we have a successful track record in exporting diamond jewelry to international markets. Our commitment to outstanding craftsmanship and exceptional quality extends to customers around the world.
                      </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="17">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16"
                    aria-expanded="false" aria-controls="collapse16">
                    Q7: Can I request custom-made jewelry?
                </button>
            </div>
            <div id="collapse16"  className="accordion-collapse collapse" aria-labelledby="17"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A7: Absolutely! We offer customization services where we can work with you to bring your vision to life. Whether you want to modify an existing design or create a completely new piece, our skilled artisans can create a custom-made jewelry piece that is tailored to your preferences.
                      </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="18">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16"
                    aria-expanded="false" aria-controls="collapse16">
                    Q8: How can I purchase Diora Adams jewelry?
                </button>
            </div>
            <div id="collapse16"  className="accordion-collapse collapse" aria-labelledby="18"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A8: You can purchase our jewelry directly from our website or visit our authorized retailers. We strive to provide a seamless and secure shopping experience for our customers, both online and offline.
                      </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="19">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse16"
                    aria-expanded="false" aria-controls="collapse16">
                    Q9: What is your commitment to quality?
                </button>
            </div>
            <div id="collapse16"  className="accordion-collapse collapse" aria-labelledby="19"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A9: At Diora Adams, we have a strong commitment to delivering exceptional quality in every piece of jewelry we create. We meticulously select the finest materials and employ skilled artisans who pay attention to even the smallest details, ensuring that our jewelry meets the highest standards of craftsmanship.
                    </p>
                </div>
            </div>
        </div>


        <h6 className='mb-4 mt-4'>FAQ - ORDERS & PAYMENTS</h6>


        <div className="accordion-item">
            <div className="accordion-header" id="20">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse17"
                    aria-expanded="true" aria-controls="collapse17">  Q1: How can I place an order? </button>
            </div>
            <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="20" data-bs-parent="#faqaccordion">
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A1: To place an order, simply browse our selection of products on our website or visit our store. Once you have selected the item(s) you wish to purchase, follow the prompts to add them to your cart and proceed to the checkout process. Alternatively, you can contact our customer service team for assistance in placing your order.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="21">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse18"
                    aria-expanded="false" aria-controls="collapse18">
                    Q2: What payment methods do you accept?
                </button>
            </div>
            <div id="collapse18"  className="accordion-collapse collapse" aria-labelledby="21"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A2: We accept various payment methods, including credit cards (Visa, Mastercard, American Express), debit cards, PayPal, and bank transfers. The available payment options will be displayed during the checkout process.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="22">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse19"
                    aria-expanded="false" aria-controls="collapse19">
                    Q3: Is it safe to make a payment on your website?
                </button>
            </div>
            <div id="collapse19"  className="accordion-collapse collapse" aria-labelledby="22"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A3: Yes, we prioritize the security of our customers' information. Our website utilizes secure encryption technology to protect your payment details. Additionally, we adhere to strict privacy policies to safeguard your personal information.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="23">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse20"
                    aria-expanded="false" aria-controls="collapse20">
                   Q4: Can I cancel or modify my order after it has been placed?
                </button>
            </div>
            <div id="collapse20"  className="accordion-collapse collapse" aria-labelledby="23"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A4: We strive to process orders promptly; however, if you need to cancel or modify your order, please contact our customer service team as soon as possible. We will do our best to accommodate your request, but please note that once an order has been shipped, we may not be able to make changes.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="24">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse21"
                    aria-expanded="false" aria-controls="collapse21">
                    Q5: How will I know if my order has been successfully placed?
                </button>
            </div>
            <div id="collapse21"  className="accordion-collapse collapse" aria-labelledby="24"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A5: After successfully placing your order, you will receive an order confirmation email. This email will include details such as your order number, item(s) purchased, billing and shipping information, and an estimated delivery date.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="25">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse22"
                    aria-expanded="false" aria-controls="collapse22">
                    Q6: Do you offer international shipping?
                </button>
            </div>
            <div id="collapse22"  className="accordion-collapse collapse" aria-labelledby="25"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A6: Yes, we offer international shipping to many countries. During the checkout process, you can select your country for shipping, and the applicable shipping charges and estimated delivery times will be displayed.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="26">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse23"
                    aria-expanded="false" aria-controls="collapse23">
                    Q7: What is your return policy?
                </button>
            </div>
            <div id="collapse23"  className="accordion-collapse collapse" aria-labelledby="26"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A7: We have a comprehensive return policy outlined on our website. Generally, we offer a certain timeframe for returns or exchanges, provided the item is in its original condition and accompanied by the necessary documentation. Please review our return policy or contact our customer service team for specific details and instructions.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="27">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse24"
                    aria-expanded="false" aria-controls="collapse24">
                    Q8: Will I be charged sales tax?
                </button>
            </div>
            <div id="collapse24"  className="accordion-collapse collapse" aria-labelledby="27"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A8: Sales tax may be applicable depending on your shipping address and the local tax regulations. The sales tax amount, if applicable, will be calculated and added to your total during the checkout process.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="28">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse25"
                    aria-expanded="false" aria-controls="collapse25">
                    Q9: Can I track the status of my order?
                </button>
            </div>
            <div id="collapse25"  className="accordion-collapse collapse" aria-labelledby="28"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A9: Yes, once your order has been shipped, you will receive a shipping confirmation email with a tracking number. You can use this tracking number to monitor the progress of your shipment online through the designated courier's website.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="29">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse26"
                    aria-expanded="false" aria-controls="collapse26">
                    Q10: What should I do if I encounter any issues with my order or payment?
                </button>
            </div>
            <div id="collapse26"  className="accordion-collapse collapse" aria-labelledby="29"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A10: If you encounter any issues or have questions regarding your order or payment, please contact our customer service team immediately. We are here to assist you and resolve any concerns you may have.
                    </p>
                </div>
            </div>
        </div>

        
        <h6 className='mb-4 mt-4'>FAQ - SHIPPING & DELIVERY</h6>

        <div className="accordion-item">
            <div className="accordion-header" id="30">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse33"
                    aria-expanded="true" aria-controls="collapse33"> Q1: Where do you offer shipping? </button>
            </div>
            <div id="collapse15" className="accordion-collapse collapse" aria-labelledby="30" data-bs-parent="#faqaccordion">
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A1: We offer shipping to various locations worldwide. During the checkout process on our website, you can select your country for shipping. The applicable shipping charges and estimated delivery times will be displayed accordingly.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="31">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse34"
                    aria-expanded="false" aria-controls="collapse34">
                   Q2: What are the shipping options available?
                </button>
            </div>
            <div id="collapse34"  className="accordion-collapse collapse" aria-labelledby="31"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A2: We offer several shipping options, including standard shipping, express shipping, and in-store pickup (if applicable). The available options will be presented during the checkout process, and you can choose the one that best suits your needs.
                    </p>
                </div>
            </div>
        </div>
        <div className="accordion-item">
            <div className="accordion-header" id="32">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35"
                    aria-expanded="false" aria-controls="collapse35">
                   Q3: How long does it take to process and ship an order?
                </button>
            </div>
            <div id="collapse35"  className="accordion-collapse collapse" aria-labelledby="32"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A3: We strive to process and ship orders as quickly as possible. The processing time may vary depending on factors such as product availability, customization requirements, and order volume. Once your order has been processed, it will be shipped according to the selected shipping option and the estimated delivery timeframe associated with it.
                    </p>
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <div className="accordion-header" id="33">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35"
                    aria-expanded="false" aria-controls="collapse35">
                   Q4: How can I track the status of my shipment?
                </button>
            </div>
            <div id="collapse35"  className="accordion-collapse collapse" aria-labelledby="33"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A4: Once your order has been shipped, you will receive a shipping confirmation email containing a tracking number. You can use this tracking number to monitor the progress of your shipment. Simply visit the designated courier's website and enter the tracking number to obtain real-time updates on the location and status of your package.
                    </p>
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <div className="accordion-header" id="34">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35"
                    aria-expanded="false" aria-controls="collapse35">
                   Q5: Do you offer free shipping?
                </button>
            </div>
            <div id="collapse35"  className="accordion-collapse collapse" aria-labelledby="34"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A5: We may offer free shipping promotions from time to time. Please check our website or subscribe to our newsletter for updates on any ongoing shipping offers or promotions.
                    </p>
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <div className="accordion-header" id="35">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35"
                    aria-expanded="false" aria-controls="collapse35">
                   Q6: Can I change the shipping address after placing an order?
                </button>
            </div>
            <div id="collapse35"  className="accordion-collapse collapse" aria-labelledby="35"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A6: If you need to change the shipping address after placing an order, please contact our customer service team as soon as possible. We will do our best to accommodate your request. However, please note that once an order has been shipped, it may not be possible to change the shipping address.
                    </p>
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <div className="accordion-header" id="36">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35"
                    aria-expanded="false" aria-controls="collapse35">
                   Q7: Do you ship to P.O. boxes or APO/FPO addresses?
                </button>
            </div>
            <div id="collapse35"  className="accordion-collapse collapse" aria-labelledby="36"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A7: We can ship to some P.O. boxes and APO/FPO addresses, depending on the shipping carrier's policies. Please ensure that you provide the accurate and complete address details during checkout to avoid any delivery issues.
                    </p>
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <div className="accordion-header" id="37">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35"
                    aria-expanded="false" aria-controls="collapse35">
                   Q8: Will I be responsible for customs duties or import taxes?
                </button>
            </div>
            <div id="collapse35"  className="accordion-collapse collapse" aria-labelledby="37"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A8: Customs duties and import taxes may apply to international shipments, depending on the destination country's regulations. These additional charges are the responsibility of the recipient. Please familiarize yourself with the customs policies of your country and be prepared to settle any applicable fees upon delivery.
                    </p>
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <div className="accordion-header" id="38">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35"
                    aria-expanded="false" aria-controls="collapse35">
                   Q9: What should I do if my package is damaged or lost during transit?
                </button>
            </div>
            <div id="collapse35"  className="accordion-collapse collapse" aria-labelledby="38"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A9: In the unfortunate event that your package is damaged or lost during transit, please contact our customer service team immediately. We will work with you to resolve the issue and initiate any necessary claims or investigations with the shipping carrier.
                    </p>
                </div>
            </div>
        </div>

        <div className="accordion-item">
            <div className="accordion-header" id="39">
                <button className="accordion-button collapsed s3" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35"
                    aria-expanded="false" aria-controls="collapse35">
                   Q10: Can I request expedited shipping for urgent orders?
                </button>
            </div>
            <div id="collapse35"  className="accordion-collapse collapse" aria-labelledby="39"
                data-bs-parent="#faqaccordion"   >
                <div className="accordion-body">
                    <p className="b2 faqtxt">
                    A10: Yes, if you require expedited shipping for urgent orders, we offer express shipping options. During the checkout process, you can choose the express shipping option that best suits your needs. Please note that additional charges may apply for expedited shipping services.
                    </p>
                </div>
            </div>
        </div>

      </div>

        </div>
      </div>
    </div>
    </div> */}
    </>
  )
}

export default FAQ