import React from "react";
import "../Diamond Guide/DiamondGuide.css";

const ConflictFreeDiamonds = () => {

  return (
    <>
      <section className="why-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div>
                
                <div className="mb-4">
                  <h2 className="sub_title-guide">Conflict-Free Diamonds: Our Commitment to Ethical Sourcing</h2>
                  <p>At Diora Adams Diamond Jewelry, we consider inside the energy of beauty and craftsmanship, but additionally within the duty to make certain that our products are ethically sourced. That's why we are proud to provide struggle-loose diamonds as a part of our series. We apprehend the importance of transparency, social responsibility, and the preservation of human rights within the diamond enterprise.</p>
                </div>


                <div className="mb-4">
                  <h2 className="sub_title-subguide">What are Conflict-Free Diamonds?</h2>
                  <p >
                  Conflict-free diamonds, additionally referred to as ethically sourced diamonds, are diamonds which have been obtained with out supporting or investment conflicts or human rights abuses. These diamonds are extracted and traded in a accountable and moral manner, with recognize for both the environment and the people involved within the diamond supply chain.
                  </p>
                </div>

                <div className="mb-4">
                  <h2 className="sub_title-subguide">Our Commitment to Ethical Sourcing</h2>
                  <p >
                  At Diora Adams, we've taken proactive steps to ensure that each one our diamonds are sourced responsibly and are war-unfastened. We are committed to helping the Kimberley Process Certification Scheme, an worldwide initiative geared toward stopping the exchange of conflict diamonds. This process entails strict tips and guidelines to track the foundation of diamonds, making sure that they come from legitimate resources and have no longer been used to finance conflicts.
                  </p>
                  <p >
                  We simplest paintings with relied on providers who share our dedication to moral sourcing. These suppliers adhere to the best industry requirements and comply with the Kimberley Process and other relevant regulations. By forging strong relationships with official suppliers, we will hopefully offer our clients a assure that our diamonds are conflict-loose.
                  </p>
                </div>

                <div className="mb-4">
                  <h2 className="sub_title-subguide">Our Diamond Certification</h2>
                  <p >
                  To similarly make certain the authenticity and ethical sourcing of our diamonds, we offer certificates of authenticity for all our diamond rings. These certificates are issued by famend gemological laboratories, including the Gemological Institute of America (GIA). They offer particular information about the characteristics and origin of the diamond, giving our clients peace of mind and confidence of their purchase.
                  </p>
                </div>

                <div className="mb-4">
                  <h2 className="sub_title-subguide">The Impact of Ethical Sourcing</h2>
                  <p >
                  Choosing warfare-loose diamonds has a high quality effect that extends beyond the jewelry itself. By helping moral sourcing practices, we make a contribution to the well-being and empowerment of neighborhood communities involved in the diamond enterprise. Ethical mining practices promote safe operating situations, honest wages, and the protection of human rights. They also help keep the surroundings and save you the exploitation of natural sources.
                  </p>
                  <p >
                  We firmly accept as true with that via selling moral sourcing, we can make a difference within the global and set an example for the diamond industry. Our willpower to struggle-free diamonds is a mirrored image of our values and our dedication to creating accountable choices which have a high-quality impact on society and the surroundings.
                  </p>
                </div>

                <div className="mb-4">
                  <h2 className="sub_title-subguide">Join Us in Making a Difference</h2>
                  <p >
                  When you pick out Diora Adams Diamond Jewelry, you can put on our incredible portions with delight, knowing that they have got been created with care, integrity, and a dedication to moral sourcing. Each diamond in our collection tells a tale of beauty and duty, showcasing the concord among human craftsmanship and the protection of moral values.
                  </p>
                  <p >
                  We invite you to discover our collection of warfare-loose diamond rings, understanding that your purchase no longer handiest reflects your excellent taste however additionally supports a sustainable and responsible diamond enterprise. Together, we are able to create a brighter destiny in which beauty and ethical values move hand in hand.
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConflictFreeDiamonds;
