import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Navigate, useNavigate } from 'react-router-dom'
import { BASE_URL, GetAPIUrl } from '../API/APIUrl'
import { fetch2, fetchGet } from '../helpers/fetch'
import { ShowErrorMessage, ShowMessage } from '../module/Tostify'
import Cookies from 'universal-cookie';
import axios from 'axios'

const initialState = {
    token: null,
    status: false,
    error: null,
    data: "",
}
const cookies = new Cookies();
const $ = window.$;

export const CallCartCountApi = async (token) => {
    const controller = new AbortController();

    await axios.get(BASE_URL + GetAPIUrl.CARTWISHLISTCOUNT_URL, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }
    })
        .then(response => {
            if (response.data.success == true) {
                localStorage.setItem('wishlistcount', JSON.stringify(response.data.wishlist.count))
                localStorage.setItem('cartcount', JSON.stringify(response.data.cart.count))
                $(function () {
                    $('.addtocartCount').html(JSON.stringify(response.data.cart.count));
                    $('.addtowishlistCount').html(JSON.stringify(response.data.wishlist.count));
                })
            } else {
                localStorage.setItem('wishlistcount', JSON.stringify(0))
                localStorage.setItem('cartcount', JSON.stringify(0))
            }
        }).catch(error => {
        }).finally(() => {

        })
    controller.abort()
}


export const loginUser = createAsyncThunk(
    'loginuser',
    async (body) => {
        let data = {
            "email": body.email,
            "password": body.password,
        }
        let formData = new FormData();
        formData.append("email",body.email);
        formData.append("password", body.password);
        const result = await fetch2(BASE_URL + GetAPIUrl.LOGIN_URL, formData, null)
        if (result.success == true) {
            
            if (body.Remember == true) {
                cookies.set('email', body.email, { path:  + "/" });
                cookies.set('password', body.password, { path:  + "/" });

            } else {
                if (cookies.get('email') != undefined) {
                    cookies.remove('email', { path:  + "/" });
                    cookies.remove('password', { path:  + "/" });
                }
            }

        } return result

    }
)

const authReducer = createSlice({
    name: "user",
    initialState,
    reducers: {
        addToken: (state, action) => {
            state.data = JSON.parse(localStorage.getItem('data'))
            state.token = localStorage.getItem('token')
        },
        logout: (state, action) => {
            state.token = null
            state.data = null
            localStorage.removeItem('data')
            localStorage.removeItem('token')
            localStorage.removeItem('customerGroup')
        },
        updateUser: (state, action) => {
            state.token = localStorage.getItem('token')
            state.data = JSON.parse(localStorage.getItem('data'))
        }
    },
    extraReducers: {

        [loginUser.pending]: (state, action) => {
            state.status = true
        },
        [loginUser.fulfilled]: (state, { payload: { data, errors, token, message } }) => {
            state.status = false
            if (token == undefined) {
                ShowErrorMessage(message)
                state.error = errors
            } else {
                // ShowMessage(message)
                state.token = token
                state.data = data
                state.error = message
                localStorage.setItem('data', JSON.stringify(data))
                localStorage.setItem('token', token)
                localStorage.setItem('customerGroup',JSON.stringify(data?.customer_group));
                CallCartCountApi(token)
                // window.location.pathname = '/'
            }
        },
    }

})

export const { addToken, logout, updateUser } = authReducer.actions
export default authReducer.reducer