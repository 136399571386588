
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { loginUser } from '../reducers/userReducers'

import Form from 'react-bootstrap/Form';

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Colors } from '../constant/Constant'
import Cookies from 'universal-cookie';
import { AiOutlineBorder, AiOutlineCheckSquare } from "react-icons/ai";
import { BASE_URL, GetAPIUrl } from '../API/APIUrl'
import axios from 'axios'
import { ShowErrorMessage, ShowMessage } from '../module/Tostify'
import LoadingSpinner from '../module/LoadingSpinner'



const ResetPassword = ({ authorized }) => {
    const location = useLocation();

    const token = new URLSearchParams(location.search).get("token");
    const email = new URLSearchParams(location.search).get("email");


    const [SignupData, setSignupData] = useState([])

    const [emailLoad, setEmailLoad] = useState(false)

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showPassShow, setShowPassShow] = useState(false)
    const [showConfiPassShow, setShowConfiPassShow] = useState(false)
    const [ErrorData, setErrorData] = useState([])
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [Message, setMessage] = useState('');

    useEffect(() => {
        CallVerifyTokenApi()
    }, [])
    const CallVerifyTokenApi = async () => {
        const controller = new AbortController();

        setEmailLoad(true)

        var form = new FormData();

        form.append("email", email);
        form.append("token", token);


        await axios.post(BASE_URL + GetAPIUrl.VERIFYTOKEN_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.data.status == true) {
                    // ShowMessage(response.data.message)
                    // setjewelryDetail(response?.data?.data);
                } else {
                    setMessage(response.data.message)
                    ShowErrorMessage(response.data.message)
                }
            })
            .catch(error => {
                setMessage(error.response.data.message)
                ShowErrorMessage(error.response.data.message)
            }).finally(() => {
                setEmailLoad(false)
            })
        controller.abort()
    }

    const authenticate = async (event) => {
        event.preventDefault()
        setEmailLoad(true)

        var form = new FormData();
        form.append("email", email);
        form.append("token", token);
        form.append("password", password);
        form.append("password_confirmation", confirmPassword);


        await axios.post(BASE_URL + GetAPIUrl.RESETPASSWORD_URL, form, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {

                if (response?.data?.status == true) {
                    // ShowMessage(response?.data?.message)
                    // window.location.replace(process.env.PUBLIC_URL + "/Login")

                } else {
                    ShowErrorMessage(response?.message)
                }
                setSignupData(response?.error?.response?.data?.errors)
            }).catch((error) => {
                if (error?.response?.data?.status == true) {
                    ShowErrorMessage(error?.response?.data?.errors?.password[0])

                } else {
                    ShowErrorMessage(error?.response?.data?.message)

                }
                setSignupData(error?.response?.data?.errors)

                // setErrorData(error?.response?.data?.errors?.password[0])

            }).finally(() => {
                setEmailLoad(false)
            })

    }


    return (
        <>

            <div>
                <div className="container container-main">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={'/'}><i className="fa fa-home" aria-hidden="true"></i></Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Reset Password</li>
                        </ol>
                    </nav>
                </div>
            </div>
            {emailLoad == true ?
                <LoadingSpinner />
                :
                null}

                <div className="main-section">

                <div className="main-section">

                    <section className="pb-5 mob-ptpb-1">

                        <div className="container">

                            <div style={{ maxWidth: "500px", margin: "auto", boxShadow: "0px 2px 10px 0px #0530691a" }}>

                            <Form className="input-form-div signup-div" style={{padding:"1rem 1rem"}}>
                            <div className="text-center">
                                                <h3 className="main-color">
                                                Reset Password
                                                </h3>
                                            </div>
                                {Message == '' ?
                                    <div className="">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="form-group pos-relative">
                                                    <label className="lblmy">Password <span className='mark_red'>*</span></label>
                                                    <input
                                                        // style={{ font: showPassShow && "small-caption", }}
                                                        value={password}
                                                        type={showPassShow ? "text" : 'password'}
                                                        id="showpassword"
                                                        onChange={e => setPassword(e.target.value)}
                                                        className="form-control my-textbox1" placeholder="Enter Password" />
                                                    <span className="show-pass hand" title={showPassShow ? "show password" : "Hide password"} onClick={() => setShowPassShow(!showPassShow)}
                                                        style={{ paddingLeft: 5, paddingRight: 10 }} >
                                                        <i className={showPassShow ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                    </span>
                                                    <p style={{ color: Colors.red, height: 15, padding: 4 }}>

                                                        {SignupData?.password != undefined && SignupData?.password?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <div className="form-group pos-relative">
                                                    <label className="lblmy">Confirm Password <span className='mark_red'>*</span></label>
                                                    <input
                                                        // style={{ font: showConfiPassShow && "small-caption", }}
                                                        value={confirmPassword}
                                                        type={showConfiPassShow ? "text" : 'password'}
                                                        id="showconfipassword"
                                                        onChange={e => setConfirmPassword(e.target.value)}
                                                        className="form-control my-textbox1" placeholder="Enter Confirm Password" />
                                                    <span className="show-pass hand" title={showConfiPassShow ? "show password" : "Hide password"} onClick={() => setShowConfiPassShow(!showConfiPassShow)}
                                                        style={{ paddingLeft: 5, paddingRight: 10 }} >
                                                        <i className={showConfiPassShow ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                    </span>
                                                    <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                        {SignupData?.confirm_password != undefined && SignupData?.confirm_password?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="col-xl-12 text-center">
                                                <button
                                                    onClick={authenticate}
                                                    className="login-me btn-shadow-me min-w-200">Submit</button>

                                            </div>

                                        </div>
                                    </div>
                                    :
                                    
                                        <div className="" style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
                                                <img height={100} src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/warn.png"} style={{
                                                    display: "block",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",

                                                }} />
                                                <label className="lblmy" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {"Invalid Toekn. This Token is already Used."}{Message}
                                                </label>
                                        </div>
                                }
                            </Form>
                                
                            </div>
                        </div>
                        
                    </section>

                </div>

                </div>

            {/* <section className="jumbotron">
                <div className="limiter">
                    <div className="container-login100">
                        <d  iv className="wrap-login100">
                            <form className="login100-form validate-form" >
                            {Message == '' &&  <span className="login100-form-title p-b-34">Reset Password</span>}
                                {Message == '' ?
                                    <div className="">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="form-group">
                                                    <label className="lblmy">Password*</label>
                                                    <input
                                                        style={{ font: showPassShow && "small-caption", }}
                                                        value={password}
                                                        type={showPassShow ? "text" : 'password'}
                                                        id="showpassword"
                                                        onChange={e => setPassword(e.target.value)}
                                                        className="form-control my-textbox" placeholder="Enter Password" />
                                                    <span className="show-pass hand" title={showPassShow ? "show password" : "Hide password"} onClick={() => setShowPassShow(!showPassShow)}
                                                        style={{ paddingLeft: 5, paddingRight: 5,top:'40px' }} >
                                                        <i className={showPassShow ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                    </span>
                                                    <p style={{ color: Colors.red, height: 15, padding: 4 }}>

                                                        {SignupData.password != undefined && SignupData?.password?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                                <div className="form-group">
                                                    <label className="lblmy">Confirm Password*</label>
                                                    <input
                                                        style={{ font: showConfiPassShow && "small-caption", }}
                                                        value={confirmPassword}
                                                        type={showConfiPassShow ? "text" : 'password'}
                                                        id="showconfipassword"
                                                        onChange={e => setConfirmPassword(e.target.value)}
                                                        className="form-control my-textbox" placeholder="Enter Confirm Password" />
                                                    <span className="show-pass hand" title={showConfiPassShow ? "show password" : "Hide password"} onClick={() => setShowConfiPassShow(!showConfiPassShow)}
                                                        style={{ paddingLeft: 5, paddingRight: 5,top:'40px' }} >
                                                        <i className={showConfiPassShow ? "fa fa-eye-slash" : 'fa fa-eye showpassword'} aria-hidden="true"></i>
                                                    </span>
                                                    <p style={{ color: Colors.red, height: 15, padding: 4 }}>
                                                        {SignupData.confirm_password != undefined && SignupData?.confirm_password?.map((key, i) => key)}
                                                    </p>
                                                </div>
                                            </div>


                                            <div className="col-xl-12 text-center">
                                                <br />

                                                <button
                                                    onClick={authenticate}
                                                    className="login-me btn-shadow-me min-w-200">Submit</button>

                                            </div>

                                        </div>
                                    </div>
                                    :
                                    
                                        <div className="" style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
                                                <img height={100} src={process.env.REACT_APP_PUBLIC_PATH_URL+ "/assets/images/warn.png"} style={{
                                                    display: "block",
                                                    marginLeft: "auto",
                                                    marginRight: "auto",

                                                }} />
                                                <label className="lblmy" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {"Invalid Toekn. This Token is already Used."}{Message}
                                                </label>
                                        </div>
                                }
                            </form>
                            <div className="login100-more d-none d-xl-block d-lg-block" style={{ backgroundImage: `url('./assets/images/login/login-banner.jpg')` }}></div>

                        </d>
                    </div>
                </div>
            </section> */}

            <ToastContainer />

        </>

    )
}

export default ResetPassword