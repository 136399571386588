import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'

export const ShowMessage = (message) => {

    toast.success(message, {
        position: toast.POSITION.BOTTOM_RIGHT,
    })
}
export const ShowErrorMessage = (message,pos='') => {
    if(pos != ''){
        toast.error(message) 
    }else{
        toast.error(message, {position: toast.POSITION.BOTTOM_RIGHT,})        
    }
}

export const confirmRemove = (title = "Are you sure?",text = "You won't be able to revert this!") => {
    return (
        Swal.fire({
            // title: 'Are you sure?',
            // text: "You won't be able to revert this!",
            title: title,
            text: text,
            icon: "warning",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                return true
            }
        })
    )
}
export const confirmReturns = () => {
    return (
        Swal.fire({
            title: 'Are you sure you want to return ?',
            text: "You won't be able to revert this!",
            icon: "warning",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            preConfirm: (Message) => {

                if (Message != '') {
                    return Message.json
                } else {
                    Swal.showValidationMessage(
                        `Message Required`
                    )
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                return result
            }
        })
    )
}
export const confirmCancelOrder = () => {
    return (
        Swal.fire({
            title: 'Are you sure you want to Cancel Order ?',
            text: "You won't be able to revert this!",
            icon: "warning",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true,
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            preConfirm: (Message) => {

                if (Message != '') {
                    return Message.json
                } else {
                    Swal.showValidationMessage(
                        `Message Required`
                    )
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                return result
            }
        })
    )
}
export const confirmLogout = () => {
    return (
        Swal.fire({
            title: 'Are you sure you want to Logout?',
            text: "You won't be able to show your saved Data!",
            icon: "warning",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                return true
            }
        })
    )
}
export const confirmPayment = () => {
    return (
        Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Your Order Is Successfully Done',
            showConfirmButton: false,
            timer: 1500
        })
    )
}
export const confirmAddress = () => {
    return (
        Swal.fire({
            title: 'Sweet!',
            text: 'Data Successfully Saved.!',
            icon: "success",

            allowOutsideClick: false,
            allowEscapeKey: false,

        }).then((result) => {
            if (result.isConfirmed) {

                return true
            }
        })
    )
}

export const FailedPayment = () => {
    return (
        Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Your Order Is Failed',
            showConfirmButton: false,
            timer: 1500
        })
    )
}